<template>
    <div class="contact">
        <div class="contact__avatar" @click.prevent="gotoProfile">
            <picture v-if="opponent && opponent.avatar">
                <source type="image/webp" :srcset="`${s3Url}/${opponent.avatar_webp}`" v-if="opponent.avatar_webp">
                <img class="avatar avatar_sm" :src="`${s3Url}/${opponent.avatar}`" alt="avatar user"  width="60" height="60">
            </picture>
            <div class="avatar avatar_sm avatar_holder" v-else>
                <img class="avatar__image" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="60" height="60">
            </div>
<!--            <span class="status status_online" v-if="opponent && opponent.online">-->
<!--                <i class="status__icon"></i>-->
<!--            </span>-->
        </div>
<!--        <div class="contact__info">-->
<!--            <span class="contact__title">-->
<!--                <span class="contact__name" v-if="opponent">{{opponent.name}}</span>-->
<!--                <span class="contact__age" v-if="opponentAge">{{opponentAge}}</span>-->
<!--            </span>-->
<!--        </div>-->
        <div class="contact__info">
            <span class="contact__title">
                <span class="contact__name" v-if="opponent">{{opponent.name}}</span>
                <span class="contact__age">{{opponentAge}}</span>
            </span>
            <span class="status status_online" v-if="opponent && opponent.online">
                <i class="status__icon"></i>
                <span>Online</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "ChatHeaderContact",
    props:['s3Url','myUrl','opponent','opponentAge'],
    methods:{
        gotoProfile(){
            this.$emit('goto_profile')
        }
    }
}
</script>

<style scoped>

</style>
