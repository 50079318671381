<template>
    <div class="page__actions" :class="{'ml-auto': $mq !=='desktop'}">
        <button class="button button_sm button_rounded button_border"
                data-modal="account-user-name" @click="openAccountNameEditor" v-if="$mq !=='desktop'">
            <span>Edit <i class="button__icon icon icon_edit"></i></span>
        </button>
        <button class="button button_options" @click.stop.capture="openEmailAndPathEditor"><span></span></button>
        <div class="dropdown dropdown_right dropdown_lg">
            <ul class="dropdown__menu menu" v-if="statusEmailAndPathEditor">
                <li class="dropdown__item menu__item" @click="openModalChangeEmail"><span class="dropdown__link menu__link is-flex">
                            <span class="dropdown__title"><i class="icon icon_envelope"></i>Email</span>
                            <span class="dropdown__text">{{ currentUser.email }}</span></span></li>
                <li class="dropdown__item menu__item" @click="openModalChangePassword"><span class="dropdown__link menu__link"><i
                    class="icon icon_unlock"></i>Change Password</span></li>
                <li class="dropdown__item menu__item" @click="openModalConfirmDelete"><span class="dropdown__link menu__link"><i
                    class="icon icon_delete"></i>Delete account</span></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "AccountHeaderEditor",
    props: ['currentUser', 'chatOwner'],
    data() {
        return{
            statusEmailAndPathEditor: false,
        }
    },
    methods: {
        closeEditor(){
            this.statusEmailAndPathEditor = false;
        },
        openEmailAndPathEditor(){
            this.statusEmailAndPathEditor = !this.statusEmailAndPathEditor
        },
        openAccountNameEditor(){
            this.$emit('open_modal_name')
        },
        openModalChangeEmail(){
            console.log('openModalChangeEmail')
            this.$emit('open_modal_change_email')
            this.statusEmailAndPathEditor = false
        },
        openModalChangePassword(){
            this.$emit('open_modal_change_password')
            this.statusEmailAndPathEditor = false
        },
        openModalConfirmDelete(){
            this.$emit('confirm_delete_account')
            this.statusEmailAndPathEditor = false
        },
    }
}
</script>

<style scoped>

</style>
