<template>
    <div class="custom-select" @click="toggleOptions" v-if="showOptions">
        <div class="custom-select__option custom-select__option_selected">
            <i class="icon fi" :class="`fi-${selectedCountry.countryCode}`" v-if="selectedCountry"></i>
            <span>{{ selectedCountry ? selectedCountry.countryName : selectedOption }}</span>
        </div>
        <div class="custom-select__options" >
            <div class="custom-select__option" v-for="(option, index) in countries" :key="index" @click.stop.capture="selectOption(option)">
                <i class="icon fi" :class="`fi-${option.countryCode}`"></i>
                <span>{{ option.countryName }}</span>
            </div>
        </div>
    </div>
    <div class="custom-select" @click.stop.capture="toggleOptions" v-else>
        <div class="custom-select__option custom-select__option_selected">
            <i class="icon fi" :class="`fi-${selectedCountry.countryCode}`" v-if="selectedCountry"></i>
            <span>{{ selectedCountry ? selectedCountry.countryName : selectedOption }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "SelectCountry",
    props: ['countries'],
    data() {
        return {
            selectedOption: "Select Country",
            selectedCountry: null,
            showOptions: false,
        };
    },
    methods: {
        closeOptions(){
            this.showOptions = false;
        },
        resetCountry(){
            this.selectedCountry = null;
        },
        setCountry(country_id){
            console.log('setCountry: '+country_id);
            this.selectedCountry = this.countries.find(country => +country.id === +country_id);
        },
        toggleOptions() {
            this.showOptions = !this.showOptions

            // this.$refs.options.style.display = this.$refs.options.style.display === "block" ? "none" : "block";
        },
        selectOption(option) {
            console.log('selectOption');
            console.log(option)
            this.selectedCountry = option;
            this.$emit('selected_country', option);
            this.showOptions = false;
            // this.selectedOption = option.countryName;
        },
    },
};
</script>

<style scoped>

</style>

