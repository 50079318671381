<template>
    <div class="form__row" :class="{'is-error':(errBirthday || showBadgeWarning)}">
        <label class="form__label">Date of birth</label>
        <div class="is-flex">
            <select name="date" class="ui-select" v-model="date" @change="selectDay">
                <option selected>date</option>
                <option v-for="option in dates" :value="option">{{ option }}</option>
            </select>
            <select name="month" class="ui-select" v-model="month" @change="selectMonth">
                <option selected>month</option>
                <option v-for="option in months" :value="option">{{ option }}</option>
            </select>
            <select name="year" class="ui-select" v-model="year" @change="selectYear">
                <option selected>year</option>
                <option v-for="option in years" :value="option">{{ option }}</option>
            </select>
        </div>
        <span class="form__error" v-if="errBirthday">{{ errBirthday }}</span>
    </div>
</template>

<script>
const checkMonth = {
    January:31,
    February:29,
    March:31,
    April:30,
    May:31,
    June:30,
    July:31,
    August:31,
    September:30,
    October:31,
    November:30,
    December:31
}
export default {
    name: "SelectBirthday",
    props:['initBirthday','errBirthday','showBadgeWarning'],
    data(){
        return{
            date: 'date',
            dates: Array.from({ length: 31 }, (_, i) => i + 1),
            month: 'month',
            months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
            year: 'year',
            years: [],
        }
    },
    watch:{
        initBirthday(old, prev){
            this.setInitBirthday()
        }
    },

    mounted() {
        this.initYears();
        this.setInitBirthday()
    },
    methods:{
        setInitBirthday(){
            console.log('setInitBirthday')
            console.log(this.initBirthday)

            if(!this.initBirthday || !this.initBirthday.length) return;
            // 1977-07-16
            try {
                let birth = ''
                if(this.initBirthday && typeof this.initBirthday === 'string')birth  = this.initBirthday.split('T')[0]
                const birthdayArr = birth.split('-')
                this.year = +birthdayArr[0];
                this.date = +birthdayArr[2]
                this.month = this.months[birthdayArr[1]-1]
            }catch (e) {
               console.log(e)
            }
        },
        isLeapYear(year) {
            return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
        },
        initYears(){
            console.log('initYears')
            for (let i = 2023; i > 1900; i-- ){
                this.years.push(i)
            }
        },
        selectYear(){
            console.log('selectYear');
            if(!isNaN(this.year)){
                let maxDate = this.isLeapYear(this.year) ? 29 : 28;
                checkMonth.February = maxDate;
                if(this.month === 'February'){
                    this.dates = Array.from({ length: maxDate }, (_, i) => i + 1);
                    if( !isNaN(this.date) && this.date > maxDate){
                        this.date = maxDate;
                    }
                }
            }
            this.setBirthday();
        },
        selectMonth(){
            console.log('selectMonth');
            if(this.month !== 'month'){
                this.dates = Array.from({ length: checkMonth[this.month] }, (_, i) => i + 1);
                if(!isNaN(this.date) && this.date > checkMonth[this.month]){
                    this.date = checkMonth[this.month];
                }
            }
            this.setBirthday();
        },
        selectDay(){
            this.setBirthday();
        },
        setBirthday(){
            this.$emit('set_birthday',{
                date: this.date,
                month: this.month,
                year: this.year,
            })
        }
    }
}
</script>

<style scoped>

</style>
