<template>
    <div class="message__gift">
        <img class="message__image"
             :src="`${s3UrlStatic}/${message.src}`" alt="gift"
             width="130" height="130">
    </div>
</template>
<script>
export default {
    name:"ChatItemGift",
    props:['s3UrlStatic','message'],
}
</script>
<style scoped>
</style>
