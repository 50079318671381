<template>
<div>
    <div class="modal" tabindex="-1" role="dialog" data-backdrop="static"  aria-labelledby="loginModal" id="loginModal" v-if="showLogin">
        <div class="modal-dialog modal-login" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-col is-bg">
                        <div class="caption">
                            <div class="buttons">
                                <button class="btn btn_light active">Login</button>
                                <button class="btn btn_border-light" @click.prevent="openRegister">Registration</button>
                            </div>
                            <h3 class="heading">Welcome back to Hablario</h3>
                            <p>
                                <span>Immerse yourself in a world of romance and</span>
                                <span>unforgettable impressions</span>
                                <span>Enjoy your communication</span>
                            </p>
                        </div>
                    </div>
                    <div class="modal-col is-form">
                        <login
                            :my-url="myUrl"
                            :is_lending="false"
                            :url-google="urlGoogle"
                            @login="loginUser"
                            @open_forgot_password="openFogot"
                            @errorhandler="errorHandler"
                        ></login>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" data-backdrop="static"  aria-labelledby="registerModal" id="registerModal" v-if="showRegister">
        <div class="modal-dialog modal-register" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-col is-form">
                        <register
                            :my-url="myUrl"
                            :countries="countries"
                            :is_lending="false"
                            :is_operator="false"
                            @register="registerUser"
                            @errorhandler="errorHandler"
                        ></register>
                    </div>
                    <div class="modal-col is-bg">
                        <div class="caption">
                            <div class="buttons">
                                <button class="btn btn_light active">Registration</button>
                                <button class="btn btn_border-light" @click.prevent="openLogin">Login</button>
                            </div>
                            <h3 class="heading">Welcome to Hablario</h3>
                            <p>
                                <span>Experience new emotions and an unforgettable </span>
                                <span>sense of passion</span>
                                <span>Enjoy your communication</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" tabindex="-1" role="dialog" data-backdrop="static"  aria-labelledby="forgotModal" id="forgotModal" v-if="showFogot">
        <div class="modal-dialog modal-forgot" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <div class="modal-col is-bg">
                        <div class="caption">
                            <div class="buttons">
                                <button class="btn btn_light" @click.prevent="openLogin">Login</button>
                                <button class="btn btn_border-light" @click.prevent="openRegister">Registration</button>
                            </div>
                            <h3 class="heading">Welcome back to Hablario</h3>
                        </div>
                    </div>
                    <forgot-password
                        :url-google="urlGoogle"
                        @errorhandler="errorHandler"
                    ></forgot-password>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import Login from "./login";
import Register from "./register";
import ForgotPassword from "../landing/ForgotPassword";
export default {
    name: "AuthComponent",
    components: {
        Register,
        Login,
        ForgotPassword
    },
    props:['countries','myUrl','urlGoogle'],
    data(){
        return{
            showLogin: true,
            showRegister: false,
            showFogot: false,
        }
    },
    methods:{
        openLogin(){
            this.showLogin = true;
            this.showRegister = false;
            this.showFogot = false;
        },
        openRegister(){
            this.showLogin = false;
            this.showRegister = true;
            this.showFogot = false;
        },
        openFogot(){
            this.showLogin = false;
            this.showRegister = false;
            this.showFogot = true;
        },
        registerUser(currentUser){
            this.$emit('register',currentUser);
        },
        loginUser(currentUser){
            this.$emit('login',currentUser);
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    },
    mounted() {
        this.showLogin = true;
    }
}
</script>

<style scoped>

</style>
