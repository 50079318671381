<template>
    <portal selector="#portal-target">
        <div class="modal modal_sm modal_block-user is-active"  tabindex="-1" role="dialog">
            <div class="modal__bg" @click.prevent="closeModalClear"></div>
            <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close" @click.prevent="closeModalClear">
                    <i class="icon icon_close"></i>
                </span>
                <div class="modal__header">
                    <h2 class="modal__title">Attention </h2>
                </div>
                <div class="modal__body">
                    <p>Are you sure that you want to clear all messages from the chat request?</p>
                </div>
                <div class="modal__footer">
                    <button class="button button_md button_bordered" @click.prevent="closeModalClear">
                        <span>
                            <span class="button__text">Close</span>
                        </span>
                    </button>
                    <button class="button button_md button_primary" @click.prevent="clearUnreadMessages">
                        <span>Clear</span>
                    </button>
                </div>
            </div>
        </div>
    </portal>
</template>
<script>
export default {
    name: "ClearUnreadMessages",
    methods:{
        closeModalClear(){
            this.$emit('close_modal_clear')
        },
        clearUnreadMessages(){
            this.$emit('clear_unread_messages')
        }
    }

}
</script>

<style scoped>

</style>
