<template>
    <div :class="{'panel__body': $mq ==='desktop', 'request request_mob' : $mq !=='desktop'}">
        <template v-if="$mq==='desktop'">
            <list-contact-opponents
                :s3-url="s3Url"
                :current-user="currentUser"
                :my-url="myUrl"
                :contacts="chatRequest"
                :role-opponent="roleOpponent"
                :type="'chat-request'"
                :unread="unread"
                :ban-list="banList"
                :is-new-version="isNewVersion"
                :is-supported-webp="isSupportedWebp"
                @get_more_contacts="getMoreChatRequest"
            ></list-contact-opponents>
        </template>
        <template v-else>
            <div class="request__container" :style="`height: ${windowHeight}px`">
                <div class="request__header mobile-head">
                    <div class="request__col">
                        <button class="btn btn_icon btn_back" @click="closeRequest">
                             <span class="holiday">
                                 <i class="icon icon-btn-back"></i>
                             </span>
                            <i class="icomoon icon-left"></i>
                        </button>
                        <span class="title">Chat request</span>
                    </div>
                    <div class="actions">
                        <button class="btn btn_icon btn_fav" @click.prevent="goToFavorite"><i class="icomoon icon-user-add"></i></button>
                        <button class="btn btn_icon btn_his" @click.prevent="goToHistory"><i class="icomoon icon-favorites"></i></button>
                        <button class="btn btn_icon btn_menu" @click.prevent="openMenu"><i class="icomoon icon-group"></i></button>
                    </div>
                </div>
<!--                <div class="request__body">-->
                    <list-contact-opponents
                        :s3-url="s3Url"
                        :current-user="currentUser"
                        :my-url="myUrl"
                        :contacts="chatRequest"
                        :role-opponent="roleOpponent"
                        :type="'chat-request'"
                        :ban-list="banList"
                        :unread="unread"
                        :is-new-version="isNewVersion"
                        :is-supported-webp="isSupportedWebp"
                        @get_more_contacts="getMoreChatRequest"
                    ></list-contact-opponents>
<!--                </div>-->
            </div>
        </template>
    </div>
</template>

<script>
import ListContactOpponents from "./ListContactOpponents";
export default {
    name: "ChatRequest",
    components: {
        ListContactOpponents
    },
    props:[
        's3Url',
        'currentUser',
        'chatOwner',
        'myUrl',
        'contacts',
        'roleOpponent',
        'banList',
        'windowHeight',
        'unread',
        'isNewVersion',
        'isAllChatRequest',
        'countContacts',
        'isSupportedWebp'
    ],
    data(){
        return{
            isDownload:false,
        }
    },
    computed:{
        chatRequest(){
            return  this.contacts.filter(contact=>{
                return +contact[`unread_${this.currentUser.role}`] && !this.banList.includes(contact[this.roleOpponent].id);
            })

        }
    },
    methods: {
        openMenu() {
            this.$emit('open_menu', false);
        },
        closeRequest(){
            this.$emit('close_request');
        },
        goToFavorite() {
            this.$emit('go_to_favorite');
        },
        goToHistory() {
            this.$emit('go_to_history');
        },
        getMoreChatRequest(){
            console.log('getMoreChatRequest')
            if(!this.chatRequest || !this.chatRequest.length) return;
            if (!this.isAllChatRequest && !this.isDownload){
                const lastContact = this.chatRequest[this.chatRequest.length-1]
                if(!lastContact) return;
                this.isDownload = true ;

                axios.post('/get-more-chat-request/v2',{
                    last: lastContact.last_time,
                    ownerID: this.chatOwner.id,
                    role: this.currentUser.role,
                    roleOpponent: this.roleOpponent,
                    countContacts: this.countContacts
                })
                    .then(response => {
                        console.log(response.data);
                        this.isDownload = false ;

                        this.$bus.$emit('add_contacts',{
                            contacts:response.data.contacts,
                            messages:response.data.messages,
                            countUnread:response.data.countUnread,
                            type:'chat_request',
                        })
                    })
                    .catch(e => {
                        console.log(e);
                        this.isDownload = false ;
                        this.errorHandler(e);
                    })

            }
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        // openModalClear() {
        //     console.log('openModalClear chat request');
        //     this.$emit('open-modal-clear');
        // },
    }
}
</script>

<style scoped>

</style>
