<template>
    <portal selector="#portal-target">
        <CoolLightBox
            :items="mediaChat"
            :index="indexMedia"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @close="indexMedia = null">
        </CoolLightBox>
        <div class="modal modal_support-chat is-active" id="support-chat" tabindex="-1" role="dialog">
            <div class="modal__bg" @click="closeSupport"></div>
            <div class="modal__container">
                    <support-chat-container
                        :current-user="currentUser"
                        :message-size="messageSize"
                        :my-url="myUrl"
                        :s3-url="s3Url"
                        :support-messages="supportMessages"
                        :support-contact="supportContact"
                        :support-typing="supportTyping"
                        :is-all-support-messages="isAllSupportMessages"
                        :last-support-messages-i-d="lastSupportMessagesID"
                        :window-height="windowHeight"
                        :count-unread="countUnread"
                        :text-count-unread="textCountUnread"
                        :init-support-message="initSupportMessage"
                        @open_menu="openMenu"
                        @go_to_request="goToRequest"
                        @open_chat_media="openChatMedia"
                        @close-support="closeSupport"
                        v-if="showSupport"
                    ></support-chat-container>
            </div>
        </div>
    </portal>
</template>

<script>
import CheckReadOpponent from "../CheckReadOpponent.vue";
import SupportChatContainer from "../SupportChatContainer.vue";

export default {
    name: "SupportChat",
    components: { SupportChatContainer },
    props: [
        'messageSize',
        'supportMessages',
        'supportContact',
        'messageSize',
        'currentUser',
        'showSupport',
        'myUrl',
        's3Url',
        'lastSupportMessagesID',
        'isAllSupportMessages',
        'supportTyping',
        'windowHeight',
        'countUnread',
        'textCountUnread',
        'initSupportMessage'
    ],
    data(){
        return{
            message:'',
            isSendTyping:false,
            exts:['png','jpeg','jpg','svg','mp3','mp4','txt','pdf','doc','docx','xls','xlsx'],
            maxsize: 10*1024*1024,
            // previewMedia: null,
            fileVideo: null,
            fileVoice: null,
            mediaChat: [],
            indexMedia: null,
            messageTextMaxSize: 2000,
            isDisabled:false,
        }
    },
    computed:{
        getExts(){
            let accept = '';
            this.exts.forEach(ext=>{
                accept += `.${ext}, `
            });
            return accept;
        },
        messageTextMax() {
            return this.message.length < this. messageTextMaxSize
        },
    },
    mounted() {
        this.$bus.$emit('open_modal');

    },
    beforeDestroy() {
        this.$bus.$emit('close_modal');

    },
    methods:{
        pasteToInput(e) {
            e.preventDefault();
            let text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
        },
        openMenu() {
            this.$emit('open_menu', false);
        },
        goToRequest() {
            this.$emit('go_to_request', false);
        },
        onScroll(event){
            if (!this.isAllSupportMessages && this.supportContact && this.lastSupportMessagesID){
                if (this.$refs.supportChatList.scrollTop === 0){
                    const oldHeight = this.$refs.supportChatList.scrollHeight ;
                    console.log('scroll top');
                    axios.get(`/get-more-support-messages/${this.supportContact.id}/${this.lastSupportMessagesID}`)
                        .then(response=>{
                            console.log(response.data);
                            this.$bus.$emit('add_more_support_message',response.data.messages);
                            this.$nextTick(()=>{
                                setTimeout(()=>{
                                    this.$refs.supportChatList.scrollTop = this.$refs.supportChatList.scrollHeight - oldHeight;
                                },250);
                            });
                        })
                        .catch(err => {
                            this.errorHandler(err);
                        });
                    // this.$bus.$emit('more_support_messages',this.$refs.supportChatList)
                }
            }
        },
        openChatMedia(src){
            // this.$emit('open_chat_media', data);
            console.log('openChatMedia support chat');
            this.mediaChat[0] = `${this.s3Url}/${src}`;
            this.indexMedia = 0;
        },
        // resetPreviewMedia(){
        //     this.isTextInput = true;
        //     this.previewMedia = null;
        //     this.fileImage = null;
        //     this.fileVideo = null;
        //     this.originVideo = null;
        //     this.fileVoice = null;
        //     this.mediaName = '' ;
        //     this.$refs.inputMessage.textContent = '';
        //     this.$refs.inputMessage.innerText = '';
        //     this.typeMessage = 'message';
        //     this.isConversion = false ;
        //
        // },
        messageTime(message){
            return new Date(message.created_at).toLocaleTimeString() ;
        },
        writeDate(date){
            const arr = date.split('.')
            return `${arr[0]} ${month[Number(arr[1])]}, ${arr[2]} `
        },
        isPicture(src){
            const ext = this.getExtantionFile(src);
            return ext === 'png' || ext === 'jpeg' || ext === 'jpg' || ext === 'svg'
        },
        changeMessage(event){
            const message = event.target.innerText || event.target.textContent;
            this.message = message;
        },
        closeSupport() {
            console.log('closeSupport');
            this.$emit('close-support')
        },
        sendEventTyping(){
            if (!this.isSendTyping){
                this.$bus.$emit('is_typing_support');
                this.isSendTyping = true ;
                setTimeout(()=>{
                    this.isSendTyping = false ;
                },1500);
            }
        },
        scrollDown(){
            console.log('scrollDown');
            // console.log(this.$refs.chatOperatorContainer);
            // const  container = document.getElementById('chatoperatorcontainer') ;
            if (this.$refs.supportChatList) {
                setTimeout(()=>{
                    this.$refs.supportChatList.scrollTop = this.$refs.supportChatList.scrollHeight;
                },0)
            }
        },
        // classMessage(adminID){
        //     return adminID ? 'msg-start' : 'msg-end'
        // },
        sendSupportMessage(){

            console.log(this.message);
            if (!this.isDisabled && this.message && this.message.replace(/\s+/g, '').length !== 0) {
                if (this.messageTextMax ){
                    this.isDisabled = true;
                    axios.post(`/send-support-message/${this.currentUser.id}`,{
                        contact_id:this.supportContact.id,
                        text:this.message,
                        src:'',
                        admin_id:null,
                    })
                        .then(response => {
                            console.log(response.data);
                            this.isDisabled = false;
                            this.newMessageHandler(response.data)
                        })
                        .catch(e => {
                            console.log(e);
                            this.isDisabled = false;
                            this.errorHandler(e);
                        })
                }else {
                    alert('The message length cannot be longer than 2000 characters');
                }
            }
        },
        newMessageHandler(data){
            this.message = '';
            this.$refs.inputSupportMessage.textContent = '';
            this.$refs.inputSupportMessage.innerText = '';
            this.$bus.$emit('send_support_message',data);
            this.scrollDown();
        },
        errorHandler(err){
            this.errorHandler('error_handler',err);
        },
        getExtantionFile(fileName){
            let parts = fileName.split('.');
            let ext = '';
            if (parts.length > 1) {
                ext = parts.pop();
                return ext.toLowerCase();
            }
            return '';
        },
        saveFile(e){
            console.log('save file');
            if (this.isDisabled) return;
            // this.isRead = true;
            // clearTimeout(this.timer) ;
            let file = e.target.files[0];
            console.log(file);
            if (file.size > this.maxsize || file.fileSize > this.maxsize) {
                //show an alert to the user
                alert(`Allowed file size exceeded. (Max. ${this.maxsize / (1024 * 1024)} MB)`);
            }else if(this.exts.includes(this.getExtantionFile(file.name))){
                this.isDisabled = true;
                let data = new FormData();
                data.append('file', file);
                // data.append('name', file.name);
                data.append('contact_id',this.supportContact.id);
                data.append('admin_id',null);
                data.append('text', file.name);
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                };

                axios.post('/send-file-support-chat' ,data, config)
                    .then(response=>{
                        console.log(response.data);
                        this.isDisabled = false;
                        if (response.data.message){
                            this.$bus.$emit('send_support_message',response.data);
                            this.scrollDown();
                        }
                    })
                    .catch(err => {
                        this.isDisabled = false;
                        this.errorHandler(err);
                    });
            }else {
                alert('This file type is not supported. Supported files - '+ this.exts.toString());
            }
            e.target.value = null;

        },

    }
}
</script>


<style scoped>

</style>
