<template>
    <portal selector="#portal-target">
        <div class="modal modal_lg modal_payment modal_payment-table is-active"  tabindex="-1" role="dialog">
            <div class="modal__bg" @click.prevent="closeModal"></div>
            <div class="modal__container">
                    <span class="modal__close"  role="button" aria-label="Close" @click.prevent="closeModal">
                        <i class="icon icon_close"></i>
                    </span>
                <div class="modal__header">
                    <h2 class="section__heading heading">Warning <i class="icon icon_md icon_warning"></i></h2>
                </div>
                <div class="modal__body">
                    <div class="pad pad_modal">
                        <div class="pad__body">
                            <p>
                                <strong>Payment Verification: </strong> Once you initiate the payment, our system will verify the transaction based on the exchange rate at that specific moment. It is essential to ensure that the payment amount matches the specified invoice amount exactly. If the payment received differs from the invoice amount due to exchange rate fluctuations, our system will provide credits according to the amount that was received at the time of payment
                            </p>
                        </div>
                    </div>
                </div>
                <div class="modal__footer">
                    <a :href="cryptoPaymentPage" class="modal__button button button_md button_primary" @click="goPaymentPage" target="_blank">
                        <span>Apply</span>
                    </a>
                    <p>
                        <span>Read more rules for payment <a class="modal__link" :href="`${myUrl}/terms-and-conditions`" target="_blank">Terms And Conditions</a></span>
                    </p>
                </div>
            </div>
        </div>
    </portal>

</template>
<script>

export default {
    name: "ModalPaymentCrypto",
    props:['cryptoPaymentPage','myUrl'],
    mounted() {
        this.$bus.$emit('open_modal')
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
    },
    methods:{
        goPaymentPage(){
            this.$emit('go_to_payment');
        },
        closeModal(){
            this.$emit('close_modal')
        }
    }
}
</script>

<style scoped>

</style>
