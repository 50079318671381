<template>
    <div class="panel panel_success" :class="{'panel_mobile' : $mq !=='desktop'}" >
        <div class="panel__container">
            <div class="panel__header">
                <h1>Unsubscribe <span>to newsletters</span></h1>
            </div>
            <div class="panel__body">
                <p>Now you will not receive newsletters from our service.</p>
                <p>You can always subscribe to the newsletter by clicking on the settings icon in the upper right corner of the screen.</p>
            </div>
            <div class="panel__footer">
                <button class="btn btn_confirm" @click="homePage">Home Page</button>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "UnsubscribeToNewsletters",
    props:[],
    data(){
        return{
            isSubscribe: false
        }
    },
    created() {
        console.log('created from SuccessValidationEmail')
    },
    methods:{
        homePage(){
            this.$router.push({path: '/gallery'})
        },
        logout(){
            this.$emit('logout');
        },
    }
}
</script>

<style scoped>
</style>

