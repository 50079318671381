<template>
    <div class="chat__preview preview" >
        <div class="preview__message" v-if="isConversionProgress">
            <span class="preview__text">Converting.Please wait.</span>
        </div>
        <div class="preview__container" v-else>
            <div class="preview__item" :class="{'preview__item_audio': isVoice}">
                <button class="preview__close button button_icon button_close" @click.prevent="resetPreviewMedia" v-if="!isConversionProgress">
                    <i class="icon icon_close"></i>
                </button>
                <img class="preview__media" :src="media" v-if="!isVideo && !isVoice" alt="preview media">
                <video class="preview__media" controls="controls" id="videoPreview"
                       autoplay loop playsinline muted v-else-if="!isVoice && isVideo">
                    <source :src="media" type="video/mp4">
                    <source :src="media" type="video/webm">
                </video>
                <custom-audio-player v-else-if="isVoice"
                    :my-url="myUrl"
                    :media="media"
                ></custom-audio-player>
            </div>
        </div>
    </div>
</template>

<script>
    import CustomAudioPlayer from "../newModules/CustomAudioPlayer.vue";

    export default {
        name: "PreviewMedia",
        components: {CustomAudioPlayer},
        props: [
            'myUrl',
            'media',
            'isVideo',
            'isVoice',
            'isConversionProgress'
        ],
        methods:{
            resetPreviewMedia(){
                this.$emit('close_preview');
            }
        }
    }
</script>

