<template>
    <div>
<!--        <CoolLightBox-->
<!--            :items="mediaChat"-->
<!--            :index="indexMedia"-->
<!--            :loop="false"-->
<!--            :slideshow="false"-->
<!--            @close="closeBox"-->
<!--        ></CoolLightBox>-->
    </div>

</template>

<script>

export default {
    name: "ShowChatMedia",
    props:['mediaChat','indexMedia','id'],
    mounted() {
        if (this.$mq === 'desktop'){
            this.$router.push({name: 'profile',params:{id: this.id}})
        }
        if(!this.mediaChat || !this.mediaChat.length){
            this.$router.push({name: 'chat',params:{id: this.id}})
        }
    },
    methods:{

    }
}
</script>

<style scoped>

</style>
