<template>
    <ul class="list list_emoji">
        <li class="list__item" v-for="emoji in emojis" @click="selectEmoji(emoji.file)">
            <img :src="`${myUrl}/assets/emojis/${emoji.file}`" alt="emoji">
        </li>
    </ul>
</template>

<script>
export default {
name: "Emojis",
    props:[ 'myUrl', 'emojis'],
    methods:{
        selectEmoji(fileName){
            this.$emit('selectemoji',fileName);
        }
    }
}
</script>

<style scoped>

</style>
