<template>
    <div class="image-cropper">
        <span v-if="nameHeader">{{nameHeader}}</span>
        <div class="image-cropper__container">
            <img  class="image-cropper__image" :src="src" ref="image" alt="" :width="this.$mq === 'desktop' ? 400 : 300"
                :height="this.$mq === 'desktop' ? 400 : 500">
        </div>
        <div class="image-cropper__buttons">
            <button type="button" class="image-cropper__button image-cropper__button_left" :disabled="disabledButton"
                    @click.prevent="rotate(90)" title="Rotate Left">
                <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#reload`"></use>
                </svg>
            </button>
            <button class="button button_primary button_sm" @click.prevent="crop"><span :disabled="disabledButton">Upload image</span></button>
            <button type="button" class="image-cropper__button image-cropper__button_right" @click.prevent="rotate(-90)" title="Rotate Right" :disabled="disabledButton">
                <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                    <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#reload`"></use>
                </svg>
            </button>
<!--            <button type="button" class="image-cropper__button image-cropper__button_right" @click.prevent="crop" title="Rotate Right" :disabled="disabledButton">-->
<!--                <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24">-->
<!--                    <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#reload`"></use>-->
<!--                </svg>-->
<!--            </button>-->
        </div>
    </div>
</template>

<script>
import Cropper from 'cropperjs';

export default {
    name: "ImageCropper",
    props: {
        nameHeader: String,
        myUrl: String,
        src: String,
        width: Number,
        height: Number,
        avatarSize: Number,
        isMakeThumb: Boolean,
        disabledButton: Boolean
    },
    data(){
        return{
            cropper:{},
        }
    },
    mounted() {
        const image = this.$refs.image
        console.log(this.width +' <-w:h-> '+ this.height)
        const aspect= this.width / this.height;
        const cropperMinWidth = this.$mq === 'desktop' ? 400 : 300;
        const cropperMinHeight = this.$mq === 'desktop' ? 400 : 500;
        const options = {
            aspectRatio: aspect,
            viewMode: 2,
            autoCropArea: 1,
            cropBoxResizable: false,
            minContainerWidth: cropperMinWidth,
            minContainerHeight: cropperMinHeight,
            dragMode: 'move',
            responsive: false
        }
        this.cropper = new Cropper( image , options)
    },
    beforeDestroy() {
        console.log('beforeDestroy')
        this.cropper.destroy()
    },
    methods: {
        rotate(deg) {
            this.cropper.rotate(deg)
        },
        crop() {
            console.log('crop')
            let data = {
                width: this.width,
                height: this.height,
            }
            const canvas = this.cropper.getCroppedCanvas(data);
            if(this.isMakeThumb) this.$emit('crop', canvas, this.makeThumb(canvas))
            else this.$emit('crop', canvas)
        },
        makeThumb(canvas){
            const canvasThumb = document.createElement("canvas"),
                ctxThumb = canvasThumb.getContext("2d");
            canvasThumb.height = this.avatarSize;
            canvasThumb.width = this.avatarSize;
            ctxThumb.drawImage(canvas, 0,0,this.width,this.width,0, 0,this.avatarSize,this.avatarSize);

            return canvasThumb ;
        },
    }
}
</script>

<style>
.image-cropper, .image-cropper__container {
    width: 100%;
    overflow: hidden;
}
.image-cropper__container .cropper-container {
    margin: auto;
}
.image-cropper__container  img {
    display: block;
    max-width: 100%;
}
.image-cropper__buttons {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.image-cropper__button {
    display: inline-block;
    background: none;
    border: 0;
    padding: 0;
    font-size: 2rem;
    transition: opacity .25s ease-out;
    color: #7e7e7e;
    cursor: pointer;
}
.image-cropper__button_left {
    scale: -1 1;
}
.image-cropper__button:hover {
    opacity: .85;
}
.image-cropper__video {
    max-width: 100%;
    max-height: 60vh;
}
.cropped-image img {
    max-width: 100%;
}
</style>
