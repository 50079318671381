<script>
export default {
    name: "AnimeEasy",
    methods:{



    }
}
</script>

<template>
    <div class="carousel carousel_animation">
        <div class="carousel__wrapper">
            <div class="carousel__image"></div>
            <div class="carousel__image"></div>
        </div>
        <div class="carousel__wrapper">
            <div class="carousel__image"></div>
            <div class="carousel__image"></div>
        </div>
        <div class="carousel__wrapper">
            <div class="carousel__image"></div>
            <div class="carousel__image"></div>
        </div>
    </div>
</template>

<style scoped>

</style>
