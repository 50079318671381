<template>
    <div class="page page_inner page_account" :class="{'page_mobile': $mq !=='desktop', 'is-chat-open' : ($mq ==='desktop' && showChat)}" @click="clickPage">
        <div class="container" >
            <template v-if="$mq ==='desktop'">
                <div class="page__header">
                    <div class="page__wrapper">
                        <base-logo></base-logo>
                        <account-header-editor ref="accountHeaderEditor"
                                               :current-user="currentUser"
                                               :chat-owner="chatOwner"
                                               @open_modal_name="openModalName"
                                               @open_modal_change_email="openModalChangeEmail"
                                               @open_modal_change_password="openModalChangePassword"
                                               @confirm_delete_account="openModalConfirmDeleteAccount"
                        ></account-header-editor>
                    </div>
                </div>
                <div class="profile profile_account" ref="parentSettingsContainer">

                    <!-- central bar start Desktop -->
                    <div class="profile__container">
                        <div class="profile__promo">
                            <div class="profile__promo-header">
                                <div class="person person_card">
                                    <div class="person__info">
                                        <span class="person__name">{{ memberName}}</span>
                                        <span class="person__age person__age_inline">{{ chatOwnerAge }}</span>
                                        <span class="person__icon"><i class="icon icon_zodiac icon_aries"></i></span>
                                        <span class="person__location">
                                            <i class="icon icon_marker"></i>
                                            {{country ? country.countryName: ''}}
                                        </span>
                                    </div>
                                    <button class="button button_sm button_rounded button_border"
                                            @click="openModalName"  v-if="$mq ==='desktop'">
                                        <span>Edit <i class="button__icon icon icon_edit"></i></span>
                                    </button>
                                </div>
                            </div>
                            <!--promo side desktop-->
                            <upload-presentation-media
                                :presentation-media="presentationMedias"
                                :member-i-d="chatOwner?chatOwner.id:null"
                                :my-url="myUrl"
                                :s3-url="s3Url"
                                :is-supported-webp="isSupportedWebp"
                                :role-member="currentUser.role"
                                :is-account=true
                                :type="'edit'"
                                :credits="chatOwner.credits"
                                :is-allowed-edit-woman="true"
                                :maxsize-video="maxsizeVideo"
                                :maxsize-image="maxsizeImage"
                                :background="profile?profile.background:'holder.jpg'"
                                :chat-owner="chatOwner"
                                :chat-owner-age="chatOwnerAge"
                                :country="country"
                                :current-user="currentUser"
                                @delete_promo_media="deletePromoMedia"
                                @add_presentation_media="addPresentationMedia"
                                @update_slogan="updateSlogan"
                                @show_chat_settings="toggleChat"
                                @errorhandler="errorHandler"
                                ref="uploadPromo"
                            ></upload-presentation-media>

                            <cropper-module
                                :s3-url="s3Url"
                                :my-url="myUrl"
                                :is-edit=true
                                :is-supported-webp="isSupportedWebp"
                                :img-gallery-webp="chatOwner.img_gallery_webp"
                                :img-gallery="chatOwner.img_gallery"
                                :member-i-d="chatOwner.id"
                                :member-role="currentUser.role"
                                v-if="$mq === 'desktop'"
                            >
                            </cropper-module>
                        </div>
                        <few-words-about
                            :my-url="myUrl"
                            :member="chatOwner"
                            :current-user="currentUser"
                            @open-modal-world="openModalWord"
                        ></few-words-about>

                        <my-self
                            :name="chatOwner?chatOwner.name:''"
                            :img-gallery="chatOwner.img_gallery"
                            :img-gallery-webp="chatOwner.img_gallery_webp"
                            :member-online="true"
                            :member-i-d="chatOwner.id"
                            :member-role="currentUser.role"
                            :member-color="chatOwner && chatOwner.color?chatOwner.color:'#df3535'"
                            :profile="profile"
                            :my-url="myUrl"
                            :s3-url="s3Url"
                            :is-edit=true
                            :country="country"
                            :is-supported-webp="isSupportedWebp"
                            :my-role="currentUser.role"
                            :interests="interestsSelected"
                            :birthday="birthday"
                            :get-zodiac-sign="getZodiacSign"
                            :show-badge-warning="showBadgeWarning"
                            @open_modal_myself_edit="openModalMyselfEdit"
                            @updated_avatar="setAvatar"
                            @open_modal_looking="openModalLooking"
                            @open_modal_interest="openModalInterest"
                        ></my-self>



                        <!--portfolio side desktop -->

                        <!--@TODO slider hooper styling -->
                        <upload-portfolio
                            :is-supported-webp="isSupportedWebp"
                            :maxsize-image="maxsizeImage"
                            :maxsize-video="maxsizeVideo"
                            :member-id="chatOwner.id"
                            :role-member="currentUser.role"
                            :s3-url="s3Url"
                            :my-url="myUrl"
                            :portfolios="portfolios"
                            :videos="videos"
                            :pack-medias="packMedias"
                            :pack-medias-i-d="packMediasID"
                            :is-edit=true
                            :is-account=true
                            @upload_video="addNewVideo"
                            @upload_photo="addNewPhoto"
                            @upload_pack="addNewPhotoPack"
                            @delete_video="deleteVideo"
                            @delete_photo="deletePhoto"
                            @delete_pack_media="deletePackMedia"
                            @errorhandler="errorHandler"
                        ></upload-portfolio>

                        <!--stories side desktop-->
                        <div class="profile__stories">
                            <button class="button button_bookmark">
                                <i class="button__icon icon icon_stories"></i>My stories
                            </button>
                            <button  class="button button_sm button_rounded button_border profile__add-stories"
                                     @click="addNewStory"><span>Add <i class="button__icon icon icon_edit"></i></span>
                            </button>
                            <my-stories-edit :my-role="currentUser.role"
                                             :member-i-d="chatOwner.id"
                                             :member-avatar="chatOwner.avatar"
                                             :member-name="chatOwner.name"
                                             :role-opponent="roleOpponent"
                                             :s3-url="s3Url"
                                             :my-url="myUrl"
                                             @close_stories="closeStories"
                                             @errorhandler="errorHandler"
                                             :is-supported-webp="isSupportedWebp"
                                             :is-edit=true
                                             :my-stories="myStories"
                                             @add_comments_to_story="addCommentsToStory"
                                             @add_more_comments_to_story="addMoreCommentsToStory"
                                             v-if="myStories && myStories.length"
                            ></my-stories-edit>
                        </div>
                    </div>
                    <!--  chat settings side desktop  -->
                    <div v-if="showChat" class="chat chat_settings" :class="{'is-absolute':isSeeFooter,'is-fixed':!isSeeFooter}">
                        <div class="chat__container"  ref="chatSettingsContainer">
                            <div class="chat__wrapper" :class="classBg">
                                <div class="chat__header">
                                    <div class="contact">
                                        <div class="contact__avatar">
                                            <picture v-if="chatOwner && chatOwner.avatar">
                                                <source type="image/webp" :srcset="`${s3Url}/${chatOwner.avatar_webp}`" v-if="chatOwner.avatar_webp">
                                                <img class="avatar avatar_md" :src="`${s3Url}/${chatOwner.avatar}`" alt="avatar user" width="70" height="70">
                                            </picture>
                                            <div class="avatar avatar_sm avatar_holder" v-else>
                                                <img class="avatar__image" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="65" height="65">
                                            </div>
                                        </div>
                                        <div class="contact__info">
                                             <span class="contact__title">
                                                <span class="contact__name">{{ memberName }},</span><span class="contact__age">{{ chatOwnerAge }}</span>
                                                </span>
                                            <span class="status status_online">
                                            <i class="status__icon"></i>
                                            <span>Online</span>
                                        </span>
                                        </div>
                                    </div>
                                    <div class="chat__actions">
                                        <button class="button button_icon chat__close" aria-label="Close" @click.prevent="closeChat"><i class="icon icon_close"></i></button>
                                    </div>
                                </div>


                                <div class="chat__body">
                                    <div class="chat__overlay" v-show="showBg">
                                        <button class="button button_icon chat__close" aria-label="Close" @click.prevent="toggleBg">
                                            <i class="icon icon_close"></i>
                                        </button>
                                        <!--            select background desktop                                      -->
                                        <div class="chat__list-bg">
                                            <span class="chat__bg chat__bg_default" @click="changeBg('bg-default')" :class="{'is-checked':this.classBg==='bg-default'}">
                                                <span class="chat__item-caption">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                        <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="chat__bg chat__bg_1" @click="changeBg('bg-1')" :class="{'is-checked':this.classBg==='bg-1'}">
                                                <span class="chat__item-caption">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                        <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                    </svg>
                                                </span>
                                             </span>
                                            <span class="chat__bg chat__bg_2" @click="changeBg('bg-2')" :class="{'is-checked':this.classBg==='bg-2'}">
                                                <span class="chat__item-caption">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                        <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="chat__bg chat__bg_3" @click="changeBg('bg-3')" :class="{'is-checked':this.classBg==='bg-3'}">
                                                <span class="chat__item-caption">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                        <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                            <span class="chat__bg chat__bg_4" @click="changeBg('bg-4')" :class="{'is-checked':this.classBg==='bg-4'}">
                                                    <span class="chat__item-caption">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                        <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                    </svg>
                                                    </span>
                                                </span>
                                            <span class="chat__bg chat__bg_5" @click="changeBg('bg-5')" :class="{'is-checked':this.classBg==='bg-5'}">
                                                    <span class="chat__item-caption">
                                                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                        <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                    </svg>
                                                    </span>
                                                </span>
                                            <span class="chat__bg chat__bg_6" @click="changeBg('bg-6')" :class="{'is-checked':this.classBg==='bg-6'}">
                                                <span class="chat__item-caption">
                                                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                        <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                    </svg>
                                                </span>
                                            </span>
                                        </div>
                                    </div>

                                    <ul class="chat__list">
                                        <li class="chat__item is-start">
                                            <!--                                            message_color1-->
                                            <div class="message message_base" :class="classMessageColor">
<!--                                                <svg class="message__triangle" xmlns="http://www.w3.org/2000/svg" width="12" height="20" fill="none">-->
<!--                                                    <path fill="currentColor" d="m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z"></path>-->
<!--                                                </svg>-->
                                                <span class="message__text" :style="`font-size: ${messageSizeRem}rem`">Choose the color of incoming and outgoing messages</span>
                                            </div>
                                        </li>
                                        <!--    desktop start block change color scheme-->
                                        <li class="chat__item chat__item_settings is-start">
                                            <div v-for="(color, index) in messageColors" :class="[classMessageColor === `message_color${index+1}` ? 'is-checked' : '', color, 'chat__color']"
                                                 :key="color" @click="setMessageColor(`message_color${index+1}`)">
                                                <span class="chat__item-caption">
                                                     <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                        <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                    </svg>
                                                </span>
                                            </div>
                                        </li>
                                        <li class="chat__item is-end">
                                            <!--                                            message_color1-->
                                            <div class="message message_base" :class="classMessageColor">
<!--                                                <svg class="message__triangle" xmlns="http://www.w3.org/2000/svg" width="12" height="20" fill="none">-->
<!--                                                    <path fill="currentColor" d="m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z"></path>-->
<!--                                                </svg>-->
                                                <span class="message__text" :style="`font-size: ${messageSizeRem}rem`">Change the text size</span>
                                            </div>
                                        </li>
                                        <!--                    changing text size-->

                                        <li class="chat__item chat__item_settings is-end">
                                            <label class="ui-range ui-range_sm ui-range_bg">
                                                <input class="ui-range__input" type="range" min="16" max="32" step="2" v-model="textValue" @input="updateTextSlider" :style="{backgroundSize: backgroundSize}">
                                            </label>
                                        </li>
                                        <li class="chat__item is-start">
                                            <!--                                            message_color1-->
                                            <div class="message message_base" :class="classMessageColor">
<!--                                                <svg class="message__triangle" xmlns="http://www.w3.org/2000/svg" width="12" height="20" fill="none">-->
<!--                                                    <path fill="currentColor" d="m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z"></path>-->
<!--                                                </svg>-->
                                                <span class="message__text" :style="`font-size: ${messageSizeRem}rem`">Select background in chat</span>
                                            </div>
                                        </li>
                                        <li class="chat__item chat__item_settings is-start">
                                            <button class="button button_md button_white" @click.prevent="toggleBg"><span>Select</span></button>
                                        </li>
                                    </ul>
                                </div>
                                <div class="chat__footer" v-if="isChatSettingChanged">
                                    <button class="button button_md button_primary" @click="saveChatSettings">
                                        <span>Apply</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--        modal A few words about yourself-->
                    <portal selector="#portal-target">
                        <div class="modal modal_md modal_account is-active"  tabindex="-1" role="dialog" v-if="modalWord">
                            <div class="modal__bg" @click.prevent="closeModalWord"></div>
                            <div class="modal__container">
                                <span class="modal__close" role="button" aria-label="Close" @click.prevent="closeModalWord"><i class="icon icon_close"></i></span>
                                <div class="modal__header">
                                    <h2 class="modal__title">A few words about yourself</h2>
                                </div>
                                <div class="form form_horizontal">
                                    <div class="form__row">
                                        <textarea class="ui-textarea" maxlength="1200" v-model="chatOwner[currentUser.role+'_profile'].slogan"></textarea>
                                    </div>
                                    <div class="form__footer">
                                        <button class="button button_md button_primary" @click.prevent="saveNewWord"><span>Save</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </portal>

                </div>
            </template>


            <template v-else>
<!--  Start  Mob block chat settings  -->
                <div v-if="showChat" class="chat chat_settings chat_mobile">
                    <div class="chat__container" >
                        <div class="chat__wrapper" :class="classBg">
                            <div class="chat__header">
                                <button class="button button_round button_back" @click="toggleChat">
                                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#back`" />
                                    </svg>
                                </button>
                                <div class="contact">
                                    <div class="contact__avatar">
                                        <picture v-if="chatOwner.avatar">
                                            <source type="image/webp" :srcset="`${s3Url}/${chatOwner.avatar_webp}`" v-if="chatOwner.avatar_webp">
                                            <img class="avatar avatar_md" :src="`${s3Url}/${chatOwner.avatar}`" alt="avatar user"
                                                 width="70" height="70">
                                        </picture>
                                        <div class="avatar avatar_md avatar_holder" v-else>
                                            <img class="avatar__image" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="70" height="70">
                                        </div>
                                    </div>
                                    <div class="contact__info">
                                        <span class="contact__title">
                                            <span class="contact__name">{{ memberName }}</span>
                                            <span class="contact__age">{{ chatOwnerAge }}</span>
                                        </span>
                                        <span class="status status_online">
                                            <i class="status__icon"></i>
                                             <span>Online</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="chat__body" :class="classBg">
                                <div class="chat__overlay" v-show="showBg">
                                    <button class="button button_icon chat__close" aria-label="Close" @click.prevent="toggleBg">
                                        <i class="icon icon_close"></i>
                                    </button>
                                    <div class="chat__list-bg">
                                        <span class="chat__bg chat__bg_default" @click="changeBg('bg-default')" :class="{'is-checked':this.classBg==='bg-default'}">
                                            <span class="chat__item-caption">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <span class="chat__bg chat__bg_1" @click="changeBg('bg-1')" :class="{'is-checked':this.classBg==='bg-1'}">
                                            <span class="chat__item-caption">
                                                           <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <span class="chat__bg chat__bg_2" @click="changeBg('bg-2')" :class="{'is-checked':this.classBg==='bg-2'}">
                                            <span class="chat__item-caption">
                                                           <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <span class="chat__bg chat__bg_3" @click="changeBg('bg-3')" :class="{'is-checked':this.classBg==='bg-3'}">
                                            <span class="chat__item-caption">
                                                           <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <span class="chat__bg chat__bg_4" @click="changeBg('bg-4')" :class="{'is-checked':this.classBg==='bg-4'}">
                                            <span class="chat__item-caption">
                                                           <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                        <span class="chat__bg chat__bg_5" @click="changeBg('bg-5')" :class="{'is-checked':this.classBg==='bg-5'}">
                                            <span class="chat__item-caption">
                                                           <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                </svg>
                                            </span>
                                         </span>
                                        <span class="chat__bg chat__bg_6" @click="changeBg('bg-6')" :class="{'is-checked':this.classBg==='bg-6'}">
                                            <span class="chat__item-caption">
                                                           <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                </svg>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <ul class="chat__list">
                                    <li class="chat__item is-start">
<!--                                        message_color1-->
                                        <div class="message message_base" :class="classMessageColor">
<!--                                            <svg class="message__triangle" xmlns="http://www.w3.org/2000/svg" width="12" height="20" fill="none">-->
<!--                                                <path fill="currentColor" d="m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z"/>-->
<!--                                            </svg>-->
                                            <span class="message__text" :style="`font-size: ${messageSizeRem}rem`">Choose the color of incoming and outgoing messages</span>
                                        </div>
                                    </li>
<!--    MOBIL start block change color scheme-->
                                    <li class="chat__item chat__item_settings is-start">
                                        <div v-for="(color, index) in messageColors" :class="[classMessageColor === `message_color${index+1}` ? 'is-checked' : '', color, 'chat__color']"
                                             :key="color" @click="setMessageColor(`message_color${index+1}`)" >
                                                <span class="chat__item-caption">
                                                               <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" fill="none" class="svg-icon">
                                                    <path fill="currentColor" d="m11.1599 2.11979-5.51998 7.2c-.24.24-.6.48-.96.48s-.72-.12-.96-.48l-2.88-3.72c-.36-.48-.36-1.32.24-1.68.6-.36 1.32-.36 1.68.24l1.92 2.52 4.56-6c.36-.48 1.19998-.6 1.67998-.24.6.36.6 1.08.24 1.68Z"></path>
                                                </svg>
                                                </span>
                                        </div>
                                    </li>
                                    <li class="chat__item is-end">
<!--                                        message_color1-->
                                        <div class="message message_base " :class="classMessageColor">
<!--                                            <svg class="message__triangle" xmlns="http://www.w3.org/2000/svg" width="12" height="20" fill="none">-->
<!--                                                <path fill="currentColor" d="m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z"/>-->
<!--                                            </svg>-->
                                            <span class="message__text" :style="`font-size: ${messageSizeRem}rem`">Change the text size</span>
                                        </div>
                                    </li>
                                    <li class="chat__item chat__item_settings is-end">
                                        <label class="ui-range ui-range_sm ui-range_bg">
                                            <input class="ui-range__input" type="range" min="16" max="32" step="2" v-model="textValue" @input="updateTextSlider" :style="{backgroundSize: backgroundSize}">
                                        </label>
                                    </li>
                                    <li class="chat__item is-start">
<!--                                        message_color1-->
                                        <div class="message message_base" :class="classMessageColor">
<!--                                            <svg class="message__triangle" xmlns="http://www.w3.org/2000/svg" width="12" height="20" fill="none">-->
<!--                                                <path fill="currentColor" d="m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z"/>-->
<!--                                            </svg>-->
                                            <span class="message__text" :style="`font-size: ${messageSizeRem}rem`">Select background in chat</span>
                                        </div>
                                    </li>
                                    <li class="chat__item chat__item_settings is-start">
                                        <button class="button button_md button_white" @click.prevent="toggleBg"><span>Select</span></button>
                                    </li>
                                </ul>
                            </div>
                            <div class="chat__footer" v-if="isChatSettingChanged">
                                <button class="button button_md button_primary" @click="saveChatSettings">
                                    <span>Apply</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
<!--  STOP  Mob block chat settings  -->
                <div class="page__header">
<!-- button go back MOB-->
                    <button class="button button_round button_back" @click.prevent="goBack">
                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                            <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#back`"/>
                        </svg>
                    </button>
                    <account-header-editor
                        :current-user="currentUser"
                        :chat-owner="chatOwner"
                        @open_modal_name="openModalName"
                        @open_modal_change_email="openModalChangeEmail"
                        @open_modal_change_password="openModalChangePassword"
                        @confirm_delete_account="openModalConfirmDeleteAccount"
                    ></account-header-editor>
                </div>
                <div class="profile profile_account profile_mobile">

                    <div class="profile__container">
<!-- MOBIL upload-presentation-media -->
                        <upload-presentation-media
                            :presentation-media="presentationMedias"
                            :member-i-d="chatOwner?chatOwner.id:null"
                            :my-url="myUrl"
                            :s3-url="s3Url"
                            :is-supported-webp="isSupportedWebp"
                            :role-member="currentUser.role"
                            :is-account=true
                            :type="'edit'"
                            :credits="chatOwner.credits"
                            :is-allowed-edit-woman="true"
                            :maxsize-video="maxsizeVideo"
                            :maxsize-image="maxsizeImage"
                            :background="profile?profile.background:'holder.jpg'"
                            @delete_promo_media="deletePromoMedia"
                            @add_presentation_media="addPresentationMedia"
                            :chat-owner="chatOwner"
                            :chat-owner-age="chatOwnerAge"
                            :country="country"
                            :current-user="currentUser"
                            @update_slogan="updateSlogan"
                            :member-name="memberName"
                            @show_chat_settings="toggleChat"
                        ></upload-presentation-media>
        <!--mobil -->
                        <my-self
                            :name="chatOwner?chatOwner.name:''"
                            :img-gallery="chatOwner.img_gallery"
                            :img-gallery-webp="chatOwner.img_gallery_webp"
                            :member-online="true"
                            :member-i-d="chatOwner.id"
                            :member-role="currentUser.role"
                            :member-color="chatOwner && chatOwner.color?chatOwner.color:'#df3535'"
                            :profile="profile"
                            :my-url="myUrl"
                            :s3-url="s3Url"
                            :is-edit=true
                            :country="country"
                            :is-supported-webp="isSupportedWebp"
                            :interests="interestsSelected"
                            :birthday="birthday"
                            :get-zodiac-sign="getZodiacSign"
                            :show-badge-warning="showBadgeWarning"
                            @open_modal_myself_edit="openModalMyselfEdit"
                            @updated_avatar="setAvatar"
                            @open_modal_looking="openModalLooking"
                            @open_modal_interest="openModalInterest"

                        ></my-self>


                            <!--@TODO slider hooper styling -->
                        <upload-portfolio
                            :is-supported-webp="isSupportedWebp"
                            :maxsize-image="maxsizeImage"
                            :maxsize-video="maxsizeVideo"
                            :member-id="chatOwner.id"
                            :role-member="currentUser.role"
                            :s3-url="s3Url"
                            :my-url="myUrl"
                            :portfolios="portfolios"
                            :videos="videos"
                            :pack-medias="packMedias"
                            :pack-medias-i-d="packMediasID"
                            :is-edit=true
                            :is-account=true
                            @upload_video="addNewVideo"
                            @upload_photo="addNewPhoto"
                            @upload_pack="addNewPhotoPack"
                            @delete_video="deleteVideo"
                            @delete_photo="deletePhoto"
                            @delete_pack_media="deletePackMedia"
                            @errorhandler="errorHandler"
                        ></upload-portfolio>

                        <div class="profile__stories">
                            <button class="button button_bookmark">
                                <i class="button__icon icon icon_stories"></i>My stories
                            </button>
                            <button  class="button button_sm button_rounded button_border profile__add-stories"
                                    @click="addNewStory"><span>Add <i class="button__icon icon icon_edit"></i></span>
                            </button>
                            <my-stories-edit :my-role="currentUser.role"
                                             :member-i-d="chatOwner.id"
                                             :member-avatar="chatOwner.avatar"
                                             :member-name="chatOwner.name"
                                             :role-opponent="roleOpponent"
                                             :s3-url="s3Url"
                                             :my-url="myUrl"
                                             @close_stories="closeStories"
                                             @errorhandler="errorHandler"
                                             :is-supported-webp="isSupportedWebp"
                                             :is-edit=true
                                             :my-stories="myStories"
                                             @add_comments_to_story="addCommentsToStory"
                                             @add_more_comments_to_story="addMoreCommentsToStory"
                                             v-if="myStories && myStories.length"
                            ></my-stories-edit>
                        </div>
        <!--mobil -->

                    </div>
                </div>
            </template>
        </div>

<!--        modal edit user name-->
        <portal selector="#portal-target" v-if="modalName">
            <div class="modal modal_md modal_account" :class="{'is-active' : modalName}" tabindex="-1" role="dialog">
            <div class="modal__bg" @click="closeModalName"></div>
            <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close" @click="closeModalName"><i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h2 class="modal__title">My Name</h2>
                </div>
                <div class="form form_horizontal">
                    <div class="form__row">
                        <input type="text" class="ui-input" maxlength="50" value="" v-model="memberName">
                    </div>
                    <div class="form__footer">
                        <button class="button button_md button_primary" @click.prevent="saveNewName"><span>Save</span></button>
                    </div>
                </div>
            </div>
        </div>
        </portal>
<!--        modal About myself-->
        <portal selector="#portal-target" v-if="modalMySelf">
            <div class="modal modal_md modal_about modal_account" :class="{'is-active' : modalMySelf}"
                tabindex="-1" role="dialog" @click="clickModalEdit">
            <div class="modal__bg" @click="closeModalMyself"></div>
            <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close" @click="closeModalMyself"><i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h2 class="modal__title">About myself</h2>
                </div>
                <my-self-edit ref="mySelfEdit"
                              :disabled-button="disabledButton"
                              :profile-init="profile"
                              :countries="countries"
                              :window-height="windowHeight"
                              @save_my_self="saveMySelf"
                              @close_my_self_edit="closeModalMyself"
                              :chat-owner="chatOwner"
                ></my-self-edit>
            </div>
        </div>
        </portal>
<!--        MODAL i am looking for -->
        <portal selector="#portal-target" v-if="modalLooking">
        <div class="modal modal_md modal_account" :class="{'is-active' : modalLooking}" tabindex="-1" role="dialog">
            <div class="modal__bg" @click="closeModalLooking"></div>
            <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close"
                      @click="closeModalLooking"><i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h2 class="modal__title">I'm looking for...</h2>
                </div>
                <div class="form form_horizontal">
                    <div class="form__row">
                        <textarea class="ui-textarea" maxlength="1200" v-model="profile.preference"></textarea>
                    </div>
                    <div class="form__footer">
                        <button class="button button_md button_primary" @click.prevent="saveLookingFor"><span>Save</span></button>
                    </div>
                </div>
            </div>
        </div>
        </portal>
<!--        modal my interests-->
        <portal selector="#portal-target" v-if="modalInterest">
            <div class="modal modal_md modal_account modal_interests" :class="{'is-active' : modalInterest}" tabindex="-1" role="dialog">
                <div class="modal__bg" @click="closeModalInterest"></div>
                <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close"
                      @click="closeModalInterest"><i class="icon icon_close"></i></span>
                    <div class="modal__header">
                        <h2 class="modal__heading">My interests</h2>
                    </div>
                    <div class="modal__body">
                        <ul class="list list_hobbies">
                            <li class="list__item" v-for="interest in interests">
                                <label class="custom-checkbox">
                                    <input type="checkbox" class="custom-checkbox__input" v-model="interest.isSelected">
                                    <span class="custom-checkbox__label icon-box">
                                            <i class="icon icon_hobbies" :class="interest.img"></i>
                                        <span class="icon-box__title">{{interest.title}}</span>
                                    </span>
                                </label>
                            </li>
                        </ul>
                    </div>
                    <div class="modal__footer">
                        <button class="button button_md button_primary" @click.prevent="saveInterests"><span>Save</span></button>
                    </div>
                </div>
            </div>
        </portal>
<!--        modal edit user email-->
        <portal selector="#portal-target" v-if="modalEmail">
            <div class="modal modal_md modal_account" :class="{'is-active' : modalEmail}" tabindex="-1" role="dialog">
                <div class="modal__bg" @click="closeModalChangeEmail"></div>
                <div class="modal__container">
                    <span class="modal__close" role="button" aria-label="Close" @click="closeModalChangeEmail">
                        <i class="icon icon_close"></i></span>
                    <div class="modal__header">
                        <h2 class="modal__title">My Email</h2>
                    </div>
                    <div class="form form_horizontal">
                        <label class="text">enter new email: <label style="color: red">{{errEmailMassage}}</label></label>
                        <div class="form__row">
                            <input type="text" class="ui-input" placeholder="new email" maxlength="50" v-model="newEmail">
                        </div>
                        <label class="text">enter your password: <label style="color: red">{{errPassMassage}}</label></label>
                        <div class="form__row">
                            <input type="text" class="ui-input" maxlength="50" placeholder="password" v-model="password">
                        </div>
                        <div class="form__footer">
                            <button v-if="activeApply" class="button button_md button_primary" @click.prevent="saveNewEmail">
                                <span>Save</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </portal>
<!--        modal edit user password-->
        <portal selector="#portal-target" v-if="modalPassword">
            <div class="modal modal_md modal_account" :class="{'is-active' : modalPassword}" tabindex="-1" role="dialog">
                <div class="modal__bg" @click="closeModalChangePassword"></div>
                <div class="modal__container">
                    <span class="modal__close" role="button" aria-label="Close" @click="closeModalChangePassword">
                        <i class="icon icon_close"></i></span>
                    <div class="modal__header">
                        <h2 class="modal__title">My New Password</h2>
                    </div>
                    <div class="form form_horizontal">
                        <label class="text">enter new password: <label style="color: red">{{ errOldPassMassage }}</label></label>
                        <div class="form__row">
                            <input type="text" placeholder="minimum 8 characters" class="ui-input" minlength="8" maxlength="50" v-model="newPassword">
                        </div>
                        <label class="text">enter old password: <label style="color: red">{{ errPassMassage }}</label></label>
                        <div class="form__row">
                            <input type="text" placeholder="minimum 8 characters" class="ui-input" minlength="8" maxlength="50" v-model="password">
                        </div>
                        <div class="form__footer">
                            <button v-if="activeApplySavePassword" class="button button_md button_primary" @click.prevent="saveNewPassword"><span>Save</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </portal>
<!--        modal edit user password-->
        <portal selector="#portal-target" v-if="modalConfirmDelete">
            <div class="modal modal_md modal_account" :class="{'is-active' : modalConfirmDelete}" tabindex="-1" role="dialog">
                <div class="modal__bg" @click="closeModalConfirmDeleteAccount"></div>
                <div class="modal__container">
                    <span class="modal__close" role="button" aria-label="Close" @click="closeModalConfirmDeleteAccount">
                        <i class="icon icon_close"></i></span>
                    <div class="modal__header">
                        <h3 class="modal__title">Сonfirm you want to delete account</h3>
                    </div>
                    <div class="form form_horizontal">
                        <div class="form__footer">
                            <button class="button button_md button_primary" @click.prevent="confirmDeleteAccount"><span>Confirm</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </portal>

    </div>

</template>

<script>
import MySelf from "./ProfileItem/MySelf";
import MySelfEdit from "./ProfileItem/MySelfEdit";
import UploadPortfolio from "./ProfileItem/UploadPortfolio";
import UploadPresentationMedia from "./ProfileItem/UploadPresentationMedia";
import MyStoriesEdit from "./ProfileItem/MyStoriesEdit";
import BaseLogo from "./newModules/BaseLogo.vue";
import AccountHeaderEditor from "./ProfileItem/AccountHeaderEditor.vue";
import ShowStories from "./ProfileItem/ShowStories.vue";
import FewWordsAbout from "./newModules/FewWordsAbout.vue";
import CropperModule from "./newModules/CropperModule.vue";
let footerObserver = null;
let footer = null;
export default {
    name: "Account",
    components: {
        CropperModule,
        FewWordsAbout,
        ShowStories,
        AccountHeaderEditor,
        BaseLogo,
        MyStoriesEdit,
        UploadPresentationMedia,
        UploadPortfolio,
        MySelfEdit,
        MySelf,
    },
    props: [
        's3Url',
        'myUrl',
        'currentUser',
        'chatOwner',
        'countries',
        'settingsInit',
        'roleOpponent',
        'isOpenStories',
        'windowHeight',
        'countUnread',
        'showNotificationButton',
        'onSubscribeNotification',
        'disabledNotification',
        'holidayPromotionName',
        'isSupportedWebp',
    ],
    data(){
        return {
            maxsizeImage: 10*1024*1024,
            maxsizeVideo: 20*1024*1024,
            presentationVideo:null,
            member:null,
            myInterestIDs:[],
            interests:[],
            presentationMedias:[],
            packMedias:[],
            packMediasID:null,
            formData:new FormData(),
            portfolios:[],
            videos:[],
            country:null,
            // selectInterests:{},
            memberName:'',
            mySelf:null,
            previewAvatar: null,
            disabledButton:false,
            // previewAvatar: 'https://lifeforlove.test/storage/old/Images/590/mini/3852.jpg',
            // previewPortfolioImages: [],
            // previewPortfolioVideos: [],
            previewPortfolioPack: [],
            modalMySelf: false,
            modalLooking: false,
            modalInterest: false,
            modalName: false,
            modalWord: false,
            showBg: false,
            isExtendStories: false,
            classBg:'bg-default',
            classMessageColor: 'message_color1',
            messageColors: ['chat__color_1','chat__color_2','chat__color_3','chat__color_4','chat__color_5','chat__color_6','chat__color_7','chat__color_8'],
            showChat: false,
            isSeeFooter:false,
            showStories: false,
            textValue: 18,
            backgroundSize: '10% 100%',
            profile:{
                age:null,
                live_in:null,
                education:null,
                lang:null,
                marital_status:null,
                kids:null,
                smoke:null,
                drink:null,
                height:null,
                weight:null,
                slogan:null,
                preference:null,
                body_type:null,
                eye_color:null,
                hair_color:null,
                work:null,
                preference_age_from:null,
                preference_age_to:null,
                preference_person:null
            },
            modalNotify: false,
            offNotify: false,
            modalEmail:false,
            modalPassword:false,
            modalConfirmDelete:false,
            birthday: null,
            newEmail: '',
            password: '',
            errEmailMassage: '',
            errPassMassage: '',
            errOldPassMassage: '',
            newPassword: '',
            myStories: [],
            modalCropper: false,
        }
    },
    computed:{
        showBadgeWarning(){
            return !this.chatOwner.country_id || !this.chatOwner.birthday
        },
        messageSizeRem(){
            const windowWidth = window.innerWidth || document.documentElement.clientWidth;
            if( windowWidth >= 1920){
                return this.textValue/14;
            }

            return this.textValue/16;
        },
        getZodiacSign(){
            let zodiacSign = '';
            if (this.birthday) {
                let birthday = new Date(this.birthday);
                const zodiac =['', 'Capricorn', 'Aquarius', 'Pisces', 'Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn'];
                const last_day =[0, 20, 19, 21, 21, 22, 22, 23, 23, 22, 23, 22, 21, 20];
                let month = birthday.getMonth()+1;
                let day = birthday.getDate();
                zodiacSign = (Number(day) < last_day[month]) ? zodiac[month] : zodiac[month+1];
            }
            return zodiacSign
        },
        activeApplySavePassword(){
            let pass = this.password.replace(/\s/g, '')
            let newPass = this.newPassword.replace(/\s/g, '')
            return !(pass.length < 8 || newPass.length < 8);
        },
        activeApply(){
            return !(!this.newEmail.replace(/\s/g, '') || !this.password.replace(/\s/g, ''));
        },
        chatOwnerAge(){
            if(!this.currentUser || !this.chatOwner || !this.chatOwner[`${this.currentUser.role}_profile`]) return '_'
            if(this.chatOwner.birthday){
                // let diff = new Date()-new Date(birthday); // This is the difference in milliseconds.
                return  Math.floor((new Date()-new Date(this.chatOwner.birthday))/31557600000); // Divide by 1000*60*60*24*365.25
            }
            return this.chatOwner[`${this.currentUser.role}_profile`].age;
        },
        isChatSettingChanged(){
            return  !(this.classBg === this.settingsInit.chat_background &&
                this.classMessageColor === this.settingsInit.message_color &&
                +this.textValue === +this.settingsInit.message_size)
        },
        getImageGallery(){
            return this.member?this.member.img_gallery:null;
        },
        interestsSelected(){
            return this.interests.filter(interest=>{
                return interest.isSelected;
            })
        }
    },
    created() {
        if (!this.currentUser){
            this.$router.push({path: '/'});
        }else if (!this.currentUser.role){
            this.$router.push({path: '/success-registration'});
        }else if (this.currentUser.role==='operator' || this.currentUser.role==='admin_operator'){
            window.location.href = this.myUrl;
        }
    },
    mounted() {
        this.$bus.$emit('close_modal');
        this.$bus.$on('open_modal_change_email',this.openModalChangeEmail);
        this.$bus.$on('close_options_menu',this.closeOptionsMenu);
        this.initProfile();
        this.memberName = this.chatOwner.name;
        console.log('Account mounted ...');
        window.scrollTo(0, 0);
        if (this.chatOwner) this.initBirthday();
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal');
        this.$bus.$off('open_modal_change_email',this.openModalChangeEmail);
        this.$bus.$off('close_options_menu',this.closeOptionsMenu);
    },

    methods: {
        closeModal(){
            this.$bus.$emit('close_modal');
        },
        clickPage(){
            // if(this.$mq === 'desktop') this.$bus.$emit('close_side_bar');
            if(this.$mq === 'desktop') this.$bus.$emit('close_nav_menu');
        },
        closeOptionsMenu(){
            if( this.$refs.accountHeaderEditor ) this.$refs.accountHeaderEditor.closeEditor();
        },
        clickModalEdit(){
            if( this.$refs.mySelfEdit && this.$refs.mySelfEdit.$refs.selectCountry) this.$refs.mySelfEdit.$refs.selectCountry.closeOptions();
        },
        addMoreCommentsToStory(data){
            console.log('addMoreCommentsToStory')
            this.myStories.forEach(story => {
                if (+story.id === +data.story_id){
                    story.viewComment = false;
                    story.isAllComments = data.comments.length < 3;
                    data.comments.forEach(new_comment => {
                        story.story_comments.push(new_comment)
                    })
                    story.viewComment = true;
                }
            })
        },
        addCommentsToStory(data){
            console.log('addCommentsToStory')
            console.log(data)
            this.myStories.forEach(story => {
                if (+story.id === +data.story_id){
                    story.isAllComments = data.comments.length < 3;
                    story.story_comments = []
                    data.comments.forEach(new_comment => {
                        story.story_comments.push(new_comment)
                    })
                    story.viewComment = true;
                }
            })
        },
        setMessageColor(classMessageColor){
            console.log(classMessageColor);
            this.classMessageColor = classMessageColor;
        },
        setFooterObserver(){
            if(this.$mq!=='desktop') return;
            console.log('setFooterObserver account')
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const chatContainer = this.$refs.chatSettingsContainer;
            const parentContainer = this.$refs.parentSettingsContainer;
            if(!chatContainer || !parentContainer) {
                console.warn('!chatContainer')
                return
            }
            footerObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {

                        if (entry.isIntersecting) {
                            console.log('FooterObserver isIntersecting')
                            this.isSeeFooter = true;

                            if (chatContainer && windowHeight > parentContainer.offsetHeight) {
                                console.log('if')
                                const offset = 86;
                                chatContainer.style.top = `-${offset}px`;
                                chatContainer.style.height = `${ parentContainer.offsetHeight + offset }px`;
                            } else if (chatContainer) {
                                console.log('else')
                                chatContainer.style.height = `${ windowHeight }px`;
                            }

                        } else {
                            if (chatContainer) chatContainer.style = '';

                            this.isSeeFooter = false;
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.0,
                },
            );
            footer = document.getElementById('footer')
            if (footer) footerObserver.observe(footer);
        },
        saveNewPassword(){
            console.log(this.password)
            console.log(this.newPassword)
        //     TODO change password
            axios.post(`/change-user-password`,{new_password: this.newPassword, password: this.password})
                .then(response => {
                    console.log(response.data)
                    if (response.data.status === 'ok'){
                        this.modalPassword = false
                        this.$bus.$emit('close_modal');
                        this.clearInput()
                        this.clearErrMessages()
                        // alert('Password updated. Please remember it!');
                    }else {
                        // save message...
                        this.errPassMassage = response.data.errPassMassage
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },

        checkEmail(){
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const re2 = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
            return re.test(String (this.newEmail).toLowerCase()) && re2.test(String (this.newEmail).toLowerCase())
        },
        initBirthday(){
            this.birthday = this.chatOwner.birthday
        },
        confirmDeleteAccount(){
            this.modalConfirmDelete = false
            this.$bus.$emit('close_modal');
        //     TODO open support chat with message
            this.$bus.$emit('open_support_chat', 'I want to delete my account!')
        },
        closeModalConfirmDeleteAccount(){
            this.modalConfirmDelete = false
            this.$bus.$emit('close_modal');
        },
        openModalConfirmDeleteAccount(){
            this.modalConfirmDelete = true
            this.$bus.$emit('open_modal');
        },
        openModalChangePassword(){
            this.modalPassword = true
            this.$bus.$emit('open_modal');
        },

        closeModalChangePassword(){
            this.modalPassword = false
            this.$bus.$emit('close_modal');
        },

        openModalChangeEmail(){
            console.log('openModalChangeEmail')
            this.modalEmail = true
            this.$bus.$emit('open_modal');
        },
        clearErrMessages(){
            this.errEmailMassage = ''
            this.errPassMassage = ''
            this.errOldPassMassage = ''
        },
        clearInput(){
            this.newEmail = ''
            this.password = ''
            this.newPassword = ''
        },
        closeModalChangeEmail(){
            console.log('openModalChangeEmail')
            this.modalEmail = false
            this.$bus.$emit('close_modal');
            this.clearInput();
        },
        saveNewEmail(){
            console.log('saveNewEmail')
            this.newEmail = this.newEmail.replace(/\s/g,'')
            if (this.checkEmail(this.newEmail)){
                console.log('continue changing...')
                axios.post(`/change-user-email`,{new_email: this.newEmail, password: this.password})
                    .then(response => {
                        console.log(response.data)
                        if (response.data.status === 'ok'){
                            this.modalEmail = false
                            this.$bus.$emit('close_modal');
                            this.clearInput()
                            this.clearErrMessages()
                            this.$emit('update_current_user', response.data.user)
                            if(response.data.credits){
                                this.$bus.$emit('new_balance',response.data.credits);
                            }
                            // alert('Email updated. Remember your new email');
                        }else {
                            // save message...
                            this.errEmailMassage = response.data.errEmailMassage
                            this.errPassMassage = response.data.errPassMassage
                        }
                    })
                    .catch(e => {
                        this.disabledButton = false;
                        console.log(e);
                        this.errorHandler(e);
                    })
            }else {
                this.errEmailMassage = 'Email incorrect!'
            }
        },

        updateSlogan(slogan){
            this.profile.slogan = slogan
            this.updateProfile();
        },
        // TestCG(){
        //     console.log('TestCG');
        //     axios.get(`/get-url-payment-form-cg/300`)
        //         .then(response => {
        //             console.log(response.data);
        //             if (response.data.bodyForm){
        //                 window.location.href = response.data.bodyForm.forwardUrl;
        //             }
        //         })
        //         .catch(e => {
        //             console.log(e);
        //             this.errorHandler(e);
        //         })
        // },

        // unsubsribeNotify() {
        //     this.modalNotify = false
        //     this.$bus.$emit('unsubsribe_notify')
        // },
        // subscribeEmailNotifications(status){
        //     if (this.settingsInit && !this.disabledButton){
        //         this.disabledButton = true;
        //         axios.post(`/subscribe-notifications`,{
        //             status:status,
        //             settingID:this.settingsInit.id
        //         })
        //             .then(response => {
        //                 this.disabledButton = false;
        //                 console.log(response.data);
        //                 this.$bus.$emit('change_setting',response.data.settings)
        //             })
        //             .catch(e => {
        //                 this.disabledButton = false;
        //                 console.log(e);
        //                 this.errorHandler(e);
        //             })
        //     }
        //
        // },
        goToRequest(arg) {
            this.$emit('go_to_request',arg);
        },
        openMenu(arg) {
            this.$emit('open_menu', arg);
        },
        addNewStory(){
            this.$router.push({name: 'CreateMyStory'})
        },
        closeStories() {
            console.log('closeStories');
            this.showStories = false
            this.updateSliderPromo()
            if (this.$mq !== 'desktop') {
                document.documentElement.classList.remove('no-scroll')
            }
            // this.isExtendStories = false
            // this.$refs.profile.removeAttribute('style')
        },
        shortName(name){
            let arr = name.split(' ');
            let rez = '';
            arr.forEach(item=>{
                rez += item.charAt(0).toUpperCase();
            })
            return rez;
        },
        getOnlyParams(obj){
            // return obj;
            const resArr = {}
            for (let key in obj){
                if(obj[key]){
                    resArr[key] = obj[key];
                }else {
                    resArr[key] = null;
                }
            }

            return resArr;
        },
        addPresentationMedia(media){
            this.presentationMedias.unshift(media);
        },
        deletePromoMedia(index){
            this.presentationMedias.splice(index,1);
        },
        deletePackMedia(index){
            this.packMedias.splice(index,1);
        },
        addNewPhotoPack(src,id){
            this.packMedias.unshift(src);
            this.packMediasID = id;
        },
        addNewVideo(video){
            this.videos.unshift(video);
        },
        addNewPhoto(photo){
            this.portfolios.unshift(photo);
        },
        deleteVideo(index){
            this.videos.splice(index,1);
        },
        deletePhoto(index){
            this.portfolios.splice(index,1);
        },
        initProfile(){
            console.log('initProfile');
            axios.get(`/init-my-profile/${this.currentUser.role}/${this.chatOwner.id}`)
                .then(response => {
                    this.initProfileHandel(response.data);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        initProfileHandel(data){
            console.log(data);
            this.classBg = data.settings.chat_background;
            this.classMessageColor = data.settings.message_color;
            this.textValue = data.settings.message_size;
            // min="16" max="32"
            this.backgroundSize = (data.settings.message_size - 16) * 100 / (16) + '% 100%';
            this.myInterestIDs = data.myInterestIDs;
            this.interests = data.interests;
            this.interests.forEach(interest=>{
                this.$set(interest,'isSelected',this.myInterestIDs.includes(interest.id));
            });
            ({...this.profile} = data.profile );
            if ('birthday' in this.profile) delete this.profile.birthday
            this.portfolios = data.portfolios;
            this.videos = data.videos;
            this.presentationMedias = data.presentationMedias;
            if (data.packMedia){
                this.packMedias = data.packMedia.medias;
                this.packMediasID = data.packMedia.id;
            }
            if(this.chatOwner.country_id){
                this.country = this.countries.find(country=>{
                    return +this.chatOwner.country_id === +country.id
                })
            }
            this.myStories = data.myStories
            this.myStories.forEach( story => {
                this.$set(story,'viewComment',false);
            })
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        saveMySelf(profile, country){
            console.log('saveMySelf');
            console.log(profile);
            this.country = country;
            this.birthday = profile.birthday;
            ({...this.profile} = profile);
            this.updateProfile();
        },
        updateProfile(){
            if(this.chatOwner){
                this.disabledButton = true;
                delete this.profile.created_at;
                delete this.profile.updated_at;
                delete this.profile.id;
                delete this.profile[`${this.currentUser.role}_id`];
                // this.profile.live_in = this.country ? this.country.countryName:null;
                axios.post(`/update_member_profile`,this.getProfileData())
                    .then(response => {
                        this.disabledButton = false;
                        console.log(response.data);
                        if (response.data.status === 'ok'){
                            this.$emit('update_member_profile', response.data.member);
                            ({...this.profile} = response.data.profile);
                            if ('birthday' in this.profile) delete this.profile.birthday
                            // alert('Profile updated');
                        }
                    })
                    .catch(e => {
                        this.disabledButton = false;
                        console.log(e);
                        this.errorHandler(e);
                    })
            }else {
                alert('You need to create a profile');
            }
        },
        getProfileData(){
            console.log('getProfileData')
            const formData = new FormData();
            formData.append('name', this.memberName);
            formData.append('memberID',this.chatOwner.id);
            formData.append('role',this.currentUser.role);
            formData.append('countryID', (this.country?this.country.id:null));
            formData.append('profile', JSON.stringify(this.getOnlyParams(this.profile)));
            formData.append('birthday',this.birthday);
            return formData;
        },
        openModalMyselfEdit(){
            this.modalMySelf = true
            this.$bus.$emit('open_modal');
        },
        setAvatar(member){
            console.log('setAvatar');
            this.$bus.$emit('update_avatar',member);
        },
        getMemberData(){
            axios.get(`/get-member-data/${this.currentUser.role}/${this.chatOwner.id}`)
                .then(response => {
                    console.log(response.data);
                    this.portfolios = response.data.portfolios;
                    this.videos = response.data.videos;
                    this.presentationMedia = response.data.presentationMedias;
                    ({...this.profile}=response.data.profile);
                    if (this.profile.birthday) delete this.profile.birthday
                    let myInterestID = [];
                    if (response.data.myInterest.length){
                        myInterestID = response.data.myInterest.map(interest=>{
                            return interest.id;
                        })
                    }
                    this.interests.forEach(interest=>{
                        this.$set(interest,'isSelected',myInterestID.includes(interest.id));
                    });
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        // changeMessageColor(color){
        //     console.log('changeMessageColor')
        //     this.classMessageColor = color
        //     // console.log(event)
        //     // this.classMessageColor = event.target.closest("li").getAttribute('data-color')
        //     // console.log(this.classMessageColor);
        //     // const active = this.$refs.colorList.querySelector('.is-checked');
        //     // if(active){
        //     //     active.classList.remove('is-checked');
        //     // }
        //     // event.target.offsetParent.classList.toggle('is-checked');
        // },
        updateTextSlider(event){
            console.log('updateTextSlider');
            console.log(this.textValue);
            let clickedElement = event.target,
                min = clickedElement.min,
                max = clickedElement.max,
                val = clickedElement.value;
            this.backgroundSize = (val - min) * 100 / (max - min) + '% 100%';

        },
        updateSliderPromo() {
            setTimeout(()=> {
                if(this.$refs.uploadPromo) this.$refs.uploadPromo.updateSliderPromo()
            }, 50)
        },
        closeChat(){
            this.showChat = false
            this.updateSliderPromo()
            //TODO check
            // if (this.$mq === 'desktop') {
            //     let footer = document.querySelector('footer')
            //     footer.classList.remove('is-chat-open')
            // } else {
            //     document.documentElement.classList.remove('no-scroll')
            // }
            if (footerObserver && footer){
                footerObserver.unobserve(footer)
                footerObserver = null;
                this.isSeeFooter = false;
            }
        },
        toggleChat(){
            this.showChat = !this.showChat
            this.updateSliderPromo()
            if(this.showChat){

                if (this.$mq === 'desktop'){
                    setTimeout(()=>{
                        this.setFooterObserver()
                    },10)
                }
                if (this.$mq === 'mobile'){
                    this.$bus.$emit('add_no_scroll');
                }
                //TODO check
                // if (this.$mq === 'desktop') {
                //     let footer = document.querySelector('footer')
                //     footer.classList.add('is-chat-open')
                // } else {
                //     document.documentElement.classList.add('no-scroll')
                // }
            } else {
                if (footerObserver && footer){
                    footerObserver.unobserve(footer)
                    footerObserver = null;
                    this.isSeeFooter = false;
                }
                if (this.$mq === 'mobile'){
                    this.$bus.$emit('remove_no_scroll');
                }
                //TODO check
                // if (this.$mq === 'desktop') {
                //     let footer = document.querySelector('footer')
                //     footer.classList.remove('is-chat-open')
                // } else {
                //     document.documentElement.classList.remove('no-scroll')
                // }
            }
        },
        toggleBg(){
            this.showBg = !this.showBg
        },
        changeBg(classBg){
            console.log('changeBg: '+ classBg);
            // this.classBg = event.target.offsetParent.getAttribute('data-bg');
            // console.log(this.classBg);
            // const active = this.$refs.bgList.querySelector('.is-checked');
            // if(active){
            //     active.classList.remove('is-checked');
            // }
            // event.target.offsetParent.classList.toggle('is-checked')
            this.classBg = classBg
            this.showBg = false;
        },
        saveChatSettings(){
            if(this.chatOwner){
                axios.post(`/save-chat-settings`,{
                    role:this.currentUser.role,
                    memberID:this.chatOwner.id,
                    messageSize:this.textValue,
                    messageColor:this.classMessageColor,
                    chatBg:this.classBg
                })
                    .then(response => {
                        console.log(response.data);
                        if (response.data.status === 'ok'){
                            this.$bus.$emit('change_setting',response.data.settings);
                            // alert('Chat settings saved')
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.errorHandler(e);
                    })
            }else {
                alert('You need to create a profile');
            }

        },
        closeModalMyself(){
            this.modalMySelf = false
            // document.body.classList.remove("modal-open");
            this.$bus.$emit('close_modal');
            // this.updateProfile();
        },
        openModalLooking() {
            this.modalLooking = true
            this.$bus.$emit('open_modal');
        },
        closeModalLooking(){
            this.modalLooking = false;
            this.$bus.$emit('close_modal');
        },
        saveLookingFor(){
            this.modalLooking = false;
            this.$bus.$emit('close_modal');
            this.updateProfile();
        },
        openModalInterest() {
            this.modalInterest = true
            this.$bus.$emit('open_modal');
        },
        closeModalInterest(){
            this.modalInterest = false;
            this.$bus.$emit('close_modal');
        },
        saveInterests(){
            this.modalInterest = false;
            this.$bus.$emit('close_modal');
            this.updateInterest();
        },
        getInterestsID(){
            return this.interests.filter(interest=>{
                return interest.isSelected;
            })
                .map(interest=>{
                    return interest.id;
                });
        },
        updateInterest(){
            if(this.chatOwner){
                this.disabledButton = true;
                const interestIDs = this.getInterestsID();
                console.log(interestIDs);
                const formData = new FormData();
                formData.append('role', this.currentUser.role);
                formData.append('memberID',this.chatOwner.id);
                formData.append('interestIDs', JSON.stringify(interestIDs));
                axios.post(`/update_interests-member`,formData)
                    .then(response => {
                        this.disabledButton = false;
                        console.log(response.data);
                        if (response.data.status === 'ok'){
                            // alert('Interests updated');
                        }
                    })
                    .catch(e => {
                        this.disabledButton = false;
                        console.log(e);
                        this.errorHandler(e);
                    })
            }else {
                alert('You need to create a profile');
            }

        },
        openModalName() {
            this.modalName = true
            this.$bus.$emit('open_modal');
        },
        closeModalName(){
            this.modalName = false;
            this.$bus.$emit('close_modal');
        },
        saveNewName(){
            this.modalName = false;
            this.$bus.$emit('close_modal');
            this.updateProfile();
        },
        goGallery(){
            this.$router.push({path: '/gallery'});
        },
        goBack(){
            this.$router.back();
        },
        openModalWord() {
            this.modalWord = true
            this.$bus.$emit('open_modal')
        },
        closeModalWord(){
            this.modalWord = false;
            this.$bus.$emit('close_modal')
        },
        saveNewWord(){
            this.modalWord = false;
            this.updateSlogan(this.chatOwner[this.currentUser.role+'_profile'].slogan)
            this.$bus.$emit('close_modal')
        },
        uploadImage(event){
            console.log('uploadImage');
            const img = event.target.files[0];
            this.image = new Image()
            this.image.onload = () => {
                setTimeout(()=> {
                    this.$bus.$emit('open_modal');
                    this.modalCropper = true
                },300)
            };
            this.image.src = URL.createObjectURL(img)

            event.target.value = null
        },
    }
}

</script>


<style scoped>

</style>
