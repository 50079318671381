<template>
    <section id="home" class="section section_intro">
        <img class="video video_responsive" :src="welcomePosterSrc" alt="promo Image" ref="image">
    </section>
</template>

<script>
export default {
    name: "LandingImageOld",
    props:[
        'cdnUrl',
        'myUrl',
        'cdnStaticDataPath',
        'img_428',
        'img_3840',
        'img_3440',
        'img_2560',
        'img_1920',
        'img_1440',
        'img_1280',
    ],
    data(){
        return{
            // welcomePosterSrc: '',
        }
    },
    computed:{
        windowHeight(){
            let h = window.innerHeight;
            //console.log(h)
            return h;
        },
        welcomePosterSrc(){
            let url = this.cdnUrl + this.cdnStaticDataPath
            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;
            console.log(windowWidth,windowHeight);
            if( windowWidth < windowHeight ){
                url = url + this.img_428
            } else if (windowWidth >= 3840) {
                url = url + this.img_3840
            } else if (windowWidth >= 3440) {
                url = url + this.img_3440
            } else if (windowWidth >= 2560) {
                url = url + this.img_2560
            } else if (windowWidth >= 1920) {
                url = url + this.img_1920
            } else if (windowWidth >= 1440) {
                url = url + this.img_1440
            } else{
                url = url + this.img_1280
            }
            console.log('url -->' + url);
            return url
        }
    },
    created() {
        // if (this.$mq !=='desktop') {
        //     window.addEventListener('resize', this.handleResize);
        //     this.handleResize();
        // }
    },
    beforeDestroy() {
        // window.removeEventListener('resize', this.handleResize);
    },
    mounted() {
        // this.setImageSrc();
        this.$emit('hide_global_loader')
    },
    methods:{
        // handleResize() {
        //     this.window.width = window.innerWidth;
        //     this.window.height = window.innerHeight;
        // },
        // setImageSrc() {
        //     const url = this.cdnUrl + this.cdnStaticDataPath
        //     if (this.$refs.poster) {
        //         // let windowWidth = window.innerWidth;
        //         // let windowHeight = window.innerHeight;
        //         // console.log(windowWidth,windowHeight);
        //         if( this.window.width/this.window.height < 1 ){
        //             this.welcomePosterSrc = url + this.img_428
        //         } else if (this.window.width >= 3840) {
        //             this.welcomePosterSrc = url + this.img_3840
        //         } else if (this.window.width >= 3440) {
        //             this.welcomePosterSrc = url + this.img_3440
        //         } else if (this.window.width >= 2560) {
        //             this.welcomePosterSrc = url + this.img_2560
        //         } else if (this.window.width >= 1920) {
        //             this.welcomePosterSrc = url + this.img_1920
        //         } else if (this.window.width >= 1440) {
        //             this.welcomePosterSrc = url + this.img_1440
        //         } else{
        //             this.welcomePosterSrc = url + this.img_1280
        //         }
        //     }
        // },
    }
}
</script>

<style scoped>

</style>
