<template>
    <div>
        <button class="button button_options" @click.stop.capture="isOpenOptions=!isOpenOptions">
          <span></span>
        </button>
        <div class="dropdown dropdown_right dropdown_md" v-if="isOpenOptions">
            <ul class="dropdown__menu menu">

                <li class="dropdown__item menu__item" @click.prevent="openModalComplain">
                                              <span class="dropdown__link menu__link"><i
                                                  class="icon icon_complain"></i>Complain</span>
                </li>
                <li class="dropdown__item menu__item" @click.prevent="openModalBlocked" v-if="!isBlockedOpponent">
                                              <span class="dropdown__link menu__link">
                                              <i class="icon icon_block-user" ></i>Block user</span>
                </li>
                <li class="dropdown__item menu__item" @click.prevent="blockOpponent(false)" v-else>
                                              <span class="dropdown__link menu__link">
                                              <i class="icon icon_block-user" ></i>Unblock user</span>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default{
    name:"ThreeDots",
    props:['isBlockedOpponent'],
    data(){
        return {
          isOpenOptions:false,
        }
    },
    mounted() {
        this.$bus.$on('close_options_menu',this.closeOptionsMenu);
    },
    beforeDestroy() {
        this.$bus.$off('close_options_menu',this.closeOptionsMenu);
    },
    methods:{
        closeOptionsMenu(){
          this.isOpenOptions = false;
        },
        openModalBlocked() {
          this.isOpenOptions = false;
          this.$bus.$emit('open_modal_blocked');
        },
        openModalComplain() {
          this.$bus.$emit('open_modal_complain');
        },
        closeModalBlocked(){
          this.$bus.$emit('close_modal_blocked');
        },
        blockOpponent(status){
          this.$bus.$emit('block_opponent_profile', status)
        }
    }
}
</script>

<style scoped>

</style>
