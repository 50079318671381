<template>
    <span class="message__date">{{messageTime()}}
        <svg class="chat__check-icon" :class="{'is-read':isReadOpponent}" xmlns="http://www.w3.org/2000/svg" width="24" height="14">
            <use  :xlink:href="`${myUrl}/source/images/icons/icons.svg#check`" />
        </svg>
    </span>
</template>
<script>
export default {
    name: "CheckReadOpponent",
    props:['time','isReadOpponent','myUrl'],
    methods:{
        messageTime(){
            const time = new Date(this.time).toLocaleTimeString();
            // console.log(time);
            if(!time) return '_'
            const timeArr = time.split(':');
            if(timeArr.length < 2) return '_'
            const hours = timeArr[0] > 12 ? timeArr[0]-12 : timeArr[0];
            const m = time.includes('AM') ? 'AM' : (time.includes('PM') ? 'PM': (timeArr[0] > 12 ? 'PM':'AM'))
            return `${hours}:${timeArr[1]} ${m}` ;
        },
    }
}
</script>

<style scoped>

</style>
