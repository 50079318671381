<template>
    <div class="price" :class="{'is-open': show, 'price_fixed': $mq ==='desktop', 'price_mobile': $mq !=='desktop'}" v-if="paymentTariff.length">
        <div class="modal modal_service modal_payment-system" :class="{'show' : showModalPayment}">
            <div class="modal__overlay"></div>
            <div class="modal__wrapper">
                <div class="modal__container" :style="`height: ${$mq !=='desktop' ? windowHeight : 'auto'}px`" v-if="showModalPayment">
                    <button class="btn btn_icon close" @click.prevent="closeModalPayment"></button>
                    <div class="modal__body">
                        <div class="modal__header">
                            <h4 class="modal__heading">Payment system</h4>
                            <span class="line"></span>
                        </div>
                        <div class="modal__section modal__section_card">
                            <div class="modal__label">Card</div>
                            <div class="d-flex">
                                <div class="modal__buttons">

                                    <template v-if="ccBillTariff">
                                        <form method="GET" action="https://api.ccbill.com/wap-frontflex/flexforms/affa2ab5-075c-41a4-90ef-87137d6f6d3f">
                                            <input type=hidden name=subscriptionTypeId :value="ccBillTariff.subscriptionTypeId">
                                            <input type=hidden name=currencyCode :value="ccBillTariff.currencyCode">
                                            <input type=hidden name=clientSubacc :value="ccBillTariff.clientSubacc">
                                            <input type=hidden name=user_id :value="currentUser.id">
                                            <!--                                        <button type=submit class="btn btn_price"><i class="icon-apply"></i>Apply</button>-->
                                            <button type=submit class="btn btn_ccbill">
                                                <img :src="`${myUrl}/assets/images/svgs/ccbill-btn.svg`" alt="">
                                            </button>
                                        </form>
                                    </template>

                                    <template v-else>
                                        <button class="btn btn_ccbill" disabled>
                                            <img :src="`${myUrl}/assets/images/svgs/ccbill-btn.svg`" alt="">
                                        </button>
                                    </template>

                                    <button class="btn btn_cg" @click.prevent="goToPaymentCG('card')">
                                        <img :src="`${myUrl}/assets/images/svgs/cg-btn.svg`" alt="" class="cc-light">
                                        <img :src="`${myUrl}/assets/images/svgs/cc-light.svg`" alt="" class="cc-dark">
                                    </button>
                                </div>
                                <div class="modal__icons">
                                    <img :src="`${myUrl}/assets/images/svgs/visa1.svg`" alt="visa" class="visa">
                                    <img :src="`${myUrl}/assets/images/svgs/jd1.svg`" alt="jd" class="jd">
                                    <img :src="`${myUrl}/assets/images/svgs/master1.svg`" alt="master" class="master">
                                    <img :src="`${myUrl}/assets/images/svgs/jcb1.svg`" alt="jsb" class="jsb">
                                    <img :src="`${myUrl}/assets/images/svgs/discover1.svg`" alt="disc" class="disc">
                                    <img :src="`${myUrl}/assets/images/svgs/american1.svg`" alt="america" class="america">
                                    <img :src="`${myUrl}/assets/images/svgs/up1.svg`" alt="up" class="up">
                                </div>
                            </div>
                        </div>
<!--                        <div class="modal__section modal__section_crypto">-->
<!--                            <div class="modal__label">Crypto</div>-->
<!--                            <div class="modal__buttons">-->
<!--                                <button class="btn btn_cg" @click.prevent="goToPaymentCG('crypto')">-->
<!--                                    <img :src="`${myUrl}/assets/images/svgs/cg-btn-crypto.svg`" alt="">-->
<!--                                </button>-->
<!--                                <button class="btn btn_crypto" @click.prevent="goToPaymentCG('crypto')">-->
<!--                                    <img :src="`${myUrl}/assets/images/svgs/btn-bitcoin.svg`" alt="">-->
<!--                                </button>-->
<!--                                <button class="btn btn_crypto" @click.prevent="goToPaymentCG('crypto')">-->
<!--                                    <img :src="`${myUrl}/assets/images/svgs/btn-lnbc.svg`" alt="">-->
<!--                                </button>-->
<!--                                <button class="btn btn_crypto" @click.prevent="goToPaymentCG('crypto')">-->
<!--                                    <img :src="`${myUrl}/assets/images/svgs/btn-usdt.svg`" alt="">-->
<!--                                </button>-->
<!--                                <button class="btn btn_crypto" @click.prevent="goToPaymentCG('crypto')">-->
<!--                                    <img :src="`${myUrl}/assets/images/svgs/btn-etn.svg`" alt="">-->
<!--                                </button>-->
<!--                            </div>-->
<!--                        </div>-->
                        <div class="modal__footer">
                            <span class="text"><i class="icon-transaction"></i>Transactions are encrypted and secured</span>
                            <div class="images">
                                <img :src="`${myUrl}/assets/images/svgs/pci.svg`" alt="visa" class="visa">
                                <img :src="`${myUrl}/assets/images/svgs/visa-secure.svg`" alt="master" class="master">
                                <img :src="`${myUrl}/assets/images/svgs/master-check.svg`" alt="jd" class="jd">
                                <img :src="`${myUrl}/assets/images/svgs/jcb-secure.svg`" alt="jsb" class="jsb">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <template v-if="$mq==='desktop'">
            <button class="btn btn_icon btn_close" @click.prevent="close"><i class="fas fa-times"></i></button>
            <div class="logo">
                <a href="/" class="logo__base" v-if="!holidayPromotionName">
                    <img :src="`${myUrl}/assets/images/svgs/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/svgs/logo-white-bg.svg`" class="img-dark" alt="logo">
                </a>
                <a href="/" class="logo__feb" v-else-if="holidayPromotionName === 'valentine'">
                    <img :src="`${myUrl}/assets/images/holidays/feb/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/feb/logo-white-bg.svg`" class="img-dark" alt="logo">
                </a>
                <a href="/" class="logo__action" v-else-if="holidayPromotionName === 'ukraine'">
                    <img :src="`${myUrl}/assets/images/holidays/action/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/action/logo-white-bg.svg`" class="img-dark" alt="logo">
                </a>
                <span class="logo__july" v-else-if="holidayPromotionName === 'kiss'">
                    <img :src="`${myUrl}/assets/images/holidays/6july/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/6july/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__photo" v-else-if="holidayPromotionName === 'photo'">
                    <img :src="`${myUrl}/assets/images/holidays/photo/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/photo/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__halloween" v-else-if="holidayPromotionName === 'halloween'">
                    <img :src="`${myUrl}/assets/images/holidays/halloween/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/halloween/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__thanks" v-else-if="holidayPromotionName === 'thanks'">
                    <img :src="`${myUrl}/assets/images/holidays/thanks/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/thanks/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__friday" v-else-if="holidayPromotionName === 'friday'">
                    <img :src="`${myUrl}/assets/images/svgs/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/svgs/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__christmas" v-else-if="holidayPromotionName === 'christmas'">
                    <img :src="`${myUrl}/assets/images/holidays/christmas/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/christmas/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__newyear" v-else-if="holidayPromotionName === 'newyear'">
                    <img :src="`${myUrl}/assets/images/holidays/newyear/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/newyear/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__hugging" v-else-if="holidayPromotionName === 'hugging'">
                    <img :src="`${myUrl}/assets/images/svgs/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/svgs/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__patrik" v-else-if="holidayPromotionName === 'patrik'">
                    <img :src="`${myUrl}/assets/images/holidays/patrik/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/patrik/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__rock" v-else-if="holidayPromotionName === 'rock'">
                    <img :src="`${myUrl}/assets/images/holidays/rock/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/rock/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__blond" v-else-if="holidayPromotionName === 'blond'">
                    <img :src="`${myUrl}/assets/images/holidays/blond/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/blond/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
            </div>

            <div class="price__container">
                <h3 class="heading">Choose your plan</h3>
                <ul class="price__list">
                    <li v-for="tariff in paymentTariff" class="price__item" @click.prevent="select(tariff)">
                        <div class="price__header">
                            <h3 class="price__title">{{tariff.title}}</h3>
                        </div>
                        <div class="price__block">
                            <div class="price__figure">
                                <div class="price__description">
                                    <span class="unit">US$</span>
                                    <span class="value">{{tariff.amount}}</span>
                                    <span class="single">/single</span>
                                </div>
                                <div class="price__credits">{{tariff.credits}} credits</div>
                                <button class="btn btn_price" @click="clickSelect(tariff)">Select</button>
                            </div>
                            <ul class="price__features">
                                <li><strong>1</strong> Credit costs <strong>{{tariff.credit_cost}}</strong> cents</li>
                                <li class="bonus"><strong>{{getBonus(tariff.amount, tariff.bonus)}}</strong> Bonus credits</li>
                                <template v-if="tariff.addition">
                                    <li v-for="addition in tariff.addition">{{addition}}</li>
                                </template>
                            </ul>
                        </div>
                    </li>
                </ul>
                <div class="price__footer">
                    <span><i class="icon-transaction"></i>Transactions are encrypted and secured</span><span class="divider">|</span>
                    <span> <i class="icon-buying"></i>Before buying credits, please read</span>
                    <span class="link"><a class="menu__link" :href="`${myUrl}/terms-and-conditions-short`" target="_blank">Terms&Conditions</a></span>
                    <span>/</span>
                    <span class="link"><a class="menu__link" :href="`${myUrl}/cookies-policy`" target="_blank">Cookies Policy</a></span>
                    <span>of Hablario</span>
                </div>
            </div>
            <ul class="list list_links">
<!--                <li><a href=""><img :src="`${myUrl}/assets/images/price/mc.jpg`" alt=""></a></li>-->
<!--                <li><a href=""><img :src="`${myUrl}/assets/images/price/trust.jpg`" alt=""></a></li>-->
            </ul>
        </template>
        <template v-else>
            <div class="price__wrapper" :style="`height: ${windowHeight}px`">
                <div class="price__head mobile-head">
                    <div class="price__col">
                        <button class="btn btn_icon btn_back" @click="close">
                             <span class="holiday">
                                 <i class="icon icon-btn-back"></i>
                             </span>
                            <i class="icomoon icon-left"></i>
                        </button>
                        <span class="title">Buy credits</span>
                    </div>
                </div>
                <div class="price__container">
                    <ul class="price__list">
                        <li v-for="tariff in paymentTariff" class="price__item" @click.prevent="select(tariff)">
                            <div class="price__header">
                                <h3 class="price__title">{{tariff.title}}</h3>
                            </div>
                            <div class="price__block">
                                <div class="price__figure">
                                    <div class="price__description">
                                        <span class="unit">US$</span>
                                        <span class="value">{{tariff.amount}}</span>
                                        <span class="single">/single</span>
                                    </div>
                                    <div class="price__credits">{{tariff.credits}} credits</div>
                                    <button class="btn btn_price">Select</button>
                                </div>
                                <ul class="price__features">
                                    <li><strong>1</strong> Credit costs <strong>{{tariff.credit_cost}}</strong> cents</li>
                                    <li class="bonus"><strong>{{getBonus(tariff.amount, tariff.bonus)}}</strong> Bonus credits</li>
                                    <template v-if="tariff.addition">
                                        <li v-for="addition in tariff.addition">{{addition}}</li>
                                    </template>
                                </ul>
                            </div>
                        </li>
                    </ul>
                    <div class="price__footer">
                        <p> <img :src="`${myUrl}/assets/images/price/visa.svg`" alt="">
                            <img :src="`${myUrl}/assets/images/price/master.svg`" alt="">
                            <img :src="`${myUrl}/assets/images/price/jcb.svg`" alt="">
                            <img :src="`${myUrl}/assets/images/price/disco.svg`" alt="">
                            <img :src="`${myUrl}/assets/images/price/american.svg`" alt="">
                        </p>
                        <span><i class="icon-transaction"></i>Transactions are encrypted and secured</span>
                    </div>
                    <ul class="list list_links">
<!--                        <li><a href=""><img :src="`${myUrl}/assets/images/price/mc.jpg`" alt=""></a></li>-->
<!--                        <li><a href=""><img :src="`${myUrl}/assets/images/price/trust.jpg`" alt=""></a></li>-->
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>
<script>
export default {
    name: "PriceTable",
    props:['myUrl', 'show', 'currentUser', 'windowHeight','holidayPromotionName'],
    data(){
        return {
            paymentTariff: [],
            holidayPromotion:null,
            showModalPayment: false,
            selectedAmount:null,
            ccBillTariffs:[],
            ccBillTariff:null
        }
    },
    created() {
        axios.get('/get-payment-tariffs')
            .then(response => {
                if(response.data.status === 'ok'){
                    console.log('get-payment-tariffs success!');
                    console.log(response.data);
                    this.paymentTariff = response.data.paymentTariff;
                    this.holidayPromotion = response.data.holidayPromotion;
                    this.ccBillTariffs = response.data.ccBillTariffs;
                    this.sendGoogleEcommerce()
                }
            })
            .catch(e => {
                this.errorHandler(e);
                console.log(e);
        })
    },
    methods:{
        sendGoogleEcommerce(){
            // try {
            //     dataLayer.push({ ecommerce: null });      // Clear the previous ecommerce object.
            //     dataLayer.push({
            //         'ecommerce': {
            //             'currencyCode': 'USD',            // Local currency is optional.
            //             'impressions': this.paymentTariff.map(tariff => ({
            //                     'name': tariff.title,       // Name or ID is required.
            //                     'id': Number(tariff.id).toFixed(0),
            //                     'price': Number(tariff.amount).toFixed(2),
            //                     'brand': 'lifeforlove',
            //                     'variant': 'credirs',
            //                     'list': 'Search Results',
            //                     'position': 1
            //             }))
            //         }
            //     });
            //
            //
            //
            // }catch (e) {
            //     console.log(e)
            // }
        },
        clickSelect(tariff){
            try {
                console.log('clickSelect');
                dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                dataLayer.push({
                    event: "add_to_cart",
                    ecommerce: {
                        items: [{
                            item_name: tariff.title, // Название товара
                            item_id: Number(tariff.id).toFixed(0),
                            price: Number(tariff.amount).toFixed(2),
                            item_brand: "lifeforlove",
                            item_variant: "credits", // количество кредитов
                            quantity: 1
                        }]
                    }
                });
                // dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
                // dataLayer.push({
                //     'event': 'addToCart',
                //     'ecommerce': {
                //         'currencyCode': 'USD',
                //         'add': {                                  // 'add' actionFieldObject measures.
                //             'products': [{                        //  adding a product to a shopping cart.
                //                 'name': tariff.title,             // Название товара
                //                 'id': Number(tariff.id).toFixed(0),
                //                 'price': Number(tariff.amount).toFixed(2),
                //                 'brand': 'lifeforlove',          // Бренд товара
                //                 'variant': 'credirs',
                //                 'quantity': 1                     // Количество товара
                //             }]
                //         }
                //     }
                // });

            }catch (e) {
                console.log(e)
            }

        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        getBonus(amount,bonus){
            if(this.holidayPromotion && this.holidayPromotion.bonuses){
                return this.holidayPromotion.bonuses[amount] ? this.holidayPromotion.bonuses[amount] : 0
            }
            return bonus ;
        },
        close(){
            this.showModalPayment = false;
            this.$emit('cancel_price');
            document.body.classList.remove("modal-open")
        },
        open() {
            this.$emit('open_price');
            document.body.classList.add("modal-open")
        },
        select(tariff) {
            this.showModalPayment = true;
            console.log(tariff);
            this.selectedAmount = tariff.amount;
            this.ccBillTariff = this.ccBillTariffs.find( ccBillTariff => {
                return +ccBillTariff.amount === +tariff.amount
            });
        },
        closeModalPayment() {
            this.showModalPayment = false
            this.$emit('handle_resize')
        },
        // select(tariff){
        //     this.$emit('close_price');
        //     console.log(this.$route.name)
        //     if (this.$route.name !== 'payment') {
        //         this.$router.push({
        //             name: 'payment',
        //             params: {
        //                 amount: tariff.amount,
        //                 bonus:this.getBonus(tariff.amount,tariff.bonus),
        //                 currentUser: this.currentUser,
        //                 tariff: tariff,
        //             }
        //         });
        //     }
        //     else {
        //         console.log(this.$route.name)
        //         const param = {
        //             amount: tariff.amount,
        //             tariff: tariff,
        //             bonus:this.getBonus(tariff.amount,tariff.bonus),
        //         }
        //         this.$bus.$emit('update-payment-tariff',param )
        //     }
        // },
        goToPaymentCG(paymentMethod){
            console.log('goToFormCG');

            axios.get(`/get-url-payment-form-cg/payment/${this.selectedAmount}/${paymentMethod}`)
                .then(response => {
                    console.log(response.data);
                    if(response.data.status === 'pending'){
                        console.log('status pending');
                        this.$router.push({path: `/payment-cg-status/${response.data.amount}/${response.data.id}`});
                        this.close();
                    }else if (response.data.status === 'ok' && response.data.bodyForm){
                        window.location.href = response.data.bodyForm.forwardUrl;
                    }
                    // this.evenGooglePromo(tariff)
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        // evenGooglePromo(tariff){
        //     try {
        //         console.log('begin_checkout');
        //         dataLayer.push({ ecommerce: null });  // Clear the previous ecommerce object.
        //         dataLayer.push({
        //             event: "begin_checkout",
        //             ecommerce: {
        //                 items: [{
        //                     item_name: tariff.title, // Название товара
        //                     item_id: Number(tariff.id).toFixed(0),
        //                     price: Number(tariff.amount).toFixed(2),
        //                     item_brand: "lifeforlove",
        //                     item_variant: "credits", // количество кредитов
        //                     quantity: 1
        //                 }]
        //             }
        //         });
        //     }catch (e) {
        //         console.log(e)
        //     }
        // },
        // updatePaymentTariff(data){
        //     console.log('updatePaymentTariff')
        //     console.log(data)
        //     if(this.selectedAmount !== data.amount){
        //         this.selectedAmount = data.amount;
        //         this.selectedTariff = data.tariff;
        //         this.getPaymentData(data.amount);
        //         this.$router.push({
        //             name: 'payment',
        //             params: {
        //                 amount: data.amount,
        //                 currentUser: this.currentUser,
        //                 tariff: data.tariff,
        //                 bonus:data.bonus,
        //             }
        //         });
        //     }
        // },

        // goToGallery(){
        //     if (this.$route.name !== 'gallery') {
        //         this.$router.push({name: 'gallery'});
        //     }
        // },
        // getPaymentData(amount){
        //     console.log('getPaymentData by tariff '+amount)
        //     axios.get('/get-payment-buttons/'+amount+'/'+this.from_profile_id)
        //         .then(response => {
        //             if(response.data.status === 'ok'){
        //                 console.log('get-payment-buttons success!')
        //                 this.CCBillButtonData = response.data.CCBillButtonData
        //                 // this.CGButtonData = response.data.CGButtonData
        //                 if (+amount === 20) this.selectedPayment = 'CG';
        //                 else this.selectedPayment = 'CCBill';
        //             }
        //         })
        //         .catch(e => {
        //             console.log(e);
        //         })
        // },
    }
}
</script>
