<template>
    <div class="message__donate">
        <img class="message__image"
             :src="`${myUrl}/source/images/icons/gifts/coin-flip-43.gif`" alt="donate"
                 width="130" height="130">
        <span class="message__title">{{ getAmount() }}</span>
    </div>
</template>
<script>
export default  {
    name:"ChatItemDonate",
    props:['messageSizeRem', 'myUrl', 'message'],
    methods:{
        getAmount(){
            let a = this.message.src_mini/100
            let b = a > 1 ? ` Credits` : ` Credit`
            return a + b
        }
    }
}
</script>
<style scoped>

</style>
