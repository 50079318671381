<template>
    <ul class="panel__list contacts list">
        <li class="contacts__text" v-if="type==='chat-request' && +unread" @click="openModalClear">
            <svg class="contacts__icon" xmlns="http://www.w3.org/2000/svg" width="8" height="13">
                <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#trash`" />
            </svg>
            <span>Clear Messages</span>
        </li>
        <li class="contacts__text" v-else-if="type==='history' && contacts && contacts.length" @click="openModalClearHistory">
            <svg class="contacts__icon" xmlns="http://www.w3.org/2000/svg" width="8" height="13">
                <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#trash`" />
            </svg>
            <span>Clear History</span>
        </li>

        <li class="list__item contacts__item" v-for="contact in contacts" :key="contact[`${roleOpponent}_id`]" @click.prevent="selectOpponent(contact[roleOpponent])">
<!--            class="contact"-->
            <div class="contact">
                <span class="date" v-if="type==='chat-request'">{{getTimeAgo(contact.created_at)}}</span>
                <div class="contact__info">
                    <span class="tooltip tooltip_secondary" v-if="contact[`unread_${currentUser.role}`] && type==='chat-request'">
                        <span class="tooltip__content">{{ contact[`unread_${currentUser.role}`] }}</span>
                    </span>
                    <span class="contact__title">
                    <span class="contact__name">{{contact[roleOpponent].name}}</span>
                            <span class="contact__age">{{getAge('',contact[roleOpponent].birthday)}}</span>
                    </span>
                    <span class="contact__message">
                        {{getLastMessage(contact.lastMessage)}}
                    </span>
                </div>
                <div class="contact__avatar">
                    <picture v-if="contact[roleOpponent].avatar">
                        <source type="image/webp" :srcset="`${s3Url}/${contact[roleOpponent].avatar_webp}`" v-if="contact[roleOpponent].avatar_webp">
                        <img class="avatar avatar_sm" :src="`${s3Url}/${contact[roleOpponent].avatar}`" alt="avatar user" width="50"
                             height="50">
                    </picture>
                    <div class="avatar avatar_sm avatar_holder" v-else>
                        <img class="avatar__image" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="65" height="65">
                    </div>
                    <span class="status status_online" v-if="contact[roleOpponent].online">
                        <i class="status__icon"></i>
                    </span>
                </div>
            </div>
        </li>
<!--        class="end_contacts_list"-->
        <li :id="`end_contacts_${type}`"></li>
    </ul>
</template>

<script>

let observer = null;
let itemEnd = null;

const shotMonth = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
const nameType =  {
    letter:'LETTER',
    picture:'PHOTO',
    clip:'VIDEO',
    voice:'AUDIO',
    gif:'GIF',
    emoji:'SMILE'
}
export default {
    name: "ListContactOpponents",
    props:[
        'currentUser',
        'myUrl',
        's3Url',
        'contacts',
        'roleOpponent',
        'type',
        'banList',
        'isDownload',
        'isAll',
        'unread',
        'isNewVersion',
        'isSupportedWebp'
    ],
    mounted() {
        console.log('mounted list contact');
        this.initScrollObserver();
        // this.$bus.$on('get_my_stories',this.getMyStories);
    },
    beforeDestroy() {

        if (observer && itemEnd) {
            observer.unobserve(itemEnd)
            observer = null
            itemEnd = null
        }
    },
    methods:{
        initScrollObserver(){
            console.log('initScrollObserver');
            itemEnd = document.getElementById(`end_contacts_${this.type}`);
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            };
            observer = new IntersectionObserver(this.callbackObserver, options);
            observer.observe(itemEnd);
        },
        callbackObserver(entries, observer){
            if(this.isAll || !this.contacts || !this.contacts.length || this.contacts.length < 10) return
            console.log('callbackObserver');
            entries.forEach((entry, index) => {
                // console.log(entry);
                if (entry.isIntersecting && entry.target.id === `end_contacts_${this.type}`){
                    if (!this.isAll) this.$emit('get_more_contacts');
                }
            });
        },
        getLastMessage(lastMessage){
            if(!lastMessage) return ''
            if(lastMessage.type==='message'){
                if(!lastMessage.message) return ''
                return (lastMessage.message.length < 30) ?
                    lastMessage.message :
                    `${lastMessage.message.slice(0,25)}...`
            }
            return lastMessage.type==='message' ? lastMessage.message : nameType[lastMessage.type]
        },
        getTimeAgo(eventDate) {
            const event = new Date(eventDate);
            const now = new Date();

            const timeDiff = (now.getTime() - event.getTime())/1000;
            const oneMinute = 60;
            const oneHour = 60 * oneMinute;
            const oneDay = 24 * oneHour;

            const daysAgo = Math.floor(timeDiff / oneDay);
            if(daysAgo >= 30) return `${event.getDate()} ${shotMonth[event.getMonth()]}`;
            if(daysAgo >= 1) return `${daysAgo} day${(daysAgo > 1) ? 's':''} ago`;

            const hoursAgo = Math.floor(timeDiff / oneHour);
            if(hoursAgo >= 1) return `${hoursAgo} hour${(hoursAgo > 1) ? 's':''} ago`;

            let minutesAgo = Math.floor(timeDiff / oneMinute);
            if(minutesAgo < 1) minutesAgo = 1;

            return `${minutesAgo} min ago`
        },
        getAge(age,birthday){
            if(birthday){
                // let diff = new Date()-new Date(birthday); // This is the difference in milliseconds
                return  Math.floor((new Date()-new Date(birthday))/31557600000); // Divide by 1000*60*60*24*365.25
            }
            return age;
        },
        // writeDate(date){
        //     const arr = date.split('.')
        //     return `${(arr[0])}/${arr[1]}/${arr[2]}`
        // },
        getNewVersion(){
           console.log('click getNewVersion');
           this.$bus.$emit('get_new_version')
        },
        writeDateFull(date){
            const fullDate = new Date(date);
            return `${fullDate.getDate()}/${fullDate.getMonth()+1}/${fullDate.getFullYear()}`
        },
        shortName(name){
            let arr = name.split(' ');
            let rez = '';
            arr.forEach(item=>{
                rez += item.charAt(0).toUpperCase();
            })
            return rez;
        },
        // removeOpponent(memberID){
        //     this.$emit('remove_opponent',memberID);
        // },
        selectOpponent(member){
            console.log('selectOpponent');
            this.$bus.$emit('select_opponent', member, this.type, true);
            if (this.type === 'chat-request' &&   this.contacts.length !== 0 && this.contacts.length < 20){
                this.$emit('get_more_contacts');
            }
        },
        //reply
        titleAvatar(opponentId){
            return  this.type ==='chat-request'?'reply':
                    this.opponentBan(opponentId)?'locked':
                    'view'
        },
        opponentBan(opponentId){
            return this.banList.includes(opponentId)
        },
        // scrollOn(){
        //     // console.log('list contact scrollOn()');
        //    if(!this.isDownload && !this.isAll) {
        //        const listContact = this.$mq==='desktop' ? this.$refs.listContact : this.$refs.listContactMobile
        //        if ((listContact.offsetHeight + listContact.scrollTop) >= listContact.scrollHeight-(listContact.scrollHeight/10)){
        //            console.log('get_more_contacts');
        //            this.$emit('get_more_contacts');
        //        }
        //
        //    }
        // },
        openModalClear() {
            console.log('openModalClear');
            this.$bus.$emit('open_modal_clear');
        },
        openModalClearHistory() {
            console.log('openModalClearHistory');
            this.$bus.$emit('open_modal_clear_history');
        },
    }
}
</script>

<style scoped>

</style>
