<template>
    <div class="chat chat_main" @click="clickChat" v-show="isCheckHeight"
         :class="{'is-fixed':($mq==='desktop' && !isSeeFooter), 'is-absolute':($mq==='desktop' && isSeeFooter),'js-chat':$mq==='desktop','chat_mobile':$mq!=='desktop'}">

        <div class="chat__container" ref="chatContainer">
<!--            bg-default-->
            <div class="chat__wrapper" :class="settingsInit ? settingsInit.chat_background : 'bg-default'">
                <div class="chat__header">
                    <button class="button button_round button_back" @click.prevent="closeMobile" v-if="$mq!=='desktop'">
                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                            <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#back`" />
                        </svg>
                    </button>
                    <chat-header-contact
                        :s3-url="s3Url"
                        :my-url="myUrl"
                        :opponent="opponent"
                        :opponent-age="opponentAge"
                        @goto_profile="gotoProfile"
                    ></chat-header-contact>
                    <div class="chat__actions">
                        <button class="button button_letter" @click="toggleLetter" v-if="$mq!=='desktop'">
                            <i class="button__icon icon icon_envelope"></i>
                        </button>
                    <block-user v-if="modalBlocked"></block-user>
                    <presents-block
                        :gift-processing=giftProcessing
                        :opponent-id="opponent ? opponent.id : 0"
                        :member="opponent"
                    ></presents-block>
                    <modal-gifts  v-if="showModalGifts"
                        :myUrl="myUrl"
                        :s3Url="s3Url"
                        :gifts="gifts"
                        :s3-url-static="s3UrlStatic"
                        :opponent-id="opponent ? opponent.id : 0"
                        >
                    </modal-gifts>
<!--                    <three-dots-->
<!--                        v-if="$mq!=='desktop'"-->
<!--                        :is-blocked-opponent="isBlockedOpponent"-->
<!--                    >-->
<!--                    </three-dots>-->
                    <complain-modal v-if="modalComplain"
                        :opponent="opponent"
                        :chat-owner="chatOwner"
                        :role="currentUser.role"
                    ></complain-modal>
                        <button class="button button_icon chat__close"  v-if="$mq==='desktop'" @click.prevent="close">
                            <i class="icon icon_close"></i>
                        </button>
                    </div>
                </div>
                <chat-log
                    :my-url="myUrl"
                    :s3-url="s3Url"
                    :messages="messages"
                    :member-id="chatOwner.id"
                    :role-opponent="roleOpponent"
                    :is-all-messages="isAllMessages"
                    :cdn-letter-images="cdnLetterImages"
                    :message-color="settingsInit.message_color"
                    :message-size="settingsInit.message_size"
                    :disabled-button="disabledButton"
                    :avatar="opponent ? opponent.avatar : ''"
                    :avatar_webp="opponent ? opponent.avatar_webp : ''"
                    :s3-url-static="s3UrlStatic"
                    :is-supported-webp="isSupportedWebp"
                    @open_media="openChatMedia"
                    @more_messages="moreMessages"
                    @show_btn_down="setShowBtnDown"
                    @read_letter="readLetter"
                    @buy_attachment_message="buyAttachmentMessage"
                    @edit_chat_message="editChatMessage"
                    @errorhandler="errorHandler"
                    ref="chatLog"
                ></chat-log>
                <chat-input ref="chatInput"
                    :my-url="myUrl"
                    :emojis="emojis"
                    :is-typing="isOpponentTyping"
                    :opponent-name="opponent ? opponent.name : ''"
                    :show-btn-down="showBtnDown"
                    :is-opponent-in-chat="isOpponentInChat"
                    :is-blocked="isBlockedOpponent"
                    :is-conversion-progress="isConversionProgress"
                    :path-temp-file="pathTempFile"
                    :edit-message="editMessage"
                    :opponent-i-d="opponent ? opponent.id : 0"
                    :used-video-codecs="usedVideoCodecs"
                    :supports-local-storage="supportsLocalStorage"
                    :disabled-send-message="disabledSendMessage"
                    @is_conversion_progress="setConversionProgress"
                    @toggle_letter="toggleLetter"
                    @open_chat_media="openChatMedia"
                    @is_typing="sendEventTyping"
                    @end_edit_chat_message="endEditChatMessage"
                ></chat-input>
                <letter-chat
                    :my-url="myUrl"
                    :s3-url="s3Url"
                    :cdn-letter-images="cdnLetterImages"
                    :opponent="opponent"
                    :opponent-age="opponentAge"
                    :supports-local-storage="supportsLocalStorage"
                    :is-write-letters="isWriteLetters"
                    :is-edit-letter="isEditLetter"
                    :letter="letter"
                    :chat-owner-id="chatOwner?chatOwner.id:0"
                    :my-role="currentUser.role"
                    :role-opponent="roleOpponent"
                    :is-opponent="isOpponentAuthorLetter"
                    :is-opponent-in-chat="isOpponentInChat"
                    :disabled-send-message="disabledSendMessage"
                    :is-supported-webp="isSupportedWebp"
                    :selected-message="selectedMessage"
                    @extend="extend"
                    @close_letter="closeLetter"
                    @replay_letters="replayLetters"
                    @open_menu="openMenu"
                    @goto_profile="gotoProfile"
                    @errorhandler="errorHandler"
                    v-if="showLetter"
                ></letter-chat>
            </div>

        </div>

    </div>
</template>

<script>
import ChatInput from "./ChatInput";
import ChatLog from "./ChatLog";
import LetterChat from "./LetterChat";
import BaseLogo from "../newModules/BaseLogo.vue";
import ButtonFavorite from "../newModules/ButtonFavorite.vue";
import ChatHeaderContact from "../newModules/ChatHeaderContact.vue";
import PresentsBlock from "../newModules/PresentsBlock.vue";
import ModalGifts from "../newModules/modals/ModalGifts.vue";
import ThreeDots from "../newModules/ThreeDots.vue";
import ComplainModal from "../newModules/modals/ComplainModal.vue";
import BlockUser from "../newModules/modals/BlockUser.vue";
const month = ['','January','February','March','April','May','June','July','August','September','October','November','December'];

export default {
    name: "Chat",
    components: {
        BlockUser,
      ComplainModal,
      ThreeDots,
      ModalGifts,
        PresentsBlock,
        ChatHeaderContact,
        ButtonFavorite,
        BaseLogo,
        ChatLog,
        ChatInput,
        LetterChat
    },
    props:[
        'id',
        's3Url',
        'myUrl',
        'messages',
        'chatOwner',
        'currentUser',
        'opponent',
        'opponentAge',
        'isFavorite',
        'emojis',
        'moods',
        'roleOpponent',
        'isAllMessages',
        'selectedContactId',
        'tariffs',
        'isBlockedOpponent',
        'settingsInit',
        'disabledSendMessage',
        'usedVideoCodecs',
        // 'isInit',
        'isSeeFooter',
        'giftProcessing',
        'showModalGifts',
        'gifts',
        's3UrlStatic',
        'modalComplain',
        'modalBlocked',
        'banList',
        'cdnLetterImages',
        'isSupportedWebp',
        'typingsMLMessage',
        'showModalLostConnection',
        'typeSelectOpponent'
    ],
    data(){
        return{
            isExtend: false,
            showLetter: false,
            showMenuUpload:false ,
            showEmoji:false ,
            selectedEmojiID:null ,
            newMessage:'',
            typingInterval:null,
            isTyping:false,
            contactChannel:null,
            showBtnDown: false,
            image: null,
            isWriteLetters:true,
            isEditLetter:false,
            letter: null,
            isOpponentAuthorLetter:false,
            isOpponentInChat:false,
            isConversionProgress:false,
            pathTempFile:'',
            disabledButton:false,
            editMessage: null,
            isCheckHeight: false,
            isOpenOptions: false,
            selectedMessage: null,

        }
    },
    computed: {
        isOpponentTyping(){
            return (this.isTyping || (this.typingsMLMessage && this.opponent && this.typingsMLMessage[this.opponent.id] && this.typingsMLMessage[this.opponent.id].enabled))
        },
        // isBlockedOpponent(){
        //     return ( this.opponent && this.banList && this.banList.includes(this.opponent.id) )
        // },
        // styleContainer(){
        //     let style = {}
        //     const windowHeight = window.innerHeight || document.documentElement.clientHeight;
        //     const windowWidth = window.innerWidth || document.documentElement.clientWidth;
        //     const chatContainer = this.$refs.chatContainer;
        //     const parentChatContainer = document.getElementById('profileEl');
        //     if(chatContainer && parentChatContainer && this.isSeeFooter) {
        //         console.log('chatContainer && parentChatContainer');
        //         if (chatContainer && windowHeight > parentChatContainer.offsetHeight) {
        //             console.log('>')
        //             const offset = windowWidth > 1919 ? 106 : 92;
        //             // chatContainer.style.top = `-${offset}px`;
        //             // chatContainer.style.height = `${parentChatContainer.offsetHeight + offset}px`;
        //             style = {
        //                 top:`-${offset}px`,
        //                 height: `${parentChatContainer.offsetHeight + offset}px`
        //             }
        //             // `top:-${offset}px;height:${parentChatContainer.offsetHeight + offset}px;`;
        //
        //         } else if (chatContainer) {
        //             console.log('<')
        //             // chatContainer.style.height = `${windowHeight}px`;
        //             style = {
        //                 height:`${windowHeight}px`
        //             }
        //             // `height:${windowHeight}px`
        //         }
        //     }
        //     return style
        // }
        supportsLocalStorage() {
            try {
                return 'localStorage' in window  && window['localStorage'] !== null;
            } catch (e) {
                return false;
            }
        },
    },
    watch:{

        id(){
            this.initOpponentForChat();
        },
        isSeeFooter(newValue,oldValue){
            this.setStyleChatContainer();
        },
        selectedContactId(id,oldId){
            if (oldId){
                console.log ('selectedContactId old', oldId);
                this.leaveContactChannel(oldId);
            }
            if(id){
                console.log ('selectedContactId', id);
                this.connectToOnChannels();
            }
            this.showLetter = false;
            //this.openDesktopChat()
        }
    },
    mounted() {
        console.log ('mounted chat', this.opponent);
        this.connectToOnChannels();
        this.$bus.$on('update_data_mobile_chat',this.initOpponentForChat);
        if(this.$mq === 'desktop') this.setStyleChatContainer()
        else this.isCheckHeight = true;
        // setTimeout(()=>{
        //     if(this.isInit)this.setFooterObserver();
        // },0)
        this.$bus.$on('close_options_menu',this.closeOptionsMenu);
        if(this.$route.name === 'chat' || this.$route.name === 'promoChat'){
            console.log('initOpponentForChat');
            if(this.$mq !== 'desktop')this.initOpponentForChat();
            else this.gotoProfile();
        }
        else {
            if(this.isPromoPage(false)){
                this.meetingPromoViewer(2000)
            }
            //else this.openDesktopChat()
        }

        console.log ('chat mounted selectedContactId',this.selectedContactId);
    },
    beforeDestroy() {
        this.$bus.$off('update_data_mobile_chat',this.initOpponentForChat);
        // Echo.leave(`contact.${this.selectedContactId}`);
        this.leaveContactChannel(this.selectedContactId);
        this.$bus.$off('close_options_menu',this.closeOptionsMenu);
    },
    methods:{
        meetingPromoViewer(pause = 0, showTyping = true){
            if(Object.keys(this.messages).length > 0)return
            //let idW = this.$route.path.split('/')[3]
            //console.log('url: ' + idW)
            let arr = ["Hi! Let's get to know each other. My name is Kate. What's yours?🤗",
                "Hello. I'm feeling a bit lonely today. Let's chat.☺️",
                "Hi there. Tell me about yourself. What do you do? Where are you from?😏",
                "Hello. I need some advice. Can you help? 😇",
                "Hi! Are you looking for a relationship or just here to have fun? 😏",
                "Hey. Does the age of a potential partner matter to you?😉",
                "Hello. I'm searching for my other half. Could it be you?❤️",
                "Hi. How are you? Have you tried online dating before?😉",
                "Hello. Don't hesitate to message me. I'm off today and I want to meet new people.🤗",
                "Hey. Rate me from 1 to 10. I'm curious about how people see me from the outside. 🥰"
            ]
            let index = Math.floor(Math.random() * arr.length);
            let message = arr[index]
            console.log(message)
            if(showTyping)this.typing()
            let eventMessage = {
                manID:0,
                member:{
                    avatar:this.opponent != null ? this.opponent.avatar : '',
                    id:this.opponent != null ? this.opponent.id : '',
                    name:this.opponent != null ? this.opponent.name : '',
                },
                message:{
                    author:"woman",
                    contact_id:null,
                    created_at:new Date().toUTCString(),
                    id: null,
                    message:message,
                    message_translate:null,
                    paid:false,
                    type:"message",
                    updated_at:new Date().toUTCString()
                },
                womanID:this.opponent != null ? this.opponent.id : '',
            }
            let eventEmoji = {
                manID:0,
                member:{
                    avatar:this.opponent != null ? this.opponent.avatar : '',
                    id:this.opponent != null ? this.opponent.id : '',
                    name:this.opponent != null ? this.opponent.name : '',
                },
                message:{
                    author:"woman",
                    contact_id:null,
                    created_at:new Date().toUTCString(),
                    id:null,
                    isReadOpponent:false,
                    message:"",
                    paid:false,
                    src:"047.svg",
                    src_mini:"",
                    type:"emoji",
                    updated_at:new Date().toUTCString()
                },
                womanID:this.opponent != null ? this.opponent.id : '',
            }
            let eventGift = {
                manID:0,
                member:{
                    avatar:this.opponent != null ? this.opponent.avatar : '',
                    id:this.opponent != null ? this.opponent.id : '',
                    name:this.opponent != null ? this.opponent.name : '',
                },
                message:{
                    audioPlay:false,
                    author:"woman",
                    contact_id:null,
                    created_at:new Date().toUTCString(),
                    id:null,
                    isOptionMenu:false,
                    isReadOpponent:true,
                    message:"HART",
                    paid:false,
                    src:"gifts/8RXCh3P0AO4Cjm2qERZ7jjQqCThaX3TRqOYbsZLU.gif",
                    src_mini:1000,
                    type:"gift",
                    updated_at:new Date().toUTCString(),
                    wavesurfer:null
                },
                womanID:this.opponent != null ? this.opponent.id : '',
            }
            setTimeout(()=>{
                this.$bus.$emit('get_new_message_promo', eventMessage);
                setTimeout(()=>{this.$bus.$emit('get_new_message_promo', eventGift);}, pause)
            }, pause)
        },
        setStyleChatContainer(){
            if(this.$mq !== 'desktop') return;
            const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            const windowWidth = window.innerWidth || document.documentElement.clientWidth;
            const chatContainer = this.$refs.chatContainer;
            const parentChatContainer = document.getElementById('profileEl');
            if(!chatContainer || !parentChatContainer) {
                console.log('!chatContainer || !parentChatContainer');
                // console.log(chatContainer)
                // console.log(parentChatContainer)
                return;
            }
            if (this.isSeeFooter) {
                console.log('setStyle isSee');
                // if (chatContainer && windowHeight > chatContainer?.closest('.profile').offsetHeight) {
                if (chatContainer && windowHeight > parentChatContainer.offsetHeight) {
                    const offset = 92;
                    chatContainer.style.top = `-${offset}px`;
                    chatContainer.style.height = `${parentChatContainer.offsetHeight + offset}px`;
                } else if (chatContainer) {
                    chatContainer.style.height = `${windowHeight}px`;
                }
            } else {
                if (chatContainer) chatContainer.style = '';
            }
            setTimeout(()=>{
                this.isCheckHeight = true;
            },100)

        },
        clickChat(){
            if(this.$mq !== 'desktop'){
                this.$bus.$emit('close_emoji_letter');
                if(this.$mq !== 'desktop' && this.$refs.chatInput) this.$refs.chatInput.closeMenuUpload()
            }
        },
        // openDesktopChat(){
        //     if(this.$mq !== 'desktop' || !this.selectedContactId ) return;
        //     console.log('openDesktopChat')
        //     if(!this.selectedContactId) return
        //     const url = this.currentUserRole === 'man' ?
        //         `/open-profile-chat/${this.$router.currentRoute.params.id}/${this.chatOwner.id}/${this.selectedContactId}?type=${this.typeSelectOpponent}`:
        //         `/open-profile-chat/${this.chatOwner.id}/${this.$router.currentRoute.params.id}/${this.selectedContactId}?type=${this.typeSelectOpponent}`
        //     axios.get(url)
        //         .then(response => {
        //             console.log('response openDesktopChat');
        //             console.log(response.data);
        //         })
        //         .catch(e => {
        //             console.log(e);
        //             this.errorHandler(e);
        //         })
        // },
        gotoProfile(){
            console.log('gotoProfile ->' + this.id);
            if(!this.id || this.id==='undefined') return
            // this.$router.push({path:`/profile/${this.id}`});
            if(this.$route.name === 'promoChat') this.$router.push({ name: 'promoProfile', params: { id: this.id }})
            else this.$router.push({ name: 'profile', params: { id: this.id }})

            if(this.$mq !== 'desktop') this.$bus.$emit('close_mobile_contacts');

        },
        initOpponentForChat(){
            if((this.$route.name !== 'chat' && this.$route.name !== 'promoChat') || !this.$router.currentRoute.params.id) return;
            this.$bus.$emit('clear_opponent_messages')
            let url = `/init-profile-promo-for-chat/${this.$router.currentRoute.params.id}`
            if(this.$route.name !=='promoProfile' && this.$route.name !=='promoChat' ){
                url = this.currentUser.role === 'man' ?
                    `/init-profile-for-chat/${this.$router.currentRoute.params.id}/${this.chatOwner.id}?type=${this.typeSelectOpponent}`:
                    `/init-profile-for-chat/${this.chatOwner.id}/${this.$router.currentRoute.params.id}?type=${this.typeSelectOpponent}`
            }

            axios.get(url)
                .then(response => {
                    // this.personalStory = null;
                    console.log('data from chat : ' + response.data)
                    this.initProfileHandel(response.data);

                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        initProfileHandel(data){
            console.log('initProfileHandel');
            console.log(data);
            this.$emit('init_profile', data);
            this.$bus.$emit('chat_scroll_down');
            this.$bus.$emit('reset_unread_messages');
            if(this.isPromoPage(false)){
                setTimeout(()=>{this.meetingPromoViewer(0, false)},0)
            }
        },
        editChatMessage(message) {
            console.log('editChatMessage');
            if(message.type === 'letter') {
                this.readLetter(message);
                this.selectedMessage = message;
                this.isEditLetter = true;
            }else {
                this.editMessage = message;
            }

        },
        endEditChatMessage() {
            this.editMessage = null;
        },
        openMenu() {
            this.$emit('open_menu', false);
        },
        goToRequest() {
            this.$emit('go_to_request', true);
        },
        setConversionProgress(status){
            this.isConversionProgress = status;
        },
        shortName(name){
            let arr = name.split(' ');
            let rez = '';
            arr.forEach(item=>{
                rez += item.charAt(0).toUpperCase();
            })
            return rez;
        },
        leaveContactChannel(contactID){
            if(!window.Echo || this.$route.name === 'promoProfile' || this.$route.name === 'promoChat') return
            Echo.leave(`contact.${contactID}`);
            this.isOpponentInChat = false;
            this.$bus.$emit('is_opponent_in_chat',this.isOpponentInChat);
        },
        buyAttachmentMessage(message,type){
            if( this.chatOwner.credits >= this.tariffs[`tariff_${type}`] ){
                if(!this.disabledButton && this.opponent){
                    this.disabledButton = true;
                    axios.post('/buy-attachment-message',{
                        messagesID:message.id,
                        letterID:message.src,
                        contactID:message.contact_id,
                        author:message.author,
                        type:type,
                        role:this.currentUser.role,
                        chatOwnerID:this.chatOwner.id,
                        opponentID:this.opponent.id,
                        operator:this.opponent.operator_id?this.opponent.operator_id:null,
                        tariff:this.tariffs[`tariff_${type}`]
                    })
                        .then(response => {
                            this.disabledButton = false;
                            console.log('Message Attachment saved');
                            console.log(response.data);
                            this.$bus.$emit('new_balance',response.data.credits);
                            if(response.data.status === 'ok'){
                                this.$bus.$emit('message_paid',message.id);
                                if(type === 'letter'){
                                    this.readLetter(message);
                                }else if (type === 'picture' || type === 'clip'){
                                    console.log('open paid media')
                                    this.openChatMedia({
                                        src: `${this.s3Url}/${message.src}`,
                                        type: type
                                    });
                                }
                                if (this.isOpponentInChat){
                                    this.contactChannel.whisper('paid', {
                                        messageID: message.id
                                    });
                                }
                            }else {
                                this.$router.push({name: 'PaymentPage'})
                                // this.$bus.$emit('open_modal_price');
                                // alert(`Failed to buy ${type}.\n Your balance ${this.chatOwner.credits/100}`)
                                // alert('You do not have enough credits\n' +
                                //     'Refill your balance')
                            }
                        })
                        .catch(error => {
                            this.disabledButton = false;
                            console.log(error);
                            this.errorHandler(error);
                        });
                }

            }else {
                this.$router.push({name: 'PaymentPage'})
                // this.$bus.$emit('open_modal_price');
            }
        },
        replayLetters(){
            this.isWriteLetters = true;
        },
        readLetter(message){
            this.isOpponentAuthorLetter = (this.roleOpponent === message.author);
            this.isWriteLetters = false;
            this.selectedMessage = message;
            axios.get(`/get-letter/${message.src}`)
                .then(response => {
                    console.log(response.data);
                    this.letter = response.data.letter ;
                    this.showLetter = true;
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        openChatMedia(media){
            // this.$emit('open_media', media)
            this.$bus.$emit('open_chat_media',media)
        },
        // openChatMedia(src){
        //     console.log('openChatMedia home');
        //     this.mediaChat[0] = `${this.s3Url}/${src}`;
        //     this.indexMedia = 0;
        // },
        // openChatMedia(media){
        //     console.log(media);
        //     this.mediaChat[0] = media.type === 'clip' ? {src: `${this.s3Url}/${media.src}`, autoplay: true}  : {src: `${this.s3Url}/${media.src}`}
        //     this.indexMedia = 0
        // },
        setShowBtnDown(showBtnDown){
            this.showBtnDown = showBtnDown ;
        },
        moreMessages(){
            this.$emit('more_messages');
        },
        closeLetter() {
            console.log('close letter chat');
            this.selectedMessage = null;
            this.showLetter = false;
            this.isWriteLetters = true;
            this.letter = null;
            this.isEditLetter = false;
            this.editMessage = null;
        },
        extend(){
            this.$emit('extend')
        },
        toggleLetter(){
            this.showLetter = !this.showLetter
            if(this.showLetter && this.$refs.chatLog) this.$refs.chatLog.removeSpeechAudio(false)
        },
        sendEventTyping(){
            console.log('sendEventTyping()')
            if(this.contactChannel){
                this.contactChannel.whisper('typing', {
                    role:this.currentUser.role,
                    typing: true
                });
            }
        },
        connectToOnChannels() {
            console.log('connectToOnChannels');
            if (this.selectedContactId && window.Echo) {
                this.isOpponentInChat = false;
                this.$bus.$emit('is_opponent_in_chat',this.isOpponentInChat);
                this.contactChannel = Echo.join(`contact.${this.selectedContactId}`)
                    .here(users => {
                        console.log(users)
                        this.isOpponentInChat = !!users.find(user=>{
                            return user.role !== this.currentUser.role
                        })
                        this.$bus.$emit('is_opponent_in_chat',this.isOpponentInChat);
                    })
                    .joining(user => {
                        console.log(`visit contact.${this.selectedContactId}`)
                        console.log(user)
                        this.isOpponentInChat = true;
                        this.$bus.$emit('is_opponent_in_chat',this.isOpponentInChat);
                    })
                    .leaving(user => {
                        console.log(`leaving contact.${this.selectedContactId}`)
                        console.log(user)
                        this.isOpponentInChat = false;
                        this.$bus.$emit('is_opponent_in_chat',this.isOpponentInChat);
                    })
                    .listen('ConversionCompleted',event=>{
                        console.log('ConversionCompleted');
                        console.log(event);
                        this.conversionCompletedHandler(event);
                    })
                    .listenForWhisper('typing', (e)=>{
                        console.log("typing")
                        console.log(e)
                        if (e.role !== this.currentUser.role){
                            this.typing()
                        }
                    })
                    .listenForWhisper('paid', (e)=>{
                        console.log('paid')
                        console.log(e)
                        this.$bus.$emit('message_paid',e.messageID);
                    })
            }
        },
        typing(){
            console.log("typing = true")
            this.isTyping = true ;
            clearTimeout(this.typingInterval) ;
            this.typingInterval = setTimeout(()=>{
                this.isTyping = false ;
            }, 2500);
        },
        extendChat() {
            this.$emit('extend')
        },
        conversionCompletedHandler(event){
           if(event.author === this.currentUser.role && this.isConversionProgress){
               this.isConversionProgress = false;
               if (event.status === 'success'){
                   this.pathTempFile = event.path;
                   this.$refs.chatInput.conversionCompleted(`${this.myUrl}/storage/${event.path}`);
               }else {
                   this.pathTempFile = '';
                   this.$refs.chatInput.resetPreviewMedia();
                   alert('File conversion not successful, please select another file');
               }
           }
        },
        getLetter(id){
            this.$emit('getletter',id);
        },
        classMsg(type,author){
            const classContainerData = {
                message:'',
                picture:'msg-photo',
                gif:'msg-photo',
                letter:'msg-letter',
                clip:'msg-video',
                voice:'msg-voice',
                animation:'msg-animation',
                emoji:'msg-emoji'
            }
            let gender = author==='man'?'start':'end';
            return 'msg msg-'+ gender +' '+ classContainerData[type] ;
        },
        selectEmoji(id){
            this.selectedEmojiID = id ;
        },
        close() {
            this.$emit('close_chat')
        },
        closeMobile() {
            this.$bus.$emit('clear_opponent_messages')
            //TODO need checked
            this.$emit('show_chat', false)
            this.$router.back();
        },
        viewProfile() {
            this.close();
            this.$bus.$emit('close_request')
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        closeOptionsMenu(){
          this.isOpenOptions = false;
        },
        isPromoPage(openRegister = true){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat'){
                if(openRegister)this.$bus.$emit('open_register')
                return true
            }else {return false}
        }
    }
}
</script>

<!--<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>-->
