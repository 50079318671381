<template>

    <!--    <div class="intro" :style="{'background-image': `url(${poster})`}" v-if="poster">-->
    <div class="intro">
        <div class="intro__wrapper"
             :style="{'background-image': `url(${poster})`}"
             v-if="poster">
            <landing-video
                :cdn-url="cdnUrl"
                :my-url="myUrl"
                :window-height="windowHeight"
                :cdn-static-data-path="cdnStaticDataPath"
                :is-welcome=false
                :src-video="srcVideo"
                @set_video_el="setVideoEl"
            ></landing-video>

        <div class="intro__caption">
<!--            <p>Hablario Lifestyle - this  is a service where you can meet new friends and have a great time</p>-->
<!--            <span class="intro__line"></span>-->
<!--            <span class="intro__text">Do not be lonely, join us</span>-->
       <span class="intro__text">Hablario - is the only dating platform that is dedicated to helping individuals find and build meaningful relationships</span>
            <button class="button button_lg button_primary" data-modal="sign" @click.prevent="openRegister">
                <span>Sign Up</span>
            </button>
        </div>
<!--        <div class="button button_scroll" @click="scrollDown">-->
<!--            <span class="button__text">Scroll</span>-->
<!--            <span class="button__arrow"></span>-->
<!--        </div>-->
        </div>
    </div>
</template>

<script>

import LandingVideo from "./LandingVideo.vue";

export default {
    name: "LandingVideoBox",
    components: {LandingVideo},
    props:['cdnUrl','myUrl','windowHeight','cdnStaticDataPath','isWelcome','srcVideo','srcPoster'],
    data(){
        return {
            poster: '',
            isSupportedWebp: false,
        }
    },
    mounted() {
        this.initData()
    },
    methods:{
        openRegister(){
           this.$emit('open_register')
        },
        async initData(){
            this.isSupportedWebp = await this.webpIsSupported();
            this.setPosterSrc();
        },
        async webpIsSupported() {
            // If the browser doesn't do the method createImageBitmap, you can't display webp format
            // const self = this;
            if (!window.createImageBitmap) return false;

            // Base64 representation of a white point image
            const webpData = 'data:image/webp;base64,UklGRiQAAABXRUJQVlA4IBgAAAAwAQCdASoCAAEAAQAcJaQAA3AA/v3AgAA=';

            // Retrieve the Image in Blob Format
            const blob = await fetch(webpData)
                .then((r) => r.blob());

            // If the createImageBitmap method succeeds, return true, otherwise false
            return createImageBitmap(blob)
                .then(
                    () => true,
                    () => false,
                );
        },
        scrollDown(){
            this.$emit('scroll_down_lending')
        },
        setVideoEl(el){
            this.$emit('set_video_el',el)
        },
        setPosterSrc() {
            let windowWidth = window.innerWidth;
            let windowHeight = window.innerHeight;
            if( windowWidth/windowHeight < 1 ){
               this.poster = this.cdnUrl + this.cdnStaticDataPath + this.srcPoster.smart
            } else if (windowWidth >= 3840) {
                this.poster = this.cdnUrl + this.cdnStaticDataPath + this.srcPoster.tv
            } else if (windowWidth >= 3440) {
                this.poster = this.cdnUrl + this.cdnStaticDataPath + this.srcPoster.big
            } else if (windowWidth >= 2560) {
                this.poster = this.cdnUrl + this.cdnStaticDataPath + this.srcPoster.high
            } else if (windowWidth >= 1920) {
                this.poster = this.cdnUrl + this.cdnStaticDataPath + this.srcPoster.xl
            }else if (windowWidth >= 1440) {
                this.poster = this.cdnUrl + this.cdnStaticDataPath + this.srcPoster.lg
            } else{
                this.poster = this.cdnUrl + this.cdnStaticDataPath + this.srcPoster.md
            }
            //if(this.isSupportedWebp) this.poster = this.poster.replace('.jpg','.webp')
        },
    // 'tv'=> '/lending/3840_2160.mp4',
    // 'big'=> '/lending/3440_1440.mp4',
    // 'high'=> '/lending/2560_1440.mp4',
    // 'xl'=> '/lending/1920_1080.mp4',
    // 'lg'=> '/lending/1440_1024.mp4',
    // 'md'=> '/lending/1280_720.mp4',
    // 'smart'=>'/lending/434_864.mp4',
    }
}
</script>

<style scoped>

</style>
