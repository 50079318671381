<template>

    <div class="panel panel_success" :class="{'panel_mobile' : $mq !=='desktop'}">
        <div class="panel__container">
            <div class="panel__header">
                <h1>Welcome to <span>Hablario</span></h1>
            </div>
            <div class="panel__body">
                <p>You have successfully registered</p>
                <p>Your account is being checked by the moderator</p>
                <p>After that, a confirmation of your validation will be sent to the email you specified</p>
                <p>Thank you for choosing our project</p>
            </div>
            <div class="panel__footer">
                               <button class="btn btn_confirm" @click="logoutUser">Exit</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AwaitingVerification",
    methods:{
        logoutUser(){
            this.$emit('logout')
        }
    }
}
</script>

<style scoped>

</style>
