<template>
    <div id="tab2" :class="{'tab-pane tab-pane_history': $mq ==='desktop', 'request request_mob' : $mq !=='desktop'}">
        <template v-if="$mq==='desktop'">
            <list-contact-opponents
                :s3-url="s3Url"
                :current-user="currentUser"
                :my-url="myUrl"
                :contacts="contactsHistory"
                :role-opponent="roleOpponent"
                :type="'history'"
                :ban-list="banList"
                :is-all="isAllHistory"
                :is-download="isDownloadHistory"
                :is-new-version="false"
                :is-supported-webp="isSupportedWebp"
                @get_more_contacts="getMoreHistory"
            ></list-contact-opponents>
        </template>
        <template v-else>
            <div class="request__container" :style="`height: ${windowHeight}px`">
                <div class="request__header mobile-head">
                    <div class="request__col">
                        <button class="btn btn_icon btn_back" @click="closeHistory">
                            <span class="holiday">
                                <i class="icon icon-btn-back"></i>
                            </span>
                            <i class="icomoon icon-left"></i>
                        </button>
                        <span class="title">History</span>
                    </div>
                    <div class="actions">
                        <button class="btn btn_icon btn_req" @click.prevent="closeHistory">
                            <i class="icomoon icon-chats">
                            <i class="icomoon icon-bubble" v-if="countUnread">
                                <span :class="{'text-small':(countUnread > 99)}">{{textCountUnread}}</span>
                            </i></i>
                        </button>
                        <button class="btn btn_icon btn_menu" @click.prevent="openMenu"><i class="icomoon icon-group"></i></button>
                    </div>
                </div>
<!--                <div class="request__body">-->
                    <list-contact-opponents
                        :s3-url="s3Url"
                        :current-user="currentUser"
                        :my-url="myUrl"
                        :contacts="contactsHistory"
                        :role-opponent="roleOpponent"
                        :type="'history'"
                        :is-new-version="false"
                        :ban-list="banList"
                        :is-all="isAllHistory"
                        :is-download="isDownloadHistory"
                        :is-supported-webp="isSupportedWebp"
                        @get_more_contacts="getMoreHistory"
                    ></list-contact-opponents>
<!--                </div>-->
            </div>
        </template>

    </div>
</template>

<script>
import ListContactOpponents from "./ListContactOpponents";
export default {
name: "ChatHistory",
    components: {
        ListContactOpponents,
        },
    props: [
        'currentUser',
        's3Url',
        'chatOwner',
        'isAllHistory',
        'myUrl',
        'contacts',
        'roleOpponent',
        'selectedContact',
        'indicatorNewMan',
        'indicatorNewWoman',
        'searchContact',
        'banList',
        'windowHeight',
        'countUnread',
        'textCountUnread',
        'countContacts',
        'isSupportedWebp'
    ],
    data(){
        return{
            isDownloadHistory:false,
        }
    },
    computed:{
        contactsHistory(){
            return this.contacts.filter(contact => !contact[`hidden_${this.currentUser.role}`])
        }
    },

    methods:{
        openMenu() {
            this.$emit('open_menu', false);
        },
        // goToRequest() {
        //     this.$emit('go_to_request');
        // },
        closeHistory(){
            this.$emit('close_history');
        },
        closeFavorite(){
            this.$emit('close_favorite');
        },
        removeOpponent(memberID){
            if(confirm('Are you sure you want to delete your communication history with this user? If you choose "Confirm your choice", all messages from this user will be deleted.')){
                const url = this.currentUser.role === 'man'?
                    '/remove-contacts-for-man':
                    '/remove-contacts-for-woman';
                console.log(url)
                // axios.get(url)
                //     .then(response => {
                //         console.log(response.data);
                //         this.isDownloadHistory = false ;
                //         this.$bus.$emit('add_contacts',response.data.contacts,response.data.messages,'history')
                //     })
                //     .catch(e => {
                //         console.log(e);
                //         this.isDownloadHistory = false ;
                //         this.errorHandler(e);
                //     })
            }
        },
        getMoreHistory(){
            if(!this.contacts || !this.this.contacts.length) return;
            if (!this.isAllHistory && !this.isDownloadHistory){
                const lastContact = this.contacts[this.contacts.length-1]
                if(!lastContact) return;
                this.isDownloadHistory = true ;
                // const url = this.currentUser.role === 'man'?
                //     '/get-more-history-for-man':
                //     '/get-more-history-for-woman';

                axios.post('/get-more-history/v2',{
                    last: lastContact.last_time,
                    ownerID: this.chatOwner.id,
                    role: this.currentUser.role,
                    roleOpponent: this.roleOpponent,
                    countContacts: this.countContacts
                })
                    .then(response => {
                        console.log(response.data);
                        this.isDownloadHistory = false ;
                        this.$bus.$emit('add_contacts',{
                            contacts:response.data.contacts,
                            messages:response.data.messages,
                            countUnread:response.data.countUnread,
                            type:'history',
                        })
                    })
                    .catch(e => {
                        console.log(e);
                        this.isDownloadHistory = false ;
                        this.errorHandler(e);
                    })

            }
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },

    }
}
</script>

<style scoped>

</style>
