<template>
    <portal selector="#portal-target">
        <div class="modal modal_md modal_tariffs is-active" tabindex="-1" role="dialog">
            <div class="modal__bg" @click.prevent="closeServicePrices"></div>
            <div class="modal__container">
                <span class="modal__close" @click.prevent="closeServicePrices" role="button" aria-label="Close"><i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h2 class="modal__title">Service Prices</h2>
                    <span class="modal__label">Credits</span>
                </div>
                <div class="form">
                    <div class="form__row">
                        <ul class="description description_tariffs list">
                            <li class="description__item list__item">
<!--                                <span class="description__tariff"><i class="icon icon_chat"></i>Live chat - 1 minute</span>-->
<!--                                <span class="description__credit">{{ tariffs.tariff_chat/100 }}</span>-->
                                <span class="description__tariff"><i class="icon icon_chat"></i>Live chat - 1 minute</span>
                                <span class="description__credit">{{ tariffs.tariff_chat/100 }}</span>
                            </li>
                            <li class="description__item list__item">
                                <span class="description__tariff"><i class="icon icon_letter-full"></i>Letter</span>
                                <span class="description__credit">{{ tariffs.tariff_letter/100 }}</span>
                            </li>
                            <li class="description__item list__item">
                                <span class="description__tariff"><i class="icon icon_camera"></i>Photo</span>
                                <span class="description__credit">{{ tariffs.tariff_picture/100 }}</span>
                            </li>
                            <li class="description__item list__item">
                                <span class="description__tariff"><i class="icon icon_video"></i>Video</span>
                                <span class="description__credit">{{ tariffs.tariff_clip/100 }}</span>
                            </li>
<!--                            <li class="description__item list__item">-->
<!--                                <span class="description__tariff"><i class="icon icon_box"></i>Multimedia pack</span>-->
<!--&lt;!&ndash;                                <span class="description__credit">{{ tariffs.tariff_pack/100 }}</span>&ndash;&gt;-->
<!--                                <span class="description__credit">50</span>-->
<!--                            </li>-->
                            <li class="description__item list__item">
                                <span class="description__tariff"><i class="icon icon_mic"></i>Voice</span>
                                <span class="description__credit">{{ tariffs.tariff_voice/100 }}</span>
                            </li>
                        </ul>
                    </div>
                    <div class="form__button">
                        <button class="button button_sm button button_bordered button_rounded button_presentation" @click.prevent="goPaymentPage">
                            <span>
                                <span class="button__text">Buy credits</span>
                                <span class="button__icon"><i class="icon icon_buy-credits"></i></span>
                            </span>
                        </button>
                    </div>
                    <div class="form__footer">
                <span class="form__text">
                    <i class="icon icon_warning"></i>Before buying credits, please read
                </span>
                        <span class="form__text">
                            <a class="form__link" :href="`${myUrl}/terms-and-conditions-short`" target="_blank">Terms&Conditions</a>
                            <a class="form__link" :href="`${myUrl}/cookies-policy`" target="_blank">Cookies Policy</a>
                            of Hablario</span>
                    </div>
                </div>
            </div>
        </div>
    </portal>
</template>


<script>
export default {
    name: "ServicePrices",
    props: ['showPrice', 'myUrl', 'tariffs'],

    data(){
        return{

        }
    },
    mounted() {
        console.log('open ServicePrices')
        this.sendStatistics()
    },
    methods:{
        sendStatistics(){
            axios.get(`/open-modal-price`)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        goPaymentPage(){
            this.$emit('close_price')
            this.$router.push({name: 'PaymentPage'})
        },
        closeServicePrices(){
            this.$emit('close_price')
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    }
}
</script>

<style scoped>

</style>
