<template>
    <div class="profile__card">
        <ul class="list profile__stories-list">
            <li class="list__item" :ref="`post${post.id}`"  v-for="(post, index) in myStories" :key="post.id">
                <div class="post post_stories" :ref="`postContainer${post.id}`" tabindex="-1">
                    <div class="post__meta">
                        <div class="post__meta-data">
                            <span class="post__date">{{writeDateFull(new Date(post.created_at))}}</span>
<!--                            <span class="post__category">{{post.interest ? post.interest.title.toUpperCase():'Personal'}}</span>-->
                        </div>
                    </div>
                    <h2 class="post__heading">{{post.subject}}</h2>

<!--                    v-if="!(post.isShowStory && $mq !=='desktop')"-->
                    <div class="post__poster poster poster_horizontal" :class="{'post__poster_locked': post.isNeedPaid}">
                        <picture @click.prevent="openViewPost(post)">
                            <source type="image/webp" :srcset="`${s3Url}/${$mq==='desktop'?post.poster_webp:post.poster_gallery_webp}`" v-if="post.poster_webp">
                            <img class="poster__media" :src="`${s3Url}/${$mq==='desktop'?post.poster:post.poster_gallery}`"
                                 alt="post media" width="810"
                                 height="488">
                        </picture>
                        <span class="button button_bg post__button" @click.prevent="openViewPost(post)" v-if="post.type_src==='image'">
                            <i class="button__icon icon icon_camera-white"></i>
                        </span>
                        <span class="button button_bg post__button" @click.prevent="openViewPost(post)" v-else-if="post.type_src==='video'">
                            <i class="button__icon icon icon_video-white"></i>
                        </span>
                        <div class="post__overlay" v-if="post.isNeedPaid">
                            <div class="post__overlay-content">
                                <i class="icon icon_lock"></i>
                                <h4 class="title">Paid Content</h4>
                                <p>To open this photo you need to pay <strong class="sub-title">{{Number(post.credits/100).toFixed(0)}} credits</strong></p>
                                <button class="button button_primary" @click.prevent="buyStory(post)">
                                    <span>Show Now</span>
                                </button>
                            </div>
                        </div>

                    </div>
                    <div class="post__body">
                        <template v-if="post.isShowStory && !post.isNeedPaid && post.medias">
                            <div v-if="post.medias.length && $mq ==='desktop'" class="slider slider_portfolio">
                                <div class="slider__container" :ref="`sliderContainer${post.id}`" tabindex="-1">
                                    <hooper
                                        :itemsToShow="3"
                                        :trimWhiteSpace="true"
                                        :itemsToSlide="1"
                                        :transition="500"
                                        :infiniteScroll="false"
                                        :playSpeed="5000"
                                        :ref="`sliderPortfolio_${post.id}`">
                                        <template v-if="post.type_src === 'image'">
                                            <slide class="slider__item" v-for="(media, el) in post.medias" :key="media.id">
                                                <picture @click.prevent="showStoryMedia(post.medias,el,post.type_src)">
                                                    <source type="image/webp" :srcset="`${s3Url}/${media.src_webp}`" v-if="isSupportedWebp && media.src_webp">
                                                    <img class="slider__media" :src="`${s3Url}/${media.src}`" alt="" @click.capture="index = el">
                                                </picture>
                                            </slide>
                                        </template>
                                        <template v-if="post.type_src === 'video'">
                                            <slide class="slider__item" v-for="(media, el) in post.medias" :key="media.id">
                                                <button class="button button_video-play"
                                                        @click.stop.capture="showStoryMedia(post.medias,el,post.type_src)">
                                                    <span class="button__icon">
                                                        <span class="triangle triangle_r triangle_sm"></span>
                                                    </span>
                                                </button>
                                                <video class="slider__media" playsinline muted @click.prevent="showStoryMedia(post.medias,el,post.type_src)">
                                                    <source :src="`${s3Url}/${media.src_webp}`" type="video/webm" v-if="isSupportedWebp && media.src_webp"/>
                                                    <source :src="`${s3Url}/${media.src}`" type="video/mp4" />
                                                </video>
                                            </slide>
                                        </template>
                                    </hooper>

                                    <button class="slider__button slider__button_prev button" @click.prevent="slidePortfolioPrev(post.id)" v-if="post.medias.length > 3">
                                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                             height="12" fill="none">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                        </svg>
                                    </button>
                                    <button class="slider__button slider__button_next button" @click.prevent="slidePortfolioNext(post.id)" v-if="post.medias.length > 3">
                                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                             height="12" fill="none">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
<!--                            <div v-if="post.medias && post.medias.length && $mq !=='desktop'" class="slider slider_portfolio">-->

<!--                                <div class="slider__container" :ref="`sliderContainer${post.id}`" tabindex="-1">-->
<!--                                    <hooper-->
<!--                                        :transition="500"-->
<!--                                        :wheelControl="false"-->
<!--                                        :ref="`sliderPortfolio_`">-->
<!--                                        <template v-if="post.type_src === 'image'">-->
<!--                                            <slide class="slider__item" v-for="(photo, el) in post.medias" :key="photo.id">-->
<!--                                                &lt;!&ndash;                                    @click.capture="index"&ndash;&gt;-->
<!--                                                <picture @click.prevent="showStoryMedia(post.medias,el,post.type_src)">-->
<!--                                                    <source type="image/webp" :srcset="`${s3Url}/${photo.src_webp}`" v-if="isSupportedWebp && photo.src_webp">-->
<!--                                                    <img class="slider__media" :src="`${s3Url}/${photo.src}`" alt="photo portfolio" @click.capture="index = el">-->
<!--                                                </picture>-->
<!--                                            </slide>-->
<!--                                        </template>-->
<!--                                        <template v-if="post.type_src === 'video'">-->
<!--                                            <slide class="slider__item" v-for="(media, el) in post.medias" :key="media.id">-->
<!--                                                &lt;!&ndash;                                    @click.capture="index"&ndash;&gt;-->
<!--                                                <button class="button button_video-play"-->
<!--                                                        @click.stop.capture="showStoryMedia(post.medias,el,post.type_src)">-->
<!--                                                    <span class="button__icon">-->
<!--                                                        <span class="triangle triangle_r triangle_sm"></span>-->
<!--                                                    </span>-->
<!--                                                </button>-->
<!--                                                <video class="slider__media" playsinline muted @click.prevent="showStoryMedia(post.medias,el,post.type_src)">-->
<!--                                                    <source :src="`${s3Url}/${media.src_webp}`" type="video/webm" v-if="isSupportedWebp && media.src_webp"/>-->
<!--                                                    <source :src="`${s3Url}/${media.src}`" type="video/mp4" />-->
<!--                                                </video>-->
<!--                                            </slide>-->
<!--                                        </template>-->
<!--                                        <hooper-pagination slot="hooper-addons"></hooper-pagination>-->
<!--                                    </hooper>-->
<!--                                </div>-->
<!--                            </div>-->
                        </template>

                        <div class="post__content">
                            <div class="post__short" v-if="!post.isShowStory">
                                <p class="post__short-text" v-if="post.description">{{post.description.substring(0,300)}}...</p>
                                <span class="button button_link" @click.prevent="openViewPost(post,true)" v-if="!post.isNeedPaid">View More</span>
                            </div>
                            <div class="post__full" v-else>
                                <template v-if="post.description">
                                    <p v-for="line in post.description.split(/[\r\n]+/)">
                                        {{line}}
                                    </p>
                                </template>
                            </div>
                            <div class="post__info">
                                <button class="button button_like"  @click.prevent="likePost(post)" v-if="!post.like"></button>
                                <button class="button button_like is-active"  @click.prevent="dislikePost(post)" v-else></button>
                                <span class="post__like">{{post.counter_like}}</span>
                                <span class="post__view">
                                    <i class="post__icon icon icon_eye"></i><span>{{post.counter_visitors}}</span>
                                </span>
                            </div>
                        </div>
                        <div class="post__additional" v-if="post.isShowStory">
                            <div class="post__form form">
                                <h4 class="post__form-title">Leave a comment</h4>
                                <div class="post__comment">
                                    <picture v-if="memberAvatar">
                                        <source type="image/webp" :srcset="`${s3Url}/${memberAvatarWebp}`" v-if="memberAvatarWebp && isSupportedWebp">
                                        <img class="avatar avatar_sm" :src="`${s3Url}/${memberAvatar}`" alt="avatar" width="50" height="50">
                                    </picture>
                                    <img class="avatar__image" :src="`${myUrl}/source/images/icons/holder-user.svg`"
                                         alt="holder" width="65" height="65" v-else>
                                    <div class="form__input post__input">
                                        <textarea class="ui-textarea post__textarea" placeholder="Write your comment..." maxlength="512" v-model="post.newComment"></textarea>
                                        <div class="form__buttons">
                                            <!--                                            <button class="button button_icon"><i class="icon icon_emoji"></i></button>-->
                                            <button class="button button_icon" @click.prevent="addNewComment(post)">
                                                <i class="icon icon_send"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <button class="post__less button button_icon" @click.prevent="lessStory(post)">
                                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28" height="12" fill="none">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-width="3" d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="post__comments" v-if="post.story_comments && post.story_comments.length">
                                <h4 class="title">Comments</h4>
                                <ul class="post__comments-list list">
                                    <li class="post__comments-media" v-for="comment in post.story_comments" :key="comment.id">
                                        <template v-if="!comment[myRole] && +comment[`${roleOpponent}_id`] === +opponentID">
                                            <img class="avatar avatar_sm" :src="`${s3Url}/${comment[roleOpponent].avatar}`" v-if="comment[roleOpponent].avatar" alt="image avatar">
                                            <div class="avatar avatar_sm avatar_holder" v-else>
                                                <img class="avatar__image" :src="`${myUrl}/source/images/icons/holder-user.svg`"
                                                     alt="holder" width="65" height="65">
                                            </div>
                                        </template>
                                        <template v-else>
                                            <img class="avatar avatar_sm" :src="`${s3Url}/${comment[myRole].avatar}`" v-if="comment[myRole].avatar" alt="image avatar">
                                            <div class="avatar avatar_sm avatar_holder" v-else>
                                                <img class="avatar__image" :src="`${myUrl}/source/images/icons/holder-user.svg`"
                                                     alt="holder" width="65" height="65">
                                            </div>
                                        </template>

                                        <div class="post__comments-body">
                                            <span class="post__comment-author">{{comment[myRole] ? comment[myRole].name : ''}}</span>
                                            <span class="post__comment-date">{{writeDateFull(new Date(comment.created_at))}}</span>
                                            <p class="post__comment">{{comment.text}}</p>
                                        </div>
                                    </li>
                                    <li v-if="!post.isAllComments">
                                        <span class="button button_link" @click.prevent="getMoreComment(post)">View More Comments</span>
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </div>
            </li>
            <li id="end_stories" class="end_stories_list">Observer</li>
        </ul>
    </div>

</template>

<script>

let observer = null;
let itemEnd = null;
const month = ['','January','February','March','April','May','June','July','August','September','October','November','December'];
// import StoryPost from "./StoryPost";

export default {
    name: "ShowStories",
    // components: {StoryPost},
    props:[
        'myUrl',
        's3Url',
        'myStories',
        'myRole',
        'memberID',
        'memberAvatar',
        'memberAvatarWebp',
        'memberColor',
        'memberName',
        'roleOpponent',
        'opponentID',
        'opponentName',
        'opponentAvatar',
        'opponentColor',
        'isOperator',
        'operatorID',
        'isSupportedWebp'
    ],
    data(){
        return{
            // mediaStory: [],
            isExtendStories:false,
            isGetMoreStories:false,
            isAllStories:false,
            // indexMedia: null,
            isDisableButton: false
        }
    },
    created() {
        console.log('created component view story')
        // this.getOpponentStories();
    },
    mounted() {
        console.log('mounted view story');
        this.initScrollObserver();

        // this.$bus.$on('get_my_stories',this.getMyStories);
    },
    beforeDestroy() {
        if (observer && itemEnd) {
            observer.unobserve(itemEnd)
            observer = null
            itemEnd = null
        }
    },
    // watch:{
    //     myStories(newValue,oldValue){
    //         if(newValue && newValue.length && !observer) this.initScrollObserver();
    //     }
    // },
    methods:{
        lessStory(post){
            post.isShowStory = false;
            if(this.$refs[`postContainer${post.id}`] && this.$refs[`postContainer${post.id}`][0]) {
                console.log('focus')
                const el = this.$refs[`postContainer${post.id}`][0];
                console.log(el)
                setTimeout(()=>{
                    // el.focus();
                    el.scrollIntoView({ behavior: 'smooth' });
                },300)

            } else {
                console.log(`postContainer${post.id}`)
                console.log(this.$refs[`postContainer${post.id}`])
            }
        // :ref="`postContainer${post.id}`"
        },
        showStoryMedia(medias,index,type){
            console.log('showStoryMedia '+ index)
            const mediaStory = medias.map(item => (type === 'video' ? {src: `${this.s3Url}/${item.src}`, autoplay: true}  : {src: `${this.s3Url}/${item.src}`}));
            this.$emit('show_medias', mediaStory, index)
        },
        getMoreComment(post){
            console.log('getMoreComment postId -'+ post.id)
            if(this.isDisableButton) return
            this.isDisableButton = true;
            post.isShowStory = true;
            axios.get(`/get-more-comments-my-story-profile/${post.id}/${this.myRole}/${this.memberID}/${post.story_comments[post.story_comments.length-1].id}`)
                .then(response => {
                    this.isDisableButton = false;
                    console.log(response.data);
                    post.story_comments = post.story_comments.concat(response.data.comments);
                    post.isAllComments = response.data.comments.length < 3;
                })
                .catch(e => {
                    this.isDisableButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        slidePortfolioPrev(post_id) {
            this.$refs[`sliderPortfolio_${post_id}`][0].slidePrev();
        },
        slidePortfolioNext(post_id) {
            this.$refs[`sliderPortfolio_${post_id}`][0].slideNext();
        },
        gotoProfile(opponentID){
            if(+opponentID!==this.opponentID) this.$router.push({ name: 'profile', params: {id: opponentID }});
        },
        initScrollObserver(){
            console.log('initScrollObserver');
            itemEnd = document.getElementById(`end_stories`);
            if(!itemEnd) return;
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            };
            observer = new IntersectionObserver(this.callbackObserver, options);
            observer.observe(itemEnd);
        },

        callbackObserver(entries, observer){
            console.log('callbackObserver');
            entries.forEach((entry, index) => {
                if (entry.isIntersecting && entry.target.id === `end_stories`){
                    if (!this.isAllStories) this.getMoreMyStory();
                }
            });
        },
        buyStory(story){
            console.log('buyStory storyid: ' + story.id)
            axios.post('/buy-story-media',{
                storyId: story.id,
                chatOwnerID: this.memberID,
                opponentID: this.opponentID,
                role: this.myRole
            })
                .then(response => {
                    console.log(response.data);
                    if(response.data.status==='ok'){
                        story.isNeedPaid = false;
                        story.isShowStory = true;
                        story.medias = response.data.myStoryMedias;
                        this.$bus.$emit('new_balance',response.data.credits)
                    }else if(response.data.status==='not credits'){
                        this.$router.push({name:'PaymentPage'});
                    }

                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        openMenu() {
            this.$emit('open_menu', false);
        },
        goToRequest() {
            this.$emit('go_to_request',false);
        },
        onScroll(){
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            // console.log(scrollTop,this.$refs.storiesContainer.offsetHeight);
            if (!this.isAllStories && !this.isGetMoreStories && this.$refs.storiesContainer && scrollTop > this.$refs.storiesContainer.offsetHeight*0.8){
                console.log('onScroll new');
                this.getMoreMyStory();
            }
        },
        getMoreMyStory(){
            if(this.myStories.length && !this.isGetMoreStories) {
                this.isGetMoreStories = true;
                axios.get(`/get-more-my-stories-profile/${this.roleOpponent}/${this.opponentID}/${this.myRole}/${this.memberID}/${this.myStories[this.myStories.length - 1].id}`)
                    .then(response => {
                        console.log(response.data);
                        this.isGetMoreStories = false;
                        this.isAllStories = response.data.myStories.length < 10;
                        this.$emit('more_my_stories_handler',response.data);

                    })
                    .catch(e => {
                        this.isGetMoreStories = false;
                        console.log(e);
                        this.errorHandler(e);
                    })
            }

        },
        shortName(name){
            let arr = name.split(' ');
            let rez = '';
            arr.forEach(item=>{
                rez += item.charAt(0).toUpperCase();
            })
            return rez;
        },
        // getOpponentStories(){
        //     console.log('getOpponentStories');
        //     if (!this.myStories.length){
        //         axios.get(`/get-opponent-stories/${this.roleOpponent}/${this.opponentID}/${this.myRole}/${this.memberID}`)
        //             .then(response => {
        //                 console.log(response.data);
        //                 this.myStoriesHandel(response.data.myStories,response.data.likes,response.data.dislikes);
        //
        //             })
        //             .catch(e => {
        //                 console.log(e);
        //                 this.errorHandler(e);
        //             })
        //     }
        // },
        // myStoriesHandel(myStories,likes,dislikes){
        //     this.myStories = myStories.filter(story => (!story.hidden && story.is_publish && story.src && Number(story.cost) === 0));
        //     this.addFieldsPost(this.myStories,likes,dislikes)
        // },
        likePost(post){
            const formData = new FormData ();
            formData.append('id',this.memberID);
            formData.append('role',this.myRole);
            formData.append('storyID',post.id);
            axios.post(`/like-story`,formData)
                .then(response => {
                    console.log(response.data);
                    if (response.data.action === 'create'){
                        post.like = true;
                        post.dislike = false;
                        post.counter_like ++
                    }else {
                        post.like = false;
                        post.counter_like --
                    }
                    if (response.data.dislike === 'deleted'){
                        post.counter_dislike --
                    }

                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        dislikePost(post){
            const formData = new FormData ();
            formData.append('id',this.memberID);
            formData.append('role',this.myRole);
            formData.append('storyID',post.id);
            axios.post(`/dislike-story`,formData)
                .then(response => {
                    console.log(response.data);
                    // post.dislike = (response.data.action === 'create');
                    if (response.data.action === 'create'){
                        post.dislike = true;
                        post.like = false;
                        post.counter_dislike ++
                    }else {
                        post.dislike = false;
                        post.counter_dislike --
                    }
                    if (response.data.like === 'deleted'){
                        post.counter_like --
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        addNewComment(post){
            if (post.newComment && post.newComment.replace(/\s+/g, '').length !== 0){
                // if (this.isOperator){
                //     const strArr = post.newComment.split('') ;
                //     const charRu = strArr.find(char=>{
                //         return  char.charCodeAt(0) >= 127
                //     })
                //     if (charRu){
                //         alert('Cyrillic characters cannot be used in chat - '+ charRu);
                //         return;
                //     }
                // }
                const member = {
                    name:this.memberName,
                    avatar:this.memberAvatar,
                    color:this.memberColor
                }
                const formData = new FormData ();
                formData.append('id',this.memberID);
                formData.append('role',this.myRole);
                formData.append('text',post.newComment);
                formData.append('storyID',post.id);
                formData.append('opponentID',this.opponentID);
                formData.append('operatorID',this.operatorID);
                formData.append('roleOpponent',this.roleOpponent);
                formData.append('member',JSON.stringify(member));

                axios.post(`/create-new-story-comment`,formData)
                    .then(response => {
                        console.log(response.data);
                        if (response.data.status === 'ok'){
                            post.newComment = '';
                            // const comment = response.data.comment;
                            // comment.author = response.data.author
                            post.story_comments.unshift(response.data.comment)
                        }
                    })
                    .catch(e => {
                        this.disabledButton = false;
                        console.log(e);
                        this.errorHandler(e);
                    })
            }else {
                alert('The comment must contain text')
            }
        },
        // writeDate(date){
        //     const arr = date.split('.')
        //     return `${month[Number(arr[1])]} ${arr[0]}, ${arr[2]}`
        // },
        writeDateFull(date){
            const fullDate = new Date(date);
            return `${month[Number(fullDate.getMonth()+1)]} ${fullDate.getDate()}, ${fullDate.getFullYear()}`
        },
        // viewPostUp(post){
        //     this.$refs[`post${post.id}`][0].classList.toggle('is-full');
        // },
        openViewPost(post,isView=false) {
           if(this.isDisableButton || post.isNeedPaid) return
           this.isDisableButton = true;
           if (this.$mq === 'desktop' || isView) post.isShowStory = true;
           axios.get(`/get-full-my-story-profile/${post.id}/${this.myRole}/${this.memberID}`)
               .then(response => {
                   this.isDisableButton = false;
                   console.log(response.data);
                   post.story_comments = response.data.comments;
                   post.medias = response.data.myStoryMedias;
                   post.isAllComments = response.data.comments.length < 3;
                   if (this.$mq !== 'desktop' && !isView) this.showStoryMedia(post.medias,0,post.type_src)
               })
               .catch(e => {
                   this.isDisableButton = false;
                   console.log(e);
                   this.errorHandler(e);
               })

        },
        extendStories(){
            this.$emit('extend_stories');
        },
        closeStories(){
            this.$emit('close_stories');
        },
        toggleChat(){
            this.$emit('toggle_chat');
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },

    }
}
</script>

<style scoped>
    .end_stories_list {
        position: absolute;
        visibility: hidden;
        height: 50px;
    }

</style>

<!--            <story-post ref="storymyStories"-->
<!--                        :opponent-name="opponentName"-->
<!--                        :opponent-i-d="opponentID"-->
<!--                        :opponent-avatar="opponentAvatar"-->
<!--                        :role-opponent="roleOpponent"-->
<!--                        :member-name="memberName"-->
<!--                        :member-avatar="memberAvatar"-->
<!--                        :member-color="memberColor"-->
<!--                        :member-i-d="memberID"-->
<!--                        :my-url="myUrl"-->
<!--                        :s3-url="s3Url"-->
<!--                        :my-role="myRole"-->
<!--                        :interests="interests"-->
<!--                        :operatorID="operatorID"-->
<!--                        :is-operator="isOperator"-->
<!--                        @errorhandler="errorHandler"-->
<!--            ></story-post>-->

<!--                <div class="post" :ref="`post${post.id}`"  v-for="(post, index) in myStories" :key="post.id">-->
<!--                    <div class="post__media">-->
<!--                        <img :src="`${s3Url}/${post.src}`" alt="" v-if="post.type_src === 'image'">-->
<!--                        <video controls="controls" controlslist="nodownload"  playsinline v-else-if="post.type_src === 'video'">-->
<!--                            <source :src="`${s3Url}/${post.src}`" type="video/mp4">-->
<!--                        </video>-->
<!--                        <audio controls v-if="post.type_src === 'audio'">-->
<!--                            <source :src="`${s3Url}/${post.src}`"  type="audio/mp3">-->
<!--                        </audio>-->
<!--                    </div>-->
<!--                    <div class="post__body">-->
<!--                        <p class="post__meta">-->
<!--                            <span class="post__date">{{writeDate(new Date(post.created_at).toLocaleDateString())}} /-->
<!--                                <span class="post__category">{{post.interest ? post.interest.title.toUpperCase():'Personal'}}</span></span>-->
<!--                            <span class="post__view"><i class="fe fe-eye"></i>{{post.counter_visitors}}</span>-->
<!--                        </p>-->
<!--                        <h3 class="post__title">{{post.subject}}</h3>-->
<!--                        <p class="post__content">-->
<!--                            {{post.description.substring(0,300)}}-->
<!--                            <span class="post__dots" v-if="post.description.length>300">...</span>-->
<!--                            <span class="post__more" v-if="post.description">{{post.description}}</span>-->
<!--                        </p>-->
<!--                        <div class="post__additional">-->
<!--                            <div class="post__comments">-->
<!--                                <h4 class="title">Comments</h4>-->
<!--                                <ul class="list">-->
<!--                                    <li class="media" v-for="comment in post.story_comments" :key="comment.id">-->
<!--                                        <img :src="`${s3Url}/${memberAvatar}`" alt="">-->
<!--                                        <div class="media__body">-->
<!--                                            <span class="author">{{memberName}}</span>-->
<!--                                            <span class="date">{{writeDate(new Date(comment.created_at).toLocaleDateString())}}</span>-->
<!--                                            <p class="comment">{{comment.text}}</p>-->
<!--                                        </div>-->
<!--                                    </li>-->

<!--                                </ul>-->
<!--                            </div>-->
<!--                            <div class="post__form">-->
<!--                                <h4 class="title">Leave a replay</h4>-->
<!--                                <div class="form">-->
<!--                                    <textarea class="ui-textarea" placeholder="Comment*" v-model="post.newComment"></textarea>-->
<!--                                    <button class="btn btn_bordered" @click.prevent="addNewComment(post)">Add Comment</button>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <button class="btn btn_icon btn_less" @click.prevent="viewPostUp(post)"><i class="icon icon-arrow-up"></i></button>-->
<!--                        </div>-->
<!--                        <div class="post__actions">-->
<!--                            <div class="post__btn">-->
<!--                                <button class="btn btn_bordered" @click.prevent="openViewPost(post)">View more</button>-->
<!--                            </div>-->
<!--                            <div class="post__social">-->
<!--                                <span class="post__icon" :class="{'is-like':post.like}">-->
<!--                                    <i class="icomoon icon-like" @click.prevent="likePost(post)"></i>{{post.counter_like}}-->
<!--                                </span>-->
<!--                                <span class="post__icon" :class="{'is-dislike':post.dislike}">-->
<!--                                    <i  class="icomoon icon-dislike" @click.prevent="dislikePost(post)"></i>{{post.counter_dislike}}-->
<!--                                </span>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->


<!--                                    <li class="media">-->
<!--                                        <img src="https://lifeforlove.test/storage/old/Images/590/avatar/3852.jpg" alt="">-->
<!--                                        <div class="media__body">-->
<!--                                            <span class="author">Gabby-Natal</span>-->
<!--                                            <span class="date">{{writeDate(new Date(post.created_at).toLocaleDateString())}}</span>-->
<!--                                            <p class="comment">Yes, a cool film, I also really liked one of the best films of Quentin Tarantino, I am also a fan of all of his work</p>-->
<!--                                        </div>-->
<!--                                    </li>-->
<!--                                    <li class="media">-->
<!--                                        <img src="https://lifeforlove.test/storage/old/Images/590/avatar/3852.jpg" alt="">-->
<!--                                        <div class="media__body">-->
<!--                                            <span class="author">Katharine</span>-->
<!--                                            <span class="date">October 30, 2020 </span>-->
<!--                                            <p class="comment">Yes, a cool film, I also really liked one of the best films of Quentin Tarantino, I am also a fan of all of his work</p>-->
<!--                                        </div>-->
<!--                                    </li>-->
