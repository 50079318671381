<template>
  <div class="profile__about">
    <div class="profile__card">
        <cropper-module
            :s3-url="s3Url"
            :my-url="myUrl"
            :is-edit="isEdit"
            :is-supported-webp="isSupportedWebp"
            :img-gallery-webp="imgGalleryWebp"
            :img-gallery="imgGallery"
            :member-i-d="memberID"
            :member-role="memberRole"
            v-if="$mq !== 'desktop' && isEdit"
        >
        </cropper-module>
      <div class="profile__card-wrapper" :class="{'is-open': isShowProfile}">
        <div class="profile__heading">
<!--          <h3 class="profile__title title">About myself</h3>-->
          <button class="button button_edit" @click="openModalMyselfEdit" v-if="isEdit">
              <span class="badge badge_inform badge_animate" v-if="showBadgeWarning"></span>
              <i class="button__icon icon icon_edit"></i>
          </button>
        </div>

        <h3 class="profile__title title">About myself</h3>

        <ul v-if="(isShowProfile) && profile && $mq === 'desktop'" class="description description_personal list">
          <li class="description__item list__item">
            <span class="description__key">Work as:</span>
            <span class="description__value">{{profile.work}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Language:</span>
            <span class="description__value">{{profile.lang}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Zodiac:</span>
            <span class="description__value">{{getZodiacSign}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Education:</span>
            <span class="description__value">{{profile.education}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Status:</span>
            <span class="description__value">{{profile.marital_status}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Kids:</span>
            <span class="description__value">{{profile.kids}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Body:</span>
            <span class="description__value">{{profile.body_type}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Eyes:</span>
            <span class="description__value">{{profile.eye_color}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Hair:</span>
            <span class="description__value">{{profile.hair_color}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Smoke:</span>
            <span class="description__value">{{profile.smoke}}</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Height:</span>
            <span class="description__value">{{profile.height}} cm</span>
          </li>
          <li class="description__item list__item">
            <span class="description__key">Weight:</span>
            <span class="description__value">{{profile.weight}} kg</span>
          </li>
        </ul>


        <div v-if="profile && ($mq !== 'desktop')" class="slider slider_content">
          <div class="slider__container">
            <hooper :wheelControl="false" :transition="500">
              <slide class="slider__item">
                <ul class="description description_personal list">
                  <li class="description__item list__item">
                    <span class="description__key">Work as:</span>
                    <span class="description__value">{{profile.work}}</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Language:</span>
                    <span class="description__value">{{profile.lang}}</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Zodiac:</span>
                    <span class="description__value">{{getZodiacSign}}</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Education:</span>
                    <span class="description__value">{{profile.education}}</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Status:</span>
                    <span class="description__value">{{profile.marital_status}}</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Kids:</span>
                    <span class="description__value">{{profile.kids}}</span>
                  </li>
                </ul>
              </slide>
              <slide class="slider__item">
                <ul class="description description_personal list">
                  <li class="description__item list__item">
                    <span class="description__key">Body:</span>
                    <span class="description__value">{{profile.body_type}}</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Eyes:</span>
                    <span class="description__value">{{profile.eye_color}}</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Hair:</span>
                    <span class="description__value">{{profile.hair_color}}</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Smoke:</span>
                    <span class="description__value">{{profile.smoke}}</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Height:</span>
                    <span class="description__value">{{profile.height}} cm</span>
                  </li>
                  <li class="description__item list__item">
                    <span class="description__key">Weight:</span>
                    <span class="description__value">{{profile.weight}} kg</span>
                  </li>
                </ul>
              </slide>
              <hooper-pagination slot="hooper-addons"></hooper-pagination>
            </hooper>
          </div>
        </div>

        <button class="button button_collapse" :class="{'is-collapsed':isShowProfile}" @click.prevent="isShowProfile=!isShowProfile" v-if="$mq === 'desktop'">
          <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12"
               width="23" height="12" fill="none">
            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="profile__card"  v-if="profile">
        <div class="profile__card-wrapper" :class="{'is-open': isShowShouldBe}">
            <div class="profile__heading" v-if="isEdit">
                <h3 class="profile__title title">I'm looking for...</h3>
                <button class="button button_edit" @click="openModalLooking">
                    <i class="button__icon icon icon_edit"></i>
                </button>
            </div>
            <h3 class="profile__title title" v-else>My {{myRole}} should be...</h3>

        <div class="profile__text" v-if="isShowShouldBe">
          <p>{{profile.preference}}</p>
        </div>

          <div class="profile__text" v-if="isEdit && $mq !== 'desktop'">
              <div v-if="!profile.preference" class="profile__text-preview">
                  <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                      <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#information`" />
                  </svg>
                  <span>You have not provided this information about yourself</span>
              </div>
              <p v-else>{{(profile.preference).slice(0, 500)}}</p>
          </div>

        <button class="button button_collapse" :class="{'is-collapsed':isShowShouldBe}" @click.prevent="isShowShouldBe=!isShowShouldBe" v-if="$mq === 'desktop'">
          <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12"
               width="23" height="12" fill="none">
            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
          </svg>
        </button>
      </div>
    </div>

    <div class="profile__card">
      <div class="profile__card-wrapper" :class="{'is-open': isShowInterest}">
        <div class="profile__heading">
<!--          <h3 class="profile__title title">My interests</h3>-->
          <button class="button button_edit" @click="openModalInterest" v-if="isEdit">
            <i class="button__icon icon icon_edit"></i>
          </button>
        </div>

        <h3 class="profile__title title">My interests</h3>

        <button class="button button_collapse" :class="{'is-collapsed':isShowInterest}" @click.prevent="isShowInterest=!isShowInterest" v-if="$mq === 'desktop'">
          <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12"
               width="23" height="12" fill="none">
            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
          </svg>
        </button>

        <my-interests
            :interests="interests"
            :is-edit="isEdit"
            :my-url="myUrl"
            v-if="isShowInterest || $mq !== 'desktop'">
        </my-interests>
      </div>
    </div>
  </div>
</template>

<script>
import ImageCropper from "../modules/ImageCropper";
import MyInterests from "../newModules/MyInterests.vue";
import CropperModule from "../newModules/CropperModule.vue";
export default {
    name: "MySelf",
    props:[
        'myRole',
        'memberColor',
        'memberRole',
        'memberID',
        'birthday',
        'getZodiacSign',
        'imgGallery',
        'imgGalleryWebp',
        'name',
        'memberOnline',
        'profile',
        'interests',
        'myUrl',
        's3Url',
        'isEdit',
        'country',
        'isSupportedWebp',
        'showBadgeWarning'
    ],
    components: {
        CropperModule,
        MyInterests,
        ImageCropper
    },
    data(){
        return{
            avatarSize: 100,
            galleryWidth: 512,
            galleryHeight: 828,
            galleryWidthDesktop: 770,
            galleryHeightDesktop: 398,
            maxsizeImage: 10*1024*1024,
            image: null,
            modalCropper: false,
            modalCropperDesktop: false,
            modalWarning: false,
            textWarning: null,
            isShowProfile: false,
            isShowShouldBe: false,
            isShowInterest: false,
        }
    },
    computed:{
        getBirthday(){
            return this.profile && this.profile.birthday? new Date(this.profile.birthday).toLocaleDateString():''
        },
        liveIn(){
            return this.profile.live_in?this.profile.live_in:
                   this.country? this.country.countryName:'';
        },
        shortName(){
            if(!this.name) return '_'
            let arr = this.name.split(' ');
            let rez = '';
            arr.forEach(item=>{
                rez += item.charAt(0).toUpperCase();
            })
            return rez;
        },
        myAge(){
            if (this.profile){
                if(this.profile.birthday){
                    let diff = new Date()-new Date(this.profile.birthday); // This is the difference in milliseconds
                    return  Math.floor(diff/31557600000); // Divide by 1000*60*60*24*365.25
                }else {
                    return this.profile.age;
                }
            }
            return '';
        },
    },
    methods:{
        getTitleInterest(title){
            return title.charAt(0).toUpperCase() + title.slice(1);
        },
        closeModalCropper() {
            this.modalCropper = false;
            this.modalCropperDesktop = false;
            this.image = null
            this.textCropper = null
            this.$bus.$emit('close_modal');
        },
        closeModalWarning() {
            this.modalWarning = false
            this.textWarning = null
        },
        uploadImage(event){
            console.log('uploadImage');
            const img = event.target.files[0];
            this.image = new Image()
            this.image.onload = () => {
                setTimeout(()=> {
                    this.$bus.$emit('open_modal');
                    this.modalCropper = true
                },300)
            };
            this.image.src = URL.createObjectURL(img)

            event.target.value = null
        },
        async cropImages(canvas, canvasThumb) {
            // this.cropImage(canvas, canvasThumb,"image/jpeg",'jpeg');
            // if(this.isSupportedWebp){
            //     this.cropImage(canvas, canvasThumb,"image/webp",'webp');
            // }
            this.modalCropper = false;
            this.$nextTick(()=>{
                this.modalCropperDesktop = true;
            })

            // this.image = null;
            const blobGallery = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
            const blobAvatar = await new Promise(resolve => canvasThumb.toBlob(resolve, "image/jpeg", 1));
            let blobGalleryWebp = null;
            let blobAvatarWebp = null;

            if(this.isSupportedWebp){
                blobGalleryWebp = await new Promise(resolve => canvas.toBlob(resolve, "image/webp", 1));
                blobAvatarWebp = await new Promise(resolve => canvasThumb.toBlob(resolve, "image/webp", 1));
            }
            this.uploadAvatar(blobGallery,blobAvatar,blobGalleryWebp,blobAvatarWebp);
        },
        async cropImagesDesktop(canvas) {
            // this.cropImage(canvas, canvasThumb,"image/jpeg",'jpeg');
            // if(this.isSupportedWebp){
            //     this.cropImage(canvas, canvasThumb,"image/webp",'webp');
            // }
            this.modalCropper = false;
            this.modalCropperDesktop = false;
            this.image = null;
            this.$bus.$emit('close_modal');
            const blobGallery = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));

            let blobGalleryWebp = null;


            if(this.isSupportedWebp){
                blobGalleryWebp = await new Promise(resolve => canvas.toBlob(resolve, "image/webp", 1));

            }
            this.uploadAvatarDesktop(blobGallery,blobGalleryWebp);
        },

        cropImage(canvas, canvasThumb, type, imageType) {
            try {
                canvas.toBlob((blob) => {
                    console.log('canvas.toBlob');
                    const fileGallery = new File([blob], 'gallery', {
                        type: "application/octet-stream"
                    });
                    console.log(fileGallery);
                    this.image = null
                    canvasThumb.toBlob((blob) => {
                        const fileAvatar = new File([blob], 'avatar', {
                            type: "application/octet-stream"
                        });
                        this.uploadAvatar(fileGallery,fileAvatar,imageType);
                    }, type, 0.8)
                }, type, 0.9)
            }catch (e) {
                console.log('error canvas.toBlob');
            }

            this.closeModalCropper()
        },
        // cropImage(canvas, canvasThumb) {
        //     canvas.toBlob((blob) => {
        //         const fileGallery = new File([blob], 'gallery', {
        //             type: "application/octet-stream"
        //         });
        //         console.log(fileGallery);
        //         this.image = null
        //         canvasThumb.toBlob((blob) => {
        //             const fileAvatar = new File([blob], 'avatar', {
        //                 type: "application/octet-stream"
        //             });
        //             this.uploadAvatar(fileGallery,fileAvatar);
        //         }, "image/jpeg", 1)
        //     }, "image/jpeg", 1)
        //     this.closeModalCropper()
        // },
        uploadAvatar(fileGallery,fileAvatar,blobGalleryWebp,blobAvatarWebp){
            console.log('uploadAvatar');
            console.log(fileGallery);
            console.log(fileAvatar);

            if (this.memberID){
                const formData = new FormData();
                formData.append('avatar',fileAvatar,'avatar');
                formData.append('gallery',fileGallery,'gallery');
                formData.append('avatarWebp',fileAvatar,'avatarWebp');
                formData.append('galleryWebp',fileGallery,'galleryWebp');
                formData.append('role',this.memberRole);
                formData.append('memberID',this.memberID);
                axios.post(`/updated-avatar`,formData)
                    .then(response => {
                        console.log(response.data);
                        this.$emit('updated_avatar',response.data.member);
                    })
                    .catch(e => {
                        console.log(e);
                        this.errorHandler(e);
                    })
            }
        },
        uploadAvatarDesktop(blobGallery,blobGalleryWebp){
            console.log('uploadAvatar');


            if (this.memberID){
                const formData = new FormData();
                formData.append('gallery',blobGallery,'gallery');
                formData.append('galleryWebp',blobGalleryWebp,'galleryWebp');
                formData.append('role',this.memberRole);
                formData.append('memberID',this.memberID);
                axios.post(`/updated-avatar-desktop`,formData)
                    .then(response => {
                        console.log(response.data);
                        this.$emit('updated_avatar_desctop',response.data.member);
                    })
                    .catch(e => {
                        console.log(e);
                        this.errorHandler(e);
                    })
            }
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        openModalMyselfEdit() {
           this.$emit('open_modal_myself_edit');
        },
        openModalLooking(){
            console.log('openModalLooking')
            this.$emit('open_modal_looking');
        },
        openModalInterest(){
            this.$emit('open_modal_interest');
        }
    }
}
</script>

<style scoped>

</style>
