<template>
    <!--    class - chat__letter_images - for letter with image and for letter add -->
    <!--    :class="{'chat__letter_images': 'letter_images': appliedImageLetter}"-->
    <div class="chat__letter" :class="{'chat__letter_images': postcard}" :style="`background-image: url(${cdnLetterImages}/${postcard})`">
        <div class="chat__header">
            <chat-header-contact
                :s3-url="s3Url"
                :opponent="opponent"
                :opponent-age="opponentAge"
                @goto_profile="gotoProfile"
            ></chat-header-contact>
            <div class="chat__actions">
                <button class="button" aria-label="Close" @click.prevent="closeLetter">
                    <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <path d="M7.06127 6.00012L11.7801 1.28108C12.0733 0.988138 12.0733 0.512831 11.7801 0.219892C11.4869 -0.0732973 11.0122 -0.0732973 10.719 0.219892L6.00012 4.93894L1.28102 0.219892C0.987847 -0.0732973 0.51306 -0.0732973 0.219883 0.219892C-0.0732943 0.512831 -0.0732943 0.988138 0.219883 1.28108L4.93898 6.00012L0.219883 10.7192C-0.0732943 11.0121 -0.0732943 11.4874 0.219883 11.7804C0.366471 11.9267 0.558587 12 0.750453 12C0.942319 12 1.13444 11.9267 1.28102 11.7801L6.00012 7.06106L10.719 11.7801C10.8656 11.9267 11.0577 12 11.2495 12C11.4414 12 11.6335 11.9267 11.7801 11.7801C12.0733 11.4872 12.0733 11.0119 11.7801 10.7189L7.06127 6.00012Z" fill="var(--color-text)"/>
                    </svg>
                </button>
            </div>
        </div>

        <!--class - letter_images -  for letter with image  ,'letter_images': appliedImageLetter -->
        <div class="letter" :class="{'letter_view':(letter && !isWriteLetters && !isEditLetter),'letter_images': postcard}">
            <!-- letter choice images component-->
            <div class="images letter__overlay" v-if="letterImages.length && !appliedImageLetter">
                <div class="images__body" :style="`background-image: url(${cdnLetterImages}/${isSupportedWebp ? selectedLetterImage.src_min_webp : selectedLetterImage.src_min})`">
                    <div class="images__container">
                        <img :src="`${cdnLetterImages}/${isSupportedWebp?selectedLetterImage.src_min_webp:selectedLetterImage.src_min}`" alt="" class="images__thumbnail">
                    </div>
                    <div class="images__actions">
                        <button class="button button_white button_md button_rounded" @click.prevent="cancelImageLetter" v-if="$mq==='desktop'">
                            <span><span class="button__text">Cancel</span></span>
                        </button>
                        <button class="button button_white button_round" @click.prevent="cancelImageLetter" v-else>
                            <i class="icon icon_close"></i>
                        </button>
                        <button class="button button_primary button_md button_rounded" @click.prevent="applyImageLetter">
                            <span><span class="button__text">Apply</span></span>
                        </button>
                    </div>
                </div>
                <ul class="images__list list" >
                    <li class="images__item" v-for="letterImage in letterImages" :key="letterImage.id"
                        :class="{'is-checked': selectedLetterImage.id===letterImage.id}" @click.prevent="selectImage(letterImage)">
                        <img :src="`${cdnLetterImages}/${letterImage.src_min_webp}`" alt="" class="images__thumbnail" v-if="isSupportedWebp">
                        <img :src="`${cdnLetterImages}/${letterImage.src_min}`" alt="" class="images__thumbnail" v-else>
                    </li>
                </ul>
            </div>

            <div class="letter__form">
                <template v-if="isWriteLetters || isEditLetter">
                    <div class="letter__message">
                        <div class="letter__textarea ui-textarea" ref="inputLetter"
                             data-placeholder="Write your message..."
                             contenteditable="true"
                             @input="changeText"
                             @keyup.enter="changeText"
                             @click="clickInput"
                             tabindex="-1"
                        ></div>
                        <!--                        <p class="letter__text" style="display: none;">This user agreement is a public offer agreement. LFL team limited, the company incorporated at 65 London Wall, London, United Kingdom, EC2M 5TU, hereinafter referred to as “lifeforlove.com”. Lifeforlove.com is an entertainment site and provides the ability to correspond and exchange multimedia files.</p>-->
                    </div>
                </template>
                <template v-else-if="letter">
                    <div class="letter__message">
                        <p class="letter__text">{{text}}</p>

                        <button class="button button_white button_rounded button_sound is-animated" :id="`speech_letter_btn_${letter.id}`"
                                @click.prevent="stopSpeech" v-if="speechStatus === 'speech'">
                            <i class="button__icon icon icon_sound"></i>
                            <span class="button__wave">
                                    <span class="button__circle"></span>
                                    <span class="button__circle"></span>
                                    <span class="button__circle"></span>
                                </span>
                        </button>
                        <button class="button button_white button_rounded button_sound" :id="`speech_letter_btn_${letter.id}`"
                                @click.prevent="messageToSpeech" :class="{disabled: speechStatus==='load'}" v-else>
                            <i class="button__icon icon icon_sound"></i>
                            <span class="button__wave">
                                    <span class="button__circle"></span>
                                    <span class="button__circle"></span>
                                    <span class="button__circle"></span>
                                </span>
                        </button>

                    </div>
                </template>
                <!-- for letter with image-->
                <div class="letter__image-container" v-if="postcard">
                    <img :src="`${cdnLetterImages}/${postcard}`" alt="" class="letter__image" @click.prevent="openLetterMedia">
                    <a :href="`${cdnLetterImages}/${appliedImageLetter.src}`" download class="button button_icon button_download" v-if="appliedImageLetter">
                        <i class="icon icon_download"></i>
                    </a>
                    <a :href="`${cdnLetterImages}/${letter.src}`" download class="button button_icon button_download" v-else-if="letter && letter.src">
                        <i class="icon icon_download"></i>
                    </a>
                </div>
            </div>
            <div class="letter__footer">
                <template v-if="isWriteLetters || isEditLetter">
                    <div class="letter__actions" v-if="isEditLetter">
                        <button class="button button_primary button_sm button_rounded button_send" @click.prevent="sendLetters" :disabled="!isFilledText">
                            <span><i class="button__icon icon icon_plane"></i>
                                <span class="button__text">Update</span>
                            </span>
                        </button>
                    </div>
                    <div class="letter__actions" v-else>
                        <button class="button button_white button_sm button_rounded" v-if="!postcard"
                                @click.stop.capture="showImageSubjects" :disabled="!(isFilledText && subjectsImage.length)" >
                            <span>
                                <i class="button__icon icon icon_image-fill"></i>
                                <span class="button__text">Add image</span>
                            </span>
                        </button>

                        <!-- buttons for letter with image-->
                        <template v-if="appliedImageLetter">
                            <button class="button button_white button_md button_rounded" @click.prevent="cancelAppliedImageLetter"  v-if="$mq==='desktop'">
                                <span><span class="button__text">Cancel</span></span>
                            </button>
                            <button class="button button_white button_round" @click.prevent="cancelAppliedImageLetter" v-else>
                                     <i class="icon icon_close"></i>
                            </button>
                        </template>

                        <button class="button button_primary button_sm button_rounded button_send" @click.prevent="sendLetters" :disabled="!isFilledText">
                            <span><i class="button__icon icon icon_plane"></i>
                                <span class="button__text">Send</span>
                            </span>
                        </button>
                    </div>
                    <!--  images letter list -->
                    <div class="chat__dropdown dropdown dropdown_bottom dropdown_sm" v-if="isShowImageSubjects">
                        <div class="dropdown__menu">
                            <ul class="dropdown__list menu">
                                <li v-for="subjectImage in subjectsImage" class="dropdown__item menu__item" @click.prevent="setSubjectImage(subjectImage)">
                                    <span class="dropdown__link menu__link">{{subjectImage}}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>

                <button class="button button_primary button_sm button_rounded button_send" @click.prevent="replayLetters" v-if="!isWriteLetters && letter && isOpponent">
                    <span><i class="button__icon icon icon_plane"></i>
                        <span class="button__text">Reply</span>
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import ChatHeaderContact from "../newModules/ChatHeaderContact.vue";
import Emojis from "../Emojis.vue";


let audio = null;
let  isStartSpeech = false;
export default {
    name: "LetterChat",
    props:[
        'myUrl',
        's3Url',
        'myRole',
        'roleOpponent',
        'opponent',
        'chatOwnerId',
        'isWriteLetters',
        'letter',
        'isOpponent',
        'isOpponentInChat',
        'disabledSendMessage',
        'opponentAge',
        'cdnLetterImages',
        'supportsLocalStorage',
        'isEditLetter',
        'isSupportedWebp',
        'selectedMessage',
    ],
    components: {
        Emojis,
        ChatHeaderContact,
    },
    data(){
        return {
            text:'',
            letterTextMaxSize: 2000,
            subjectsImage: [],
            letterImages: [],
            selectedLetterImage: null,
            appliedImageLetter: null,
            selectedSubjectImage: '',
            postcard: '',
            isShowImageSubjects: false,
            speechStatus: '',
        }
    },
    computed: {
        isFilledText(){
            if(!this.text) return false
            const text = this.text.trim();
            return (text && text.length)
        },

    },
    watch:{
        letter(newLetter, oldLetter){
            console.log('watch letter')
            if (newLetter) this.initLetter()
            else this.resetData();
        }
    },
    mounted() {
        console.log('mounted letter')
        this.$bus.$on('close_emoji_letter',this.closeShowImageSubjects);
        if ((this.isWriteLetters || !this.letter) && !this.isEditLetter ) {
            this.getCacheLetter()
            this.getSubjectsLetterImage()
        } else this.initLetter()
    },
    beforeDestroy() {
        this.stopSpeech()
    },
    methods: {
        clickInput(){
            const input = this.$refs.inputLetter
            if(input && this.$mq !== 'desktop' ){
                setTimeout(() => {
                    input.scrollIntoView()
                },1000)
            }
        },
        stopSpeech(){
            console.log('stopSpeech')
            if(audio){
                audio.pause()
                audio.remove()
                audio = null
            }
            this.speechStatus = '';
            isStartSpeech = false
        },
        messageToSpeech(){
            console.log('messageToSpeech')
            if(isStartSpeech || this.speechStatus) return;
            isStartSpeech = true;
            this.speechStatus = 'load'
            audio = new Audio();
            axios.post('/letter-to-speech',{
                memberId: this.chatOwnerId,
                letterId: this.letter.id,
                role: this.isOpponent ? this.roleOpponent : this.myRole,
                text: this.letter.text,
                is_origin: true
            })
                .then(response => {
                    console.log(response.data) ;
                    const btn = document.getElementById(`speech_letter_btn_${this.letter.id}`)
                    if(btn && response.data && response.data.status === 'ok' && response.data.speech){
                        audio.src = `${this.s3Url}/${response.data.speech.src}`
                        audio.load()
                        audio.oncanplay = (event) => {
                            console.log(event);
                            this.speechStatus = 'speech';
                            audio.play();
                        };
                        audio.onerror = (event) => { this.stopSpeech() };
                        audio.onended = (event) => { this.stopSpeech() };
                    }
                })
                .catch(err => {
                    console.log(err);
                    isStartSpeech = false;
                    this.speechStatus = '';
                    this.errorHandler(err);
                })
        },
        openLetterMedia(){
            if(!this.letter) return
            this.$bus.$emit('open_letter_media', (this.isSupportedWebp ? this.letter.src_webp : this.letter.src))
        },
        initLetter(){
            console.log('initLetter')
            if(this.selectedMessage && this.selectedMessage.src_mini) this.postcard = this.isSupportedWebp ? this.selectedMessage.src_mini_webp : this.selectedMessage.src_mini

            if( this.letter ) this.setTextToDiv(this.letter.text)
            else console.log('!letter')
        },
        cancelAppliedImageLetter(){
            this.appliedImageLetter = null
            this.postcard = ''
        },
        applyImageLetter(){
            this.appliedImageLetter = this.selectedLetterImage
            this.postcard = this.isSupportedWebp ? this.appliedImageLetter.src_min_webp: this.appliedImageLetter.src_min
        },
        cancelImageLetter(){
            this.selectedLetterImage = null
            this.letterImages = []
            this.postcard = ''
        },
        selectImage(letterImage){
            this.selectedLetterImage = letterImage
        },
        showImageSubjects(){
            this.isShowImageSubjects = !this.isShowImageSubjects
        },
        closeShowImageSubjects(){
            this.isShowImageSubjects = false
        },
        setSubjectImage(subjectImage){
            this.selectedSubjectImage = subjectImage
            this.isShowImageSubjects = false
            this.getLetterImages()
        },
        getSubjectsLetterImage(){
            console.log('getSubjectsLetterImage')
            // let subjects = ["World", "1024", "1024*1792", "1792", "hhhh", "I love you", "Nan", "Subject", "Sub"]
            // this.subjectsImage = subjects
            // uncoment in production
            axios.get('/get-subjects-letter-image-gpt')
                .then(response => {
                    console.log(response.data)
                    if(response.data.status === 'ok'){
                        this.subjectsImage = response.data.subjects
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        getLetterImages(){
            console.log('getSubjectsLetterImage')
            // only for dev
            // this.letterImages = [{'created_at':"2023-12-04T12:46:44.000+02:00",
            //     'id':74,'is_active':true,'prompt_id':8,'src':"Subject/8/src/lpqsdv3r.jpg",
            //     'src_min':"Subject/8/min/lpqsdv3r.jpg",'src_min_webp':"Subject/8/min/lpqsdv3r.webp",
            //     'src_webp':"Subject/8/src/lpqsdv3r.webp",'subject':"Subject",
            //     'updated_at':"2023-12-04T12:46:44.000+02:00"
            // },{'created_at':"2023-12-04T12:46:44.000+02:00",
            //     'id':75,'is_active':true,'prompt_id':8,'src':"Subject/8/src/lpq5bhjl.jpg",
            //     'src_min':"Subject/8/min/lpq5bhjl.jpg",'src_min_webp':"Subject/8/min/lpq5bhjl.webp",
            //     'src_webp':"Subject/8/src/lpq5bhjl.webp",'subject':"Subject",
            //     'updated_at':"2023-12-04T12:46:44.000+02:00"
            // },{'created_at':"2023-12-04T12:46:44.000+02:00",
            //     'id':76,'is_active':true,'prompt_id':8,'src':"Subject/8/src/lpqs8dfv.jpg",
            //     'src_min':"Subject/8/min/lpqs8dfv.jpg",'src_min_webp':"Subject/8/min/lpqs8dfv.webp",
            //     'src_webp':"Subject/8/src/lpqs8dfv.webp",'subject':"Subject",
            //     'updated_at':"2023-12-04T12:46:44.000+02:00"
            // }]
            //
            // this.appliedImageLetter = null
            // this.selectedLetterImage = this.letterImages[0]
            // only for dev end

            // uncoment in prod mode
            axios.post('/get-letter-images-gpt',{
                subject: this.selectedSubjectImage,
                manID: this.myRole==='man'?this.chatOwnerId:this.opponent.id
            })
                .then(response => {
                    console.log(response.data)
                    if(response.data.status === 'ok' && response.data.letterImages && response.data.letterImages.length){
                        this.letterImages = response.data.letterImages
                        this.appliedImageLetter = null
                        this.selectedLetterImage = this.letterImages[0]
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        changeText(event){
            const text = event.target.innerText || event.target.textContent;
            this.text = text;
            if(text) this.cacheText()
            else this.removeCacheText()
        },
        removeCacheText(){
            if(this.supportsLocalStorage && this.opponent && this.opponent.id){
                localStorage.removeItem(`letter.${this.opponent.id}`);
            }
        },
        cacheText(){
            if(this.supportsLocalStorage && this.opponent && this.opponent.id){
                const text = this.text.trim();
                if(text) localStorage[`letter.${this.opponent.id}`] = text;
                else this.removeCacheText();
            }
        },
        setTextToDiv(text){
            this.text = text;
            const letterEl = this.$refs.inputLetter
            if(letterEl) {
                letterEl.textContent = text;
                letterEl.innerText = text;
            }
        },
        getCacheLetter(){
            console.log('getCacheLetter')
            if(this.supportsLocalStorage && this.opponent && this.opponent.id){
                const text = localStorage[`letter.${this.opponent.id}`]
                if(text){
                    const textTrim = text.trim()
                    if(textTrim && textTrim.length) this.setTextToDiv(textTrim)
                    else localStorage.removeItem(`letter.${this.opponent.id}`)
                }
            }
        },
        resetData(){
            this.setTextToDiv('')
        },
        replayLetters(){
            this.resetData()
            this.getSubjectsLetterImage()
            this.cancelAppliedImageLetter()
            this.cancelImageLetter()
            this.$emit('replay_letters');
        },
        sendLetters(){
            // console.log('sendLetters: ' + this.$route.name)
            // if(this.$route.name === 'promoProfile'){
            //     return;
            // }
            if (this.disabledSendMessage) return;
            if (this.isEditLetter && this.letter){
                this.$bus.$emit('edit_letter',{
                    letterID: this.letter.id,
                    text: this.text,
                });
            }else if(!this.isEditLetter){
                this.$bus.$emit('send_letter',{
                    text: this.text,
                    imageLetter: this.appliedImageLetter,
                }, this.isOpponentInChat);
            }
            this.resetData();
            this.$emit('close_letter');
        },
        gotoProfile(){
            this.$emit('goto_profile')
        },
        shortName(name){
            let arr = name.split(' ');
            let rez = '';
            arr.forEach(item=>{
                rez += item.charAt(0).toUpperCase();
            })
            return rez;
        },
        closeLetter() {
            console.log('close letter');
            this.$emit('close_letter');
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    }
}
</script>





