<template>
    <ul class="menu menu_sub menu_sub-settings navigation__menu-sub">
        <template v-if="this.mySetting">
            <li class="menu__item ui-switcher" v-if="showButton && showNotificationButton">
                <span class="ui-switcher__title">Notification</span>
                <i class="icon icon_bell" v-if="onSubscribeNotification"></i>
                <i class="icon icon_bell-red" v-else></i>
                <label class="ui-switcher__box" title="Enable notification">
                    <input type="checkbox" :checked="onSubscribeNotification" :class="{'is-disabled': disabledNotification}"
                           ref="checkboxPushNotify" :disabled="disabledNotification" @change="clickPushNotification">
                    <span class="ui-switcher__label"></span>
                </label>
            </li>
            <li class="menu__item ui-switcher" v-if="this.currentUser.email_verified">
                <template v-if="this.mySetting.is_notifications">
                    <span class="ui-switcher__title">Subscribed</span>
                    <i class="icon icon_subscribe"></i>
                </template>
                <template v-else>
                    <span class="ui-switcher__title">Unsubscribed</span>
                    <i class="icon icon_subscribe-on"></i>
                </template>

                <label class="ui-switcher__box" title="Enable subscribe">
                    <input type="checkbox" :checked="this.mySetting.is_notifications" :class="{'is-disabled': disabledButton}"
                           ref="checkboxMailNotify" :disabled="disabledButton" @change="subscribeEmailNotifications(!mySetting.is_notifications)">
                    <span class="ui-switcher__label"></span>
                </label>
            </li>
            <li class="menu__item ui-switcher" style="cursor: pointer" @click.prevent="changeEmail" v-else>
                <span class="ui-switcher__title">Change Email</span>
                <i class="icon icon_envelope"></i>
            </li>
        </template>
    </ul>
</template>

<script>

// import * as PusherPushNotifications from '@pusher/push-notifications-web'

export default {
    name: "PushNotification",
    props:[
        'currentUser',
        'myUrl',
        'mySetting',
        'onSubscribeNotification',
        'disabledNotification',
        'showNotificationButton'
    ],
    data(){
        return{
            disabledButton:false,
        }
    },
    computed:{
        // textButton(){
        //     if (Notification.permission === "granted") {
        //         return 'Unsubscribe from notifications'
        //     } else if (Notification.permission === "blocked") {
        //         return 'Renew subscription'
        //     } else {
        //         return 'Subscribe to notifications'
        //     }
        // },
        showButton(){
            return ('Notification' in window && navigator.serviceWorker && 'BeamsClient' in window)
        },
    },
    mounted() {
        this.$bus.$on('cancel_unsubscribe_notify',this.cancelUnsubscribeNotify);

    },
    beforeDestroy(){
        this.$bus.$off('cancel_unsubscribe_notify',this.cancelUnsubscribeNotify);
    },
    methods:{
        changeEmail(){
            if(this.isPromoPage())return
            console.log('validateEmail');
            this.$bus.$emit('open_modal_change_email');
        },
        cancelUnsubscribeNotify(){
            if(this.isPromoPage())return
            this.$refs.checkboxPushNotify.checked = true;
        },
        validateEmail(){
            if(this.isPromoPage())return
            console.log('validateEmail');
            this.$bus.$emit('show_modal_valid_email');
        },
        subscribeEmailNotifications(status){
            if(this.isPromoPage())return
            console.log('subscribeEmailNotifications',status);
            if (this.mySetting && !this.disabledButton){
                this.disabledButton = true;
                axios.post(`/subscribe-notifications`,{
                    status:status,
                    settingID:this.mySetting.id
                })
                    .then(response => {
                        this.disabledButton = false;
                        console.log(response.data);
                        this.$bus.$emit('change_setting',response.data.settings)
                    })
                    .catch(e => {
                        this.disabledButton = false;
                        console.log(e);
                        this.errorHandler(e);
                    })
            }

        },
        clickPushNotification(){
            if(this.isPromoPage())return
            console.log('clickPushNotification');
            if(Notification.permission === 'blocked' || Notification.permission === 'denied'){
                console.log('Notification.permission === blocked')
                this.$refs.checkboxPushNotify.checked = false;
                alert('Change your permission settings to display notifications in your browser for this site');

                return;
            }
            if(!this.disabledNotification){
                if(this.onSubscribeNotification){
                    this.showModal()
                    this.$bus.$emit('stop_beams_notifications');
                }else {
                    this.$bus.$emit('start_beams_notifications');
                }
            }
        },
        showModal() {
            if(this.isPromoPage())return
            this.$bus.$emit('show_modal_notify');
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        isPromoPage(){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat'){
                this.$bus.$emit('open_register')
                return true
            }else {return false}
        }
        // beamsClientStop(){
        //     this.$bus.$emit('stop_beams_notifications');
        //     // BeamsClient.stop()
        //     //     .then(() => {
        //     //         console.log('Beams SDK has been stopped');
        //     //         this.onSubscribe = false;
        //     //         this.sendStatusNotification('0');
        //     //     })
        //     //     .catch(e => {
        //     //         console.error('Could not stop Beams SDK', e);
        //     //         this.disabledButton = false;
        //     //     });
        // },

        // beamsClientInit(){
        //     try {
        //         BeamsClient
        //             .getRegistrationState()
        //             .then((state) => {
        //                 const states = PusherPushNotifications.RegistrationState;
        //                 switch (state) {
        //                     case states.PERMISSION_DENIED: {
        //                         // Notifications are blocked
        //                         // Show message saying user should unblock notifications in their browser
        //                         console.log('Renew subscription');
        //                         this.onSubscribe = false;
        //                         break;
        //                     }
        //                     case states.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS: {
        //                         // Ready to receive notifications
        //                         // Show "Disable notifications" button, onclick calls '.stop'
        //                         console.log('Unsubscribe from notifications');
        //                         this.onSubscribe = this.mySetting.push_notify && this.chatOwner.push_notify && Notification.permission !== 'denied';
        //                         break;
        //                     }
        //                     case states.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS:
        //                     case states.PERMISSION_PROMPT_REQUIRED: {
        //                         // Need to call start before we're ready to receive notifications
        //                         // Show "Enable notifications" button, onclick calls '.start'
        //                         console.log('Subscribe to notifications');
        //                         this.onSubscribe = false;
        //                         break;
        //                     }
        //                 }
        //             })
        //             .catch((e) => console.error("Could not get registration state", e));
        //     }catch (e) {
        //         console.error("Could not BeamsClient", e)
        //     }
        // },

        // beamsPushNotification(){
        //     console.log('beamsPushNotification');
        //     BeamsClient.getUserId()
        //         .then(userId => {
        //             console.log('BeamsClient.getUserId',userId) // Will log the current user id
        //             if(+this.currentUser.id !== +userId){
        //                 const  tokenProvider = new PusherPushNotifications.TokenProvider({
        //                     url: `${this.myUrl}/pusher/beams-auth`,
        //                     queryParams: { userId: this.currentUser.id },
        //                     credentials: 'same-origin',
        //                 })
        //                 console.log(tokenProvider);
        //                 BeamsClient.start()
        //                     .then(() => BeamsClient.setUserId(String(this.currentUser.id), tokenProvider))
        //                     .then(() => {
        //                         console.log('User ID has been set BeamsClient');
        //                         this.beamsClientStartHandel();
        //                     })
        //                     .catch(e => {
        //                         console.error('Could not authenticate with Beams:', e);
        //                         this.disabledButton = false;
        //                     })
        //             }else {
        //                 this.beamsClientStartHandel();
        //             }
        //         })
        //         .catch(e => console.error('Could not get user id', e));
        //
        // },
        // beamsClientStartHandel(){
        //     console.log('beamsClientStartHandel');
        //     this.onSubscribe = true;
        //     this.sendStatusNotification('1');
        // },
        // sendStatusNotification(status){
        //     axios.get(`/change-push-notify-user/${status}/${this.currentUser.role}/${this.chatOwner.id}/${this.currentUser.id}`)
        //         .then(response => {
        //             console.log(response);
        //             console.log('/change-push-notify-user/',status);
        //             // this.disabledButton = false;
        //             const newSetting = Object.assign({}, this.mySetting);
        //             newSetting.push_notify = (status === '1')
        //             this.$bus.$emit('change_setting',newSetting);
        //         })
        //         .catch(e => {
        //             console.log(e);
        //             // this.disabledButton = false;
        //             this.errorHandler(e);
        //         })
        // },

        // turnNotify() {
        //     this.$emit('turnnotify');
        // }
    }
}
</script>

<style scoped>

</style>
