<template>
    <portal selector="#portal-target" >
        <div class="modal modal_md modal_services is-active" tabindex="-1" role="dialog">
            <div class="modal__bg" @click="closeModal"></div>
            <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close" @click="closeModal"><i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h2 class="modal__title">Success Publish</h2>
                </div>
                <div class="modal__body text-center">
                    <p>The story has been successfully published,</p>
                    <p>now other users can see it.</p>
                </div>
                <div class="modal__footer text-center">
                    <button class="button button_md button_primary" @click.prevent="closeModal">
                        <span>OK!</span>
                    </button>
                </div>
            </div>
        </div>
    </portal>

</template>

<script>
export default {
    name: "ModalPublishStory",
    mounted() {
        this.$bus.$emit('open_modal')
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
    },
    methods:{
        closeModal(){
            this.$emit('close_modal')
        }
    }

}
</script>

<style scoped>

</style>
