<template>
    <portal selector="#portal-target">
        <div class="modal modal_sm modal_block-user is-active"  tabindex="-1" role="dialog">
            <div class="modal__bg" @click.prevent="closeModalBlocked"></div>
            <div class="modal__container">
                <span class="modal__close"  role="button" aria-label="Close" @click.prevent="closeModalBlocked">
                    <i class="icon icon_close"></i>
                </span>
                <div class="modal__header">
                    <h2 class="modal__title">Attention <i class="icon icon_block-user"></i></h2>
                </div>
                <div class="modal__body">
                    <p>Do you want to block messages from this user?</p>
                </div>
                <div class="modal__footer">
                    <button class="button button_md button_bordered" @click.prevent="closeModalBlocked">
                        <span>
                            <span class="button__text">Close</span>
                        </span>
                    </button>
                    <button class="button button_md button_primary" @click.prevent="blockOpponent">
                        <span>Block</span>
                    </button>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
export default {
    name: "BlockUser",
    methods:{
        closeModalBlocked(){
            this.$bus.$emit('close_modal_blocked')
        },
        blockOpponent(){
          this.$bus.$emit('block_opponent_profile', true)
        }
    }
}
</script>

<style scoped>

</style>
