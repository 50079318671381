<template>
    <div class="profile__portfolio">
                <CoolLightBox
                    :items="portfoliosBox"
                    :index="indexMedia"
                    :loop="false"
                    :slideshow="false"
                    :close-on-click-outside-mobile="true"
                    @close="indexMedia = null">
                </CoolLightBox>
                <CoolLightBox
                    :items="videosBox"
                    :index="indexVideo"
                    :loop="false"
                    :slideshow="false"
                    :close-on-click-outside-mobile="true"
                    @close="indexVideo = null">
                </CoolLightBox>
        <div class="slider slider_portfolio">
            <div class="slider__container" v-if="$mq==='desktop'">

                <hooper
                    :itemsToShow="3"
                    :trimWhiteSpace="true"
                    :itemsToSlide="1"
                    :transition="500"
                    :infiniteScroll="false"
                    :playSpeed="5000"
                    ref="sliderPortfolio">
                    <slide class="slider__item" v-for="i in countHolder" :key="i">
                        <div class="profile__upload upload">
                            <label :for="`portfolioUpload${i}`" class="upload__label upload__label_abs upload__label_image">
                                <input @change="getPortfolioImage" :id="`portfolioUpload${i}`" type='file' accept=".png, .jpg, .jpeg, .svg, .webp" hidden />
                            </label>
                            <img class="slider__holder" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="300" height="320">
                        </div>
                    </slide>
                    <template v-if="portfolios && portfolios.length">
                        <slide class="slider__item" v-for="(photo, el) in portfolios" :key="photo.id">
                            <button class="slider__delete button button_filled button_close" @click.capture="deletePhoto(photo,el)">
                                <i class="icon icon_close"></i>
                            </button>
                            <picture>
                                <source type="image/webp" :srcset="`${s3Url}/${photo.src_webp}`" v-if="isSupportedWebp && photo.src_webp">
                                <img class="slider__media" :src="`${s3Url}/${photo.src}`" alt="photo portfolio" @click.capture="indexMedia = el"
                                     width="270" height="270">
                            </picture>
                        </slide>
                    </template>

                </hooper>

                <button class="slider__button slider__button_prev button" @click.prevent="slidePortfolioPrev"
                        v-if="portfolios && portfolios.length > 2">
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                         height="12" fill="none">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                              d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                    </svg>
                </button>
                <button class="slider__button slider__button_next button" @click.prevent="slidePortfolioNext"
                        v-if="portfolios && portfolios.length > 2">
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                         height="12" fill="none">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                              d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                    </svg>
                </button>
            </div>
            <div class="slider__container" v-else>
                <hooper
                    :transition="500"
                    :wheelControl="false"
                    ref="sliderPortfolio">
                    <slide class="slider__item">
                        <div class="profile__upload upload">
                            <label for="portfolioUpload" class="upload__label upload__label_abs upload__label_image">
                                <input @change="getPortfolioImage" id="portfolioUpload" type='file' accept=".png, .jpg, .jpeg, .svg, .webp" hidden />
                            </label>
                            <img class="slider__holder" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="300" height="320">
                        </div>
                    </slide>
                    <template v-if="portfolios && portfolios.length">
                        <slide class="slider__item" v-for="(photo, el) in portfolios" :key="photo.id">
                            <button class="slider__delete button button_filled button_close" @click.capture="deletePhoto(photo,el)">
                                <i class="icon icon_close"></i>
                            </button>

                            <picture>
                                <source type="image/webp" :srcset="`${s3Url}/${photo.src_webp}`" v-if="isSupportedWebp && photo.src_webp">
                                <img class="slider__media" :src="`${s3Url}/${photo.src}`" alt="photo portfolio" @click.capture="indexMedia = el"
                                     width="270" height="270">
                            </picture>
                        </slide>
                    </template>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                </hooper>
            </div>
        </div>
        <div class="slider slider_portfolio">
            <div class="slider__container" v-if="$mq==='desktop'">

                <hooper
                    :itemsToShow="3"
                    :trimWhiteSpace="true"
                    :itemsToSlide="1"
                    :transition="500"
                    :infiniteScroll="false"
                    :playSpeed="5000"
                    ref="sliderPortfolioVideo">
<!--                    v-if="!isConversion && (videoUpload || conversationUrl)"-->
                    <slide  class="slider__item" >
                        <div class="profile__upload upload">
                            <template v-if="isConversion">
                                <span>Conversion</span>
                            </template>
                            <template v-else-if="!conversationUrl && !videoUpload">
                                <label for="portfolioUploadVideo" class="upload__label upload__label_abs upload__label_video">
                                    <span class="label__icon">
                                        <span class="triangle triangle_r triangle_sm"></span>
                                    </span>
                                    <input @change="getPortfolioVideo" id="portfolioUploadVideo" type='file' accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm " hidden />
                                </label>
                                <img class="slider__holder" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="300" height="320">
                            </template>
                            <template v-else>
                                <video ref="videoPlayerUpload" autoplay playsinline muted
                                       v-if="conversationUrl">
                                    <source :src="`${myUrl}/storage/${conversationUrl}`" type="video/mp4">
                                </video>
                                <video ref="videoPlayerUpload" autoplay playsinline muted
                                       v-else>
                                    <source :src="videoUpload" type="video/mp4">
                                </video>
                            </template>
                        </div>
                    </slide>
                    <slide class="slider__item" v-for="i in countHolderVideo" :key="i">
                        <div class="profile__upload upload">
                            <label for="portfolioUploadVideo" class="upload__label upload__label_abs upload__label_video">
                                <span class="label__icon">
                                    <span class="triangle triangle_r triangle_sm"></span>
                                </span>
                                <input @change="getPortfolioVideo" id="portfolioUploadVideo" type='file' accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm " hidden />
                            </label>
                            <img class="slider__holder" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="300" height="320">
                        </div>
                    </slide>
                    <template v-if="videos.length">
                        <slide class="slider__item" v-for="(video,index) in videos" :key="video.id">
                            <button class="slider__delete button button_filled button_close" @click="deleteVideo(video,index)">
                                <i class="icon icon_close"></i>
                            </button>

                            <picture>
                                <source type="image/webp" :srcset="`${s3Url}/${video.poster_webp}`" v-if="isSupportedWebp && video.poster_webp">
                                <img class="slider__media" :src="`${s3Url}/${video.poster}`" alt="photo portfolio"
                                     width="270" height="270">
                            </picture>
                            <button class="button button_video-play" @click.capture="indexVideo = index">
                                    <span class="button__icon">
                                        <span class="triangle triangle_r triangle_sm"></span>
                                    </span>
                            </button>
                        </slide>
                    </template>

                </hooper>

                <button class="slider__button slider__button_prev button" @click.prevent="sliderPortfolioVideoPrev"
                        v-if="videos && videos.length > 2">
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                         height="12" fill="none">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                              d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                    </svg>
                </button>
                <button class="slider__button slider__button_next button" @click.prevent="sliderPortfolioVideoNext"
                        v-if="videos && videos.length > 2">
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                         height="12" fill="none">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                              d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                    </svg>
                </button>
            </div>
            <div class="slider__container" v-else>

                <hooper
                    :wheelControl="false"
                    :transition="500"
                    ref="sliderPortfolioVideo">
                    <slide class="slider__item">
                        <div class="profile__upload upload">
                            <template v-if="isConversion">
                                <span>Conversion</span>
                            </template>
                            <template v-else-if="!conversationUrl && !videoUpload">
                                <label for="portfolioUploadVideo" class="upload__label upload__label_abs upload__label_video">
                                    <span class="label__icon">
                                        <span class="triangle triangle_r triangle_sm"></span>
                                    </span>
                                    <input @change="getPortfolioVideo" id="portfolioUploadVideo" type='file' accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm " hidden />
                                </label>
                                <img class="slider__holder" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="300" height="320">
                            </template>
                            <template v-else>
                                <video ref="videoPlayerUpload" autoplay playsinline muted width="270" height="270"
                                       v-if="conversationUrl">
                                    <source :src="`${myUrl}/storage/${conversationUrl}`" type="video/mp4">
                                </video>
                                <video ref="videoPlayerUpload" autoplay playsinline muted width="270" height="270"
                                       v-else>
                                    <source :src="videoUpload" type="video/mp4">
                                </video>
                            </template>
                        </div>

                    </slide>
                    <template v-if="videos.length">
                        <slide class="slider__item" v-for="(video,index) in videos" :key="video.id">
                            <button class="slider__delete button button_filled button_close" @click="deleteVideo(video,index)">
                                <i class="icon icon_close"></i>
                            </button>

                            <picture>
                                <source type="image/webp" :srcset="`${s3Url}/${video.poster_webp}`" v-if="isSupportedWebp && video.poster_webp">
                                <img class="slider__media" :src="`${s3Url}/${video.poster}`" alt="photo portfolio"
                                     width="270" height="270">
                            </picture>
                            <button class="button button_video-play" @click.capture="indexVideo = index">
                                    <span class="button__icon">
                                        <span class="triangle triangle_r triangle_sm"></span>
                                    </span>
                            </button>
                        </slide>
                    </template>
                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                </hooper>
            </div>
        </div>
    </div>

</template>

<script>
export default {
name: "UploadPortfolio",
    props:[
        'isSupportedWebp',
        'maxsizeImage',
        'maxsizeVideo',
        'memberId',
        'roleMember',
        's3Url',
        'myUrl',
        'portfolios',
        'videos',
        'packMedias',
        'packMediasID',
        'isEdit',
        'isAccount'
    ],
    data(){
        return {
            image: null,
            videoUpload: null,
            indexMedia: null,
            indexVideo: null,
            indexPack: null,
            isConversion:false,
            conversationUrl:'',
            index: null,
            extens:['avi', '3gp', 'flv', 'm4v', 'mkv', 'mov', 'mp4', 'mpeg', 'mpg', 'ogg', 'webm'],
        }
    },
    computed:{
        countHolderVideo(){
            if(!this.videos || !this.videos.length) return 2
            if(this.videos.length === 1) return 1

            return 0
        },
        countHolder(){
            if(!this.portfolios || !this.portfolios.length) return 3
            if(this.portfolios.length === 1) return 2

            return 1
        },
        portfoliosBox(){
            return this.portfolios.map(photo=>{
                return `${this.s3Url}/${photo.src}`
            });
        },
        videosBox(){
            return this.videos.map(video=>{
                return `${this.s3Url}/${video.src}`
            });
        },
        packBox(){
            return this.packMedias.map(media=>{
                return `${this.s3Url}/${media}`
            });
        }
    },
    mounted() {
    },
    beforeDestroy() {
        this.disconnectPortfolioChannel();
    },
    methods:{
        slidePortfolioPrev() {
            this.$refs.sliderPortfolio.slidePrev();
        },
        slidePortfolioNext() {
            this.$refs.sliderPortfolio.slideNext();
        },
        sliderPortfolioVideoPrev() {
            this.$refs.sliderPortfolioVideo.slidePrev();
        },
        sliderPortfolioVideoNext() {
            this.$refs.sliderPortfolioVideo.slideNext();
        },
        deletePackMedia(media,index){
            console.log('deletePackMedia');
            this.$emit('delete_pack_media',index);
            axios.post(`/delete-media-for-pack`,{
                id:this.packMediasID,
                src:media,
                role:this.roleMember
            })
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        convertMedia(media,typeMedia,ext){
            this.connectPortfolioChannel();
            const formData = new FormData();
            formData.append('media',media,'media');
            formData.append('name',media.name);
            formData.append('type_media',typeMedia);
            formData.append('role',this.roleMember);
            formData.append('memberID',this.memberId);
            formData.append('type','portfolio');
            formData.append('ext',ext);

            axios.post(`/send-media-for-conversion`,formData)
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === 'ok'){
                        this.isConversion = true;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        connectPortfolioChannel(){
            Echo.private(`portfolio-${this.roleMember}.${this.memberId}`)
                .listen('ConversionMediaCompleted',(event)=>{
                    console.log(event);
                    this.isConversion = false;
                    this.conversationUrl = event.path;
                    this.getVideo(null);
                    this.disconnectPortfolioChannel();
                });
        },
        disconnectPortfolioChannel(){
            Echo.leave(`portfolio-${this.roleMember}.${this.memberId}`);
        },
        getPortfolioVideo(event){
            const video = event.target.files[0];
            if(!video) return;
            const ext = this.getExtantionFile(video.name);
            if (video.size > this.maxsizeVideo || video.fileSize > this.maxsizeVideo) {
                //show an alert to the user
                alert(`Allowed file size exceeded. (Max. ${this.maxsizeVideo/ (1024*1024)} MB)`);
            } else if (this.extens.includes(ext)){
                if ( ext !== 'mp4'){
                    this.convertMedia(video,'video',ext);
                }else if ( ext === 'mp4'){
                    this.videoUpload = URL.createObjectURL(video);
                    this.getVideo(video);
                }
            } else {
                alert('Video must be in \'avi\', \'3gp\', \'flv\', \'m4v\', \'mkv\', \'mov\', \'mp4\', \'mpeg\', \'mpg\', \'ogg\', \'webm\' format');
            }
        },
        getVideo(video) {
            const formData = new FormData();
            formData.append('video', video);
            this.$nextTick(()=>{
                setTimeout(() => {
                    this.shoot(formData)
                        .then(() => {
                            console.log('poster created');
                        })
                }, 1000);
            })

        },
        async shoot(formData) {
            const video  = this.$refs.videoPlayerUpload;
            console.log(video);
            const canvas = this.captureVideo(video);
            const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
            formData.append("poster",blob,'poster');
            this.sendVideo(formData);
        },
        captureVideo(video){
            const w = video.videoWidth * 0.75 ;
            const h = video.videoHeight * 0.75;
            const canvas = document.createElement('canvas');
            canvas.width  = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, w, h);
            return canvas;
        },
        sendVideo(formData){
            formData.append('memberID', this.memberId);
            formData.append('role', this.roleMember);
            formData.append('conversationUrl',this.conversationUrl);

            axios.post(`/save-video-for-videos`,formData)
                .then(response => {
                    console.log(response.data);
                    this.isConversion = false ;
                    this.conversationUrl = '';
                    this.$emit('upload_video',response.data.video);
                    // this.videos.unshift(response.data.video);
                    this.videoUpload = null;
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        deleteVideo(video,index){
            this.$emit('delete_video',index);

            axios.post(`/delete-video-for-videos`,{
                id:video.id,
                src:video.src,
                role:this.roleMember
            })
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        deletePhoto(photo,index){
            // this.portfolios.splice(index,1);
            this.$emit('delete_photo',index);
            axios.post(`/delete-photo-for-portfolio`,{
                id:photo.id,
                src:photo.src,
                role:this.roleMember
            })
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        sendPhoto(fileOriginal,url){
            const formData = new FormData();
            formData.append('photo', fileOriginal,'photo');
            formData.append('memberID', this.memberId);
            formData.append('role', this.roleMember);

            axios.post(url,formData)
                .then(response => {
                    console.log(response.data);
                    if(url === `/save-photo-for-portfolio`){
                        this.$emit('upload_photo',response.data.portfolio);
                    }else {
                        this.$emit('upload_pack',response.data.src,response.data.packMediaID);
                    }
                    // this.portfolios.unshift(response.data.portfolio);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        convertPhoto(file,url){
            // let output = {};
            let fileOriginal = null;
            let jpegOriginal = null;
            URL.revokeObjectURL(this.image.src);

            const canvas = document.createElement("canvas"),
                ctx = canvas.getContext("2d");
            canvas.width = this.image.width;
            canvas.height = this.image.height;
            ctx.drawImage(this.image, 0, 0);
            canvas.toBlob((blob) => {
                fileOriginal = new File([blob], file.name, {
                    type: "application/octet-stream"
                });
                jpegOriginal = canvas.toDataURL("image/jpeg", .95);
                this.sendPhoto(fileOriginal,url);
            }, "image/jpeg", .95)
        },
        getPortfolioImage(event){
           this.getImage(event,`/save-photo-for-portfolio`);
        },
        getPortfolioPack(event){
            this.getImage(event,`/save-photo-for-pack`);
        },

        getImage(event,url){
            const file = event.target.files[0];
            if(!file) return;
            this.image = null;
            if (file.size > this.maxsizeImage || file.fileSize > this.maxsizeImage) {
                alert(`Allowed file size exceeded. (Max. ${this.maxsizeImage/ (1024*1024)} MB)`);
            } else {
                this.image = new Image;

                const ext = this.getExtantionFile(file.name);
                if (ext === 'png' || ext === 'jpeg' || ext === 'jpg' || ext === 'svg'){
                    if(ext === 'svg'){
                        this.sendPhoto(file,url);
                    }else {
                        this.image.onload = () => {
                            this.convertPhoto(file,url);
                        };
                        this.image.src = URL.createObjectURL(file);
                    }

                } else {
                    alert('This format is not supported!\n' +
                        'Supported formats .png .jpeg .jpg .svg');
                }
            }
        },
        // getPortfolioPack(event){
        //     const file = event.target.files[0];
        //     let pack = { src: URL.createObjectURL(file)}
        //     this.previewPortfolioPack.push(pack)
        // },
        getExtantionFile(fileName){
            let parts = fileName.split('.');
            let ext = '';
            if (parts.length > 1) {
                ext = parts.pop();
                return ext.toLowerCase();
            }
            return '';
        },
        errorHandler(err){
            this.errorHandler('errorhandler',err);
        },
    }
}
</script>

<style scoped>

</style>
