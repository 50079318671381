<template>
    <div class="form__row">
        <label class="form__label">{{labelName}}</label>
        <div class="form__col">
            <input type="number" class="ui-input" v-model="minValue"  >
            <input type="number" class="ui-input" v-model="maxValue"  >
        </div>
        <div class="ui-range ui-range_double">

            <span class="ui-range__track" id="range_track" :style="`left: ${styleLeft}%; right: ${styleRight}%;`"></span>

            <input type="range" class="ui-range__input ui-range__input_min"
                            :min=min
                            :max=max
                            :step=step
                            v-model=minValue
                            @input="changeMin"
            >
            <input type="range" class="ui-range__input ui-range__input_max"
                            :min=min
                            :max=max
                            :step=step
                            v-model=maxValue
                            @input="changeMax"
            >
        </div>
    </div>

</template>
<script>
export default {
    name: "DoubleRangeSlider",
    props:{
        labelName: {
            type: String,
            require: true,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 100,
        },
        step: {
            type: Number,
            default: 1,
        },
        initMin: {
            type: Number,

        },
        initMax: {
            type: Number,
        },

    },
    data(){
        return{
            minValue: this.min,
            maxValue: this.max
        }
    },
    computed:{
        onePercent(){
            return (this.max - this.min)/100;
        },
        styleLeft(){
            return (this.minValue - this.min)/this.onePercent
        },
        styleRight(){
            return (this.max-this.maxValue)/this.onePercent
        },

    },
    mounted() {
        if(this.initMax) this.maxValue = this.initMax
        if(this.initMin) this.minValue = this.initMin
    },
    methods:{
        changeMax(value){
             //console.log('changeMax')
            if (+this.maxValue < +this.minValue+3) this.maxValue = +this.minValue+3
            else{
                this.$emit("update_max_value", +this.maxValue);
            }
        },
        changeMin(value){
             //console.log('changeMin')
            if (+this.minValue > +this.maxValue-3) this.minValue = +this.maxValue-3

            else{
                this.$emit("update_min_value", +this.minValue);
            }

        },
        reset(){
            this.minValue = this.min
            this.maxValue = this.max
        }
    }
}
</script>

