<template>
    <div v-if="isEdit">
        <ul class="list list_hobbies" v-if="interests.length">
            <li class="list__item" v-for="interest in interests">
            <span class="icon-box">
                <i class="icon icon_hobbies" :class="interest.img"></i>
                <span class="icon-box__title">{{getTitleInterest(interest.title)}}</span>
            </span>
            </li>
        </ul>
        <div v-else class="profile__text-preview">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#information`" />
            </svg>
            <span>You have not provided this information about yourself</span>
        </div>
    </div>
    <div v-else>
        <ul class="list list_hobbies">
            <li class="list__item" v-for="interest in interests">
            <span class="icon-box">
                <i class="icon icon_hobbies" :class="interest.img"></i>
                <span class="icon-box__title">{{getTitleInterest(interest.title)}}</span>
            </span>
            </li>
        </ul>
    </div>
</template>
<script>
export default {
    name: "MyInterests",
    props:['interests', 'isEdit', 'myUrl'],
    methods:{
        getTitleInterest(title){
            return title.charAt(0).toUpperCase() + title.slice(1);
        },
    }
}
</script>

<style scoped>

</style>
