import { render, staticRenderFns } from "./ClearUnreadMessages.vue?vue&type=template&id=32485393&scoped=true"
import script from "./ClearUnreadMessages.vue?vue&type=script&lang=js"
export * from "./ClearUnreadMessages.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32485393",
  null
  
)

export default component.exports