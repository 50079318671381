<template>

    <div :class="`modal modal_promo modal_${promoName} is-active`" tabindex="-1" role="dialog">
        <div class="modal__bg" @click="closeModal"></div>
        <div class="modal__container">
            <span class="modal__close"  role="button" aria-label="Close" @click.prevent="closeModal">✕</span>
            <picture>
                <!--                <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-3840.jpg`" media="(min-width: 3840px)">-->
                <!--                <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-3440.jpg`" media="(min-width: 3440px) and (max-width: 3839px)">-->
                <!--                <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-2560.jpg`" media="(min-width: 2560px) and (max-width: 3439px)">-->
                <!--                <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-1920.jpg`" media="(min-width: 1920px) and (max-width: 2559px)">-->
                <!--                <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-1680.jpg`" media="(min-width: 1680px) and (max-width: 1919px)">-->
                <!--                <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-1440.jpg`" media="(min-width: 1440px) and (max-width: 1679px)">-->
                <!--                <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-1280.jpg`" media="(min-width: 768px) and (max-width: 1439px)">-->
                <!--                    <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-992.png`" media="(min-width: 992px) and (max-width: 1279px)">-->
                <!--                    <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-768.png`" media="(min-width: 768px) and (max-width: 991px)">-->
                <source :srcset="`${myUrl}/source/images/modal/${promoName}/${promoName}-mobile-modal.jpg`" media="(max-width: 767px)">
                <img class="modal__background" :src="`${myUrl}/source/images/modal/${promoName}/${promoName}-original.jpg`" :alt="`${promoName}`">
            </picture>
            <count-down
                :deadline="holidayPromotion.stop"
                @stop_promo="stopPromo"
                v-if="holidayPromotion && holidayPromotion.stop"
            ></count-down>
        </div>

    </div>


</template>
<script>
import CountdownPromo from "../CountdownPromo.vue";
import CountDown from "../CountDown.vue";

export default {
    name: "ModalFreeChat",
    components: {CountDown, CountdownPromo},
    props: ['promoName', 'myUrl', 'holidayPromotion'],
    mounted() {
        this.$bus.$emit('open_modal')
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
    },
    methods:{
        stopPromo() {
            console.log('stopPromo');
        },
        closeModal(){
            this.$emit('close_modal');
        },
    }
}
</script>


<style scoped>

</style>
