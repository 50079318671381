<template>
    <div class="section__buttons">
        <template v-if="installPromptEvent && btnInstall">
            <button class="button button_md button_primary" data-modal="install" @click.prevent="clickInstallDesktop">
                <span>Install app</span>
            </button>
        </template>
        <template v-else>
            <button class="button button_md button_primary" data-modal="install" @click.prevent="openInstall">
                <span>Install app</span>
            </button>
        </template>
    </div>

</template>

<script>
export default {
name: "InstallDesktop",
    props:['myUrl'],
    data(){
        return{
            installPromptEvent:null,
            btnInstall:true,
        }
    },
    mounted() {
        console.log('mounted button install');
        // please install pwa
        window.addEventListener('beforeinstallprompt', event => {
            console.log('beforeinstallprompt event was fired');

            // Prevent Chrome <= 67 from automatically showing the prompt
            // event.preventDefault();

            // Stash the event so it can be triggered later.
            this.installPromptEvent = event;
        });
    },
    methods:{
        openInstall(){
            this.$emit('open_modal_install')
        },
        clickInstallDesktop(){
            // Show the modal add to home screen dialog
            // document.body.classList.add('has-blur')
            this.$emit('blur_landing')
            this.installPromptEvent.prompt();
            this.btnInstall = false;

            // Wait for the user to respond to the prompt
            this.installPromptEvent.userChoice
                .then(choice => {
                if (choice.outcome === 'accepted') {
                    console.log('User accepted the A2HS prompt');
                } else {
                    console.log('User dismissed the A2HS prompt');
                }
                // Clear the saved prompt since it can't be used again
                this.installPromptEvent = null;
                this.$emit('remove_blur_landing')
                // document.body.classList.remove('has-blur')
            });
        }
    }
}
</script>

<style scoped>

</style>
