<template>
    <div>
        <div class="chat__dropdown dropdown dropdown_bottom dropdown_sm" v-if="$mq==='desktop'">
            <ul class="dropdown__menu menu menu_upload">
                <li class="dropdown__item menu__item">
                <span class="dropdown__link menu__link">
                    <span class="dropdown__label">Select gif</span>
                    <i class="icon icon icon_image"></i>
                    <input name="image" @change="getGif" type="file" accept=".gif">
                </span>
                </li>
                <li class="dropdown__item menu__item">
                <span class="dropdown__link menu__link">
                    <span class="dropdown__label">Select image</span>
                    <i class="icon icon_camera"></i>
                    <input name="image" type="file" @change="getImage" accept=".jpg, .jpeg, .png, .svg">
                </span>
                </li>
                <li class="dropdown__item menu__item">
                <span class="dropdown__link menu__link">
                    <span class="dropdown__label">Select video</span>
                    <i class="icon icon_video"></i>
                    <input name="video" type="file" @change="getVideo"
                           accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm">
                </span>
                </li>
<!--                <li class="dropdown__item menu__item">-->
<!--                <span class="dropdown__link menu__link" @click="recordeVoice">-->
<!--                    <span class="dropdown__label">Select Voice</span>-->
<!--                    <i class="icon icon_mic"></i>-->
<!--&lt;!&ndash;                    <input name="sound" type="file" accept=".aac, .ac3, .amr, .m4a, .oga, .wav, .weba, .wma, .mp3, .m4b"&ndash;&gt;-->
<!--&lt;!&ndash;                           @change="getVoice">&ndash;&gt;-->
<!--                </span>-->
<!--                </li>-->
            </ul>
        </div>
        <div class="chat__caption" v-else>
            <ul class="menu menu_upload">
                <li class="menu__item">
                    <span class="menu__link">
                        <i class="icon icon icon_image"></i>
                        <input name="image" @change="getGif" type="file" accept=".gif">
                    </span>
                </li>
                <li class="menu__item">
                    <span class="menu__link">
                        <i class="icon icon_camera"></i>
                        <input name="image" @change="getImage" type="file" accept=".jpg, .jpeg, .png, .svg">
                    </span>
                </li>
                <li class="menu__item">
                    <span class=" menu__link">
                        <i class="icon icon_video"></i>
                        <input name="video" type="file" @change="getVideo"
                               accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm">
                    </span>
                </li>

                <li class="menu__item">
                    <span class="menu__link">
                         <span class="button button_emoji" @click.stop.capture="toggleEmoji" ref="btnEmoji">
                            <i class="icon icon_emoji"></i>
                        </span>
                    </span>
                </li>
<!--                <li class="menu__item">-->
<!--                    <span class="menu__link" @click="recordeVoice">-->
<!--                        <i class="icon icon_mic"></i>-->
<!--&lt;!&ndash;                        <input name="sound" type="file" @change="getVoice"&ndash;&gt;-->
<!--&lt;!&ndash;                               accept=".aac, .ac3, .amr, .m4a, .oga, .wav, .weba, .wma, .mp3, .m4b">&ndash;&gt;-->
<!--                    </span>-->
<!--                </li>-->
            </ul>
        </div>
    </div>
</template>

<script>
const videoExts = ['avi', '3gp', 'flv', 'm4v', 'mkv', 'mov', 'mp4', 'mpeg', 'mpg', 'ogg', 'webm'];
const audioExts = ['aac', 'ac3', 'amr', 'm4a', 'oga', 'wav', 'weba', 'wma', 'mp3', 'm4b'];
export default {
    name: "MenuUpload",
    data() {
        return {
            media: null,
            image: null,
        }
    },
    methods: {
        toggleEmoji(){
            this.$emit('toggle_emoji')
        },
        // recordeVoice(){
        //     console.log('recordeVoice');
        //
        //     window.navigator.mediaDevices.getUserMedia({ audio: true })
        //         .then(stream => {
        //             // Создаем MediaRecorder
        //             const mediaRecorder = new MediaRecorder(stream);
        //
        //             // Объявляем массив для хранения чанков данных с микрофона
        //             let audioChunks = [];
        //
        //             // Обработчик события dataavailable вызывается каждый раз, когда MediaRecorder
        //             // готов передать накопленные аудиоданные
        //             mediaRecorder.addEventListener("dataavailable", event => {
        //                 audioChunks.push(event.data);
        //             });
        //
        //             // Обработчик события stop, вызывается когда запись остановлена
        //             // На этом этапе можно обрабатывать все чанки данных
        //             mediaRecorder.addEventListener("stop", () => {
        //                 console.log('stop recorde')
        //                 // Шаг 2: Создать аудио-блоб из записанных данных
        //                 const audioBlob = new Blob(audioChunks);
        //                 const mimeType = mediaRecorder.mimeType;
        //                 console.log({mimeType})
        //                 let fileType = mimeType.split('/')[1]; // Получение расширения файла из MIME типа
        //                 console.log(fileType)
        //                 fileType = fileType.split(';')[0]
        //                 const audioFile= new File([audioBlob], `recorded-audio.${fileType}`,{
        //                     type: mimeType,
        //                     lastModified: new Date().getTime() // Опционально: установить время последней модификации файла
        //                 });
        //                 console.log(audioFile)
        //                 stream.getTracks().forEach(track => track.stop());
        //                 this.$emit('upload_voice', {
        //                     file: audioFile,
        //                     preview: URL.createObjectURL(audioFile),
        //                 })
        //
        //             });
        //
        //             // Начать запись
        //             mediaRecorder.start();
        //
        //             // Допустим, мы хотим записать аудио в течение 5 секунд
        //             setTimeout(() => {
        //                 // Остановить запись
        //                 mediaRecorder.stop();
        //             }, 5000);
        //         })
        //         .catch(error => {
        //             console.error(error);
        //         });
        // },

        getGif(event) {
            console.log('getGif')
            console.log(event)
            if (event.target.files[0].size > (5 * 1024 * 1024)) {
                alert("Saving error, size over 5 MB!");
            } else {
                this.$emit('upload_gif', this.getFile(event))
            }
        },
        getImage(event) {
            // this.$emit('upload_file', file, preview)
            this.$emit('upload_image', this.getFile(event))
        },
        getFile(event) {
            const file = event.target.files[0];
            event.target.value = null;
            return {
                file: file,
                preview: URL.createObjectURL(file),
            }
        },
        getVoice(event) {
            if (event.target.files[0].size > (10 * 1024 * 1024)) {
                alert("Saving error, size over 10 MB!");
            } else if (this.checkExtantion(audioExts, event.target.files[0].name)) {
                // this.$bus.$emit('selected_voice',event.target.files[0])
                this.$emit('upload_voice', this.getFile(event))
            } else {
                alert("This file format is not supported. Select the file type from the list: " + audioExts);
            }
            event.target.value = null;
        },
        getVideo(event) {
            if (event.target.files[0].size > (40 * 1024 * 1024)) {
                alert("Saving error, size over 40 MB!");
            } else if (this.checkExtantion(videoExts, event.target.files[0].name)) {
                console.log('file video');
                // console.log(event.target.files[0]);
                // console.log(event.target.result);
                // this.$bus.$emit('selected_video',event.target.files[0])
                this.$emit('upload_video', this.getFile(event))
            } else {
                alert("This file format is not supported. Select the file type from the list: " + videoExts);
            }
            event.target.value = null;
            // let file = event.target.files[0]
            // let preview = URL.createObjectURL(file);
        },
        checkExtantion(exts, name) {
            return !!exts.find(ext => {
                return ext === this.getExtantionFile(name)
            })
        },
        getExtantionFile(fileName) {
            let parts = fileName.split('.');
            let ext = '';
            if (parts.length > 1) {
                ext = parts.pop();
                return ext.toLowerCase();
            }
            return '';
        },


    }
}
</script>

<style scoped>

</style>
