<template>
    <div class="page page_static">
        <section class="section section_page">
            <div class="container">
                <h2 class="section__heading heading text-gradient">ERROR 404 </h2>
                <h4 class="section__content-title">The page does not exist</h4>
                <span class="button button_md button_border mt-2" @click="gotoHome">
                    <span>Go to main page</span>
                </span>
            </div>
        </section>
    </div>
</template>

<script>
export default {
name: "Error404",
    methods:{
        gotoHome(){
            this.$router.push({path: '/gallery'});
        }
    }
}
</script>

<style scoped>

</style>
