<template>
    <portal selector="#portal-target">
        <div class="modal modal_lg modal_payment modal_payment-table is-active" tabindex="-1" role="dialog">
            <div class="modal__bg" @click.prevent="closeModal"></div>
            <div class="modal__container">
                <span class="modal__close"  role="button" aria-label="Close" @click.prevent="closeModal">
                    <i class="icon icon_close"></i>
                </span>
                <div class="modal__header">
                    <h2 class="heading">Successful Payment <i class="icon icon_md icon_secured"></i></h2>
                </div>
                <div class="modal__body">
                    <table class="table table_page table_modal table_responsive">
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Description</th>
                            <th>Credit</th>
                            <th class="text-center">Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
<!--                            12-08-2023-->
                            <td class="table__cell table__date">{{datePayment()}}</td>
                            <td class="table__cell table__description">Purchase of credits</td>
                            <td class="table__cell table__credit"><span class="title">{{(credits/100).toFixed(2)}}</span></td>
                            <td class="table__cell table__amount text-center"><span class="title">$ {{(amount/100).toFixed(2)}}</span></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal__footer">
                    <button href="" class="modal__button button button_md button_primary" @click.prevent="closeModal">
                        <span>Apply</span>
                    </button>
                    <p>
                        <span>To contact us you can use <a class="page__link" href="mailto:support@hablario.com">support@hablario.com</a></span>
                    </p>
                </div>
            </div>
        </div>
    </portal>

</template>
<script>

export default {
    name: "ModalPaymentCoinsBuyStatus",
    props:['credits','amount'],
    mounted() {
        this.$bus.$emit('open_modal')
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
    },
    methods:{
        closeModal(){
            this.$emit('close_modal')
        },
        datePayment(){
            let date
            if (this.datetime){
                date = new Date(this.datetime);
            }else {
                date = new Date();
            }
            let month = date.getMonth() + 1;
            if(month < 10){
                month = `0${month}`;
            }
            let day = date.getDate();
            if(day < 10){
                day = `0${day}`;
            }
            return `${day}-${month}-${date.getFullYear()}`
        }
    }
}
</script>

<style scoped>

</style>
