<template>
    <div class="form form_horizontal">
        <div class="form__header">
            <h2 class="form__heading">Log in</h2>
            <h4 class="form__sub-heading" v-if="error500">Attention!!!</h4>
            <h4 class="form__sub-heading" v-else>to your account</h4>
            <span class="form__error"  v-show="errMessage">{{errMessage}}</span>
        </div>
        <template v-if="error500">
            <div>
                <!--                <h4 class="text-red">Attention</h4>-->
                <h4>We apologize for any inconvenience caused, but we are currently performing essential maintenance.</h4>
                <countdown-maintenance v-if="timeMaintenance"
                                       :deadline="timeMaintenance"
                ></countdown-maintenance>
                <!--                <h6 v-if="timeMaintenance">Technical work completion date: {{timeMaintenance.toLocaleString()}}</h6>-->
            </div>
        </template>
        <template v-else>
            <div class="form__row" :class="{'is-error':errEmail}">
                <label for="email" class="form__label">Email</label>
                <input id="email" class="ui-input" maxlength="255" type="email" autocomplete="username" v-model="email">
                <span class="form__error" v-show="errEmail">{{errEmail}}</span>
            </div>
            <div class="form__row" :class="{'is-error':errPassword}">
                <label for="password" class="form__label">Password</label>
                <div class="form__row-wrapper">
                    <input id="password" class="ui-input" maxlength="255" :type="isSeePassword?'text':'password'" autocomplete="current-password" v-model="password">
                    <i class="form__icon icon icon_eye" @click.prevent="seePassword"></i>
                </div>
                <span class="form__error" v-show="errPassword">{{ errPassword}}</span>
            </div>
            <div class="form__row is-flex">
                <label for="keep" class="form__label">
                    <input type="checkbox" id="keep" class="ui-checkbox">
                    <span class="label">Keep me logged in</span>
                </label>
                <span class="form__link" data-modal="forgot" @click.prevent="openForgotPassword">Forgot Your Password?</span>
            </div>

        </template>

        <div class="form__footer" v-if="error500">
            <button class="button button_md button_primary"  @click.prevent="tryAgain">
                <span>Try again</span>
            </button>
        </div>

        <div class="form__footer" v-else>
            <button class="button button_md button_primary" :disabled="!(this.email && this.password) || disabledButton" @click.prevent="loginUser">
                <span>Login</span>
            </button>
<!--            <a :href="urlGoogle" class="button button_md button_bordered">-->
<!--               <span>-->
<!--                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18">-->
<!--                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#google`" />-->
<!--                    </svg>-->
<!--                    <span class="button__text">Sign up with Google</span>-->
<!--               </span>-->
<!--            </a>-->
            <a :href="`${myUrl}/socialite/google/auth/login`" class="button button_md button_bordered" v-if="!timeMaintenance">
               <span>
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#google`" />
                    </svg>
                    <span class="button__text">Sign In with Google</span>
               </span>
            </a>
            <span class="form__text" @click.prevent="openRegister">Create a new account?
                <span class="form__link" data-modal="sign">Register</span>
            </span>
        </div>
    </div>
</template>

<script>
export default {
name: "login",
    props:['myUrl','message'],
    data(){
        return{
            email:'',
            password:'',
            errEmail:'',
            errPassword:'',
            errCredentials:false,
            errMessage:'',
            isSeePassword:false,
            disabledButton:false,
            timeMaintenance: null,
            error500: false
        }
    },
    mounted() {

        if(this.message){
            alert(this.message);
        }
        if (navigator && !navigator.cookieEnabled) {
            this.errMessage = 'Please enable cookies in your browser for the best website experience!!!'
        }
        this.getTimeMaintenance()
    },

    methods:{
        tryAgain(){
            window.location.reload()
        },
        getTimeMaintenance(){
            console.log('getTimeMaintenance');
            axios.get('/get-time-maintenance')
                .then(response => {
                    console.log(response.data)
                    if(response.data.time_maintenance){
                        this.timeMaintenance = response.data.time_maintenance;
                        if(this.timeMaintenance) this.error500 = true;
                    }
                })
                .catch(e=>{
                    console.log(e)
                    // this.errorHandler(e)
                })
        },
        openForgotPassword(){
            this.$emit('open_forgot_password');
        },
        openRegister(){
            this.$emit('open_register');
        },

        seePassword(){
            this.isSeePassword = !this.isSeePassword ;
        },
        goToLending(){
            window.location.href = this.myUrl+'/';
        },
        loginUser(){
            console.log('LoginUser');
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.errEmail = !re.test(String (this.email).toLowerCase())?'Incorrect email address':'';
            this.errPassword =this.password.length<6?'Password must be at least 6 characters long':'';

            if (!this.errEmail && !this.errPassword && !this.disabledButton){
                this.disabledButton = true;

                axios.post('/login-user',{
                    email:this.email,
                    password:this.password,
                })
                    .then(response => {
                        this.disabledButton = false;
                        if (response.data.currentUser){
                            console.log(response.data);
                            console.log('login-user success...');
                            this.$emit('login',response.data.currentUser);
                        }else {
                            this.errMessage = 'These credentials do not match our records';
                            this.errCredentials = true ;
                        }
                    })
                    .catch(e => {
                        this.disabledButton = false;
                        console.log(e);
                        if (+e.response.status===422 && e.response.data.errors){
                            this.errEmail = e.response.data.errors.email?e.response.data.errors.email[0]:'';
                            this.errPassword = e.response.data.errors.password?e.response.data.errors.password[0]:'';
                            this.errMessage = e.response.data.message ;
                            // alert(e.response.data.message)
                        }else {
                            this.errorHandler(e);
                        }
                    });
                }
        },
        errorHandler(e){
         this.$emit('errorhandler',e);
        },
    }
}
</script>

<style scoped>

</style>
