<template>
    <div class="form form_horizontal">
        <div class="form__header">
            <h2 class="form__heading">Forgot Password</h2>
            <h4 class="form__sub-heading" v-if="!answer">To recover your password</h4>
            <h4 class="form__sub-heading" v-else>Check your email, a password reset has been sent to
                you
            </h4>
            <span class="is-error" style="display: none;"></span>
        </div>
        <div class="form__row">
            <label for="emailUser">Email</label>
            <input id="emailUser" class="ui-input" type="text" autocomplete="Email" v-model="email" :disabled="!!answer">
            <span class="is-error" v-if="errEmail">{{ errEmail }}</span>
        </div>
        <div class="form__footer">
            <button class="button button_md button_primary" @click.prevent="send" v-if="!answer">
                <span>Send Me Email</span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
name: "ForgotPassword",
    props:['urlGoogle'],
    data(){
        return{
            email: '',
            errEmail: '',
            answer: '',
        }
    },
    methods:{
        send(){
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            this.errEmail = !re.test(String (this.email).toLowerCase())?'Incorrect email address':'';
            if (!this.errEmail){
                axios.post('/password/email ',{
                    email:this.email,
                })
                    .then(response => {
                        console.log(response.data);
                        this.answer = response.data.message;

                    })
                    .catch(e => {
                        console.log(e);
                        if (+e.response.status===422 && e.response.data.errors){
                            this.errEmail = e.response.data.errors.email?e.response.data.errors.email[0]:'';
                        }else {
                            this.errorHandler(e);
                        }
                    });
            }

        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    }
}
</script>

<style scoped>

</style>
