<template>
    <section class="section section_intro section_welcome" :style="`min-height: ${windowHeight}px`">
        <img class="video video_responsive" ref="image" :src="imageLendingSrc">
        <div class="section__caption"
             :class="{'section__caption_romantic' : region === 'romantic',
             'section__caption_friends' : region === 'friends',
             'section__caption_italy' : region === 'italy',
             'section__caption_girls' : region === 'girls',
             'section__caption_over' : region === 'over',
             'section__caption_mistress' : region === 'mistress',
             'section__caption_nurse' : region === 'nurse',
             'section__caption_adult' : region === 'adult',
             'section__caption_relationship' : region === 'relationship'}">
            <div class="content" :class="{'content_texas' : region === 'texas'}" v-if="region === 'texas'">
                <span class="title is-text-animation"><span>Hello</span> <span>Texan</span></span>
                <span class="text"><span>I want to</span> <span>talk to you</span></span>
            </div>
            <div class="content" :class="{'content_romantic' : region === 'romantic'}" v-if="region === 'romantic'">
                <span class="title">
                    <span class="heading">The best <span>place</span></span>
                    <span class="is-first">for romantic <span>messaging</span></span>
                    <span class="is-second">with <span>single <span>women</span></span></span>
                </span>
            </div>
            <div class="content" :class="{'content_relationship' : region === 'relationship'}" v-if="region === 'relationship'">
                <span class="title">
                    <span class="is-first">The best <span>place</span></span>
                    <span class="is-second">for sexting</span>
                </span>
                <span class="text">
                    <span class="is-first">with horny</span>
                    <span class="is-second">single women</span>
                </span>
            </div>
            <div class="content" :class="{'content_friends' : region === 'friends'}" v-if="region === 'friends'">
                <span class="title">
                    <span class="is-first">The best place <span>for hot datings</span></span>
                    <span class="is-second">With <span>single women</span></span>
                </span>
            </div>
            <div class="content" :class="{'content_italy' : region === 'italy'}" v-if="region === 'italy'">
                <span class="title">
                    <span class="is-first">The Best <span>Place</span></span>
                    <span class="is-second">for hot <span>messaging</span></span>
                </span>
                <span class="text">with single <span>Italian Women</span></span>
            </div>
            <div class="content" :class="{'content_girls' : region === 'girls'}" v-if="region === 'girls'">
                <span class="title">
                    <span class="is-first">The Best Place <span>to chat</span></span>
                    <span class="is-second">with <span>single girls</span></span>
                </span>
            </div>
            <div class="content" :class="{'content_over' : region === 'over'}" v-if="region === 'over'">
                <span class="title">
                    <span class="is-first">The Best Place <span>for men over 60</span></span>
                    <span class="is-second">for messaging <span>with Single Women</span></span>
                </span>
            </div>
            <div class="content" :class="{'content_mistress' : region === 'mistress'}" v-if="region === 'mistress'">
                <span class="title">
                    <span class="is-first"><span>The best place</span> <span>for school <span>mistress</span></span></span>
                    <span class="is-third"><span>role-playing</span> <span>sexting games</span></span>
                    <span class="is-second">with <span>Single Women</span></span>
                </span>
            </div>
            <div class="content" :class="{'content_nurse' : region === 'nurse'}" v-if="region === 'nurse'">
                <span class="title">
                    <span class="is-first"><span>The best place</span><span>to role-playing</span></span>
                    <span class="is-third"><span>a sexy nurse</span> <span>by texting</span></span>
                    <span class="is-second">with <span>Single Women</span></span>
                </span>
            </div>
            <div class="content" :class="{'content_adult' : region === 'adult'}" v-if="region === 'adult'">
                <span class="title">
                    <span class="is-first">
                        <span>The best place</span>
                        <span><span>for</span> adult messaging</span>
                    </span>
                    <span class="is-second">with <span>Single Women</span></span>
                </span>
            </div>
<!--            <div class="section__buttons">-->
<!--                <button class="btn btn_light btn_login" data-toggle="modal" data-target="#loginModal" v-if="$mq ==='desktop'">Login</button>-->
<!--                <button class="btn"  :class="{'btn_border-light': $mq ==='desktop', 'btn_light': $mq !=='desktop'}" data-toggle="modal" data-target="#registerModal">Registration</button>-->
<!--            </div>-->
        </div>
    </section>
</template>
<!--https://ps3lifeforlove.fra1.cdn.digitaloceanspaces.com/hablario-static-data/promo/black/black-1920.jpg-->
<script>
export default {
    name: "LandingImage",
    props:['cdnUrl','myUrl','windowHeight','cdnStaticDataPath','srcImage', 'region'],
    data(){
        return{
            isMuted: true,
            imageLendingSrc: '',
        }
    },
    created() {
    },
    mounted() {
        this.setImageSrc();
    },
    methods:{
        scrollDown(){
            this.$emit('scroll_down_lending')
        },
        setImageSrc() {
            if (this.$refs.image) {
                const windowWidth = window.innerWidth;
                const windowHeight = window.innerHeight;
                console.log(windowWidth,windowHeight);
                if (windowWidth >= 3840) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.tv
                } else if (windowWidth >= 3440) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.big
                } else if (windowWidth >= 2560) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.high
                } else if (windowWidth >= 1920) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.xl
                } else if (windowWidth >= 1680) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.lg
                } else if (windowWidth >= 1440) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.md
                } else if (windowWidth >= 1280) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.smart
                } else if (windowWidth >= 992) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.ipad
                } else if (windowWidth >= 768) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.medium
                } else if (windowWidth >= 567) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.mobile
                } else if (windowWidth >= 428) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.xs
                } else if (windowWidth >= 414) {
                    if (windowWidth === 414 && windowHeight === 736) {
                        this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.xs_414_736
                    } else {
                        this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.xs_414
                    }
                } else if (windowWidth >= 390) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.xxs
                } else if (windowWidth >= 375) {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.xxs_375
                } else {
                    this.imageLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcImage.xxs_320
                }
            }
        },
    }
}
</script>

<style scoped>

</style>
