<template>
    <div class="panel__tab panel__tab_chat">
        <list-contact-opponents
            :s3-url="s3Url"
            :current-user="currentUser"
            :my-url="myUrl"
            :contacts="chatRequest"
            :role-opponent="roleOpponent"
            :type="'chat-request'"
            :unread="unread"
            :ban-list="banList"
            :is-new-version="isNewVersion"
            :is-supported-webp="isSupportedWebp"
            @get_more_contacts="getMoreChatRequest"
        ></list-contact-opponents>
    </div>
</template>
<script>

import ListContactOpponents from "../modules/ListContactOpponents.vue";

export default {
    name: "ContactUnread",
    components: { ListContactOpponents },
    props:[
        's3Url',
        'currentUser',
        'chatOwner',
        'myUrl',
        'contacts',
        'roleOpponent',
        'banList',
        'windowHeight',
        'unread',
        'isNewVersion',
        'isAllChatRequest',
        'countContacts',
        'isSupportedWebp'
    ],
    data(){
        return{
            isDownload:false,
        }
    },
    computed:{
        chatRequest(){
            return  this.contacts.filter(contact=>{
                return +contact[`unread_${this.currentUser.role}`] && !this.banList.includes(contact[this.roleOpponent].id);
            })

        }
    },
    methods: {
        openMenu() {
            this.$emit('open_menu', false);
        },
        closeRequest(){
            this.$emit('close_request');
        },
        goToFavorite() {
            this.$emit('go_to_favorite');
        },
        goToHistory() {
            this.$emit('go_to_history');
        },
        getMoreChatRequest(){
            console.log('getMoreChatRequest')
            if (!this.isAllChatRequest && !this.isDownload){
                this.isDownload = true ;

                axios.post('/get-more-chat-request/v2',{
                    last: this.chatRequest[this.chatRequest.length-1].last_time,
                    ownerID: this.chatOwner.id,
                    role: this.currentUser.role,
                    roleOpponent: this.roleOpponent,
                    countContacts: this.countContacts
                })
                    .then(response => {
                        console.log(response.data);
                        this.isDownload = false ;

                        this.$bus.$emit('add_contacts',{
                            contacts:response.data.contacts,
                            messages:response.data.messages,
                            countUnread:response.data.countUnread,
                            type:'chat_request',
                        })
                    })
                    .catch(e => {
                        console.log(e);
                        this.isDownload = false ;
                        this.errorHandler(e);
                    })

            }
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        // openModalClear() {
        //     console.log('openModalClear chat request');
        //     this.$emit('open-modal-clear');
        // },
    }
}
</script>

<style scoped>

</style>
