<template>
    <div>
        <div class="profile__avatar profile__avatar_rounded" v-if="isEdit">
            <div class="upload">
                <picture v-if="imgGallery">
                    <source type="image/webp" :srcset="`${s3Url}/${imgGalleryWebp}`" v-if="isSupportedWebp && imgGalleryWebp">
                    <img class="avatar avatar_xl" :src="`${s3Url}/${imgGallery}`" alt="person photo" width="100" height="100">
                </picture>
                <img class="avatar avatar_xl" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="100" height="100" v-else>

                <label for="profileUpload" class="upload__label upload__label_abs upload__label_image">
                    <input @change="uploadImage" ref="upload"  id="profileUpload" type='file' accept=".png, .jpg, .jpeg, .svg, .webp" hidden />
                </label>
            </div>
        </div>
        <portal selector="#portal-target" v-if="modalCropper">
            <div class="modal modal_cropper is-active" tabindex="-1" role="dialog">
                <div class="modal__bg" @click.prevent="closeModalCropper"></div>
                <div class="modal__container">
            <span class="modal__close button button_filled"  role="button" aria-label="Close" @click.prevent="closeModalCropper">
                <i class="icon icon_close"></i>
            </span>
                    <div class="modal-body">
                        <image-cropper
                            :name-header="'CropperMobile'"
                            :my-url="myUrl"
                            :src="image.src"
                            :width="galleryWidth"
                            :height="galleryHeight"
                            :avatar-size="avatarSize"
                            :is-make-thumb="true"
                            @crop="cropImages"
                            v-if="image"
                        ></image-cropper>
                    </div>
                </div>
            </div>
        </portal>

        <portal selector="#portal-target" v-if="modalCropperDesktop">
            <div class="modal modal_cropper is-active" tabindex="-1" role="dialog">
                <div class="modal__bg" @click.prevent="closeModalCropper"></div>
                <div class="modal__container">
        <span class="modal__close button button_filled"  role="button" aria-label="Close" @click.prevent="closeModalCropper">
            <i class="icon icon_close"></i>
        </span>
                    <div class="modal-body">
                        <image-cropper
                            :name-header="'CropperDesktop'"
                            :my-url="myUrl"
                            :src="image.src"
                            :width="galleryWidthDesktop"
                            :height="galleryHeightDesktop"
                            :avatar-size="avatarSize"
                            :is-make-thumb="false"
                            @crop="cropImagesDesktop"
                            v-if="image"
                        ></image-cropper>
                    </div>
                </div>
            </div>
        </portal>


            </div>
</template>
<script>
import ImageCropper from "../modules/ImageCropper.vue";
import {withScope} from "@sentry/vue";

export default {
    name:"CropperModule",
    components: {ImageCropper},
    props:['s3Url', 'myUrl', 'isEdit', 'isSupportedWebp', 'imgGalleryWebp', 'imgGallery', 'memberRole', 'memberID'],
    data(){
        return{
            modalCropper:false,
            modalCropperDesktop:false,
            image:null,
            galleryWidth: 512,
            galleryHeight: 828,
            galleryWidthDesktop: 770,
            galleryHeightDesktop: 398,
            avatarSize: 100,
        }
    },
    methods:{
        openCropper(){
            this.modalCropper = true
        },
        uploadImage(event){
            console.log('uploadImage');
            const img = event.target.files[0];
            this.image = new Image()
            this.image.onload = () => {
                setTimeout(()=> {
                    this.$bus.$emit('open_modal');
                    this.modalCropper = true
                },300)
            };
            this.image.src = URL.createObjectURL(img)

            event.target.value = null
        },
        closeModalCropper() {
            this.modalCropper = false;
            this.modalCropperDesktop = false;
            this.image = null
            this.textCropper = null
            this.$bus.$emit('close_modal');
        },
        async cropImages(canvas, canvasThumb) {
            // this.cropImage(canvas, canvasThumb,"image/jpeg",'jpeg');
            // if(this.isSupportedWebp){
            //     this.cropImage(canvas, canvasThumb,"image/webp",'webp');
            // }

            this.modalCropper = false;
            this.$nextTick(()=>{
                this.modalCropperDesktop = true;
            })

            // this.image = null;
            const blobGallery = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
            const blobAvatar = await new Promise(resolve => canvasThumb.toBlob(resolve, "image/jpeg", 1));
            let blobGalleryWebp = null;
            let blobAvatarWebp = null;

            if(this.isSupportedWebp){
                blobGalleryWebp = await new Promise(resolve => canvas.toBlob(resolve, "image/webp", 1));
                blobAvatarWebp = await new Promise(resolve => canvasThumb.toBlob(resolve, "image/webp", 1));
            }
            this.uploadAvatar(blobGallery,blobAvatar,blobGalleryWebp,blobAvatarWebp);
        },
        uploadAvatar(fileGallery,fileAvatar,blobGalleryWebp,blobAvatarWebp){
            console.log('uploadAvatar');
            console.log(fileGallery);
            console.log(fileAvatar);

            if (this.memberID){
                const formData = new FormData();
                formData.append('avatar',fileAvatar,'avatar');
                formData.append('gallery',fileGallery,'gallery');
                formData.append('avatarWebp',fileAvatar,'avatarWebp');
                formData.append('galleryWebp',fileGallery,'galleryWebp');
                formData.append('role',this.memberRole);
                formData.append('memberID',this.memberID);
                axios.post(`/updated-avatar`,formData)
                    .then(response => {
                        console.log(response.data);
                        this.$bus.$emit('update_avatar',response.data.member);
                    })
                    .catch(e => {
                        console.log(e);
                        this.errorHandler(e);
                    })
            }
        },
        uploadAvatarDesktop(blobGallery,blobGalleryWebp){
            console.log('uploadAvatarDesktop');
            if (this.memberID){
                const formData = new FormData();
                formData.append('gallery',blobGallery,'gallery');
                formData.append('galleryWebp',blobGalleryWebp,'galleryWebp');
                formData.append('role',this.memberRole);
                formData.append('memberID',this.memberID);
                axios.post(`/updated-avatar-desktop`,formData)
                    .then(response => {
                        console.log(response.data);
                        this.$emit('updated_avatar_desctop',response.data.member);
                    })
                    .catch(e => {
                        console.log(e);
                        this.errorHandler(e);
                    })
                    .finally(()=>console.log('finally'))
            }
        },
        async cropImagesDesktop(canvas) {
            this.modalCropper = false;
            this.modalCropperDesktop = false;
            this.image = null;
            this.$bus.$emit('close_modal');
            const blobGallery = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
            let blobGalleryWebp = null;
            if(this.isSupportedWebp){
                blobGalleryWebp = await new Promise(resolve => canvas.toBlob(resolve, "image/webp", 1));
            }
            this.uploadAvatarDesktop(blobGallery,blobGalleryWebp);
        },
        errorHandler(e){
            this.$bus.$emit('error_handler',e);
        },
    }
}

</script>

<style scoped>

</style>
