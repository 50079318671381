<template>
    <portal selector="#portal-target">
        <CoolLightBox
            :items="imageBox"
            :index="indexImage"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @close="indexImage = null">
        </CoolLightBox>
        <CoolLightBox
            :items="videosBox"
            :index="indexVideo"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @close="indexVideo = null">
        </CoolLightBox>
        <div class="modal modal_lg modal_new-story modal_account is-active"
             id="new-story" tabindex="-1" role="dialog">
            <div class="modal__bg" @click="closeStoryCreator"></div>
            <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close" @click="closeStoryCreator">
                    <i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h2 class="modal__title">{{ post ? 'Edit the story' :'Create new story'}}</h2>
                </div>
                <div class="form form_horizontal">
                    <div class="form__row">
                        <label for="subjectStory" class="form__label">Subject</label>
                        <input id="subjectStory" placeholder="name your story" class="ui-input" maxlength="250" v-model="newStory.subject">
                    </div>
                    <div class="form__row">
                        <label for="descriptionStory" class="form__label">Description</label>
                        <textarea id="descriptionStory" placeholder="more ..." class="ui-textarea" maxlength="2000" v-model="newStory.description"></textarea>
                    </div>
                    <div class="form__row is-inline-flex">
                        <div class="form__col">
                            <label for="typeMedia" class="form__label">Type media</label>
                            <select class="ui-select" id="typeMedia" v-model="newStory.type_src">
                                <option value="image">Image</option>
                                <option value="video">Video</option>
                            </select>
                        </div>
                        <label for="isComment">
                            <input id="isComment" type="checkbox" v-model="newStory.is_comments" :true-value = false :false-value = true class="ui-checkbox">
                            <span class="label">No comment</span>
                        </label>
                    </div>
                    <template v-if="!unpublishedStory || !unpublishedStory.id">
                        <div class="form__footer">
                            <button class="button button_md button_primary" @click="createNewMyStory" :disabled="disabledNextStep">Next Step</button>
                        </div>
                    </template>
                    <template v-else>
                        <div class="form__row upload upload_modal">
                            <span class="form__label">Select poster for story</span>
                            <div class="upload__section">
                                <template v-if="!modalCropper && (!unpublishedStory.poster_gallery || !unpublishedStory.poster)">
                                    <label for="storyMediaUpload" class="upload__label">
                                        <button class="button button_sm button_primary">
                                            <span><i class="icon icon_choose button__icon"></i>Choose</span>
                                        </button>
                                        <input id="storyMediaUpload" type='file' accept=".png, .jpg, .jpeg, .svg, .webp" hidden @input="selectPoster"/>
                                    </label>
                                </template>
                                <template v-else-if="(unpublishedStory && unpublishedStory.poster_gallery && unpublishedStory.poster && unpublishedStory.id)">
                                    <label class="upload__label">
                                        <button class="button button_sm button_primary" @click.prevent="deletePoster">
                                            <span><i class="icon icon_choose button__icon"></i>Delete Poster</span>
                                        </button>
                                    </label>
                                </template>

                                <template v-else-if="imagePoster && (!unpublishedStory.poster_gallery || !unpublishedStory.poster)">
                                    <div v-if="!unpublishedStory.poster">
                                        <span>Crop Poster {{POSTER810X488.width}}X{{POSTER810X488.height}}</span>
                                        <image-cropper
                                            :src="imagePoster.src"
                                            :width="POSTER810X488.width"
                                            :height="POSTER810X488.height"
                                            :avatar-size="100"
                                            :is-make-thumb="false"
                                            :disabled-button="disabledButton"
                                            @crop="cropImages810X488"
                                        ></image-cropper>
                                    </div>
                                    <div v-if="!unpublishedStory.poster_gallery">
                                        <span>Crop Poster {{POSTER370X360.width}}X{{POSTER370X360.height}}</span>
                                        <image-cropper
                                            :src="imagePoster.src"
                                            :width="POSTER370X360.width"
                                            :height="POSTER370X360.height"
                                            :avatar-size="100"
                                            :is-make-thumb="false"
                                            :disabled-button="disabledButton"
                                            @crop="cropImages370X360"
                                        ></image-cropper>
                                    </div>

                                </template>

                                <div class="upload__preview" v-if="unpublishedStory && unpublishedStory.poster">
                                    <span>Poster {{POSTER810X488.width}}X{{POSTER810X488.height}}</span>
                                    <img :src="`${s3Url}/${unpublishedStory.poster_webp}`" alt="" v-if="unpublishedStory.poster_webp">
                                    <img :src="`${s3Url}/${unpublishedStory.poster}`" alt="">
                                </div>
                                <div class="upload__preview" v-if="unpublishedStory && unpublishedStory.poster_gallery">
                                    <span>Poster {{POSTER370X360.width}}X{{POSTER370X360.height}}</span>
                                    <img :src="`${s3Url}/${unpublishedStory.poster_gallery_webp}`" alt="" v-if="unpublishedStory.poster_gallery_webp">
                                    <img :src="`${s3Url}/${unpublishedStory.poster_gallery}`" alt="">
                                </div>
                            </div>
                        </div>
                        <div class="form__row upload upload_modal">
                            <!--@TODO slider with thumbnails-->


                            <template v-if="unpublishedStory.type_src==='image'">
                                <span class="form__label">Upload 1 to 10 images for your story</span>
                                <div class="upload__section">
                                    <template v-if="!imageStory && myStoryMedias && myStoryMedias.length<10">
                                        <label for="storyMediasUpload" class="upload__label">
                                            <button class="button button_sm button_primary"><span><i class="icon icon_choose button__icon"></i>Choose</span></button>
                                            <input id="storyMediasUpload" type='file' @input="uploadStoryImage" accept=".png, .jpg, .jpeg, .svg, .webp"  hidden v-if="unpublishedStory.type_src==='image'" />
                                        </label>
                                    </template>
                                    <template v-else>
                                        <image-cropper
                                            :src="imageStory.src"
                                            :width="POSTER810X488.width"
                                            :height="POSTER810X488.height"
                                            :avatar-size="100"
                                            :is-make-thumb="false"
                                            :disabled-button="disabledButton"
                                            @crop="saveImagePack"
                                        ></image-cropper>
                                    </template>

<!--                                    <div class="upload__preview" style="display: none;">-->
<!--                                        <img :src="`${myUrl}/source/images/member/member1.jpg`" alt="">-->
<!--                                    </div>-->
                                    <div class="slider slider_portfolio">
                                        <div class="slider__container" v-if="$mq==='desktop'">

                                            <hooper
                                                :itemsToShow="3"
                                                :trimWhiteSpace="true"
                                                :itemsToSlide="1"
                                                :transition="500"
                                                :infiniteScroll="false"
                                                :playSpeed="5000"
                                                ref="sliderPortfolio">

                                                <template v-if="myStoryMedias && myStoryMedias.length">
                                                    <slide class="slider__item" v-for="(media, index) in myStoryMedias" :key="media.id">
                                                        <button class="slider__delete button button_filled button_close" @click.capture="deleteMedia(media,index)">
                                                            <i class="icon icon_close"></i>
                                                        </button>
                                                        <picture >
                                                            <source type="image/webp" :srcset="`${s3Url}/${media.src_webp}`" v-if="isSupportedWebp && media.src_webp">
                                                            <img class="slider__media" :src="`${s3Url}/${media.src}`" alt="photo portfolio" @click.capture="indexImage = index"
                                                                 width="270" height="270">
                                                        </picture>
                                                    </slide>
                                                </template>

                                            </hooper>

                                            <button class="slider__button slider__button_prev button" @click.prevent="slidePortfolioPrev"
                                                    v-if="myStoryMedias && myStoryMedias.length > 3">
                                                <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                                     height="12" fill="none">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                          d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                                </svg>
                                            </button>
                                            <button class="slider__button slider__button_next button" @click.prevent="slidePortfolioNext"
                                                    v-if="myStoryMedias && myStoryMedias.length > 3">
                                                <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                                     height="12" fill="none">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                          d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                                </svg>
                                            </button>
                                        </div>
                                        <div class="slider__container" v-else>
                                            <hooper
                                                :transition="500"
                                                :wheelControl="false"
                                                ref="sliderPortfolio">

                                                <template v-if="myStoryMedias && myStoryMedias.length">
                                                    <slide class="slider__item" v-for="(media, index) in myStoryMedias" :key="media.id">
                                                        <button class="slider__delete button button_filled button_close" @click.capture="deleteMedia(media,index)">
                                                            <i class="icon icon_close"></i>
                                                        </button>

                                                        <picture >
                                                            <source type="image/webp" :srcset="`${s3Url}/${media.src_webp}`" v-if="isSupportedWebp && media.src_webp">
                                                            <img class="slider__media" :src="`${s3Url}/${media.src}`" alt="photo portfolio" @click.capture="indexImage = index"
                                                                 width="270" height="270">
                                                        </picture>
                                                    </slide>
                                                </template>
                                                <hooper-pagination slot="hooper-addons"></hooper-pagination>
                                            </hooper>
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <template v-else-if="unpublishedStory.type_src==='video'">
                                <span class="form__label">Upload 1 to 2 videos for your story</span>
                                <div  v-if="imageVideoPoster || (unpublishedStory.poster && unpublishedStory.poster_webp)">
                                    <button  @click="deletePoster" :disabled="disabledButton">Delete Video Poster</button>
                                </div>

                                <template v-if="!(unpublishedStory.poster && unpublishedStory.poster_webp) && !imageVideoPoster">
                                    <div class="upload__section">
                                        <div class="" v-if="videoSrcPack && videoSrcPack.src">-->
                                            <!--                       height: 480px-->
                                            <video  autoplay playsinline loop :muted="true" controls ref="videoEl" style="width: 810px">
                                                <!--                            <source :src="videoSrc" type="video/webm">-->
                                                <source :src="videoSrcPack.src" :type="typeVideoPack">
                                            </video>
                                        </div>
                                        <div class="" v-else>
                                            <label for="storyVideoPosterUpload" class="upload__label">
                                                <button class="button button_sm button_primary">
                                                    <span><i class="icon icon_choose button__icon"></i>Choose</span>
                                                </button>
                                                <input id="storyMediasUpload" type='file' @input="uploadStoryVideo" accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm "  hidden />
                                            </label>
                                        </div>

                                    </div>
<!--                                    <div class="card flex justify-content-center" v-if="videoSrc && !img">-->
<!--                                        &lt;!&ndash;                       height: 480px&ndash;&gt;-->
<!--                                        <video  autoplay playsinline loop :muted="true" controls ref="videoEl" style="width: 810px">-->
<!--                                            &lt;!&ndash;                            <source :src="videoSrc" type="video/webm">&ndash;&gt;-->
<!--                                            <source :src="videoSrc" :type="typeVideo">-->
<!--                                        </video>-->
<!--                                    </div>-->
<!--                                    <div class="card flex justify-content-center" v-else>-->
<!--                                        <FileUpload mode="basic" name="demo[]" url="" accept="video/*" :maxFileSize="100000000" @select="onUploadVideo"/>-->
<!--                                    </div>-->
                                </template>
<!--                                <div class="card flex justify-content-center" v-if="videoSrc && !img">-->
<!--                                    <Button label="Take a freeze frame" class="p-button-warning mr-2 mb-2" @click="createFreezeFrame" :disabled="isDisabled"/>-->
<!--                                </div>-->

                            </template>
                        </div>
                        <div class="form__footer">
                            <button v-if="post" class="button button_md button_primary" @click.prevent="updateStory"><span>Update Story</span></button>
                            <button v-else class="button button_md button_primary" @click.prevent="publicationStory"><span>Publish</span></button>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
// import Cropper from 'cropperjs';
import ImageCropper from "../modules/ImageCropper.vue";

let posterImageFile = null;
let imagePackFile = null;
let videoFile = null;

export default {
    name: "StoryCreator",
    components: {ImageCropper},
    props: [ 'isEdit','post', 'myUrl', 's3Url','memberID','memberRole','isSupportedWebp'],

    data(){
        return{
            indexImage: null,
            videosBox:[],
            indexVideo: null,
            unpublishedStory: null,
            myStoryMedias: [],
            disabledButton: false,
            imagePoster: null,
            modalCropper: false,
            imageStory: null,
            imageVideoPoster: null,
            videoSrcPack: null,
            typeVideoPack: null,
            POSTER810X488:{
                width: 810,
                height: 488,
            },
            POSTER370X360: {
                width: 370,
                height: 360,
            },
            newStory: {
                id: 0,
                subject: '',
                description: '',
                type_src: 'image',
                is_comment: true,
                poster: '',
                poster_webp: '',
            }
        }
    },
    computed:{
        imageBox(){
            if(!this.myStoryMedias || !this.myStoryMedias)return [];
            return this.myStoryMedias.map(media =>(`${this.s3Url}/${media.src}`));
        },
        disabledNextStep(){
            return this.disabledButton || !this.newStory.subject ||
                this.newStory.subject.length < 1
        }
    },
    mounted() {
        if (this.post && this.isEdit) {
            console.log('edit old story')
            this.newStory.subject = this.post.subject
            this.newStory.description = this.post.description
            this.newStory.is_comments = this.post.is_comment
            this.newStory.type_src = this.post.type_src
        }else {
            this.getUnpublishedStory()
        }
    },
    methods:{
        slidePortfolioPrev(){

        },
        slidePortfolioNext(){

        },
        deletePoster(){
            this.disabledButton = true;
            axios.delete(`/delete-my-story-poster/${this.unpublishedStory.id}`)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        this.unpublishedStory.poster = null;
                        this.unpublishedStory.poster_webp = null;
                        this.unpublishedStory.poster_gallery = null;
                        this.unpublishedStory.poster_gallery_webp = null;
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        deleteMedia(media,index){
            this.disabledButton = true;
            axios.delete(`/delete-my-story-media/${media.id}`)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        this.myStoryMedias = this.myStoryMedias.filter(item =>( +media.id !== +item.id))
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        deleteVideoPoster(){

        },
        uploadStoryVideo(event){
            videoFile = event.files[0];
            this.videoSrcPack = URL.createObjectURL(videoFile);
            this.typeVideoPack = event.files[0].type;
            event.target.value = null
        },
        cropImagesStory(){

        },
        async saveImagePack(canvas){
            console.log('saveImagePack');
            if(this.disabledButton || !this.imageStory || !imagePackFile || !this.unpublishedStory) return;
            const hash = await this.getHash(imagePackFile);
            if(!hash) return;

            // let canvas = getCanvasImage(selectedImageEl.value)
            // let canvas = this.getCanvasImage(this.imageStory)
            const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 0.95));
            const blobWebp = await new Promise(resolve => canvas.toBlob(resolve, "image/webp", 0.95));
            const formData = new FormData();
            formData.append('image',blob,'image');
            formData.append('image_webp',blobWebp,'image_webp');
            formData.append('origin_sha1',hash);
            formData.append('myRole',this.memberRole);
            formData.append('id',this.unpublishedStory.id);
            // formData.append('thumb',blobThumb,'thumb');
            // formData.append('thumbWebp',blobThumbWebp,'thumbWebp');
            this.disabledButton = true;
            axios.post(`/save-image-my-story-account`,formData)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        imagePackFile = null;
                        this.imageStory = null;

                    }else if(response.data.status === 'duplicated'){
                        alert('This file is already used in stories')

                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
            // ApiBackend.savePackImageStory(formData).then((data)=>{
            //     console.log({data});
            //     isDisabled.value = false;
            //     if(data.status === 'ok' && data.myStoryMedia){
            //         myStoryMedias.value.unshift({
            //             id: data.myStoryMedia.id,
            //             alt:"Image",
            //             itemImageSrc: `${cdnUrl.value}/${data.myStoryMedia.src}`,
            //             thumbnailImageSrc: `${cdnUrl.value}/${data.myStoryMedia.src}`,
            //             title:"Image Story"
            //         })
            //         imagePackFile = null;
            //         imgPack.value = '';
            //         toast.add({
            //             severity:'success',
            //             summary: 'Upload image',
            //             detail:'Upload image',
            //             closable: true,
            //             life: 5000});
            //         // unpublishedStory.value.my_story_medias.push(data.myStoryMedia)
            //     }else if(data.status === 'duplicated'){
            //         imagePackFile = null;
            //         imgPack.value = '';
            //         toast.add({
            //             severity:'error',
            //             summary: 'Duplicated',
            //             detail:'This file is already used in stories',
            //             closable: true,
            //             life: 10000});
            //
            //     }else {
            //         toast.add({
            //             severity:'error',
            //             summary: 'Error Upload',
            //             detail:`Error Upload Image`,
            //             closable: true,
            //             life: 5000});
            //     }
            //
            // })
        },
        cropImages810X488(canvas){
            this.savePosterImage(canvas,this.POSTER810X488.width,this.POSTER810X488.height,'poster')
        },
        cropImages370X360(canvas){
            this.savePosterImage(canvas,this.POSTER370X360.width,this.POSTER370X360.height,'poster_gallery')
        },
        makeThumb(canvas,width,height){
            console.log({width,height})
            const canvasThumb = document.createElement("canvas"),
                ctxThumb = canvasThumb.getContext("2d");
            canvasThumb.width = width;
            canvasThumb.height = height;

            // ctxThumb.drawImage(canvas, 0, 0,width,height);
            ctxThumb.drawImage(canvas, 0,0,canvas.width,canvas.height,0, 0,width,height);

            return canvasThumb ;
        },
        async savePosterImage(currentCanvas,width,height,field){
            // const currentCanvas = field === 'poster' ? cropCanvas : cropCanvasGallery
            if(this.disabledButton || !currentCanvas || !this.unpublishedStory || !this.imagePoster ) return
            console.log('start saveImage');
            let canvas = this.makeThumb(currentCanvas,width,height)
            const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
            const blobWebp = await new Promise(resolve => canvas.toBlob(resolve, "image/webp", 1));

            const formData = new FormData();
            formData.append(field,blob,field);
            formData.append(`${field}_webp`,blobWebp,`${field}_webp`);
            formData.append('field',field);
            formData.append('id',this.unpublishedStory.id);
            formData.append('type_src',this.unpublishedStory.type_src);
            formData.append('myRole',this.memberRole);
            formData.append('memberID',this.memberID);
            if(!this.unpublishedStory.poster && !this.unpublishedStory.poster_gallery ){

                if(this.unpublishedStory.type_src === 'image'){
                    const hash = await this.getHash(posterImageFile);
                    if(!hash) return;
                    // const canvasOrigin = await this.getCanvasOrigin(cropCanvas);
                    const canvasOrigin = this.getCanvasImage(this.imagePoster);
                    const blobOrigin = await new Promise(resolve => canvasOrigin.toBlob(resolve, "image/jpeg", 1));
                    const blobOriginWebp = await new Promise(resolve => canvasOrigin.toBlob(resolve, "image/webp", 1));
                    formData.append('origin',blobOrigin,'origin');
                    formData.append(`origin_webp`,blobOriginWebp,`origin_webp`);
                    formData.append(`origin_sha1`,hash);
                }else if(this.unpublishedStory.value.type_src === 'video'){
                    const hash = await this.getHash(videoFile);
                    if(!hash) return;
                    formData.append(`origin_sha1`,hash);
                    formData.append('origin',videoFile,'origin');
                }
            }
            this.disabledButton = true;
            // formData.append('thumb',blobThumb,'thumb');
            // formData.append('thumbWebp',blobThumbWebp,'thumbWebp');
            axios.post(`/save-poster-my-story-account`,formData)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        this.unpublishedStory[field] = response.data.image;
                        this.unpublishedStory[`${field}_webp`] = response.data.image_webp;
                        if(response.data.myStoryMedia){
                            console.log('push to myStoryMedias');
                            this.myStoryMedias.unshift(response.data.myStoryMedia);
                        }
                    }else if(response.data.status === 'error'){

                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        // getCanvasOrigin(){
        //     return new Promise(resolve => {
        //         const imageOrigin = new Image()
        //
        //         imageOrigin.onload = () => {
        //             const canvas = this.getCanvasImage(imageOrigin)
        //             return resolve(canvas);
        //         };
        //         imageOrigin.src = img.value
        //     })
        // },
        getCanvasImage(imageEl){
            const w = imageEl.width;
            const h = imageEl.height;
            const canvas = document.createElement('canvas');
            canvas.width  = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(imageEl, 0, 0, w, h);
            return canvas;
        },
        async getHash(file) {
            try {
                return  await this.getSha1Image(file);
            }catch (e) {
                console.error('error getSha1Image');
                console.log(e)
                alert('Your browser does not support all required features. Please update your browser or use a different one');
                return ''
            }
        },
        getSha1Image(file){
            if(process.env.MIX_APP_ENV==='local'){
               return this.getTestHash();
            }else {
                return new Promise((resolve,reject) => {
                    try {
                        const fileReader = new FileReader();
                        fileReader.onloadend = async (e)=> {
                            const hashBuffer = await window.crypto.subtle.digest('SHA-1', e.target.result)
                            const hashArray = Array.from(new Uint8Array(hashBuffer));// convert buffer to byte array
                            const hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');// convert bytes to hex string
                            console.log(hash);
                            return resolve(hash)
                        }
                        fileReader.readAsArrayBuffer(file);
                    }catch (e) {
                        return reject(e)
                    }
                })
            }

        },
        getTestHash() {
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < 32; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        },
        selectPoster(event){
            console.log('selectPoster');
            // console.log(event);
            posterImageFile = event.target.files[0];
            this.imagePoster = new Image()
            this.imagePoster.onload = () => {
                setTimeout(()=> {
                    this.modalCropper = true;
                },100)
            };
            this.imagePoster.src = URL.createObjectURL(posterImageFile)
            event.target.value = null
        },
        uploadStoryImage(event){
            console.log('selectPoster');
            // console.log(event);
            imagePackFile = event.target.files[0];
            this.imageStory = new Image()
            this.imageStory.onload = () => {
                setTimeout(()=> {
                    this.modalCropper = true;
                },100)
            };
            this.imageStory.src = URL.createObjectURL(imagePackFile)
            event.target.value = null
        },
        createNewMyStory(){
            console.log('createNewMyStory');
            const updateData = {
                myRole: this.memberRole,
                memberID: this.memberID,
                subject: this.newStory.subject,
                type_src: this.newStory.type_src,
                description: this.newStory.description,
                is_comment: this.newStory.is_comment,
                url: this.createUrl(this.newStory.subject),
            }
            axios.post(`/create-new-my-story-account`,updateData)
                .then(response => {
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        this.initStory(response.data.newStory);
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        createUrl(text){
            const strArr = text.toLowerCase().split('') ;
            const isLetter = (code)=>{
                return (code > 47 && code < 58) || (code > 96 && code < 123)
            };
            let url = '/';
            let isChar = false;
            strArr.forEach(char=>{
                const code = char.charCodeAt(0);
                if(isLetter(code)){
                    url = url + char;
                    isChar = true
                }else {
                    if (isChar){
                        url = url + '-';
                    }
                    isChar = false
                }
            })
            if(!isChar){
                url = url.slice(0,-1)
            }
            if(url===''){
                url = '/story';
            }
            console.log(url);
            return url
        },
        getUnpublishedStory(){
            axios.get(`/get-unpublished-story-account/${this.memberID}`)
                .then(response => {
                    console.log(response.data)
                    if (response.data.status === 'ok' && response.data.unpublishedStory) {
                        this.initStory(response.data.unpublishedStory)
                        // this.unpublishedStory = response.data.unpublishedStory;
                        // this.newStory = {
                        //     id: response.data.unpublishedStory.id,
                        //     subject: response.data.unpublishedStory.subject,
                        //     description: response.data.unpublishedStory.description,
                        //     type_src: response.data.unpublishedStory.type_src,
                        //     is_comment: response.data.unpublishedStory.is_comment,
                        //     poster: response.data.unpublishedStory.poster,
                        //     poster_webp: response.data.unpublishedStory.poster_webp,
                        // }
                        // const medias = myStoryMedias;
                        this.myStoryMedias = response.data.unpublishedStory.medias;

                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        initStory(myStory){
            this.unpublishedStory = myStory;
            this.newStory = {
                id: myStory.id,
                subject: myStory.subject,
                description: myStory.description,
                type_src: myStory.type_src,
                is_comment: myStory.is_comment,
                poster: myStory.poster,
                poster_webp: myStory.poster_webp,
            }
        },
        updateStory(){
            console.log('updateStory')
            // TODO realisation
        },
        closeStoryCreator(){
            this.newStory.subject = ''
            this.newStory.description = ''
            this.newStory.is_comments = true
            this.newStory.type_src = 'image'
            this.$emit('close_creator')
        },
        publicationStory(){
            console.log('publicationStory')
            // TODO realisation
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    },

}
</script>

<style scoped>

</style>
