<template>
    <div class="page page_inner page_gallery" :class="{'page_mobile':($mq!=='desktop')}" @click="clickPage">

        <div class="container">
            <div class="page__header" :class="{'page__header_search': isSearch}" v-if="$mq==='desktop'">
                <base-logo></base-logo>
                <button class="button button_xs button_bordered button_rounded" @click.prevent="openMembers">
                     <span>
                        <span class="button__text">Members</span>
                        <i class="icon icon_user"></i>
                    </span>
                </button>
                <filter-desktop v-if="isSearch && $mq==='desktop'"
                                :my-url="myUrl"
                                :search-data="searchData"
                                :countries="countries"
                                @reset_search="resetSearch"
                ></filter-desktop>
            </div>

<!--            style="display: none;"-->
            <div class="gallery__stop" id="gallery_top"> Gallery Start </div>
            <filter-desktop v-if="isSearch && $mq!=='desktop'"
                            :my-url="myUrl"
                            :search-data="searchData"
                            :countries="countries"
                            @reset_search="resetSearch"

            ></filter-desktop>
            <div class="gallery" ref="gallery" v-if="loaded">
                <gallery-item v-if="$mq === 'desktop'"
                              v-for="(member, index) of gallerySlice" :key="member.id"
                              :my-url="myUrl"
                              :member="member"
                              :is-blocked="(member.lock || banList.includes(member.id))"
                              :s3-url="s3Url"
                              :role-opponent="roleOpponent"
                              :is-favorite="favorites.includes(member.id)"
                              :indicator-new-opponent="indicatorNewOpponent"
                              :is-supported-webp="isSupportedWebp"
                ></gallery-item>

                <template v-if="$mq === 'mobile'">
                    <div class="slider slider_gallery">
                        <hooper ref="sliderHooper"
                            :vertical=true
                            :itemsToSlide=1
                            :initialSlide=this.indexSlide
                            :transition=300
                            @slide="hooperSlide"
                            @updated="hooperUpdated"
                        >
                            <template>
                                <slide class="slider__item" v-for="(member, index) of gallerySlide" :key="member.id">
                                    <gallery-item-mobile
                                        :member="member"
                                        :s3-url="s3Url"
                                        :role-opponent="roleOpponent"
                                        :is-favorite="favorites.includes(member.id)"
                                        :my-url="myUrl"
                                        :is-blocked="(member.lock || banList.includes(member.id))"
                                        :is-supported-webp="isSupportedWebp"
                                    >
                                    </gallery-item-mobile>
                                </slide>
                            </template>
                        </hooper>
                    </div>
                </template>

                <template v-if="$mq === 'tablet' || $mq === 'laptop'">
                    <gallery-item-mobile
                        v-for="(member, index) of gallerySlice" :key="member.id"
                        :member="member"
                        :s3-url="s3Url"
                        :role-opponent="roleOpponent"
                        :is-favorite="favorites.includes(member.id)"
                        :my-url="myUrl"
                        :is-blocked="(member.lock || banList.includes(member.id))"
                        :is-supported-webp="isSupportedWebp"
                    >
                    </gallery-item-mobile>
                </template>
            </div>
            <div class="gallery__stop" id="gallery_end" >The end</div>
        </div>
    </div>
</template>

<script>

import GalleryItem from "./newModules/GalleryItem.vue";
import BaseLogo from "./newModules/BaseLogo.vue";
import FilterDesktop from "./modules/FilterDesktop.vue";
import GalleryItemMobile from "./newModules/GalleryItemMobile.vue";

let observer = null;
const THRESHOLD = 60;
let itemTop = null;
let itemEnd = null;
let isSleepObserver = false;
// let lastMemberID = 0;
let isShowAlways = false;

export default {
    name: "Gallery",
    components: {GalleryItemMobile, FilterDesktop, BaseLogo, GalleryItem },
    props: [
        'currentUser',
        'galleryInit',
        'countries',
        'myUrl',
        's3Url',
        'opponent',
        'isFavorite',
        'messages',
        'chatOwner',
        'emojis',
        'moods',
        'banList',
        'favorites',
        'loaded',
        'isSearch',
        'searchData',
        'roleOpponent',
        'myBanned',
        'scrollGalleryPosition',
        'indicatorNewOpponent',
        'isAllGallery',
        'isGetMoreGallery',
        'holidayPromotionName',
        'galleryIndexFrom',
        'galleryIndexTo',
        'galleryCropLimit',
        'isScroll',
        'isSupportedWebp',
        'indexSlide',
        'offsetSlide',
        'limitSlide',
        'updatingSlide',
    ],

    data(){
        return {
            // observerImages: null,
            isMoreGalleryCompleted: false,
            // lastGalleryItemID: 0,
            oldClientHeight: 0,
            oldScrollTop: 0,
            // indexStart: 0,
            // indexEnd: 60
            // indicatorNewOpponent:10*20*60*60,
            from_index: 0,
            to_index: 20,

        //     for hooper slider
            whenUpdatedSliceToTheEnd : 3,
            cropLimit : 10
        //     end

        }
    },
    computed:{
        galleryValid(){
            return this.galleryInit.filter(opponent=>{
                if (this.roleOpponent === 'man'){
                    return !this.myBanned.includes(opponent.id) && !opponent.lock ;
                }
                return !this.myBanned.includes(opponent.id) && !opponent.lock && opponent.valid;
            })
        },
        gallerySlice(){
            return this.galleryValid.slice(this.from_index, this.to_index);
        },
        gallerySlide(){
            return this.galleryValid.slice(this.offsetSlide, this.limitSlide);
        },
        showLoadMoreGallery(){
          return this.isGetMoreGallery && !this.isAllGallery;
        }
    },
    watch:{
        gallerySlide(newValue, oldValue){
            console.log('watcher')
            if(this.updatingSlide){
                this.moveSlider();
            }
        }
    },
    created() {

    },
    mounted() {
        console.log('mounted gallery');
        this.$bus.$emit('close_modal');
        this.from_index = this.galleryIndexFrom;
        this.to_index = this.galleryIndexTo;
        isSleepObserver = true;
        this.$nextTick(()=>{
            window.scrollTo( 0, this.scrollGalleryPosition )

            setTimeout(()=> {
                isSleepObserver = false;
            }, 1000)
        })

        //       Change styles for footer in mobile mode
        if(this.$mq==='mobile'){
            setTimeout(()=>{
                // this.setFooterObserver();
                // this.isInit = true;
                this.$bus.$emit('add_no_scroll');
                let footer = document.getElementById('footer')
                footer.hidden = true
                let page = document.getElementsByClassName('page_mobile')
                for(let i=0;i<page.length;i++){
                      page[i].style.paddingBottom="0px";
                }
            },0);
        }else{
            this.initScrollObserver();
        }
        this.$bus.$on('more_gallery_completed',this.moreGalleryCompleted);
        this.$bus.$on('get_indexes_gallery',this.getIndexesGallery);
        this.$bus.$on('set_indexes_gallery',this.setIndexesGallery);
    },
    beforeDestroy() {
        if (this.$mq !== 'desktop') {
          this.$bus.$emit('remove_no_scroll');
        }
        this.$bus.$off('more_gallery_completed',this.moreGalleryCompleted);
        this.$bus.$off('get_indexes_gallery',this.getIndexesGallery);
        this.$bus.$off('set_indexes_gallery',this.setIndexesGallery);
        this.$bus.$emit('close_modal');

        if (observer) {
          if(itemEnd) observer.unobserve(itemEnd);
          if(itemTop) observer.unobserve(itemTop)
          observer = null
          itemEnd = null
          itemTop = null
        }
    },
    methods: {
        clickPage(){
            // if(this.$mq === 'desktop') this.$bus.$emit('close_side_bar');
            if(this.$mq === 'desktop') this.$bus.$emit('close_nav_menu');
        },
        resetSearch(){
            this.$emit('reset_search')
        },
        initScrollObserver(){
            console.log('initScrollObserver');
            itemTop = document.getElementById('gallery_top');
            itemEnd = document.getElementById(`gallery_end`);
            if(!itemTop || !itemEnd) console.error('!itemTop || !itemEnd')
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            };


            observer = new IntersectionObserver(this.callbackObserver, options);
            observer.observe(itemTop);
            observer.observe(itemEnd);

        },
        callbackObserver(entries, observer){
            console.log('callbackObserver');

            entries.forEach((entry, index) => {

                // Scroll up
                if (!isSleepObserver && entry.isIntersecting && entry.target.id === "gallery_top" && this.from_index ) {
                    console.log('Scroll up');
                    const oldClientHeight = this.$refs.gallery.clientHeight;
                    // const oldScroll = document.documentElement.scrollTop;
                    const old_from_index = this.from_index
                    this.from_index = (this.from_index - this.galleryCropLimit) > 0 ? (this.from_index - this.galleryCropLimit) : 0

                    setTimeout(()=>{
                        if(!this.$refs.gallery) return;
                        const oldClientHeight2 = this.$refs.gallery.clientHeight;
                        // document.documentElement.scrollTop = Math.abs(this.$refs.gallery.clientHeight - oldClientHeight + oldScroll);
                        document.documentElement.scrollTop = document.documentElement.scrollTop + (oldClientHeight2 - oldClientHeight);
                        const diff = old_from_index - this.from_index; //(old_from_index - this.from_index)

                        this.to_index = (this.to_index - diff) <= this.galleryValid.length ? (this.to_index - diff) : this.galleryValid.length

                    },0)

                }else if (entry.isIntersecting && entry.target.id === `gallery_end` ) { // Scroll Down
                    console.log('Scroll Down');
                    // lastMemberID = this.gallerySlice[this.gallerySlice.length-1].id
                    const diff_limit = (this.to_index + this.galleryCropLimit) <= this.galleryValid.length ?
                        this.galleryCropLimit :
                        this.galleryValid.length - this.to_index;
                    this.to_index += diff_limit

                    if ((this.galleryValid.length - this.to_index) < this.galleryCropLimit*1.5 && !this.isGetMoreGallery && !this.isAllGallery && this.$refs.gallery){
                        this.addImagesToGallery();
                    }
                        //const oldGalleryHeight1 = this.$refs.gallery.clientHeight;
                        // const oldScroll1 = document.documentElement.scrollTop;
                        setTimeout(()=>{
                            const oldGalleryHeight1 = this.$refs.gallery.clientHeight;
                            // document.documentElement.scrollTop = document.documentElement.scrollTop - (oldGalleryHeight2 - oldGalleryHeight1);
                            this.from_index += diff_limit;
                            const oldScroll = document.documentElement.scrollTop;
                            this.$nextTick(()=>{
                                const oldGalleryHeight2 = this.$refs.gallery.clientHeight;
                                console.log({scrollTop: document.documentElement.scrollTop})
                                if(document.documentElement.scrollTop === oldScroll && oldGalleryHeight1 === oldGalleryHeight2 && !this.isAllGallery){
                                    document.documentElement.scrollTop = document.documentElement.scrollTop - 700
                                }else {
                                    document.documentElement.scrollTop = oldScroll - (oldGalleryHeight1 - oldGalleryHeight2);
                                }
                                console.log({oldScroll})
                                console.log({oldGalleryHeight1})
                                console.log({oldGalleryHeight2})
                            })
                        },0);
                }else {
                    console.log('else');
                    // console.log(entry.target)
                }

            });

        },
        //old version
        isNewOpponent(member){
            const now = new Date();
            const arr = member.created_at.split('T')
            const memberCreate = new Date(arr[0]);

            return now.getTime() - memberCreate.getTime() < this.indicatorNewOpponent*1000 ;
        },
        // onScroll() {
        //     const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //     if (scrollTop === 0 && this.from_index) {
        //         console.log('gallery UP')
        //         const old_from_index = this.from_index
        //         this.from_index = (this.from_index - this.galleryCropLimit) > 0 ? (this.from_index - this.galleryCropLimit) : 0
        //
        //         const oldClientHeight = this.$refs.gallery.clientHeight
        //
        //         this.$nextTick(()=>{
        //             if(!this.$refs.gallery) return;
        //             document.documentElement.scrollTop = Math.abs(this.$refs.gallery.clientHeight - oldClientHeight);
        //             this.to_index -= (old_from_index - this.from_index)
        //         })
        //
        //     }else  if (scrollTop >= this.$refs.gallery.clientHeight * 0.8) {
        //             console.log(scrollTop, this.$refs.gallery.clientHeight);
        //             const diff_limit = (this.to_index + this.galleryCropLimit) <= this.galleryValid.length ?
        //                 this.galleryCropLimit :
        //                 this.galleryValid.length - this.to_index;
        //             this.to_index += diff_limit
        //
        //             console.log('onScroll down');
        //             if ((this.galleryValid.length - this.to_index) < this.galleryCropLimit && !this.isGetMoreGallery && !this.isAllGallery && this.$refs.gallery){
        //                 this.addImagesToGallery(scrollTop);
        //             }
        //             this.$nextTick(()=>{
        //                 this.from_index += diff_limit
        //             })
        //
        //     }
        // },
        getAge(age,birthday){
            if(birthday){
                // let diff = new Date()-new Date(birthday); // This is the difference in milliseconds
                return  Math.floor((new Date()-new Date(birthday))/31557600000); // Divide by 1000*60*60*24*365.25
            }
            return age;
        },
        shortName(name){
            let arr = name.split(' ');
            let rez = '';
            arr.forEach(item=>{
                rez += item.charAt(0).toUpperCase();
            })
            return rez;
        },
        // selectOpponent(member){
        //     if (!member.lock && (this.roleOpponent === 'man' || member.valid)){
        //         this.$bus.$emit('select_opponent',member);
        //     }
        // },
        loadMore(){
            console.log('loadMore');
            this.$emit('more_gallery_start')
        },
        addImagesToGallery(){
            if(this.isGetMoreGallery && this.isAllGallery )return;
            console.log('addImagesToGallery');
            this.oldClientHeight = this.$refs.gallery.clientHeight;
            this.oldScrollTop = document.documentElement.scrollTop;
            // if(this.gallery.length) this.lastGalleryItemID = this.gallery[this.gallery.length-1].id;
            this.$emit('more_gallery_start')
        },
        moreGalleryCompleted(){
            console.log('moreGalleryCompleted');
            this.isMoreGalleryCompleted = true;
            // test1
            // if(isShowAlways){
            //     this.to_index = this.galleryValid.length
            // }

            // const oldScroll = document.documentElement.scrollTop;
            // this.$nextTick(()=>{
            //     window.scrollTo( 0, oldScroll );
            //     // document.documentElement.scrollTop = oldScroll ;
            // })
        },
        getIndexesGallery(){
            const position = window.pageYOffset || document.documentElement.scrollTop;
            console.log('getIndexesGallery window.pageYOffset',position);
            this.$emit('scroll_gallery_position', {
                position: position,
                indexFrom: this.from_index,
                indexTo: this.to_index,
            });
        },
        setIndexesGallery(data){
            console.log('setIndexesGallery');
            console.log(data);
            this.from_index = data.from;
            this.to_index = data.to;
            document.documentElement.scrollTop = data.position;
            // this.$nextTick(()=>{
            //     document.documentElement.scrollTop = data.position;
            // });
            if(this.$mq==='mobile') {
                this.$nextTick(()=>{
                    this.moveSlider()
                })
            }
        },
        hooperSlide(data){
            console.log(`to -> ${data.currentSlide} <- from -> ${data.slideFrom}`)
            if(this.updatingSlide) {
                return
            }
            //refresh void
            if(data.currentSlide === 0 && data.slideFrom === 0 && this.offsetSlide === 0){
                this.refreshSlide()
            }
            else{
                setTimeout(()=>{
                    this.updateSliderRange(data.currentSlide)
                },310)

            }
        },
        hooperUpdated(data){
            console.log('hooper updated:')
            this.printDataFromObject(data)
        },
        refreshSlide(){
            console.log(`should refresh slide`)
            //TODO ask Alex
            //this.$bus.$emit('refresh_member_data')
            if(!this.isSearch){
                window.location.reload();
            }

        },
        updateSliderRange(currentSlide){
            console.log(`update-slider-range`)
            if(this.isNeedMoveSlideUp(currentSlide)){
                let offset = this.offsetSlide > 0 ? Math.min(this.cropLimit, this.offsetSlide) : 0
                if(offset){
                    console.log(`up-slider-range`)
                    this.$emit('change-state-gallery-slider', true)
                    this.$emit('update-offset-gallery-slide', this.offsetSlide - offset)
                    this.$emit('update-limit-gallery-slide', this.limitSlide - offset)
                    this.$emit('update-index-gallery-slide', currentSlide + offset)
                }
                else {
                    this.$emit('update-index-gallery-slide', currentSlide)
                }
            }
            else if(this.isNeedMoveSlideDown(currentSlide)){
                let offset = Math.min(this.galleryValid.length - this.limitSlide, this.cropLimit)
                if(this.isNeedMoreItemsInGallery())this.loadMore()
                if(offset){
                    console.log(`down-slider-range`)
                    this.$emit('change-state-gallery-slider', true)
                    this.$emit('update-offset-gallery-slide', this.offsetSlide + offset)
                    this.$emit('update-limit-gallery-slide', this.limitSlide + offset)
                    this.$emit('update-index-gallery-slide', currentSlide - offset)
                }
                else {
                    this.$emit('update-index-gallery-slide', currentSlide)
                }

            }else {
                this.$emit('update-index-gallery-slide', currentSlide)
            }
        },
        printDataFromObject(obj){
            Object.keys(obj).map(key => {
                if (typeof obj[key] === 'object' && obj[key] !== null){
                    this.printDataFromObject(obj[key]);
                }else {
                    console.log(key + " --- " + obj[key])
                }
            })
        },
        moveSlider() {
            this.$refs.sliderHooper.config.transition = 0
            this.$refs.sliderHooper.isSliding = false;
            this.$refs.sliderHooper.slideTo(this.indexSlide)
            //this.$refs.sliderHooper.isSliding = true;
            this.$refs.sliderHooper.config.transition = 300;
            this.$emit('change-state-gallery-slider', false)
        },
        isNeedMoveSlideUp(currentSlide) {
            return currentSlide < this.whenUpdatedSliceToTheEnd && this.offsetSlide !== 0;
        },
        isNeedMoveSlideDown(currentSlide) {
            if(this.galleryValid.length < this.limitSlide)return false;
            return currentSlide > this.gallerySlide.length - this.whenUpdatedSliceToTheEnd;
        },
        isNeedMoreItemsInGallery(){
            return this.galleryValid.length - this.limitSlide < this.cropLimit*1.5;
        },
        openMembers(){
            window.location.href = '/members';
        }
    },
    beforeRouteLeave(to, from, next){
        console.log('beforeRouteLeave gallery');
       this.getIndexesGallery();
       next() ;
        // this.scrollGalleryPosition = window.pageYOffset
    },




}
</script>

<style scoped>

</style>
