<template>
    <div :class="classDiv">
        <h2 class="heading" v-if="$mq === 'desktop'">{{title}}</h2>
        <div class="buttons">
            <button class="btn btn_history" @click.prevent="showPeriod">History</button>
            <ul class="menu menu_history" v-if="isShowPeriod">
                <li v-for="item in blogsPeriod" :key="item.id">
                    <a :href="myUrl+item.url" class="link">{{item.name}}</a>
                </li>
            </ul>
            <button class="btn btn_subscribe"
                    data-toggle="modal" @click.prevent="actionSubscribe" v-if="currentUser">{{subscribeText}}
                <!--            data-target="#loginModal"-->
                <i class="icomoon icon-bell" v-if="$mq === 'desktop'"></i>
            </button>
            <button class="btn btn_subscribe"
                    data-toggle="modal"
                    data-target="#loginModal"
                    v-else>Subscribe
                <!--            data-target="#loginModal"-->
                <i class="icomoon icon-bell" v-if="$mq === 'desktop'"></i>
            </button>
        </div>

    </div>
</template>

<script>
export default {
name: "SubscribeBlog",
    props:['type','title'],
    data(){
        return{
            myUrl:'',
            currentUser:null,
            userSetting:null,
            blogsPeriod:[],
            isShowPeriod:false,
        }
    },
    computed:{
        classDiv(){
            return this.$mq === 'desktop'?'card__header':'actions'
        },
        subscribeText(){
            return this.userSetting && this.userSetting.subscribe_blog ? 'Unsubscribe':'Subscribe';
        }
    },
    mounted() {
        this.$bus.$on('login_user', this.getUserSetting);
        setTimeout(()=>{
            this.getUser();
        },0);
    },
    beforeDestroy() {
        this.$bus.$off('login_user', this.getUserSetting);
    },
    methods:{
        showPeriod(){
            this.isShowPeriod = !this.isShowPeriod
        },
        // show and close modal
        // $("#loginModal ").modal("show");
        // $("#loginModal ").modal("hide");
        // $("#registerModal ")
        getUser(){
            console.log('/get-user-for-blog',this.title);
            axios.get(`/get-user-for-blog/${this.title.toLowerCase()}`)
                .then(response => {
                    console.log(response.data);
                    this.currentUser = response.data.currentUser;
                    this.userSetting = response.data.userSetting;
                    this.blogsPeriod = response.data.blogsPeriod;
                    this.myUrl= response.data.myUrl;
                })
        },
        getUserSetting(user){
            this.currentUser = user;
            axios.get(`/get-user-setting-for-blog/${this.currentUser.id}`)
                .then(response => {
                    console.log(response.data);
                    this.userSetting = response.data.userSetting;
                })
        },
        actionSubscribe(){
            console.log('actionSubscribe');
            if(this.userSetting){
                const status = this.userSetting.subscribe_blog ? '0' : '1'
                axios.get(`/action-subscribe-blog/${this.userSetting.id}/${status}`)
                    .then(response => {
                        console.log(response.data);
                        this.userSetting.subscribe_blog = !this.userSetting.subscribe_blog ;
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>
