<script>
export default {
    name: "LikeStory",
    props:['storyId'],
    data(){
        return{
            isLike: false,
        }
    },
    methods:{
        likeStory(){
            console.log('likeStory')
            axios.post(`/like-member-story`,{storyID: this.storyId})
                .then(response => {
                    console.log(response.data);
                    this.isLike = true;
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        }
    }
}
</script>

<template>
    <div class="card__buttons">
        <button class="button button_social" @click.prevent="likeStory" :disabled="this.isLike">
            <i class="icon icon_like"></i>
            <span>Like</span>
        </button>
    </div>
</template>

<style scoped>

</style>
