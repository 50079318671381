<template>
    <div class="container">
        <template v-if="$mq==='desktop'">
            <div class="logo">
                <a :href="myUrl" class="logo__base" v-if="!holidayPromotionName">
                    <img :src="`${myUrl}/assets/images/svgs/logo-mini.svg`" alt="logo">
                </a>
                <a :href="myUrl" class="logo__feb" v-else-if="holidayPromotionName === 'valentine'">
                    <img :src="`${myUrl}/assets/images/holidays/feb/logo-mini.svg`" alt="logo">
                </a>
                <span class="logo__action" v-else-if="holidayPromotionName === 'ukraine'">
                    <img :src="`${myUrl}/assets/images/holidays/action/logo-mini.svg`" alt="logo">
                </span>
                <span class="logo__july" v-else-if="holidayPromotionName === 'kiss'">
                    <img :src="`${myUrl}/assets/images/holidays/6july/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/6july/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__photo" v-else-if="holidayPromotionName === 'photo'">
                    <img :src="`${myUrl}/assets/images/holidays/photo/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/photo/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__halloween" v-else-if="holidayPromotionName === 'halloween'">
                    <img :src="`${myUrl}/assets/images/holidays/halloween/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/halloween/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__thanks" v-else-if="holidayPromotionName === 'thanks'">
                    <img :src="`${myUrl}/assets/images/holidays/thanks/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/thanks/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__friday" v-else-if="holidayPromotionName === 'friday'">
                    <img :src="`${myUrl}/assets/images/svgs/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/svgs/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__christmas" v-else-if="holidayPromotionName === 'christmas'">
                    <img :src="`${myUrl}/assets/images/holidays/christmas/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/christmas/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__newyear" v-else-if="holidayPromotionName === 'newyear'">
                    <img :src="`${myUrl}/assets/images/holidays/newyear/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/newyear/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__hugging" v-else-if="holidayPromotionName === 'hugging'">
                    <img :src="`${myUrl}/assets/images/svgs/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/svgs/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__patrik" v-else-if="holidayPromotionName === 'patrik'">
                    <img :src="`${myUrl}/assets/images/holidays/patrik/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/patrik/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__rock" v-else-if="holidayPromotionName === 'rock'">
                    <img :src="`${myUrl}/assets/images/holidays/rock/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/rock/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
                <span class="logo__blond" v-else-if="holidayPromotionName === 'blond'">
                    <img :src="`${myUrl}/assets/images/holidays/blond/logo-mini.svg`" class="img-light" alt="logo">
                    <img :src="`${myUrl}/assets/images/holidays/blond/logo-white-bg.svg`" class="img-dark" alt="logo">
                </span>
            </div>

            <ul class="menu">
                <li><a class="link" :href="myUrl">Home</a></li>
                <li><a class="link" :href="`${myUrl}/about`">About</a></li>
                <li><a class="link" :href="`${myUrl}/faq`">FAQ</a></li>
                <li><a class="link" :href="`${myUrl}/blogs`">Blog</a></li>
                <li><a class="link" :href="`${myUrl}/members`">Members</a></li>
                <li><a class="link" :href="`${myUrl}/contact-us`">Contact Us</a></li>
            </ul>
        </template>

        <template v-else>
            <button class="btn btn_icon btn_settings" @click.prevent="openMenuModal" v-if="!isOpenMenuModal"><i class="icomoon icon-menu"></i></button>
            <div class="navbar__container" :class="{'is-open' : isOpenMenuModal}" v-if="isOpenMenuModal">
                <button class="btn btn_icon btn_close" @click.prevent="closeMenuModal"><i class="fas fa-times"></i></button>
                <ul class="nav">
                    <li><a class="link" :href="myUrl">Home</a></li>
                    <li><a class="link" :href="`${myUrl}/about`">About</a></li>
                    <li><a class="link" :href="`${myUrl}/faq`">FAQ</a></li>
                    <li><a class="link" :href="`${myUrl}/blogs`">Blog</a></li>
                    <li><a class="link" :href="`${myUrl}/members`">Members</a></li>
                    <li><a class="link" :href="`${myUrl}/contact-us`">Contact Us</a></li>
                </ul>
            </div>
        </template>

    </div>
</template>

<script>
export default {
name: "MenuStaticPage",
    props:['myUrl','holidayPromotionName'],
    data(){
        return{
            isOpenMenuModal:false,
        }

    },
    methods:{
        openMenuModal(){
            this.isOpenMenuModal = !this.isOpenMenuModal;
        },
        closeMenuModal() {
            this.isOpenMenuModal = false
        }
    }
}
</script>

<style scoped>

</style>
