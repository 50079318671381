<template>
    <div>
        <p v-if="diff > 0 && hours > 0">We anticipate completion in approximately
            <strong class="text-dark">{{hours}}:{{minutes}}</strong>
        </p>
        <p v-else-if="diff > 0">We anticipate completion in approximately
            <strong class="text-dark"> {{minutes}} minutes</strong>
        </p>
        <p v-else>Try again after a while</p>
    </div>
</template>
<script>
let intervalNow = null;
export default {
    name: "CountdownMaintenance",
    props: {
        deadline: {
            type: String,
        },
    },
    data() {
        return {
            now: Math.trunc(new Date().getTime() / 1000),
            date: null,
            diff: 0,
            show: false,
        }
    },
    computed: {
        seconds() {
            return Math.trunc(this.diff) % 60;
        },
        minutes() {
            return Math.trunc(this.diff / 60) % 60;
        },
        hours() {
            return Math.trunc(this.diff / 60 / 60) % 24;
        },
        days() {
            return Math.trunc(this.diff / 60 / 60 / 24);
        },
    },
    watch: {
        now(value) {
            this.getDiff();
        },
    },
    created() {
        if (!this.deadline) {
            throw new Error("Missing props 'deadline'");
        }
        // const endTime = this.deadline;
        // this.date = Math.trunc(Date.parse(endTime.replace(/-/g, '/')) / 1000);
        this.date = Math.trunc(Date.parse(this.deadline) / 1000);
        // this.now = Math.trunc(new Date().getTime() / 1000);
        if (!this.date) {
            throw new Error("Invalid props value, correct the 'deadline'");
        }
        this.getDiff();
        intervalNow = setInterval(() => {
            this.now = Math.trunc(new Date().getTime() / 1000);
        }, 1000);
    },
    mounted() {
        if (this.diff !== 0) {
            this.show = true;
        }
    },
    beforeDestroy() {
        if(intervalNow) clearInterval(intervalNow)
    },
    methods:{
        getDiff(){
            this.diff = this.date - this.now;
            if (this.diff <= 0 || this.stop) {
                this.diff = 0;
                // this.$emit('stop_countdown');
                // this.$bus.$emit('stop_switch_promo');
                clearInterval(intervalNow);
            }
        },
        twoDigits(value) {
            if (value.toString().length <= 1) {
                return '0' + value.toString();
            }

            return value.toString();
        },
    }
}
</script>

<style scoped>

</style>

