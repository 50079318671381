<template>
    <div class="profile__card profile__card_promo">
        <h3 class="profile__title title">A few words about Yourself</h3>
        <div class="profile__text-preview">
            <template v-if="member && member[currentUser.role+'_profile'] && member[currentUser.role+'_profile'].slogan">
                <p >{{member[currentUser.role+'_profile'].slogan}}</p>
            </template>
            <template v-else>
                <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                    <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#information`" />
                </svg>
                <span>You have not provided this information about yourself</span>
            </template>
        </div>

        <button class="button button_sm button_rounded button_border" @click.prevent="$emit('open-modal-world')">
            <span>Edit <i class="button__icon icon icon_edit"></i></span></button>
    </div>
</template>
<script setup>
export default {
    name:"FewWordsAbout",
    props:['myUrl', 'member', 'currentUser'],
}
</script>
<style scoped>

</style>
