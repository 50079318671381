<template>
    <hooper :playSpeed="5000" :wheelControl="false" :transition="500" ref="sliderPromo">
        <template v-if="presentation_medias && presentation_medias.length > 0">
            <slide class="slider__item" :class="{'profile__upload upload':isEdit}" v-for="(media, index) in presentation_medias" :key="media.id" >
                <button class="slider__delete button button_filled button_close" @click.prevent="deletePromoMedia(media,index)" v-if="isEdit">
                    <i class="icon icon_close"></i>
                </button>
                <label for="promoUpload" class="upload__label upload__label_abs upload__label_image" v-if="isEdit && presentation_medias && presentation_medias.length < 4">
                    <input @change="setPromoMedia" type='file' accept=".webp, .png, .jpg, .jpeg, .svg, .avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm " hidden />
                </label>
                <template v-if="media.type==='img'">
                    <picture @click.capture="setIndexPromo(index)" v-if="$mq==='desktop' || !media.src_mobile">
                        <source type="image/webp" :srcset="`${s3Url}/${media.src_webp}`" v-if="media.src_webp && isSupportedWebp">
                        <img class="slider__media" :src="`${s3Url}/${media.src}`" alt="person photo"
                             width="870" height="500">
                    </picture>
                    <picture @click.capture="setIndexPromo(index)" v-else>
                        <source type="image/webp" :srcset="`${s3Url}/${media.src_mobile_webp}`" v-if="media.src_mobile_webp && isSupportedWebp">
                        <img class="slider__media" :src="`${s3Url}/${media.src_mobile}`" alt="person photo"
                             width="870" height="500">
                    </picture>
                </template>
                <template v-else>
                    <template v-if="media.poster">
                        <picture @click.capture="setIndexPromo(index)" v-if="$mq==='desktop' || !media.poster_mobile">
                            <source type="image/webp" :srcset="`${s3Url}/${media.poster_webp}`" v-if="media.poster_webp && isSupportedWebp">
                            <img class="slider__media" :src="`${s3Url}/${media.poster}`" alt="person photo"
                                 width="870" height="500">
                        </picture>
                        <picture @click.capture="setIndexPromo(index)" v-else>
                            <source type="image/webp" :srcset="`${s3Url}/${media.poster_mobile_webp}`" v-if="media.poster_mobile_webp && isSupportedWebp">
                            <img class="slider__media" :src="`${s3Url}/${media.poster_mobile}`" alt="person photo"
                                 width="870" height="500">
                        </picture>
                    </template>
                    <template v-else>
                            <video @click.capture="setIndexPromo(index)" class="slider__media" playsinline muted >
        <!--                        <source :src="`${s3Url}/${media.src_webp}`" type="video/webm" v-if="isSupportedWebp && media.src_webp"/>-->
                                <source :src="`${s3Url}/${media.src}`" type="video/mp4" />
                            </video>
                    </template>

                    <!--                                            :poster="`${myUrl}/storage/${video.poster}`"-->
<!--                    <video @click.capture="setIndexPromo(index)" class="slider__media" playsinline muted :poster="`${s3Url}/${(isSupportedWebp && media.poster_webp) ? media.poster_webp : media.poster}`" v-if="media.poster">-->
<!--                        <source :src="`${s3Url}/${media.src_webp}`" type="video/webm" v-if="isSupportedWebp && media.src_webp"/>-->
<!--                        <source :src="`${s3Url}/${media.src}`" type="video/mp4" />-->
<!--                    </video>-->
<!--                    <video @click.capture="setIndexPromo(index)" class="slider__media" playsinline muted v-else>-->
<!--&lt;!&ndash;                        <source :src="`${s3Url}/${media.src_webp}`" type="video/webm" v-if="isSupportedWebp && media.src_webp"/>&ndash;&gt;-->
<!--                        <source :src="`${s3Url}/${media.src}`" type="video/mp4" />-->
<!--                    </video>-->

                    <button class="button button_video-play" v-if="$mq==='desktop'"
                            @click.capture="showVideos(`${s3Url}/${isSupportedWebp && media.src_webp ? media.src_webp : media.src}`)">
                        <span class="button__icon">
                            <span class="triangle triangle_r triangle_sm"></span>
                        </span>
                    </button>
                    <button class="button" v-else @click.capture="showVideos(`${s3Url}/${isSupportedWebp && media.src_webp ? media.src_webp : media.src}`)">
                        <i class="icon icon_play"></i>
                    </button>

                </template>
            </slide>
        </template>
        <template v-if="!presentation_medias || presentation_medias.length === 0 || (isEdit && presentation_medias.length < 4)">
            <slide class="slider__item" :class="{'profile__upload upload':isEdit}">
                <!--                @change="setPromoMedia"-->
                <label for="promoUpload" class="upload__label upload__label_abs upload__label_image" v-if="isEdit  && presentation_medias && presentation_medias.length < 4">
                    <input  id="promoUpload" @change="setPromoMedia" type='file' accept=".png, .jpg, .jpeg, .svg, .webp, .avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm " hidden />
                </label>
                <img class="slider__holder" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="person photo" width="400" height="420">
            </slide>
        </template>

        <hooper-pagination slot="hooper-addons" v-if="$mq !=='desktop'"></hooper-pagination>
    </hooper>
</template>
<script>
export default {
    name: "HooperPromo",
    props:[
        'myUrl',
        'isEdit',
        's3Url',
        'presentation_medias',
        'indexPromo',
        'isSupportedWebp'
    ],
    mounted() {
    },
    methods:{
        deletePromoMedia(media,index){
            console.log('delete_promo_media');
            this.$emit('delete_promo_media', media , index);
        },
        setPromoMedia(event){
            console.log('setPromoMedia');
            this.$emit('set_promo_media',event);
        },
        showVideos(url){
            this.$emit('show_videos',url)
        },
        setIndexPromo(index){
            this.$emit('set_index_promo',index)
        }
    }
}
</script>

<style scoped>

</style>
