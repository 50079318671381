<template>
    <div class="logo" @click.prevent="goHome">
        <span class="logo__image">
            <span class="visually-hidden">hablario.com</span>
        </span>
    </div>
</template>

<script>
export default {
    name: "BaseLogo",
    methods:{
        goHome(){
            if(this.isPromoPage())return
            console.log('goHome');
            if (this.$route.name !== 'gallery'){
                this.$router.push({name: 'gallery'});
            }
        },
        isPromoPage(){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat'){
                this.$bus.$emit('open_register')
                return true
            }else {return false}
        }

    }
}
</script>

<style scoped>

</style>
