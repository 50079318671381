<template>
    <div class="chat__preview chat__preview_record preview">
        <div class="preview__container" v-if="errorMessage">
            <span class="preview__text">Microphone not available...</span>
            <button class="preview__close button button_icon button_close" @click.stop.capture="cancelVoice" v-if="$mq==='desktop'">
                <i class="icon icon_close"></i>
            </button>
        </div>
        <div class="preview__container" v-else>

            <div class="progress" ref="progress" v-if="isRecord && isStartRecord">
                <svg class="progress__circle" width="40" height="40">
                    <circle class="progress__bg" cx="20" cy="20" r="18"></circle>
                    <circle class="progress__line" cx="20" cy="20" r="18" ref="progressLine"></circle>
                </svg>
                <span class="progress__label" ref="progressLabel">{{remainingTime}}</span>
            </div>

            <span class="message__audio">
                <span class="loader loader_local" v-if="isRecord && !isStartRecord"><span class="loader__spinner"></span></span>
                <!--   add class is-stop-->
                <button class="button button_audio-play is-stop" @click.prevent="stopRecord" v-else-if="isRecord">
                    <span class="button__icon button__icon_stop" v-if="isStartRecord"></span>
                </button>
                <button class="button button_audio-play" :class="{'is-play':!audioPlay, 'is-pause': audioPlay}"
                        @click.prevent="playAudio" v-else>
                      <svg class="button__icon button__icon_play" xmlns="http://www.w3.org/2000/svg" width="16" height="18" v-if="!audioPlay">
                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#play`" />
                      </svg>
                      <svg class="button__icon button__icon_pause" xmlns="http://www.w3.org/2000/svg" width="16" height="18" v-else>
                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#pause`" />
                      </svg>
                </button>
                <span class="audio" id="mic_voice" v-if="isRecord"></span>
                <span class="audio" id="wavesurfer_preview" v-else></span>
            </span>

            <button class="preview__close button button_icon button_close" @click.stop.capture="cancelVoice" v-if="$mq==='desktop'">
                <i class="icon icon_close"></i>
            </button>
        </div>
    </div>
</template>

<script>
import CustomAudioPlayer from "../newModules/CustomAudioPlayer.vue";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";

let wavesurferRecord = null;
let wavesurferPlay = null;
let record = null;
let progressValue = 0
let progressInterval = null;
let scrollingWaveform = false;

export default {
    name: "RecordVoice",
    components: {
        CustomAudioPlayer
    },
    props: ['myUrl'],
    data(){
        return{
            remainingTime: 60,
            audioPlay: false,
            isRecord: true,
            isStartRecord: false,
            errorMessage: ''
        }
    },
    mounted() {
        this.initWavesurferItem()
    },
    beforeDestroy() {
        clearInterval(progressInterval)
        this.destroyInstans()
    },
    methods: {
        destroyInstans() {
            console.log('destroyInstans')

            if (record) {
                // if (record.isRecord()){
                //     record.pauseRecording()
                // }
                record.destroy();
                record = null;
            }
            if (wavesurferRecord) {
                wavesurferRecord.destroy();
                wavesurferRecord = null;
            }
            if (wavesurferPlay) {
                wavesurferPlay.destroy();
                wavesurferPlay = null;
            }
        },
        stopRecord() {
            console.log('stopRecord')
            clearInterval(progressInterval)
            if (record && record.isRecording()) {
                console.log('click stop recording')
                record.stopRecording()
            } else {
                console.error('!record.isRecording')
            }
        },
        cancelVoice() {
            this.stopRecord()
            this.destroyInstans()
            this.$emit('cancel_voice');
        },
        initProgressBar() {
            const durationVoice = 90
            this.remainingTime = durationVoice;
            progressValue = 0
            this.setProgress(progressValue);
            progressInterval = setInterval(() => {
                if (this.remainingTime <= 1) {
                    clearInterval(progressInterval)
                    this.stopRecord();
                }
                this.remainingTime--
                progressValue = Math.floor((durationVoice - this.remainingTime) / (durationVoice / 100));
                this.setProgress(progressValue);
            }, 1000);

        },
        setProgress(value) {
            const progressCircle = this.$refs.progressLine;
            if(!progressCircle || !progressCircle.r || !progressCircle.r.baseVal) return;
            //const progressLabel = this.$refs.progressLabel;
            const radius = progressCircle.r.baseVal.value;
            const circumference = 2 * Math.PI * radius;
            const offset = circumference - (value / 100) * circumference;

            progressCircle.style.strokeDasharray = `${circumference}`;
            progressCircle.style.strokeDashoffset = `${circumference}`;
            progressCircle.style.strokeDashoffset = offset;
            // progressLabel.textContent = value
        },
        playAudio() {
            if (!wavesurferPlay) {
                console.error('!wavesurferPlay')
                return;
            }
            if (this.audioPlay) {
                wavesurferPlay.pause();

            } else {
                wavesurferPlay.play();

            }
        },
        initWavesurferItem() {
            console.log('initWavesurfer Preview');
            if (wavesurferRecord) return;
            const containerMic = document.getElementById('mic_voice');
            if (!containerMic) {
                console.error('!containerMic');
                return;
            }
            wavesurferRecord = WaveSurfer.create({
                container: containerMic,
                height: 40,
                waveColor: '#A8A8A8',
                progressColor: '#131313',
                barRadius: 3,
                barWidth: 1,
                barGap: 2,
                backend: 'MediaElement',
                cursorWidth: 0,
                mediaControls: false,
            });
            record = wavesurferRecord.registerPlugin(RecordPlugin.create({
                scrollingWaveform,
                renderRecordedAudio: false
            }))


            record.startMic()
                .then(() => {
                    record.stopMic()
                    record.on('record-start', () => {
                        console.log('record-start')
                        this.isStartRecord = true
                        this.$emit('is_start_record', true)
                        this.$nextTick(() => {
                            this.initProgressBar()
                        })
                    })
                    record.on('record-end', (blob) => {
                        console.log('record-end')
                        this.isRecord = false;
                        this.$emit('is_start_record', false)
                        const recordedUrl = URL.createObjectURL(blob);
                        console.log({type: blob.type})
                        const mimeType = blob.type.split(';')[0].split('/')[1] || 'webm';
                        console.log({mimeType})

                        const audioFile = new File([blob], `recorded-audio.${mimeType}`, {
                            type: blob.type,
                            lastModified: new Date().getTime() // Опционально: установить время последней модификации файла
                        });
                        // console.log(audioFile)

                        this.$emit('upload_voice', {
                            file: audioFile,
                            preview: recordedUrl,
                        })
                        if (record) {
                            record.destroy();
                            record = null;
                        }
                        if (wavesurferRecord) {
                            wavesurferRecord.destroy();
                            wavesurferRecord = null;
                        }
                        this.$nextTick(() => {
                            const container = document.getElementById('wavesurfer_preview')
                            // Create wavesurfer from the recorded audio
                            if (!container) return
                            wavesurferPlay = WaveSurfer.create({
                                container,
                                height: 40,
                                waveColor: '#A8A8A8',
                                progressColor: '#131313',
                                barRadius: 3,
                                barWidth: 1,
                                barGap: 2,
                                backend: 'MediaElement',
                                cursorWidth: 0,
                                mediaControls: false,
                                url: recordedUrl,
                            })
                            wavesurferPlay.on('pause', () => (this.audioPlay = false))
                            wavesurferPlay.on('play', () => (this.audioPlay = true))
                        })
                    })
                    record.on('record-progress', (time) => {
                        this.$emit('progress_record', time)
                    })
                    //{ deviceId }
                    record.startRecording().then(() => {
                        console.log('Start Recording')
                    })
                })
                .catch((err) => {
                    console.error(err)
                    this.errorMessage = err.message
                })
            console.log('create record');

        },
    }
}
</script>

<style scoped>

</style>
