<template>
  <div class="media__image">
    <video class="media__video media__video_mobile" autoplay playsinline loop :muted="isMuted"
           style="visibility: visible;" ref="videoEl" v-show="isLoadVideo" v-if="$mq==='mobile'">
      <source :src="pathMobile" type="video/mp4" />
    </video>
    <video class="media__video" autoplay playsinline loop :muted="isMuted"
           style="visibility: visible;" ref="videoEl" v-show="isLoadVideo" v-else>
      <source :src="path" type="video/mp4" />
    </video>
  </div>
</template>

<script>
export default {
  name: "VideoBlock",
  props: ['myUrl', 'srcVideo', 'srcVideoMobile'],
  data() {
    return {
      path:'',
      pathMobile:'',
      isMuted:true,
      isLoadVideo:false
    }
  },
  mounted() {
    this.setPath()
    if(this.$refs.videoEl){
      //this.$emit('set_video_el',this.$refs.video);
    }
    if (this.videoCanPlay()) {
      this.$refs.videoEl.oncanplay = () => {
        if (!this.$refs.videoEl) return
        this.isLoadVideo = true;
        this.$refs.videoEl.style.visibility = 'visible'            }
    }
  },
  methods:{
    videoCanPlay () {
      return !!this.$refs.videoEl.canPlayType
    },
    setPath() {
      this.path = this.myUrl + this.srcVideo
      this.pathMobile = this.myUrl + this.srcVideoMobile
    }
  }


}
</script>

<style scoped>

</style>
