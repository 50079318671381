<template>
    <div class="page page_fixed page_new-story" :class="{'page_mobile':$mq!=='desktop'}">
        <div class="loader" v-if="disabledButton || isConversion">
            <span class="loader__text text-danger" v-if="isConversion">Conversion in progress</span>
            <span class="loader__spinner"></span>
        </div>
        <CoolLightBox
            :items="imageBox"
            :index="indexImage"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @close="indexImage = null">
        </CoolLightBox>
        <div class="page__header">
<!--            <a href="index.html" class="logo">-->
<!--                <span class="logo__image"><span class="visually-hidden">Lifeforlove.com</span></span>-->
<!--            </a>-->
            <template v-if="$mq!=='desktop'">
                <button class="button button_round button_back" @click.prevent="goBack">
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#back`" />
                    </svg>
                </button>
                <h3 class="page__heading">{{ this.$route.name==='CreateMyStory' ? 'Create new story': 'Update story' }}</h3>
            </template>
            <template v-else>
                <base-logo></base-logo>
                <span class="page__close" role="button" aria-label="Close" @click.prevent="goBack">
                    <i class="icon icon_close"></i>
                </span>
            </template>
        </div>
        <div class="container">
            <h3 class="page__heading" v-if="$mq==='desktop'">{{ this.$route.name==='CreateMyStory'?'Create new story': 'Update story' }}</h3>
            <div class="form form_horizontal">
                <div class="form__row">
                    <label for="subjectStory" class="form__label">Subject</label>
                    <input id="subjectStory" class="ui-input" maxlength="250" v-model="newStory.subject">
                </div>
                <div class="form__row">
                    <label for="descriptionStory" class="form__label">Description</label>
                    <textarea id="descriptionStory" class="ui-textarea" maxlength="2000" v-model="newStory.description" ></textarea>
                </div>
                <div class="form__row">
                    <label for="isComment">
                        <input id="isComment" type="checkbox"  class="ui-checkbox" v-model="newStory.is_comments" :true-value = false :false-value = true>
                        <span class="label">No comment</span>
                    </label>
                </div>
                <div class="form__row">
                    <label for="typeMedia" class="form__label">Type media</label>
                    <select class="ui-select" name="" id="typeMedia" v-model="newStory.type_src" :disabled="unpublishedStory && unpublishedStory.id">
                        <option value="image">Image</option>
                        <option value="video">Video</option>
<!--                        <option value="">Audio</option>-->
                    </select>
                </div>
                <template v-if="!unpublishedStory || !unpublishedStory.id">
                    <div class="form__footer">
                        <button class="button button_md button_primary" @click="createNewMyStory" :disabled="disabledNextStep">
                            <span>Next Step</span>
                        </button>
                    </div>
                </template>
                <template v-else>
                    <div class="form__row upload upload_simple">
                        <span class="form__label">Select poster for story</span>
                        <div class="upload__section">
                            <template v-if="!unpublishedStory.poster || !unpublishedStory.poster_gallery">
                                <label for="storyPosterUpload" class="upload__label">
                                    <button class="upload__button button button_sm button_primary">
                                        <span><i class="icon icon_choose button__icon"></i>Choose</span>
                                    </button>
                                    <input v-if="unpublishedStory.type_src==='image'" id="storyPosterUpload" type='file' accept=".png, .jpg, .jpeg, .svg, .webp"  @input="selectPoster" hidden />
                                    <input v-else-if="unpublishedStory.type_src==='video'" @input="selectVideoPoster" id="storyPosterUpload" type='file' accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm " hidden />
                                </label>
                            </template>
                            <template v-else>
                                <button class="button upload__delete" @click.prevent="deletePoster">
                                    <i class="icon icon_delete"></i>
                                </button>
                                <div class="upload__col">
                                    <div class="upload__preview" v-if="unpublishedStory.poster">
                                        <h6 class="title">Poster Story</h6>
                                        <img :src="`${s3Url}/${unpublishedStory.poster_webp}`" alt="poster" v-if="unpublishedStory.poster_webp">
                                        <img :src="`${s3Url}/${unpublishedStory.poster}`" alt="poster" v-else>
                                    </div>
                                    <div class="upload__preview" v-if="unpublishedStory.poster_gallery">
                                        <h6 class="title">Poster Members</h6>
                                        <img :src="`${s3Url}/${unpublishedStory.poster_gallery_webp}`" alt="poster" v-if="unpublishedStory.poster_gallery_webp">
                                        <img :src="`${s3Url}/${unpublishedStory.poster_gallery}`" alt="poster" v-else>
                                    </div>
                                </div>
                            </template>

                        </div>
                        <div class="slider slider_portfolio">
                            <div class="slider__container" v-if="$mq==='desktop'">

                                <hooper
                                    :itemsToShow="3"
                                    :trimWhiteSpace="true"
                                    :itemsToSlide="1"
                                    :transition="500"
                                    :infiniteScroll="false"
                                    :playSpeed="5000"
                                    ref="sliderPortfolio">
                                    <template v-if="(myStoryMedias && myStoryMedias.length < LIMIT_MAX_IMAGE && unpublishedStory.type_src==='image') ||
                                    (myStoryMedias && myStoryMedias.length < LIMIT_MAX_VIDEO && unpublishedStory.type_src==='video')">
                                        <slide class="slider__item" v-for="i in countHolder" :key="i">
                                            <div class="profile__upload upload">
                                                <label :for="`portfolioUpload${i}`" class="upload__label upload__label_abs upload__label_image">
                                                    <input v-if="unpublishedStory.type_src==='image'" @input="saveImagePack" :id="`portfolioUpload${i}`" type='file' accept=".png, .jpg, .jpeg, .svg, .webp" hidden />
                                                    <input v-else-if="unpublishedStory.type_src==='video'" @input="selectVideoPack" :id="`portfolioUpload${i}`" type='file' accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm " hidden />
                                                </label>
                                                <img class="slider__holder" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder">
                                            </div>
                                        </slide>
                                    </template>

                                    <template v-if="myStoryMedias && myStoryMedias.length && unpublishedStory && unpublishedStory.type_src==='image'">
                                        <slide class="slider__item" v-for="(photo, el) in myStoryMedias" :key="photo.id">
                                            <button class="slider__delete button button_filled button_close" @click.capture="deleteMedia(photo,el)">
                                                <i class="icon icon_close"></i>
                                            </button>
                                            <picture>
                                                <source type="image/webp" :srcset="`${s3Url}/${photo.src_webp}`" v-if="isSupportedWebp && photo.src_webp">
                                                <img class="slider__media" :src="`${s3Url}/${photo.src}`" alt="photo portfolio" @click.capture="indexImage = el">
                                            </picture>
                                        </slide>
                                    </template>
                                    <template v-else-if="myStoryMedias && myStoryMedias.length && unpublishedStory && unpublishedStory.type_src==='video'">
                                        <slide class="slider__item" v-for="(media, el) in myStoryMedias" :key="media.id" >
                                            <button class="slider__delete button button_filled button_close" @click.capture="deleteMedia(media,el)">
                                                <i class="icon icon_close"></i>
                                            </button>
                                            <video class="slider__media" playsinline muted @click.capture="indexImage = el">
                                                <source :src="`${s3Url}/${media.src_webp}`" type="video/webm" v-if="isSupportedWebp && media.src_webp"/>
                                                <source :src="`${s3Url}/${media.src}`" type="video/mp4" />
                                            </video>
                                        </slide>
                                    </template>

                                </hooper>

                                <button class="slider__button slider__button_prev button" @click.prevent="slidePortfolioPrev"
                                        v-if="myStoryMedias && myStoryMedias.length > 2">
                                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                         height="12" fill="none">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                              d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                    </svg>
                                </button>
                                <button class="slider__button slider__button_next button" @click.prevent="slidePortfolioNext"
                                        v-if="myStoryMedias && myStoryMedias.length > 2">
                                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                         height="12" fill="none">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                              d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                    </svg>
                                </button>
                            </div>
                            <div class="slider__container" v-else>
                                <div class="upload__section" v-if="unpublishedStory.poster && unpublishedStory.poster_gallery && (myStoryMedias && myStoryMedias.length < LIMIT_MAX_IMAGE && unpublishedStory.type_src==='image') ||
                                    (myStoryMedias && myStoryMedias.length < LIMIT_MAX_VIDEO && unpublishedStory.type_src==='video')">
                                    <label for="portfolioUpload" class="upload__label">
                                        <button class="upload__button button button_sm button_primary">
                                            <span><i class="icon icon_choose button__icon"></i>Choose file</span>
                                        </button>
                                        <input v-if="unpublishedStory.type_src==='image'" @input="saveImagePack" :id="`portfolioUpload`" type='file' accept=".png, .jpg, .jpeg, .svg, .webp" hidden />
                                        <input v-else-if="unpublishedStory.type_src==='video'" @input="selectVideoPack" :id="`portfolioUpload`" type='file' accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm " hidden />
                                    </label>
                                </div>
                                <hooper
                                    :transition="500"
                                    :wheelControl="false"
                                    ref="sliderPortfolio">
<!--                                    <template v-if="(myStoryMedias && myStoryMedias.length < LIMIT_MAX_IMAGE && unpublishedStory.type_src==='image') ||-->
<!--                                    (myStoryMedias && myStoryMedias.length < LIMIT_MAX_VIDEO && unpublishedStory.type_src==='video')">-->
<!--                                        <slide class="slider__item">-->
<!--                                            <div class="profile__upload upload">-->
<!--                                                <label for="portfolioUpload" class="upload__label upload__label_abs upload__label_image">-->
<!--                                                    <input v-if="unpublishedStory.type_src==='image'" @input="saveImagePack" :id="`portfolioUpload`" type='file' accept=".png, .jpg, .jpeg, .svg, .webp" hidden />-->
<!--                                                    <input v-else-if="unpublishedStory.type_src==='video'" @input="saveVideoPack" :id="`portfolioUpload`" type='file' accept=".avi, .3gp, .flv, .m4v, .mkv, .mov, .mp4, .mpeg, .mpg, .ogg, .webm " hidden />-->
<!--                                                </label>-->
<!--                                                <img class="slider__holder" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="300" height="320">-->
<!--                                            </div>-->
<!--                                        </slide>-->
<!--                                    </template>-->
                                    <template v-if="myStoryMedias && myStoryMedias.length && unpublishedStory && unpublishedStory.type_src==='image'">
                                        <slide class="slider__item" v-for="(photo, el) in myStoryMedias" :key="photo.id">
                                            <button class="slider__delete button button_filled button_close" @click.capture="deleteMedia(photo,el)">
                                                <i class="icon icon_close"></i>
                                            </button>

                                            <picture>
                                                <source type="image/webp" :srcset="`${s3Url}/${photo.src_webp}`" v-if="isSupportedWebp && photo.src_webp">
                                                <img class="slider__media" :src="`${s3Url}/${photo.src}`" alt="photo portfolio" @click.capture="indexImage = el"
                                                     width="270" height="270">
                                            </picture>
                                        </slide>
                                    </template>
                                    <template v-else-if="myStoryMedias && myStoryMedias.length && unpublishedStory && unpublishedStory.type_src==='video'">
                                        <slide class="slider__item" v-for="(media, el) in myStoryMedias" :key="media.id" >
                                            <button class="slider__delete button button_filled button_close" @click.capture="deleteMedia(media,el)">
                                                <i class="icon icon_close"></i>
                                            </button>
                                            <video class="slider__media" playsinline muted @click.capture="indexImage = el">
                                                <source :src="`${s3Url}/${media.src_webp}`" type="video/webm" v-if="isSupportedWebp && media.src_webp"/>
                                                <source :src="`${s3Url}/${media.src}`" type="video/mp4" />
                                            </video>
                                        </slide>
                                    </template>
                                    <hooper-pagination slot="hooper-addons"></hooper-pagination>
                                </hooper>
                            </div>
                        </div>
                    </div>
                    <div class="form__footer">
<!--                        style="color: red"-->
                        <button class="button button_md button_danger"  @click="deleteMyStory" :disabled="disabledButton" v-if="this.unpublishedStory.id && this.unpublishedStory.id">
                            <span>Delete <i class="icon icon_delete"></i></span>
                        </button>
                        <button class="button button_md button_primary" @click="publishMyStory" :disabled="disabledButton || !isPublishedStory">
                            <span>{{ this.$route.name==='CreateMyStory'? 'Publish': 'Update' }}</span>
                        </button>
                    </div>
                </template>

            </div>
        </div>
        <portal selector="#portal-target" v-if="modalCropper">
            <div class="modal modal_cropper is-active" tabindex="-1" role="dialog">
                <div class="modal__bg" @click.prevent="closeModalCropper"></div>
                <div class="modal__container">
                    <span class="modal__close button button_filled"  role="button" aria-label="Close" @click.prevent="closeModalCropper">
                        <i class="icon icon_close"></i>
                    </span>
                    <div class="modal-body">
                        <image-cropper
                            :my-url="myUrl"
                            :name-header="'Crop poster for story'"
                            :src="imagePoster.src"
                            :width="POSTER810X488.width"
                            :height="POSTER810X488.height"
                            :avatar-size="100"
                            :is-make-thumb="false"
                            :disabled-button="disabledButton"
                            @crop="cropImages810X488"
                        ></image-cropper>
                    </div>
                </div>
            </div>
        </portal>
        <portal selector="#portal-target" v-if="modalCropperGallery">
            <div class="modal modal_cropper is-active" tabindex="-1" role="dialog">
                <div class="modal__bg" @click.prevent="closeModalCropper"></div>
                <div class="modal__container">
                    <span class="modal__close button button_filled"  role="button" aria-label="Close" @click.prevent="closeModalCropper">
                        <i class="icon icon_close"></i>
                    </span>
                    <div class="modal-body">
                        <image-cropper
                            :my-url="myUrl"
                            :name-header="'Crop poster for members'"
                            :src="imagePoster.src"
                            :width="POSTER370X360.width"
                            :height="POSTER370X360.height"
                            :avatar-size="100"
                            :is-make-thumb="false"
                            :disabled-button="disabledButton"
                            @crop="cropImages370X360"
                        ></image-cropper>
                    </div>
                </div>
            </div>
        </portal>
        <modal-show-video v-if="showModalVideo"
                          :video-src="srcVideo"
                          :type-video="typeVideo"
                          @close_modal="closeModalVideo"
                          @select_frame="selectVideoFrame"
        ></modal-show-video>
        <modal-duplicate @close_modal_duplicate="showModalDuplicate=false" v-if="showModalDuplicate"></modal-duplicate>
        <modal-error-conversation @close_modal="showModalErrorConversation=false" v-if="showModalErrorConversation"></modal-error-conversation>
        <modal-publish-story @close_modal="closeModalPublish" v-if="showModalPublish"></modal-publish-story>
        <confirm-delete-story
            v-if="modalConfirmDelete"
            @close_confirm_delete="closeConfirmDelete"
            @confirm_delete_story="confirmDeleteMyStory"
        ></confirm-delete-story>
    </div>
</template>

<script>
import ImageCropper from "../modules/ImageCropper.vue";
import BaseLogo from "../newModules/BaseLogo.vue";
import ModalDuplicate from "../newModules/modals/ModalDublicate.vue";
import ModalPublishStory from "../newModules/modals/ModalPublishStory.vue";
import ModalShowVideo from "../newModules/modals/ModalShowVideo.vue";
import ConfirmDeleteStory from "../newModules/modals/ConfirmDeleteStory.vue";
import ModalErrorConversation from "../newModules/modals/ModalErrorConversation.vue";

let posterImageFile = null;
let imagePackFile = null;
let videoFile = null;
let hash = '';

export default {
    name: "CreateMyStory",
    components: {
        ModalErrorConversation,
        ConfirmDeleteStory, ModalShowVideo, ModalPublishStory, ModalDuplicate, BaseLogo, ImageCropper},
    props: ['storyID','myUrl', 's3Url','chatOwner','currentUser','isSupportedWebp'],
    data(){
        return{
            maxsizeImage: 10*1024*1024,
            maxsizeVideo: 20*1024*1024,
            indexImage: null,
            unpublishedStory: null,
            myStoryMedias: [],
            disabledButton: false,
            imagePoster: null,
            imagePosterSrc: null,
            isConversion:false,
            modalCropper: false,
            modalCropperGallery: false,
            showModalDuplicate: false,
            showModalPublish: false,
            showModalVideo: false,
            showModalErrorConversation: false,
            conversationUrl: '',
            typeConversation: 'story',
            srcVideo: '',
            typeVideo: '',
            videoSrcPack: null,
            typeVideoPack: null,
            POSTER810X488:{
                width: 810,
                height: 488,
            },
            POSTER370X360: {
                width: 370,
                height: 360,
            },
            LIMIT_MIN_IMAGE: 2,
            LIMIT_MAX_IMAGE: 10,
            LIMIT_MIN_VIDEO: 1,
            LIMIT_MAX_VIDEO: 10,
            newStory: {
                id: 0,
                subject: '',
                description: '',
                type_src: 'image',
                is_comment: true,
                poster: '',
                poster_webp: '',
            },
            modalConfirmDelete: false
        }
    },
    computed:{
        countHolder(){
            if(!this.myStoryMedias|| !this.myStoryMedias.length) return 3
            if(this.myStoryMedias.length === 1) return 2

            return 1
        },
        imageBox(){
            if(!this.myStoryMedias || !this.myStoryMedias)return [];
            return this.myStoryMedias.map(media =>(`${this.s3Url}/${media.src}`));
        },
        disabledNextStep(){
            return this.disabledButton || !this.newStory.subject ||
                this.newStory.subject.length < 1
        },
        isPublishedStory(){

            return Boolean(this.unpublishedStory && this.unpublishedStory.id &&
                this.unpublishedStory.subject && this.unpublishedStory.poster &&
                this.unpublishedStory.poster_gallery && this.myStoryMedias &&
                (
                    (this.unpublishedStory.type_src ==="image" &&  this.myStoryMedias.length >= this.LIMIT_MIN_IMAGE) ||
                    (this.unpublishedStory.type_src==="video" &&  this.myStoryMedias.length >= this.LIMIT_MIN_VIDEO)
                ))
        },
    },
    mounted() {
        this.$bus.$emit('close_modal');
        if (this.post ) {
            console.log('edit old story');
            this.newStory.subject = this.post.subject
            this.newStory.description = this.post.description
            this.newStory.is_comments = this.post.is_comment
            this.newStory.type_src = this.post.type_src
        }else {
            this.getInitStory()
        }
    },
    beforeDestroy() {
        this.disconnectStoryChannel()
        this.$bus.$emit('close_modal');
    },
    methods:{
        getStoryMedia(file,obj,type){
            const ext = this.getExtantionFile(file.name);
            if(type === 'video'){
                if (ext === 'mp4'){
                    this.typeVideo = posterImageFile.type;
                    this.srcVideo = URL.createObjectURL(posterImageFile);
                    this.showModalVideo = true;
                }else {
                    this.isConversion = true;
                    this.convertMedia(file,'video',ext)
                    console.log('convert')
                }
            }
        },

        getExtantionFile(fileName){
            let parts = fileName.split('.');
            let ext = '';
            if (parts.length > 1) {
                ext = parts.pop();
                return ext.toLowerCase();
            }
            return '';
        },
        convertMedia(media,typeMedia,ext){
            this.connectStoryChannel();
            const formData = new FormData();
            formData.append('media',media,'media');
            formData.append('name',media.name);
            formData.append('type_media',typeMedia);
            formData.append('role',this.currentUser.role);
            formData.append('memberID',this.chatOwner.id);
            formData.append('type',this.typeConversation);
            formData.append('ext',ext);

            axios.post(`/send-media-for-conversion`,formData)
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === 'ok'){

                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        connectStoryChannel(){
            Echo.private(`${this.typeConversation}-${this.currentUser.role}.${this.chatOwner.id}`)
                .listen('ConversionMediaCompleted',(event)=>{
                    console.log(event);
                    this.isConversion = false;
                    if(event.status==="success"){
                        this.conversationUrl = event.path;
                        this.typeVideo = 'video/mp4';
                        if(this.typeConversation==='story'){
                            this.srcVideo = `${this.myUrl}/storage/${event.path}`;
                            this.showModalVideo = true;
                        }else {
                            this.saveVideoPack(null)
                        }
                    }else {
                        this.showModalErrorConversation = true;
                    }

                    this.disconnectStoryChannel();
                });
        },
        disconnectStoryChannel(){
            Echo.leave(`story-${this.myRole}.${this.memberID}`);
        },
        confirmDeleteMyStory(){
            console.log('confirmDeleteMyStory ' )
            this.disabledButton = true;
            axios.post(`/delete-my-story`,{
                myStoryID: this.unpublishedStory.id,
            })
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        this.$router.push({ name: 'account' })
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })

        //     TODO delete story

            this.closeConfirmDelete()
        },
        closeConfirmDelete(){
            this.modalConfirmDelete = false
            this.$bus.$emit('close_modal')
        },
        deleteMyStory(){
            console.log('delete ')
            this.modalConfirmDelete = true
            this.$bus.$emit('open_modal')
        },
        selectVideoFrame(blob){
            console.log('selectVideoFrame')
            this.imagePoster = new Image()
            this.imagePoster.onload = () => {
                setTimeout(()=> {
                    this.modalCropper = true;
                    this.showModalVideo = false;
                },100)
            };
            this.imagePoster.src = URL.createObjectURL(blob)
        },
        closeModalPublish(){
            console.log('closeModalPublish');
            this.showModalPublish = false;
            this.$nextTick(()=>{
                this.$router.push({name: 'account'});
            })

        },
        publishMyStory(){
            if (!this.unpublishedStory || !this.unpublishedStory.id) return

            axios.post(`/publish-my-story`,{
                myStoryID: this.unpublishedStory.id,
                subject: this.newStory.subject,
                description: this.newStory.description,
                memberID: this.chatOwner.id,
                myRole: this.currentUser.role,
                is_comment: this.newStory.is_comment
            })
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        this.showModalPublish = true;
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        closeModalCropper(){
            this.modalCropper = false;
            this.modalCropperGallery = false;
            this.imagePoster = null;
            posterImageFile = null;
        },
        goBack(){
            this.$router.back();
        },
        slidePortfolioPrev() {
            this.$refs.sliderPortfolio.slidePrev();
        },
        slidePortfolioNext() {
            this.$refs.sliderPortfolio.slideNext();
        },
        deletePoster(){
            console.log('deletePoster');
            this.disabledButton = true;
            axios.post(`/delete-my-story-poster/${this.unpublishedStory.id}`)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        this.unpublishedStory.poster = null;
                        this.unpublishedStory.poster_webp = null;
                        this.unpublishedStory.poster_gallery = null;
                        this.unpublishedStory.poster_gallery_webp = null;
                        this.unpublishedStory.is_publish = false;
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        deleteMedia(media,index){
            this.disabledButton = true;
            axios.post(`/delete-my-story-media/${media.id}`)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        this.myStoryMedias = this.myStoryMedias.filter(item =>( +media.id !== +item.id))
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        uploadStoryVideo(event){
            videoFile = event.files[0];
            this.videoSrcPack = URL.createObjectURL(videoFile);
            this.typeVideoPack = event.files[0].type;
            event.target.value = null
        },

        async selectVideoPack(event){
            console.log(event)
            const videoFilePack =  event.target.files[0];
            const typeVideoPack = videoFilePack.type;
            event.target.value = null;
            this.typeConversation = 'story_media';
            hash = await this.getHash(videoFilePack);

            const ext = this.getExtantionFile(videoFilePack.name);
            if (ext !== 'mp4'){
                this.isConversion = true;
                this.convertMedia(videoFilePack,'video',ext)
                console.log('convert VideoPack')
                return;
            }
            this.saveVideoPack(videoFilePack);
        },

        saveVideoPack(videoFilePack) {
            console.log('saveVideoPack');

            if(!hash || !this.unpublishedStory) return;
            const formData = new FormData();
            if(this.conversationUrl){
                formData.append('conversationUrl',this.conversationUrl);
            }else {
                formData.append('video',videoFilePack,'video');
            }

            formData.append('origin_sha1', hash);
            formData.append('id',this.unpublishedStory.id);

            this.disabledButton = true;
            axios.post(`/save-video-my-story-account`,formData)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        this.myStoryMedias.unshift(response.data.myStoryMedia);
                        if(this.$refs.sliderPortfolio) this.$refs.sliderPortfolio.slideTo(0);
                    }else if(response.data.status === 'duplicated'){
                        this.showModalDuplicate = true;
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        async saveImagePack(event){
            console.log('saveImagePack');
            if(this.disabledButton || !this.unpublishedStory) return;
            imagePackFile = event.target.files[0];
            event.target.value = null
            const hash = await this.getHash(imagePackFile);
            if(!hash) return;
            const imageEl = await this.uploadStoryImage(imagePackFile);
            const canvas = this.getCanvasImage(imageEl);
            const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 0.95));
            const blobWebp = await new Promise(resolve => canvas.toBlob(resolve, "image/webp", 0.95));
            const formData = new FormData();
            formData.append('image',blob,'image');
            formData.append('image_webp',blobWebp,'image_webp');
            formData.append('origin_sha1',hash);
            formData.append('myRole',this.currentUser.role);
            formData.append('id',this.unpublishedStory.id);
            // formData.append('thumb',blobThumb,'thumb');
            // formData.append('thumbWebp',blobThumbWebp,'thumbWebp');
            this.disabledButton = true;
            axios.post(`/save-image-my-story-account`,formData)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        imagePackFile = null;
                        this.myStoryMedias.unshift(response.data.myStoryMedia);
                        if(this.$refs.sliderPortfolio) this.$refs.sliderPortfolio.slideTo(0);
                    }else if(response.data.status === 'duplicated'){
                        this.showModalDuplicate = true;
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        cropImages810X488(canvas){
            this.savePosterImage(canvas,this.POSTER810X488.width,this.POSTER810X488.height,'poster')
        },
        cropImages370X360(canvas){
            this.savePosterImage(canvas,this.POSTER370X360.width,this.POSTER370X360.height,'poster_gallery')
        },
        makeThumb(canvas,width,height){
            console.log({width,height})
            const canvasThumb = document.createElement("canvas"),
                ctxThumb = canvasThumb.getContext("2d");
            canvasThumb.width = width;
            canvasThumb.height = height;

            // ctxThumb.drawImage(canvas, 0, 0,width,height);
            ctxThumb.drawImage(canvas, 0,0,canvas.width,canvas.height,0, 0,width,height);

            return canvasThumb ;
        },
        async savePosterImage(currentCanvas,width,height,field){
            // const currentCanvas = field === 'poster' ? cropCanvas : cropCanvasGallery
            if(this.disabledButton || !currentCanvas || !this.unpublishedStory || !this.imagePoster || (this.unpublishedStory.poster && this.unpublishedStory.poster_gallery )) return
            console.log('start saveImage');
            this.modalCropper = false;
            this.modalCropperGallery = false;
            let canvas = this.makeThumb(currentCanvas,width,height)
            const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
            const blobWebp = await new Promise(resolve => canvas.toBlob(resolve, "image/webp", 1));

            const formData = new FormData();
            formData.append(field,blob,field);
            formData.append(`${field}_webp`,blobWebp,`${field}_webp`);
            formData.append('field',field);
            formData.append('id',this.unpublishedStory.id);
            formData.append('type_src',this.unpublishedStory.type_src);
            formData.append('myRole',this.currentUser.role);
            formData.append('memberID',this.chatOwner.id);
            const hash = await this.getHash(posterImageFile);
            if(!hash) return;
            // const canvasOrigin = await this.getCanvasOrigin(cropCanvas);
            formData.append(`origin_sha1`,hash);
            if(this.unpublishedStory.type_src === 'image'){
                const canvasOrigin = this.getCanvasImage(this.imagePoster);
                const blobOrigin = await new Promise(resolve => canvasOrigin.toBlob(resolve, "image/jpeg", 1));
                formData.append('origin',blobOrigin,'origin');
                const blobOriginWebp = await new Promise(resolve => canvasOrigin.toBlob(resolve, "image/webp", 1));
                formData.append(`origin_webp`,blobOriginWebp,`origin_webp`);
            }else if(this.unpublishedStory.type_src === 'video'){
                if(this.conversationUrl){
                    formData.append('conversationUrl',this.conversationUrl);
                }else {
                    formData.append('origin',posterImageFile,'origin');
                }
            }

            this.disabledButton = true;
            // formData.append('thumb',blobThumb,'thumb');
            // formData.append('thumbWebp',blobThumbWebp,'thumbWebp');
            axios.post(`/save-poster-my-story-account`,formData)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data);
                    // this.conversationUrl = '';
                    // posterImageFile = null;
                    if (response.data.status === 'ok') {
                        this.unpublishedStory[field] = response.data.image;
                        this.unpublishedStory[`${field}_webp`] = response.data.image_webp;
                        if (response.data.myStoryMedia) {
                            console.log('push to myStoryMedias');
                            this.myStoryMedias.unshift(response.data.myStoryMedia);
                        }
                        if (field === 'poster') {
                            this.modalCropper = false;
                            this.modalCropperGallery = true;
                        } else if (field === 'poster_gallery') {
                            this.closeModalCropper();
                        }
                    }
                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        // getCanvasOrigin(){
        //     return new Promise(resolve => {
        //         const imageOrigin = new Image()
        //
        //         imageOrigin.onload = () => {
        //             const canvas = this.getCanvasImage(imageOrigin)
        //             return resolve(canvas);
        //         };
        //         imageOrigin.src = img.value
        //     })
        // },
        getCanvasImage(imageEl){
            const w = imageEl.width;
            const h = imageEl.height;
            const canvas = document.createElement('canvas');
            canvas.width  = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(imageEl, 0, 0, w, h);
            return canvas;
        },
        async getHash(file) {
            try {
                return  await this.getSha1Image(file);
            }catch (e) {
                console.error('error getSha1Image');
                console.log(e)
                alert('Your browser does not support all required features. Please update your browser or use a different one');
                return ''
            }
        },
        getSha1Image(file){
            if(process.env.MIX_APP_ENV==='local'){
                return this.getTestHash();
            }else {
                return new Promise((resolve,reject) => {
                    try {
                        const fileReader = new FileReader();
                        fileReader.onloadend = async (e)=> {
                            const hashBuffer = await window.crypto.subtle.digest('SHA-1', e.target.result)
                            const hashArray = Array.from(new Uint8Array(hashBuffer));// convert buffer to byte array
                            const hash = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');// convert bytes to hex string
                            console.log(hash);
                            return resolve(hash)
                        }
                        fileReader.readAsArrayBuffer(file);
                    }catch (e) {
                        return reject(e)
                    }
                })
            }

        },
        getTestHash() {
            let text = "";
            let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

            for (var i = 0; i < 32; i++)
                text += possible.charAt(Math.floor(Math.random() * possible.length));

            return text;
        },
        closeModalVideo(){
            posterImageFile = null;
            this.typeVideo = '';
            this.srcVideo = '';
            this.showModalVideo = false;
        },
        selectVideoPoster(event){
            console.log('selectVideoPoster');

            posterImageFile = event.target.files[0];
            this.conversationUrl = '';
            if (posterImageFile.size > this.maxsizeVideo || posterImageFile.fileSize > this.maxsizeVideo) {
                //show an alert to the user
                alert(`Allowed file size exceeded. (Max. ${this.maxsizeVideo/ (1024*1024)} MB)`);
                return
            }
            this.typeConversation = 'story';
            this.getStoryMedia(posterImageFile,this.storyMedia,'video');
            event.target.value = null;
            // console.log(posterImageFile);

        },
        selectPoster(event){
            console.log('selectPoster');
            // console.log(event);
            posterImageFile = event.target.files[0];
            this.imagePoster = new Image()
            this.imagePoster.onload = () => {
                setTimeout(()=> {
                    this.modalCropper = true;
                },100)
            };
            this.imagePoster.src = URL.createObjectURL(posterImageFile)
            event.target.value = null
        },
        uploadStoryImage(imagePackFile){
            console.log('uploadStoryImage');
            // console.log(event);
            return new Promise((resolve, reject)=>{
                const imageEl = new Image()
                imageEl.onload = () => {
                    resolve(imageEl)
                };
                imageEl.src = URL.createObjectURL(imagePackFile)
            })

        },
        createNewMyStory(){
            console.log('createNewMyStory');
            const updateData = {
                myRole: this.currentUser.role,
                memberID: this.chatOwner.id,
                subject: this.newStory.subject,
                type_src: this.newStory.type_src,
                description: this.newStory.description,
                is_comment: this.newStory.is_comment,
                url: this.createUrl(this.newStory.subject),
            }
            axios.post(`/create-new-my-story-account`,updateData)
                .then(response => {
                    console.log(response.data)
                    if (response.data.status === 'ok') {
                        const story = Object.assign({poster: '',poster_gallery: ''},response.data.newStory);
                        this.initStory(story);
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        createUrl(text){
            const strArr = text.toLowerCase().split('') ;
            const isLetter = (code)=>{
                return (code > 47 && code < 58) || (code > 96 && code < 123)
            };
            let url = '/';
            let isChar = false;
            strArr.forEach(char=>{
                const code = char.charCodeAt(0);
                if(isLetter(code)){
                    url = url + char;
                    isChar = true
                }else {
                    if (isChar){
                        url = url + '-';
                    }
                    isChar = false
                }
            })
            if(!isChar){
                url = url.slice(0,-1)
            }
            if(url===''){
                url = '/story';
            }
            console.log(url);
            return url
        },
        getInitStory(){
            const url = this.$route.name==='CreateMyStory' ? `/get-unpublished-story-account/${this.chatOwner.id}` :
                `/get-update-story-account/${this.storyID}`
            axios.get(url)
                .then(response => {
                    console.log(response.data)
                    if (response.data.status === 'ok' && response.data.myStory) {
                        this.initStory(response.data.myStory)
                        this.myStoryMedias = response.data.medias;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        initStory(myStory){
            this.unpublishedStory = myStory;
            this.newStory = {
                id: myStory.id,
                subject: myStory.subject,
                description: myStory.description,
                type_src: myStory.type_src,
                is_comment: myStory.is_comment,
                poster: myStory.poster,
                poster_webp: myStory.poster_webp,
            }
        },
        updateStory(){
            console.log('updateStory')
            // TODO realisation
        },
        closeStoryCreator(){
            this.newStory.subject = ''
            this.newStory.description = ''
            this.newStory.is_comments = true
            this.newStory.type_src = 'image'
            this.$emit('close_creator')
        },
        publicationStory(){
            console.log('publicationStory')
            // TODO realisation
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    },
}
</script>

<style scoped>

</style>
