<template>
    <div class="nav__container navigation">
        <span class="navigation__close" role="button" aria-label="Close" @click="closeMenu"></span>
        <ul class="menu menu_settings navigation__menu">
            <li class="menu__item" @click.prevent="gotoAccount">
                <span class="badge badge_warning badge_animate" v-if="showBadgeWarning"></span>
                <span href="" class="menu__link">Account<i class="icon icon_account"></i></span>
            </li>
            <li class="menu__item" @click.prevent="gotoSearch">
                <span href="" class="menu__link">Search<i class="icon icon_search"></i></span>
            </li>
            <li class="menu__item" @click.prevent="toggleSound">
                <span href="" class="menu__link">
                    Sound
                    <i class="icon icon_sound-on" v-if="isSound"></i>
                    <i class="icon icon_sound-off" v-else></i>
                </span>
            </li>
            <li class="menu__item">
                <span href="" class="menu__link" @click.prevent="toggleMode('mode-dark')" v-if="selectedMode==='mode-light'">
                    Dark mode
                    <i class="icon icon_dark"  ></i>
                </span>
                <span href="" class="menu__link" @click.prevent="toggleMode('mode-light')" v-else>
                    Light mode
                    <i class="icon icon_light" ></i>
                </span>
            </li>
            <li class="menu__item menu__item menu__item_parent">
                <span class="menu__link" @click="isOpenSettings = !isOpenSettings">
                   Settings<i class="icon icon_settings"></i>
                </span>
                <push-notification :show-notification-button="showNotificationButton"
                                   :current-user="currentUser"
                                   :my-setting="settingsInit"
                                   :my-url="myUrl"
                                   :disabled-notification="disabledNotification"
                                   :on-subscribe-notification="onSubscribeNotification"
                                   @errorhandler="errorHandler"
                                   v-if="isOpenSettings"
                ></push-notification>
            </li>
        </ul>
        <ul class="menu menu_settings navigation__menu">
            <li class="menu__item"><span aria-label="link" class="menu__link" @click.prevent="openTariffsModal">Tariffs<i
                class="icon icon_tariff"></i></span></li>
            <li class="menu__item" @click.prevent="openPaymentPage">
                <span class="menu__link">
                    Buy Credits<i class="icon icon_money"></i>
                </span>
            </li>
            <li class="menu__item">
                <a :href="`${myUrl}/help-center`" target="_blank" class="menu__link">
                    Help Center
                    <i class="icon icon_question"></i>
                </a>
            </li>
            <li class="menu__item menu__item_parent">
                <span class="menu__link" @click="isOpenInformation=!isOpenInformation">
                  Information
                  <i class="icon icon_info"></i>
                </span>
                <ul class="menu menu_sub navigation__menu-sub" v-if="isOpenInformation">
                    <li class="menu__item">
                        <a :href="`${myUrl}/about`" target="_blank" class="menu__link">About</a>
                    </li>
                    <li class="menu__item">
                        <a :href="`${myUrl}/terms-and-conditions-short`" target="_blank" class="menu__link">Terms and conditions</a>
                    </li>
                    <li class="menu__item">
                        <a :href="`${myUrl}/privacy-policy`" target="_blank" class="menu__link">Privacy Policy</a>
                    </li>
                    <li class="menu__item">
                        <a :href="`${myUrl}/cookies-policy`" target="_blank" class="menu__link">Cookies Policy</a>
                    </li>
                    <li class="menu__item">
                        <a :href="`${myUrl}/refund-policy`" target="_blank" class="menu__link">Refund Policy</a>
                    </li>
                    <li class="menu__item">
                        <a :href="`${myUrl}/adult-content`" target="_blank" class="menu__link">Adult Content</a>
                    </li>
                </ul>
            </li>
        </ul>
        <ul class="menu menu_settings navigation__menu">
            <li class="menu__item" @click="openSupportChat" v-if="$mq!=='desktop'">
                <span class="menu__link">
                    Support chat
                     <span class="menu__icon">
                        <span class="tooltip tooltip_abs tooltip_primary">
                            <span class="tooltip__content" v-if="supportUnread">{{supportUnread}}</span>
                        </span>
                        <i class="button__icon icon icon_headset"></i>
                    </span>
                </span>
            </li>
            <li class="menu__item" @click="logoutUser">
                <span href="" class="menu__link">Logout<i class="icon icon_logout"></i></span>
            </li>
        </ul>
    </div>
</template>
<script>
import PushNotification from "../PushNotification.vue";

export default {
    name: "NavMenu",
    components: {PushNotification},
    props:[
        'myUrl',
        'memberId',
        'currentUser',
        'settingsInit',
        'supportUnread',
        'disabledNotification',
        'showNotificationButton',
        'onSubscribeNotification',
        'showBadgeWarning',
        'isSound'
    ],
    data(){
        return {
            isOpenInformation: false,
            isOpenSettings: false,
            selectedMode:'mode-light',
        }
    },
    mounted() {
        this.initSettings();
        this.$bus.$on('init_user_setting',this.initSettings);
        // this.$bus.$on('open_payment',this.openPaymentPage);
    },
    beforeDestroy() {
        this.$bus.$off('init_user_setting',this.initSettings);
        // this.$bus.$off('open_payment',this.openPaymentPage);
    },
    methods:{
        openPaymentPage() {
            if(this.isPromoPage())return
            console.log('openPaymentPage');
            if(this.$route.name !== 'PaymentPage') this.$router.push({name:'PaymentPage'});
        },
        openTariffsModal(){
            if(this.isPromoPage())return
            this.$emit('open_price')
        },
        openSupportChat(){
            if(this.isPromoPage())return
            this.$emit('open_support_chat');
        },
        initSettings(){
            console.log('initSettings');
            this.selectedMode = this.settingsInit.mode;
            this.changeMode(this.selectedMode);
            // this.colorTheme = this.settingsInit.color_theme;
            // this.changeColorTheme(this.colorTheme);
            // this.isSound = this.settingsInit.is_sound;
        },
        toggleSound() {
            if(this.isPromoPage())return
            this.$bus.$emit('change_setting',null,!this.isSound);
        },
        toggleMode(mode) {
            if(this.isPromoPage())return
            this.changeMode(mode);
            this.selectedMode = mode;
            this.saveSettings();
        },
        changeMode(mode){
            this.$bus.$emit('change_mode',mode)
            // document.body.classList.forEach(item=>{
            //     if(item.startsWith('mode')) {
            //         document.body.classList.remove(item) ;
            //     }
            // })
            // document.body.classList.add(mode)
        },
        saveSettings(){
            if(this.isPromoPage())return
            if(this.memberId && this.currentUser){
                axios.post(`/save-user-settings`,{
                    role: this.currentUser.role,
                    memberID: this.memberId,
                    mode: this.selectedMode,
                    color_theme: 'color-theme-1',
                    is_sound: false
                })
                    .then(response => {
                        console.log(response.data);
                        if (response.data.status === 'ok'){
                            this.$bus.$emit('change_setting',response.data.settings,this.isSound);
                            // alert('Settings saved')
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.errorHandler(e);
                    })
            }else {
                alert('You need to create a profile');
            }

        },
        gotoAccount(){
            if(this.isPromoPage())return
            console.log('gotoAccount');
            if(this.$route.name !== 'account')  this.$router.push({name: 'account'});
            this.closeMenu();
        },
        gotoSearch(){
          if(this.isPromoPage())return
          console.log('gotoSearch');
          this.$emit('open_search');
        },
        closeMenu(){
            console.log('closeMenu');
            this.$emit('close_menu')
        },
        logoutUser(){
            if(this.isPromoPage())return
            console.log('logoutUser')
            this.$bus.$emit('logout_user')
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        isPromoPage(){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat'){
                this.$bus.$emit('open_register')
                return true
            }else {return false}
        }
    }

}
</script>

<style scoped>

</style>
