<template>
    <div class="page__section">
        <div class="page__wrapper">
            <button class="button button_sm button button_bordered button_rounded button_presentation"
                    @click.prevent="showList=!showList">
                <span>
                    <span class="button__text">How can I use credits</span>
                    <span class="button__icon"><span><i class="icon icon_crypto"></i></span></span>
                </span>
            </button>
        </div>
        <list-with-credits v-if="showList || initShowList"></list-with-credits>
        <button class="button button_sm button_rounded button_white button_service" @click.prevent="openTariffsModal">
            <span>Service prices</span>
        </button>
    </div>
</template>
<script>
import ListWithCredits from "./ListWithCredits.vue";

export default {
    name: "SectionPaymentPage",
    components: {ListWithCredits},
    props: ['initShowList'],
    data() {
        return {
            showList: false
        }
    },
    methods: {
        openTariffsModal() {
            this.$bus.$emit('open_modal_price');
        },
    }
}
</script>

<style scoped>

</style>
