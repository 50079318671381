<template>
    <portal selector="#portal-target">
        <div class="modal modal_sm modal_block-user is-active"  tabindex="-1" role="dialog">
            <div class="modal__bg" @click.prevent="closeModal"></div>
            <div class="modal__container">
                <span class="modal__close"  role="button" aria-label="Close" @click.prevent="closeModal">
                    <i class="icon icon_close"></i>
                </span>
                <div class="modal__header">
                    <h2 class="modal__title">We’ll Be Back Soon!</h2>
                </div>
                <div class="modal__body">
                    <p>We apologize for any inconvenience caused, but we are currently performing essential maintenance.</p>
                    <p>If you need assistance during this time, please feel free to contact us at support@hablario.com.</p>
                    <p>We appreciate your patience and understanding and look forward to being back online shortly.</p>
                    <p v-if="!timeMaintenance">Try again after a while.</p>
                    <countdown-maintenance v-if="timeMaintenance"
                                           :deadline="timeMaintenance"
                    ></countdown-maintenance>
                    <p>— Best Regards,</p>
                    <p>The Hablario Team</p>
                </div>
                <div class="modal__footer">
                    <button class="button button_md button_primary" @click.prevent="closeModal">
                        <span>OK</span>
                    </button>
                </div>
            </div>
        </div>
    </portal>
</template>
<script>
import CountdownMaintenance from "../CountdownMaintenance.vue";

export default {
    name: "ModalMaintenance",
    components: {CountdownMaintenance},
    data(){
        return{
            timeMaintenance: null,
        }
    },
    mounted() {
        this.$bus.$emit('open_modal');
        this.getTimeMaintenance();
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
    },
    methods:{
        getTimeMaintenance(){
            console.log('getTimeMaintenance');
            axios.get('/get-time-maintenance')
                .then(response => {
                    console.log(response.data)
                    if(response.data.time_maintenance){
                        this.timeMaintenance = response.data.time_maintenance
                    }
                })
                .catch(e=>{
                    console.log(e)
                    // this.errorHandler(e)
                })
        },
        closeModal(){
            this.$emit('close_modal')
        },
    }
}
</script>


<style scoped>

</style>
