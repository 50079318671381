<template>
    <div class="card card_gallery" :class="{'is-blocked':isBlocked}">
        <span class="card__icon card__icon_new" v-if="$parent.isNewMember"><i class="icon icon_new"></i></span>
        <span class="card__icon card__icon_view" v-if="member.isViewOpponent"><i class="icon icon_view"></i></span>
        <div class="card__media poster poster_gallery">
            <picture v-if="member.desktop_gallery">
                <source type="image/webp" :srcset="`${s3Url}/${member.desktop_gallery_webp}`" v-if="isSupportedWebp && member.desktop_gallery_webp">
                <img class="poster__media" :src="`${s3Url}/${member.desktop_gallery}`" alt="gallery" width="770" height="398">
            </picture>

            <img class="poster__media poster__media_holder"
                 :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="400" height="400"
                 v-else>
            <div class="card__caption caption">
                <div class="person person_card">
                    <div class="person__info">
                                <span class="status status_online status_badge" v-if="member.online">
                                    <i class="status__icon"></i>
                                    Online
                                </span>
                        <span class="person__name">{{ member.name }}</span>
                        <span class="person__age person__age_inline" v-if="$parent.memberAge">{{
                                $parent.memberAge
                            }}</span>
                        <span class="person__icon"><i class="icon icon_zodiac"
                                                      :class="`icon_${$parent.getZodiacSign}`"></i></span>
                        <span class="person__location" v-if="member.country && member.country.countryName.length">
                                    <i class="icon icon_marker"></i>
                                    {{ getCountry() }}
                                </span>
                    </div>
                    <button class="button button_rounded button_favorite button_bordered"
                            :class="{'is-active':isFavorite}" @click.stop.capture="clickFavorite">
                                <span><span>Favorites</span>
                                <i class="button__icon icon"></i></span>
                    </button>
                </div>
            </div>
        </div>

        <div class="card__footer"
             v-if="member[`${roleOpponent}_portfolios`] && member[`${roleOpponent}_portfolios`].length >= 3">
            <div class="card__photo" v-for="(photo, el) in member[`${roleOpponent}_portfolios`].slice(0, 3)">
                <picture>
                    <source type="image/webp" :srcset="`${s3Url}/${photo.src_webp}`" v-if="photo.src_webp">
                    <img class="card__media" :src="`${s3Url}/${photo.src}`" alt="gallery-three" width="239"
                         height="170">
                </picture>
            </div>
        </div>
        <div class="card__footer" v-else>
            <div class="card__photo" v-for="n in 3">
                <img class="card__media card__media_holder"
                :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="239" height="170">
            </div>
        </div>
    </div>

</template>
<script>
export default {
    name: "GalleryItemDesktop",
    props: ['isBlocked', "member", "s3Url", "isFavorite", "myUrl", "roleOpponent", "isSupportedWebp"],
    computed: {},
    methods: {
        getCountry() {
            if (!this.member || !this.member.country) return '_'
            return this.member.country.countryName.length > 15 ? this.member.country.countryName.slice(15) : this.member.country.countryName
        },
        clickFavorite() {
            console.log('clickFavorite');
            this.$bus.$emit('start_change_favorite', !this.isFavorite, this.member.id)
        }
    }
}
</script>
<style scoped>

</style>

