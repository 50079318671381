<template>
    <div class="profile__card">
        <CoolLightBox
            :items="mediaStory"
            :index="indexMedia"
            :gallery="false"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @close="indexMedia = null">
        </CoolLightBox>
        <div class="profile__text-preview" v-if="!myStories || (myStories && !myStories.length)">
            <svg class="svg-icon" xmlns="http://www.w3.org/2000/svg" width="30" height="30">
                <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#information`" />
            </svg>
            <span>You have not provided this information about yourself</span>
        </div>
        <ul class="list profile__stories-list" v-else>
            <li class="list__item" :ref="`post${post.id}`" v-for="(post, index) in myStories" :key="post.id">
                <div class="post post_stories">
                    <div class="post__container">
                        <button  class="button button_edit" @click="editStory(post)"><i class="button__icon icon icon_edit"></i></button>
                        <div class="post__meta">
                            <div class="post__meta-data">
                                <span class="post__date">{{ new Date(post.created_at).toLocaleString() }}</span>
<!--                                    <span class="post__category">{{ post.interest_id ? post.interest.title : ''}}</span>-->
                            </div>
                        </div>
                        <h2 class="post__heading" :class="{'text-danger':post.hidden}">{{post.hidden ? post.subject+' (blocked Administration)': post.subject}}</h2>
                        <div class="post__poster poster poster_horizontal" v-if="!(post.isShowStory && $mq !=='desktop')">
                            <picture @click.prevent="viewPostComments(post)">
                                <source type="image/webp" v-if="isSupportedWebp && post.poster_webp" :srcset="`${s3Url}/${post.poster_webp}`">
                                <img class="poster__media" :src="`${s3Url}/${post.poster}`"
                                     alt="post media" width="810"
                                     height="488">
                            </picture>
                            <span class="button button_bg post__button" @click.prevent="viewPostComments(post)" v-if="post.type_src==='image'">
                                <i class="button__icon icon icon_camera-white"></i>
                            </span>
                            <span class="button button_bg post__button" @click.prevent="viewPostComments(post)" v-else-if="post.type_src==='video'">
                                <i class="button__icon icon icon_video-white"></i>
                            </span>
                        </div>
                        <div v-if="!post.viewComment" class="post__body" >
                            <div class="post__content">
                                <div class="post__short">
                                    <p class="post__short-text">{{ post.description }}</p>
                                    <span class="button button_link" @click.prevent="viewPostComments(post)" :ref="`buttonViewMore${post.id}`">View More</span>
                                </div>
                                <div class="post__info">
                                    <button class="button button_like"></button><span class="post__like">{{ post.counter_like }}</span>
                                    <span class="post__view"><i class="post__icon icon icon_eye"></i><span>{{ post.counter_visitors }}</span></span>
                                </div>
                            </div>
                        </div>
                        <div v-else class="post__body">
                            <div v-if="post.medias && post.medias.length && $mq ==='desktop'" class="slider slider_portfolio">
                                <div class="slider__container" :ref="`sliderContainer${post.id}`" tabindex="-1">
                                    <hooper
                                        :itemsToShow="3"
                                        :trimWhiteSpace="true"
                                        :itemsToSlide="1"
                                        :transition="500"
                                        :infiniteScroll="false"
                                        :playSpeed="5000"
                                        :ref="`sliderPortfolio_${post.id}`">
                                            <template v-if="post.type_src === 'image'">
                                                <slide class="slider__item" v-for="(media, el) in post.medias" :key="media.id">
                                                    <picture @click.prevent="showStoryMedia(post.medias,el,post.type_src)">
                                                        <source type="image/webp" :srcset="`${s3Url}/${media.src_webp}`" v-if="isSupportedWebp && media.src_webp">
                                                        <img class="slider__media" :src="`${s3Url}/${media.src}`" alt="" >
                                                    </picture>
                                                </slide>
                                            </template>
                                            <template v-if="post.type_src === 'video'">
                                            <slide class="slider__item" v-for="(media, el) in post.medias" :key="media.id">
                                                <button class="button button_video-play"
                                                        @click.stop.capture="showStoryMedia(post.medias,el,post.type_src)">
                                                    <span class="button__icon">
                                                        <span class="triangle triangle_r triangle_sm"></span>
                                                    </span>
                                                </button>
                                                <video class="slider__media" playsinline muted @click.prevent="showStoryMedia(post.medias,el,post.type_src)">
                                                    <source :src="`${s3Url}/${media.src_webp}`" type="video/webm" v-if="isSupportedWebp && media.src_webp"/>
                                                    <source :src="`${s3Url}/${media.src}`" type="video/mp4" />
                                                </video>
                                            </slide>
                                        </template>
                                    </hooper>

                                    <button class="slider__button slider__button_prev button" @click.prevent="slidePortfolioPrev(post.id)" v-if="post.medias.length > 3">
                                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                             height="12" fill="none">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                        </svg>
                                    </button>
                                    <button class="slider__button slider__button_next button" @click.prevent="slidePortfolioNext(post.id)" v-if="post.medias.length > 3">
                                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                             height="12" fill="none">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div v-if="post.medias && post.medias.length && $mq !=='desktop'" class="slider slider_portfolio">

                                <div class="slider__container" :ref="`sliderContainer${post.id}`" tabindex="-1">
                                    <hooper
                                        :transition="500"
                                        :wheelControl="false"
                                        :ref="`sliderPortfolio_`">
                                        <template v-if="post.type_src === 'image'">
                                            <slide class="slider__item" v-for="(photo, el) in post.medias" :key="photo.id" >
                                                <!--                                    @click.capture="index"-->
                                                <picture @click.prevent="showStoryMedia(post.medias,el,post.type_src)">
                                                    <source type="image/webp" :srcset="`${s3Url}/${photo.src_webp}`" v-if="isSupportedWebp && photo.src_webp">
                                                    <img class="slider__media" :src="`${s3Url}/${photo.src}`" alt="photo portfolio" >
                                                </picture>
                                            </slide>
                                        </template>
                                        <template v-if="post.type_src === 'video'">
                                            <slide class="slider__item" v-for="(media, el) in post.medias" :key="media.id">
                                                <button class="button button_video-play"
                                                        @click.stop.capture="showStoryMedia(post.medias,el,post.type_src)">
                                                    <span class="button__icon">
                                                        <span class="triangle triangle_r triangle_sm"></span>
                                                    </span>
                                                </button>
                                                <!--                                    @click.capture="index"-->
                                                <video class="slider__media" playsinline muted @click.prevent="showStoryMedia(post.medias,el,post.type_src)">
                                                    <source :src="`${s3Url}/${media.src_webp}`" type="video/webm" v-if="isSupportedWebp && media.src_webp"/>
                                                    <source :src="`${s3Url}/${media.src}`" type="video/mp4" />
                                                </video>
                                            </slide>
                                        </template>
                                        <hooper-pagination slot="hooper-addons"></hooper-pagination>
                                    </hooper>
                                </div>
                            </div>
                            <div class="post__content">
                                <div class="post__full">
                                    <p>{{ post.description }}</p>
                                </div>
                                <div class="post__info">
                                    <button class="button button_like"></button><span class="post__like">{{ post.counter_like }}</span>
                                    <span class="post__view"><i class="post__icon icon icon_eye"></i><span>{{ post.counter_visitors }}</span></span>
                                </div>
                            </div>
                            <div class="post__additional">
                                <div class="post__comments" v-if="post.story_comments && post.story_comments.length">
                                    <h4 class="title">Comments</h4>
                                    <ul class="post__comments-list list">
                                        <li class="post__comments-media" v-for="comment in post.story_comments" :key="comment.id">
                                            <img class="avatar avatar_sm" :src="`${s3Url}/${comment[roleOpponent].avatar}`" v-if="comment[roleOpponent].avatar"
                                                 @click.prevent="gotoProfile(comment[roleOpponent].id)" alt="">
                                            <div class="avatar avatar_sm avatar_holder" v-else>
                                                <img class="avatar__image" :src="`${myUrl}/source/images/icons/holder-user.svg`"
                                                     alt="holder" width="65" height="65" @click.prevent="gotoProfile(comment[roleOpponent].id)">
                                            </div>
                                            <div class="post__comments-body">
                                                <span class="post__comment-author">{{comment[roleOpponent].name}}</span>
                                                <span class="post__comment-date">{{writeDateFull(new Date(comment.created_at))}}</span>
                                                <p class="post__comment">{{comment.text}}</p>
                                            </div>
                                        </li>
                                        <li v-if="!post.isAllComments">
                                            <span class="button button_link" @click.prevent="getMoreComments(post)">View More Comments</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="post__form form">
                                    <button class="post__less button button_icon" @click.prevent="lessStory(post)">
                                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                             height="12" fill="none">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div >
                    </div>
                </div>
            </li>
<!--                class="end_stories_list"-->
            <li id="end_stories" class="end_stories_list">end</li>
        </ul>

    </div>
</template>

<script>
import StoryCreator from "../arhiv/StoryCreator.vue";

let observer = null;
let itemEnd = null;

const month = ['','January','February','March','April','May','June','July','August','September','October','November','December'];
export default {
    name: "MyStoriesEdit",
    components: {StoryCreator},
    props:[
        'myUrl',
        's3Url',
        'myRole',
        'memberID',
        'memberAvatar',
        'memberName',
        'roleOpponent',
        'fixHeader',
        'isOperator',
        'windowHeight',
        'countUnread',
        'isSupportedWebp',
        'isEdit',
        'myStories'
    ],
    data(){
        return{
            disabledButton:false,
            comments:[],
            unreadComments:[],
            visitors:0,
            image:null,
            interestID:null,
            subject:'',
            mediaSrc:'',
            typeSrc:'',
            description:'',
            noComment:false,
            showNotify: false,
            // showStoryCreator: false,
            isConversion:false,
            sizeVideo:20*1024*1024,
            sizeImage:5*1024*1024,
            sizeAudio:5*1024*1024,
            conversationUrl:'',
            isGetMoreStories:false,
            isAllStories:false,
            selectedPost: null,
            storyMedia: {
                file: null,
                preview: null
            },
            updateStoryMedia: {
                file: null,
                preview: null
            },
            formData:null,
            indexMedia: null,
            mediaStory: [],
        }
    },
    computed:{

    },
    created() {
        // TODO
        // document.addEventListener('click', this.handleOutsideClick);
        // document.addEventListener('touchstart', this.handleOutsideClick);
    },
    mounted() {
        console.log('mounted MyStoryEdit')
        this.initScrollObserver();

        // this.$bus.$on('get_my_stories',this.getMyStories);

        // ScrollTrigger.create({
        //     id: 'load',
        //     markers:true,
        //     trigger: this.$refs.myStoriesContainer,
        //     start: `top top`,
        //     end: `80% bottom`,
        //     onUpdate: (self) => {
        //
        //         if (self.scroller.pageYOffset.toFixed() > self.end && self.direction === 1) {
        //             console.log('scroll');
        //         }
        //     },
        // });
    },
    beforeDestroy() {
        // this.$bus.$off('get_my_stories',this.getMyStories);

        if (observer && itemEnd) {
            observer.unobserve(itemEnd)
            observer = null
            itemEnd = null
        }

    },
    // watch:{
    //     myStories(newValue,oldValue){
    //         if(newValue && newValue.length && !observer ) this.initScrollObserver();
    //     }
    // },
    methods:{
        lessStory(post){
            post.viewComment = false;
            this.$nextTick(()=>{
                if(this.$refs[`sliderContainer${post.id}`] && this.$refs[`sliderContainer${post.id}`][0])
                    this.$refs[`sliderContainer${post.id}`][0].focus()
                else console.log(this.$refs[`sliderContainer${post.id}`])
            })
            // :ref="`sliderContainer${post.id}`"
        },
        showStoryMedia(medias,index,type){
            console.log('showStoryMedia '+ index)
            this.mediaStory = medias.map(item => (type === 'video' ? {src: `${this.s3Url}/${item.src}`, autoplay: true}  : {src: `${this.s3Url}/${item.src}`}));
            this.indexMedia = index
        },
        initScrollObserver(){
            console.log('initScrollObserver');
            itemEnd = document.getElementById('end_stories');
            if(!itemEnd) {
                console.log('!itemEnd')
                return;
            }
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.1
            };
            observer = new IntersectionObserver(this.callbackObserver, options);
            observer.observe(itemEnd);
        },

        callbackObserver(entries, observer){
            console.log('callbackObserver');
            entries.forEach((entry, index) => {
                if (entry.isIntersecting && entry.target.id === `end_stories`){
                    if (!this.isAllStories) this.getMoreMyStory();
                }
            });
        },

        slidePortfolioPrev(post_id) {
            this.$refs[`sliderPortfolio_${post_id}`][0].slidePrev();
        },
        slidePortfolioNext(post_id) {
            this.$refs[`sliderPortfolio_${post_id}`][0].slideNext();
        },
        openMediaStory(media) {
          this.mediaStory[0] = {src: media}
          this.indexMedia = 0
        },
        openVideoStory(media) {
          this.mediaStory[0] = {src : media, autoplay: true}
          this.indexMedia = 0
        },
        // handleOutsideClick(event) {
        //     if (!(this.$refs.btnNewStory == event.target
        //         || this.$refs.btnNewStory.contains(event.target)
        //         || this.$refs.newStory == event.target
        //         || this.$refs.newStory.contains(event.target))
        //     ) {
        //         this.closeStory()
        //     }
        //     if (!(this.$refs.btnNotify == event.target
        //         || this.$refs.btnNotify.contains(event.target)
        //         || this.$refs.notify == event.target
        //         || this.$refs.notify.contains(event.target))
        //     ) {
        //         this.closeNotify()
        //     }
        // },
        openMenu() {
            this.$emit('open_menu', false);
        },
        goToRequest() {
            this.$emit('go_to_request',false);
        },
        onScrollComment(post){
            if (post && !post.isGetMoreComment && !post.isAllComments && post.story_comments.length &&
                this.$refs[`commentContainer${post.id}`][0] &&
                (this.$refs[`commentContainer${post.id}`][0].scrollTop + this.$refs[`commentContainer${post.id}`][0].clientHeight) >
                this.$refs[`commentContainer${post.id}`][0] .scrollHeight*0.8){
                console.log(this.$refs[`commentContainer${post.id}`][0].scrollTop,this.$refs[`commentContainer${post.id}`][0].clientHeight,this.$refs[`commentContainer${post.id}`][0] .scrollHeight)
                console.log('onScrollComment');
                post.isGetMoreComment = true;
                this.getMoreComments(post);
            }

        },
        getMoreComments(post){
            const lastID = post.story_comments[post.story_comments.length-1].id;

            axios.get(`/get-more-comments-for-my-story/${post.id}/${lastID}/${this.myRole}`)
                .then(response => {
                    console.log(response.data);
                    this.$emit('add_more_comments_to_story', {comments: response.data.comments, story_id: post.id})
                    // post.story_comments = response.data.comments;
                    // post.isAllComments = response.data.comments.length < 3 ;
                    // post.isGetMoreComment = false ;
                    // response.data.comments.forEach(comment=>{
                    //     post.story_comments.push(comment)
                    // });
                    // this.deleteReadComment(post.id);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        shortName(name){
            let arr = name.split(' ');
            let rez = '';
            arr.forEach(item=>{
                rez += item.charAt(0).toUpperCase();
            })
            return rez;
        },
        gotoProfile(OpponentID){
            if (this.isOperator){
                this.$bus.$emit('goto_chat_with_man',OpponentID)
            }else {
                this.$router.push({ name: 'profile', params: {id: OpponentID }});
            }

        },
        gotoComment(id){
            console.log('gotoComment');
            const findPost = this.myStories.find(post=>{
                return +post.id === +id;
            })
            if(findPost){
                if(!this.$refs[`buttonViewMore${id}`] || !this.$refs[`buttonViewMore${id}`][0]) return
                this.$refs[`buttonViewMore${id}`][0].click();
                setTimeout(()=>{
                    if(this.$refs[`comments_${id}`] && this.$refs[`comments_${id}`][0])
                        this.$refs[`comments_${id}`][0].focus();
                },200);

            }else {
                this.getStory(id);
            }

        },
        getStory(storyID){
            console.log('getStory');
            axios.get(`/get-my-story-by-id/${storyID}/${this.myRole}`)
                .then(response => {
                    console.log(response.data);
                    // post.story_comments = response.data.comments;
                    this.myStories.push(response.data.myStory);
                    this.deleteReadComment(storyID);
                    setTimeout(()=>{
                        if(this.$refs[`post${storyID}`] && this.$refs[`post${storyID}`][0]){
                            this.$refs[`post${storyID}`][0].classList.toggle('is-full');
                            this.$refs[`comments-${storyID}`][0].focus();
                        }
                        this.showNotify = false;
                    },500);

                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },

        getMoreMyStory(){
            if(this.myStories.length){
                this.isGetMoreStories = true;
                axios.get(`/get-more-my-stories/${this.myRole}/${this.memberID}/${this.myStories[this.myStories.length-1].id}`)
                    .then(response => {
                        console.log(response.data);
                        this.isGetMoreStories = false;
                        this.isAllStories = response.data.myStories.length < 3 ;
                        response.data.myStories.forEach(story=>{
                            const findPost = this.myStories.find(post=>{
                                return +post.id === +story.id;
                            })
                            if (!findPost){
                                this.$set(story,'viewComment',false);
                                this.$set(story,'story_comments',[]);
                                story.isGetMoreComment = false;
                                story.isAllComments = false;
                                this.myStories.push(story);
                            }
                        });
                    })
                    .catch(e => {
                        this.isGetMoreStories = false;
                        console.log(e);
                        this.errorHandler(e);
                    })
            }
        },
        updateSrc(post){
            // if(this.$refs[`update_${this.selectedPost.type_src}`]){
            //     this.reloadMediaSrc(this.$refs[`update_${this.selectedPost.type_src}`][0]);
            // }
            return this.updateStoryMedia.preview ? this.updateStoryMedia.preview : `${this.s3Url}/${post.src}`;
        },
        isCancelUpdate(post) {
            return this.selectedPost.subject===post.subject &&
                this.selectedPost.description===post.description &&
                this.selectedPost.noComment===!post.is_comment &&
                !(this.updateStoryMedia.file || this.conversationUrl);
        },
        closeEditStory(){
            this.selectedPost.id = null;
            this.selectedPost.subject = '';
            this.selectedPost.type_src = '';
            this.selectedPost.description = '';
            this.selectedPost.src = '';
            // this.selectedPost = {};

            this.updateStoryMedia.file = null;
            this.updateStoryMedia.preview = null;
        },
        updateMediaStory(event){
           console.log('updateMediaStory')
           console.log(event);

           const allowableSize = this.selectedPost.type_src === 'video'?this.sizeVideo:this.sizeImage
           this.getStoryMedia(event,this.updateStoryMedia,this.selectedPost.type_src,allowableSize);
           // const file = event.target.files[0];
           // this.updateStoryMedia =  {
           //     file: file,
           //     preview: URL.createObjectURL(file)
           // }
           const tag = this.$refs[`update_${this.selectedPost.type_src}`];
           // console.log(tag);
           if(tag){
               this.reloadMediaSrc(tag[0]);
           }

        },
        reloadMediaSrc(el){
            if (el){
                console.log(el);
                this.$nextTick(()=>{
                    setTimeout(()=>{
                        el.load();
                    },700);
                })
            }
        },
        // writeDate(date){
        //     const arr = date.split('.')
        //     return `${month[Number(arr[1])]} ${arr[0]}, ${arr[2]}`
        // },
        writeDateFull(date){
            const fullDate = new Date(date);
            return `${month[Number(fullDate.getMonth()+1)]} ${fullDate.getDate()}, ${fullDate.getFullYear()}`
        },
        // getMyStories(){
        //     if (!this.myStories.length){
        //         axios.get(`/get-my-stories/${this.myRole}/${this.memberID}`)
        //             .then(response => {
        //                 console.log('получили свои сторисы')
        //                 console.log(response.data);
        //                 this.visitors = response.data.visitors;
        //                 this.isAllStories = response.data.myStories.length < 3 ;
        //                 this.myStories = response.data.myStories;
        //                 // this.unreadComments = response.data.unreadComments;
        //                 this.myStories.forEach(post=>{
        //                     this.$set(post,'viewComment',false);
        //                     this.$set(post,'story_comments',[]);
        //                     post.isGetMoreComment = false;
        //                     post.isAllComments = false;
        //                 });
        //                 if (response.data.unreadComments.length){
        //                     response.data.unreadComments.forEach(comment=>{
        //                         const findComment = this.unreadComments.find(item=>{
        //                             return +item.my_story.id === +comment.my_story.id
        //                         })
        //                         if (findComment){
        //                             findComment.count++
        //                         }else {
        //                             this.$set(comment,'count',1);
        //                             this.unreadComments.push(comment);
        //                         }
        //                     });
        //                 }
        //                 this.$bus.$emit('open_stories');
        //             })
        //             .catch(e => {
        //                 console.log(e);
        //                 this.errorHandler(e);
        //             })
        //     }
        // },
        // closeViewPost(post){
        //     console.log('closeViewPost')
        //     console.log(post.id)
        //     this.$refs[`post${post.id}`][0].classList.toggle('is-full');
        // },

        viewPostComments(post) {
            console.log('viewPostComments')
            console.log(post.id)
            if(post.viewComment) return;
            // post.viewComment = true;
            axios.get(`/get-story-comments-opponent/${post.id}/${this.myRole}/${this.memberID}`)
                .then(response => {
                    console.log(response.data);
                    this.$emit('add_comments_to_story', {story_id: post.id, comments: response.data.comments})
                    // post.isAllComments = response.data.comments.length < 3;
                    // post.story_comments = response.data.comments;
                    // post.isGetMoreComment = false;
                    // this.showNotify = false;
                    // this.deleteReadComment(post.id);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        deleteReadComment(storyID){
            const index = this.unreadComments.findIndex(comment=>{
                return +comment.my_story.id === +storyID ;
            })
            if (index >= 0){
                if (this.unreadComments[index].count){
                    this.$bus.$emit('decrement_counter_comment',this.unreadComments[index].count,this.unreadComments[index].my_story.woman_id);
                }
                this.unreadComments.splice(index,1);
            }
        },
        editStory(post) {
            this.$router.push({name:'UpdateMyStory', params: {storyID: post.id}})
            // this.selectedPost = post
            // this.showStoryCreator = true;
            // this.$bus.$emit('open_modal');
        },
        updateStory(post) {
            this.disabledButton = true;
            this.formData = new FormData ();

            if(this.selectedPost.type_src === 'video' && this.updateStoryMedia.file){
                setTimeout(() => {
                    const videoPlayer  = this.$refs.update_video[0];
                    this.shoot(videoPlayer)
                        .then(() => {
                            console.log('poster created');
                            this.saveUpdateStory(post);
                        })
                }, 1000);
            }else {
                this.saveUpdateStory(post);
            }

        },
        saveUpdateStory(post){
            this.formData.append('id',this.memberID);
            this.formData.append('role',this.myRole);
            this.formData.append('subject',this.selectedPost.subject);
            this.formData.append('description',this.selectedPost.description);
            this.formData.append('file',this.updateStoryMedia.file);
            this.formData.append('storyID',this.selectedPost.id);
            this.formData.append('isComment',this.selectedPost.noComment?0:1);
            this.formData.append('conversationUrl',this.conversationUrl);

            axios.post(`/update-my-story`,this.formData)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data);
                    if (response.data.status === 'ok'){
                        if (response.data.src){
                            post.src = response.data.src;
                            if(this.selectedPost.type_src !== 'image' && this.$refs[`media_story_${post.id}`]){
                                const el = this.$refs[`media_story_${post.id}`][0]
                                this.reloadMediaSrc(el) ;
                            }
                        }
                        post.subject = this.selectedPost.subject;
                        post.description = this.selectedPost.description;
                        post.is_comment = !this.selectedPost.noComment;
                        this.closeEditStory();
                        this.resetData();
                        this.formData = null;
                    }else {
                        alert('My story is not updated')
                    }

                })
                .catch(e => {
                    this.disabledButton = false;
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        // publicationStory(){
        //     if (!this.disabledButton && this.subject && this.description && this.typeSrc && (this.storyMedia.file || this.conversationUrl) &&
        //         (this.interestID || !this.myStories.length)){
        //         this.disabledButton = true;
        //         this.formData = new FormData ();
        //
        //         if(this.typeSrc === 'video'){
        //             const videoPlayer  = this.$refs.storyVideo;
        //             setTimeout(() => {
        //                 this.shoot(videoPlayer)
        //                     .then(() => {
        //                         console.log('poster created');
        //                         this.saveStory();
        //                     })
        //             }, 1000);
        //         }else {
        //             this.saveStory();
        //         }
        //     }else {
        //         alert('All fields must be filled')
        //     }
        // },
        async shoot(videoPlayer) {
            // const videoPlayer  = document.getElementById('presentationVideo');
            console.log(videoPlayer);
            const canvas = this.captureVideo(videoPlayer);
            const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
            if (blob){
                this.formData.append("poster",blob,'poster');
            }else {
                console.error('poster not created')
            }

            // this.sendVideo(formData);
        },
        captureVideo(video){
            const w = video.videoWidth * 0.75 ;
            const h = video.videoHeight * 0.75;
            const canvas = document.createElement('canvas');
            canvas.width  = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, w, h);
            return canvas;
        },
        // saveStory(){
        //     this.disabledButton = true;
        //
        //     this.formData.append('id',this.memberID);
        //     this.formData.append('role',this.myRole);
        //     this.formData.append('subject',this.subject);
        //     this.formData.append('description',this.description);
        //     this.formData.append('typeSrc',this.typeSrc);
        //     this.formData.append('file',this.storyMedia.file);
        //     this.formData.append('interestID',this.interestID);
        //     this.formData.append('isComment',this.noComment?0:1);
        //     this.formData.append('conversationUrl',this.conversationUrl);
        //     this.formData.append('url',this.createUrl(this.subject));
        //
        //     axios.post(`/create-my-story`,this.formData)
        //         .then(response => {
        //             this.disabledButton = false;
        //             console.log(response.data);
        //             if (response.data.status === 'ok'){
        //                 this.showStoryCreator = false;
        //                 this.resetData();
        //                 this.myStories.unshift(response.data.myStory);
        //                 this.$set(this.myStories[0],'newComment','');
        //                 this.formData = null;
        //             }else {
        //                 alert('My story is not saved')
        //             }
        //         })
        //         .catch(e => {
        //             this.disabledButton = false;
        //             console.log(e);
        //             this.errorHandler(e);
        //         })
        // },
        // createUrl(text){
        //     const strArr = text.toLowerCase().split('') ;
        //     const isLetter = (code)=>{
        //         return (code > 47 && code < 58) || (code > 96 && code < 123)
        //     };
        //     let url = '/';
        //     let isChar = false;
        //     strArr.forEach(char=>{
        //         const code = char.charCodeAt(0);
        //         if(isLetter(code)){
        //             url = url + char;
        //             isChar = true
        //         }else {
        //             if (isChar){
        //                 url = url + '-';
        //             }
        //             isChar = false
        //         }
        //     })
        //     if(!isChar){
        //         url = url.slice(0,-1)
        //     }
        //     if(url===''){
        //         url = '/story';
        //     }
        //     console.log(url);
        //     return url
        // },
        resetData(){
            this.interestID = null;
            this.subject = '';
            this.mediaSrc = '';
            this.typeSrc = '';
            this.description = '';
            this.noComment =  false;
            this.storyMedia.file = null;
            this.storyMedia.preview = null;
            this.isConversion = false;
            this.conversationUrl = '';
        },
        deleteStory(id,index) {
            console.log('deleteStory'+index);
            axios.get(`/delete-my-stories/${id}`)
                .then(response => {
                    console.log(response.data);
                    this.myStories.splice(index,1);
                    this.deleteReadComment(id);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })


        },
        closeStoryCreator(){
            // this.showStoryCreator = false
            // this.$bus.$emit('close_modal');
            // this.selectedPost = null
        },
        getStoryVideo(event) {
            this.typeSrc = 'video';
            this.getStoryMedia(event,this.storyMedia,'video',this.sizeVideo);
        },
        getStoryImage(event) {
           this.typeSrc = 'image';
            this.getStoryMedia(event,this.storyMedia,'image',this.sizeImage);
        },
        getStoryAudio(event) {
            this.typeSrc = 'audio';
            this.getStoryMedia(event,this.storyMedia,'audio',this.sizeAudio);
        },
        convertPhoto(file,obj){
            // let fileOriginal = null;
            URL.revokeObjectURL(this.image.src);

            const canvas = document.createElement("canvas"),
                ctx = canvas.getContext("2d");
            canvas.width = this.image.width;
            canvas.height = this.image.height;
            ctx.drawImage(this.image, 0, 0);
            canvas.toBlob((blob) => {
                const fileOriginal = new File([blob], file.name, {
                    type: "application/octet-stream"
                });
                obj.file = fileOriginal;
                // jpegOriginal = canvas.toDataURL("image/jpeg", .95);
                // this.sendPhoto(fileOriginal);
            }, "image/jpeg", .95)
        },
        deleteStoryMedia() {
            this.typeSrc = '';
            this.conversationUrl = '';
            this.storyMedia = {
                        file: null,
                        preview: null
                    }
        },
        closeNotify() {
            this.showNotify = false
        },
        toggleNotify() {
            if (this.unreadComments.length || this.showNotify){
                this.showNotify = !this.showNotify
            }
        },
        closeStories() {
            console.log('closeStories');
            this.$emit('close_stories');
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    }
}
</script>

<style scoped>

.end_stories_list {
    position: absolute;
    visibility: hidden;
    height: 50px;
}

</style>

