<template>
    <div>
        <template v-for="blog in blogs">
            <div class="post post_blog"  v-if="typeBlog==='public'">
                <div class="post__meta">
                    <h3 class="post__title">{{blog.h1}}</h3>
                    <span class="post__info">
                <span class="post__date">{{writeDateFull(new Date(blog.created_at))}}</span>
                <span class="post__view"><i class="fe fe-eye"></i>{{blog.counter}}</span>
            </span>
                </div>
                <div class="post__media">
                    <a :href="`${myUrl}/blog/${blog.url}`" v-if="blog.type_src==='picture' || blog.type_src==='video'">
                        <img :src="`${cdnUrl}/${blog.src}`" alt="" v-if="blog.type_src==='picture'">
                        <img :src="`${cdnUrl}/${blog.poster}`" alt="" v-if="blog.type_src==='video'">
                        <!--                    <video controls="controls" controlslist="nodownload" poster="" playsinline v-if="blog.type_src==='video'">-->
                        <!--                        <source :src="`${cdnUrl}/${blog.src}`" type="video/mp4">-->
                        <!--                    </video>-->
                    </a>

                    <audio controls v-if="blog.type_src==='audio'">
                        <source :src="`${cdnUrl}/${blog.src}`"  type="audio/mp3">
                    </audio>
                </div>
                <div class="post__body">
                    {{blog.short_text}}
                    <a :href="`${myUrl}/blog/${blog.id}/${blog.url}`" class="link-more">view more</a>
                </div>
            </div>
            <div class="post post_blog post_members" v-else-if="blog && blog.woman && blog.woman.avatar && blog.url && blog.url !== '/'">
                <!--            <span>Add new story {{blog.id}}</span>-->
                <div class="post__meta">
               <span class="post__avatar" @click.prevent="gotoAccountWoman(blog.woman.id)" >
                   <img :src="`${cdnUrl+cdnDynamicDataPath}/${blog.woman.avatar}`" alt="">
                   <span>
                       <span class="post__author">{{blog.woman.name}}</span>
                       <span class="post__date">{{writeDateFull(new Date(blog.created_at))}}</span>
                   </span>
               </span>
                    <span class="post__info">
                    <span class="post__view"><i class="fe fe-eye"></i>{{blog.counter_visitors}}</span>
                </span>
                    <a class="post__heading" :href="`${myUrl}/member-story/${blog.id+blog.url}`">
<!--                        <h3 class="post__title">{{blog.subject}}</h3>-->
                        <h3 class="post__title">{{getSubject(blog.subject,blog.description)}}</h3>
                    </a>
                </div>
                <div class="post__media">
                    <a :href="`${myUrl}/member-story/${blog.id+blog.url}`" v-if="blog.type_src==='image' || blog.type_src==='video'">
                        <img :src="`${cdnUrl+cdnDynamicDataPath}/${blog.src}`" alt="" v-if="blog.type_src==='image'">
                        <img :src="`${cdnUrl+cdnDynamicDataPath}/${blog.poster}`" alt="" v-if="blog.type_src==='video'">
                    </a>

                    <audio controls v-if="blog.type_src==='audio'">
                        <source :src="`${cdnUrl+cdnDynamicDataPath}/${blog.src}`"  type="audio/mp3">
                    </audio>
                </div>
                <div class="post__body">
                    {{blog.description.substr(0, 100)+'...'}}
                    <a :href="`${myUrl}/member-story/${blog.id+blog.url}`" class="link-more">view more</a>
                </div>
            </div>
        </template>
    </div>

</template>

<script>
const month = ['','January','February','March','April','May','June','July','August','September','October','November','December'];
export default {
name: "AddNewBlog",
    props:['myUrl','cdnUrl','lastBlogIdInit','typeBlog','womanId','cdnDynamicDataPath','year','month'],
    data(){
        return{
            blogs:[],
            lastBlogId:0,
            isAllBlogs:false,
            isGet:false
        }
    },
    mounted() {
        this.lastBlogId = this.lastBlogIdInit;
        this.$bus.$on('get_new_blogs',this.getNewBlogs);
    },
    beforeDestroy() {
        this.$bus.$off('get_new_blogs',this.getNewBlogs);
    },
    methods:{
        gotoAccountWoman(womanID){
            this.$emit('goto_account_woman',womanID);
        },
        writeDateFull(date){
            const fullDate = new Date(date);
            return `${month[Number(fullDate.getMonth()+1)]} ${fullDate.getDate()}, ${fullDate.getFullYear()}`
        },
        getSubject(subject,text){
            const strArr = subject.toLowerCase().split('') ;
            let count = 0;
            strArr.forEach(char=>{
                const code = char.charCodeAt(0);
                if((code > 47 && code < 58) || (code > 96 && code < 123) || (code > 64 && code < 91))count++
            })
            if(count>2) return subject

            return text.length > 20 ? text.substr(0, 20)+'...' : text.substr(0, 20)
        },
        getNewBlogs(){
            if(!this.isGet && !this.isAllBlogs && this.lastBlogId > 0){
                this.isGet = true;
                const url = this.typeBlog==='public'?`/get-new-blogs/${this.lastBlogId}`:
                    this.typeBlog==='stories'?`/get-new-members-stories/${this.lastBlogId}`:
                        (this.typeBlog==='personal' && this.womanId)?`/get-new-member-stories/${this.womanId}/${this.lastBlogId}`:
                            (this.typeBlog==='stories_period' && this.year && this.month)?`/get-new-members-stories-period/${this.year}/${this.month}/${this.lastBlogId}`:'';
                axios.get(url)
                    .then(response => {
                        this.isGet = false;
                        console.log(response.data);
                        if (response.data.blogs.length){
                            this.blogs = this.blogs.concat(response.data.blogs);
                            this.lastBlogId = this.blogs[this.blogs.length-1].id;
                        }else {
                            this.isAllBlogs = true;
                        }
                    })
                    .finally(()=>{
                        this.isGet = false;
                    })
            }
        }
    }
}
</script>

<style scoped>

</style>
