import { render, staticRenderFns } from "./ModalPromo.vue?vue&type=template&id=3d67e3a3&scoped=true"
import script from "./ModalPromo.vue?vue&type=script&lang=js"
export * from "./ModalPromo.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d67e3a3",
  null
  
)

export default component.exports