<template>
    <div class="panel panel_success" :class="{'panel_mobile' : $mq !=='desktop'}" >
        <div class="panel__container">
            <div class="panel__header">
                <h1>Successfully <span>validated</span></h1>
            </div>
            <div class="panel__body">
                <p>You have successfully validated your mailbox.</p>
                <p>Thank you for your understanding.</p>
            </div>
            <div class="panel__footer">
                <button class="btn btn_confirm" @click="closePage">Close page</button>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "SuccessValidationEmail",
    props:[],
    data(){
        return{
            isSubscribe: false
        }
    },
    created() {
        console.log('created from SuccessValidationEmail')
    },
    methods:{
        closePage(){
            // if ( confirm('This page will be closed')) close()
            //     window.open(window.location, '_self').close();
                window.close();
        },
        logout(){
            this.$emit('logout');
        },
    }
}
</script>

<style scoped>
</style>

