<template>
    <div class="form form_horizontal">
        <div class="form__row" :class="{'is-error':!chatOwner.country_id}">
            <label class="form__label">Country</label>
            <select-country ref="selectCountry"
                :countries="countries"
                @selected_country="selectedCountry"
            ></select-country>
        </div>
        <div class="form__row">
            <label for="live_in" class="form__label">Live in</label>
            <input id="live_in" v-model="selectParam.live_in" type="text" class="ui-input" maxlength="50" placeholder="Enter text">
        </div>
        <div class="form__row">
            <label class="form__label" for="work">Work As</label>
            <input id="work" type="text" class="ui-input" maxlength="255" v-model="selectParam.work" placeholder="Enter text">
        </div>
        <div class="form__row">
            <label for="education" class="form__label">Education</label>
            <select id="education" class="ui-select" v-model="selectParam.education">
                <option value="" hidden>What's your education level?</option>-->
                <option v-for="education in educations" :value="education">{{education}}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="lang" class="form__label">Languages</label>
            <select id="lang" class="ui-select" v-model="selectParam.lang">
                <option value="" hidden>What languages do you know?</option>-->
                <option v-for="lang in languages" :value="lang">{{ lang }}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="status" class="form__label">Status</label>
            <select id="status" class="ui-select" v-model="selectParam.marital_status">
                <option value="" hidden>What's your relationship status?</option>-->
                <option v-for="status in marital_statuses" :value="status">{{status}}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="kids" class="form__label">Kids</label>
            <select id="kids" class="ui-select" v-model="selectParam.kids">
                <option value="" hidden>Do you have kids?</option>-->
                <option v-for="kid in kids" :value="kid">{{kid}}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="body" class="form__label">Body</label>
            <select id="body" class="ui-select" v-model="selectParam.body_type">
                <option value="" hidden>What's your body type?</option>-->
                <option v-for="body_type in body_types" :value="body_type">{{body_type}}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="eyes" class="form__label">Eyes</label>
            <select id="eyes" class="ui-select" v-model="selectParam.eye_color">
                <option value="" hidden>What's your eye color?</option>-->
                <option v-for="eye_color in eye_colors" :value="eye_color">{{eye_color}}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="hair" class="form__label">Hair</label>
            <select id="hair" class="ui-select" v-model="selectParam.hair_color">
                <option value="" hidden>What's your hair color?</option>-->
                <option v-for="hair_color in hair_colors" :value="hair_color">{{hair_color}}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="smoke" class="form__label">Smoke</label>
            <select id="smoke" class="ui-select" v-model="selectParam.smoke">
                <option value="" hidden>Do you smoke?</option>-->
                <option v-for="smoke in smokes" :value="smoke">{{smoke}}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="drink" class="form__label">Drink</label>
            <select id="drink" class="ui-select" v-model="selectParam.drink">
                <option value="" hidden>Do you drink?</option>-->
                <option v-for="drink in drinks" :value="drink">{{drink}}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="height" class="form__label">Height</label>
            <select id="height" class="ui-select" v-model="selectParam.height">
                <option value="" hidden>How tall are you?</option>-->
                <option v-for="height in heights" :value="height">{{height}}</option>
            </select>
        </div>
        <div class="form__row">
            <label for="weight" class="form__label">Weight</label>
            <input id="weight" class="ui-input" type="number" min="20" max="300"
                   placeholder="How much do you weight?" v-model="selectParam.weight">
        </div>

        <select-birthday
            :err-birthday="errBirthday"
            :init-birthday="selectParam.birthday"
            :show-badge-warning="!chatOwner.birthday"
            @set_birthday="setBirthday"
        ></select-birthday>

        <div class="form__footer">
            <button class="button button_md button_primary" @click.prevent="saveMySelf" :disabled="disabledButton">
                <span>Save</span></button>
        </div>
    </div>
</template>

<script>

import SelectBirthday from "../newModules/SelectBirthday.vue";
import SelectCountry from "../modules/SelectCountry.vue";

const checkMonth = {
    January:31,
    February:29,
    March:31,
    April:30,
    May:31,
    June:30,
    July:31,
    August:31,
    September:30,
    October:31,
    November:30,
    December:31
}
export default {
name: "MySelfEdit",
    components: {SelectCountry, SelectBirthday},
    props:[
        'disabledButton',
        'profileInit',
        'countries',
        'windowHeight',
        'chatOwner'
    ],
    data() {
        return {
            educations: ['high school', 'college', 'associated degree', 'bachelors degree', 'graduate degree', 'PhD', 'other'],
            languages: ['English', 'Russian', 'Spanish', 'Portuguese', 'Italian', 'German', 'French', 'Georgian', 'Armenian', 'Arabic', 'Turkish'],
            marital_statuses: ['Married', 'Never Married', 'Separated', 'Divorced', 'Widowed', 'other'],
            kids: ['Yes', 'No'],
            smokes: ['Never', 'Occasionally', 'Regularly', 'other'],
            drinks: ['Never', 'Yes', 'Occasionally', 'Regularly', 'other'],
            heights: ['140-150', '150-160', '160-160', '160-165', '165-170', '170-175', '175-180', '180-185', '185-190', '190-195', '195-200', '200-205', '205-210', '210-215', '215+', 'other'],
            body_types: ['Average', 'A few extra pounds', 'Athletic', 'Muscular', 'Slim', 'other'],
            eye_colors: ['Blue', 'Brown', 'Green', 'Gray', 'Hazel', 'Green-brown', 'Gray-Green', 'other'],
            hair_colors: ['Black', 'Blond', 'Brown', 'Fair', 'Chestnut', 'Ginger', 'Bald', 'other'],
            preference_persons: ['Woman Looking for a Man', 'Man looking for a Woman'],
            country:null,
            selectParam:{
                weight:'',
                work:'',
                live_in:'',
                birthday:'',
                education: '',
                lang: '',
                marital_status: '',
                kids: '',
                smoke: '',
                drink: '',
                height: '',
                body_type: '',
                eye_color: '',
                hair_color: '',
                preference_person: '',
            },
            errBirthday:'',
            months: ['January','February','March','April','May','June','July','August','September','October','November','December'],
        }
    },
    mounted() {
        if(this.profileInit){
            this.setProfile(this.profileInit, this.chatOwner.country_id);
        }
    },
    methods:{
        clickPage(){
            if(this.$refs.selectCountry) this.$refs.selectCountry.closeOptions();
        },
        selectedCountry(selectCountry){
            this.country = selectCountry;
        },
        setBirthday(data){
            console.log('setBirthday')
            console.log(data)
            let month = this.getMonthNumber(data.month);
            this.selectParam.birthday = `${data.year}-${month}-${data.date}`
            console.log((this.selectParam.birthday))
        },

        getMonthNumber(monthLetter){
            let monthNumber
            this.months.forEach((month, index) => {
                if (month === monthLetter) monthNumber = index+1
            })
            return monthNumber
        },

        setProfile(profile,countryID){
            console.log('setProfile');

            ({...this.selectParam} = profile);
            if (this.chatOwner && this.chatOwner.birthday) this.selectParam.birthday = this.chatOwner.birthday
            // {
            //     const date = new Date(profile.birthday);
            //     const day = date.getDate();
            //     const month = date.getMonth()+1;
            //     this.selectParam.birthday = `${date.getFullYear()}-${month>9?month:'0'+month}-${day>9?day:'0'+day}`;
            //     // this.selectParam.birthday = new Date(profile.birthday);
            // }
            if (countryID){
               const findCountry = this.countries.find(country=>{
                   return +country.id === +countryID;
               });
               if(findCountry){
                   console.log(findCountry);
                   this.country = findCountry;
                   if(this.$refs.selectCountry) this.$refs.selectCountry.setCountry(this.country.id)
               }
            }
        },

        saveMySelf(){
            if (this.selectParam.birthday && isNaN(Date.parse(this.selectParam.birthday))){
                alert('Date of birth entered incorrectly. Enter the date in the format 2000-12-31');
            }else {
                if(this.selectParam.birthday && typeof this.selectParam.birthday === 'string')this.selectParam.birthday = this.selectParam.birthday.split('T')[0]

                this.$emit('save_my_self',this.selectParam,this.country);
                this.closeModalMyself();
            }
        },
        closeModalMyself(){
            this.$emit('close_my_self_edit',this.selectParam);
        },
    }
}
</script>

<style scoped>

</style>
