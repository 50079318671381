<template>
    <div class="chat__body" ref="chatContainer" @scroll.passive="onScroll">

        <span class="chat__body-overlay"  @click.stop.capture="closeAllOptionMenu" v-if="$mq!=='desktop' && showOptionMenu"></span>
        <ul class="chat__list" >
            <template v-for="(messageArr, date) in messages" >
                <li class="text-center mb-2">
<!--                    19 December, 2022-->
                    <span class="chat__date">{{writeDate(date)}}</span>
                </li>
                <li class="chat__item" :class="classMessage(message)"  v-for="message in messageArr" :key="message.id">
<!--                    <div class="chat__avatar" v-if="isOpponentMessage(message) && !message.hidden">
                        <picture v-if="avatar">
                            <source type="image/webp" :srcset="`${s3Url}/${avatar_webp}`" v-if="avatar_webp">
                            <img class="avatar avatar_xs" :src="`${s3Url}/${avatar}`" alt="avatar user" width="40" height="40">
                        </picture>
                        <div class="avatar avatar_sm avatar_holder" v-else>
                            <img class="avatar__image" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="65" height="65">
                        </div>
                    </div>-->
                    <div class="chat__message-menu dropdown dropdown_md dropdown_right" v-if="!isOpponentMessage(message) && message.isOptionMenu">
                        <ul class="dropdown__menu">
                            <li class="dropdown__item" @click.prevent="editMessage(message)" v-if="isEditMessage(message)">
                                <span class="dropdown__link"><i class="icon icon_edit"></i> Edit <span>message</span></span>
                            </li>
                            <li class="dropdown__item" @click.prevent="deleteMessage(message.id,message.isReadOpponent)">
                                <span class="dropdown__link"><i class="icon icon_delete"></i> Delete <span>message</span></span>
                            </li>
                        </ul>
                    </div>
                    <template v-if="message.type === 'emoji' || message.type === 'gif'">
                         <div class="message">
                             <img :src="`${s3Url}/${message.src}`" alt="" v-if="message.type === 'gif'">
                             <img :src="`${myUrl}/assets/emojis/${message.src}`" alt="" v-else-if="message.type === 'emoji'">
                            <check-read-opponent
                                :my-url="myUrl"
                                :time="message.created_at"
                                :is-read-opponent="isOpponentMessage(message) || message.isReadOpponent"
                                v-if="message.type!=='letter' && message.type!=='voice' && message.type!=='emoji' && message.type!=='gif'"
                            ></check-read-opponent>
                         </div>
                    </template>
                    <template v-else-if="message.type === 'donate' || message.type === 'link' || message.type === 'gift'">
                        <span class="message message_promo">
                            <chat-item-donate
                                v-if="message.type === 'donate'"
                                :my-url="myUrl"
                                :message="message"
                                :message-size-rem="messageSizeRem"
                            ></chat-item-donate>

                            <chat-item-link
                                v-if="message.type === 'link'"
                                :message="message"
                                :message-size-rem="messageSizeRem"
                            ></chat-item-link>

                            <chat-item-gift
                                v-if="message.type === 'gift'"
                                :message="message"
                                :s3-url-static="s3UrlStatic"
                            ></chat-item-gift>
                             <check-read-opponent
                                 :my-url="myUrl"
                                 :time="message.created_at"
                                 :is-read-opponent="isOpponentMessage(message) || message.isReadOpponent"
                             ></check-read-opponent>
                        </span>
                    </template>
                    <template v-else>
                        <!--                   message_color1 message_base message_letter message_audio message_media-->

                            <div class="message" :class="getMessageClass(message.type,message.src_mini)" :style="getMessageStyle(message.type, message.src_mini, message.src_mini_webp)">
<!--                            <svg class="message__triangle" xmlns="http://www.w3.org/2000/svg" width="12" height="20" fill="none" v-if="!message.hidden">-->
<!--                                <path fill="currentColor" d="m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z"/>-->
<!--                            </svg>-->
                            <button class="chat__options button button_options" @click.stop.capture="toggleOptionMenu(message)" v-if="!isOpponentMessage(message)">
                                <span></span>
                            </button>

                            <template v-if="message.hidden">
                                <span class="message__text message__text_info">
                                    <i class="icon icon_block-user"></i>
                                    The message was blocked due to violations of the rules of the service.
                                    {{message.paid ? 'Credits were refunded to your balance.': ''}}
                                </span>
                            </template>
                            <template v-else-if="message.type === 'message'">
                                <span class="message__text" :style="`font-size: ${messageSizeRem}rem`">{{message.message}}</span>
                            </template>

                            <template v-else-if="message.type === 'letter'">
                                 <span class="message__text" @click.prevent="readLetter(message)">
                                    <span class="message__caption">
                                        <span class="message__icon">
                                            <i class="icon icon_letter-open" v-if="message.paid"></i>
                                            <i class="icon icon_letter-white" v-else></i>
<!--                                            Letter:-->
                                        </span>
                                        <span class="message__preview" :style="`font-size: ${messageSizeRem}rem`">{{ message.message }}</span>
                                    </span>
                                 </span>
                                 <div class="message__footer">
                                    <button class="button button_link" v-if="message.author === roleOpponent && !message.paid"
                                            :disabled="disabledButton" @click.prevent="readLetter(message)">buy
                                        <span>&</span>read
                                    </button>
                                    <check-read-opponent
                                        :my-url="myUrl"
                                        :time="message.created_at"
                                        :is-read-opponent="isOpponentMessage(message) || message.isReadOpponent"
                                    ></check-read-opponent>
                                 </div>
                            </template>
                            <template v-else-if="message.type === 'voice'">
                                 <span class="message__audio">
                                    <!--add class button is-paid if it is needs to pay-->
                                    <button class="button button_audio-play" :class="{'is-play':!message.audioPlay, 'is-pause': message.audioPlay,'is-paid':(!message.paid && message.author === roleOpponent)}"
                                             @click.prevent="playAudio(message)" :disabled="(!message.paid && message.author===roleOpponent)">
                                      <svg class="button__icon button__icon_play" xmlns="http://www.w3.org/2000/svg" width="16" height="18" v-if="!message.audioPlay">
                                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#play`" />
                                      </svg>
                                      <svg class="button__icon button__icon_pause" xmlns="http://www.w3.org/2000/svg" width="16" height="18" v-else>
                                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#pause`" />
                                      </svg>
                                    </button>
                                    <span class="audio" :id="`wavesurfer_${message.id}`"></span>
                                 </span>
                                 <span class="message__footer">
                                     <button class="button button_link js-start-audio" @click.prevent="buyAttachmentMessage(message,'voice')" v-if="!message.paid && message.author === roleOpponent">
                                        paid<span>&</span>listen
                                     </button>
                                     <span class="message__duration">{{message.duration}}</span>
                                     <check-read-opponent
                                         :my-url="myUrl"
                                         :time="message.created_at"
                                         :is-read-opponent="isOpponentMessage(message) || message.isReadOpponent"
                                     ></check-read-opponent>
                                 </span>
                            </template>
                            <template v-else-if="(message.type === 'clip' || message.type === 'picture') && message.author === roleOpponent && !message.paid">
                                <span class="message__thumb" @click.prevent="buyAttachmentMessage(message,message.type)">
                                    <img class="is-blur" :src="`${s3Url}/${message.src_mini}`" alt="paid media">
                                    <span class="message__overlay">
                                         <span class="message__content">
                                            <span class="message__icon icon icon_lock"></span>
                                            <span class="message__title">Paid viewing</span>
                                            <button class="message__button button button_sm button_primary"><span>Show Now</span></button>
                                         </span>
                                    </span>
                                </span>
                            </template>
                            <template v-else-if="message.type === 'picture' && (message.author !== roleOpponent || message.paid)">
                                 <span class="message__thumb" @click="openChatMedia({src: `${s3Url}/${message.src}`, type: 'picture'})">
                                     <img :src="`${s3Url}/${message.src_mini}`" alt="picture">
                                </span>
                            </template>
                            <template v-else-if="message.type === 'clip' && (message.author !== roleOpponent || message.paid)">
                                <span class="message__thumb" @click="openChatMedia({src:`${s3Url}/${message.src}`, type: 'clip'})">
    <!--                                 <video class="video" controls="controls" controlslist="nodownload"  playsinline loop-->
    <!--                                        :poster="`${s3Url}/${message.src_mini}`" v-if="message.src_mini">-->
    <!--                                     <source :src="`${s3Url}/${message.src}`" type="video/mp4">-->
    <!--                                 </video>-->
                                    <img :src="`${s3Url}/${message.src_mini}`" alt="poster" v-if="message.src_mini">
                                    <video class="video" controls="controls" controlslist="nodownload"  playsinline loop v-else>
                                         <source :src="`${s3Url}/${message.src}`" type="video/mp4">
                                     </video>
                                    <span class="message__overlay">
                                        <button class="button button_video-play"><span class="button__icon"><span class="triangle triangle_r triangle_sm"></span></span></button>
                                    </span>

                                </span>
                            </template>



                            <div class="message__footer" v-if="message.type!=='letter' && message.type!=='voice' && message.type!=='emoji' && message.type!=='gif'">
                                <template v-if="message.type === 'message'">
                                    <button class="button button_white button_rounded button_sound is-animated" :id="`speech_btn_${message.id}`"
                                            @click.prevent="stopSpeech(message)" v-if="message.speechStatus === 'speech'">
                                        <i class="button__icon icon icon_sound"></i>
                                        <span class="button__wave">
                                        <span class="button__circle"></span>
                                        <span class="button__circle"></span>
                                        <span class="button__circle"></span>
                                    </span>
                                    </button>
                                    <button class="button button_white button_rounded button_sound" :id="`speech_btn_${message.id}`"
                                            @click.prevent="messageToSpeech(message)" :class="{disabled: message.speechStatus==='load'}" v-else>
                                        <i class="button__icon icon icon_sound"></i>
                                        <span class="button__wave">
                                        <span class="button__circle"></span>
                                        <span class="button__circle"></span>
                                        <span class="button__circle"></span>
                                    </span>
                                    </button>
                                </template>



                            <check-read-opponent
                                :my-url="myUrl"
                                :time="message.created_at"
                                :is-read-opponent="isOpponentMessage(message) || message.isReadOpponent"
                                v-if="message.type!=='letter' && message.type!=='voice' && message.type!=='emoji' && message.type!=='gif'"
                            ></check-read-opponent>
                              </div>
                        </div>
                    </template>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>

import CheckReadOpponent from "../newModules/CheckReadOpponent.vue";
import WaveSurfer from 'wavesurfer.js'
import ChatItemLink from "../newModules/ChatItemLink.vue";
import ChatItemGift from "../newModules/ChatItemGift.vue";
import ChatItemDonate from "../newModules/ChatItemDonate.vue";

const messageClass = {
    message: 'message_base',
    letter: 'message_base message_letter',
    voice: 'message_base message_audio',
    clip: 'message_media',
    picture: 'message_media'
}
let audio = null;
let isStartSpeech = false;
const month = ['','January','February','March','April','May','June','July','August','September','October','November','December'];
export default {
    name: "ChatLog",
    components: {ChatItemDonate, ChatItemGift, ChatItemLink, CheckReadOpponent},

    props:[
        'myUrl',
        's3Url',
        'messages',
        'memberId',
        'roleOpponent',
        'isAllMessages',
        // 'chatBg',
        'messageColor',
        'messageSize',
        'disabledButton',
        'avatar',
        'avatar_webp',
        's3UrlStatic',
        'cdnLetterImages',
        'isSupportedWebp'
    ],
    data() {
        return {
            showOptionMenu: false,

        }
    },
    computed:{
        messageSizeRem(){
            const windowWidth = window.innerWidth || document.documentElement.clientWidth;
            if( windowWidth >= 1920){
                return this.messageSize/14;
            }

            return this.messageSize/16;
        },
    },
    mounted() {
        this.$bus.$on('chat_scroll_down', this.scrollDown);
        this.$bus.$on('close_message_drop_menu', this.closeMessageDropMenu);
        this.scrollDown();
        this.$nextTick(()=>{
            this.initWavesurfer();
        })

        // this.$bus.$emit('init_wavesurfer')

    },
    beforeDestroy() {
        this.$bus.$off('chat_scroll_down', this.scrollDown);
        this.$bus.$off('close_message_drop_menu', this.closeMessageDropMenu);
        this.destroyWavesurfer()
        this.removeSpeechAudio(true)
    },
    methods:{
        removeSpeechAudio(isDestroy){
            if( audio ){
                audio.pause()
                audio.remove()
            }
            audio = null;
            isStartSpeech = false
            if(!isDestroy){
                for (let key in this.messages) {
                    this.messages[key].forEach((messageItem)=>{
                        if(messageItem.type==='message'){
                            messageItem.speechStatus = ''
                        }
                    })
                }
            }
        },
        stopSpeech(message){
            console.log('stopSpeech')
            if(audio){
                audio.pause()
                audio.remove()
                audio = null
            }
            message.speechStatus = '';
            isStartSpeech = false
        },
        messageToSpeech(message){
            console.log('messageToSpeech')
            if(isStartSpeech || message.speechStatus) return;
            isStartSpeech = true;
            message.speechStatus = 'load'
            audio = new Audio();
            axios.post('/chat-message-to-speech',{
                memberId: this.memberId,
                messageId: message.id,
                role: message.author,
                text: message.message,
                is_origin: true
            })
                .then(response => {
                    console.log(response.data) ;
                    const btn = document.getElementById(`speech_btn_${message.id}`)
                    if(btn && response.data && response.data.status === 'ok' && response.data.speech){
                        audio.src = `${this.s3Url}/${response.data.speech.src}`
                        audio.load()
                        audio.oncanplay = (event) => {
                            console.log(event);
                            message.speechStatus = 'speech';
                            audio.play();
                        };
                        audio.onerror = (event) => { this.stopSpeech(message) };
                        audio.onended = (event) => { this.stopSpeech(message) };
                    }
                })
                .catch(err => {
                    console.log(err);
                    isStartSpeech = false;
                    message.speechStatus = '';
                    this.errorHandler(err);
                })
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        getMessageStyle(type,src,src_webp){
            if( type === 'letter' && src) return {'background-image': `url(${this.cdnLetterImages}/${this.isSupportedWebp && src_webp?src_webp:src})`}
            return {}
        },
        playAudio(message){
            if(message.wavesurfer && (message.paid || message.author !== this.roleOpponent)) {
                if(message.audioPlay){
                    this.stopAllAudio();
                    message.wavesurfer.un('finish' , ()=>{ message.audioPlay = false})
                    setTimeout(()=>{
                        message.audioPlay = false;
                    },50)
                }else {
                    this.stopAllAudio();
                    message.wavesurfer.play();
                    message.audioPlay = true;
                    message.wavesurfer.once('finish' , ()=>{ message.audioPlay = false})
                }
            }
        },
        stopAllAudio(){
            for (let key in this.messages) {
                this.messages[key].forEach((messageItem)=>{
                    if(messageItem.type==='voice' && messageItem.audioPlay && messageItem.wavesurfer){
                        messageItem.wavesurfer.pause();
                        messageItem.audioPlay = false;
                    }
                })
            }
        },
        destroyWavesurfer(){
            for (let key in this.messages) {
                this.messages[key].forEach((messageItem)=>{
                    if(messageItem.type==='voice' && messageItem.wavesurfer){
                        messageItem.wavesurfer.destroy()
                        messageItem.wavesurfer = null;
                        messageItem.audioPlay = false;
                    }
                })
            }
        },
        initWavesurfer(){
            for (let key in this.messages) {
                this.messages[key].forEach((messageItem)=>{
                    this.$set( messageItem, 'speechStatus', '')
                    if(messageItem.type==='voice'){
                        this.initWavesurferItem(messageItem)
                    }
                })
            }
        },
        initWavesurferItem(message){
            console.log('initWavesurferItem');
            if(message.wavesurfer) return;
            const span = document.getElementById(`wavesurfer_${message.id}`);
            if(!span) {
                console.log('!spam');
                return;
            }
            message.wavesurfer = WaveSurfer.create({
                container: span,
                height: 40,
                waveColor: '#A8A8A8',
                progressColor: '#131313',
                barRadius: 3,
                barWidth: 1,
                barGap: 2,
                backend: 'MediaElement',
                cursorWidth: 0,
                mediaControls: false,
                url: message.src_mini? `${this.s3Url}/${message.src_mini}`:`${this.s3Url}/${message.src}`
            });
            message.wavesurfer.once('ready', (duration) => {
                console.log({duration})
                this.$set(message, 'duration', this.timeFormat(duration));
            })

            // message.wavesurfer.load(`${this.s3Url}/${message.src}`);
        },
        closeMessageDropMenu(){
            console.log('closeMessageDropMenu');
            if(!this.messages) return

            this.closeAllOptionMenu();
        },
        getMessageClass(type,src){
            //message_base message_letter message_audio message_media message_letter-images
            if( type === 'letter' && src) return 'message_base message_letter-images'
            return messageClass[type] ? `${this.messageColor} ${messageClass[type]}` : this.messageColor;
        },
        isEditMessage(message){
          return message.type === 'message' || message.type === 'letter';
        },
        toggleOptionMenu(message) {

            const isOptionMenu = message.isOptionMenu;
            console.log(`toggleOptionMenu message id:${message.id}`)
            console.log(message)
            this.closeAllOptionMenu()
            if(!isOptionMenu) {
                message.isOptionMenu = true;
                this.showOptionMenu = true;
            }
            // message.isOptionMenu = !isOptionMenu;
        },
        closeAllOptionMenu(){
            console.log('closeAllOptionMenu')
            this.showOptionMenu = false;
            for (let key in this.messages) {
                this.messages[key].forEach((messageItem)=>{
                    messageItem.isOptionMenu = false;
                })
            }
        },
        deleteMessage(id,isRead) {
            console.log(`delete message id:${id}`)
            this.$bus.$emit('delete_chat_message',id,isRead);
        },
        editMessage(message) {
            console.log(`edit message id:${message.id}`);
            this.closeAllOptionMenu()
            this.$emit('edit_chat_message',message)
        },
        buyAttachmentMessage(message,type){
            if(this.disabledButton) return;
            this.$emit('buy_attachment_message',message,type)
        },
        readLetter(message){
            this.removeSpeechAudio(false);
            if (message.paid || message.author !== this.roleOpponent){
                this.$emit('read_letter',message);
            }else {
                this.buyAttachmentMessage(message,'letter')
            }

        },
        openChatMedia(data){
            // this.$emit('open_media', data);
            this.$bus.$emit('open_chat_media',data)
        },
        onScroll(event){
            if (this.$refs.chatContainer) {
                if (this.showBtnDown !== ((this.$refs.chatContainer.scrollTop+this.$refs.chatContainer.clientHeight) < this.$refs.chatContainer.scrollHeight)){
                    this.showBtnDown = this.$refs.chatContainer.scrollTop+this.$refs.chatContainer.clientHeight < this.$refs.chatContainer.scrollHeight
                    this.$emit('show_btn_down',this.showBtnDown) ;
                }
            }
            if (!this.isAllMessages){
                if (this.$refs.chatContainer && this.$refs.chatContainer.scrollTop === 0){
                    console.log('scroll top');
                    this.$bus.$emit('more_messages',this.$refs.chatContainer)
                }
            }
            //block for changing bg-position on chat message (gradient)
            let chatBody = document.querySelector('.chat__body')
            let root = document.getElementsByClassName('message_color1')
            for(let r of root){
                if(r.getClientRects().length && r.getClientRects()[0].top > 0 && r.getClientRects()[0].top < chatBody.offsetHeight){
                    let yPer = r.getClientRects()[0].top * 100/chatBody.offsetHeight;
                    r.style.setProperty('background-position', '0 '+ yPer +'%')
                    //console.log('y% -> ' + yPer)
                }
            }
        },
        isVisible(e) {
            return !!( e.offsetWidth || e.offsetHeight || e.getClientRects().length );
        },
        getPosY(posY, fullY){
            if(posY - fullY < 0){
                return posY;
            }else {
                return this.getPosY(posY - fullY, fullY)
            }
        },
        scrollDown(animate = false){
            console.log('scrollDown');
            // const  container = document.getElementById('chatontainer') ;
            this.$nextTick(()=>{
                this.initWavesurfer();
                setTimeout(()=>{
                    if (this.$refs.chatContainer) {
                        // let offset =  this.$refs.chatContainer.scrollHeight
                        // animate ?  gsap.to(this.$refs.chatContainer,  1,{ scrollTo: {y: offset, autoKill: false }, ease: Power4.easeIn})
                        //     : this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
                        this.$refs.chatContainer.scrollTop = this.$refs.chatContainer.scrollHeight;
                    }
                },200);
            });

        },
        isOpponentMessage(message){
            return message.author === this.roleOpponent
        },

        classMessage(message){
            return this.isOpponentMessage(message) ?'is-start': 'is-end'

            // return (message && message.hidden) ? `${className} is-message-hidden`: className ;
        },
        writeDate(date){
            const arr = date.split('.')
            return `${arr[0]} ${month[Number(arr[1])]}, ${arr[2]} `
        },
        timeFormat(time){
            const min = Math.floor((time % 3600) / 60);
            const sec = Math.floor(time % 60);
            return `${min<10 ? '0'+min : min}:${sec<10 ? '0'+sec : sec}`
        },
    }
}
</script>

<style>
</style>
