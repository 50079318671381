<template>
    <portal selector="#portal-target">
        <div class="modal modal_sm modal_block-user is-active" tabindex="-1" role="dialog">
            <div class="modal__bg"></div>
            <div class="modal__container">
                <div class="modal__header">
                    <h2 class="modal__title">Attention!</h2>
                </div>
                <div class="modal__body">
                    <p>The session has timed out. Log in again!</p>
                </div>
                <div class="modal__footer">
                    <button class="button button_md button_primary" @click.prevent="closeModalSessionEnded">
                        <span>OK!</span>
                    </button>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
export default {
    name: "ModalSessionEnded",
    mounted() {
        this.$bus.$emit('open_modal')
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
    },
    methods: {
        closeModalSessionEnded(){
            this.$emit('close_modal_session_ended')
        },
    },

}
</script>

<style scoped>

</style>
