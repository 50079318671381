<template>
    <span class="message__audio">
    <!--add class button is-paid if it is needs to pay-->

        <!--   add class is-stop-->
        <button class="button button_audio-play" :class="{'is-play':!audioPlay, 'is-pause': audioPlay}" @click.prevent="playAudio()">
          <svg class="button__icon button__icon_play" xmlns="http://www.w3.org/2000/svg" width="16" height="18" v-if="!audioPlay">
            <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#play`" />
          </svg>
          <svg class="button__icon button__icon_pause" xmlns="http://www.w3.org/2000/svg" width="16" height="18" v-else>
            <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#pause`" />
          </svg>
          <span class="button__icon button__icon_stop"></span>
        </button>
        <span class="audio" id="mic_voice" v-show="isRecord"></span>
        <span class="audio" id="wavesurfer_preview" v-show="!isRecord"></span>
    </span>
</template>

<script>
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";

let wavesurfer = null;
let wavesurferPlay = null;
let record = null;
let scrollingWaveform = false
export default {
    name: "CustomAudioPlayer",
    props:['myUrl','media'],
    data(){
        return{
            audioPlay: false,
            isRecord: true,
        }
    },
    mounted() {
        this.initWavesurferItem();
    },
    beforeDestroy() {
        if(wavesurfer){
            wavesurfer.destroy();
            wavesurfer = null;
        }
    },
    methods:{
        playAudio(){
            if(!wavesurferPlay) return;
            if(this.audioPlay){
                wavesurferPlay.pause();
                setTimeout(()=>{
                    this.audioPlay = false;
                },50)
            }else {
                wavesurferPlay.play();
                this.audioPlay = true;
            }
        },
        initWavesurferItem(){
            console.log('initWavesurfer Preview');
            if(wavesurfer) return;
            const containerMic = document.getElementById('mic_voice');
            if(!containerMic) {
                console.log('!containerMic');
                return;
            }
            wavesurfer = WaveSurfer.create({
                container: containerMic,
                height: 40,
                waveColor: '#A8A8A8',
                progressColor: '#131313',
                barRadius: 3,
                barWidth: 1,
                barGap: 2,
                backend: 'MediaElement',
                cursorWidth: 0,
                mediaControls: false,
            });
            record = wavesurfer.registerPlugin(RecordPlugin.create({ scrollingWaveform, renderRecordedAudio: false }))
            record.on('record-end', (blob) => {
                this.isRecord = false;
                const container = document.getElementById('wavesurfer_preview')
                const recordedUrl = URL.createObjectURL(blob)
                if(!container){
                    console.error('not contaner')
                    return
                }

                // Create wavesurfer from the recorded audio
                const wavesurfer = WaveSurfer.create({
                    container,
                    height: 40,
                    waveColor: '#A8A8A8',
                    progressColor: '#131313',
                    barRadius: 3,
                    barWidth: 1,
                    barGap: 2,
                    backend: 'MediaElement',
                    cursorWidth: 0,
                    mediaControls: false,
                    url: recordedUrl,
                })

                // Play button
                const button = container.appendChild(document.createElement('button'))
                button.textContent = 'Play'
                button.onclick = () => wavesurfer.playPause()
                wavesurfer.on('pause', () => (button.textContent = 'Play'))
                wavesurfer.on('play', () => (button.textContent = 'Pause'))

                // Download link
                const link = container.appendChild(document.createElement('a'))
                Object.assign(link, {
                    href: recordedUrl,
                    download: 'recording.' + blob.type.split(';')[0].split('/')[1] || 'webm',
                    textContent: 'Download recording',
                })
                pauseButton.style.display = 'none'
                recButton.textContent = 'Record'

                record.on('record-progress', (time) => {
                    updateProgress(time)
                })
            })
        },
        // const progress = document.querySelector('#progress')
        updateProgress(time){
            // time will be in milliseconds, convert it to mm:ss format
            const formattedTime = [
                Math.floor((time % 3600000) / 60000), // minutes
                Math.floor((time % 60000) / 1000), // seconds
            ]
                .map((v) => (v < 10 ? '0' + v : v))
                .join(':')
            console.log(formattedTime)
            // progress.textContent = formattedTime
        },
        // const pauseButton = document.querySelector('#pause')
        pauseButton(){
            if (record.isPaused()) {
                record.resumeRecording()
                pauseButton.textContent = 'Pause'
                return
            }

            record.pauseRecording()
            // pauseButton.textContent = 'Resume'
        },

        // const micSelect = document.querySelector('#mic-select')
// {
//     // Mic selection
//     RecordPlugin.getAvailableAudioDevices().then((devices) => {
//         devices.forEach((device) => {
//             const option = document.createElement('option')
//             option.value = device.deviceId
//             option.text = device.label || device.deviceId
//             micSelect.appendChild(option)
//         })
//     })
// }
// Record button
// const recButton = document.querySelector('#record')

        recButtonСlick(){
            if (record.isRecording() || record.isPaused()) {
                record.stopRecording()
                recButton.textContent = 'Record'
                pauseButton.style.display = 'none'
                return
            }

            recButton.disabled = true

            // reset the wavesurfer instance

            // get selected device
            const deviceId = micSelect.value
            record.startRecording({ deviceId }).then(() => {
                recButton.textContent = 'Stop'
                recButton.disabled = false
                pauseButton.style.display = 'inline'
            })
        },
// document.querySelector('input[type="checkbox"]').onclick = (e) => {
//     scrollingWaveform = e.target.checked
//     createWaveSurfer()
// }
    }
}
</script>

<style scoped>

</style>
