<script>


export default {
    name: "StoriesList"
}
</script>

<template>
    <ul class="list profile__stories-list">
        <li class="list__item">
            <div class="post post_stories">
                <div class="post__meta">
                    <div class="post__meta-data">
                        <span class="post__date">December 1, 2022</span>
                        <span class="post__category">Animals</span>
                    </div>
                </div>
                <h2 class="post__heading">Loyalty Is Royalty</h2>
                <!--@TODO slider hooper styling -->
                <div class="post__poster post__poster_locked poster poster_horizontal">
                    <picture>
                        <source type="image/webp" srcset="assets/images/profile/story1.webp">
                        <img class="poster__media" src="assets/images/profile/story1.jpg"
                             alt="post media" width="810"
                             height="488">
                    </picture>
                    <div class="post__overlay">
                        <div class="post__overlay-content">
                            <i class="icon icon_lock"></i>
                            <h4 class="title">Paid Content</h4>
                            <p>To open this photo you need to pay <strong class="sub-title">10 credits</strong></p>
                            <button class="button button_primary"><span>Show Now</span></button>
                        </div>
                    </div>
                </div>
                <div class="post__body">
                    <div class="post__content">
                        <div class="post__short">
                            <p class="post__short-text">Never mind, said Hachiko each day. Here I wait, for my frien Never mind, said Hachiko each day. Here I wait, for my Hachiko each day. Here I wait, for my frie frie</p>
                            <span class="button button_link">View More</span>
                        </div>
                        <div class="post__full" style="display: none">
                            <p>Never mind, said Hachiko each day. Here I wait, for my frien Never mind, said
                                Hachiko each day. Here I wait, for my frie</p>
                            <p>Never mind, said Hachiko each day. Here I wait, for my frien Never mind, said
                                Hachiko each day. Here I wait, for my frie</p>
                            <p>Never mind, said Hachiko each day. Here I wait, for my frien Never mind, said
                                Hachiko each day. Here I wait, for my frie</p>
                        </div>
                        <div class="post__info">
                            <button class="button button_like"></button><span class="post__like">58</span>
                            <span class="post__view"><i class="post__icon icon icon_eye"></i><span>838</span></span>
                        </div>
                    </div>
                    <div class="post__additional" style="display: none">
                        <div class="post__comments">
                            <h4 class="title">Comments</h4>
                            <ul class="post__comments-list list">
                                <li class="post__comments-media">
                                    <img class="avatar avatar_sm" src="assets/images/member/avatar1.png" alt="">
                                    <div class="avatar avatar_sm avatar_holder" style="display: none">
                                        <img class="avatar__image" src="assets/images/icons/holder-user.svg" alt="holder" width="65" height="65">
                                    </div>
                                    <div class="post__comments-body">
                                        <span class="post__comment-author">Tim</span>
                                        <span class="post__comment-date">December 1, 2022</span>
                                        <p class="post__comment">comment text</p>
                                    </div>
                                </li>
                                <li class="post__comments-media">
                                    <img class="avatar avatar_sm" src="assets/images/member/avatar1.png" alt="">
                                    <div class="avatar avatar_sm avatar_holder" style="display: none">
                                        <img class="avatar__image" src="assets/images/icons/holder-user.svg" alt="holder" width="65" height="65">
                                    </div>
                                    <div class="post__comments-body">
                                        <span class="post__comment-author">Tim</span>
                                        <span class="post__comment-date">December 1, 2022</span>
                                        <p class="post__comment">comment text</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="post__form form">
                            <h4 class="post__form-title">Leave a comment</h4>
                            <div class="post__comment">
                                <picture>
                                    <source type="image/webp" srcset="assets/images/profile/avatar.webp">
                                    <img class="avatar avatar_sm" src="assets/images/profile/avatar.png" alt="avatar" width="50" height="50">
                                </picture>
                                <div class="form__input post__input">
                                    <textarea class="ui-textarea post__textarea" placeholder="Write your comment..." maxlength="512" ></textarea>
                                    <div class="form__buttons">
                                        <button class="button button_icon"><i class="icon icon_emoji"></i></button>
                                        <button class="button button_icon"><i class="icon icon_send"></i></button>
                                    </div>
                                </div>
                            </div>
                            <button class="post__less button button_icon">
                                <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                     height="12" fill="none">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                          d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="list__item">
            <div class="post post_stories">
                <div class="post__meta">
                    <div class="post__meta-data">
                        <span class="post__date">December 1, 2022</span>
                        <span class="post__category">Travelling</span>
                    </div>
                </div>
                <h2 class="post__heading">Vacation and travel by car</h2>
                <div class="post__poster poster poster_horizontal">
                    <picture>
                        <source type="image/webp" srcset="assets/images/profile/story2.webp">
                        <img class="poster__media" src="assets/images/profile/story2.jpg"
                             alt="post media" width="810"
                             height="488">
                    </picture>
                </div>
                <div class="post__body">
                    <div class="post__content">
                        <div class="post__short" style="display: none">
                            <p class="post__short-text">Never mind, said Hachiko each day. Here I wait, for my frien Never mind, said Hachiko each day. Here I wait, for my Hachiko each day. Here I wait, for my frie frie</p>
                            <span class="button button_link">View More</span>
                        </div>
                        <div class="post__full">
                            <p>I like traveling very much. Especially by car, it's a long way to go. Many people travel by train, by plane, by car, and some by hitchhiking. Especially I love Alieksika new places where I have not been before. It's interesting and educational.</p>
                        </div>
                        <div class="post__info">
                            <button class="button button_like"></button><span class="post__like">58</span>
                            <span class="post__view"><i class="post__icon icon icon_eye"></i><span>838</span></span>
                        </div>
                    </div>
                    <div class="post__additional">
                        <div class="post__comments">
                            <h4 class="title">Comments</h4>
                            <ul class="post__comments-list list">
                                <li class="post__comments-media">
                                    <img class="avatar avatar_sm" src="assets/images/member/avatar1.png" alt="">
                                    <div class="avatar avatar_sm avatar_holder" style="display: none">
                                        <img class="avatar__image" src="assets/images/icons/holder-user.svg" alt="holder" width="65" height="65">
                                    </div>
                                    <div class="post__comments-body">
                                        <span class="post__comment-author">Tim</span>
                                        <span class="post__comment-date">December 1, 2022</span>
                                        <p class="post__comment">I like traveling very much. </p>
                                    </div>
                                </li>
                                <li class="post__comments-media">
                                    <img class="avatar avatar_sm" src="assets/images/member/avatar1.png" alt="">
                                    <div class="avatar avatar_sm avatar_holder" style="display: none">
                                        <img class="avatar__image" src="assets/images/icons/holder-user.svg" alt="holder" width="65" height="65">
                                    </div>
                                    <div class="post__comments-body">
                                        <span class="post__comment-author">Nick</span>
                                        <span class="post__comment-date">December 1, 2022</span>
                                        <p class="post__comment">I like traveling very much. </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="post__form form">
                            <h4 class="post__form-title">Leave a comment</h4>
                            <div class="post__comment">
                                <picture>
                                    <source type="image/webp" srcset="assets/images/profile/avatar.webp">
                                    <img class="avatar avatar_sm" src="assets/images/profile/avatar.png" alt="avatar" width="50" height="50">
                                </picture>
                                <div class="form__input post__input">
                                    <textarea class="ui-textarea post__textarea" placeholder="Write your comment..." maxlength="512" ></textarea>
                                    <div class="form__buttons">
                                        <button class="button button_icon"><i class="icon icon_emoji"></i></button>
                                        <button class="button button_icon"><i class="icon icon_send"></i></button>
                                    </div>
                                </div>
                            </div>
                            <button class="post__less button button_icon">
                                <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                     height="12" fill="none">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                          d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <li class="list__item">
            <div class="post post_stories">
                <div class="post__meta">
                    <div class="post__meta-data">
                        <span class="post__date">December 1, 2022</span>
                        <span class="post__category">Personal</span>
                    </div>
                </div>
                <h2 class="post__heading">The girl is beautiful like a flower</h2>
                <div class="post__poster poster poster_horizontal">
                    <picture>
                        <source type="image/webp" srcset="assets/images/profile/story3.webp">
                        <img class="poster__media" src="assets/images/profile/story3.jpg"
                             alt="post media" width="810"
                             height="488">
                    </picture>
                </div>
                <div class="post__body">
                    <div class="post__content">
                        <div class="post__short">
                            <p class="post__short-text">"I want to do with you what the spring does with the cherrye</p>
                            <span class="button button_link">View More</span>
                        </div>
                        <div class="post__full" style="display: none">
                            <p>Never mind, said Hachiko each day. Here I wait, for my frien Never mind, said
                                Hachiko each day. Here I wait, for my frie</p>
                            <p>Never mind, said Hachiko each day. Here I wait, for my frien Never mind, said
                                Hachiko each day. Here I wait, for my frie</p>
                            <p>Never mind, said Hachiko each day. Here I wait, for my frien Never mind, said
                                Hachiko each day. Here I wait, for my frie</p>
                        </div>
                        <div class="post__info">
                            <button class="button button_like"></button><span class="post__like">58</span>
                            <span class="post__view"><i class="post__icon icon icon_eye"></i><span>838</span></span>
                        </div>
                    </div>
                    <div class="post__additional" style="display: none">
                        <div class="post__comments">
                            <h4 class="title">Comments</h4>
                            <ul class="post__comments-list list">
                                <li class="post__comments-media">
                                    <img class="avatar avatar_sm" src="assets/images/member/avatar1.png" alt="">
                                    <div class="avatar avatar_sm avatar_holder" style="display: none">
                                        <img class="avatar__image" src="assets/images/icons/holder-user.svg" alt="holder" width="65" height="65">
                                    </div>
                                    <div class="post__comments-body">
                                        <span class="post__comment-author">Nick</span>
                                        <span class="post__comment-date">December 1, 2022</span>
                                        <p class="post__comment">comment text</p>
                                    </div>
                                </li>
                                <li class="post__comments-media">
                                    <img class="avatar avatar_sm" src="assets/images/member/avatar1.png" alt="">
                                    <div class="avatar avatar_sm avatar_holder" style="display: none">
                                        <img class="avatar__image" src="assets/images/icons/holder-user.svg" alt="holder" width="65" height="65">
                                    </div>
                                    <div class="post__comments-body">
                                        <span class="post__comment-author">Nick</span>
                                        <span class="post__comment-date">December 1, 2022</span>
                                        <p class="post__comment">comment text</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="post__form form">
                            <h4 class="post__form-title">Leave a comment</h4>
                            <div class="post__comment">
                                <picture>
                                    <source type="image/webp" srcset="assets/images/profile/avatar.webp">
                                    <img class="avatar avatar_sm" src="assets/images/profile/avatar.png" alt="avatar" width="50" height="50">
                                </picture>
                                <div class="form__input post__input">
                                    <textarea class="ui-textarea post__textarea" placeholder="Write your comment..." maxlength="512" ></textarea>
                                    <div class="form__buttons">
                                        <button class="button button_icon"><i class="icon icon_emoji"></i></button>
                                        <button class="button button_icon"><i class="icon icon_send"></i></button>
                                    </div>
                                </div>
                            </div>
                            <button class="post__less button button_icon">
                                <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                     height="12" fill="none">
                                    <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                          d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>

<style scoped>

</style>
