<template>
    <div class="sidebar__container">
        <div class="panel panel_mobile">
            <button class="button button_back" @click="buttonBackMobile">
                <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                    <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#back`"/>
                </svg>
            </button>
            <ul class="tabs panel__tabs">
                <li class="tabs__tab" @click="typeTab='chat-request'">
                    <span class="tabs__link" :class="{'is-active':typeTab==='chat-request'}">
                    <i class="icon icon_chat-bubble-gradient"></i>New chat</span>
                </li>
                <li class="tabs__tab" @click="typeTab='favorites'">
                    <span class="tabs__link" :class="{'is-active':typeTab==='favorites'}">
                    <i class="icon icon_favorite"></i>Favorites</span>
                </li>
                <li class="tabs__tab" @click="typeTab='history'">
                    <span class="tabs__link" :class="{'is-active':typeTab==='history'}">
                    <i class="icon icon_history"></i>History</span>
                </li>
            </ul>
            <div class="panel__content">
                <contact-unread
                    :s3-url="s3Url"
                    :current-user="currentUser"
                    :chat-owner="chatOwner"
                    :my-url="myUrl"
                    :contacts="contacts"
                    :role-opponent="roleOpponent"
                    :ban-list="banList"
                    :unread="countUnread"
                    :is-new-version="isNewVersion"
                    :is-all-chat-request="isAllChatRequest"
                    :count-contacts="countContacts"
                    :is-supported-webp="isSupportedWebp"
                    @errorhandler="errorHandler"
                    v-if="showContacts && typeTab==='chat-request'"
                ></contact-unread>

                <contact-favorite
                    :s3-url="s3Url"
                    :current-user="currentUser"
                    :my-url="myUrl"
                    :contacts="contacts"
                    :role-opponent="roleOpponent"
                    :ban-list="banList"
                    :chat-owner="chatOwner"
                    :is-all-favorite="isAllFavorite"
                    :count-contacts="countContacts"
                    :is-supported-webp="isSupportedWebp"
                    @errorhandler="errorHandler"
                    v-if="showContacts && typeTab==='favorites'"
                ></contact-favorite>
                <contact-history
                    :s3-url="s3Url"
                    :current-user="currentUser"
                    :my-url="myUrl"
                    :contacts="contacts"
                    :role-opponent="roleOpponent"
                    :ban-list="banList"
                    :chat-owner="chatOwner"
                    :is-all-history="isAllHistory"
                    :count-contacts="countContacts"
                    :is-supported-webp="isSupportedWebp"
                    @errorhandler="errorHandler"
                    v-if="showContacts && typeTab==='history'"
                ></contact-history>

            </div>
        </div>
    </div>
</template>
<script>
import ContactFavorite from "./ContactFavorite.vue";
import ContactHistory from "./ContactHistory.vue";
import ContactUnread from "./ContactUnread.vue";

export default {
    name: "MobileContacts",
    components: { ContactUnread, ContactHistory, ContactFavorite },
    props: [
        'myUrl',
        's3Url',
        'contacts',
        'currentUser',
        'countUnread',
        'chatOwner',
        'showContacts',
        'isNewVersion',
        'isAllFavorite',
        'countContacts',
        'isSupportedWebp',
        'isAllChatRequest',
        'roleOpponent',
        'isAllHistory',
        'banList',
    ],
    data(){
        return{
            typeTab: 'chat-request',
        }
    },
    methods:{
        buttonBackMobile(){
            this.$emit('button_back_mobile');
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    }
}
</script>

<style scoped>

</style>
