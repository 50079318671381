<template>
    <div class="page page_static page_payment-status">
        <section class="section section_page">
            <div class="container">
                <template v-if="status === 'success'">
                    <h2 v-if="status === 'success'" class="section__heading heading">Successful Payment <i class="icon icon_md icon_secured"></i></h2>
<!--                    <h2 v-if="status === 'failed'" class="section__heading heading">Unsuccessful Payment <i class="icon icon_md icon_warning"></i></h2>-->
                    <table class="table table_page page__table">
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Description</th>
                            <th class="text-center">Amount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
<!--                            :class="{'is_through': status === 'failed'}"-->
                            <td>{{datePayment}}</td>
                            <td >Purchase of credits</td>
                            <td class="text-center"><span class="title">$ {{Number(amount/100).toFixed(2)}}</span></td>
                        </tr>
                        </tbody>
                    </table>

                </template>
                <template v-else-if="status ==='failed'">
                        <h2 class="section__heading heading">Unsuccessful Payment <i class="icon icon_md icon_warning"></i></h2>
                        <div class="pad page__pad">
                            <div class="pad__header"><span>Help</span></div>
                            <div class="pad__body">
                                <ul class="list list_marked">
                                    <li>Make sure you entered the correct card details and verification code.</li>
                                    <li>You must have enough money on your credit card.</li>
                                    <li>Make sure that the appropriate limits are set on your card before performing your payment. Your limit must not be less than the amount of the payment, and you must not have a limit on online payments.</li>
                                    <li>Check your mobile application, it may need to confirm the payment.</li>
                                </ul>
                            </div>
                        </div>
                        <p class="page__info">If all the rules have been followed, but the payment is still unsuccessful, contact your bank and find out what is the reason for your bank's refusal. You can also try using another credit card</p>

                </template>

<!--                pending -->
                <template v-else>
                    <h2 class="section__heading heading">Payment is pending <i class="icon icon_md icon_warning"></i></h2>
                    <div class="pad page__pad">
                        <div class="pad__header"><span>Warning!!!</span></div>
                        <div class="pad__body">
                            <p>
                                Your payment is in progress, please wait for the transaction to complete, it may take some time
                                Until your payment is processed, you cannot complete another transaction !
                                You can return to the site while waiting
                                We will notify you of the status of your payment
                            </p>
                        </div>
                    </div>
                </template>

                <span @click.prevent="GoToGallery" class="page__button button button_md button_primary">
                    <span>Apply</span>
                </span>
                <span @click.prevent="goToFormCG" class="page__button button button_md button_primary" v-if="inProgress">
                    <span>Repeat</span>
                </span>
                <div class="page__footer">
                    <p>
                        <span>To contact us you can use <a class="page__link" href="mailto:support@hablario.com">support@hablario.com</a></span>
                        <span>To contact us you can use <a class="page__link" href="mailto:support@cgbilling.com">support@cgbilling.com</a></span>
                    </p>
                </div>

            </div>
        </section>
    </div>
</template>

<script>
export default {
name: "PaymentCgStatus",
    props:['amount', 'id','chatOwner'],
    data(){
        return{
            status:'progress',
            datetime:null,
            orderID:'',
            errMessage:'',
            paymentTariff:null,
            cgForm:null,
        }
    },
    computed:{
        inProgress(){
          return  this.status !== 'success' && this.status !=='failed' ;
        },
        datePayment(){
            let date
            if (this.datetime){
                 date = new Date(this.datetime);
            }else {
                 date = new Date();
            }
            let month = date.getMonth() + 1;
            if(month < 10){
                month = `0${month}`;
            }
            let day = date.getDate();
            if(day < 10){
                day = `0${day}`;
            }
            return `${date.getFullYear()}-${month}-${day}`
        }
    },
    mounted() {
        // let url = window.location.search;
        // const arr = url.split('orderId=');
        // this.orderID = arr.pop().trim();
        // console.log(this.orderID);
        // console.log(this.$route.query);
        // console.log(this.$route.params);

        if(this.$route.name === 'PaymentCgStatusTest'){
            console.log('test error');
            // this.status = 'failed';
            this.getStatusPayment()

        }else {
            this.getStatusPayment();
        }


    },
    methods:{
        GoToGallery(){
            // this.$bus.$emit('end_payment');
            // this.$router.push({name: 'gallery'});
            window.location.href = '/gallery';
        },
        goToFormCG(){
            console.log('goToFormCG');
            axios.get(`/get-url-payment-form-cg/repeat/${this.amount}/card`)
                .then(response => {
                    console.log(response.data);
                    if(response.data.status === 'pending'){
                        console.log('status pending');
                        // this.$router.push({name: 'PaymentCgStatus', params: {
                        //     amount: response.data.amount,
                        //     id: response.data.id
                        // }});
                        this.$router.push({path: `/payment-cg-status/${response.data.amount}/${response.data.id}`});
                    }else if (response.data.status === 'ok' && response.data.bodyForm){
                        window.location.href = response.data.bodyForm.forwardUrl;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        getStatusPayment(){
            console.log('getStatusPayment');
            if(this.inProgress){
                const isTariff = !this.paymentTariff ? 'tariff': 'only'
                console.log('id: '+ this.id)
                axios.get(`/get-payment-status-cg/${this.id}/${isTariff}`)
                    .then(response => {
                        console.log(response.data);
                        this.status = response.data.status;
                        this.datetime = response.data.datetime;
                        this.cgForm = response.data.cgForm;
                        if(!this.paymentTariff){
                            this.paymentTariff = response.data.paymentTariff;
                        }
                        if(this.status ==='failed'){
                            this.errMessage = response.data.errMessage;
                        } else if(this.status ==='success'){
                            this.sendEventEcommerce()
                        }
                        if (response.data.balance){
                            this.$bus.$emit('new_balance',response.data.balance)
                        }
                        if (this.inProgress){
                            setTimeout(()=>{
                                this.getStatusPayment();
                            },5000);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        this.errorHandler(e);
                    })
            }
        },
        sendEventEcommerce(){
            try {
                if( this.chatOwner.lead === 'rc') return
                dataLayer.push({ ecommerce: null });  //
                dataLayer.push({
                    event: "purchase",
                    ecommerce: {
                        transaction_id: `cg_${this.id}`,
                        affiliation: "cart",
                        value: Number(this.paymentTariff.amount / 100).toFixed(2),
                        tax: "0",
                        shipping: "0",
                        currency: "USD",
                        items: [{
                            item_name: this.paymentTariff.title,
                            item_id: Number(this.paymentTariff.id).toFixed(0),
                            price: Number(this.paymentTariff.amount / 100).toFixed(2),
                            item_brand: "lifeforlove",
                            item_variant: "credits", // количество кредитов
                            quantity: 1
                        }]
                    }
                });

            }catch (e) {
                console.log(e)
            }
        }

    }
}
</script>

<style scoped>

</style>
