<template>
    <div class="container">
        <div class="row">
            <h1>Generate Url for Registration</h1>
            <button class="button_danger" @click="generateUrl">Generate</button>
        </div>
        <div class="row">
            <h4>{{url}}</h4>
        </div>

    </div>
</template>

<script>
export default {
    name: "GenerateUrlRegister",
    data(){
        return{
            url:''
        }
    },
    methods:{
        generateUrl(){
            axios.get('/get-url-register')
                .then(response => {
                    this.url = response.data.url
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                });
        },
    }
}
</script>

<style scoped>

</style>
