import VueRouter from "vue-router"
import Account from "../components/Account"
import Home from "../components/Home";
import SuccessRegister from "../components/SuccessRegister";
import SuccessValidationEmail from "../components/SuccessValidationEmail";
import GenerateUrlRegister from "../components/GenerateUrlRegister";
import Profile from "../components/Profile";
import Gallery from "../components/Gallery";
import AuthComponent from "../components/auth/AuthComponent";
import Chat from "../components/modules/Chat";
import Error404 from "../components/Error404";
import PaymentCgStatus from "../components/PaymentCgStatus";
import InstallDesktop from '../components/InstallDesktop';
import UnsubscribeToNewsletters from "../components/UnsubscribeToNewsletters";
import SupportChatContainer from "../components/newModules/SupportChatContainer.vue";
import PaymentPage from "../components/newPage/PaymentPage.vue";
import CreateMyStory from "../components/newPage/CreateMyStory.vue";
import ShowChatMedia from "../components/newPage/ShowChatMedia.vue";

// import PaymentCoinsbuyStatus from "../components/newPage/PaymentCoinsbuyStatus.vue";

const routes = [
    {
        path: '/',
        component: InstallDesktop,
        name: "landing",
        props: true,
    },
    {
        path: '/about',
        component: InstallDesktop,
        name: "about",
        props: false,
    },
    {
        path: '/appearance/:type',
        component: InstallDesktop,
        name: "appearance",
        props: false,
    },
    // {
    //     path: '/welcome-usa/:region',
    //     component: LandingImage,
    //     name: "welcomeUSA",
    //     props: true,
    // },
    // {
    //     path: '/promo/romantic-relationship',
    //     component: LandingImage,
    //     name: "romanticRelationship",
    //     props: false,
    // },
    // {
    //     path: '/promo/sexting',
    //     component: LandingImage,
    //     name: "relationshipSexting",
    //     props: false,
    // },
    // {
    //     path: '/promo/hot-datings',
    //     component: LandingImage,
    //     name: "hotDatings",
    //     props: false,
    // },
    // {
    //     path: '/promo/women-like-older-men',
    //     component: LandingImage,
    //     name: "likeOlderMen",
    //     props: false,
    // },
    // {
    //     path: '/promo/chat-with-women',
    //     component: LandingImage,
    //     name: "chatWithWomen",
    //     props: false,
    // },
    // {
    //     path: '/promo/chat-with-ladies',
    //     component: LandingImage,
    //     name: "chatWithLadies",
    //     props: false,
    // },
    // {
    //     path: '/promo/chat-with-girls',
    //     component: LandingImage,
    //     name: "chatWithGirls",
    //     props: false,
    // },
    // {
    //     path: '/promo/men-over-sixty',
    //     component: LandingImage,
    //     name: "menOverSixty",
    //     props: false,
    // },
    // {
    //     path: '/promo/school-mistress',
    //     component: LandingImage,
    //     name: "schoolMistress",
    //     props: false,
    // },
    // {
    //     path: '/payment/:amount',
    //     component: Payment,
    //     name: "payment",
    //     props: true,
    //
    // },
    {
        path: '/home',
        component: Home,
        name: "home",
        // props: true,
        children: [
            {
                path: '',
                component: AuthComponent,
                name: "auth",
                props: true,

            },
            {
                path: '/gallery',
                component: Gallery,
                name: "gallery",
                props: true,

            },
            {
                path: '/profile/:id',
                component: Profile,
                name: "profile",
                props: true,

            },
            {
                path: '/promo/profile/:id',
                component: Profile,
                name: "promoProfile",
                props: true,

            },
            {
                path: '/account',
                component: Account,
                name: "account",
                props: true,

            },
            {
                path: '/account/create-my-story',
                component: CreateMyStory,
                name: "CreateMyStory",
                props: true,

            },
            {
                path: '/account/update-my-story/:storyID',
                component: CreateMyStory,
                name: "UpdateMyStory",
                props: true,

            },
            {
                path: '/mobile-support-chat',
                component: SupportChatContainer,
                name: "SupportChat",
                props: true,
            },
            {
                path: '/mobile-chat/:id',
                component: Chat,
                name: "chat",
                props: true,
            },
            {
                path: '/promo/mobile-chat/:id',
                component: Chat,
                name: "promoChat",
                props: true,
            },
            {
                path: '/mobile-show-chat-media/:id',
                component: ShowChatMedia,
                name: "ShowChatMedia",
                props: true,
            },
            // {
            //     path: '/mobile-contacts',
            //     component: MobileContacts,
            //     name: "MobileContacts",
            //     props: true,
            // },
            {
                path: '/success-registration',
                component: SuccessRegister,
                name: "SuccessRegistration",
                props: true
            },
            {
                path: '/success-validation-email',
                component: SuccessValidationEmail,
                name: "SuccessValidationEmail",
                props: true
            },
            {
                path: '/unsubscribe-to-newsletters',
                component: UnsubscribeToNewsletters,
                name: "UnsubscribeToNewsletters",
                props: true
            },
            {
                path: '/payment-cg-status/:amount/:id',
                component: PaymentCgStatus,
                name: "PaymentCgStatus",
                props: true
            },
            // {
            //     path: '/payment-coinsbuy-status/:amount/:id',
            //     component: PaymentCoinsbuyStatus,
            //     name: "PaymentCgStatus",
            //     props: true
            // },
            {
                path: '/payment-cg-status-test/:amount/:id',
                component: PaymentCgStatus,
                name: "PaymentCgStatusTest",
                props: true
            },
            {
                path: '/payment-page',
                component: PaymentPage,
                name: "PaymentPage",
                props: true
            },

        ]
    },
    {
        path: '/generate-url-register',
        component: GenerateUrlRegister,
        name: "GenerateUrlRegister",
        props: true
    },
    {
        path: '/error',
        component: Error404,
        name: "Error404",
        props: true
    }

]

const router = new VueRouter({
    routes,
    mode: "history"
})

export default router;

