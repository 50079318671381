<template>
    <div class="form form_horizontal">
        <div class="form__header">
            <h2 class="form__heading">Sign up</h2>
            <h4 class="form__sub-heading" v-if="error500">Attention!!!</h4>
            <h4 class="form__sub-heading" v-else-if="!errMessage">Get a bonus $15</h4>
            <span class="form__error" v-else>{{ errMessage }}</span>
        </div>
        <template v-if="error500">
            <div>
                <!--                <h4 class="text-red">Attention</h4>-->
                <h4>We apologize for any inconvenience caused, but we are currently performing essential maintenance.</h4>
                <countdown-maintenance v-if="timeMaintenance"
                                       :deadline="timeMaintenance"
                ></countdown-maintenance>
                <!--                <h6 v-if="timeMaintenance">Technical work completion date: {{timeMaintenance.toLocaleString()}}</h6>-->
            </div>
        </template>
        <template v-else>
            <div class="form__row">
                <label class="form__label">Your gender</label>
                <div class="is-flex">
                    <label for="man" class="custom-radio">
                        <input id="man" type="radio" name="gender" value="man" v-model="role">
                        <span class="custom-radio__input ui-input">
                    <span class="custom-radio__label">
                      <svg class="custom-radio__icon" xmlns="http://www.w3.org/2000/svg" width="16" height="20">
                        <use xlink:href="source/images/icons/icons.svg#man"/>
                      </svg>Man</span>
                  </span>
                    </label>
                    <label for="woman" class="custom-radio">
                        <input id="woman" type="radio" name="gender" value="woman" v-model="role">
                        <span class="custom-radio__input ui-input">
                    <span class="custom-radio__label">
                      <svg class="custom-radio__icon" xmlns="http://www.w3.org/2000/svg" width="22" height="22">
                        <use xlink:href="source/images/icons/icons.svg#woman"/>
                      </svg>Woman</span>
                  </span>
                    </label>
                </div>
            </div>
            <div class="form__row" :class="{'is-error':errName}">
                <label for="userName" class="form__label">Name</label>
                <input id="userName" class="ui-input ui-input_sm" type="text" maxlength="100" v-model="name">
                <span class="form__error" v-if="errName">{{ errName }}</span>
            </div>
            <div class="form__row" :class="{'is-error':errEmail}">
                <label for="emailReg" class="form__label">Email</label>
                <input id="emailReg" class="ui-input" type="email" maxlength="100" autocomplete="username" v-model="email">
                <span class="form__error" v-if="errEmail">{{ errEmail }}</span>
            </div>
            <div class="form__row" :class="{'is-error':errPassword}">
                <label for="passwordReg" class="form__label">Password</label>
                <div class="form__row-wrapper">
                    <input id="passwordReg" :type="isSeePassword?'text':'password'" class="ui-input" maxlength="100" autocomplete="new-password" v-model="password">
                    <i class="form__icon icon icon_eye" @click.prevent="seePassword"></i>
                </div>
                <span class="form__error" v-if="errPassword">{{ errPassword }}</span>
            </div>
<!--            <select-birthday-->
<!--                :err-birthday="errBirthday"-->
<!--                :init-birthday="''"-->
<!--                @set_birthday="setBirthday"-->
<!--            ></select-birthday>-->
<!--            <div class="form__row" :class="{'is-error':errCountry}">-->
<!--                <label class="form__label">Country</label>-->
<!--                <select-country ref="selectCountry"-->
<!--                                :countries="countries"-->
<!--                                @selected_country="selectedCountry"-->
<!--                ></select-country>-->
<!--                <span class="form__error" v-if="errCountry">{{ errCountry }}</span>-->
<!--            </div>-->
            <div class="form__row row" v-if="role === 'woman'">
                <div class="col-sm-6">
                    <div class="file-input mb-2">
                        <label class="form__label">Upload copy of Passport</label>
                        <span class="ui-input">{{ imageName }}</span>
                        <input type="file" @change="selectedScanPassport" accept=".jpg, .jpeg, .png, .svg">
                        <span class="form__error" v-if="errScanPassport">{{ errScanPassport }}</span>
                    </div>
                </div>
                <div class="col-sm-6">
                    <div class="file-input">
                        <label class="form__label">Upload video</label>
                        <span class="ui-input">{{videoName}}</span>
                        <input type="file" @change="selectedVideo" accept=".mp4, .webm, .mov">
                        <span class="form__error" v-if="errVideo">{{ errVideo }}</span>
                    </div>
                </div>
            </div>
            <div class="form__row">
                <label for="rules" class="form__label">
                    <input type="checkbox" id="rules" class="ui-checkbox" v-model="isAgree">

                    <span class="label">By registering, you agree to our <a class="form__link" target="_blank" href="/terms-and-conditions-short">Terms and conditions</a>
                  and <a class="form__link" target="_blank" href="/adult-content">Adult content</a> and confirm that you are at least 18 years old.</span>
                </label>
                <span class="form__error" v-if="errAgree">{{ errAgree }}</span>
            </div>

        </template>

        <div class="form__footer" v-if="error500">
            <button class="button button_md button_primary"  @click.prevent="tryAgain">
                <span>Try again</span>
            </button>
        </div>

        <div class="form__footer" v-else>
<!--            :disabled="!isAgree"-->
            <button class="button button_md button_primary" @click.prevent="registerUser">
                <span>Registration</span>
            </button>
            <a :href="`${myUrl}/socialite/google/auth/registration`" class="button button_md button_bordered" v-if="role==='man' && !timeMaintenance">
               <span>
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="18" height="18">
                        <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#google`" />
                    </svg>
                    <span class="button__text">Register with Google</span>
               </span>
            </a>
            <span class="form__text">Already a user? <span class="form__link" data-modal="login" @click.prevent="openLogin">Login</span></span>
        </div>
    </div>
</template>

<script>
import SelectCountry from "../modules/SelectCountry.vue";
import SelectBirthday from "../newModules/SelectBirthday.vue";
import CountdownMaintenance from "../newModules/CountdownMaintenance.vue";

let scanCopy = null;
let videoWomen = null;



export default {
    name: "register",
    components: {CountdownMaintenance, SelectBirthday, SelectCountry},
    props:['myUrl','token'],
    data(){
        return{
            name:'',
            email:'',
            password:'',
            errName:'',
            errEmail:'',
            errPassword:'',
            errAgree: '',
            passwordConfirm:'',
            errPasswordConfirm:'',
            // birthday:'',
            // errBirthday:'',
            isAgree:false,
            role:'man',
            // country:null,
            // errCountry:'',
            // data:null,
            maxsize:10*1024*1024,
            maxsizeVideo:20*1024*1024,
            imageName:'',
            videoName:'',
            errScanPassport:'',
            errVideo:'',
            errMessage:'',
            error500: false,
            isSeePassword:false,
            // isSeePasswordConfirm:false,
            disabledButton:false,
            // isSubscribe: false,
            date: 0,
            month: 'month',
            year: 0,
            timeMaintenance: null,
        }
    },
    created() {
        this.$bus.$on('set_time_maintenance',this.setTimeMaintenance);
    },
    mounted() {
        if(this.timeMaintenance) this.error500 =true;
        if (navigator && !navigator.cookieEnabled)
            this.errMessage = 'Please enable cookies in your browser for the best website experience!!!'
    },
    beforeDestroy() {
        this.$bus.$off('set_time_maintenance',this.setTimeMaintenance);
    },
    methods:{
        tryAgain(){
            window.location.reload()
        },
        setTimeMaintenance(timeMaintenance){
            console.log('setTimeMaintenance: '+ timeMaintenance);
            this.error500 = true;
        },
        // getTimeMaintenance(){
        //     console.log('getTimeMaintenance');
        //     axios.get('/get-time-maintenance')
        //         .then(response => {
        //             console.log(response.data)
        //             if(response.data.time_maintenance){
        //                 this.timeMaintenance = response.data.time_maintenance
        //                 if(this.timeMaintenance) this.error500 = true;
        //             }
        //         })
        //         .catch(e=>{
        //             console.log(e)
        //             // this.errorHandler(e)
        //         })
        // },
        clickPage(){
            console.log('clickPage');
            if(this.$refs.selectCountry) this.$refs.selectCountry.closeOptions();
        },
        // setBirthday(data){
        //     this.date =  data.date;
        //     this.month =  data.month;
        //     this.year =  data.year;
        // },
        // selectedCountry(selectCountry){
        //     this.country = selectCountry;
        // },
        openLogin(){
            this.$emit('open_login_modal')

        },
        // seePasswordConfirm(){
        //     this.isSeePasswordConfirm = !this.isSeePasswordConfirm ;
        // },
        seePassword(){
            this.isSeePassword = !this.isSeePassword ;
        },
        goToLending(){
            window.location.href = this.myUrl+'/';
        },
        selectedScanPassport(e){
            console.log('selectedFile');
            let file = e.target.files[0];
            console.log(file);
            const ext = this.getExtantionFile(file.name);
            if (file.size > this.maxsize || file.fileSize > this.maxsize) {
                //show an alert to the user
                alert(`Allowed file size exceeded. (Max. ${this.maxsize / (1024 * 1024)} MB)`);
            }else if (ext === 'png' || ext === 'jpeg' || ext === 'jpg' || ext === 'svg'){

                scanCopy = file;
                this.imageName = file.name ;

            } else {
                alert('This format is not supported!\n' +
                    'Supported formats .png .jpeg .jpg .svg');
            }
        },
        getExtantionFile(fileName){
            let parts = fileName.split('.');
            let ext = '';
            if (parts.length > 1) {
                ext = parts.pop();
                return ext.toLowerCase();
            }
            return '';
        },
        selectedVideo(e){
            console.log('selectedVideo');
            let file = e.target.files[0];
            console.log(file);
            const ext = this.getExtantionFile(file.name);
            if (file.size > this.maxsizeVideo || file.fileSize > this.maxsizeVideo) {
                //show an alert to the user
                alert(`Allowed file size exceeded. (Max. ${this.maxsizeVideo / (1024 * 1024)} MB)`);
            }else if (ext === 'mp4' || ext === 'webm' || ext === 'mov'){
                videoWomen = file;
                this.videoName = file.name ;
            }else {
                alert('This format is not supported!\n' +
                    'Supported formats .mp4 .webm .mov');
            }
        },
        registerUser(){
            console.log('registerUser') ;
            // this.birthday = new Date(`${this.month} ${this.date}, ${this.year} 00:00:00`);
            this.createErrors();

            if (this.checkErrors() && !this.disabledButton) {
                this.disabledButton = true;
                // this.appendData();
                const url = this.role==='man'?'/register-man':'/register-woman'
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                };

                axios.post(url,this.appendData(),config)
                    .then(response => {
                        this.disabledButton = false;
                        if (response.data.status === 'ok'){
                            console.log(response.data);
                            console.log('register-user success...');
                            this.$emit('register',response.data.currentUser);

                            // setTimeout(()=>{
                            //     this.$emit('register',response.data.currentUser);
                            // },300)

                        }else {
                            this.error500 = true;
                            this.getTimeMaintenance()
                        }
                    })
                    .catch(err => {
                        this.disabledButton = false;
                        console.log(err.response)
                        this.errorHandler(err);
                    });
            }
        },
        appendData(){
            const formData = new FormData();
            formData.append('name', this.name);
            formData.append('email', this.email);
            formData.append('password', this.password);
            // formData.append('password_confirmation', this.passwordConfirm);
            formData.append('role', this.role);
            // formData.append('birthday', `${this.year}-${this.month}-${this.date}`);//this.birthday
            // formData.append('country_id', this.country ? this.country.id : null);
            // formData.append('country_name', this.country ? this.country.countryName : null);
            formData.append('token', '');
            formData.append('lead', '');
            // formData.append('isSubscribe', this.isSubscribe ? 1 : 0);

            // let promoMessages = localStorage.getItem("promoMessages") || "[]";
            // formData.append('promoMessages', promoMessages);

            if(this.role==='woman'){
                formData.append('video', videoWomen);
                formData.append('video_name', videoWomen.name);
                formData.append('scan_passport', scanCopy);
                formData.append('image_name', scanCopy.name);
            }

            return formData;
            // console.log(formData);
        },
        checkErrors(){
            // if (this.birthday && isNaN(Date.parse(this.birthday))){
            //     alert('Date of birth selected incorrectly.');
            //     return false;
            // }
            // !this.errBirthday &&
            return  !this.errName && !this.errEmail && !this.errPassword &&
                this.isAgree && !this.errVideo && !this.errScanPassport;

        },
        createErrors(){
            this.errEmail = this.email === ''?'Required field':this.checkEmail()?'Incorrect email address':'';
            // this.errName = this.name === ''?'Required field':!isNaN(this.name)?'The name cannot be a number':'';
            this.errName = this.checkName(this.name);
            this.errPassword =this.password.length<8?'Password must be at least 8 characters long':'';
            // this.errPasswordConfirm = this.passwordConfirm !== this.password?'Passport is not confirmed correctly':'';
            // this.errBirthday = this.checkDate() ;
            this.errScanPassport = (this.role==='man' || this.imageName)?'':'You must upload a scanned copy of your passport';
            this.errVideo = (this.role==='man' || this.videoName)?'':'You must upload your video';
            // this.errCountry = (this.country || this.is_operator)?'':'Select country'
            if(!this.isAgree){
                this.errAgree = 'Agreement must be accepted';
                // alert(this.errAgree);
            }
        },
        checkEmail(){
            const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            const re2 = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;

            return !re.test(String (this.email).toLowerCase()) || !re2.test(String (this.email).toLowerCase())
        },
        // checkDate(){
        //     if(this.is_operator){
        //         return ''
        //     }
        //     if (!this.birthday || this.date==='date' || this.month === 'month' || this.year === 'year'){
        //         return 'Required field'
        //     }
        //     const nowTime = new Date();
        //     const nowBirthday = new Date(this.birthday) ;
        //
        //     return (nowTime - nowBirthday) < (3600*24*365*18*1000)?'The service is available to persons who have reached +18 years of age':
        //         (nowTime - nowBirthday) > (3600*24*365*120*1000)?'Incorrect birthday entry':'';
        // },

        errorHandler(err){
            if (+err.response.status===422 && err.response.data.errors){
                this.errName = err.response.data.errors.name?err.response.data.errors.name[0]:'';
                this.errEmail = err.response.data.errors.email?err.response.data.errors.email[0]:'';
                this.errPassword = err.response.data.errors.password?err.response.data.errors.password[0]:'';
                this.errScanPassport = err.response.data.errors.scan_passport?err.response.data.errors.scan_passport[0]:'';
                this.errVideo = err.response.data.errors.video?err.response.data.errors.video[0]:'';
                // this.errCountry = err.response.data.errors.country_id?err.response.data.errors.country_id[0]:'';
                // this.imageName = '' ;
                // this.videoName = '' ;
                this.errMessage = err.response.data.message ;
                // alert(err.response.data.message)
            }else if(err.response.status >= 500){
                this.error500 = true;
                this.getTimeMaintenance()
            }else if(+err.response.status===403){
                alert('This registration link is no longer valid')
            }else {
                this.$emit('errorhandler',err);
            }

        },
        checkName(name) {
            let verdict = '';

            let reg = /^[a-zA-Z][\s\S]{1,}/
            if(!reg.test(name)){
                verdict = 'The name must start with a letter and be at least 2 characters long'
            }
            return verdict
        }
    }

}
</script>

<style scoped>

</style>
