<template>
    <portal selector="#portal-target">
        <div class="modal modal_md modal_account modal_interests" tabindex="-1" role="dialog">
            <div class="modal__bg" @click="closeModal"></div>
            <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close"
                      @click="closeModal"><i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h2 class="modal__heading">Donate</h2>
                </div>
                <div class="modal__body">
                    <ul class="list list_hobbies">
<!--                        <li class="list__item" v-for="interest in interests">-->
<!--                            <label class="custom-checkbox">-->
<!--                                <input type="checkbox" class="custom-checkbox__input" v-model="interest.isSelected">-->
<!--                                <span class="custom-checkbox__label icon-box">-->
<!--                                            <i class="icon icon_hobbies" :class="interest.img"></i>-->
<!--                                        <span class="icon-box__title">{{interest.title}}</span>-->
<!--                                    </span>-->
<!--                            </label>-->
<!--                        </li>-->
                    </ul>
                </div>
                <div class="modal__footer">
                    <button class="button button_md button_primary" ><span>Save</span></button>
                </div>
            </div>
        </div>
    </portal>
</template>
<script>
export default {
    name:"ModalDonate",
    props:['interests'],
    methods:{
        closeModal(){
            this.$emit('close_modal')
        }
    }

}
</script>
<style scoped>

</style>
