<template>
    <portal selector="#portal-target">
        <div class="modal modal_md modal_search modal_account is-active" tabindex="-1" role="dialog" @click="clickPage">
            <div class="modal__bg" data-dismiss="modal" @click="close"></div>
            <div class="modal__container">
                <span class="modal__close"  role="button" aria-label="Close" @click="close"><i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h2 class="modal__title">Your Preferences</h2>
                    <button class="button button_link" @click.prevent="resetSearch" v-if="$mq==='desktop'">
                        <span>Reset</span>
                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                            <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#reload`" />
                        </svg>
                    </button>
                </div>
                <div class="form form_primary form_horizontal">
                    <div class="form__row">
                        <label class="form__label">Country</label>
                        <select-country ref="selectCountry"
                            :countries="countries"
                            @selected_country="selectedCountry"
                        ></select-country>

                    </div>

                    <double-range-slider
                        :label-name="'Ages'"
                        :max="80"
                        :min="18"
                        :step="1"
                        :init-max=searchRanges.ageTo
                        :init-min=searchRanges.ageFrom
                        @update_min_value="updateAgesFrom"
                        @update_max_value="updateAgesTo"
                        ref="rangeAgesSlider"
                    ></double-range-slider>
                    <div class="form__row">
                        <label for="bodyType" class="form__label">Body type</label>
                        <select id="bodyType" class="ui-select" v-model="searchParams.body_type">
                            <option value="" hidden>Select Options</option>
                            <option v-for="type in body_types">{{type}}</option>
                        </select>
                    </div>
                </div>
                <div class="form__separator">
                    <span class="form__label">Additional options</span>
                </div>
                <div class="form form_secondary form_horizontal">
                    <div class="form__row">
                        <label for="personName" class="form__label">Name</label>
                        <input id="personName" class="ui-input" v-model="searchMember.name">
                    </div>
                    <div class="form__row">
                        <label for="langPerson" class="form__label">Languages</label>
                        <select id="langPerson" class="ui-select" v-model="searchParams.lang">
                            <option value="" hidden>Select Options</option>
                            <option v-for="language in langs">{{language}}</option>
                        </select>
                    </div>
                    <div class="form__row">
                        <label for="hairColor" class="form__label">Hair color</label>
                        <select id="hairColor" class="ui-select" v-model="searchParams.hair_color">
                            <option value="" hidden>Select Options</option>
                            <option v-for="hair in hair_colors">{{hair}}</option>
                        </select>
                    </div>
                    <div class="form__row">
                        <label for="eyesColor" class="form__label">Eyes</label>
                        <select id="eyesColor" class="ui-select" v-model="searchParams.eye_color">
                            <option value="" hidden>Select Options</option>
                            <option v-for="eyes in eye_colors">{{eyes}}</option>
                        </select>
                    </div>
                    <div class="form__row">
                        <label class="form__label">Height</label>
                        <select id="eyesColor" class="ui-select" v-model="searchParams.height">
                            <option value="" hidden>Select Options</option>
                            <option v-for="height in heights">{{height}}</option>
                        </select>

                    </div>

                    <double-range-slider
                        :label-name="'Weight'"
                        :max="100"
                        :min="40"
                        :step="1"
                        :init-max=searchRanges.weightTo
                        :init-min=searchRanges.weightFrom
                        @update_min_value="updateWeightFrom"
                        @update_max_value="updateWeightTo"
                        ref="rangeWeightSlider"
                    ></double-range-slider>
                    <div class="form__row">
                        <label for="statusPerson" class="form__label">Status</label>
                        <select id="statusPerson" class="ui-select" v-model="searchParams.marital_status">
                            <option value="" hidden>Select Options</option>
                            <option v-for="status in marital_statuses">{{status}}</option>
                        </select>
                    </div>
                    <div class="form__row">
                        <label for="children" class="form__label">Children</label>
                        <select id="children" class="ui-select" v-model="searchParams.kids">
                            <option value="" hidden>Select Options</option>
                            <option v-for="item in kids">{{item}}</option>
                        </select>
                    </div>
                    <div class="form__footer">
                        <button class="button button_link button_link-mobile" @click.prevent="resetSearch" v-if="$mq!=='desktop'">
                            <span>Reset</span>
                            <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                                <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#reload`" />
                            </svg>
                        </button>
                        <button class="button button_md button_primary" @click.prevent="searchUsers">
                            <span><i class="button__icon icon icon_search-white"></i>Search</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </portal>

</template>
<script>
import SelectCountry from "./SelectCountry.vue";
import DoubleRangeSlider from "../newModules/DoubleRangeSlider.vue";

export default {
    name: "Search",
    components: {DoubleRangeSlider, SelectCountry},
    props:['myUrl','show', 'myRole','chatOwnerId', 'countries', 'windowHeight','countUnread','searchDataInit'],
    data(){
        return {
            langs: ['English', 'Russian', 'Spanish', 'Portuguese', 'Italian', 'German', 'French', 'Georgian', 'Armenian', 'Arabic', 'Turkish'],
            marital_statuses: ['Married', 'Never Married', 'Separated', 'Divorced', 'Widowed', 'other'],
            kids: ['Yes', 'No'],
            heights: ['140-150', '150-160', '160-160', '160-165', '165-170', '170-175', '175-180', '180-185', '185-190', '190-195', '195-200', '200-205', '205-210', '210-215', '215+', 'other'],
            body_types: ['Average', 'A few extra pounds', 'Athletic', 'Muscular', 'Slim', 'other'],
            eye_colors: ['Blue', 'Brown', 'Green', 'Gray', 'Hazel', 'Green-brown', 'Gray-Green', 'other'],
            hair_colors: ['Black', 'Blond', 'Brown', 'Fair', 'Chestnut', 'Ginger', 'Bald', 'other'],
            ages:[20,25,30,35,40,45,50,55,60,65,70,75],
            weights:['40-50','50-60','60-70','70-80','80-90','90+'],
            searchWeights:null,
            searchMember:{
                name: null,
                country_id:null,
            },
            searchParams: {
                lang: null,
                hair_color: null,
                marital_status: null,
                kids: null,
                eye_color: null,
                height: null,
                body_type: null,
            },
            searchRanges:{
                ageFrom: null,
                ageTo: null,
                weightFrom: null,
                weightTo: null,
            }
        }
    },
    computed:{
        // weightFrom(){
        //     if (!this.searchWeights){
        //         return null
        //     }
        //     if (this.searchWeights === '90+'){
        //         return  90;
        //     }
        //     const arr = this.searchWeights.split('-');
        //
        //     return arr[0];
        // },
        // weightTo(){
        //     if (!this.searchWeights){
        //         return null
        //     }
        //     const arr = this.searchWeights.split('-');
        //
        //     return  arr[1];
        // }
    },
    created() {

    },
    mounted() {
        if(this.searchDataInit){
            console.log('searchDataInit')
            this.initParamsData(this.searchMember,this.searchDataInit.searchMembers)
            this.initParamsData(this.searchRanges,this.searchDataInit.searchRanges)
            this.initParamsData(this.searchParams,this.searchDataInit.searchParams)
            setTimeout(()=>{
                if(this.searchMember.country_id && this.$refs.selectCountry) this.$refs.selectCountry.setCountry(this.searchMember.country_id)
                else console.log({selectCountry: this.$refs.selectCountry})
            },10)

        }
    },
    methods:{
        clickPage(){
            if(this.$refs.selectCountry) this.$refs.selectCountry.closeOptions();
        },
        updateWeightFrom(value){
            // console.log(value);
            this.searchRanges.weightFrom = Number(value)
        },
        updateWeightTo(value){
            // console.log(value);
            this.searchRanges.weightTo = Number(value)
        },
        updateAgesFrom(value){
            // console.log(value);
            this.searchRanges.ageFrom = Number(value)
        },
        updateAgesTo(value){
            // console.log(value);
            this.searchRanges.ageTo = Number(value)
        },
        resetSearch(){
            this.searchWeights = null;
            this.searchRanges = {
                ageFrom: null,
                ageTo: null,
                weightFrom: null,
                weightTo: null,
            }
            this.searchMember = {
                name: null,
                country_id:null,
            }
            this.searchParams = {
                lang: null,
                hair_color: null,
                marital_status: null,
                kids: null,
                eye_color: null,
                height: null,
                body_type: null,
            }

            if(this.$refs.selectCountry) this.$refs.selectCountry.resetCountry();
            this.$refs.rangeAgesSlider.reset()
            this.$refs.rangeWeightSlider.reset()

        },
        selectedCountry(country){
            this.searchMember.country_id = country.id;
        },
        openMenu() {
            this.$emit('open_menu', false);
        },
        goToRequest() {
            this.$emit('go_to_request', false);
        },
        open() {
            this.$emit('open_search');
            document.body.classList.add("modal-open")
        },
        close() {
            this.$emit('close_search');
            document.body.classList.remove("modal-open")
        },
        getOnlyParams(obj){
            let resArr = {}
            for (let key in obj){
                if(obj[key]) {
                    resArr[key] = obj[key]
                }
            }
            return resArr;
        },
        initParamsData(obj,objInit){
            console.log('initParamsData')
            console.log({obj})
            console.log({objInit})

            if (!objInit || !this.isNotEmptyObject(objInit)) return
            for (let key in obj){
                if(objInit[key]) {
                    obj[key] = objInit[key]
                }
            }
        },
        searchUsers(){
            console.log('searchUsers')
            // this.searchRanges.weightFrom = this.weightFrom;
            // this.searchRanges.weightTo = this.weightTo;

            const searchParams = this.getOnlyParams(this.searchParams);
            const searchRanges = this.getOnlyParams(this.searchRanges);
            const searchMembers = this.getOnlyParams(this.searchMember);
            console.log(searchRanges);
            if (this.isNotEmptyObject(searchParams) || this.isNotEmptyObject(searchRanges) || this.isNotEmptyObject(searchMembers)){
                let searchData = {
                    chatOwnerId: this.chatOwnerId,
                    currentUserRole: this.myRole,
                    searchParams: searchParams,
                    searchRanges: searchRanges,
                    searchMembers: searchMembers,
                }
                console.log({searchData});
                axios.post('/search',searchData)
                    .then(response => {
                        console.log('success');
                        console.log(response.data);
                        if (!response.data.members.length){
                            alert('No results were found for your search')
                        } else {
                            this.$bus.$emit('search_complete', response.data.members, response.data.searchMemberIDs, searchData, Boolean(this.searchDataInit), response.data.views);
                            this.close()
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }else {
                alert('No search options selected')
            }
        },
        isNotEmptyObject(obj) {
            return Boolean(Object.keys(obj).length);
        },
    }
}
</script>
