<template>
    <div class="panel__tab panel__tab_favorite">
        <list-contact-opponents
            :s3-url="s3Url"
            :current-user="currentUser"
            :my-url="myUrl"
            :contacts="favorites"
            :role-opponent="roleOpponent"
            :type="'favorites'"
            :ban-list="banList"
            :is-new-version="false"
            :is-supported-webp="isSupportedWebp"
            @get_more_contacts="getMoreFavorites"
        ></list-contact-opponents>
    </div>
</template>
<script>

import ListContactOpponents from "../modules/ListContactOpponents.vue";

export default {
    name: "ContactFavorite",
    components: {ListContactOpponents},
    props:[
        'myUrl',
        's3Url',
        'currentUser',
        'chatOwner',
        'roleOpponent',
        'contacts',
        'type',
        'selectedContact',
        'isAllFavorite',
        'search',
        'banList',
        'windowHeight',
        'countUnread',
        'textCountUnread',
        'countContacts',
        'isSupportedWebp',
    ],
    data(){
        return{
            isDownloadFavorites:false,
        }
    },
    computed:{
        favorites(){
            return this.contacts.filter(contact=>{
                return contact[`favorite_${this.roleOpponent}`];
            })
        }
    },
    methods:{

        // removeOpponent(memberID){
        //     this.$emit('remove_opponent',memberID);
        // },
        openMenu() {
            this.$emit('open_menu', false);
        },
        closeFavorite(){
            this.$emit('close_favorite');
        },
        // goToRequest() {
        //     this.$emit('go_to_request');
        // },
        getMoreFavorites(){
            if (!this.isAllFavorite && !this.isDownloadFavorites){
                this.isDownloadFavorites = true ;
                // const url = this.currentUser.role === 'man'?
                //     '/get-more-favorites-for-man':
                //     '/get-more-favorites-for-woman';

                axios.post('/get-more-favorites/v2',{
                    last:this.favorites[this.favorites.length-1].last_time,
                    ownerID:this.chatOwner.id,
                    role:this.currentUser.role,
                    roleOpponent:this.roleOpponent,
                    countContacts: this.countContacts
                })
                    .then(response => {
                        console.log(response.data);
                        this.isDownloadFavorites = false ;
                        this.$bus.$emit('add_contacts',{
                            contacts:response.data.contacts,
                            messages:response.data.messages,
                            countUnread:response.data.countUnread,
                            type:'favorite',
                        })
                    })
                    .catch(e => {
                        console.log(e);
                        this.isDownloadFavorites = false ;
                        this.errorHandler(e);
                    })

            }
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    }
}
</script>

<style scoped>

</style>
