<template>
    <div class="gallery__item" @click.prevent="selectOpponent">

<!--        <gallery-item-mobile-->
<!--            v-if="$mq === 'tablet'"-->
<!--            :isBlocked="isBlocked"-->
<!--            :isNewMember="isNewMember"-->
<!--            :member="member"-->
<!--            :s3Url="s3Url"-->
<!--            :isFavorite="isFavorite"-->
<!--            :roleOpponent="roleOpponent"-->
<!--            @getZodiacSign="getZodiacSign()"-->
<!--        >-->
<!--        </gallery-item-mobile>-->

        <gallery-item-desktop

            :isBlocked="isBlocked"
            :isNewMember="isNewMember"
            :member="member"
            :s3Url="s3Url"
            :isFavorite="isFavorite"
            :myUrl="myUrl"
            :roleOpponent="roleOpponent"
            :is-supported-webp="isSupportedWebp"
            @getZodiacSign="getZodiacSign"
        >
        </gallery-item-desktop>

    </div>
</template>
<script>
import ButtonFavorite from "./ButtonFavorite.vue";
import GalleryItemMobile from "./GalleryItemMobile.vue";
import GalleryItemDesktop from "./GalleryItemDesktop.vue";

export default {
    name: "GalleryItem",
    components: {GalleryItemDesktop, GalleryItemMobile, ButtonFavorite},
    props: ['myUrl', 's3Url', 'member', 'isFavorite', 'roleOpponent', 'indicatorNewOpponent', 'isBlocked', 'isSupportedWebp'],
    mounted() {
        console.log(this.member)
    },
    computed: {
        profile() {
            return this.member[`${this.roleOpponent}_profile`]
        },
        memberAge() {
            if (!this.member || !this.profile) return '_'
            if (this.member.birthday) {
                // let diff = new Date()-new Date(birthday); // This is the difference in milliseconds.
                return Math.floor((new Date() - new Date(this.member.birthday)) / 31557600000); // Divide by 1000*60*60*24*365.25
            }
            return this.profile.age;
        },
        getZodiacSign() {
            let zodiacSign = '';
            if (this.member && this.member.birthday) {
                let birthday = new Date(this.member.birthday);
                // const zodiac =['', 'Capricorn', 'Aquarius', 'Pisces', 'Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn'];
                const zodiac = ['', 'capricorn', 'aquarius', 'pisces', 'aries', 'taurus', 'gemini', 'cancer', 'leo', 'virgo', 'libra', 'scorpio', 'sagittarius', 'capricorn'];
                const last_day = [0, 20, 19, 21, 21, 22, 22, 23, 23, 22, 23, 22, 21, 20];
                let month = birthday.getMonth() + 1;
                let day = birthday.getDate();
                zodiacSign = (Number(day) < last_day[month]) ? zodiac[month] : zodiac[month + 1];
            }
            return zodiacSign
        },
        isNewMember() {
            const now = new Date();
            const arr = this.member.created_at.split('T')
            const memberCreate = new Date(arr[0]);

            return now.getTime() - memberCreate.getTime() < this.indicatorNewOpponent * 1000;
        },
    },
    methods: {
        selectOpponent() {
            if (this.member && !this.member.lock && (this.roleOpponent === 'man' || this.member.valid)) {
                this.$bus.$emit('select_opponent', this.member, 'gallery', false);
            }
        },

    }
}
</script>

<style scoped>

</style>
