<template >
    <div class="gallery__item" @click.prevent="selectOpponent" >
                <div class="card card_gallery card_mobile" :class="{'is-blocked':isBlocked}" >
                    <span class="card__icon card__icon_new" v-if="isNewMember"><i class="icon icon_new"></i></span>
                    <span class="card__icon card__icon_view" v-if="member.isViewOpponent"><i
                        class="icon icon_view"></i></span>
                    <picture v-if="member.img_gallery">
                        <source type="image/webp" :srcset="`${s3Url}/${member.img_gallery_webp}`" v-if="isSupportedWebp && member.img_gallery_webp">
                        <img class="card__media" :src="`${s3Url}/${member.img_gallery}`" alt="gallery" width="320" height="468">
                    </picture>

                    <img class="card__media"
                         :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="400" height="400"
                         v-else>
                    <div class="card__caption caption">
                        <div class="person person_card">
                            <div class="person__info">
                            <span class="status status_online status_badge" v-if="member.online">
                                <i class="status__icon"></i>
                                Online
                            </span>
                                <span class="person__name">{{ member.name }}</span>
                                <span class="person__age person__age_inline" v-if="memberAge">{{ memberAge }}</span>
                                <span class="person__icon"><i class="icon icon_zodiac" :class="`icon_${getZodiacSign}`"></i></span>
                                <span class="person__location" v-if="member.country && member.country.countryName.length">
                                <i class="icon icon_marker"></i>
                                {{ getCountry() }}
                            </span>
                            </div>
                <button class="button button_round button_gradient button_favorite" :class="{'is-active':isFavorite}"
                        @click.stop.capture="clickFavorite">
                    <svg class="button__border" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" width="50" height="50">
                        <defs>
                            <linearGradient id="grad" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%"
                                            y2="0%">
                                <stop offset="0%" stop-color="#FF51F8"/>
                                <stop offset="50%" stop-color="#3F75FF"/>
                                <stop offset="100%" stop-color="#00D6BC"/>
                            </linearGradient>
                        </defs>
                        <circle cx="25" cy="25" r="22" fill="transparent" stroke="url(#grad)" stroke-width="2"/>
                    </svg>
                    <i class="button__icon icon"></i>
                </button>

                        </div>
                    </div>
                </div>
    </div>
</template>
<script>
export default {
    name    : "GalleryItemMobile",
    props   : ['isBlocked', "member", "s3Url", "isFavorite", "myUrl", "roleOpponent", "galleryInit", "isSupportedWebp"],
    computed:{
        profile() {
            return this.member[`${this.roleOpponent}_profile`]
        },
        memberAge() {
            if (!this.member || !this.profile) return '_'
            if (this.member.birthday) {
                // let diff = new Date()-new Date(birthday); // This is the difference in milliseconds.
                return Math.floor((new Date() - new Date(this.member.birthday)) / 31557600000); // Divide by 1000*60*60*24*365.25
            }
            return this.profile.age;
        },
        getZodiacSign() {
            let zodiacSign = '';
            if (this.member && this.member.birthday) {
                let birthday = new Date(this.member.birthday);
                // const zodiac =['', 'Capricorn', 'Aquarius', 'Pisces', 'Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn'];
                const zodiac = ['', 'capricorn', 'aquarius', 'pisces', 'aries', 'taurus', 'gemini', 'cancer', 'leo', 'virgo', 'libra', 'scorpio', 'sagittarius', 'capricorn'];
                const last_day = [0, 20, 19, 21, 21, 22, 22, 23, 23, 22, 23, 22, 21, 20];
                let month = birthday.getMonth() + 1;
                let day = birthday.getDate();
                zodiacSign = (Number(day) < last_day[month]) ? zodiac[month] : zodiac[month + 1];
            }
            return zodiacSign
        },
        isNewMember() {
            const now = new Date();
            const arr = this.member.created_at.split('T')
            const memberCreate = new Date(arr[0]);

            return now.getTime() - memberCreate.getTime() < this.indicatorNewOpponent * 1000;
        },
    },
    methods : {
        getCountry() {
            console.log('getCountry');
            if (!this.member || !this.member.country) return '_'
            return this.member.country.countryName.length > 15 ? this.member.country.countryName.slice(15) : this.member.country.countryName
        },
        clickFavorite() {
            console.log('clickFavorite');
            this.$bus.$emit('start_change_favorite', !this.isFavorite, this.member.id)
        },
        selectOpponent() {
            if (this.member && !this.member.lock && (this.roleOpponent === 'man' || this.member.valid)) {
                this.$bus.$emit('select_opponent', this.member);
            }
        },
    }
}
</script>
<style scoped>

</style>
