<template>
    <div class="profile__promo">
        <div class="loader" v-if="isConversion">
            <span class="loader__text text-danger" v-if="isConversion">Conversion in progress</span>
            <span class="loader__spinner"></span>
        </div>
        <CoolLightBox
            :items="promoMedia"
            :index="indexPromo"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @close="indexPromo = null">
        </CoolLightBox>
        <CoolLightBox
            :items="videos"
            :index="indexVideo"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @close="indexVideo = null">
        </CoolLightBox>
        <div class="slider slider_promo" v-if="$mq==='desktop'">
            <div class="slider__container" >
                <div class="hooper">
                    <div class="hooper-list">
                        <hooper-promo ref="sliderPromo"
                                      :my-url="myUrl"
                                      :s3-url="s3Url"
                                      :index-promo="indexPromo"
                                      :is-edit="true"
                                      :is-supported-webp="isSupportedWebp"
                                      :presentation_medias="presentationMedia"
                                      @delete_promo_media="deletePromoMedia"
                                      @set_promo_media="setPromoMedia"
                                      @show_videos="showVideos"
                                      @set_index_promo="setIndexPromo"
                        ></hooper-promo>
                    </div>
                </div>
                <button class="slider__button slider__button_dark slider__button_prev button" @click.prevent="slidePromoPrev"
                        v-if="presentationMedia && presentationMedia.length>1">
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                         height="12" fill="none">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                              d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                    </svg>
                </button>
                <button class="slider__button slider__button_dark slider__button_next button" @click.prevent="slidePromoNext"
                        v-if="presentationMedia && presentationMedia.length>1">
                    <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                         height="12" fill="none">
                        <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                              d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                    </svg>
                </button>
                <div class="slider__caption">
                    <button @click.prevent="showChatSettings" class="button button_sm button_primary button_theme">
                        <span><i class="button__icon icon icon_theme"></i>Set Chat</span>
                    </button>
                    <div class="profile__credits">
                        <i class="profile__icon icon icon_dollar"></i>
                        <span class="profile__credits-balance">
                                        <span class="text">Balance:</span>
                                        <span class="label" @click.prevent="openPayment">Refill</span>
                                    </span>
                        <span class="profile__credits-value"><span>{{(credits/100).toFixed(2)}}</span> Credits</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="slider slider_promo" v-else>
            <div class="slider__container">
                <div class="hooper">
                    <div class="hooper-list">
                        <hooper-promo ref="sliderPromo"
                                      :my-url="myUrl"
                                      :s3-url="s3Url"
                                      :index-promo="indexPromo"
                                      :is-edit="true"
                                      :is-supported-webp="isSupportedWebp"
                                      :presentation_medias="presentationMedia"
                                      @delete_promo_media="deletePromoMedia"
                                      @set_promo_media="setPromoMedia"
                                      @show_videos="showVideos"
                                      @set_index_promo="setIndexPromo"
                        ></hooper-promo>
                    </div>
                </div>
                <div class="slider__caption caption">
                        <div class="person person_card">
                            <div class="person__info">
                                <span class="person__name">{{ memberName }}</span>
                                <span class="person__age person__age_inline">{{ chatOwnerAge }}</span>
                                <!--                            status-->

                                <span class="status" :class="`status_${chatOwner.payment_status}`">
                                    <span class="status__icon"></span>
                                </span>

                                <span class="person__location"><i class="icon icon_location"></i>{{ country ? country.countryName: ''}}</span>
                            </div>
                            <button @click.prevent="showChatSettings" class="button button_sm button_primary button_theme">
                                <span><i class="button__icon icon icon_theme"></i></span></button>
                        </div>
                        <div class="profile__credits">
                        <i class="profile__icon icon icon_dollar"></i>
                        <span class="profile__credits-balance">
                                    <span class="text">Balance:</span>
                                    <span class="label" @click.prevent="openPayment">Refill</span>
                                </span>
                        <span class="profile__credits-value"><span>{{(credits/100).toFixed(2)}}</span> Credits</span>
                    </div>
                </div>
            </div>
        </div>
        <portal selector="#portal-target" v-if="modalCropper">
            <div class="modal modal_cropper is-active" tabindex="-1" role="dialog">
                <div class="modal__bg" @click.prevent="closeModalCropper"></div>
                <div class="modal__container">
                    <span class="modal__close button button_filled"  role="button" aria-label="Close" @click.prevent="closeModalCropper">
                        <i class="icon icon_close"></i>
                    </span>
                    <div class="modal-body">
                        <image-cropper
                            :my-url="myUrl"
                            :name-header="'Crop image'"
                            :src="imageSrc"
                            :width="870"
                            :height="500"
                            :avatar-size="100"
                            :is-make-thumb="false"
                            :disabled-button="disabledButton"
                            @crop="cropImages810X488"
                        ></image-cropper>
                    </div>
                </div>
            </div>
        </portal>
        <modal-show-video v-if="showModalVideo"
                          :video-src="srcVideo"
                          :type-video="typeVideo"
                          @close_modal="closeModalVideo"
                          @select_frame="selectVideoFrame"
        ></modal-show-video>
        <modal-error-conversation @close_modal="showModalErrorConversation=false" v-if="showModalErrorConversation"></modal-error-conversation>

<!--        modal A few words about yourself-->
        <portal selector="#portal-target">
            <div class="modal modal_md modal_account is-active"  tabindex="-1" role="dialog" v-if="modalWord">
                <div class="modal__bg" @click.prevent="closeModalWord"></div>
                <div class="modal__container">
                    <span class="modal__close" role="button" aria-label="Close" @click.prevent="closeModalWord"><i class="icon icon_close"></i></span>
                    <div class="modal__header">
                        <h2 class="modal__title">A few words about yourself</h2>
                    </div>
                    <div class="form form_horizontal">
                        <div class="form__row">
                            <textarea class="ui-textarea" maxlength="1200" v-model="chatOwner[currentUser.role+'_profile'].slogan"></textarea>
                        </div>
                        <div class="form__footer">
                            <button class="button button_md button_primary" @click.prevent="saveNewWord"><span>Save</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </portal>
    </div>

</template>

<script>


import HooperPromo from "../newModules/HooperPromo.vue";
import ImageCropper from "../modules/ImageCropper.vue";
import ModalShowVideo from "../newModules/modals/ModalShowVideo.vue";
import ModalErrorConversation from "../newModules/modals/ModalErrorConversation.vue";
import CropperModule from "../newModules/CropperModule.vue";

export default {
    name: "UploadPresentationMedia",
    components: {CropperModule, ModalErrorConversation, ModalShowVideo, ImageCropper, HooperPromo},
    props:[
        'presentationMedia',
        'memberID',
        'myUrl',
        's3Url',
        'roleMember',
        'isAccount',
        'type',
        'isAllowedEditWoman',
        'background',
        'maxsizeVideo',
        'maxsizeImage',
        'credits',
        'chatOwner',
        'chatOwnerAge',
        'country',
        'currentUser',
        'memberName',
        'isSupportedWebp'
    ],
    data(){
        return{
            presentationVideo:null,
            showModalErrorConversation:false,
            disabledButton:false,
            formData: null,
            isConversion:false,
            conversationUrl:'',
            imageSrc: '',
            indexPromo: null,
            modalCropper: false,
            videos: [],
            indexVideo: null,
            extens:['avi', '3gp', 'flv', 'm4v', 'mkv', 'mov', 'mp4', 'mpeg', 'mpg', 'ogg', 'webm'],
            modalWord: false,
            showModalVideo: false,
            srcVideo: '',
            typeVideo: '',
            typeMedia: '',
        }
    },
    computed:{
        isSlogan(){
            let role = this.currentUser.role
            let profile = role+'_profile'
            return this.chatOwner[profile].slogan ? this.chatOwner[profile].slogan : null
        },

        labelInput(){
            return this.isConversion?'Conversion in progress':this.disabledButton?'Loading...':'Upload media'
        },
        promoMedia() {
            if(!this.presentationMedia || !this.presentationMedia.length) return []
            return this.presentationMedia.map(item=>{
                return { src: `${this.s3Url}/${ item.src_origin ? item.src_origin: item.src}`}
            })
        },
    },
    beforeDestroy() {
        this.disconnectPresentationChannel()
        // this.$bus.$emit('close_modal')
    },
    mounted() {
        // this.$bus.$emit('open_modal')
    },
    methods:{
        closeModalVideo(){
            // posterImageFile = null;
            URL.revokeObjectURL(this.srcVideo);
            this.typeVideo = '';
            this.srcVideo = '';
            this.showModalVideo = false;
        },
        selectVideoFrame(blob){
            console.log('selectVideoFrame')
            this.imageSrc = URL.createObjectURL(blob);
            this.modalCropper = true;
            this.showModalVideo = false;
        },
        async cropImages810X488(canvas){
            const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
            let blobWebp = null
            if(this.isSupportedWebp) blobWebp = await new Promise(resolve => canvas.toBlob(resolve, "image/webp", 1));
            if(this.typeMedia==='video'){
                this.formData.append("poster",blob,'poster');
                if(this.isSupportedWebp && blobWebp) this.formData.append("poster_webp",blobWebp,'poster_webp');
            }else {
                this.formData.append('presentMedia',blob,'presentMedia');
                if(this.isSupportedWebp && blobWebp) this.formData.append("presentMediaWebp",blobWebp,'presentMediaWebp');
            }
            console.log('append media image');
            this.modalCropper = false;
            this.savePromoMedia();
        },
        closeModalCropper(){
            this.modalCropper = false
            URL.revokeObjectURL(this.imageSrc);
        },
        setIndexPromo(index){
            console.log('setIndexPromo: ' + index);
            this.indexPromo = index
        },
        showChatSettings(){
            console.log('showChatSettings')
            this.$emit('show_chat_settings')
        },
        openModalWord() {
            this.modalWord = true
            this.$bus.$emit('open_modal')
        },
        closeModalWord(){
            this.modalWord = false;
            this.$bus.$emit('close_modal')
        },
        saveNewWord(){
            this.modalWord = false;
            this.$emit('update_slogan', this.chatOwner[this.currentUser.role+'_profile'].slogan)
            this.$bus.$emit('close_modal')
        },
        showVideos(videoSrc) {
            this.videos[0] = {src : videoSrc, autoplay: true}
            this.indexVideo = 0
        },
        openPayment(){
            console.log('openPayment from Acc')
            // this.$bus.$emit('open_payment');
            console.log('openPaymentPage');
            if(this.$route.name !== 'PaymentPage') this.$router.push({name:'PaymentPage'});
        },
        deletePromoMedia(media,index){
            console.log('deletePromoMedia');
            console.log(media.id);
            console.log(index);
            this.$emit('delete_promo_media',index);

            axios.post(`/delete-presentation-media`,{
                id:media.id,
                // poster:media.poster,
                // poster_webp:media.poster_webp,
                // src:media.src,
                // src_webp:media.src_webp,
                // role:this.roleMember
            })
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                    this.$emit('errorhandler',e);
                })

        },
        getExtantionFile(fileName){
            let parts = fileName.split('.');
            let ext = '';
            if (parts.length > 1) {
                ext = parts.pop();
                return ext.toLowerCase();
            }
            return '';
        },
        setPromoMedia(event){

            console.log('setPromoMedia');
            const media = event.target.files[0];
            if (media){
                this.formData = new FormData();
                const ext = this.getExtantionFile(media.name)
                //ext === 'mp4' || ext === 'webm' || ext === 'mov'
                if(this.extens.includes(ext) ){
                    this.typeMedia = 'video'
                    if (media.size > this.maxsizeVideo || media.fileSize > this.maxsizeVideo) {
                        //show an alert to the user
                        // this.disabledButton = false;
                        alert(`Allowed file size exceeded. (Max. ${this.maxsizeVideo/ (1024*1024)} MB)`);
                    } else if( ext !== 'mp4'){
                            //presentation
                        this.convertMedia(media,'video',ext);
                    }else{
                        this.getPresentationVideoPoster(media);
                    }
                }else if(ext === 'png' || ext === 'jpeg' || ext === 'jpg' || ext === 'svg' || (this.isSupportedWebp && ext === 'webp')){
                    if (media.size > this.maxsizeImage || media.fileSize > this.maxsizeImage) {
                        //show an alert to the user
                        // this.disabledButton = false;
                        alert(`Allowed file size exceeded. (Max. ${this.maxsizeImage/ (1024*1024)} MB)`);
                    } else {
                        this.typeMedia = 'img'
                        // if(ext === 'svg'){
                        //     this.formData.append('presentMedia',media,'presentMedia');
                        //     this.savePromoMedia();
                        // }else {

                            // const image = new Image;
                            // image.onload = () => {
                            //     this.convertPhoto(media,image)
                            // };
                            this.imageSrc = URL.createObjectURL(media);
                            this.modalCropper = true;

                        // }


                        const imageOrigin = new Image();
                        imageOrigin.onload = async () => {
                            this.modalCropper = true;
                            const canvasOrigin = this.getCanvasImage(imageOrigin);
                            const blobOrigin = await new Promise(resolve => canvasOrigin.toBlob(resolve, "image/jpeg", 1));
                            this.formData.append('origin',blobOrigin,'origin');
                            // const blobOriginWebp = await new Promise(resolve => canvasOrigin.toBlob(resolve, "image/webp", 1));
                            // this.formData.append(`origin_webp`,blobOriginWebp,`origin_webp`);
                        };
                        imageOrigin.src = this.imageSrc;

                    }
                }else {
                    // this.disabledButton = false;
                    alert('This format is not supported!\n' +
                        'Supported formats .png .jpeg .jpg .svg, .mp4, .webm .mov');
                }
            }
        },
        convertMedia(media,typeMedia,ext){
            this.connectPresentationChannel();
            this.disabledButton = true;
            const formData = new FormData();
            formData.append('media',media,'media');
            formData.append('name',media.name);
            formData.append('type_media',typeMedia);
            formData.append('role',this.roleMember);
            formData.append('memberID',this.memberID);
            formData.append('type','presentation');
            formData.append('ext',ext);

            axios.post(`/send-media-for-conversion`,formData)
                .then(response => {
                    this.disabledButton = false;
                    console.log(response.data);
                    if (response.data.status === 'ok'){
                        this.isConversion = true;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.disabledButton = false;
                    //this.errorHandler(e);
                    this.$emit('errorhandler',e);
                })

        },
        connectPresentationChannel(){
            Echo.private(`presentation-${this.roleMember}.${this.memberID}`)
                .listen('ConversionMediaCompleted',(event)=>{
                    console.log(event);
                    this.isConversion = false;
                    if(event.status==="success"){
                        this.conversationUrl = event.path;
                        this.srcVideo = `${this.myUrl}/storage/${event.path}`;
                        this.typeVideo = 'video/mp4';
                        this.showModalVideo =  true;
                    }else {
                        this.showModalErrorConversation = true;
                    }

                    this.disconnectPresentationChannel();
                });
        },
        disconnectPresentationChannel(){
            Echo.leave(`presentation-${this.roleMember}.${this.memberID}`);
        },
        savePromoMedia(){
            if(this.memberID){
                console.log('send promo media');
                this.disabledButton = true;
                this.formData.append('memberID', this.memberID);
                this.formData.append('role', this.roleMember);
                this.formData.append('conversationUrl',this.conversationUrl);
                this.formData.append('typeMedia', this.typeMedia);

                axios.post(`/save-presentation-media`,this.formData)
                    .then(response => {
                        this.disabledButton = false;
                        console.log(response.data);
                        this.presentationVideo = null;
                        this.isConversion = false ;
                        this.conversationUrl = '';
                        this.typeMedia = '';
                        this.$emit('add_presentation_media',response.data.media);
                        this.$nextTick(()=>{
                            if(this.$refs.sliderPromo && this.$refs.sliderPromo.$refs.sliderPromo)
                                this.$refs.sliderPromo.$refs.sliderPromo.slideTo(0)
                        })
                    })
                    .catch(e => {
                        this.disabledButton = false;
                        console.log(e);
                        //this.errorHandler(e);
                        this.$emit('errorhandler',e);
                    })
            }
        },
        //old version
        getCanvasImage(imageEl){
            const w = imageEl.width;
            const h = imageEl.height;
            const canvas = document.createElement('canvas');
            canvas.width  = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(imageEl, 0, 0, w, h);
            return canvas;
        },

        convertPhoto(file,image){
            // let output = {};
            let fileOriginal = null;
            let jpegOriginal = null;


            const canvas = document.createElement("canvas"),
                ctx = canvas.getContext("2d");
            canvas.width = image.width;
            canvas.height = image.height;
            ctx.drawImage(image, 0, 0);
            canvas.toBlob((blob) => {
                fileOriginal = new File([blob], file.name, {
                    type: "application/octet-stream"
                });
                jpegOriginal = canvas.toDataURL("image/jpeg", .95);
                this.formData.append('originalImage',fileOriginal,'originalImage');
                console.log('append media image');
            }, "image/jpeg", .95)

        },
        getPresentationVideoPoster(video){
            console.log('getPresentationVideoPoster');
            // if (video){
            //     this.presentationVideo = URL.createObjectURL(video);
            //     console.log(this.presentationVideo);
            //     this.formData.append('presentMedia',video,'presentMedia');
            // }
            // setTimeout(() => {
            //     this.shoot()
            //         .then(() => {
            //             console.log('poster created');
            //             this.savePromoMedia();
            //         })
            // }, 1000);
            if(video){
                this.formData.append('presentMedia',video,'presentMedia');
                this.typeVideo = video.type;
                this.srcVideo = URL.createObjectURL(video);
                this.showModalVideo = true;
            }
            // else {
            //     this.typeVideo = 'video/mp4';
            //     this.srcVideo = this.conversationUrl;
            // }

        },

        // async shoot() {
        //     const videoPlayer  = this.$refs.presentationVideo;
        //     // const videoPlayer  = document.getElementById('presentationVideo');
        //     console.log(videoPlayer);
        //     const canvas = this.captureVideo(videoPlayer);
        //     const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 1));
        //     if (blob){
        //         this.formData.append("poster",blob,'poster');
        //     }else {
        //         console.error('poster not created')
        //     }
        //
        //     // this.sendVideo(formData);
        // },
        captureVideo(video){
            const w = video.videoWidth * 0.75 ;
            const h = video.videoHeight * 0.75;
            const canvas = document.createElement('canvas');
            canvas.width  = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(video, 0, 0, w, h);
            return canvas;
        },
        slidePromoPrev() {
            if(this.$refs.sliderPromo && this.$refs.sliderPromo.$refs.sliderPromo)
                this.$refs.sliderPromo.$refs.sliderPromo.slidePrev()
        },
        slidePromoNext() {
            if(this.$refs.sliderPromo && this.$refs.sliderPromo.$refs.sliderPromo)
                this.$refs.sliderPromo.$refs.sliderPromo.slideNext()
        },
        slidePromoTo(index) {
            if(this.$refs.sliderPromo && this.$refs.sliderPromo.$refs.sliderPromo)
                this.$refs.sliderPromo.$refs.sliderPromo.slideTo(index)
        },
        updateSliderPromo() {
            setTimeout(()=> {
                if(this.$refs.sliderPromo && this.$refs.sliderPromo.$refs.sliderPromo)
                    this.$refs.sliderPromo.$refs.sliderPromo.update()
                console.log('update slider promo');
            }, 100)
        },
    }
}
</script>

<style scoped>

</style>
