<template>
    <portal selector="#portal-target" @click.stop>
        <div class="modal modal_md modal_complain is-active" tabindex="-1" role="dialog" >
            <div class="modal__bg" @click.prevent="closeModalComplain"></div>
            <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close" @click.prevent="closeModalComplain"><i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h2 class="modal__title">Complain</h2>
                </div>
                <div class="form form_horizontal">
                    <div class="form__row">
                        <textarea :class="{'text-danger' : complain_message.length > max_char}" v-model="complain_message" :placeholder="`Why do you want to report this account? (MAX characters ${max_char})`" class="ui-textarea"></textarea>
                    </div>
                    <div class="form__footer">
                        <button @click.prevent="complainOpponent" class="button button_md button_primary"><span>Send</span></button>
                    </div>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
export default {
    name: "ComplainModal",
    props: ['opponent', 'chatOwner', 'role'],
    data(){
        return{
            complain_message: '',
            max_char: 1000,
        }
    },
    methods:{
        closeModalComplain(){
            this.complain_message = ''
            this.$bus.$emit('close_modal_complain')
        },
        complainOpponent(){
            if (this.complain_message.length > this.max_char){
                alert('maximum number of characters - '+this.max_char)
            }else{
                let woman_id = (this.role === 'woman') ? this.chatOwner.id : this.opponent.id
                let man_id = (this.role === 'man') ? this.chatOwner.id : this.opponent.id
                this.$bus.$emit('complain_opponent', {author: this.role, complain_message: this.complain_message, man_id: man_id, woman_id: woman_id})
                this.complain_message = ''
            }

        }
    }
}
</script>

<style scoped>

</style>
