<template>
    <div class="" id="tab1" :class="{'tab-pane tab-pane_favorite active': $mq ==='desktop','request request_mob' : $mq !=='desktop'}">
        <template v-if="$mq==='desktop'">
            <list-contact-opponents
                :s3-url="s3Url"
                :current-user="currentUser"
                :my-url="myUrl"
                :contacts="favorites"
                :role-opponent="roleOpponent"
                :type="'favorites'"
                :ban-list="banList"
                :is-new-version="false"
                :is-supported-webp="isSupportedWebp"
                @get_more_contacts="getMoreFavorites"
            ></list-contact-opponents>
        </template>
        <template v-else>
            <div class="request__container" :style="`height: ${windowHeight}px`">
                <div class="request__header mobile-head">
                    <div class="request__col">
                        <button class="btn btn_icon btn_back" @click="closeFavorite">
                             <span class="holiday">
                                 <i class="icon icon-btn-back"></i>
                             </span>
                            <i class="icomoon icon-left"></i>
                        </button>
                        <span class="title">Favorite</span>
                    </div>
                    <div class="actions">
                        <button class="btn btn_icon btn_req" @click.prevent="closeFavorite">
                            <i class="icomoon icon-chats">
                            <i class="icomoon icon-bubble" v-if="countUnread">
                                <span :class="{'text-small':(countUnread > 99)}">{{textCountUnread}}</span>
                            </i></i>
                        </button>
                        <button class="btn btn_icon btn_menu" @click.prevent="openMenu"><i class="icomoon icon-group"></i></button>
                    </div>
                </div>
<!--                <div class="request__body">-->
                    <list-contact-opponents
                        :s3-url="s3Url"
                        :current-user="currentUser"
                        :my-url="myUrl"
                        :contacts="favorites"
                        :role-opponent="roleOpponent"
                        :type="'favorites'"
                        :ban-list="banList"
                        :is-new-version="false"
                        :is-supported-webp="isSupportedWebp"
                        @get_more_contacts="getMoreFavorites"
                    ></list-contact-opponents>
<!--                </div>-->
            </div>
        </template>

    </div>
</template>

<script>
import ListContactOpponents from "./ListContactOpponents";
export default {
    name: "ChatFavorite",
    components: {ListContactOpponents},
    props:[
        'myUrl',
        's3Url',
        'currentUser',
        'chatOwner',
        'roleOpponent',
        'contacts',
        'type',
        'selectedContact',
        'isAllFavorite',
        'search',
        'banList',
        'windowHeight',
        'countUnread',
        'textCountUnread',
        'countContacts',
        'isSupportedWebp',
    ],
    data(){
        return{
            isDownloadFavorites:false,
        }
    },
    computed:{
        favorites(){
            return this.contacts.filter(contact=>{
                return contact[`favorite_${this.roleOpponent}`];
            })
        }
    },
    methods:{
        // removeOpponent(memberID){
        //     this.$emit('remove_opponent',memberID);
        // },
        openMenu() {
            this.$emit('open_menu', false);
        },
        closeFavorite(){
            this.$emit('close_favorite');
        },
        // goToRequest() {
        //     this.$emit('go_to_request');
        // },
        getMoreFavorites(){
            if(!this.favorites || !this.favorites.length) return
            if (!this.isAllFavorite && !this.isDownloadFavorites){
                this.isDownloadFavorites = true ;
                const lastContact = this.favorites[this.favorites.length-1]
                if(!lastContact) return;
                this.isDownloadFavorites = true ;

                axios.post('/get-more-favorites/v2',{
                    last:lastContact.last_time,
                    ownerID:this.chatOwner.id,
                    role:this.currentUser.role,
                    roleOpponent:this.roleOpponent,
                    countContacts: this.countContacts
                })
                    .then(response => {
                        console.log(response.data);
                        this.isDownloadFavorites = false ;
                        this.$bus.$emit('add_contacts',{
                            contacts:response.data.contacts,
                            messages:response.data.messages,
                            countUnread:response.data.countUnread,
                            type:'favorite',
                        })
                    })
                    .catch(e => {
                        console.log(e);
                        this.isDownloadFavorites = false ;
                        this.errorHandler(e);
                    })

            }
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
    }
}
</script>

<style scoped>

</style>
