<template>
    <portal selector="#portal-target">
        <div class="modal modal_md modal_services is-active" tabindex="-1" role="dialog" >
            <div class="modal__bg"></div>
            <div class="modal__container">
                <div class="modal__header">
                    <h2 class="modal__title text-gradient">Congratulation!</h2>
                </div>
                <div class="modal__body text-center">
                    <h3 class="title">Welcome to Hablario</h3>
                    <template v-if="currentUser && currentUser.role ==='man'">
                        <p>You have successfully registered.</p>
                        <p>To ensure you receive ${{this.bonusesCredits/100}} free credits, kindly verify that you've provided a valid email address.</p>
                        <p>Expect an email from us, giving you the option to subscribe to our newsletter if you so desire.</p>
                        <p>Thank you for choosing us!</p>
                    </template>

                    <template v-else>
                        <p>You have successfully registered</p>
                        <p>Your account is being checked by the moderator</p>
                        <p>After that, a confirmation of your validation will be sent to the email you specified</p>
                        <p>Thank you for choosing our project</p>
                    </template>
                    <div class="mb-2">
                        <label for="subscribe" v-if="currentUser.email_verified">
                            <input class="ui-checkbox" id="subscribe" type="checkbox" v-model="settingsInit.is_notifications">
                            <span class="label">Subscribe</span>
                        </label>
                    </div>
                </div>
                <div class="modal__footer" v-if="currentUser && (currentUser.role ==='man' || (chatOwner && chatOwner.valid))">
                    <button @click.prevent="confirmRegistration" class="button button_md button_primary" ><span>OK!</span></button>
                </div>
            </div>
        </div>
    </portal>

</template>

<script>
export default {
    name: "SuccessRegister",
    props:['currentUser','myUrl','chatOwner','bonusesCredits','settingsInit'],
    data(){
        return{
            isSubscribe: false,
            redirectToFromPromoProfile:''
        }
    },
    created() {
        console.log('created from SuccessRegister')
        //console.log(`bonusesCredits ${this.bonusesCredits}`)
        if (!this.currentUser){
            this.$router.push({path: '/'});
        }
    },
    mounted() {
        if(this.settingsInit){
            this.isSubscribe = this.settingsInit.is_notifications;
        }
        this.$bus.$emit('open_modal');
    },
    methods:{
        confirmRegistration() {
            console.log('confirmRegistration');
            //if (this.currentUser.email_verified){
                axios.post('/save-subscribe-status',
                    { settings_id: this.settingsInit.id, is_notifications: this.settingsInit.is_notifications ? 1 : 0,
                      email_verified: this.currentUser.email_verified
                    })
                    .then(response=>{
                        console.log(response.data);
                        console.log(response.data.redirectToFromPromoProfile);
                        this.redirectToFromPromoProfile = response.data.redirectToFromPromoProfile
                    })
                    .catch(err => {
                        console.log(err)
                    }).finally(()=>{
                        this.modalValidEmail = false;
                        this.$bus.$emit('close_modal');
                        this.gotoHome()
                });
            //}




            // axios.get(`/send-valid-email/${this.isSubscribe ? '1':'0'}`)
            //     .then(response => {
            //         console.log(response.data);
            //         if (response.data.status === 'success'){
            //             this.$bus.$emit('show_modal_send_validation_email',this.isSubscribe);
            //         }
            //         this.gotoHome()
            //     })
            //     .catch(e => {
            //         console.log(e);
            //         this.gotoHome()
            //         this.errorHandler(e);
            //     })

        },
        gotoHome(){
            // if(this.currentUser && this.currentUser.role === 'man' && this.chatOwner && this.chatOwner.lead === 'older'){
            //     this.$router.push({path: '/profile/619'})
            // }else
            if(this.redirectToFromPromoProfile) {
                console.log('redirectToFromPromoProfile')
                console.log(this.redirectToFromPromoProfile)
                this.$router.push({path: this.redirectToFromPromoProfile})
            }
            else if(this.currentUser && this.currentUser.role === 'man' && this.chatOwner && this.chatOwner.lead === 'Role-Teacher'){
                this.$router.push({path: '/profile/2733'})
            }
            else {
                this.$router.push({path: '/gallery'})
            }
        },
        logout(){
            this.$emit('logout');
        },
    }
}
</script>

<style scoped>
</style>
