<template>
  <div class="picture animation">
    <i class="icon ellipse1 animation__rotate_right"></i>
    <i class="icon ellipse2 animation__rotate_left"></i>
    <i class="icon ellipse3 animation__rotate_right"></i>
    <div class="carousel">
      <transition-group name="fade" tag="div"  class="carousel__wrapper">
          <picture v-for="i in [currentIndex]" :key='i' v-if="i === currentIndex">
              <source :srcset="currentImg.srcsetAvif" type="image/avif">
              <source :srcset="currentImg.srcsetWebp" type="image/webp">
              <img class="carousel__slide" :srcset="currentImg.srcsetPng" :src="currentImg.srcPng" :alt="altObject(currentImg)" >
          </picture>
      </transition-group>
    </div>
    <i class="icon gifts animation__scale_gifts"></i>
    <i class="icon calls animation__scale_calls"></i>
    <i class="icon bonuses1 animation__scale_bonuses1"></i>
    <i class="icon bonuses2 animation__scale_bonuses2"></i>
    <i class="icon messages animation__scale_messages1"></i>
    <i class="icon messages2 animation__scale_messages2"></i>
    <i class="icon video animation__scale_video"></i>
    <i class="icon voice animation__scale_voice"></i>
    <i class="icon mystories animation__scale_mystories"></i>
    <i class="icon private_contents animation__scale_private_contents"></i>
  </div>
</template>
<script>
export default {
  name: "carousel",
  props: ['cdnUrl', 'myUrl',],
  computed: {
    currentImg: function () {
      return this.slides[Math.abs(this.currentIndex) % this.slides.length];
    },
  },
  data() {
    return {
      slides: [
        {
          srcsetAvif: `${this.myUrl}/source/images/landing/landing-slider/slide_1.avif`,
          srcsetWebp: `${this.myUrl}/source/images/landing/landing-slider/slide_1.webp`,
          srcsetPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_1.png 1920w, ${this.myUrl}/source/images/landing/landing-slider/1920/slide_1.png 1280w`,
          srcPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_1.png`,
          id: 1
        },
        {
          srcsetAvif: `${this.myUrl}/source/images/landing/landing-slider/slide_2.avif`,
          srcsetWebp: `${this.myUrl}/source/images/landing/landing-slider/slide_2.webp`,
          srcsetPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_2.png 1920w, ${this.myUrl}/source/images/landing/landing-slider/1920/slide_2.png 1280w`,
          srcPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_2.png`,
          id: 2
        },
        {
          srcsetAvif: `${this.myUrl}/source/images/landing/landing-slider/slide_3.avif`,
          srcsetWebp: `${this.myUrl}/source/images/landing/landing-slider/slide_3.webp`,
          srcsetPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_3.png 1920w, ${this.myUrl}/source/images/landing/landing-slider/1920/slide_3.png 1280w`,
          srcPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_3.png`,
          id: 3
        },
        {
          srcsetAvif: `${this.myUrl}/source/images/landing/landing-slider/slide_4.avif`,
          srcsetWebp: `${this.myUrl}/source/images/landing/landing-slider/slide_4.webp`,
          srcsetPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_4.png 1920w, ${this.myUrl}/source/images/landing/landing-slider/1920/slide_4.png 1280w`,
          srcPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_4.png`,
          id: 4
        },
        {
          srcsetAvif: `${this.myUrl}/source/images/landing/landing-slider/slide_5.avif`,
          srcsetWebp: `${this.myUrl}/source/images/landing/landing-slider/slide_5.webp`,
          srcsetPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_5.png 1920w, ${this.myUrl}/source/images/landing/landing-slider/1920/slide_5.png 1280w`,
          srcPng: `${this.myUrl}/source/images/landing/landing-slider/1920/slide_5.png`,
          id: 5
        }
      ],
      timer: null,
      currentIndex: 0,
      show: true,
      duration : 5000
    }
  },
  mounted: function () {
    this.startSlide();
  },
  methods:{
    startSlide: function () {
      this.timer = setInterval(this.next, this.duration);
    },
    next: function () {
      this.currentIndex += 1;
    },
    prev: function () {
      this.currentIndex -= 1;
    },
    classObject: function(slide){
      return 'carousel__slide_' + slide.id
    },
    altObject:function (slide) {
      return 'photo ' + slide.id
    }
  }
}
</script>
<style >

.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0
}


</style>
