<template>
    <div class="chat chat_support" :class="{'chat_mobile' : $mq !=='desktop'}">
        <div class="chat__container" :style="`height: ${windowHeight}px`">
            <div class="chat__header">
                <div class="chat__avatar">
                    <button class="btn btn_icon btn_back" @click="closeSupport" v-if="$mq !=='desktop'">
                         <span class="holiday">
                             <i class="icon icon-btn-back"></i>
                         </span>
                        <i class="icomoon icon-left"></i>
                    </button>
                    <span class="avatar"></span>
                    <span class="chat__caption">
                        <span class="status status_on">online</span>
                        <span class="name">Support</span>
                    </span>
                </div>
                <div class="chat__action">
                    <template v-if="$mq ==='desktop'">
                        <button class="btn btn_icon btn_close" @click.prevent="closeSupport"><i class="icomoon icon-closed"></i></button>
                    </template>
                    <template v-else>
                        <button class="btn btn_icon btn_req" @click.prevent="goToRequest">
                            <i class="icomoon icon-chats">
                                <i class="icomoon icon-bubble" v-if="countUnread">
                                    <span :class="{'text-small':(countUnread > 99)}">{{textCountUnread}}</span>
                            </i></i>
                        </button>
                        <button class="btn btn_icon btn_menu" @click.prevent="openMenu"><i class="icomoon icon-group"></i></button>
                    </template>
                </div>
            </div>
            <div class="chat__body" ref="supportChatList" @scroll.passive="onScroll">
                <ul class="chat__list">
                    <template v-for="(messageArr, date) in supportMessages" >
                        <li class="text-center"><span class="chat__date">{{writeDate(date)}}</span></li>
                        <li class="chat__item" :class="{'is-start':message.admin_id,'is-end':!message.admin_id}" v-for="message in messageArr" :key="message.id">
                            <span class="message message_base message_color1" v-if="!message.src">
                                <span class="message__text" :style="`font-size: ${messageSize}px`">{{message.text}}</span>
                                <span class="date"><i class="fe fe-check"></i>{{messageTime(message)}}</span>
                            </span>
                            <span class="message message_media" v-else-if="isPicture(message.src)" @click="openChatMedia(message.src)">
                                <span class="message__thumb">
                                   <img :src="`${s3Url}/${message.src}`" alt="">
                                </span>
                            </span>
                            <span class="message message_inform" v-else>
                            <!--                             <button class="btn">Download file</button>-->
                                <a class="btn btn_md btn_bordered" :href="`${s3Url}/${message.src}`" download target="_blank">Download file</a>

                                <span class="date"><i class="fe fe-check"></i>{{messageTime(message)}}</span>
                            </span>
                        </li>
<!--                        add message thumb and massage with button-->
                        <li class="chat__item is-end">
                            <span class="message message_media message_color1">
                                <svg class="message__triangle" xmlns="http://www.w3.org/2000/svg" width="12" height="20" fill="none">
                                    <path fill="currentColor" d="m0 0 11.4209 15.3808C12.8242 17.2706 11.518 20 9.21035 20H0V0Z"/>
                                </svg>
                                <span class="message__thumb">
                                    <img src="assets/images/chat/blur-image.jpg" alt="">
                                </span>
                                 <span class="message__date">8:23 PM
                                    <svg class="chat__check-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="14">
                                        <use xlink:href="assets/images/icons/icons.svg#check" />
                                    </svg>
                                </span>
                            </span>
                        </li>
                        <li class="chat__item is-end">
                            <span class="message message_simple">
                                <a class="button button_sm button_border" download target="_blank">Download file</a>
                                <span class="message__date">8:23 PM
                                    <svg class="chat__check-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="14">
                                        <use xlink:href="assets/images/icons/icons.svg#check" />
                                    </svg>
                                </span>
                            </span>
                        </li>
                    </template>

<!--                    <li class="chat__item is-end">-->
<!--                         <span class="message message_base message_color1">-->
<!--                            <span class="message__text">Message</span>-->
<!--                            <span class="date"><i class="fe fe-check"></i>15:22</span>-->
<!--                        </span>-->
<!--                    </li>-->
                </ul>
            </div>
            <div class="chat__footer">
                <p class="text-info" v-if="supportTyping">admin typing...</p>
                <div class="chat__input">
                    <button class="btn btn_icon btn_file">
                        <i class="icomoon icon-attach"></i>
                        <input type="file" :accept="getExts" @change="saveFile">
                    </button>
                    <div ref="inputSupportMessage"
                         class="ui-input"
                         :class="{'is-invalid': !messageTextMax}"
                         :contenteditable=true
                         data-placeholder="Message..."
                         @keypress="sendEventTyping"
                         @input="changeMessage"
                         @keyup.enter="sendSupportMessage"
                         tabindex="-1"
                    ></div>
<!--                    <button class="btn btn_icon btn_emoji"><i class="icomoon icon-smile"></i></button>-->
                    <button class="btn btn_icon btn_send" @click.prevent="sendSupportMessage">
                        <i class="icomoon icon-send" v-if="$mq==='desktop'"><span class="path1"></span><span class="path2"></span></i>
                        <i class="icomoon icon-send-mob" v-else></i>
                    </button>
                </div>
<!--                <preview-media ref="previewMedia"-->
<!--                               :media="previewMedia"-->
<!--                               :is-video="!!fileVideo"-->
<!--                               :is-voice="!!fileVoice"-->
<!--                               @close_preview="resetPreviewMedia"-->
<!--                               v-if="previewMedia"-->
<!--                ></preview-media>-->
            </div>
        </div>
    </div>
</template>

<script>
// import PreviewMedia from "./modules/PreviewMedia";
const month = ['','January','February','March','April','May','June','July','August','September','October','November','December'];

export default {
    name: "ClientSupportChat",
    props: [
        'supportMessages',
        'supportContact',
        'messageSize',
        'currentUser',
        'showSupport',
        'myUrl',
        's3Url',
        'lastSupportMessagesID',
        'isAllSupportMessages',
        'supportTyping',
        'windowHeight',
        'countUnread',
        'textCountUnread'
    ],
    // components: {
    //    // PreviewMedia,
    // },
    data(){
        return{
            message:'',
            isSendTyping:false,
            exts:['png','jpeg','jpg','svg','mp3','mp4','txt','pdf','doc','docx','xls','xlsx'],
            maxsize: 10*1024*1024,
            // previewMedia: null,
            fileVideo: null,
            fileVoice: null,
            mediaChat: [],
            indexMedia: null,
            messageTextMaxSize: 2000,
            isDisabled:false,
        }
    },
    computed:{
        getExts(){
            let accept = '';
            this.exts.forEach(ext=>{
                accept += `.${ext}, `
            });
            return accept;
        },
        messageTextMax() {
            return this.message.length < this. messageTextMaxSize
        },
    },
    mounted() {
        this.$bus.$on('support_chat_scroll_down',this.scrollDown);
        this.$refs.inputSupportMessage.addEventListener("paste", this.pasteToInput);
    },
    beforeDestroy() {
        this.$bus.$off('support_chat_scroll_down',this.scrollDown);
        this.$refs.inputSupportMessage.removeEventListener("paste", this.pasteToInput)
    },
    methods:{
        pasteToInput(e) {
            e.preventDefault();
            let text = e.clipboardData.getData("text/plain");
            document.execCommand("insertHTML", false, text);
        },
        openMenu() {
            this.$emit('open_menu', false);
        },
        goToRequest() {
            this.$emit('go_to_request', false);
        },
        onScroll(event){
            if (!this.isAllSupportMessages && this.supportContact && this.lastSupportMessagesID){
                if (this.$refs.supportChatList.scrollTop === 0){
                    const oldHeight = this.$refs.supportChatList.scrollHeight ;
                    console.log('scroll top');
                    axios.get(`/get-more-support-messages/${this.supportContact.id}/${this.lastSupportMessagesID}`)
                        .then(response=>{
                            console.log(response.data);
                            this.$bus.$emit('add_more_support_message',response.data.messages);
                            this.$nextTick(()=>{
                                setTimeout(()=>{
                                    this.$refs.supportChatList.scrollTop = this.$refs.supportChatList.scrollHeight - oldHeight;
                                },250);
                            });
                        })
                        .catch(err => {
                            this.errorHandler(err);
                        });
                    // this.$bus.$emit('more_support_messages',this.$refs.supportChatList)
                }
            }
        },
        openChatMedia(data){
            this.$emit('open_chat_media', data);
        },
        // resetPreviewMedia(){
        //     this.isTextInput = true;
        //     this.previewMedia = null;
        //     this.fileImage = null;
        //     this.fileVideo = null;
        //     this.originVideo = null;
        //     this.fileVoice = null;
        //     this.mediaName = '' ;
        //     this.$refs.inputMessage.textContent = '';
        //     this.$refs.inputMessage.innerText = '';
        //     this.typeMessage = 'message';
        //     this.isConversion = false ;
        //
        // },
        messageTime(message){
            return new Date(message.created_at).toLocaleTimeString() ;
        },
        writeDate(date){
            const arr = date.split('.')
            return `${month[Number(arr[1])]} ${arr[0]}`
        },
        isPicture(src){
            const ext = this.getExtantionFile(src);
            return ext === 'png' || ext === 'jpeg' || ext === 'jpg' || ext === 'svg'
        },
        changeMessage(event){
            const message = event.target.innerText || event.target.textContent;
            this.message = message;
        },
        closeSupport() {
            this.$emit('close-support')
        },
        sendEventTyping(){
            if (!this.isSendTyping){
                this.$bus.$emit('is_typing_support');
                this.isSendTyping = true ;
                setTimeout(()=>{
                    this.isSendTyping = false ;
                },1500);
            }
        },
        scrollDown(){
            console.log('scrollDown');
            // console.log(this.$refs.chatOperatorContainer);
            // const  container = document.getElementById('chatoperatorcontainer') ;
            if (this.$refs.supportChatList) {
                setTimeout(()=>{
                    this.$refs.supportChatList.scrollTop = this.$refs.supportChatList.scrollHeight;
                },0)
            }
        },
        // classMessage(adminID){
        //     return adminID ? 'msg-start' : 'msg-end'
        // },
        sendSupportMessage(){

            console.log(this.message);
            if (!this.isDisabled && this.message && this.message.replace(/\s+/g, '').length !== 0) {
                if (this.messageTextMax ){
                    this.isDisabled = true;
                    axios.post(`/send-support-message/${this.currentUser.id}`,{
                        contact_id:this.supportContact.id,
                        text:this.message,
                        src:'',
                        admin_id:null,
                    })
                        .then(response => {
                            console.log(response.data);
                            this.isDisabled = false;
                            this.newMessageHandler(response.data)
                        })
                        .catch(e => {
                            console.log(e);
                            this.isDisabled = false;
                            this.errorHandler(e);
                        })
                }else {
                    alert('The message length cannot be longer than 2000 characters');
                }
            }
        },
        newMessageHandler(data){
            this.message = '';
            this.$refs.inputSupportMessage.textContent = '';
            this.$refs.inputSupportMessage.innerText = '';
            this.$bus.$emit('send_support_message',data);
            this.scrollDown();
        },
        errorHandler(err){
            this.$bus.$emit('error_handler',err);
        },
        getExtantionFile(fileName){
            let parts = fileName.split('.');
            let ext = '';
            if (parts.length > 1) {
                ext = parts.pop();
                return ext.toLowerCase();
            }
            return '';
        },
        saveFile(e){
            console.log('save file');
            if (this.isDisabled) return;
            // this.isRead = true;
            // clearTimeout(this.timer) ;
            let file = e.target.files[0];
            console.log(file);
            if (file.size > this.maxsize || file.fileSize > this.maxsize) {
                //show an alert to the user
                alert(`Allowed file size exceeded. (Max. ${this.maxsize / (1024 * 1024)} MB)`);
            }else if(this.exts.includes(this.getExtantionFile(file.name))){
                this.isDisabled = true;
                let data = new FormData();
                data.append('file', file);
                // data.append('name', file.name);
                data.append('contact_id',this.supportContact.id);
                data.append('admin_id',null);
                data.append('text', file.name);
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                };

                axios.post('/send-file-support-chat' ,data, config)
                    .then(response=>{
                        console.log(response.data);
                        this.isDisabled = false;
                        if (response.data.message){
                            this.$bus.$emit('send_support_message',response.data);
                            this.scrollDown();
                        }
                    })
                    .catch(err => {
                        this.isDisabled = false;
                        this.errorHandler(err);
                    });
            }else {
                alert('This file type is not supported. Supported files - '+ this.exts.toString());
            }
            e.target.value = null;

        },

    }
}
</script>

<style scoped>

</style>
