<template>
    <div v-if="member">
        <div class="present-block">
            <button class="button button_sm button_bordered button_rounded"
                    @click.stop.capture="toggleLinkModal"
                    :disabled=giftProcessing v-if="$mq === 'desktop' && member.woman_link">
                <span><i class="button__icon icon icon_cristal"></i></span>
            </button>
            <!--   mobile     -->
            <button class="button button_round button_gradient" v-if="$mq !== 'desktop' && member.woman_link"
                    @click.stop.capture="toggleLinkModal" :disabled=giftProcessing>
                <svg class="button__border" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" width="50" height="50">
                    <defs>
                        <linearGradient id="grad" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%"
                                        y2="0%">
                            <stop offset="0%" stop-color="#FF51F8"/>
                            <stop offset="50%" stop-color="#3F75FF"/>
                            <stop offset="100%" stop-color="#00D6BC"/>
                        </linearGradient>
                    </defs>
                    <circle cx="25" cy="25" r="22" fill="transparent" stroke="url(#grad)" stroke-width="2"/>
                </svg>
                <span><i class="button__icon icon icon_cristal"></i></span>
            </button>
            <!--        -->
<!--            <button class="button button_sm button_bordered button_rounded"-->
<!--                    @click.stop.capture="toggleDonateModal"-->
<!--                    :disabled=giftProcessing  v-if="$mq === 'desktop'">-->
<!--                <span> <i class="button__icon icon icon_money"></i></span>-->
<!--            </button>-->
            <!--   mobile     -->
<!--            <button class="button button_round button_gradient" v-else @click.stop.capture="toggleDonateModal" :disabled=giftProcessing>-->
<!--                <svg class="button__border" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" width="50" height="50">-->
<!--                    <defs>-->
<!--                        <linearGradient id="grad" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%"-->
<!--                                        y2="0%">-->
<!--                            <stop offset="0%" stop-color="#FF51F8"/>-->
<!--                            <stop offset="50%" stop-color="#3F75FF"/>-->
<!--                            <stop offset="100%" stop-color="#00D6BC"/>-->
<!--                        </linearGradient>-->
<!--                    </defs>-->
<!--                    <circle cx="25" cy="25" r="22" fill="transparent" stroke="url(#grad)" stroke-width="2"/>-->
<!--                </svg>-->
<!--                <span><span> <i class="button__icon icon icon_money"></i></span></span>-->
<!--            </button>-->
            <!--        -->
            <button class="button button_sm button_bordered button_rounded"
                    @click.stop.capture="openModalGifts"
                    :disabled=giftProcessing  v-if="$mq === 'desktop'">
                <span> <i class="button__icon icon icon_present"></i></span>
            </button>
            <!--   mobile     -->
            <button class="button button_round button_gradient" v-else @click.stop.capture="openModalGifts" :disabled=giftProcessing>
                <svg class="button__border" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" width="50" height="50">
                    <defs>
                        <linearGradient id="grad" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%"
                                        y2="0%">
                            <stop offset="0%" stop-color="#FF51F8"/>
                            <stop offset="50%" stop-color="#3F75FF"/>
                            <stop offset="100%" stop-color="#00D6BC"/>
                        </linearGradient>
                    </defs>
                    <circle cx="25" cy="25" r="22" fill="transparent" stroke="url(#grad)" stroke-width="2"/>
                </svg>
                <span><i class="button__icon icon icon_present"></i></span>
            </button>

        </div>

        <!-- link dropbox  -->
        <div class="dropdown dropdown_right dropdown_content dropdown_contact dropdown_md" v-if="showModalLink" @click.stop>

            <!--       if link was not paid         -->
            <div class="dropdown__menu" @click.stop v-if="!member.linkPurchase && member.woman_link">
                <span class="title">
                    <span class="text-gradient">Contact:</span>
                    <span class="text">{{member.woman_link.credits/100}} credits</span>
                </span>
                <button class="button button_sm button_primary" @click.prevent="clickGetLink">
                    <span>Buy</span>
                </button>
            </div>

            <!--       if link was paid         -->
            <div class="dropdown__menu" @click.stop v-else>
                <span class="is-flex" v-if="member.linkPurchase && member.woman_link">
                    <input
                        type="text"
                        class="ui-input"
                        v-model="member['woman_link']['link']"
                        v-on:focus="$event.target.select()"
                        ref="linkinput"
                        readonly
                    >
                    <i class="icon icon_copy" @click.prevent="copy"></i>
                </span>
            </div>
        </div>
        <!--        -->
        <!-- donate dropbox  -->
        <div class="dropdown dropdown_right dropdown_content dropdown_donate dropdown_md " v-if="showModalDonate">
            <div class="dropdown__menu" @click.stop>
                <span class="title text-gradient">Donate:</span>
                <input type="number" min="1" oninput="validity.valid||(value='');" class="ui-input" placeholder="Enter any amount in credits" ref="inputDonateField">
                <button class="button button_sm button_primary"  @click.stop="sendMoney"><span>Send</span></button>
            </div>
        </div>
    </div>
</template>
<script >
export default {
    name:'PresentsBlock',
    props:['giftProcessing', 'opponentId', 'member'],
    mounted() {
      this.$bus.$on('close_options_menu',this.closeAllModals);
    },
    beforeDestroy() {
      this.$bus.$off('close_options_menu',this.closeAllModals);
    },
    data(){
        return{
          showModalDonate:false,
          showModalLink:false
        }
    },
    methods:{
        closeAllModals(){
            this.showModalDonate = false
            this.showModalLink = false
            //this.closeModalGifts()
        },
        toggleLinkModal(){
            if(this.isPromoPage())return
            this.showModalDonate = false
            this.showModalLink = !this.showModalLink
        },
        toggleDonateModal(){
            this.showModalDonate = !this.showModalDonate
            this.showModalLink = false
        },
        clickGetLink(){
            console.log('clickGetLink')
            this.$bus.$emit('buy-link', this.opponentId, this.member['woman_link']['credits'])
        },
        sendMoney(){
            let amount = this.$refs.inputDonateField.value;
            if(amount){
                this.showModalDonate = false
                this.$bus.$emit('send-donate', amount*100, this.opponentId)
            }
        },
        closeModalGifts(){
            this.$bus.$emit('close-gifts-modal')
        },
        openModalGifts(){
            if(this.isPromoPage())return
            this.$bus.$emit('show-gifts-modal')
        },
        copy() {
            this.$refs.linkinput.focus();
            let link = this.$refs.linkinput.value
            if (!navigator.clipboard){
                document.execCommand('copy');
            } else {
                navigator.clipboard.writeText(link)
                    .then(() => {
                        this.$bus.$emit('show_snotify', 'internal', 'Text copied to clipboard', link )
                        //this.$snotify.success('Text copied to clipboard', link);
                        this.closeAllModals()
                    })
                    .catch(err => {
                        //this.$snotify.warning(err, 'Error in copying text:');
                    });
            }
        },
        isPromoPage(openRegister = true){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat'){
                if(openRegister)this.$bus.$emit('open_register')
                return true
            }else {return false}
        }
    }
}

</script>
<style scoped>

</style>
