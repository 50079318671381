export function debounce (fn, delay=500) {
    let timeoutID = null
    return function () {
        clearTimeout(timeoutID)
        let args = arguments
        let that = this
        timeoutID = setTimeout(function () {
            fn.apply(that, args)
        }, delay)
    }
}
export function throttle(func, delay=500) {
    let timeout = null
    return function(...args) {
        if (!timeout) {
            timeout = setTimeout(() => {
                func.call(this, ...args)
                timeout = null
            }, delay)
        }
    }
}

export const promoUserData = {

}
