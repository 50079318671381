<template>

    <div class="page page_inner page_profile"
         :class="{'is-chat-open':($mq==='desktop' && showChat),'page_mobile': $mq!=='desktop'}"
         @click="clickPage">
        <CoolLightBox
            :items="promoMedia"
            :index="indexPromo"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @on-open="onOpenLightBox"
            @close="closeLightBoxPromo">
        </CoolLightBox>
        <CoolLightBox
            :items="portfolioImages"
            :index="index"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @on-open="onOpenLightBox"
            @close="closeLightBoxPortfolio">
        </CoolLightBox>
        <CoolLightBox
            :items="videos"
            :index="indexVideo"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @on-open="onOpenLightBox"
            @close="closeLightBoxVideo">
        </CoolLightBox>
        <CoolLightBox
            :items="mediaStory"
            :index="indexMedia"
            :gallery="false"
            :loop="false"
            :slideshow="false"
            :close-on-click-outside-mobile="true"
            @on-open="onOpenLightBox"
            @close="closeLightBoxStory">
        </CoolLightBox>

        <div v-if="$mq === 'desktop'">
            <div class="container">
                <div class="page__header">
                    <div class="page__wrapper">
                        <base-logo></base-logo>
                        <div class="page__actions">
                            <presents-block
                                :gift-processing=giftProcessing
                                :opponent-id="opponent ? opponent.id : 0"
                                :member="opponent"
                                v-if="!showChat"
                            ></presents-block>
                            <button class="button button_sm button_primary button_rounded button_chat" @click.prevent="toggleChat" v-if="!showChat">
                                <span><i class="button__icon icon icon_chat-bubble-bordered"></i><span>Live Chat</span></span>
                            </button>

                            <three-dots
                                :is-blocked-opponent="isBlockedOpponent"
                            >
                            </three-dots>
<!--                            <button class="button button_options" @click.stop.capture="isOpenOptions=!isOpenOptions">-->
<!--                                <span></span>-->
<!--                            </button>-->
<!--                            <div class="dropdown dropdown_right dropdown_md" v-if="isOpenOptions">-->
<!--                                <ul class="dropdown__menu menu">-->

<!--                                    <li class="dropdown__item menu__item" @click.prevent="openModalComplain">-->
<!--                                        <span class="dropdown__link menu__link">-->
<!--                                        <i class="icon icon_complain"></i>Complain</span>-->
<!--                                    </li>-->
<!--                                    <li class="dropdown__item menu__item" @click.prevent="openModalBlocked" v-if="!isBlockedOpponent">-->
<!--                                        <span class="dropdown__link menu__link">-->
<!--                                            <i class="icon icon_block-user" ></i>Block user</span>-->
<!--                                    </li>-->
<!--                                    <li class="dropdown__item menu__item" @click.prevent="blockOpponent(false)" v-else>-->
<!--                                        <span class="dropdown__link menu__link">-->
<!--                                            <i class="icon icon_block-user" ></i>Unblock user</span>-->
<!--                                    </li>-->
<!--                                </ul>-->
<!--                            </div>-->
                        </div>
                    </div>
                </div>
                <div class="profile" id="profileEl">

                    <div class="profile__container">
                        <div class="profile__promo">
                             <span class="status status_online status_badge" v-if="opponent && opponent.online">
                                <i class="status__icon"></i>
                                Online
                            </span>
                            <div class="profile__promo-header">
                                <div class="person person_card">
                                    <div class="person__info"  v-if="opponent">
                                        <span class="person__name" v-if="opponent">{{ opponent.name }}</span>
                                        <span class="person__age person__age_inline" v-if="opponentAge">{{ opponentAge }}</span>
                                        <span class="person__icon"><i class="icon icon_zodiac icon_aries"></i></span>
                                        <span class="person__location" v-if="opponent.country && opponent.country.countryName.length">
                                            <i class="icon icon_marker"></i>
                                            {{opponent && opponent.country ? opponent.country.countryName: '_'}}
                                        </span>
                                    </div>
                                  <button-favorite
                                      :is-favorite="(selectedContact && selectedContact[`favorite_${roleOpponent}`])"
                                      :opponent-id="opponent ? opponent.id : 0"
                                  ></button-favorite>
                                </div>
                            </div>
                            <div class="slider slider_promo">
                                <div class="slider__container">
                                    <hooper-promo ref="sliderPromo"
                                                  :my-url="myUrl"
                                                  :s3-url="s3Url"
                                                  :is-edit="false"
                                                  :index-promo="indexPromo"
                                                  :is-supported-webp="isSupportedWebp"
                                                  :presentation_medias="opponent ? opponent.presentation_medias : []"
                                                  @show_videos="showVideos"
                                                  @set_index_promo="setIndexPromo"
                                    ></hooper-promo>
                                    <button class="slider__button slider__button_dark slider__button_prev button" @click.prevent="slidePromoPrev" v-if="opponent && opponent.presentation_medias && opponent.presentation_medias.length > 1">
                                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                             height="12" fill="none">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                        </svg>
                                    </button>
                                    <button class="slider__button slider__button_dark slider__button_next button"  @click.prevent="slidePromoNext" v-if="opponent && opponent.presentation_medias && opponent.presentation_medias.length > 1">
                                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                             height="12" fill="none">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div class="profile__avatar profile__avatar_centered">
                                <picture v-if="opponent && opponent.avatar">
                                    <source type="image/webp" :srcset="`${s3Url}/${opponent.avatar_webp}`" v-if="opponent.avatar_webp">
                                    <img class="avatar avatar_xl" :src="`${s3Url}/${opponent.avatar}`" alt="avatar user"
                                         width="100" height="100">
                                </picture>
                                <img class="avatar avatar_xl" :src="`${myUrl}/source/images/icons/holder-user.svg`" alt="holder" width="100" height="100" v-else>
                            </div>
                        </div>
                        <div class="profile__card profile__card_promo">
                            <h3 class="profile__title title">A few words about Yourself</h3>
                            <p v-if="opponent && opponent[`${roleOpponent}_profile`]">{{opponent[`${roleOpponent}_profile`].slogan}}</p>
                        </div>

                        <div class="profile__portfolio">
                            <div class="slider slider_portfolio">
                                <div class="slider__container" v-if="opponent">
                                    <hooper
                                        :itemsToShow="3"
                                        :trimWhiteSpace="true"
                                        :itemsToSlide="1"
                                        :transition="500"
                                        :infiniteScroll="false"
                                        :playSpeed="5000"
                                        ref="sliderPortfolio">
                                        <template v-if="opponent[`${roleOpponent}_portfolios`] && opponent[`${roleOpponent}_portfolios`].length">
                                            <slide class="slider__item" v-for="(photo, el) in opponent[`${roleOpponent}_portfolios`]" :key="photo.id">
                                                <!--                                    @click.capture="index"-->
                                                <picture>
                                                    <source type="image/webp" :srcset="`${s3Url}/${photo.src_webp}`" v-if="isSupportedWebp && photo.src_webp">
                                                    <img class="slider__media" :src="`${s3Url}/${photo.src}`" alt="" @click.capture="index = el">
                                                </picture>

                                            </slide>
                                        </template>
                                        <template v-if="opponent[`${roleOpponent}_videos`] && opponent[`${roleOpponent}_videos`].length">
                                            <slide class="slider__item" v-for="video in opponent[`${roleOpponent}_videos`]" :key="video.id">
                                                <button class="button button_video-play"
                                                        @click.capture="showVideos(`${s3Url}/${isSupportedWebp && video.src_webp ? video.src_webp : video.src}`)">
                                                    <span class="button__icon">
                                                        <span class="triangle triangle_r triangle_sm"></span>
                                                    </span>
                                                </button>
                                                <img  class="slider__media"  :src="`${s3Url}/${(isSupportedWebp && video.poster_webp) ? video.poster_webp : video.poster}`" alt="" v-if="video.poster">
                                                <img  class="slider__media"  :src="`${myUrl}/assets/images/bg-rectangle.png`" alt="" v-else>
                                            </slide>
                                        </template>
                                    </hooper>

                                    <button class="slider__button slider__button_prev button" @click.prevent="slidePortfolioPrev" v-if="lengthPortfolio > 3">
                                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                             height="12" fill="none">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                        </svg>
                                    </button>
                                    <button class="slider__button slider__button_next button" @click.prevent="slidePortfolioNext" v-if="lengthPortfolio > 3">
                                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 12" width="28"
                                             height="12" fill="none">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-width="3"
                                                  d="m21 2-8.8559 7.4576a1 1 0 0 1-1.2882 0L2 2"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <my-self
                            :my-role="currentUser ? currentUser.role:''"
                            :name="opponent ? opponent.name:''"
                            :img-gallery="opponent ? opponent.img_gallery:''"
                            :img-gallery-webp="opponent?opponent.img_gallery_webp:''"
                            :member-online="opponent?opponent.online:false"
                            :member-color="opponent && opponent.color?opponent.color:'#df3535'"
                            :member-role="currentUser ? currentUser.role:''"
                            :member-i-d="null"
                            :birthday="opponent?opponent.birthday:''"
                            :profile="opponent?opponent[`${roleOpponent}_profile`]:null"
                            :my-url="myUrl"
                            :s3-url="s3Url"
                            :role-opponent="roleOpponent"
                            :country="opponent?opponent.country:null"
                            :is-edit="false"
                            :is-supported-webp="isSupportedWebp"
                            :interests="opponent?opponent.interests:[]"
                            :get-zodiac-sign="getZodiacSign"
                        ></my-self>


                        <div class="profile__stories">
                            <button class="button button_bookmark">
                                <i class="button__icon icon icon_stories"></i>My stories
                            </button>
                            <show-stories v-if="chatOwner && opponent && myStories && myStories.length"
                                          :my-stories="myStories"
                                          :my-role="currentUser ? currentUser.role:''"
                                          :member-i-d="chatOwner.id"
                                          :member-avatar="chatOwner.avatar"
                                          :member-avatar-webp="chatOwner.avatar_webp"
                                          :member-color="chatOwner.color"
                                          :member-name="chatOwner.name"
                                          :role-opponent="roleOpponent"
                                          :opponent-i-d="opponent.id"
                                          :opponent-name="opponent.name"
                                          :opponent-avatar="opponent.avatar"
                                          :opponent-color="opponent.color"
                                          :s3-url="s3Url"
                                          :my-url="myUrl"
                                          :operator-i-d="opponent.operator_id"
                                          :is-supported-webp="isSupportedWebp"
                                          @show_medias="showMediasStory"
                                          @more_my_stories_handler="moreMyStoriesHandler"
                                          @extend_stories="extendStories"
                                          @toggle_chat="toggleChat"
                                          @errorhandler="errorHandler"
                            ></show-stories>

                        </div>

                    </div>
                    <chat ref="chatDesktop"
                          :is-see-footer="isSeeFooter"
                          :is-favorite="isFavorite"
                          :my-url="myUrl"
                          :s3-url="s3Url"
                          :cdn-letter-images="cdnLetterImages"
                          :current-user="currentUser"
                          :messages="messages"
                          :chat-owner="chatOwner"
                          :opponent="opponent"
                          :opponent-age="opponentAge"
                          :emojis="emojis"
                          :moods="moods"
                          :tariffs="tariffs"
                          :role-opponent="roleOpponent"
                          :is-all-messages="isAllMessages"
                          :selected-contact-id="selectedContact?selectedContact.id:null"
                          :settings-init="settingsInit"
                          :disabled-send-message="disabledSendMessage"
                          :used-video-codecs="usedVideoCodecs"
                          :gift-processing="giftProcessing"
                          :show-modal-gifts="showModalGifts"
                          :gifts="gifts"
                          :s3-url-static="s3UrlStatic"
                          :is-blocked-opponent="isBlockedOpponent"
                          :modal-complain="modalComplain"
                          :ban-list="banList"
                          :modal-blocked="modalBlocked"
                          :typings-m-l-message="typingsMLMessage"
                          :show-modal-lost-connection="showModalLostConnection"
                          :type-select-opponent="typeSelectOpponent"
                          @toggle="toggleChat"
                          @close_chat="closeChat"
                          @extend="extendChat"
                          @errorhandler="errorHandler"
                          v-if="showChat && isInit"
                    ></chat>
                </div>
            </div>
        </div>

        <template v-else>
            <div class="container">
                <div class="page__header page__header_bg">
                    <button class="button button_round button_back" @click.prevent="goBack">
                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="14" height="14">
                            <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#back`" />
                        </svg>
                    </button>
                    <div class="page__actions">
<!--                        <presents-block
                            :gift-processing=giftProcessing
                            :opponent-id="opponent ? opponent.id : 0"
                            :member="opponent"
                        ></presents-block>-->
                        <button class="button button_rounded button_chat" @click.prevent="openMobileChat">
                            <svg class="button__border" viewBox="0 0 132 40" preserveAspectRatio="xMidYMid meet"xmlns="http://www.w3.org/2000/svg" width="132" height="40" fill="none">
                                <rect width="130" height="38.5" x=".8" y=".8" stroke="url(#grad)" stroke-width="1" rx="19.3"/>
                                <defs>
                                    <linearGradient id="grad" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%"
                                                    y2="0%">
                                        <stop offset="0%" stop-color="#FF51F8"/>
                                        <stop offset="50%" stop-color="#3F75FF"/>
                                        <stop offset="100%" stop-color="#00D6BC"/>
                                    </linearGradient>
                                </defs>
                            </svg>
                            <span><i class="button__icon icon icon_chat-bubble-bordered"></i><span>Live chat</span></span>
                        </button>

                        <three-dots
                            :is-blocked-opponent="isBlockedOpponent"
                        >
                        </three-dots>
<!--                        <button class="button button_options" @click.stop.capture="isOpenOptions=!isOpenOptions">-->
<!--                            <span></span>-->
<!--                        </button>-->
<!--                        <div class="dropdown dropdown_right dropdown_md" v-if="isOpenOptions">-->
<!--                            <ul class="dropdown__menu menu">-->

<!--                                <li class="dropdown__item menu__item" @click.prevent="openModalComplain">-->
<!--                                    <span class="dropdown__link menu__link"><i-->
<!--                                        class="icon icon_complain"></i>Complain</span>-->
<!--                                </li>-->
<!--                                <li class="dropdown__item menu__item" @click.prevent="openModalBlocked" v-if="!isBlockedOpponent">-->
<!--                                    <span class="dropdown__link menu__link">-->
<!--                                    <i class="icon icon_block-user" ></i>Block user</span>-->
<!--                                </li>-->
<!--                                <li class="dropdown__item menu__item" @click.prevent="blockOpponent(false)" v-else>-->
<!--                                    <span class="dropdown__link menu__link">-->
<!--                                    <i class="icon icon_block-user" ></i>Unblock user</span>-->
<!--                                </li>-->
<!--                            </ul>-->
<!--                        </div>-->
                    </div>
                </div>
                <div class="profile profile_mobile">
                    <div class="profile__container">
                        <div class="profile__promo">
                            <div class="profile__caption caption">
                                <div class="person person_card">
                                    <span class="status status_online status_badge" v-if="opponent && opponent.online">
                                        <i class="status__icon"></i>
                                        Online
                                    </span>
                                    <span></span>
                                    <div class="person__info"  v-if="opponent">
                                        <span class="person__name" v-if="opponent">{{opponent.name}}</span>
                                        <span class="person__age person__age_inline">{{opponentAge}}</span>
                                        <span class="person__icon"><i class="icon icon_zodiac icon_aries"></i></span>
                                        <span class="person__location"><i class="icon icon_marker"></i>{{opponent && opponent.country ? opponent.country.countryName: '_'}}</span>
                                    </div>
                                    <button-favorite
                                        :is-favorite="(selectedContact && selectedContact[`favorite_${roleOpponent}`])"
                                        :opponent-id="opponent ? opponent.id : 0"
                                        v-if="!showChat"
                                    ></button-favorite>
                                </div>
                            </div>
                            <div class="slider slider_promo">
                                <div class="slider__container">
                                    <hooper-promo ref="sliderPromo"
                                                  :my-url="myUrl"
                                                  :s3-url="s3Url"
                                                  :is-edit="false"
                                                  :index-promo="indexPromo"
                                                  :is-supported-webp="isSupportedWebp"
                                                  :presentation_medias="opponent ? opponent.presentation_medias : []"
                                                  @show_videos="showVideos"
                                                  @set_index_promo="setIndexPromo"
                                    ></hooper-promo>
                                </div>
                            </div>
                        </div>
                        <div class="profile__about">
                            <div class="profile__card">
                                <button class="button button_sm button_rounded button_white" @click.prevent="toggleAboutMeBlock">
                                    <span class="text-grsdient">About me
                                    </span></button>

                                <div class="slider slider_content" v-if="showAboutMe">
                                    <div class="slider__container">
                                        <hooper :wheelControl="false">
                                            <slide class="slider__item">
                                                <ul class="description description_personal description_first list" v-if="opponentProfile">
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Work as:</span>
                                                        <span class="description__value">{{opponentProfile.work}}</span>
                                                    </li>
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Language:</span>
                                                        <span class="description__value">{{opponentProfile.lang}}</span>
                                                    </li>
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Zodiac:</span>
                                                        <span class="description__value">{{getZodiacSign}}</span>
                                                    </li>
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Education:</span>
                                                        <span class="description__value">{{opponentProfile.education}}</span>
                                                    </li>
                                                </ul>
                                            </slide>
                                            <slide class="slider__item">
                                                <ul class="description description_personal description_second list" v-if="opponentProfile">
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Status:</span>
                                                        <span class="description__value">{{opponentProfile.marital_status}}</span>
                                                    </li>
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Kids:</span>
                                                        <span class="description__value">{{opponentProfile.kids}}</span>
                                                    </li>
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Body:</span>
                                                        <span class="description__value">{{opponentProfile.body_type}}</span>
                                                    </li>
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Eyes:</span>
                                                        <span class="description__value">{{opponentProfile.eye_color}}</span>
                                                    </li>
                                                </ul>
                                            </slide>
                                            <slide>
                                                <ul class="description description_personal description_third list" v-if="opponentProfile">
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Hair:</span>
                                                        <span class="description__value">{{opponentProfile.hair_color}}</span>
                                                    </li>
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Smoke:</span>
                                                        <span class="description__value">{{opponentProfile.smoke}}</span>
                                                    </li>
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Height:</span>
                                                        <span class="description__value">{{opponentProfile.height}} cm</span>
                                                    </li>
                                                    <li class="description__item list__item">
                                                        <span class="description__key">Weight:</span>
                                                        <span class="description__value">{{opponentProfile.weight}} kg</span>
                                                    </li>
                                                </ul>
                                            </slide>
                                            <hooper-pagination slot="hooper-addons"></hooper-pagination>
                                        </hooper>
                                    </div>
                                </div>
                            </div>
                            <div class="profile__card">
                                <h3 class="profile__title title">Interests</h3>
                                <my-interests :interests="opponent.interests" v-if="opponent && opponent.interests"></my-interests>
                            </div>
                        </div>
                        <div class="profile__portfolio">
                            <div class="slider slider_portfolio">
                                <div class="slider__container">
                                    <hooper
                                        :transition="500"
                                        :wheelControl="false"
                                        ref="sliderPortfolio">
                                        <template v-if="opponent && opponent[`${roleOpponent}_portfolios`] && opponent[`${roleOpponent}_portfolios`].length">
                                            <slide class="slider__item" v-for="(photo, el) in opponent[`${roleOpponent}_portfolios`]" :key="photo.id">
                                                <!--                                    @click.capture="index"-->
                                                <picture>
                                                    <source type="image/webp" :srcset="`${s3Url}/${photo.src_webp}`" v-if="isSupportedWebp && photo.src_webp">
                                                    <img class="slider__media" :src="`${s3Url}/${photo.src}`" alt="photo portfolio" @click.capture="index = el">
                                                </picture>

                                            </slide>
                                        </template>
                                        <template v-if="opponent && opponent[`${roleOpponent}_videos`] && opponent[`${roleOpponent}_videos`].length">
                                            <slide class="slider__item" v-for="video in opponent[`${roleOpponent}_videos`]" :key="video.id">
                                                <button class="button button_video-play"
                                                        @click.capture="showVideos(`${s3Url}/${isSupportedWebp && video.src_webp ? video.src_webp : video.src}`)">
                                                    <span class="button__icon">
                                                        <span class="triangle triangle_r triangle_sm"></span>
                                                    </span>
                                                </button>
                                                <img  class="slider__media"  :src="`${s3Url}/${(isSupportedWebp && video.poster_webp) ? video.poster_webp : video.poster}`" alt="poster video" v-if="video.poster">
                                                <img  class="slider__media"  :src="`${myUrl}/assets/images/bg-rectangle.png`" alt="poster video" v-else>
                                            </slide>
                                        </template>
                                        <hooper-pagination slot="hooper-addons"></hooper-pagination>
                                    </hooper>
                                </div>
                            </div>
                        </div>
                        <div class="profile__stories">
                            <button class="button button_bookmark">
                                <i class="button__icon icon icon_stories"></i>My stories
                            </button>
                            <show-stories v-if="chatOwner && opponent && myStories && myStories.length"
                                          :my-stories="myStories"
                                          :my-role="currentUser ? currentUser.role:''"
                                          :member-i-d="chatOwner.id"
                                          :member-avatar="chatOwner.avatar"
                                          :member-avatar-webp="chatOwner.avatar_webp"
                                          :member-color="chatOwner.color"
                                          :member-name="chatOwner.name"
                                          :role-opponent="roleOpponent"
                                          :opponent-i-d="opponent.id"
                                          :opponent-name="opponent.name"
                                          :opponent-avatar="opponent.avatar"
                                          :opponent-color="opponent.color"
                                          :s3-url="s3Url"
                                          :my-url="myUrl"
                                          :operator-i-d="opponent.operator_id"
                                          :is-supported-webp="isSupportedWebp"
                                          @show_medias="showMediasStory"
                                          @more_my_stories_handler="moreMyStoriesHandler"
                                          @extend_stories="extendStories"
                                          @toggle_chat="toggleChat"
                                          @errorhandler="errorHandler"
                            ></show-stories>

                        </div>
                    </div>
                </div>
            </div>
        </template>

        <modal-gifts  v-if="showModalGifts"
                      :myUrl="myUrl"
                      :s3Url="s3Url"
                      :gifts="gifts"
                      :s3-url-static="s3UrlStatic"
                      :opponent-id="opponent ? opponent.id : 0"
                      >
        </modal-gifts>
        <block-user v-if="modalBlocked"
        ></block-user>
        <complain-modal v-if="modalComplain"
                        :opponent="opponent"
                        :chat-owner="chatOwner"
                        :role="currentUser ? currentUser.role:''"
        ></complain-modal>
    </div>
</template>


<script>
import Chat from "./modules/Chat";
import MySelf from "./ProfileItem/MySelf";
import BaseLogo from "./newModules/BaseLogo.vue";
import StoriesList from "./newModules/StoriesList.vue";
import ButtonFavorite from "./newModules/ButtonFavorite.vue";
import HooperPromo from "./newModules/HooperPromo.vue";
import MyInterests from "./newModules/MyInterests.vue";
import SupportChatContainer from "./newModules/SupportChatContainer.vue";
import BlockUser from "./newModules/modals/BlockUser.vue";
import ShowStories from "./ProfileItem/ShowStories.vue";
import ComplainModal from "./newModules/modals/ComplainModal.vue";
import PresentsBlock from "./newModules/PresentsBlock.vue";
import ModalGifts from "./newModules/modals/ModalGifts.vue";
import ThreeDots from "./newModules/ThreeDots.vue";

const month = ['','January','February','March','April','May','June','July','August','September','October','November','December'];
let footerObserver = null;
let footer = null;

export default {
    name: "Profile",
    components: {
      ThreeDots,
      ModalGifts,
        PresentsBlock,
        ComplainModal,
        ShowStories,
        BlockUser,
        SupportChatContainer,
        MyInterests,
        HooperPromo,
        ButtonFavorite,
        StoriesList,
        BaseLogo,
        // ShowStories,
        MySelf,
        Chat,
    },
    props:[
        'currentUser',
        'myUrl',
        'messages',
        'chatOwner',
        'opponent',
        'opponentAge',
        'isFavorite',
        // 'isFavorite',
        'emojis',
        'moods',
        'gallery',
        'banList',
        'favorites',
        'id',
        'loaded',
        'roleOpponent',
        'selectedContact',
        'portfolioImages',
        'showChat',
        'isAllMessages',
        's3Url',
        's3UrlStatic',
        'tariffs',
        'packMedias',
        'isPaidPackage',
        'settingsInit',
        'windowHeight',
        'countUnread',
        'holidayPromotionName',
        'disabledSendMessage',
        'usedVideoCodecs',
        'isSupportedWebp',
        'giftProcessing',
        'showModalGifts',
        'gifts',
        'modalComplain',
        'modalBlocked',
        'isBlockedOpponent',
        'cdnLetterImages',
        'typingsMLMessage',
        'showModalLostConnection',
        'typeSelectOpponent'
        ],
    data(){
        return{
            isInit: false,
            // showStories: false,
            isExtendChat: false,
            isExtendStories: false,
            index: null,
            indexPromo: null,
            videos: [],
            indexVideo: null,
            // imagesPack: [
            //     {src: `${this.myUrl}/assets/images/gallery/girl7.jpg`},
            //     {src: `${this.myUrl}/assets/images/gallery/girl8.jpg`}
            // ],
            packPreviewImg: null,
            indexPack: null,
            modalPack: false,
            mediaChat: [],
            mediaStory: [],
            indexMedia: null,
            indexStory: null,
            // personalStory:null,
            myStories: [],
            isSeeFooter: false,
            showAboutMe:false,
            prevRoute: null
        }
    },
    watch: {
        showChat(newVal, oldVal){
            if (newVal){
               this.myStories.forEach( story => story.isShowStory = false);
            }
            if(this.$refs.sliderPortfolio) this.$refs.sliderPortfolio.update()
        },
        id(){
            this.initProfile();
        },
        // $mq(newVal, oldVal) {
        //     console.log(newVal, oldVal);
        //     if (newVal === 'laptop' && oldVal === 'desktop') {
        //         let trigger = ScrollTrigger.getById('hobbies')
        //         if (trigger) trigger.disable()
        //     } else if (newVal === 'desktop' && oldVal === 'laptop') {
        //         setTimeout(()=>this.pinSidebar(), 0)
        //     }
        // },
    },
    computed: {

        getZodiacSign(){
            let zodiacSign = '';
            if (this.opponent && this.opponent.birthday) {
                let birthday = new Date(this.opponent.birthday);
                const zodiac =['', 'Capricorn', 'Aquarius', 'Pisces', 'Aries', 'Taurus', 'Gemini', 'Cancer', 'Leo', 'Virgo', 'Libra', 'Scorpio', 'Sagittarius', 'Capricorn'];
                const last_day =[0, 20, 19, 21, 21, 22, 22, 23, 23, 22, 23, 22, 21, 20];
                let month = birthday.getMonth()+1;
                let day = birthday.getDate();
                zodiacSign = (Number(day) < last_day[month]) ? zodiac[month] : zodiac[month+1];
            }
            return zodiacSign
        },
        opponentProfile(){
            return this.opponent ? this.opponent[`${this.roleOpponent}_profile`]: null;
        },
        lengthPortfolio(){
            if(!this.opponent || !this.opponent[`${this.roleOpponent}_portfolios`] || !this.opponent[`${this.roleOpponent}_videos`]) return 0
            return this.opponent[`${this.roleOpponent}_portfolios`].length + this.opponent[`${this.roleOpponent}_videos`].length;
        },
        imagesPack(){
            return this.packMedias.map(media=>{
                return `${this.s3Url}/${media}`
            })
        },
        promoMedia() {
            if (this.opponent && this.opponent.presentation_medias) {
                return this.opponent.presentation_medias.map(item=>{
                    return { src: `${this.s3Url}/${(this.isSupportedWebp && item.src_webp) ? item.src_webp : item.src}`}
                })
            }
            return []
        },
        showProfile() {
            // if (this.opponent && this.loaded) {
            //     if (this.$mq === 'desktop' ) {
            //       setTimeout(()=>this.pinSidebar(), 3500)
            //     }
            //     return true
            // } else {
            //     return false
            // }
            return this.opponent
        }
    },
    created() {
        // this.initProfile()
    },
    mounted() {
        console.log('mounted Profile');
        console.log(this.prevRoute.path);
        //console.log(this.$router.currentRoute.params);
        window.scrollTo(0, 0);
        this.$bus.$emit('close_modal');
        this.initProfile();
        // if (window.addEventListener) {
        //     window.addEventListener('resize', this.refreshScrollTrigger, false)
        // } else if (window.attachEvent) {
        //     window.attachEvent('resize', this.refreshScrollTrigger)
        // }

        this.$bus.$on('update_data_profile',this.initProfile);
        // this.$bus.$on('buy-link-success', this.successBuyLink);
        this.$bus.$on('set-init-chat', this.setInitChat);

        // this.$nextTick(()=>{
        //     setTimeout(()=> {
        //         this.$bus.$emit('get_my_stories')
        //     }, 100)
        // })
    },
    destroyed() {
        // if (window.removeEventListener) {
        //     window.removeEventListener('resize', this.refreshScrollTrigger, false)
        // } else if (window.detachEvent) {
        //     window.detachEvent('resize',this.refreshScrollTrigger)
        // }
    },
    beforeDestroy() {
        // let trigger = ScrollTrigger.getById('hobbies')
        // if (trigger) trigger.disable();
        this.$bus.$off('update_data_profile',this.initProfile);
        this.$bus.$off('set-init-chat', this.setInitChat);
        this.$bus.$emit('close_modal');


        if (footerObserver && footer){
            footerObserver.unobserve(footer)
            footerObserver = null;
            this.isSeeFooter = false;
        }
    },
    beforeRouteLeave(to, from, next){
        this.$emit('reset_opponent');
        next() ;
        // this.scrollGalleryPosition = window.pageYOffset
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.prevRoute = from
        })
    },
    methods:{
        meetingPromoViewer(){
            if(Object.keys(this.messages).length > 0)return
            //let idW = this.$route.path.split('/')[3]
            //console.log('url: ' + idW)
            let arr = ["Hi! Let's get to know each other. My name is Kate. What's yours?🤗",
                "Hello. I'm feeling a bit lonely today. Let's chat.☺️",
                "Hi there. Tell me about yourself. What do you do? Where are you from?😏",
                "Hello. I need some advice. Can you help? 😇",
                "Hi! Are you looking for a relationship or just here to have fun? 😏",
                "Hey. Does the age of a potential partner matter to you?😉",
                "Hello. I'm searching for my other half. Could it be you?❤️",
                "Hi. How are you? Have you tried online dating before?😉",
                "Hello. Don't hesitate to message me. I'm off today and I want to meet new people.🤗",
                "Hey. Rate me from 1 to 10. I'm curious about how people see me from the outside. 🥰"
            ]
            let index = Math.floor(Math.random() * arr.length);
            let message = arr[index]
            console.log(message)
            //this.typing()
            let eventMessage = {
                manID:0,
                member:{
                    avatar:this.opponent != null ? this.opponent.avatar : '',
                    id:this.opponent != null ? this.opponent.id : '',
                    name:this.opponent != null ? this.opponent.name : '',
                },
                message:{
                    author:"woman",
                    contact_id:0,
                    created_at:new Date().toUTCString(),
                    id: null,
                    message:message,
                    message_translate:null,
                    paid:false,
                    type:"message",
                    updated_at:new Date().toUTCString()
                },
                womanID:this.opponent != null ? this.opponent.id : '',
            }
            let eventEmoji = {
                manID:0,
                member:{
                    avatar:this.opponent != null ? this.opponent.avatar : '',
                    id:this.opponent != null ? this.opponent.id : '',
                    name:this.opponent != null ? this.opponent.name : '',
                },
                message:{
                    author:"woman",
                    contact_id:0,
                    created_at:new Date().toUTCString(),
                    id:null,
                    isReadOpponent:false,
                    message:"",
                    paid:false,
                    src:"047.svg",
                    src_mini:"",
                    type:"emoji",
                    updated_at:new Date().toUTCString()
                },
                womanID:this.opponent != null ? this.opponent.id : '',
            }
            let eventGift = {
                manID:0,
                member:{
                    avatar:this.opponent != null ? this.opponent.avatar : '',
                    id:this.opponent != null ? this.opponent.id : '',
                    name:this.opponent != null ? this.opponent.name : '',
                },
                message:{
                    audioPlay:false,
                    author:"woman",
                    contact_id:0,
                    created_at:new Date().toUTCString(),
                    id:null,
                    isOptionMenu:false,
                    isReadOpponent:true,
                    message:"HART",
                    paid:true,
                    src:"gifts/8RXCh3P0AO4Cjm2qERZ7jjQqCThaX3TRqOYbsZLU.gif",
                    src_mini:1000,
                    type:"gift",
                    updated_at:new Date().toUTCString(),
                    wavesurfer:null
                },
                womanID:this.opponent != null ? this.opponent.id : '',
            }
            setTimeout(()=>{
                this.$bus.$emit('get_new_message_promo', eventMessage);
                //this.typing()
                setTimeout(()=>{this.$bus.$emit('get_new_message_promo', eventGift);}, 1000)
            }, 2500)
        },
        showMediasStory(medias,index){
            this.mediaStory = medias;
            this.indexMedia = index;
        },
        onOpenLightBox(){
            this.$bus.$emit('add_no_scroll')
        },
        closeLightBoxPromo(){
            this.indexPromo = null;
            this.$bus.$emit('remove_no_scroll');
        },
        closeLightBoxPortfolio(){
            this.index = null;
            this.$bus.$emit('remove_no_scroll');
        },
        closeLightBoxVideo(){
            this.indexVideo = null;
            this.$bus.$emit('remove_no_scroll');
        },
        closeLightBoxStory(){
            this.indexMedia = null;
            this.$bus.$emit('remove_no_scroll');
        },
        setFooterObserver(){
            if(this.$mq!=='desktop' || footerObserver) return;
            console.log('setFooterObserver')
            // const windowHeight = window.innerHeight || document.documentElement.clientHeight;
            // const windowWidth = window.innerWidth || document.documentElement.clientWidth;
            // const chatContainer = this.$refs.chatContainer;
            // const parentChatContainer = document.getElementById('profileEl');
            // if(!chatContainer || !parentChatContainer) {
            //     console.log('!chatContainer || !parentChatContainer');
            //     // console.log(chatContainer)
            //     // console.log(parentChatContainer)
            //     return;
            // }
            footerObserver = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (entry.isIntersecting) {
                            console.log('setFooterObserver isIntersecting')
                            this.isSeeFooter = true;
                            //
                            // // if (chatContainer && windowHeight > chatContainer?.closest('.profile').offsetHeight) {
                            // if (chatContainer && windowHeight > parentChatContainer.offsetHeight) {
                            //     const offset = windowWidth > 1919 ? 106 : 92;
                            //     chatContainer.style.top = `-${offset}px`;
                            //     chatContainer.style.height = `${parentChatContainer.offsetHeight + offset}px`;
                            // } else if (chatContainer) {
                            //     chatContainer.style.height = `${windowHeight}px`;
                            // }

                        } else {
                            // if (chatContainer) chatContainer.style = '';
                            this.isSeeFooter = false;
                        }
                    });
                },
                {
                    root: null,
                    rootMargin: '0px',
                    threshold: 0.0,
                },
            );
            footer = document.getElementById('footer')
            if (footer) footerObserver.observe(footer);
        },
        clickPage(){
            // if(this.$mq === 'desktop') this.$bus.$emit('close_side_bar');
            if(this.$mq === 'desktop') this.$bus.$emit('close_nav_menu');

        },
        setIndexPromo(index){
            this.indexPromo = index
        },
        clickFavorite(){
            console.log('clickFavorite');
            const status = this.selectedContact && this.selectedContact[`favorite_${this.roleOpponent}`];
            console.log({status});
            this.$bus.$emit('start_change_favorite', !status, this.opponent.id)
        },
        // openMediaStory(media) {
        //     this.mediaStory[0] = {src: media}
        //     this.indexStory = 0
        // },
        // openVideoStory(media) {
        //     this.mediaStory[0] = {src : media, autoplay: true}
        //     this.indexStory = 0
        // },
        writeDateFull(date){
            const fullDate = new Date(date);
            return `${month[Number(fullDate.getMonth()+1)]} ${fullDate.getDate()}, ${fullDate.getFullYear()}`
        },
        goToRequest(arg) {
            this.$emit('go_to_request',arg);
        },
        openMenu(arg) {
            this.$emit('open_menu', arg);
        },
        openPack(pack){
            if(this.isPaidPackage || this.chatOwner.payment_status === 'luxury'){
                this.indexPack = 0;
            }else {
               this.openPackModal(pack);
            }
        },
        openPackModal(pack) {
            this.modalPack = true
            this.packPreviewImg = pack
        },
        // buyPack() {
        //    console.log('buyPack');
        //     if (!this.opponent) return
        //     this.closeModalPack();
        //    if (this.chatOwner.credits >= this.tariffs.tariff_pack){
        //        axios.post('/buy-pack-media',{
        //            role:this.currentUser.role,
        //            chatOwnerID:this.chatOwner.id,
        //            opponentID:this.opponent.id,
        //            type:'pack',
        //            operator:this.opponent.operator_id?this.opponent.operator_id:null,
        //            tariff:this.tariffs.tariff_pack
        //        })
        //            .then(response => {
        //                console.log(response.data);
        //                this.$bus.$emit('new_balance',response.data.credits);
        //                if(response.data.status === 'ok'){
        //                    this.$bus.$emit('paid_pack_media');
        //                    this.indexPack = 0;
        //                }else {
        //                    alert(`Failed to buy pack photo.\n Your balance ${(this.chatOwner.credits/100).toFixed(2)}`)
        //                }
        //            })
        //            .catch(error => {
        //                console.log(error);
        //                this.errorHandler(error);
        //            });
        //
        //    }else {
        //        alert(`You do not have enough funds to buy a pack photo.\n
        //                 Your balance is  ${(this.chatOwner.credits/100).toFixed(2)}.\n
        //                  Check out our tariffs`);
        //    }
        // },
        // closeModalPack() {
        //     this.modalPack = false
        //     this.packPreviewImg = null
        // },
        // refreshScrollTrigger(timeout=300) {
        //     let trigger = ScrollTrigger.getById('hobbies')
        //     if (trigger) {
        //         setTimeout(()=> trigger.refresh(true), timeout)
        //     }
        // },
        updateSliderPromo() {
            setTimeout(()=> {
                if (this.$refs.sliderPromo && this.$refs.sliderPromo.$refs.sliderPromo) {
                    this.$refs.sliderPromo.$refs.sliderPromo.update()
                }
                if (this.$refs.sliderPortfolio) {
                    this.$refs.sliderPortfolio.update()
                }
            }, 100)
        },
        // openStories() {
        //     console.log('openStories');
        //     // this.showStories = true
        //     window.scrollTo(0, 0);
        //     this.refreshScrollTrigger();
        //     if (this.$mq !== 'desktop') {
        //         this.$nextTick(()=> {
        //             document.documentElement.classList.add('no-scroll')
        //         })
        //     }
        //
        //     // this.$bus.$emit('get_my_stories');
        // },
        // closeStories() {
        //     if (this.isExtendStories) this.extendStories()
        //     this.showStories = false
        //     this.refreshScrollTrigger()
        //     this.updateSliderPromo()
        //     if (this.$mq !== 'desktop' && !this.showChat) {
        //         this.$nextTick(()=> {
        //             document.documentElement.classList.remove('no-scroll')
        //         })
        //     }
        // },

        // changeFavorite(status){
        //     axios.post(`/change-favorite-${this.roleOpponent}`,{
        //         status:status,
        //         opponentID:this.opponent.id,
        //         chatOwnerID:this.chatOwner.id,
        //         contactID:this.selectedContact?this.selectedContact.id:null
        //     })
        //         .then(response => {
        //             console.log(response.data);
        //             this.$emit('change_favorite',response.data,status);
        //         })
        //         .catch(e => {
        //             console.log(e);
        //             this.errorHandler(e);
        //         })
        // },
        goBack(){
            if(this.isPromoPage(true)){return}
            if (this.$route.name !== 'gallery') {
                this.$router.push({path: '/gallery'});
            }
        },
        closeChat(){
            this.$emit('show_chat', false)
        },
        openMobileChat(){
            this.$bus.$emit('close_modal')
            this.$emit('show_chat', true)
            if(this.$route.name==='promoProfile') this.$router.push({path:`/promo/mobile-chat/${this.id}`})
            else{
                this.$router.push({path:`/mobile-chat/${this.id}`})
                this.sendOpenChat();
            }

        },
        sendOpenChat(){
            console.log('openDesktopChat')
            const contactId = this.selectedContact ? this.selectedContact.id : 0 ;
            const url = this.currentUserRole === 'man' ?
                `/open-profile-chat/${this.$router.currentRoute.params.id}/${this.chatOwner.id}/${contactId}?type=${this.typeSelectOpponent}`:
                `/open-profile-chat/${this.chatOwner.id}/${this.$router.currentRoute.params.id}/${contactId}?type=${this.typeSelectOpponent}`
            axios.get(url)
                .then(response => {
                    console.log('response openDesktopChat');
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        toggleChat(){
            if(!this.showChat) this.sendOpenChat();
            this.$emit('show_chat', !this.showChat)
            this.updateSliderPromo()
            if (this.isExtendChat) {
                this.isExtendChat = false
            }
            if (this.isExtendStories) {
                this.isExtendStories = false

            }
        },
        calculateProfileHeight(){
            let stories = this.$refs.profile.querySelector('.card_stories')
            if (this.isExtendStories && stories) {
                const height = this.$refs.profile.querySelector('.card_stories').clientHeight + 30
                this.$refs.profile.style.height = `${height}px`
            } else {
                this.$refs.profile.removeAttribute('style')
            }
        },
        extendChat(){
            if (this.isExtendStories) {
                this.isExtendStories = false
                // setTimeout(() => {
                //     this.calculateProfileHeight()
                // },50)
            }
            this.isExtendChat = !this.isExtendChat
            // this.refreshScrollTrigger()
        },
        extendStories(){
            this.isExtendStories = !this.isExtendStories
            // setTimeout(() => {
            //     this.calculateProfileHeight()
            // },50)
            if (this.isExtendChat) this.isExtendChat = false
            if (this.isExtendStories) {
                if (this.showChat) {
                    this.$emit('show_chat', false)
                }
            } else {
                this.updateSliderPromo()
            }
            // this.refreshScrollTrigger()
        },
        showPack(imageSrc){
            this.imagesPack[0] = {src: imageSrc}
            this.indexPack = 0
        },
        showVideos(videoSrc) {
            console.log('showVideos: '+videoSrc)
            this.videos[0] = {src : videoSrc, autoplay: true}
            this.indexVideo = 0
        },
        slidePromoPrev() {
            this.$refs.sliderPromo.$refs.sliderPromo.slidePrev();
        },
        slidePromoNext() {
            this.$refs.sliderPromo.$refs.sliderPromo.slideNext();
        },
        slidePortfolioPrev() {
            this.$refs.sliderPortfolio.slidePrev();
        },
        slidePortfolioNext() {
            this.$refs.sliderPortfolio.slideNext();
        },
        // pinSidebar() {
        //     const panelTop  = this.$refs.hobbies ? this.$refs.hobbies.offsetTop : null
        //     console.log(panelTop);
        //     // console.log(document.querySelector('footer').offsetTop);
        //     if (panelTop) {
        //         ScrollTrigger.create({
        //             id: 'hobbies',
        //             trigger: this.$refs.hobbies,
        //             start:  'top top',
        //             // markers: true,
        //             endTrigger: 'footer',
        //             end: '80% bottom',
        //             pin: true,
        //             scrub: true,
        //             pinSpacing: false
        //         });
        //     }
        // },
        initProfile(){
            this.isInit = false;
            // this.closeStories();
            // this.$emit('reset_opponent')
            console.log('initProfile: ' + this.$router.currentRoute.params.id);
            if(!this.$router.currentRoute.params.id || this.$router.currentRoute.params.id==='undefined') return;

            let url = `/init-profile-for-promo/${this.$router.currentRoute.params.id}`
            if(!this.isPromoPage(false)){
                url = this.currentUser.role === 'man' ?
                    `/init-profile/${this.$router.currentRoute.params.id}/${this.chatOwner.id}?type=${this.typeSelectOpponent}`:
                    `/init-profile/${this.chatOwner.id}/${this.$router.currentRoute.params.id}?type=${this.typeSelectOpponent}`
            }


            axios.get(url)
                .then(response => {
                    // this.personalStory = null;
                    console.log(response.data)
                    this.initProfileHandel(response.data);
                })
                .catch(e => {
                    console.log(e);
                    console.log('catch from profile');
                    this.errorHandler(e);
                })
        },
        checkPaidStory(story,purchases){
            if(story.credits === 0 || this.chatOwner.payment_status === 'luxury') return false;
            return !purchases.find(purchase => (+purchase.my_story_id === +story.id))
        },
        initProfileHandel(data){
            console.log(data);
            if(!data || !data.opponent) {
                this.$router.push({name: 'Error404'})
                return
            }
            this.myStories = this.addFieldsPost(data.myStories, data.likes, data.purchases)
            this.$emit('init_profile',data);

            if(this.$mq==='desktop'){
                if(this.isPromoPage(false)){
                    setTimeout(()=>{
                        this.toggleChat()
                    },2000);
                }
                setTimeout(()=>{
                    this.setFooterObserver();
                    this.isInit = true;
                },0);
            }else{
                if(this.isPromoPage(false)){

                    if(!this.prevRoute.path.includes('/promo/mobile-chat/')){
                        setTimeout(()=>{
                            //this.openMobileChat()
                            this.meetingPromoViewer()
                        },2000);
                    }else{
                        this.$bus.$emit('clear_unread_promo');
                    }
                }
                setTimeout(()=>{this.isInit = true},0);
            }

            //TODO chek
            // if(this.$mq === 'desktop'){
            //     this.$nextTick()
            //         .then(() => {
            //             setTimeout(() => this.pinSidebar(), 800)
            //         });
            // }
            if (this.showChat){
                this.$bus.$emit('chat_scroll_down');
                this.$bus.$emit('reset_unread_messages');
            }
            const el = this.$refs.videoProfile;
            console.log(el);
            if(el){
                this.reloadMediaSrc(el);
            }
        },
        addFieldsPost(myStories,likes,purchases){
            myStories.forEach(post=>{
                this.$set(post,'newComment','');
                this.$set(post,'isShowStory',false);
                this.$set(post,'story_comments',[]);
                this.$set(post,'medias',[]);
                this.$set(post,'isAllComments',false);
                this.$set(post,'like',this.isActionLike(post.id,likes));
                this.$set(post,'isNeedPaid', this.checkPaidStory(post,purchases) )
                // this.$set(post,'dislike',this.isActionLike(post.id,dislikes));
            });
            return myStories
        },
        isActionLike(id,arr){
            return Boolean (arr.find(item=>{
                return +item.my_story_id === +id;
            }));
        },
        moreMyStoriesHandler(data){
            const myStories = this.addFieldsPost(data.myStories, data.likes, data.purchases)
            myStories.forEach(story => {
                if(!story.hidden && story.is_publish){
                    this.myStories.push(story);
                }
            });
        },
        reloadMediaSrc(el){
            this.$nextTick(()=>{
                setTimeout(()=>{
                    el.load();
                },700);
            })
        },
        errorHandler(e){
            this.$emit('errorhandler',e);
        },
        setInitChat(value){
            this.isInit = value;
        },
        toggleAboutMeBlock(){
            this.showAboutMe = !this.showAboutMe
        },
        isPromoPage(openRegister = true){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat'){
                if(openRegister)this.$bus.$emit('open_register')
                return true
            }else {return false}
        },

    },

}
</script>
