<template>
<!--    <button class="button button_sm button_bordered button_rounded button_favorite" :class="{'is-active':isFavorite}"-->
<!--            @click.stop.capture="clickFavorite" v-if="$mq==='desktop'">-->
<!--        <span>-->
<!--            <i class="button__icon icon"></i>-->
<!--            <span class="button__text">Favorites</span>-->
<!--        </span>-->
<!--    </button>-->
    <button class="button button_round button_gradient button_favorite test" @click.stop.capture="clickFavorite" :class="{'is-active':isFavorite}" v-if="$mq==='desktop'">
<!--      <svg class="button__border" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" width="50" height="50">-->
<!--        <defs>-->
<!--          <linearGradient id="grad" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%"-->
<!--                          y2="0%">-->
<!--            <stop offset="0%" stop-color="#FF51F8"/>-->
<!--            <stop offset="50%" stop-color="#3F75FF"/>-->
<!--            <stop offset="100%" stop-color="#00D6BC"/>-->
<!--          </linearGradient>-->
<!--        </defs>-->
<!--        <circle cx="25" cy="25" r="22" fill="transparent" stroke="url(#grad)" stroke-width="2"/>-->
<!--      </svg>-->
      <i class="button__icon icon"></i>
    </button>
    <button class="button button_round button_gradient button_favorite" :class="{'is-active':isFavorite}"
            @click.stop.capture="clickFavorite" v-else>
        <svg class="button__border" viewBox="0 0 50 50" preserveAspectRatio="xMidYMid meet" width="50" height="50">
            <defs>
                <linearGradient id="grad" gradientUnits="userSpaceOnUse" x1="0%" y1="0%" x2="100%" y2="0%">
                    <stop offset="0%" stop-color="#FF51F8" />
                    <stop offset="50%" stop-color="#3F75FF" />
                    <stop offset="100%" stop-color="#00D6BC" />
                </linearGradient>
            </defs>
            <circle cx="25" cy="25" r="22" fill="transparent" stroke="url(#grad)" stroke-width="2"/>
        </svg>
        <i class="button__icon icon"></i>
    </button>
</template>
<script>
export default {
    name: "ButtonFavorite",
    props:['isFavorite','opponentId'],
    methods:{
        clickFavorite(){
            console.log('clickFavorite');
            // const status = this.selectedContact && this.selectedContact[`favorite_${this.roleOpponent}`];
            // console.log({status});
            this.$bus.$emit('start_change_favorite', !this.isFavorite, this.opponentId)
        },
    }
}
</script>

<style scoped>

</style>
