<template>
    <portal selector="#portal-target" >
        <div class="modal modal_cropper is-active" tabindex="-1" role="dialog">
            <div class="modal__bg" @click.prevent="closeModal"></div>
            <div class="modal__container">
                    <span class="modal__close button button_filled"  role="button" aria-label="Close" @click.prevent="closeModal">
                        <i class="icon icon_close"></i>
                    </span>
                <div class="modal-body">
                    <div class="image-cropper">
                        <template v-if="isErrorLoad">
                            <span class="text-danger">This file cannot be played by your browser, please select a new file</span>
                            <div class="image-cropper__buttons">
                                <button class="button button_primary button_sm" @click.prevent="closeModal" :disabled="!isSelectFrame">
                                    <span>Select new media</span>
                                </button>
                            </div>
                        </template>
                        <template v-else>
                            <span >Please select a freeze frame</span>
                            <div class="image-cropper__container" v-if="imageSrc">
                                <img  class="image-cropper__image" :src="imageSrc" ref="image" alt="">
                            </div>
                            <div class="image-cropper__container" v-else-if="videoSrc && typeVideo">
                                <video class="video image-cropper__video" ref="videoPlayer" autoplay playsinline controls muted>
                                    <source :src="videoSrc" :type="typeVideo">
                                </video>
                            </div>
                            <div class="image-cropper__buttons">
                                <button class="button button_primary button_sm" @click.prevent="stopVideo" v-if="!isSelectFrame">
                                    <span>Select Frame</span>
                                </button>
                                <button class="button button_primary button_sm" @click.prevent="playVideo" v-else>
                                    <span >Next Frame</span>
                                </button>
                                <button class="button button_primary button_sm" @click.prevent="goCrop" :disabled="!isSelectFrame">
                                    <span >Crop</span>
                                </button>
                            </div>

                        </template>

                    </div>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
import ImageCropper from "../../modules/ImageCropper.vue";

export default {
    name: "ModalShowVideo",
    components: {ImageCropper},
    props:['videoSrc','typeVideo'],
    data(){
        return{
            imageSrc: '',
            isSelectFrame: false,
            isErrorLoad: false,
        }
    },
    mounted() {
        this.$bus.$emit('open_modal');
        const media = this.imageSrc ?  this.$refs.image : this.$refs.videoPlayer ;
        console.log(media);
        if(media){
            if(media.error){
                this.isErrorLoad = true
            }else {
                console.log('media.onerror');
                media.onerror = () => {
                    this.isErrorLoad = true;
                }
            }

        }
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
    },
    methods:{
        closeModal(){
            this.$emit('close_modal');
        },
        playVideo(){
            this.$refs.videoPlayer.play();
            this.isSelectFrame = false;
        },
        stopVideo(){
            this.$refs.videoPlayer.pause();
            this.isSelectFrame = true;
        },
        async goCrop(){
            console.log('goCrop');
            const canvas = this.getCanvasVideo(this.$refs.videoPlayer);
            const blob = await new Promise(resolve => canvas.toBlob(resolve, "image/jpeg", 0.95));

            this.$emit('select_frame',blob);
        },
        getCanvasVideo(videoEl){
            console.log('getCanvasVideo');
            const w = videoEl.videoWidth;
            const h = videoEl.videoHeight;
            const canvas = document.createElement('canvas');
            canvas.width  = w;
            canvas.height = h;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(videoEl, 0, 0, w, h);
            return canvas;
        }
    }

}
</script>

<style scoped>

</style>
