<template>
    <section class="section section_intro section_welcome" :style="`min-height: ${windowHeight}px`">
        <hooper :settings="hooperSettings" :style="`height: ${windowHeight}px`">
            <slide class="slide" style="position: relative">
                <img class="video video_responsive" :src="imageLendingSrc1">
            </slide>
            <slide class="slide" style="position: relative">
                <img class="video video_responsive" :src="imageLendingSrc2">
            </slide>
            <slide class="slide" style="position: relative">
                <img class="video video_responsive" :src="imageLendingSrc3">
            </slide>
        </hooper>
        <div class="section__caption"
             :class="{'section__caption_older' : region === 'older' || 'ladies', 'section__caption_single' : region === 'single'}">
            <div class="content content_older" v-if="region === 'older'">
                <span class="title" v-if="region === 'older'">
                    <span class="is-first">The best place <span>for messaging</span></span>
                    <span class="is-second">With single women <span>who like older men</span></span>
                </span>
            </div>
            <div class="content content_older is-ladies" v-if="region === 'ladies'">
                <span class="title">
                    <span class="is-first">The best place <span>to chat</span></span>
                    <span class="is-second">with <span>Single ladies</span></span>
                </span>
            </div>
            <div class="content content_single" v-if="region === 'single'">
                <span class="title">
                    <span class="is-first">The best place <span>to chat</span></span>
                    <span class="is-second">with <span>single women</span></span>
                </span>
            </div>
<!--            <div class="section__buttons">-->
<!--                <button class="btn btn_light btn_login" data-toggle="modal" data-target="#loginModal" v-if="$mq ==='desktop'">Login</button>-->
<!--                <button class="btn"  :class="{'btn_border-light': $mq ==='desktop', 'btn_light': $mq !=='desktop'}" data-toggle="modal" data-target="#registerModal">Registration</button>-->
<!--            </div>-->
        </div>
    </section>
</template>

<script>
export default {
    name: "LandingImage",
    props:['cdnUrl','myUrl','windowHeight','cdnStaticDataPath','srcImage1', 'srcImage2','srcImage3','region'],
    data(){
        return{
            isMuted: true,
            imageLendingSrc1: null,
            imageLendingSrc2: null,
            imageLendingSrc3: null,
            hooperSettings: {
                infiniteScroll: true,
                centerMode: true,
                autoPlay: true,
                playSpeed: 4800,
                wheelControl: false,
                hoverPause: false,
                mouseDrag: true,
                touchDrag: true
            }
        }
    },
    created() {
    },
    mounted() {
        this.imageLendingSrc1 = this.setImageSrc(this.srcImage1);
        this.imageLendingSrc2 = this.setImageSrc(this.srcImage2);
        this.imageLendingSrc3 = this.setImageSrc(this.srcImage3);
    },
    methods:{
        scrollDown(){
            this.$emit('scroll_down_lending')
        },
        setImageSrc(img) {
            console.log('setImageSrc')

            const windowWidth = window.innerWidth;
            const windowHeight = window.innerHeight;
            // console.log(windowWidth,windowHeight);
            if (windowWidth >= 3840) {
                return  this.cdnUrl + this.cdnStaticDataPath + img.tv
            } else if (windowWidth >= 3440) {
                return this.cdnUrl + this.cdnStaticDataPath + img.big
            } else if (windowWidth >= 2560) {
                return this.cdnUrl + this.cdnStaticDataPath + img.high
            } else if (windowWidth >= 1920) {
                return this.cdnUrl + this.cdnStaticDataPath + img.xl
            } else if (windowWidth >= 1680) {
                return this.cdnUrl + this.cdnStaticDataPath + img.lg
            } else if (windowWidth >= 1440) {
                return this.cdnUrl + this.cdnStaticDataPath + img.md
            } else if (windowWidth >= 1280) {
                return this.cdnUrl + this.cdnStaticDataPath + img.smart
            } else if (windowWidth >= 992) {
                return this.cdnUrl + this.cdnStaticDataPath + img.ipad
            } else if (windowWidth >= 768) {
                return this.cdnUrl + this.cdnStaticDataPath + img.medium
            } else if (windowWidth >= 567) {
                return this.cdnUrl + this.cdnStaticDataPath + img.mobile
            } else if (windowWidth >= 428) {
                return this.cdnUrl + this.cdnStaticDataPath + img.xs
            } else if (windowWidth >= 414) {
                if (windowWidth === 414 && windowHeight === 736) {
                    return this.cdnUrl + this.cdnStaticDataPath + img.xs_414_736
                } else {
                    return this.cdnUrl + this.cdnStaticDataPath + img.xs_414
                }
            } else if (windowWidth >= 390) {
                return this.cdnUrl + this.cdnStaticDataPath + img.xxs
            } else if (windowWidth >= 375) {
                return this.cdnUrl + this.cdnStaticDataPath + img.xxs_375
            } else {
                return this.cdnUrl + this.cdnStaticDataPath + img.xxs_320
            }
        },
    }
}
</script>

<style scoped>

</style>
