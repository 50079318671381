<template>
    <div class="banner banner_photo">
        <!--span class="banner__heading"></span-->
        <div class="banner__timer">
            <h3 class="banner__title">Action of the promotion</h3>
            <div class="countdown">
                <div class="countdown__item countdown__item_days">
                    <span class="countdown__value">{{twoDigits(days)}}</span>
                    <span class="countdown__label">day</span>
                </div>
                <div class="countdown__item countdown__item_hours">
                    <span class="countdown__value">{{twoDigits(hours)}}</span>
                    <span class="countdown__label">hours</span>
                </div>
                <div class="countdown__item countdown__item_minutes">
                    <span class="countdown__value">{{twoDigits(minutes)}}</span>
                    <span class="countdown__label">minute</span>
                </div>
                <div class="countdown__item countdown__item_seconds">
                    <span class="countdown__value">{{twoDigits(seconds)}}</span>
                    <span class="countdown__label">second</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

 let intervalNow = null;
export default {
    name: "CountdownPromo",
    props: {
        deadline: {
            type: String,
        },
    },
    data() {
        return {
            now: Math.trunc(new Date().getTime() / 1000),
            date: null,
            diff: 0,
            show: false,
        }
    },
    computed: {
        seconds() {
            return Math.trunc(this.diff) % 60;
        },
        minutes() {
            return Math.trunc(this.diff / 60) % 60;
        },
        hours() {
            return Math.trunc(this.diff / 60 / 60) % 24;
        },
        days() {
            return Math.trunc(this.diff / 60 / 60 / 24);
        },
    },
    watch: {
        now(value) {
            this.getDiff();
        },
        // diff(value) {
        //     if (value === 0) {
        //         this.$emit('stop_promo');
        //         // this.updateAllCards();
        //     }
        // },
    },
    created() {
        if (!this.deadline) {
            throw new Error("Missing props 'deadline'");
        }
        // const endTime = this.deadline;
        // this.date = Math.trunc(Date.parse(endTime.replace(/-/g, '/')) / 1000);
        this.date = Math.trunc(Date.parse(this.deadline) / 1000);
        // this.now = Math.trunc(new Date().getTime() / 1000);
        if (!this.date) {
            throw new Error("Invalid props value, correct the 'deadline'");
        }
        this.getDiff();
        intervalNow = setInterval(() => {
            this.now = Math.trunc(new Date().getTime() / 1000);
        }, 1000);
    },
    mounted() {
        if (this.diff !== 0) {
            this.show = true;
        }
    },
    methods:{
        getDiff(){
            this.diff = this.date - this.now;
            if (this.diff <= 0 || this.stop) {
                this.diff = 0;
                this.$emit('stop_promo');
                this.$bus.$emit('stop_switch_promo');
                clearInterval(intervalNow);
            }
        },
        twoDigits(value) {
            if (value.toString().length <= 1) {
                return '0' + value.toString();
            }

            return value.toString();
        },
    }
}
</script>

<style scoped>

</style>
