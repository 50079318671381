<template>
    <portal selector="#portal-target">
        <div class="modal modal_sm modal_block-user is-active"  tabindex="-1" role="dialog">
            <div class="modal__bg" @click.prevent="closeModalSomethingWrong"></div>
            <div class="modal__container">
                <span class="modal__close"  role="button" aria-label="Close" @click.prevent="closeModalSomethingWrong">
                    <i class="icon icon_close"></i>
                </span>
                <div class="modal__header">
                    <h2 class="modal__title">Attention!</h2>
                </div>
                <div class="modal__body">
                    <p>Something went wrong!</p>
                </div>
                <div class="modal__footer">
                    <button class="button button_md button_primary" @click.prevent="closeModalSomethingWrong">
                        <span>Report!</span>
                    </button>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
export default {
    name: "ModalSomethingWrong",
    mounted() {
        this.$bus.$emit('open_modal')
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
    },
    methods:{
        closeModalSomethingWrong(){
            this.$emit('close_modal_something_wrong')
        },
    }
}
</script>

<style scoped>

</style>
