<template>
    <portal selector="#portal-target">
        <div :class="`modal modal_lg modal_gifts is-active`" tabindex="-1" role="dialog">
            <div class="modal__bg" @click="closeModal"></div>
            <div class="modal__container">
                <span class="modal__close" role="button" aria-label="Close" @click.prevent="closeModal"
                      v-if="$mq === 'desktop'">
                    <i class="icon icon_close"></i>
                </span>
                <div class="modal__header">
                    <button class="button button_back" aria-label="Close" @click.prevent="closeModal" v-if="$mq !== 'desktop'">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" class="button__icon">
                            <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#back`"></use>
                        </svg>
                    </button>
                    <i class="icon icon_present" v-else></i>
                    <h2 class="modal__title">Virtual gifts</h2>
                </div>
                <div class="modal__body">
                    <div class="gifts">
                        <!--        render gift cards                -->
                        <div class="gift" v-for="(gift, index) of gifts" :key="gift.id">
                            <div class="gift__body">

                                <img class="gift__image"
                                    :src="`${s3UrlStatic}/${gift.url}`" alt="gift"
                                      width="130" height="130">
                            </div>
                            <div class="gift__footer">
                                <i class="icon_gifts_tr"></i>
                                <div class="gift__footer-row">
                                    <div class="gift__footer-description">
                                        <div class="gift__footer-title">
                                            <span>{{gift.title}}</span>
                                        </div>
                                        <div class="gift__footer-crypto">
                                            <span><i class="icon icon_crypto"></i></span>
                                            <span class="crypto-amount">{{gift.credits/100}}</span>
                                        </div>
                                    </div>
                                    <button class="button button_sm button_bordered button_rounded button_gifts"
                                            v-if="$mq === 'desktop'"
                                            @click.prevent="buyGift(gift)"
                                    >
                                        <span><span class="button__text">Give</span></span>
                                    </button>
                                    <button
                                        class="button button_round button_rounded button_bordered button_gifts"
                                        style="width: 37px; height: 37px;" v-else
                                        @click.prevent="buyGift(gift)"
                                    >
                                        <span>
                                    <i class="icon icon_present" style="width: 20px; height: 20px;"></i>
                                            </span>
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </portal>
</template>


<script>
export default {
    name: "ModalGifts",
    props: ['s3Url', 'myUrl', 'gifts', 's3UrlStatic', 'opponentId'],
    computed: {},
    mounted() {},
    beforeDestroy() {},
    methods: {
        closeModal() {
            this.$bus.$emit('close-gifts-modal')
        },
        buyGift(gift){
            console.log(gift)
            this.$bus.$emit('send-gift', this.opponentId, gift)
            this.$bus.$emit('close-gifts-modal')
        }
    }
}
</script>


<style scoped>

</style>
