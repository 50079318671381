<template>
    <div class="page__filter">
        <button class="button button_link" @click.prevent="resetSearch" v-if="$mq!=='desktop'">
            <span>Reset</span>
            <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#reload`" />
            </svg>
        </button>
        <button class="button button_link" @click.prevent="showEditSearch">
            <span>Edit <i class="icon icon_edit"></i></span>
        </button>
        <ul class="list list_tags">
            <li class="list__item" v-if="searchData && searchData.searchMembers && searchData.searchMembers.name">
                <span class="tag">{{searchData.searchMembers.name}} <i class="icon icon_close" @click="deleteField('searchMembers','name')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchMembers && searchData.searchMembers.country_id">
                <span class="tag">{{ countryName(searchData.searchMembers.country_id) }} <i class="icon icon_close" @click="deleteField('searchMembers','country_id')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchRanges && searchData.searchRanges.ageFrom">
                <span class="tag">Age from:{{searchData.searchRanges.ageFrom}} <i class="icon icon_close" @click="deleteField('searchRanges','ageFrom')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchRanges && searchData.searchRanges.ageTo">
                <span class="tag">Age to:{{searchData.searchRanges.ageTo}} <i class="icon icon_close" @click="deleteField('searchRanges','ageTo')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchParams && searchData.searchParams.lang">
                <span class="tag">{{searchData.searchParams.lang}} <i class="icon icon_close" @click="deleteField('searchParams','lang')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchParams && searchData.searchParams.body_type">
                <span class="tag">{{searchData.searchParams.body_type}} <i class="icon icon_close" @click="deleteField('searchParams','body_type')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchRanges && searchData.searchRanges.weightFrom">
                <span class="tag">{{searchData.searchRanges.weightFrom}}-{{searchData.searchRanges.weightTo}}<i class="icon icon_close" @click="deleteField('searchRanges','weightFrom')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchParams && searchData.searchParams.height">
                <span class="tag">{{searchData.searchParams.height}} <i class="icon icon_close" @click="deleteField('searchParams','height')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchParams && searchData.searchParams.hair_color">
                <span class="tag">Hair:{{searchData.searchParams.hair_color}} <i class="icon icon_close" @click="deleteField('searchParams','hair_color')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchParams && searchData.searchParams.eye_color">
                <span class="tag">Eyes:{{searchData.searchParams.eye_color}} <i class="icon icon_close" @click="deleteField('searchParams','eye_color')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchParams && searchData.searchParams.marital_status">
                <span class="tag">{{searchData.searchParams.marital_status}} <i class="icon icon_close" @click="deleteField('searchParams','marital_status')"></i></span>
            </li>
            <li class="list__item" v-if="searchData && searchData.searchParams && searchData.searchParams.kids">
                <span class="tag">Kids:{{searchData.searchParams.kids}} <i class="icon icon_close" @click="deleteField('searchParams','kids')"></i></span>
            </li>
        </ul>
        <button class="button button_link" @click.prevent="resetSearch" v-if="$mq==='desktop'">
            <span>Reset</span>
            <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="12" height="12">
                <use :xlink:href="`${myUrl}/source/images/icons/icons.svg#reload`" />
            </svg>
        </button>
    </div>
</template>

<script>
export default {
name: "FilterDesktop",
    props:['myUrl','searchData','countries'],
    data(){
        return{
            // searchData:null
        }
    },
    computed:{


    },
    mounted() {
        // this.searchData = this.searchDataInit;
    },
    methods:{
        countryName(id){
            return this.countries.find(country=>{
                return +id === +country.id
            }).countryName
        },
        showEditSearch(){
            this.$bus.$emit('show_edit_search');
        },
        deleteField(type,field){
            const searchData = {...this.searchData}
            if(!searchData[type]) return;
            delete searchData[type][field]
            console.log(searchData);
            if (searchData && this.isNotEmptyObject(searchData.searchParams) || this.isNotEmptyObject(searchData.searchRanges) || this.isNotEmptyObject(searchData.searchMembers)){
                axios.post('/search',searchData)
                    .then(response => {
                        console.log('success');
                        console.log(response.data);
                        if (!response.data.members.length){
                            alert('No results were found for your search')
                        } else {
                            this.$bus.$emit('search_complete',response.data.members, response.data.searchMemberIDs, searchData, true, response.data.views);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }else {
                this.resetSearch();
            }
        },
        resetSearch(){
            this.$emit('reset_search')
        },
        isNotEmptyObject(obj) {
            return Boolean(Object.keys(obj).length);
        },
    }
}
</script>

<style scoped>

</style>
