<template>
    <ul class="list page__list-price">
        <li class="list__item">
            <span class="list__icon"><i class="icon icon_letter-full"></i></span>
            <span class="list__title">Communicate without restrictions</span>
        </li>
        <li class="list__item">
            <span class="list__icon"><i class="icon icon_camera"></i></span>
            <span class="list__title">View photos without restrictions</span>
        </li>
        <li class="list__item">
            <span class="list__icon"><i class="icon icon_video"></i></span>
            <span class="list__title">Send and receive video</span>
        </li>
        <li class="list__item">
            <span class="list__icon"><i class="icon icon_mic"></i></span>
            <span class="list__title">Communicate using voice messages</span>
        </li>
    </ul>
</template>
<script>
export default {
    name: "ListWithCredits"
}
</script>

<style scoped>

</style>
