<template>
    <portal selector="#portal-target">
        <div class="modal modal_md modal_account is-active" tabindex="-1" role="dialog">
            <div class="modal__bg" @click="closeModalConfirmDeleteStory"></div>
            <div class="modal__container">
                    <span class="modal__close" role="button" aria-label="Close" @click="closeModalConfirmDeleteStory">
                        <i class="icon icon_close"></i></span>
                <div class="modal__header">
                    <h3 class="modal__title">Confirm you want to delete this story</h3>
                </div>
                <div class="form form_horizontal">
                    <div class="form__footer">
                        <button class="button button_md button_primary" @click.prevent="confirmDeleteStory"><span>Confirm</span></button>
                    </div>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
export default {
    name: "ConfirmDeleteStory",
    methods: {
        closeModalConfirmDeleteStory(){
            console.log('closeModalConfirmDeleteStory')
            this.$emit('close_confirm_delete')
        },
        confirmDeleteStory(){
            console.log('confirmDeleteStory ')
            this.$emit('confirm_delete_story')
        }
    }
}
</script>

<style scoped>

</style>
