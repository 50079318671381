<template>
    <span class="message__text" :style="`font-size: ${messageSizeRem}rem`">
        <span><i class="button__icon icon icon_cristal"></i></span>
        <a class="message__link" @click="redirect" target="_blank" rel="noreferrer">{{message.src}}</a>
    </span>
</template>
<script>
export default {
    name:"ChatItemLink",
    props:['messageSizeRem','message'],
    methods:{
        redirect(){
            let url = this.message.src
            let redirect_rule = 'http://'
            let redirect_rule_security = 'https://'
            if (url.indexOf(redirect_rule) == -1 && url.indexOf(redirect_rule_security) == -1) {
               url = `${redirect_rule_security}${url}`
            }
            window.open(url, '_blank', 'noreferrer');
        }
    }
}
</script>
<style scoped>

</style>
