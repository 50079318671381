<template>
    <portal selector="#portal-target">
        <div class="modal modal_sm modal_block-user is-active"  tabindex="-1" role="dialog">
            <div class="modal__bg" ></div>
            <div class="modal__container">
                <span class="modal__close"  role="button" aria-label="Close" @click.prevent="closeModal">
                    <i class="icon icon_close"></i>
                </span>
                <div class="modal__header">
                    <h2 class="modal__title">Attention!</h2>
                </div>
                <div class="modal__body">
                    <p>A new update has appeared, we recommend applying it for the correct operation of the service</p>

                    <p class="m-0 text-medium"> Current version: {{ buildVersion }}</p>
                    <p class="m-0 text-medium"> New version: {{ newBuildVersion }}</p>

                    <p v-if="seconds>=0">New updates are automatically applied after {{ seconds }} seconds</p>
                    <p v-else class="text-danger">The page will be updated</p>
                </div>
                <div class="modal__footer">
                    <button class="button button_md button_primary" @click.prevent="closeModal">
                        <span>Cancel</span>
                    </button>
                    <button class="button button_md button_primary" @click.prevent="apply">
                        <span>Apply</span>
                    </button>
                </div>
            </div>
        </div>
    </portal>
</template>
<script>
let timer = null
export default {
    name: "ModalUpdate",
    props: ['activeWindowsCount','buildVersion','newBuildVersion'],
    data(){
        return{
            seconds: 16
        }
    },
    mounted() {
        this.$bus.$emit('open_modal')
        this.timerUpdate()
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
        if(timer)clearTimeout(timer)
    },
    methods:{
        timerUpdate(){
            if(this.seconds < 0){
                if(timer)clearTimeout(timer)
                window.location.reload()
            }else {
                timer = setTimeout(()=>{
                    this.timerUpdate()
                },1000)
            }
            this.seconds --
        },
        closeModal(){
            this.$emit('close_modal');
        },
        apply(){
            window.location.reload();
        },
    }
}
</script>


<style scoped>

</style>
