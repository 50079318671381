<template>
    <div class="container contactus" >
        <h2 class="section__heading heading contactus__heading"> Do you have any questions?</h2>
        <!--                <ul class="menu menu_contact">-->
        <!--                    <li class="menu__item"><i class="icon icon_phone"></i><a class="menu__link"-->
        <!--                                                                             href="tel:+442045773347">+442045773347</a>-->
        <!--                    </li>-->
        <!--                    <li class="menu__item"><i class="icon icon_mail"></i>
                                  <a class="menu__link" href="mailto:support@hablario.com">support@hablario.com</a></li>-->
        <!--                    <li class="menu__item"><i class="icon icon_mail"></i>
                                    <a class="menu__link" href="mailto:hablario@gmail.com">hablario@gmail.com</a>-->
        <!--                    </li>-->
        <!--                    <li class="menu__item">-->
        <!--                        <i class="icon icon_marker"></i>-->
        <!--                        <span class="address">-->
        <!--                            <span class="address__title">London</span>-->
        <!--                            <span class="address__text">United Kingdom</span>-->
        <!--                            <span class="address__text">65 London Wall, EC2M 5TU</span>-->
        <!--                        </span>-->
        <!--                    </li>-->
        <!--                </ul>-->
        <!--                <ul class="social social_light">-->
        <!--                    <li class="social__item"><a href="https://www.facebook.com/HablarioApp/" class="social__link" target="_blank"-->
        <!--                                                aria-label="Read more about us in Facebook"><i class="icon icon_fb"></i></a></li>-->
        <!--                    <li class="social__item"><a href="https://www.youtube.com/channel/UCaaSOBP6kbEV4QkoCoYRHhw" class="social__link" target="_blank"-->
        <!--                                                aria-label="Watch more about us in youtube"><i class="icon icon_yb"></i></a></li>-->
        <!--                    <li class="social__item"><a href="https://www.instagram.com/hablarioapp/" class="social__link" target="_blank"-->
        <!--                                                aria-label="Read more about us in instagram"><i class="icon icon_in"></i></a></li>-->
        <!--                </ul>-->

        <h3 class="section__sub-heading contactus__sub-heading">Mail us</h3>
        <div class="form form_horizontal">
            <div class="form__row">
                <input class="ui-input" type="text" placeholder="Name" maxlength="50" v-model="contactName">
            </div>
            <div class="form__row" :class="{'is-error':isErrorEmail}">
                <input class="ui-input" type="email" placeholder="Email" maxlength="100" v-model="contactEmail">
            </div>
            <div class="form__row">
                <textarea class="ui-textarea" placeholder="Message" rows="7" maxlength="510"
                          v-model="contactMessage"></textarea>
            </div>
            <div class="form__footer form__contactus" :class="{'is-error':isRobotError}">
                <label for="robot" class="form__label">
                    <input type="checkbox" id="robot" class="ui-checkbox" v-model="isNotRobot">
                    <span class="label" :class="{'text-red':isRobotError}">Not robot</span>
                </label>
                <button class="button button_md button_primary button_contactus" @click="sendContactUs">
                    <span>Send</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "ContactUs",
    props: ['google_re_captcha_key'],
    data() {
        return {
            contactMessage: '',
            contactName: '',
            contactEmail: '',
            isErrorEmail: false,
            isNotRobot: false,
            isRobotError: false,
        }
    },
    methods: {
        checkReCaptcha() {
            return new Promise(resolve => {
                console.log('checkReCaptcha');
                if (!grecaptcha || !grecaptcha.enterprise) {
                    return resolve('')
                }
                grecaptcha.enterprise.ready(async () => {
                    //'6LcWMo4mAAAAAKmuoZ4acPpykcNI48cbYVF4sXmK'
                    const token = await grecaptcha.enterprise.execute(this.google_re_captcha_key, {action: 'LOGIN'});
                    // IMPORTANT: The 'token' that results from execute is an encrypted response sent by
                    // reCAPTCHA Enterprise to the end user's browser.
                    // This token must be validated by creating an assessment.
                    // See https://cloud.google.com/recaptcha-enterprise/docs/create-assessment
                    return resolve(token)
                });
            })


        },
        async sendContactUs() {
            if (!this.isNotRobot) {
                this.isRobotError = true;
                alert('Please confirm you are not a robot');
                return
            }
            if (this.contactEmail && this.contactMessage && this.contactName) {
                const token = await this.checkReCaptcha()
                console.log({token});
                if (!token) return
                const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                if (re.test(String(this.contactEmail).toLowerCase())) {
                    axios.post('/contact-us-save-message', {
                        name: this.contactName,
                        email: this.contactEmail,
                        message: this.contactMessage,
                        token: token,
                    })
                        .then(response => {
                            console.log(response.data);
                            if (response.data.status === 'ok') {
                                this.contactMessage = '';
                                this.contactName = '';
                                this.contactEmail = '';
                                alert('Thank you for contacting us. Our specialists will contact you shortly.');
                                this.$emit('close_modal')
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            this.errorHandler(e);
                        })
                } else {
                    this.isErrorEmail = true;
                    alert('Please check your email');
                }

            } else {
                alert('Please fill in all fields in the form');
            }
        },
        errorHandler(err) {
            this.$emit('errorhandler',e);
        },
    }
}
</script>

<style scoped>

</style>
