<template>
    <div>
        <video class="video video_responsive" autoplay playsinline loop :muted="isMuted" style="visibility: visible;" ref="video" v-show="isLoadVideo">
            <source :src="videoLendingSrcWebm" type="video/webm">
            <source :src="videoLendingSrc" type="video/mp4">
<!--            <source src="https://hablario.fra1.cdn.digitaloceanspaces.com/lfl-static-data/lending/1920_1080.mp4"
                    type="video/mp4">-->

<!--                        <source src="https://ps3lifeforlove.fra1.cdn.digitaloceanspaces.com/hablario-static-data/lending/1280_720.mp4" type="video/mp4">-->
        </video>
    </div>
<!--    <section id="home" class="section section_intro" ref="videoBg" :style="`background-image: url('${myUrl}/assets/images/image 615.jpg'); min-height: ${windowHeight}px`">-->
<!--        <video class="video video_responsive" autoplay playsinline loop :muted="isMuted" ref="video" v-show="isLoadVideo">-->
<!--            <source :src="videoLendingSrcWebm" type="video/webm">-->
<!--            <source :src="videoLendingSrc" type="video/mp4">-->
<!--        </video>-->
<!--        <button class="btn btn_icon btn_mute" @click.prevent="toggleMute" v-if="videoLendingSrc && !isWelcome">-->
<!--            <i class="icomoon icon-sound-on" v-if="!isMuted"></i>-->
<!--            <i class="icomoon icon-sound-off" v-else><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span></i>-->
<!--        </button>-->
<!--        <div class="section__caption" :class="{'is-welcome': isWelcome}">-->
<!--            <button class="btn btn_light gs_reveal" data-toggle="modal" data-target="#loginModal" v-if="!isWelcome">Login</button>-->
<!--            <button class="btn btn_border-light gs_reveal"  data-toggle="modal" data-target="#registerModal">Registration</button>-->
<!--        </div>-->
<!--        <button class="btn-scroll gs_reveal" @click.prevent="scrollDown" v-if="!isWelcome"><span></span></button>-->
<!--    </section>-->
</template>

<script>
export default {
name: "LandingVideo",
    props:['cdnUrl','myUrl','windowHeight','cdnStaticDataPath','isWelcome','srcVideo'],
    data(){
        return{
            isMuted: true,
            videoLendingSrcWebm: '',
            videoLendingSrc: '',
            isLoadVideo: false,
            // srcVideo: {
            //     tv: '/lending/3840_2160.mp4',
            //     big: '/lending/3440_1440.mp4', //
            //     high: '/lending/2560_1440.mp4', //
            //     xl: '/lending/1920_1080.mp4', //
            //     lg: '/lending/1440_1024.mp4', //
            //     md: '/lending/1280_720.mp4', //
            //     smart:'/lending/434_864.mp4', // 343Х864
            // },
        }
    },
    created() {
    },
    mounted() {
        this.setVideoSrc()
        if(this.$refs.video){
            this.$emit('set_video_el',this.$refs.video);
        }
        if (this.videoCanPlay()) {
            this.$refs.video.oncanplay = () => {
                if (!this.$refs.video) return
                this.isLoadVideo = true;
                this.$refs.video.style.visibility = 'visible'            }
        }
    },
    methods:{
        scrollDown(){
            this.$emit('scroll_down_lending')
        },
        videoCanPlay () {
            return !!this.$refs.video.canPlayType
        },
        setVideoSrc() {
            if (this.$refs.video) {
                let windowWidth = window.innerWidth;
                let windowHeight = window.innerHeight;
                console.log({windowWidth, windowHeight});
                if( windowWidth/windowHeight < 1 ){
                    this.videoLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcVideo.smart
                } else if (windowWidth >= 3840) {
                    this.videoLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcVideo.tv
                } else if (windowWidth >= 3440) {
                    this.videoLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcVideo.big
                } else if (windowWidth >= 2560) {
                    this.videoLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcVideo.high
                } else if (windowWidth >= 1920) {
                    this.videoLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcVideo.xl
                }else if (windowWidth >= 1440) {
                    this.videoLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcVideo.lg
                } else{
                    this.videoLendingSrc = this.cdnUrl + this.cdnStaticDataPath + this.srcVideo.md
                }
                this.videoLendingSrcWebm = this.videoLendingSrc.replace('.mp4','.webm')
            }
        },
    }
}
</script>

<style scoped>

</style>
