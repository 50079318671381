<template>
<div>
    <CoolLightBox
        :items="mediaChat"
        :index="indexMedia"
        :loop="false"
        :slideshow="false"
        @on-open="onOpenLightBox"
        @close="closeLightBox"
    ></CoolLightBox>

    <template v-if="currentUser && chatOwner">
        <transition name="fade" mode="out-in">
            <side-bar v-if="showSideBar && $route.name!=='PaymentCgStatus' && $route.name!=='PaymentCgStatusTest' && $route.name!=='PaymentPage'"
                :s3-url="s3Url"
                :my-url="myUrl"
                :show-support="showSupport"
                :current-user="currentUser"
                :chat-owner="chatOwner"
                :contacts-init="contacts"
                :type="'history'"
                :selected-contact="selectedContact"
                :indicator-new-opponent="indicatorNewOpponent"
                :is-all-history="isAllHistory"
                :is-all-favorite="isAllFavorite"
                :is-all-chat-request="isAllChatRequest"
                :is-download="isDownload"
                :ban-list="banList"
                :role-opponent="roleOpponent"
                :is-search-done="isSearch"
                :settings-init="mySettings"
                :support-contact="supportContact"
                :count-unread="countUnread"
                :disabled-notification="disabledNotification"
                :show-notification-button="showNotificationButton"
                :on-subscribe-notification="onSubscribeNotification"
                :is-new-version="isNewVersion"
                :count-contacts="countContacts"
                :is-supported-webp="isSupportedWebp"
                :holiday-promotion-name="holidayPromotionName"
                :is-sound="isSound"
                @open_price="openPrice"
                @open_search="openSearch"
                @open_menu="openMenu"
                @reset-search="resetSearch"
                @errorhandler="errorHandler"
            ></side-bar>
        </transition>

        <router-view
            :s3-url="s3Url"
            :cdn-letter-images="cdnLetterImages"
            :s3-url-static="s3UrlStatic"
            :gallery-init="gallery"
            :pack-medias="packMedias"
            :is-paid-package="isPaidPackage"
            :my-url="myUrl"
            :current-user="currentUser"
            :messages="messages"
            :chat-owner="chatOwner"
            :opponent="opponent"
            :opponent-age="opponentAge"
            :emojis="emojis"
            :moods="moods"
            :countries="countries"
            :ban-list="banList"
            :favorites="favorites"
            :role-opponent="roleOpponent"
            :loaded="isInitData"
            :selected-contact="selectedContact"
            :portfolio-images="portfolioImages"
            :my-banned="myBanned"
            :is-all-messages="isAllMessages"
            :selected-contact-id="selectedContact?selectedContact.id:null"
            :scroll-gallery-position="scrollPosition"
            :show-chat="showChat"
            :tariffs="tariffs"
            :indicator-new-opponent="indicatorNewOpponent"
            :settings-init="mySettings"
            :is-open-stories="isOpenStories"
            :count-unread="countUnread"
            :is-all-gallery="isAllGallery"
            :is-get-more-gallery="isGetMoreGallery"
            :show-notification-button="showNotificationButton"
            :disabled-notification="disabledNotification"
            :on-subscribe-notification="onSubscribeNotification"
            :holiday-promotion-name="holidayPromotionName"
            :disabled-send-message="disabledSendMessage"
            :used-video-codecs="usedVideoCodecs"
            :gallery-crop-limit="galleryCropLimit"
            :gallery-index-from="indexFrom"
            :gallery-index-to="indexTo"
            :bonuses-credits="bonusesCredits"
            :is-scroll="isScroll"
            :is-supported-webp="isSupportedWebp"
            :message-size="mySettings ? mySettings.message_size : 16"
            :support-messages="supportMessages"
            :support-contact="supportContact"
            :support-typing="supportTyping"
            :is-all-support-messages="isAllSupportMessages"
            :last-support-messages-i-d="lastSupportMessagesID"
            :window-height="windowHeight"
            :is-favorite="isFavoriteOpponent"
            :init-support-message="initSupportMessage"
            :is-search="isSearch"
            :search-data="searchData"
            :media-chat="mediaChat"
            :index-media="indexMedia"
            :gift-processing="giftProcessing"
            :show-modal-gifts="showModalGifts"
            :gifts="gifts"
            :modal-complain="modalComplain"
            :modal-blocked="modalBlocked"
            :index-slide="indexSlide"
            :offset-slide="offsetSlide"
            :limit-slide="limitSlide"
            :updating-slide="updatingSlide"
            :is-blocked-opponent="isBlockedOpponent"
            :typings-m-l-message="typingsMLMessage"
            :show-modal-lost-connection="showModalLostConnection"
            :type-select-opponent="typeSelectOpponent"
            @open_menu="openMenu"
            @go_to_request="goToRequest"
            @close-support="closeSupportChat"
            @errorhandler="errorHandler"
            @block_opponent="blockOpponent"
            @reset_opponent="resetOpponent"
            @change_favorite="changeFavorite"
            @more_gallery_start="getMoreGallery"
            @init_profile="initProfileHandel"
            @scroll_gallery_position="setScrollGalleryPosition"
            @show_chat="setShowChat"
            @reset_search="resetSearch"
            @update_member_profile="updateChatOwner"
            @update_current_user="updateCurrentUser"
            @change-state-gallery-slider="changeStateGallerySlider"
            @update-offset-gallery-slide="updateOffsetGallerySlide"
            @update-limit-gallery-slide="updateLimitGallerySlide"
            @update-index-gallery-slide="updateIndexGallerySlide"
        ></router-view>

        <support-chat
            :show-support="showSupport"
            :current-user="currentUser"
            :message-size="mySettings ? mySettings.message_size : 16"
            :my-url="myUrl"
            :s3-url="s3Url"
            :support-messages="supportMessages"
            :support-contact="supportContact"
            :support-typing="supportTyping"
            :is-all-support-messages="isAllSupportMessages"
            :last-support-messages-i-d="lastSupportMessagesID"
            :window-height="windowHeight"
            :count-unread="countUnread"
            :init-support-message="initSupportMessage"
            @open_menu="openMenu"
            @go_to_request="goToRequest"
            @close-support="closeSupportChat"
            v-if="showSupport && $mq === 'desktop'"
        ></support-chat>
        <search v-if="showSearch"
                :my-url="myUrl"
                :show="showSearch"
                :chat-owner-id="chatOwner ? chatOwner.id: undefined"
                :my-role="currentUser ? currentUser.role: ''"
                :countries="countries"
                :window-height="windowHeight"
                :search-data-init="searchData"
                @open_menu="openMenu"
                @go_to_request="goToRequest"
                @open_search="openSearch"
                @close_search="closeSearch"
        >
        </search>
        <service-prices
            v-if="showPrice"
            :show-price="showPrice"
            @close_price="closePrice"
            :my-url="myUrl"
            :tariffs="tariffs"
        ></service-prices>
        <clear-unread-messages v-if="modalClearMessages"
                               @close_modal_clear="closeModalClear"
                               @clear_unread_messages="clearUnreadMessages"
        ></clear-unread-messages>
        <clear-history v-if="modalClearHistory"
                               @close_modal_clear_history="closeModalClearHistory"
                               @clear_my_history="clearMyHistory"
        ></clear-history>
    </template>

    <modal-session-ended
        v-if="showModalSessionEnded"
        @close_modal_session_ended="closeModalSessionEnded"
    ></modal-session-ended>
    <modal-something-wrong
        v-if="showModalSomethingWrong"
        @close_modal_something_wrong="closeModalSomethingWrong"
    ></modal-something-wrong>
    <modal-maintenance
        v-else-if="showModalMaintenance"
        @close_modal="closeModalMaintenance"
    ></modal-maintenance>
    <modal-no-connection
        v-if="showModalNoConnection"
        @close_modal_no_connection="closeModalNoConnection"
    ></modal-no-connection>
<!--    <modal-lost-connection-->
<!--        v-if="showModalLostConnection"-->
<!--        @close_modal_lost_connection=""-->
<!--    ></modal-lost-connection>-->
    <modal-update
        :active-windows-count="activeWindowsCount"
        :build-version="buildVersion"
        :new-build-version="newBuildVersion"
        v-if="showModalUpdate"
        @close_modal="showModalUpdate=false"
    ></modal-update>
    <modal-promo  v-if="validHolidayPromotion && showModalPromo"
                  :promoName="holidayPromotionName"
                  @close_modal="closeModalBonus">
    </modal-promo>
<!--    <modal-donate-->
<!--        v-if="showModalDonate"-->
<!--        @close_modal="closeModalDonate">-->
<!--    </modal-donate>-->
    <modal-payment-coins-buy-status  v-if="modalCoinsBuyStatus && cryptoCoinsbuyPayment"
                                     :amount="cryptoCoinsbuyPayment.amount"
                                     :credits="cryptoCoinsbuyPayment.credits"
                  @close_modal="closeModalCoinsBuyStatus">
    </modal-payment-coins-buy-status>
<!--    <modal-payment-crypto v-if="modalPaymentCrypto"-->
<!--                          @close_modal="closeModalPaymentCrypto">-->
<!--    </modal-payment-crypto>-->

    <audio ref="playSound" style="display:none">
        <source :src="`${myUrl}/sounds/newUserSounds/${soundList[sound]}.mp3`" type="audio/mpeg">
        <source :src="`${myUrl}/sounds/newUserSounds/${soundList[sound]}.ogg`" type="audio/ogg">
    </audio>

</div>
</template>
<script>

import Gallery from "./Gallery"
import Account from "./Account";
import Profile from "./Profile";
//import Login from "./auth/login";
import InstallDesktop from "./InstallDesktop";
import PushNotification from "./PushNotification";
//import Register from "./auth/register";
import SuccessRegister from "../components/SuccessRegister";
//import AuthComponent from "./auth/AuthComponent";
import AwaitingVerification from "./modules/AwaitingVerification";
import PriceTable from "./modules/PriceTable";
import Search from "./modules/Search";
import FilterDesktop from "./modules/FilterDesktop";
import * as PusherPushNotifications from '@pusher/push-notifications-web'
import SideBar from "./newModules/SideBar.vue";
import SupportChat from "./newModules/modals/SupportChat.vue";
import ServicePrices from "./newModules/modals/ServicePrices.vue";
import ClearUnreadMessages from "./newModules/modals/ClearUnreadMessages.vue";
import ClearHistory from "./newModules/modals/ClearHistory.vue";
import ModalSessionEnded from "./newModules/modals/ModalSessionEnded.vue";
import ModalSomethingWrong from "./newModules/modals/ModalSomethingWrong.vue";
import ModalNoConnection from "./newModules/modals/ModalNoConnection.vue";
import ModalUpdate from "./newModules/modals/ModalUpdate.vue";
import ModalPromo from "./newModules/modals/ModalPromo.vue";
import ModalPaymentCoinsBuyStatus from "./newModules/modals/ModalPaymentCoinsBuyStatus.vue";
import ModalPaymentCrypto from "./newModules/modals/ModalPaymentCrypto.vue";
import ModalDonate from "./newModules/modals/ModalDonate.vue";
import ModalGifts from "./newModules/modals/ModalGifts.vue";
import ModalMaintenance from "./newModules/modals/ModalMaintenance.vue";
import ModalLostConnection from "./newModules/modals/ModalLostConnection.vue";
// import SideBarMobile from "./newModules/SideBarMobile.vue";

// let operators = [];
let notifyIDs = [];
let sleepScroll = false;
let isStartChangeFavorite = false;
let intervalCheckHolidayPromotion = null;
let orientationMobile = '';
let timeoutEmergencyPing = null;

export default {
    name: "Home",
    props: [ 'id', 'type', 'myUrl','windowHeight','buildTimestamp','s3Url','searchCountry', 's3UrlStatic'],
    data(){
        return{
            supportContact:null,
            mySettings:null,
            gallery:[],
            galleryOld:[],
            opponent: null,
            currentUser:null,
            emojis:[],
            moods:[],
            messages:{},
            contacts:[],
            chatOwner:null,
            // isFavorite:false,
            countries:[],
            selectedContact:null,
            isDownload:false,
            isAllHistory:false,
            isAllFavorite:false,
            isAllChatRequest:false,
            isAllMessages:false,
            isAllGallery:false,
            isGetMoreGallery:false,
            // indicatorNewMan:false,
            // indicatorNewWoman:false,
            isInitData:false,
            modalTariff: false,
            modalSuccessPayment: false,
            modalFailedPayment: false,
            modalCoinsBuyStatus: false,
            cryptoCoinsbuyPayment: null,
            // modalPaymentCrypto: false,
            submenuVisible: false,
            banList:[],
            myBanned:[],
            favorites:[],
            packMedias:[],
            isPaidPackage:false,
            portfolioImages:[],
            scrollGalleryPosition:0,
            showChat:false,
            showPrice: false,
            showSearch: false,
            messagesInit:[],
            supportMessages:{},
            isOpponentInChat:false,
            tariffs:null,
            indicatorNewOpponent:10*24*60*60,
            sound:'newMessage',
            supportTypingInterval:null,
            supportTyping:false,
            soundList:{
                newMessage:'new_message',
                newMessageInChat:'new_message_in_chat',
                supportChat:'support_message'
            },
            searchResultIds: [],
            searchData:null,
            isSearch:false,
            isOpenSupport:false,
            isSound:false,
            lastSupportMessagesID:null,
            isAllSupportMessages:false,
            supportMessagesInit:[],
            showRequest: false,
            singleRequestWindow: true,
            showMobFavorite: false,
            showMobHistory: false,
            showSupport: false,
            isOpenStories:false,
            singleMenu: true,
            showNavbar: false,
            isInit:true,
            paymentTariff:null,
            bonusesCredits:0,
            // lastGalleryOnlineStatus:true,
            lastIDGalleryOnline:0,
            lastIDGalleryOffline:0,
            // bufferGallery:[],
            modalClearMessages: false,
            modalClearHistory: false,
            holidayPromotion:null,
            showNotificationButton:false,
            onSubscribeNotification:false,
            disabledNotification:true,
            modalNotify:false,
            modalValidEmail:false,
            modalSuccessSendEmail:false,
            disabledSendMessage:false,
            buildVersion:BUILD_VERSION,
            newBuildVersion:'',
            showModalUpdate:false,
            isNewVersion:false,
            remainingUnread:0,
            usedVideoCodecs:[],
            // cacheMessage:null,
            // lastMessage:null,
            //test value (was 24)
            //test value (was 24)
            galleryCropLimit: 10,
            galleryIndexFrom: 0,
            //test value (was 60)
            galleryIndexTo: 20,
            scrollSearchPosition:0,
            searchIndexFrom: 0,
            searchIndexTo: 0,
            countContacts:30,
            isScroll: false,
            isSupportedWebp: false,
            initSupportMessage: '',
            showModalSessionEnded: false,
            showModalSomethingWrong: false,
            showModalMaintenance: false,
            showModalNoConnection: false,
            showModalPromo: false,
            mediaChat: [],
            indexMedia: null,
            timeNow: null,
            giftProcessing:false,
          //presents_block
            gifts: [],
            showModalGifts: false,
            modalComplain: false,
            modalBlocked : false,

            //for hooper slider in gallery
            indexSlide:0,
            offsetSlide:0,
            limitSlide:20,
            updatingSlide:false,
            beforeSearchingIndexSlide:0,
            beforeSearchingOffsetSlide:0,
            beforeSearchingLimitSlide:20,
            cdnLetterImages: '',
            typingsMLMessage: {},
            activeWindowsCount: 1,
            unreadPromoMessage:0,

            showModalLostConnection: false,
            typeSelectOpponent: 'link',

            showSideBar: false,
        }
    },
    components: {
        ModalMaintenance,
        ModalDonate,
        ModalGifts,
        ModalPaymentCrypto,
        ModalPaymentCoinsBuyStatus,
        ModalPromo,
        ModalUpdate,
        ModalNoConnection,
        ModalSomethingWrong,
        ModalSessionEnded,
        ClearHistory,
        ClearUnreadMessages,
        ServicePrices,
        SupportChat,
        SideBar,
        FilterDesktop,
        AwaitingVerification,
        //AuthComponent,
        //Register,
        //Login,
        Gallery,
        Profile,
        Account,
        PushNotification,
        InstallDesktop,
        SuccessRegister,
        PriceTable,
        Search,
        ModalLostConnection
    },
    computed:{
        isFavoriteOpponent() {
            return (this.selectedContact && this.selectedContact[`favorite_${this.roleOpponent}`]);
        },
        opponentAge(){
            if(!this.opponent) return '_'
            if(this.opponent.birthday){
                // let diff = new Date()-new Date(birthday); // This is the difference in milliseconds.
                return  Math.floor((new Date()-new Date(this.opponent.birthday))/31557600000); // Divide by 1000*60*60*24*365.25
            }
            return this.opponent[`${this.roleOpponent}_profile`]? this.opponent[`${this.roleOpponent}_profile`].age : '_';
        },
        indexFrom(){
           return  this.isSearch ? this.searchIndexFrom : this.galleryIndexFrom;
        },
        indexTo(){
            return  this.isSearch ? this.searchIndexTo : this.galleryIndexTo;
        },
        scrollPosition(){
            return  this.isSearch ?  this.scrollSearchPosition: this.scrollGalleryPosition;
        },
        supportsLocalStorage() {
            try {
                return 'localStorage' in window  && window['localStorage'] !== null;
            } catch (e) {
                return false;
            }
        },
        holidayPromotionName(){
            return this.validHolidayPromotion ? this.validHolidayPromotion.name :
                (this.validHelpPromotion ? this.validHelpPromotion.name : null);
        },
        validHolidayPromotion(){
            // const date = new Date();
            if(this.holidayPromotion &&
                this.holidayPromotion.is_active &&
                new Date(this.holidayPromotion.start) < this.timeNow &&
                this.timeNow < new Date(this.holidayPromotion.stop)
            ){
                return this.holidayPromotion
            }
            return null
        },
        validHelpPromotion(){
            // const date = new Date();
            if(this.holidayPromotion &&
                !this.holidayPromotion.is_active &&
                this.holidayPromotion.is_help &&
                new Date(this.holidayPromotion.start) < this.timeNow &&
                this.timeNow < new Date(this.holidayPromotion.stop)
            ){
                return this.holidayPromotion
            }
            return null
        },
        // getDateStopHoliday(){
        //     if (this.validHolidayPromotion){
        //         let month = new Date(this.validHolidayPromotion.stop).getMonth() + 1;
        //         if(month < 10){
        //             month = `0${month}`;
        //         }
        //         let day = new Date(this.validHolidayPromotion.stop).getDate();
        //         if(day < 10){
        //             day = `0${day}`;
        //         }
        //
        //         return `${day}/${month}/${new Date(this.validHolidayPromotion.stop).getFullYear()}`;
        //     }
        //     return '';
        // },
        roleOpponent(){
            if(this.$route.name==='promoProfile' || this.$route.name === 'promoChat') return 'woman'
            if(!this.currentUser) return '';
            return this.currentUser.role === 'man'? 'woman' : 'man'
        },
        isValidChatOwner(){
            return (!!this.currentUser && this.currentUser.role === 'man' && this.chatOwner && !this.chatOwner.lock )||
                (!!this.currentUser && this.currentUser.role === 'woman' && this.chatOwner && !this.chatOwner.lock && this.chatOwner.valid )
        },
        countUnread(){
            // let count = 0;
            // this.contacts.forEach(contact=>{
            //     if (Number(contact[`unread_${this.currentUser.role}`])){
            //         count += Number(contact[`unread_${this.currentUser.role}`])
            //     }
            // })
            if(this.isPromoPage(false))return this.unreadPromoMessage
            let count = this.contacts.reduce((previousValue, contact) => {
                let unread = 0
                if(contact[this.roleOpponent] && !this.banList.includes(contact[this.roleOpponent].id)){
                    unread = Number(contact[`unread_${this.currentUser.role}`])
                }
                return previousValue + unread
                },
                Number(this.remainingUnread)
            );
            if (this.isNewVersion){
                count++
            }
            return count
        },
        isBlockedOpponent(){
            return ( this.opponent && this.banList && this.banList.includes(this.opponent.id) )
        },

    },
    watch: {
        $mq(newVal, oldVal) {
            console.log(newVal, oldVal);
            if (newVal !==  oldVal && this.chatOwner) {
                this.$router.go();
            }
        }
    },
    created() {
        console.log('created from Home $mq: '+this.$mq);
        axios.defaults.headers.common['My-Screen-Type'] = btoa(this.$mq);
        if(this.$route.name !== 'promoProfile' && this.$route.name !== 'promoChat') this.$bus.$emit('init_echo')
        this.checkWebPSupport()
        // if('serviceWorker' in navigator){
        //     navigator.serviceWorker.addEventListener('message', async event => {
        //         // Optional: ensure the message came from workbox-broadcast-update
        //         if (event.data.meta === 'workbox-broadcast-update') {
        //             console.log('workbox-broadcast-update');
        //         }
        //     });
        // }else {
        //     console.warn('serviceWorker not register')
        // }
    },
    mounted() {
        console.log('mounted home $mq: '+this.$mq);
        console.log('buildVersion',this.buildVersion);

        axios.defaults.headers.common['My-Screen-Type'] = btoa(this.$mq);
        this.initData('mounted');
        this.passiveScroll();
        setTimeout(()=>{
            this.isInit = false;
        },5000);
        // setInterval(()=>{
        //     this.addItemToGallery();
        // },5000);

        console.log('Home mounted.');
        //this.$bus.$on('select_contact',this.selectOpponent);
        this.$bus.$on('send_message',this.sendMessage);
        this.$bus.$on('add_contacts',this.addContacts);
        this.$bus.$on('select_opponent',this.selectOpponent);
        this.$bus.$on('reset_unread_messages',this.resetUnreadMessages);
        this.$bus.$on('send_chat_photo',this.sendChatPhoto);
        this.$bus.$on('more_messages',this.getMoreMessages);
        this.$bus.$on('send_letter',this.sendLetter);
        this.$bus.$on('edit_letter',this.editLetter);
        this.$bus.$on('upload_video',this.sendVideoForChat);
        this.$bus.$on('new_balance',this.newBalance);
        this.$bus.$on('upload_voice',this.sendVoice);
        this.$bus.$on('upload_gif',this.sendGif);
        this.$bus.$on('convert_media',this.convertMedia);
        this.$bus.$on('send_chat_emoji',this.sendChatEmoji);
        this.$bus.$on('message_paid',this.messagePaid);
        this.$bus.$on('is_opponent_in_chat',this.setOpponentInChat);
        this.$bus.$on('paid_pack_media',this.paidPackMedia);
        this.$bus.$on('update_avatar',this.updateAvatar);
        this.$bus.$on('change_setting',this.changeSetting);
        this.$bus.$on('get_support_messages',this.getSupportMessages);
        this.$bus.$on('close_support_chat',this.closeSupportChat);
        this.$bus.$on('send_support_message',this.sendSupportMessage);
        this.$bus.$on('add_more_support_message',this.addMoreSupportMessage);
        this.$bus.$on('is_typing_support',this.sendSupportEventTyping);
        this.$bus.$on('open_stories',this.openStories);
        this.$bus.$on('open_modal_price',this.openPrice);
        this.$bus.$on('open_modal_clear',this.openModalClear);
        this.$bus.$on('open_modal_clear_history',this.openModalClearHistory);
        this.$bus.$on('refresh_member_data',this.refreshMemberData);
        this.$bus.$on('close_request',this.closeRequest);
        this.$bus.$on('start_beams_notifications',this.startBeams);
        this.$bus.$on('stop_beams_notifications',this.stopBeams);
        this.$bus.$on('delete_chat_message',this.deleteChatMessage);
        this.$bus.$on('update_edit_message',this.updateEditMessage);
        this.$bus.$on('show_modal_notify',this.showModalNotify);
        this.$bus.$on('show_modal_valid_email',this.showModalValidEmail);
        this.$bus.$on('show_modal_send_validation_email',this.showModalSuccessSendEmail);
        this.$bus.$on('get_new_version',this.openModalUpdate);
        this.$bus.$on('need_reload_page',this.needReloadPage);
        this.$bus.$on('start_change_favorite',this.startChangeFavorite);
        this.$bus.$on('change_mode',this.changeMode);
        this.$bus.$on('logout_user',this.logoutUser);
        this.$bus.$on('update_member_profile',this.updateChatOwner);
        this.$bus.$on('set_support_message',this.setSupportMessage);
        this.$bus.$on('show_edit_search',this.openSearch);
        this.$bus.$on('clear_opponent_messages',this.clearOpponentMessages);
        this.$bus.$on('open_chat_media',this.openChatMedia);
        this.$bus.$on('send-gift', this.sendGift);
        this.$bus.$on('send-donate', this.sendDonate);
        this.$bus.$on('buy-link', this.buyLink);
        this.$bus.$on('show-gifts-modal', this.openModalGifts);
        this.$bus.$on('close-gifts-modal', this.closeModalGifts);
        this.$bus.$on('open_modal_complain', this.openModalComplain);
        this.$bus.$on('close_modal_complain', this.closeModalComplain);
        this.$bus.$on('open_modal_blocked', this.openModalBlocked);
        this.$bus.$on('close_modal_blocked', this.closeModalBlocked);
        this.$bus.$on('complain_opponent', this.complainOpponent);
        this.$bus.$on('block_opponent_profile', this.blockOpponentProfile);
        this.$bus.$on('error_handler', this.errorHandler);
        this.$bus.$on('show_snotify', this.showSnotify);
        this.$bus.$on('search_complete', this.searchComplete);
        this.$bus.$on('open_letter_media',this.openLetterMedia);
        this.$bus.$on('get_new_message_promo', this.getNewMessage);
        this.$bus.$on('clear_unread_promo', this.clearUnreadPromo);
        if(this.$mq !== 'desktop'){
            const mediaQueryList = window.matchMedia("(orientation: landscape)");
            if(!mediaQueryList || typeof mediaQueryList.addEventListener !== 'function' ) return;
            mediaQueryList.addEventListener('change', this.handleOrientationChange);
            this.handleOrientationChange(mediaQueryList);
        }

        if('serviceWorker' in navigator){
            navigator.serviceWorker.addEventListener('message', event => {

                if (event.data.type === 'CACHE_UPDATED') {
                    console.log('CACHE_UPDATED : ' + event.data.BUILDVERSION);
                    console.log('BUILDVERSION : ' + this.buildVersion);
                    console.log(event.data)
                    if( event.data && this.buildVersion && event.data.BUILDVERSION && event.data.BUILDTIMESTAMP &&
                        String(this.buildVersion).trim() !== String(event.data.BUILDVERSION).trim() &&
                        this.buildTimestamp < event.data.BUILDTIMESTAMP
                    ){
                        console.log('new BUILDVERSION')
                        this.activeWindowsCount = event.data.clientsCount
                        this.newBuildVersion = event.data.BUILDVERSION
                        this.needReloadPage()
                    }
                }
            });
            // navigator.serviceWorker.addEventListener('controllerchange',  ()  => {
            //     this.needReloadPage()
            // });
        }else {
            console.warn('serviceWorker not register')
        }
    },
    beforeDestroy() {
        //this.$bus.$off('select_contact',this.selectOpponent);
        this.$bus.$off('send_message',this.sendMessage);
        this.$bus.$off('add_contacts',this.addContacts);
        this.$bus.$off('select_opponent',this.selectOpponent);
        this.$bus.$off('reset_unread_messages',this.resetUnreadMessages);
        this.$bus.$off('send_chat_photo',this.sendChatPhoto);
        this.$bus.$off('more_messages',this.getMoreMessages);
        this.$bus.$off('send_letter',this.sendLetter);
        this.$bus.$off('edit_letter',this.editLetter);
        this.$bus.$off('upload_video',this.sendVideoForChat);
        this.$bus.$off('new_balance',this.newBalance);
        this.$bus.$off('upload_voice',this.sendVoice);
        this.$bus.$off('upload_gif',this.sendGif);
        this.$bus.$off('convert_media',this.convertMedia);
        this.$bus.$off('send_chat_emoji',this.sendChatEmoji);
        this.$bus.$off('message_paid',this.messagePaid);
        this.$bus.$off('is_opponent_in_chat',this.setOpponentInChat);
        this.$bus.$off('paid_pack_media',this.paidPackMedia);
        this.$bus.$off('update_avatar',this.updateAvatar);
        this.$bus.$off('change_setting',this.changeSetting);
        this.$bus.$off('get_support_messages',this.getSupportMessages);
        this.$bus.$off('close_support_chat',this.closeSupportChat);
        this.$bus.$off('send_support_message',this.sendSupportMessage);
        this.$bus.$off('add_more_support_message',this.addMoreSupportMessage);
        this.$bus.$off('is_typing_support',this.sendSupportEventTyping);
        this.$bus.$off('open_stories',this.openStories);
        this.$bus.$off('open_modal_price',this.openPrice);
        this.$bus.$off('open_modal_clear',this.openModalClear);
        this.$bus.$off('open_modal_clear_history',this.openModalClearHistory);
        this.$bus.$off('refresh_member_data',this.refreshMemberData);
        this.$bus.$off('close_request',this.closeRequest);
        this.$bus.$off('start_beams_notifications',this.startBeams);
        this.$bus.$off('stop_beams_notifications',this.stopBeams);
        this.$bus.$off('delete_chat_message',this.deleteChatMessage);
        this.$bus.$off('update_edit_message',this.updateEditMessage);
        this.$bus.$off('show_modal_notify',this.showModalNotify);
        this.$bus.$off('show_modal_valid_email',this.showModalValidEmail);
        this.$bus.$off('show_modal_send_validation_email',this.showModalSuccessSendEmail);
        this.$bus.$off('get_new_version',this.openModalUpdate);
        this.$bus.$off('need_reload_page',this.needReloadPage);
        this.$bus.$off('start_change_favorite',this.startChangeFavorite);
        this.$bus.$off('change_mode',this.changeMode);
        this.$bus.$off('logout_user',this.logoutUser);
        this.$bus.$off('update_member_profile',this.updateChatOwner);
        this.$bus.$off('set_support_message',this.setSupportMessage);
        this.$bus.$off('show_edit_search',this.openSearch);
        this.$bus.$off('clear_opponent_messages',this.clearOpponentMessages);
        this.$bus.$off('open_chat_media',this.openChatMedia);
        this.$bus.$off('send-gift', this.sendGift);
        this.$bus.$off('send-donate', this.sendDonate);
        this.$bus.$off('buy-link', this.buyLink);
        //presents_block
        this.$bus.$off('show-gifts-modal', this.openModalGifts);
        this.$bus.$off('close-gifts-modal', this.closeModalGifts);
        this.$bus.$off('open_modal_complain', this.openModalComplain);
        this.$bus.$off('close_modal_complain', this.closeModalComplain);
        this.$bus.$off('open_modal_blocked', this.openModalBlocked);
        this.$bus.$off('close_modal_blocked', this.closeModalBlocked);
        this.$bus.$off('complain_opponent', this.complainOpponent);
        this.$bus.$off('block_opponent_profile', this.blockOpponentProfile);
        this.$bus.$off('error_handler', this.errorHandler);
        this.$bus.$off('show_snotify', this.showSnotify);
        this.$bus.$off('search_complete', this.searchComplete);

        this.$bus.$off('get_new_message_promo', this.getNewMessage);
        this.$bus.$off('clear_unread_promo', this.clearUnreadPromo);

        this.$bus.$off('open_letter_media',this.openLetterMedia);
        // this.$bus.$off('end_payment',this.endPayment);
        // this.$bus.$off('delete_message',this.deleteMessage);
        // window.removeEventListener('wheel', slowScroll);
        this.leavingToChannel()
        if( Echo ) Echo.disconnect()
        if(this.$mq !== 'desktop'){
            const mediaQueryList = window.matchMedia("(orientation: landscape)");
            if(mediaQueryList  && typeof mediaQueryList.removeEventListener === 'function' )
                mediaQueryList.removeEventListener('change', this.handleOrientationChange);
        }
    },
    methods:{
        handleOrientationChange(event) {
            if(!event) return;
            console.log('Orientation changed to ' + (event.matches?'horizontal':'vertical'));
            console.log(event)
            let orientation = 'vertical'
            if (event.matches) orientation = 'horizontal'
            if(!orientationMobile) orientationMobile = orientation
            else if(orientationMobile !== orientation) this.$router.go();
        },
        onOpenLightBox(){
            this.$bus.$emit('add_no_scroll')
        },
        closeLightBox(){
            this.indexMedia = null;
            if(this.$mq !== 'desktop'){
                this.$router.back()
            }
            this.$bus.$emit('remove_no_scroll');
        },
        openChatMedia(media){
            console.log(media);
            this.mediaChat[0] = media.type === 'clip' ? {src: `${this.s3Url}/${media.src}`, autoplay: true}  : {src: `${this.s3Url}/${media.src}`}
            this.indexMedia = 0;
            if(this.$mq !== 'desktop' && this.opponent){
                this.$router.push({name: 'ShowChatMedia',params:{id: this.opponent.id}})
            }
        },
        openLetterMedia(src){
            console.log(src);
            this.mediaChat[0] = {src: `${this.cdnLetterImages}/${src}`}
            this.indexMedia = 0;
            if(this.$mq !== 'desktop' && this.opponent){
                this.$router.push({name: 'ShowChatMedia',params:{ id: this.opponent.id }})
            }
        },
        clearOpponentMessages(){
           this.opponent = null;
           this.messages = {};
           this.messagesInit = [];
        },
        closeModalNoConnection(){
            this.showModalNoConnection = false
        },
        closeModalSomethingWrong(){
            this.showModalSomethingWrong = false
    //     TODO Открыть support chat
    //         this.$bus.$emit('open_support_chat', '')
        },
        closeModalMaintenance(){
            this.showModalMaintenance = false;
            if(this.$route.name === 'PaymentPage') this.$router.push({name: 'gallery'})
        },
        closeModalSessionEnded(){
            this.showModalSessionEnded = false
            window.location.href = this.myUrl + '/';
        },
        setSupportMessage(message){
            this.initSupportMessage = message;
        },
        updateCurrentUser(user){
            this.currentUser.email = user.email
            this.currentUser.email_verified = user.email_verified
        },
        updateChatOwner(member){
            this.chatOwner.name = member.name
            this.chatOwner.birthday = member.birthday
        },
        startChangeFavorite(status,memberID){
            if(isStartChangeFavorite) return
            isStartChangeFavorite = true;

            axios.post(`/change-favorite-${this.roleOpponent}`,{
                status: status,
                opponentID:memberID,
                chatOwnerID:this.chatOwner.id,
                contactID:null
            })
                .then(response => {
                    this.setChangeFavoriteWoman();
                    console.log(response.data);
                    // this.$emit('change_favorite',response.data,status);
                    this.changeFavorite(response.data,status,memberID)
                })
                .catch(e => {
                    this.setChangeFavoriteWoman();
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        setChangeFavoriteWoman(){
            setTimeout(()=>{
                isStartChangeFavorite = false;
            },200)
        },
        checkWebPSupport() {
                const img = new Image();
                img.onload = () => {
                    console.log('onload checkWebPSupport')
                    this.isSupportedWebp = (img.width > 0) && (img.height > 0);
                };
                img.onerror = function() {
                    console.log('onerror checkWebPSupport')
                    this.isSupportedWebp = false;
                };
                img.src = `${this.myUrl}/assets/images/avatartest.webp`; // WebP изображение

                // If the browser instantaneously calls onload or onerror, then WebP is not supported.
                if (img.complete) {
                    console.log('img.complete checkWebPSupport')
                    img.onload = null;
                    img.onerror = null;
                    this.isSupportedWebp = false;//img.width > 0 && img.height > 0;
                }
        },
        passiveScroll(){
            let changeIsScroll = null
            // const scrollSpeed = 0.7;
            const slowScroll = (event) => {
                if(this.$route.name === 'gallery'){
                    this.isScroll = true;
                    if (changeIsScroll) clearTimeout(changeIsScroll);
                    changeIsScroll = setTimeout(()=>{
                        this.isScroll = false;
                    },50)
                }
                // console.log(event)
                if(this.isGetMoreGallery || sleepScroll){
                    event.preventDefault();
                    sleepScroll = true
                    console.log('event.preventDefault()')
                    // up or down
                    // const delta = event.deltaY;
                    // // set speed
                    // galleryEl.scrollBy(0, delta * scrollSpeed);
                }
            }
            window.addEventListener('wheel', slowScroll, { passive: false });
        },
        setRemainingUnread(totalUnread){
            console.log('setRemainingUnread',totalUnread)
            let count = this.contacts.reduce((previousValue, contact) =>
                previousValue + Number(contact[`unread_${this.currentUser.role}`]), 0
            );
            console.log('count',count)
            this.remainingUnread = Number(totalUnread) - count;
            if(this.remainingUnread === 0){
                this.isAllChatRequest = true
            }else if(this.remainingUnread < 0 ){
                console.error('remainingUnread < 0')
                this.remainingUnread = 0
            }
        },
        closeModalUpdate(){
            this.showModalUpdate = false;
        },
        openModalUpdate(){
            this.showModalUpdate = true;
        },
        getNewVersion(){
            // window.location.href = '/gallery';
            window.location.reload();
        },
        needReloadPage(){
            this.showModalUpdate = true;
            this.isNewVersion = true;
        },
        checkBuildVersion(){
            axios.get('/BUILD_VERSION.txt')
                .then(response => {
                    console.log('/BUILD_VERSION.txt');
                    console.log(response.data);
                    if (response.data != this.buildVersion){
                        setTimeout(()=>{
                            this.needReloadPage()
                            // if (confirm('A new version of the application has been released. Do you want to download the new version?')){
                            //     window.location.href = '/gallery';
                            // }
                        },5000);
                    }
                })
        },
        showModalNotify() {
            this.modalNotify = true
        },
        closeModalNotify() {
            this.modalNotify = false;
            this.$bus.$emit('cancel_unsubscribe_notify');
        },
        showModalValidEmail() {
            this.modalValidEmail = true
        },
        closeModalValidEmail() {
            this.modalValidEmail = false;
        },
        closeModalSuccessSendEmail(){
            this.modalSuccessSendEmail = false;
        },
        showModalSuccessSendEmail(isSubscribe){
            this.modalSuccessSendEmail = true;
            if(this.mySettings){
                this.mySettings.is_notifications = isSubscribe;
            }

        },
        sendValidEmail() {
            console.log('sendValidEmail');
            this.modalValidEmail = false;
            axios.get(`/send-valid-email/0`)
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === 'success'){
                        this.modalSuccessSendEmail = true;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        updateEditMessage(text,message_id){
            const opponentID = this.opponent? this.opponent.id: null;
            axios.post(`/update-chat-message`,{
                text:text,
                message_id: message_id,
                user_id: this.currentUser.id,
                operator:this.opponent.operator_id?this.opponent.operator_id:null
            })
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === 'success'){
                        this.resetTextInInput(opponentID);
                        this.updateMessage(text,message_id);
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        updateMessage(text, message_id){
            console.log(text, message_id);
            const findMessage = this.messagesInit.find((message)=>{
                return +message.id === +message_id
            });
            if (findMessage){
                findMessage.message = text;
                this.decomposeMessagesByDate(this.messagesInit);
            }

        },
        deleteChatMessage(message_id,isRead){
            console.log('deleteChatMessage');
            axios.post(`/delete-chat-message`,{
                message_id: message_id,
                user_id: this.currentUser.id,
                womanID:this.currentUser.role==='man'?this.opponent.id:this.chatOwner.id,
                manID:this.currentUser.role==='man'?this.chatOwner.id:this.opponent.id,
                operator:this.opponent.operator_id?this.opponent.operator_id:null,
                isRead:isRead
            })
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === 'success'){
                        this.deleteMessage(message_id);
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        blockedMessage(message_id){
            const findMessages = this.messagesInit.find((message)=>{
                return +message.id === +message_id
            });
            if(findMessages){
                findMessages.hidden = true
                this.decomposeMessagesByDate(this.messagesInit);
            }
        },
        updateChatMessageHandler(event){
            const findMessages = this.messagesInit.find((message)=>{
                return +message.id === +event.message.id
            });
            if(findMessages){
                findMessages.message = event.message.message
                // this.decomposeMessagesByDate(this.messagesInit);
            }
        },
        deleteMessage(message_id){
            this.messagesInit = this.messagesInit.filter((message)=> (+message.id !== +message_id));
            for (let key in this.messages) {
                this.messages[key] = this.messages[key].filter((message)=> (+message.id !== +message_id));
            }
            //this.decomposeMessagesByDate(this.messagesInit);
        },
        startCheckHolidayPromotion(){
            intervalCheckHolidayPromotion = setInterval(() => {
                this.timeNow = Date.now();
            }, 1000);
        },
        checkHolidayPromotion(){
            if(this.validHolidayPromotion){
                // document.body.classList.add("is-holiday");
                // document.body.classList.add(this.holidayPromotion.class);
                if(this.chatOwner && !this.chatOwner.notified_promo){
                    // this.modalBonus = true;
                    this.showModalPromo = true;
                }
            }
            // if(this.validHelpPromotion){
            //     // document.body.classList.add("is-holiday");
            //     // document.body.classList.add(this.holidayPromotion.class);
            //     if(this.chatOwner && !this.chatOwner.notified_promo){
            //         this.modalHelp = true;
            //         // this.showModalPromo = true;
            //     }
            // }
        },
        closeModalBonus(){
            // this.modalBonus = false;
            // this.modalHelp = false;
            this.showModalPromo = false;
            axios.get(`/member-notified-promo-action/${this.currentUser.role}/${this.chatOwner.id}`)
                .then(response => {
                    console.log(response.data);
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        closeModalCoinsBuyStatus(){
            this.modalCoinsBuyStatus = false;
            this.cryptoCoinsbuyPayment = null;
        },
        // closeModalPaymentCrypto() {
        //     this.modalPaymentCrypto = false
        // },
        refreshMemberData(){
            console.log('refreshMemberData');
            this.initData('mounted');
        },
        clearUnreadMessages(){
            axios.post(`/clear-unread-messages`,{
                ownerID:this.chatOwner.id,
                role:this.currentUser.role
            })
                .then(response => {
                    console.log(response.data);
                    this.contacts = this.contacts.map(contact=>{
                        contact[`unread_${this.currentUser.role}`] = 0
                        return contact
                    })
                    this.remainingUnread = 0
                    // this.countUnread = 0;
                    this.closeModalClear();
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })

        },
        clearMyHistory(){
            console.log('clearMyHistory')
            axios.post(`/clear-my-history`,{
                ownerID:this.chatOwner.id,
                role:this.currentUser.role
            })
                .then(response => {
                    console.log(response.data);
                    this.contacts = this.contacts.map(contact=>{
                        contact[`hidden_${this.currentUser.role}`] = true
                        return contact
                    })
                    this.closeModalClearHistory();
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        // addItemToGallery(){
        //     if(this.bufferGallery.length){
        //         const double = this.bufferGallery.findIndex(item=>{
        //             return +item.id === +this.bufferGallery[0].id
        //         })
        //         if (!double){
        //             this.gallery.unshift(this.bufferGallery[0]);
        //             this.$bus.$emit('more_gallery_completed')
        //         }
        //         this.bufferGallery.splice(0,1);
        //     }
        // },
        openStories(){
            this.isOpenStories = false;
        },
        goToRequest(arg = true) {
            // if (this.showMobFavorite) this.showMobFavorite = false
            // if (this.showMobHistory) this.showMobHistory = false
            if (this.showChat) this.setShowChat(false)
            if (this.$refs.header) this.$refs.header.classList.add('is-front')
            this.showRequest = true
            this.singleRequestWindow = arg
            if (!document.documentElement.classList.contains('no-scroll')) {
                document.documentElement.classList.add('no-scroll')
            }
        },
        closeRequest() {
            if (this.showMobFavorite) this.showMobFavorite = false
            if (this.showMobHistory) this.showMobHistory = false
            this.showRequest = false
            if (this.$refs.header) this.$refs.header.classList.remove('is-front')
            if (document.documentElement.classList.contains('no-scroll') && this.singleRequestWindow) {
                document.documentElement.classList.remove('no-scroll')
            }
        },
        goToFavorite() {
            this.showMobFavorite = true
        },
        closeFavorite() {
            this.showMobFavorite = false
          },
        goToHistory() {
            this.showMobHistory = true
        },
        closeHistory() {
            this.showMobHistory = false
        },
        goBack(){
            if(this.$route.name !== 'gallery'){
                this.$router.push({path: '/gallery'});
            }
        },
        openMenu(single = true) {
            this.showNavbar = true
            this.singleMenu = single
            if (!document.documentElement.classList.contains('no-scroll')) document.documentElement.classList.add('no-scroll')
        },
        closeMenu() {
            this.showNavbar = false
            if (this.singleMenu) document.documentElement.classList.remove('no-scroll')
        },
        openSearch() {
            this.showSearch = true;
            this.$bus.$emit('open_modal');
            // if (this.$mq !== 'desktop') {
            //     document.documentElement.classList.add('no-scroll')
            // }
        },
        closeSearch(){
            this.showSearch = false;
            this.$bus.$emit('close_modal');
            // if (this.$mq !== 'desktop') {
            //     document.documentElement.classList.remove('no-scroll')
            // }
        },
        openPrice() {
            console.log('openPrice')
            this.showPrice = true
            this.$bus.$emit('open_modal');

            if (this.showNavbar)  {
                this.closeMenu()
            }
        },
        closePrice() {
            this.showPrice = false
            this.$bus.$emit('close_modal');
            // if (this.$mq !== 'desktop') {
            //     document.documentElement.classList.remove('no-scroll')
            // }
        },
        sendSupportEventTyping(){
            console.log('sendSupportEventTyping')
            if(this.supportChannel){
                this.supportChannel.whisper('typing', {
                    user_id: this.currentUser.id,
                    user_name: this.currentUser.name,
                    typing: true
                });
            }else {
                console.error('supportChannel = null')
            }

        },
        sendSupportMessage(data){
            console.log('sendSupportMessage');
            console.log(data);

            // this.supportMessages.push(data.message);
            this.addSupportMessage(data.message);
            this.supportContact.unread = 0;
        },
        newSupportMessageHandler(event){
            if (event.support_message && event.support_message.text ){
                if(this.isOpenSupport){
                    // this.supportMessages.push(event.support_message);
                    this.addSupportMessage(event.support_message);
                    setTimeout(()=>{
                        this.$bus.$emit('support_chat_scroll_down');
                    },500);
                }else {
                    this.showSnotify('support','Support Message',event.support_message.text);
                    this.playSound('supportChat');

                }
            }
            this.supportContact = event.support_contact ;
        },
        addMoreSupportMessage(messages){
            if(messages.length){
                let mess = messages.filter(message=>{
                    return Boolean(message.text);
                }) ;
                mess.sort((a,b)=>{
                    return new Date(a.created_at)-new Date(b.created_at)
                });
                this.supportMessagesInit = mess.concat(this.supportMessagesInit);
                this.decomposeSupportMessagesByDate(this.supportMessagesInit);
                this.isAllSupportMessages = messages.length < 50 ;
                this.lastSupportMessagesID = mess[0].id;
            }
        },
        closeSupportChat(){
            console.log('closeSupportChat');
            this.isOpenSupport = false;
            this.supportMessages = {}
            this.supportMessagesInit = [];
            this.showSupport = false;
            if (this.supportContact){
                Echo.leave(`support_chat.${this.supportContact.id}`)
                this.supportChannel = null;
            }
            //TODO check
            // if (this.$mq !== 'desktop') {
            //     if (this.$refs.header.classList.contains('is-front')) this.$refs.header.classList.remove('is-front')
            //     document.documentElement.classList.remove('no-scroll')
            // }
        },
        getSupportMessages(){
            console.log('getSupportMessages');
            // TODO check

            this.isOpenSupport = true;
            this.showSupport = true;
            if (this.$mq !=='desktop' && this.$route.name !== 'SupportChat'){
                this.$router.push({name: 'SupportChat'});
            }
            if (this.supportContact){
                this.connectSupportChannel();
                this.getSupportMessagesAxios()
            }
        },
        getSupportMessagesAxios(){
            console.log('getSupportMessagesAxios')
            axios.get(`/get-support-messages/${this.supportContact.id}`)
                .then(response => {
                    console.log(response.data);
                    // this.supportMessages = response.data.messages ;
                    if(response.data.messages.length){
                        this.supportMessagesInit = response.data.messages.filter(message=>{
                            return Boolean(message.text);
                        })
                        this.supportMessagesInit.sort((a,b)=>{
                            return new Date(a.created_at)-new Date(b.created_at)
                        });
                        this.isAllSupportMessages = response.data.messages.length < 50 ;
                        this.lastSupportMessagesID = this.supportMessagesInit[0].id;
                        // const messages = response.data.messages;
                        // this.lastSupportMessagesID = messages[messages.length-1].id;
                        this.decomposeSupportMessagesByDate(this.supportMessagesInit);
                    }

                    this.supportContact.unread = 0 ;
                    setTimeout(()=>{
                        this.$bus.$emit('support_chat_scroll_down');
                    },100);

                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        connectSupportChannel(){
            this.supportChannel = Echo.join(`support_chat.${this.supportContact.id}`)
                .listenForWhisper('typing', (e)=>{
                    console.log("typing")
                    console.log(e)
                    if (+e.user_id !== +this.currentUser.id){
                        this.supportTyping = true ;
                        clearTimeout(this.supportTypingInterval) ;
                        this.supportTypingInterval = setTimeout(()=>{
                            this.supportTyping = false ;
                        }, 2500);
                    }
                })
        },
        changeSetting(setting,isSound){

            if(setting) this.mySettings = setting;
            this.isSound = isSound;
            if (isSound){
                console.log('changeSetting');
                const audioPlayer = this.$refs.playSound;
                audioPlayer.play().catch(error => {
                                console.log(error)
                            });
                audioPlayer.pause();
            }
        },
        updateAvatar(member){
            this.chatOwner.avatar = member.avatar;
            this.chatOwner.avatar_webp = member.avatar_webp;
            this.chatOwner.img_gallery = member.img_gallery;
            this.chatOwner.img_gallery_webp = member.img_gallery_webp;
        },
        resetSearch(){
            this.searchData = null;
            this.searchResultIds = [];
            this.isSearch = false;
            this.gallery = this.galleryOld;
            this.isAllGallery = false;
            this.searchIndexFrom = 0;
            this.searchIndexTo = this.gallery.length < 20 ? this.gallery.length: 20;
            this.scrollSearchPosition = 0;

            // window.scrollTo( 0, 0);
            this.$nextTick(()=>{
                // this.$bus.$emit('more_gallery_completed')
                this.restoreStateSlider()
                this.$bus.$emit('set_indexes_gallery',{
                    position: this.scrollGalleryPosition,
                    from: this.galleryIndexFrom,
                    to: this.galleryIndexTo
                });
            });
       },
        searchComplete(result, ids, searchData, isFilter=false, views=[]){
            console.log('searchComplete');
            this.searchData = searchData;
            this.$bus.$emit('get_indexes_gallery');
            this.isSearch = true;
            //test value (was 60)
            //this.searchIndexTo = this.gallery.length < 60 ? this.gallery.length: 60;
            this.savePrevioseStateSlider()
            this.searchResultIds = ids;
            if(!isFilter){
                this.galleryOld = this.gallery;
            }
            this.gallery = result;
            this.searchIndexTo = this.gallery.length < 20 ? this.gallery.length: 20;
            //for mobile
            this.indexSlide = 0
            this.offsetSlide = 0
            this.limitSlide = this.searchIndexTo
            this.$bus.$emit('set_indexes_gallery',{
                position: 0,
                from: 0,
                to: this.searchIndexTo
            });
            this.setViewsToGallery(views)
            this.searchIndexFrom = 0;
            this.scrollSearchPosition = 0;
            this.isAllGallery = this.gallery.length < 180 ;
            this.searchMemberIdHandel();
            window.scrollTo( 0, 0);
            if (this.$route.name !== 'gallery'){
                console.log('push gallery')
                this.$router.push({name: 'gallery'});
            }
            this.$bus.$emit('more_gallery_completed')
            // setTimeout(()=>{
            //     this.$bus.$emit('more_gallery_completed')
            // },0);
        },
        searchMemberIdHandel(){
            const arr = [];
            const galleryIDs = this.gallery.map(item=>{
                return Number(item.id)
            });
            this.searchResultIds.forEach(id=>{
                if(!galleryIDs.includes(Number(id))){
                    arr.push(id);
                }
            });
            this.searchResultIds = arr ;
        },
        paidPackMedia(){
            this.isPaidPackage = true;
        },
        toggleSumMenu() {
            this.submenuVisible = !this.submenuVisible
        },
        start(el) {
            el.style.height = el.scrollHeight + "px";
        },
        end(el) {
            el.style.height = "";
        },
        openModalTariff() {
            this.closeMenu()
            this.modalTariff = true
        },
        closeModalTariff(){
            this.modalTariff = false
        },
        openSuccessPayment() {
            this.modalSuccessPayment = true
            if (this.$mq !== 'desktop') {
                document.documentElement.classList.add('no-scroll')
            }
        },
        openFailedPayment() {
            this.modalFailedPayment = true
            if (this.$mq !== 'desktop') {
                document.documentElement.classList.add('no-scroll')
            }
        },
        openModalClear() {
            this.modalClearMessages = true;
            this.$bus.$emit('open_modal');
            // if (this.$mq !== 'desktop') {
            //     document.documentElement.classList.add('no-scroll')
            // }
        },
        openModalClearHistory() {
            this.modalClearHistory = true;
            this.$bus.$emit('open_modal');
        },
        closeModalSuccessPayment() {
            this.modalSuccessPayment = false;
            this.paymentTariff = null ;
            if (this.$mq !== 'desktop') {
                document.documentElement.classList.remove('no-scroll')
            }
        },
        closeModalFailedPayment() {
            this.modalFailedPayment = false
            if (this.$mq !== 'desktop') {
                document.documentElement.classList.remove('no-scroll')
            }
        },
        closeModalClear() {
            this.modalClearMessages = false;
            this.$bus.$emit('close_modal');
            // if (this.$mq !== 'desktop') {
            //     document.documentElement.classList.remove('no-scroll')
            // }
        },
        closeModalClearHistory() {
            this.modalClearHistory = false;
            this.$bus.$emit('close_modal');
        },
        shortName(name){
            let arr = name.split(' ');
            let rez = '';
            arr.forEach(item=>{
                rez += item.charAt(0).toUpperCase();
            })
            return rez;
        },
        showSnotify(type,title,text,member=null){
            console.log('showSnotify - ' + text)
            // this.$snotify[type](title,text , {
            //     timeout: 4000,
            //     showProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true
            // });
            if(member && notifyIDs.find(id => +member.id === +id)) return
            this.$snotify.clear();
            let html = {
                support:`<div class="d-flex is-warning">
                        <div class="snotifyToast__body">
                            <div class="snotifyToast__content"><span class="text">${text}</span></div>
<!--                            <div class="snotifyToast__title">Support chat</div>-->
                        </div>
                        <div class="snotifyToast__icon avatar"></div>
                    </div>`,
                no_connect:`<div class="d-flex is-error">
                        <div class="snotifyToast__body">
                            <div class="snotifyToast__content"><span class="name">${this.chatOwner.name}</span><span class="text">Check the connection</span></div>
<!--                            <div class="snotifyToast__title">No connection</div>-->
                        </div>
                        <div class="snotifyToast__icon avatar"></div>
                    </div>`,
                internal:`<div class="d-flex is-error">
                            <div class="snotifyToast__body">
                                <div class="snotifyToast__content">
                                    <span class="name">${title}</span>
                                    <span class="text">${text}</span>
                                </div>
                            </div>
                            <span class="logo__image"></span>
                    </div>`
            }
            // <img src="${this.s3Url}/${this.chatOwner.avatar}" alt="">
            if (member){
                notifyIDs.push(+member.id)
                const isAvatar = `<div class="snotifyToast__icon"><span class="status status_online"><i class="status__icon"></i>
                    </span><img class="avatar avatar_md" src="${this.s3Url}/${member.avatar}" alt=""></div>`;
                const noAvatar = `<div class="snotifyToast__icon"><span class="status status_online"><i class="status__icon"></i></span><span class="status status_online">
                        <i class="status__icon"></i>
                    </span><div class="avatar avatar_md avatar_holder"><img class="avatar__image" src="${this.myUrl}/source/images/icons/holder-user.svg" alt="holder"></div></div>`;
                html = {
                    message:`<div class="d-flex is-success">
                            <div class="snotifyToast__body">
                                <div class="snotifyToast__content"><span class="name">${member.name}</span><span class="text">${text}</span></div>
<!--                                <div class="snotifyToast__title">New message</div>-->
                            </div>
                            ${member.avatar ? isAvatar : noAvatar}
                         </div>`,
                    comment:`<div class="d-flex is-info">
                                <div class="snotifyToast__body">
                                    <div class="snotifyToast__content"><span class="name">${member.name}</span><span class="text">New comment</span></div>
<!--                                    <div class="snotifyToast__title">My Stories</div>-->
                                </div>
                                ${member.avatar ? isAvatar : noAvatar}
                            </div>`
                }
            }

            // <div className="snotifyToast__icon avatar">
            //     <img src="https://lifeforlove.test/storage/old/Images/3127/mini/2373.jpg" alt="">
            // </div>

            //<div class="snotifyToast__icon avatar"><div class="avatar no-image"><span class="image" style="background-color: rgb(101, 181, 66);"><span class="name">B</span></span></div></div>
            this.$snotify.html(html[type], {
                timeout: 5000,
                showProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                buttons: [
                    {
                        text: '',
                        action: ()=>{
                            if(this.isPromoPage(false))return
                            console.log('Clicked: snotify');
                            this.showChat = true;
                            this.$snotify.clear();
                            switch (type){
                                case 'message':
                                    if ((this.$route.name !== 'profile' && this.$route.name !== 'chat') || +member.id !== +this.$router.currentRoute.params.id){
                                        const name = this.$mq === 'desktop'? 'profile':'chat'
                                        this.typeSelectOpponent = 'notification'
                                        this.$router.push({
                                            name: name,
                                            params: {id: member.id }
                                        });
                                        //this.$router.push({path:`/mobile-chat/${this.opponent.id}`})
                                    }else if(this.$route.name === 'profile' && +member.id === +this.$router.currentRoute.params.id){
                                        this.$bus.$emit('set-init-chat', true)
                                        this.resetUnreadMessages()
                                        if (this.$mq === 'mobile'){
                                                this.$router.push({
                                                    name: 'chat',
                                                    params: {id: member.id }
                                                });
                                        }
                                    }
                                    break;
                                case 'comment':
                                    this.isOpenStories = true;
                                    this.$router.push({
                                        name: 'account'
                                    });
                                    break;
                                case 'support':
                                    this.getSupportMessages();
                                    break;
                                case 'no_connect':
                                    break;
                                case 'internal':
                                    break;
                            }
                        },
                        bold: true
                    },
                    {
                        text: '',
                        action: ()=>{
                            console.log('Clicked: Close notify');
                            this.$snotify.clear();
                        },
                        bold: true
                    },
                ]
            });
        },
        playSound(sound){
            console.log('playSound - ' + sound);

            if(this.isSound) { // setting sound
                this.sound = sound;
                let soundPlayer = this.$refs.playSound
                soundPlayer.load();
                soundPlayer.play()
                    .catch(error => {
                      console.log(error);
                    });
            }
            console.log(this.sound);
        },
        newBalance(credits){
            const oldCredits = Number(this.chatOwner.credits);
            this.chatOwner.credits = Number(credits);
            if(oldCredits > this.chatOwner.credits && this.chatOwner.lead !== 'rc'){
                console.log('send event spend_credits')
                try {
                    dataLayer.push({ ecommerce: null });  //
                    dataLayer.push({
                        event: "spend_credits",
                        user_data: {
                            user_id: `${this.currentUser.id}`,
                            user_name: this.currentUser.name,
                            created_at: this.currentUser.created_at,
                            credits_spent: `${Number((oldCredits - this.chatOwner.credits) / 100).toFixed(2)}`
                        }
                    });
                }catch (e) {
                    console.error(e)
                }
            }
        },
        setOpponentInChat(isOpponentInChat){
            console.log('setOpponentInChat',isOpponentInChat);
            this.isOpponentInChat = isOpponentInChat;
            if (this.isOpponentInChat && this.selectedContact && Boolean(this.selectedContact[`unread_${this.roleOpponent}`]) &&
            this.messagesInit.length){
                this.messagesInit.forEach(message=>{
                    message.isReadOpponent = true;
                })
            }
        },
        resetOpponent(){
            this.opponent = null;
        },
        messagePaid(id){
            this.actionItem(this.messagesInit,id,'paid',true);
        },
        convertMedia(media,typeMedia,ext,seesOpponent=false){
            const sendData = new FormData();
            sendData.append('media',media,'media');
            sendData.append('name',media.name);
            sendData.append('type_media',typeMedia);
            sendData.append('ext',ext);

            this.sendMediaAttachment(sendData,'/send-chat-media-for-conversion',seesOpponent,true);
        },
        // cacheLastMessage(type,text,subject){
        //     if (this.opponent) {
        //         const cache = {
        //             opponent_id: this.opponent.id,
        //             text: text,
        //             type:type
        //         }
        //         if (type === 'letter') {
        //             cache.subject = subject;
        //         }
        //         this.cacheMessage = cache;
        //     }
        //
        // },
        checkSolvency(type){
            if( (this.chatOwner.credits >= this.tariffs[`tariff_${type}`] )){
                // this.lastMessage = null;
                // this.cacheMessage = null;
                return true;
            }else {
                const balance =this.tariffs[`tariff_${type}`];
                // alert(`You do not have enough credits to use this service.\n
                //  Your balance is ${this.chatOwner.credits} credits.\n
                //  Requires ${balance} credits.\n
                //  Check out our tariffs`);
                // this.openPrice();
                this.$router.push({name:'PaymentPage'})
                return false;
            }
        },
        sendMediaAttachment(sendData,url,seesOpponent,isConvert=false){
            if (this.disabledSendMessage || !this.opponent) return;
            this.disabledSendMessage = true;
            sendData.append('author', this.currentUser.role);
            sendData.append('seesOpponent', seesOpponent);
            sendData.append('manID', this.currentUser.role==='man'?this.chatOwner.id:this.opponent.id );
            sendData.append('womanID', this.currentUser.role==='man'?this.opponent.id:this.chatOwner.id );
            sendData.append('contact_id', this.selectedContact? this.selectedContact.id:null );
            sendData.append('operator', this.opponent.operator_id?this.opponent.operator_id:null );
            const config = {
                headers: { 'content-type': 'multipart/form-data' }
            };
            const opponentID = this.opponent? this.opponent.id: null;

            axios.post(url, sendData,config)
                .then(response => {
                    console.log('Media Attachment saved');
                    console.log(response.data);
                    this.disabledSendMessage = false;
                    if(!isConvert){
                        this.newMessageHandler(response.data);
                    }else {
                        if (response.data.contact) {
                            this.selectedContact = response.data.contact;
                            this.contactReplacement();
                            this.sortContacts()
                        }
                        console.log('convertation');
                    }
                    if (this.supportsLocalStorage && opponentID){
                        localStorage.removeItem(`letter.${opponentID}`);
                        localStorage.removeItem(`subject.${opponentID}`);
                    }
                })
                .catch(error => {
                    console.log(error);
                    this.disabledSendMessage = false;
                    alert('Media not uploaded. An error has occurred.');
                    this.errorHandler(error);
                });
        },
        sendChatEmoji(fileName,seesOpponent){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat') {
                this.$bus.$emit('open_register')
                return
            }
            if(this.checkSolvency('chat')){
                const sendData = new FormData();
                sendData.append('fileName',fileName);
                this.sendMediaAttachment(sendData,'/send-emoji-for-chat',seesOpponent);
            }

        },
        sendVoice(voice,seesOpponent,convert,ext){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat') {
                this.$bus.$emit('open_register')
                return
            }

            if(this.checkSolvency('voice')){
                const sendData = new FormData();
                if (convert === 'convert'){
                    sendData.append('path',voice);
                }else {
                    sendData.append('voice',voice);
                }

                sendData.append('convert',convert);
                sendData.append('name',voice.name,);
                sendData.append('ext',ext);

                this.sendMediaAttachment(sendData,'/send-record-voice-for-chat',seesOpponent);

            }
        },
        sendGif(gif,seesOpponent){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat') {
                this.$bus.$emit('open_register')
                return
            }

            if(this.checkSolvency('chat')){
                const sendData = new FormData();
                sendData.append('gif',gif);
                sendData.append('name',gif.name,);

                this.sendMediaAttachment(sendData,'/send-gif-for-chat',seesOpponent);
            }
        },
        gotoAccount(){
            this.closeMenu()
            if (this.$route.name !== 'account'){
                if (this.$mq !== 'desktop') {
                    if (this.showRequest) {
                        this.closeRequest()
                    }
                    if (this.showSearch) {
                        this.closeSearch()
                    }
                    if (this.showMobHistory) {
                        this.showMobHistory= false
                    }
                    if (this.showMobFavorite) {
                        this.showMobFavorite= false
                    }
                    if (this.isOpenSupport) {
                        this.closeSupportChat()
                    }
                    if (document.documentElement.classList.contains('no-scroll')) document.documentElement.classList.remove('no-scroll')
                    if (this.$refs.header.classList.contains('is-front')) this.$refs.header.classList.remove('is-front')
                }
                this.$router.push({ name: 'account' });
            }

        },
        sendVideoForChat(fileVideo,seesOpponent){
            // fileVideo.append('video',this.selectedVideo,'video');
            if(this.checkSolvency('clip')){
                this.sendMediaAttachment(fileVideo,'/send-video-for-chat',seesOpponent);
            }
        },
        sendLetter(data,seesOpponent){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat') {
                this.$bus.$emit('open_register')
                return
            }

            if(this.checkSolvency('letter')){
                let sendData = new FormData();
                sendData.append('text', data.text );
                sendData.append('imageLetter', JSON.stringify(data.imageLetter) );
                this.sendMediaAttachment(sendData,'/send-letters',seesOpponent);
            }

            // if(this.checkSolvency('letter')){
            //     let sendData = new FormData();
            //     sendData.append('file', data.gifFile);
            //     sendData.append('subject', data.subject );
            //     sendData.append('text', data.text );
            //     sendData.append('moodSrc', data.moodSrc );
            //     sendData.append('classBg', data.classBg );
            //     this.sendMediaAttachment(sendData,'/send-letters',seesOpponent);
            // }
            // else {
            //     this.cacheLastMessage('letter',data.text,data.subject);
            // }
        },
        editLetter(data){
            const sendData = new FormData();
            sendData.append('letterID', data.letterID);
            // sendData.append('file', data.gifFile);
            sendData.append('subject', data.subject );
            sendData.append('text', data.text );
            sendData.append('operator', this.opponent.operator_id?this.opponent.operator_id:null);
            // sendData.append('moodSrc', data.moodSrc );
            sendData.append('classBg', data.classBg );
            axios.post(`/edit-letter`,sendData)
                .then(response => {
                    console.log(response.data);
                    if (response.data.message_id){
                        this.updateMessage(data.subject, response.data.message_id);
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        getMoreMessages(chatContainer){
            if(!this.isAllMessages && this.selectedContact && this.messagesInit.length){
                const oldHeight = chatContainer.scrollHeight ;
                axios.get(`/get_more_messages/${this.selectedContact.id}/${this.messagesInit[0].id}`)
                    .then(response => {
                        console.log(response.data);
                        let mess = response.data.messages ;
                        this.isAllMessages = mess.length < 50 ;
                        mess.sort((a,b)=>{
                            return new Date(a.created_at)-new Date(b.created_at)
                        });
                        this.messagesInit = mess.concat(this.messagesInit);
                        this.setViewedMessages();
                        this.decomposeMessagesByDate(this.messagesInit);
                        this.$nextTick(() => {
                            // const newHeight = chatContainer.scrollHeight ;
                            setTimeout(()=>{
                                chatContainer.scrollTop = chatContainer.scrollHeight - oldHeight;
                            },0);
                        })
                    })
                    .catch(e => {
                        console.log(e);
                        this.errorHandler(e);
                    })
            }
        },
        // no used
        moreMessagesHandler(messages){
            messages.forEach(message=>{
                this.addMessage(message)
            });
        },
        sendChatPhoto(output,seesOpponent){
            console.log('sendChatPhoto');
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat') {
                this.$bus.$emit('open_register')
                return
            }
            if(this.checkSolvency('picture')){
                let data = new FormData();
                data.append('file', output.file);
                data.append('file_mini', output.thumb);
                data.append('name', output.name);

                this.sendMediaAttachment(data,'/send-chat-photo',seesOpponent);
            }
        },
        // closeChat(){
        //     console.log('closeChat');
        //     this.showChat = false;
        // },
        setShowChat(isChat){
            console.log('setShowChat');
            this.showChat = isChat;
            if(isChat){
                this.resetUnreadMessages();
            }

            //TODO check
            // if(this.showChat){
            //     this.resetUnreadMessages();
            //     if (this.$mq === 'desktop') {
            //         let footer = document.querySelector('footer');
            //         if(footer){
            //             footer.classList.add('is-chat-open')
            //         }
            //
            //     } else {
            //         document.documentElement.classList.add('no-scroll')
            //     }
            // } else {
            //     if (this.$mq === 'desktop') {
            //         let footer = document.querySelector('footer')
            //         if(footer) {
            //             footer.classList.remove('is-chat-open')
            //         }
            //     } else {
            //         if (!this.showRequest) document.documentElement.classList.remove('no-scroll')
            //     }
            // }
        },
        setScrollGalleryPosition(data){
            console.log('setScrollGalleryPosition');
            console.log(data);
            if(this.isSearch){
                this.scrollSearchPosition = data.position;
                this.searchIndexFrom = data.indexFrom;
                this.searchIndexTo = data.indexTo;
            }else {
                this.scrollGalleryPosition = data.position;
                this.galleryIndexFrom = data.indexFrom;
                this.galleryIndexTo = data.indexTo;
            }

        },
        resetUnreadMessages(){
            console.log('resetUnreadMessages');
            if(this.selectedContact){
                // this.countUnread -= this.selectedContact[`unread_${this.currentUser.role}`]
                this.actionItem(this.contacts,this.selectedContact.id,`unread_${this.currentUser.role}`,0);
                // this.selectedContact[`unread_${this.currentUser.role}`] = 0;
            }
        },
        blockOpponent(id,action){
            if (action === 'block'){
                this.banList.push(id);
            }else {
                this.banList = this.banList.filter(item=>{
                    return +id !== +item
                })
            }
        },
        changeFavorite(data,status,memberID){
            console.log('changeFavorite')
            // this.selectContact(data.contact);
            let isContactReplace = false
            if(this.$route.name === 'profile' || this.$route.name === 'chat'){
                console.log('route.name === profile  || this.$route.name === chat');
                this.selectedContact = data.contact;
                this.contactReplacement();
                this.sortContacts();
                isContactReplace = true
            }

            if (status){
                this.favorites.push(memberID)
            }else {
                this.favorites = this.favorites.filter(id=>{
                    return +id !== +memberID ;
                })
            }
            if(!isContactReplace && data.contact){
                const findContact = this.contacts.find(contact => (+contact.id === +data.contact.id))
                if(findContact) {
                    findContact[`favorite_${this.roleOpponent}`] = Boolean(status);
                    findContact.last_time = data.contact.last_time;
                    this.sortContacts();
                }
                else this.contacts.unshift(data.contact);
            }
            if(status) this.$bus.$emit('favorite_scroll_up');
        },
        contactReplacement(){
            if(this.selectedContact){
                const index = this.contacts.findIndex(item=>{
                    return +this.selectedContact.id === +item.id
                })
                if (index > -1){
                    this.selectedContact.lastMessage = this.contacts[index].lastMessage;
                    // this.contacts.splice(index,1);
                    this.contacts[index] = this.selectedContact;
                }else {
                    this.contacts.push(this.selectedContact);
                }
            }
        },
        contactsCompleted(type){
            console.log('contactsCompleted')
            if (type==='history'){
                this.isAllHistory = true ;
            }else if(type==='favorite'){
                this.isAllFavorite = true ;
            }else if(type==='chat_request'){
                this.isAllChatRequest = true ;
            }
        },
        addContacts(data){
            console.log('addContacts');
            console.log(data.type);

            if (data.contacts.length < this.countContacts){
                this.contactsCompleted(data.type) ;
            }
            // let isAll = true ;
            data.contacts.forEach(contact => {
                // console.log('add forEach')
                if(!this.contacts.find(item => +contact.id === +item.id)){
                    // isAll = false ;
                    // console.log('add item contact')
                    this.contacts.push(contact);
                }
            })
            // if (isAll){
            //     this.contactsCompleted(data.type) ;
            // }
            data.messages.forEach(message=>{
                let findContact = this.contacts.find(contact=>{
                    return +message.contact_id === +contact.id ;
                }) ;
                if(findContact){
                    findContact.lastMessage = message;
                }
            })
            this.setRemainingUnread(data.countUnread)
        },
        sendMessage(message,isOpponentInChat){
            console.log('sendMessage(message)');
            if(this.isPromoPage(false)) {
                this.$bus.$emit('open_register')
                return
            }
            if(this.opponent && this.checkSolvency('chat')){
                if (this.disabledSendMessage) return;
                this.disabledSendMessage = true;

                const manID = this.currentUser.role==='man'?this.chatOwner.id:this.opponent.id  ;
                const womanID = this.currentUser.role==='man'?this.opponent.id:this.chatOwner.id ;
                const opponentID = this.opponent? this.opponent.id: null;

                axios.post(`/send-chat-message/${manID}/${womanID}`,{
                    contact_id:this.selectedContact? this.selectedContact.id:null,
                    text: message,
                    operator: this.opponent.operator_id?this.opponent.operator_id:null,
                    author: this.currentUser.role,
                    seesOpponent: isOpponentInChat,
                })
                    .then(response => {
                        console.log(response.data);
                        this.disabledSendMessage = false;
                        this.resetTextInInput(opponentID);
                        this.newMessageHandler(response.data);
                    })
                    .catch(e => {
                        console.log(e);
                        this.disabledSendMessage = false;
                        this.errorHandler(e);
                    })
            }
            // else {
            //     this.cacheLastMessage('message',message,'')
            // }
        },
        resetTextInInput(opponentID){
            this.$bus.$emit('set_message_input','');
            if(this.supportsLocalStorage && opponentID){
                localStorage.removeItem(`message.${opponentID}`);
            }
        },
        newMessageHandler(data){
            console.log('newMessageHandler');
            this.newBalance(data.credits);
            if(data.status === 'ok' && !this.messagesInit.find(message => +message.id === +data.message.id)){
                let newMessage = data.message;
                this.$set( newMessage,'isReadOpponent',this.isOpponentInChat);
                this.$set( newMessage, 'speechStatus', '')
                this.messagesInit.push(newMessage);
                this.addMessage(this.messagesInit[this.messagesInit.length-1]);

                if (data.contact && this.opponent && this.opponent.id === data.contact[`${this.roleOpponent}_id`] ){
                    this.selectedContact = data.contact ;
                    this.contactReplacement();
                }else if(this.selectedContact) {
                    this.selectedContact.last_time = new Date().toString();
                    if(this.currentUser.role==='man'){
                        this.selectedContact.count_man ++;
                        // this.countUnread -= this.selectedContact.unread_man
                        this.selectedContact.unread_man = 0 ;
                        this.selectedContact.unread_woman ++ ;
                    }else {
                        this.selectedContact.count_woman ++;
                        // this.countUnread -= this.selectedContact.unread_woman
                        this.selectedContact.unread_woman = 0 ;
                        this.selectedContact.unread_man ++ ;
                    }
                }
                if(this.selectedContact){
                    this.selectedContact.lastMessage = data.message ;
                }
                this.sortContacts();
                this.$bus.$emit('chat_scroll_down');
                if(data && data.message && data.message.type === 'link'){
                    //this.$bus.$emit('buy-link-success');
                    console.log("update link status")
                    if (this.opponent){
                        this.opponent['linkPurchase'] = true;
                    }
                }
            }else if (data.status === 'not credits'){
                this.$router.push({name:'PaymentPage'});
                // this.openPrice();
                // alert(`Not enough credits to continue using the chat, your balance ${this.chatOwner.credits/100} credits`);
            }
        },
        // addOperatorID(user){
        //     if (user.role === 'operator' || user.role === 'admin_operator'){
        //         operators.push(user.operatorID)
        //     }
        // },
        emergencyPing(){
            if(process.env.MIX_APP_ENV==='local'){return}
            if(!this.showModalLostConnection || !this.chatOwner) return
            const contactID = this.selectedContact? this.selectedContact.id: null

            axios.post(`/emergency-ping/${this.chatOwner.id}`,{contactID})
                .then(response => {
                    console.log(response.data);
                    if(!this.showModalLostConnection) return
                    this.handleEmergencyPing(response.data,contactID);
                    if(this.showSupport){
                        this.getSupportMessagesAxios()
                    }
                    timeoutEmergencyPing = setTimeout(() => {
                        this.emergencyPing();
                    },5000)
                })
                .catch(e => {
                    console.log(e);
                    clearTimeout(timeoutEmergencyPing)
                    this.errorHandler(e);
                })
        },
        handleEmergencyPing(data,contactID){
            this.chatOwner = data.chatOwner;
            this.contacts = data.contacts;
            this.favorites = data.favorites;
            this.setRemainingUnread(data.countUnread);
            this.supportContact = data.supportContact;
            if(contactID && +contactID === +data.contactID && data.messages && data.messages.length){
                this.setMessages(data.messages)
                this.$bus.$emit('chat_scroll_down');
            }
        },

        connectToOnlineChannel(){
            console.log('start connect ONLINE');
            if (this.currentUser.role === 'man'){
                Echo.join('online')
                    .here(users => {
                        // this.onChannelOnline = true;
                        // users.forEach( user => {
                        //     this.addOperatorID(user)
                        // })
                        console.log('here online')
                        console.log({ users });
                    })
                    .joining(user =>{
                        console.log('joining')
                        // this.addOperatorID(user)
                    })
                    .leaving(user =>{
                        console.log('leaving')
                        // if (user.role === 'operator' || user.role === 'admin_operator'){
                        //     operators = operators.filter(id => +id !== +user.operatorID)
                        // }
                    })
                    .error((error) => {
                        console.log('error connect chanel online');
                        console.log(error);
                        Echo.leave('online');
                        setTimeout(()=>{
                            this.connectToOnlineChannel()
                        },5000);
                    })
                    .listen('onLockMan',event => {
                        console.log('onLockMan', event);
                        this.lockOwnerChat(event.man_id,event.status==='LOCK');
                    })
                    .listen('onLockWoman',event => {
                        console.log('onLockWoman', event);
                        this.lockOpponent(event.status === 'LOCK',event.woman_id);
                    })
                    .listen('onValidateWoman',event => {
                        console.log('onValidateWoman', event);
                        this.validateWoman(event);

                    })
                    .listen('onChangeWomanOperator',event => {
                        console.log('onChangeWomanOperator', event);
                        this.changeWomanOperator(event);
                    })
                    .listen('onHiddenMediaBySrc',event => {
                        console.log('onHiddenMediaBySrc', event);
                        this.hiddenMediaBySrc(event);
                    })
                    .listen('NewWomanOnlineEvent', event => {
                        console.log('NewWomanOnlineEvent', event);
                        this.memberOnlineHandler(event,'woman');
                    });
            }else {
                Echo.join('online')
                    .here(users => {
                        // this.onChannelOnline = true;
                        console.log('here online');
                        console.log(users);
                    })
                    .error((error) => {
                        console.log('error connect chanel online');
                        console.log(error);
                        Echo.leave('online');
                        setTimeout(()=>{
                            this.connectToOnlineChannel()
                        },5000);
                    })
                    .listen('onLockWoman',event => {
                        console.log('onLockWoman', event);
                        this.lockOwnerChat(event.woman_id,event.status === 'LOCK');
                    })
                    .listen('onLockMan',event => {
                        console.log('onLockMan', event);
                        this.lockOpponent(event.status === 'LOCK',event.man_id);
                    })
                    .listen('onValidateWoman',event => {
                        console.log('onValidateWoman', event);
                        this.validateWoman(event);

                    })
                    .listen('NewManOnlineEvent', event => {
                        console.log('NewManOnlineEvent', event);
                        this.memberOnlineHandler(event,'man');
                    });

            }
        },
        connectToMemberChannel(){
            console.log('start connectToMemberChannel')

            const blockedEvent = this.currentUser.role === 'man'?'blockedMan':'blockedWoman';
            const IBlockedEvent = this.currentUser.role === 'man' ? 'blockedWoman' : 'blockedMan';

            Echo.join(`${this.currentUser.role}.${this.chatOwner.id}`)
                .here(users => {
                    console.log('here visitors')
                    // console.log(users);
                    this.initDataAfterSleep();

                })
                .joining(user => {
                    console.log('visit')
                    console.log(user)
                })
                .leaving(user => {
                    console.log('leaving')
                    // console.log(user)
                })
                .error((error) => {
                    console.log(`error connect chanel ${this.currentUser.role}.${this.chatOwner.id}`);
                    console.log(error);
                    Echo.leave(`${this.currentUser.role}.${this.chatOwner.id}`);
                    Echo.leave('service_channel')
                    setTimeout(()=>{
                        this.connectToMemberChannel()
                    },5000);
                })
                .listen('TypingMLMessage',event=>{
                    console.log('event TypingMLMessage');
                    console.log(event);
                    this.typingMLMessage(event)
                })
                .listen('onDeleteMessage',event=>{
                    console.log('event onDeleteMessage');
                    console.log(event);
                    this.deleteMessage(event.message_id)
                })
                .listen('onHiddenMessage',event=>{
                    console.log('event onHiddenMessage');
                    console.log(event);
                    this.blockedMessage(event.message_id)
                })
                .listen('SendMyMessageEvent',event=>{
                    console.log('event SendMyMessageEvent');
                    console.log(event);
                    if((this.selectedContact && +this.selectedContact.id === +event.message.contact_id)||
                        (event.contact && this.opponent && +event.contact[`${this.roleOpponent}_id`] === this.opponent.id)){
                        this.newMessageHandler(event)
                    }

                })
                .listen('NewMessageEvent', event => {
                    console.log('NewMessageEvent', event);
                    if(event.message && event.message.author !== this.currentUser.role){
                        this.getNewMessage(event);
                    }
                })
                .listen('DeleteChatMessage', event => {
                    console.log('DeleteChatMessage', event);
                    this.deleteChatMessageHandler(event)
                })
                .listen('UpdateChatMessage', event => {
                    console.log('UpdateChatMessage', event);
                    this.updateChatMessageHandler(event)
                })
                .listen('onNewSupportMessage', event => {
                    console.log('onNewSupportMessage',event);
                    this.newSupportMessageHandler(event);
                })
                .listen('NewMemberBalance', event => {
                    console.log('NewMemberBalance', event);
                    this.newBalance(event.credits);
                })
                .listen('NewCoinsbuyPayment', event => {
                    console.log('NewCoinsbuyPayment', event);
                    this.modalCoinsBuyStatus = true;
                    this.cryptoCoinsbuyPayment = {
                        credits: event.credits,
                        amount: event.amount,
                    }

                })
                .listen('ValidateEmailSuccess', event => {
                    console.log('ValidateEmailSuccess', event);
                    if(event && event.status === 'success'){
                        this.modalValidEmail = false;
                        this.modalSuccessSendEmail = false;
                        // if (this.currentUser && !this.currentUser.email_verified) {
                        //     console.log('window.close');
                        //     self.close()
                        // }
                        this.currentUser.email_verified = true;
                    }
                })
                .listen('onChangeTariffsForUser', event => {
                    console.log('onChangeTariffsForUser', event);
                    this.tariffs = event.tarif_plan;
                })
                .listen('NewCommentMyStory', event => {
                    console.log('NewCommentMyStory', event);
                    this.newCommentHandel(event);
                })
                .listen('NewPaymentCompleted', event => {
                    console.log('NewPaymentCompleted', event);
                    this.newPaymentCompletedHandel(event);
                })
                .listen(blockedEvent, event => {
                    console.log(blockedEvent, event);
                    this.myBlockedHandler(event);
                })
                .listen(IBlockedEvent, event => {
                    console.log(blockedEvent, event);
                    this.IBlockedHandler(event);
                });

            Echo.channel('service_channel')
                .listen('onNewServiceMessage', event => {
                    console.log('onNewServiceMessage ', event);
                    this.needReloadPage();
                });

        },
        typingMLMessage(event){
            console.log('test typingMLMessage')
            this.$set(this.typingsMLMessage, event.womanID, {
                enabled: true,
                timer: setTimeout(()=>{
                    // this.typingsMLMessage[event.womanID] = null
                    this.$delete(this.typingsMLMessage, event.womanID)
                },200000)
            })
        },
        deleteTypingMlMessage(womanID){
            if(this.typingsMLMessage[womanID]){
                clearTimeout(this.typingsMLMessage[womanID].timer);
                this.$delete(this.typingsMLMessage, womanID)
                // this.typingsMLMessage[womanID] = null
            }
        },
        connectToOnChannels() {
            if(process.env.MIX_APP_ENV==='local'){
                console.warn('process.env.MIX_APP_ENV = local connectToOnChannels DISABLED')
                return
            }
            console.log('start connectToOnChannels')
            this.connectToOnlineChannel()
            this.connectToMemberChannel()
            const connectStatus = Echo.connector.pusher.connection.state;
            this.showModalLostConnection = connectStatus !== 'connected'
            Echo.connector.pusher.connection.bind('state_change', (states) => {
                console.log('state_change')
                console.log(states)
                this.showModalLostConnection = states.current !== 'connected';
                if(this.showModalLostConnection && !timeoutEmergencyPing){
                    timeoutEmergencyPing = setTimeout(() => {
                        this.emergencyPing();
                    },5000)
                }
                if(!this.showModalLostConnection) clearTimeout(timeoutEmergencyPing)
            });
        },
        deleteChatMessageHandler(event){
            console.log('deleteChatMessageHandler');
            if(this.selectedContact && +this.selectedContact.id === +event.contactID && this.showChat && this.$route.name==='profile'){
                this.deleteMessage(+event.messageID)
            }else if(!event.isRead){
                console.log('delete count !event.isRead');
                const findContact = this.contacts.find(contact => +contact.id === +event.contactID)
                if(findContact){
                    if(findContact[`unread_${this.currentUser.role}`] > 0){
                        findContact[`unread_${this.currentUser.role}`] --;
                    }
                }
            }
        },
        async initDataAfterSleep(){
            if(this.isInit) return
            console.log('initDataAfterSleep')
            this.isInit = true;
            setTimeout(()=>{
                this.isInit = false;
            },5000)
            this.$bus.$emit('update_service_worker')

            await this.initData('here');

            if (this.opponent){
                this.$nextTick(()=>{
                    if(this.$route.name === 'chat' && this.$mq !== 'desktop') this.$bus.$emit('update_data_mobile_chat');
                    else this.$bus.$emit('update_data_profile');
                })
            }
        },
        hiddenMediaBySrc(event){
            const findMessages = this.messagesInit.find((message)=>{
                return message.src === event.src
            });
            if(findMessages){
                findMessages.hidden = true
                this.decomposeMessagesByDate(this.messagesInit);
            }
        },
        newPaymentCompletedHandel(event){
            this.newBalance(event.credits);
            if(this.$route.name !== 'PaymentCgStatus'){
                if(event.status === 'success'){
                    this.paymentTariff = event.tariff
                    this.openSuccessPayment()
                }else if(event.status === 'failed'){
                    this.paymentTariff = event.tariff
                    this.openFailedPayment()
                }
            }
        },
        newCommentHandel(event){
            this.showSnotify('comment','New comment','',JSON.parse(event.opponent));
        },
        changeWomanOperator(event){
            //$womanId, $oldOperatorId, $newOperatorId
            this.actionItem(this.gallery,event.womanId,'operator_id',Number(event.newOperatorId));
            this.actionContacts(event.womanId,'operator_id',Number(event.newOperatorId));
        },
        actionContacts(opponentID,field,value){
            let findContact = this.contacts.find(contact=>{
                return +contact[this.roleOpponent].id === +opponentID;
            })
            if(findContact){
                findContact[this.roleOpponent][field] = value;
            }
        },
        myBlockedHandler(event){
            if(event.action === 'block'){
                this.myBanned.push(Number(event[`${this.roleOpponent}ID`]))
            }else {
                this.myBanned = this.myBanned.filter(id=>{
                    return +id !== Number(event[`${this.roleOpponent}ID`])
                })
            }
        },
        IBlockedHandler(event){
            if(event.action === 'block'){
                this.banList.push(Number(event[`${this.roleOpponent}ID`]))
            }else {
                this.banList = this.banList.filter(id=>{
                    return +id !== Number(event[`${this.roleOpponent}ID`])
                })
            }
        },
        memberOnlineHandler(event,role){
            console.log('memberOnlineHandler' + event[role].id)
            // NEED ASK SAHSA OR YURI
            if (+event[role].id >= 1000000000){
                const status = event.status === 'member_added';
                if(this.opponent && +this.opponent.id === +event[role].id){
                    this.opponent.online = status ;
                }
                let foundContact = this.contacts.find(contact=>{
                    return +contact[role].id === +event[role].id
                })
                if (foundContact){
                    foundContact[role].online = status ;
                }
                this.actionItem(this.gallery,event[role].id,'online',status)
                // if(!this.actionItem(this.gallery,event[role].id,'online',status) && status){
                //     console.log('get new woman');
                //     console.log(event[role].id);
                //     axios.get(`/get-user-by-role-id/${role}/${event[role].id}`)
                //         .then(response => {
                //             if (response.data.status === 'ok'){
                //                 console.log(response.data.user);
                //                 const findMember = this.gallery.find(member=>{
                //                     return +member.id === +response.data.user.id
                //                 })
                //                 if(!findMember){
                //                     // this.gallery.unshift(response.data.user);
                //                     this.bufferGallery.push(response.data.user);
                //                     // this.$nextTick(()=>{
                //                     //     this.$bus.$emit('more_gallery_completed')
                //                     // });
                //                 }
                //             }
                //         })
                //         .catch(e => {
                //             console.log(e);
                //             this.errorHandler(e);
                //         })
                // }else if (!status){
                //     this.removeItemBufferGallery(+event[role].id)
                // }
            }

        },
        // removeItemBufferGallery(id){
        //     const bufferIndex = this.bufferGallery.findIndex(item=>{
        //         return +item.id === +id
        //     })
        //     if (bufferIndex > -1){
        //         this.bufferGallery.splice(bufferIndex,1)
        //     }
        // },
        getOpponentMessages(chatOwnerID,opponentID){
            if(this.isPromoPage(false)){return}
            const manID = this.currentUser.role === 'man'? chatOwnerID:opponentID ;
            const womanID = this.currentUser.role === 'woman'? chatOwnerID:opponentID ;
            axios.get(`/get-opponent-messages/${manID}/${womanID}`)
                .then(response => {
                    console.log(response.data);
                    if (response.data.status === 'ok'){
                        if(this.selectedContact && +response.data.contact.id === +this.selectedContact.id) {
                            this.setMessages(response.data.messages);
                            const findContact = this.contacts.find(contact => {
                                return +contact.id === +response.data.contact.id
                            })
                            if (findContact) {
                                // this.countUnread -= findContact[`unread_${this.currentUser.role}`]
                                findContact[`unread_${this.currentUser.role}`] = 0
                            }
                        }
                    }else if (response.data.status === 'logout'){
                        this.logoutUser();
                    }

                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        getNewMessage(event){
            console.log('getNewMessage');
            let contact = null;
            let sound = 'newMessage';
            let textNotify = '';
            if(this.isPromoPage(false)){
                this.initContactPromo()
                this.unreadPromoMessage = 2
                if(this.supportsLocalStorage){
                    let promoMessages = JSON.parse(localStorage.getItem("promoMessages") || "[]");
                    if(promoMessages.length >= 2){
                        promoMessages = []
                    }
                    promoMessages.push(event);
                    localStorage.setItem("promoMessages", JSON.stringify(promoMessages));
                    this.updatePromoMessageInSession(promoMessages)
                }
            }
            this.deleteTypingMlMessage(event.womanID)
            if ((this.currentUser.role === 'man' && this.opponent && +this.opponent.id === +event.womanID)||
                (this.currentUser.role === 'woman' && this.opponent && +this.opponent.id === +event.manID)){
                console.log('in if getNewMessage');
                let newMessage = event.message;

                this.$set( newMessage,'isReadOpponent',true);
                this.$set( newMessage, 'speechStatus', '')
                if (this.selectedContact && Boolean(this.selectedContact[`unread_${this.roleOpponent}`])){
                    const indexMax = this.messages.length - this.selectedContact[`unread_${this.roleOpponent}`] ;
                    // this.countUnread -=  this.selectedContact[`unread_${this.roleOpponent}`]
                    this.selectedContact[`unread_${this.roleOpponent}`] = 0
                    for ( let i=this.messages.length-1 ; i >= indexMax ; i-- ){
                        if (this.messages[i].author === this.currentUser.role){
                            this.messages[i].isReadOpponent = true;
                        }else {
                            i = 0 ;
                        }
                    }
                }
                this.messagesInit.push(newMessage);
                this.addMessage(this.messagesInit[this.messagesInit.length-1]);
                sound = 'newMessageInChat';
                this.$bus.$emit('chat_scroll_down');
                if(this.selectedContact){
                    if (!this.showChat){
                        // this.contactUnreadHandler(this.selectedContact);
                        this.showSnotify('message','New message',this.textNotification(event.message),event.member);
                        this.selectedContact[`unread_${this.currentUser.role}`]++
                        // this.countUnread++
                    }else {
                        // this.countUnread -= this.selectedContact[`unread_${this.currentUser.role}`]
                        this.selectedContact[`unread_${this.currentUser.role}`] = 0;
                    }
                    contact = this.selectedContact;
                }else {
                    this.getContact(event.message);
                    if (this.showChat){
                        this.getOpponentMessages(this.chatOwner.id,this.opponent.id);
                    }
                }
            }else {
                    console.log('in else');
                    let findContact = this.contacts.find(contact=>{
                        return +contact.id === +event.message.contact_id
                    });
                    if (findContact){
                        console.log('if (findContact)');
                        this.contactUnreadHandler(findContact);
                        contact = findContact ;
                    }else {
                        // this.countUnread++
                        this.getContact(event.message);
                    }
                this.showSnotify('message','New message',this.textNotification(event.message),event.member);
                }
            if (contact){
                contact.lastMessage = event.message ;
                contact.last_time = new Date().toString();
                this.sortContacts();
            }
            this.playSound(sound);
            if (!document.hasFocus()){
                console.log('not focus');
                this.showNotificationNewMessage(this.textNotification(event.message),event.member);
            }
        },
        textNotification(message){
            const texts = {
                picture:'New image',
                gif:'New gif',
                letter:'New letter',
                clip:'New video',
                voice:'New audio',
                animation:'New animation',
                emoji:'New emoji'
            }

            return message.type === 'message' ? message.message.substring(0, 30) : texts[message.type] ;
        },
        showNotificationNewMessage(message,member){
            console.log('showNotificationNewMessage');
            if (("Notification" in window) && ('serviceWorker' in navigator) && Notification.permission === "granted") {
                navigator.serviceWorker.ready
                    .then(sw =>{
                        console.log('navigator.serviceWorker.ready')
                        const msg = {
                            title: `New message from ${member.name}`,
                            body: message.substr(0, 30),
                            icon: `${this.s3Url}/${member.avatar}`,
                            url:`${this.myUrl}/profile/${member.id}`
                        }
                        if (sw.active) {
                            console.log('sw.active')
                            sw.active.postMessage(msg)
                        }
                    });
            }
        },
        contactUnreadHandler(contact){
            console.log('contactUnreadHandler');
            // this.countUnread++
            if (this.currentUser.role === 'man'){
                contact.unread_man ++
            }else {
                contact.unread_woman ++
            }
        },
        getContact(message){
            console.log('getContact(contactID)');
            if(this.isPromoPage(false)){return }
            axios.get(`/get-contact/${message.contact_id}/${this.roleOpponent}`)
                .then(response => {
                    if (response.data.status === 'ok') {
                        console.log(response.data)
                        let contact = response.data.contact;

                        if (contact) {
                            contact.lastMessage = message;

                            let findContact = this.contacts.find(item => {
                                return +item.id === +contact.id
                            })
                            if (!findContact) {
                                this.contacts.push(contact);
                            }

                            if (this.opponent && +this.opponent.id === +contact[`${this.roleOpponent}_id`]) {
                                // this.countUnread -= contact[`unread_${this.currentUser.role}`]
                                contact[`unread_${this.currentUser.role}`] = 0
                                this.selectedContact = contact;
                            }

                            this.sortContacts();
                        }
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        sortContacts(){
            console.log('sortContacts');
            this.contacts.sort( (a, b) => {
                return new Date(b.last_time) - new Date(a.last_time);
            });
        },
        validateWoman(event){
            if(this.currentUser.role === 'man'){
                const status = event.status ==='VALID';
                const isOnGallery = this.actionItem(this.gallery,+event.woman_id,'valid',status);
                if (isOnGallery && !status){
                    this.removeOpponentGallery(event.woman_id);
                }
                this.actionItem(this.gallery,+event.woman_id,'valid',status);
                this.actionItem(this.gallery,Number(event.woman_id),'operator_id',Number(event.operator_id));
                this.actionContacts(Number(event.woman_id),'valid',status);
                this.actionContacts(Number(event.woman_id),'operator_id',Number(event.operator_id));
                if (!status && this.$route.name === 'profile' && +this.opponent.id === +event.woman_id){
                    this.opponent = null;
                    this.$router.push({name: 'gallery'});
                }
            }else if(+event.woman_id === +this.chatOwner.id){
                this.chatOwner.valid = event.status === 'VALID';
            }

        },
        removeOpponentGallery(opponentID){
            const indexOpponent = this.gallery.findIndex(opponent=>{
                return +opponent.id === +opponentID;
            })
            if(indexOpponent >= 0){
                this.gallery.splice(indexOpponent,1)
            }
            // this.removeItemBufferGallery(+opponentID);
        },
        actionItem(items,id,field,value){
            console.log('actionItem');
            const findItem = items.find(item=>{
                return +item.id === +id
            })
            if (findItem){
                findItem[field] = value;
                return true ;
            }
            return false ;
        },
        leavingToChannel(){
            Echo.leave(`${this.currentUser.role}.${this.chatOwner.id}`);
            Echo.leave('online');
            // this.onChannelOnline = false;
        },
        lockOwnerChat(id,lock){
            if (+this.chatOwner.id === +id && lock){
                this.leavingToChannel();
                this.logoutUser();
            }
        },
        // lockItem(items,statusLock,id){
        //     const findItem = this.items.find(item=>{
        //         return +item.id === +id
        //     })
        //     if (findItem){
        //         findItem.lock = statusLock;
        //     }
        // },
        lockOpponent(statusLock,id){
            if (statusLock){
                this.removeOpponentGallery(id);
                if (this.$route.name === 'profile' && +this.opponent.id === +id){
                    this.opponent = null;
                    this.$router.push({name: 'gallery'});
                }

            }
            // this.lockItem(this.gallery,statusLock,id);
            // this.lockItem(this.contacts,statusLock,id);
            this.actionContacts(id,'lock',statusLock);
        },
        setViewedMessages(){
            if(this.selectedContact && this.messages && this.messages.length) {
                const indexMax = this.messagesInit.length - this.selectedContact[`unread_${this.roleOpponent}`];
                if(indexMax <= 0){
                    this.messages.forEach((message)=>{
                        this.$set(message,'isReadOpponent',false);
                    })
                }else {
                    this.messagesInit.forEach((message) => {
                        this.$set(message, 'isReadOpponent', true);
                    })
                    if (Boolean(this.selectedContact[`unread_${this.roleOpponent}`]) || indexMax < this.messages.length) {
                        for (let i = this.messagesInit.length - 1; i >= indexMax; i--) {
                            if (this.messagesInit[i].author === this.roleOpponent) {
                                this.messagesInit[i].isReadOpponent = false;
                            } else {
                                i = 0;
                            }
                        }
                    }
                }
            }
        },
        setMessages(messages){
            this.messagesInit = messages ;
            this.isAllMessages = this.messagesInit.length < 50 ;
            this.messagesInit.sort((a,b)=>{
                return new Date(a.created_at)-new Date(b.created_at)
            });

            this.decomposeMessagesByDate(this.messagesInit)
        },
        initProfileHandel(data){
            console.log('init_profile');
            console.log(data);
            this.opponent = data.opponent ;
            this.indexMedia = null;
            this.mediaChat = [];

            const findMember = this.gallery.find((member =>(+member.id === this.opponent.id)));
            if(findMember) this.$set(findMember,'isViewOpponent',true);
            notifyIDs = notifyIDs.filter(id => +id !== +this.opponent.id)
            if (!this.opponent || this.opponent.lock || (this.roleOpponent === 'woman' && !this.opponent.valid)){
                // this.$router.push({path: '/error'});
                this.$router.push({name: 'gallery'});
                return
            }
            this.setMessages(data.messages);
            this.selectedContact = data.contact;
            if (this.selectedContact){
                this.selectedContact.lastMessage = this.messages[this.messages.length-1] ;
                this.setViewedMessages();
            }
            if(this.opponent[`${this.roleOpponent}_portfolios`]){
                this.portfolioImages = [];
                const portfolios = this.opponent[`${this.roleOpponent}_portfolios`];
                if(portfolios && portfolios.length){
                    this.portfolioImages = portfolios.map(item=>{
                        return { src: `${this.s3Url}/${this.isSupportedWebp && item.src_webp ? item.src_webp : item.src}`}
                    })
                }
            }
            if(this.opponent[`${this.roleOpponent}_pack_media`]){
                this.packMedias = [];
                this.isPaidPackage = false;
                const packMedia = this.opponent[`${this.roleOpponent}_pack_media`];
                if (packMedia){
                    this.packMedias = ( this.isSupportedWebp && packMedia.medias_webp && packMedia.medias_webp.length ) ?
                        packMedia.medias_webp : packMedia.medias;
                    this.isPaidPackage = this.opponent[`${this.roleOpponent}_pack_media`].payers.includes(this.chatOwner.id);
                }
            }

            this.contactReplacement() ;
            if (this.showChat && this.selectedContact){
                this.resetUnreadMessages();
            }
            this.sortContacts() ;
            // this.messages = data.messages ;
        },
        decomposeMessagesByDate(messages){
            this.messages = {};
            messages.forEach(message=>{
               this.addMessage(message)
            })
        },
        addMessage(message){
            // const date = new Date(message.created_at).toLocaleDateString();
            const fullDate = new Date(message.created_at);
            const date = `${fullDate.getDate()}.${fullDate.getMonth()+1}.${fullDate.getFullYear()}`
            if (!(date in this.messages)){
                this.$set(this.messages,date,[]);
                // this.messages[date] = [];
            }
            this.$set(message,'isOptionMenu',false);
            this.$set(message,'audioPlay',false);
            message.wavesurfer = null;
            this.messages[date].push(message);
        },
        decomposeSupportMessagesByDate(messages){
            this.supportMessages = {};
            messages.forEach(message=>{
               this.addSupportMessage(message)
            })
        },
        addSupportMessage(message){
            // const date = new Date(message.created_at).toLocaleDateString();
            const fullDate = new Date(message.created_at);
            const date = `${fullDate.getDate()}.${fullDate.getMonth()+1}.${fullDate.getFullYear()}`
            if (!(date in this.supportMessages)){
                this.$set(this.supportMessages,date,[]);
                // this.messages[date] = [];
            }
            this.supportMessages[date].push(message);
        },
        selectOpponent(member, type, showChat=false){
            console.log('selectOpponent home');
            this.showChat = showChat;
            this.typeSelectOpponent = type;
            if (+member.id === +this.$router.currentRoute.params.id){
                if(this.$mq !== 'desktop') this.$router.push({ name: 'chat', params: {id: member.id }});
                if (showChat && this.selectedContact){
                    this.resetUnreadMessages();
                }
            }else {
                console.log({member});
                this.opponent = member ;
                if(!this.isPromoPage(false)){
                    this.selectedContact = null ;
                }

                // const path = `/profile/${member.id}`
                // console.log({path});
                // this.$router.push({ name: 'profile', params: {id: member.id }})
                if(this.$mq === 'desktop' || !this.showChat) this.$router.push({ name: 'profile', params: {id: member.id }});
                else this.$router.push({ name: 'chat', params: {id: member.id }})
            }
        },
        getMoreGallery(){
            if(!this.isAllGallery){
                this.isGetMoreGallery = true;
                if (this.isSearch && this.searchResultIds.length ){
                    axios.post(`/get-more-gallery-search`,{
                        role:this.currentUser.role,
                        memberID: this.chatOwner.id,
                        ids:this.searchResultIds
                    })
                        .then(response => {
                            console.log(response.data);
                            this.moreGalleryHandel(response.data.gallery,response.data.views) ;
                            this.searchMemberIdHandel();
                        })
                        .catch(e => {
                            this.isGetMoreGallery = false;
                            console.log(e);
                            this.errorHandler(e);
                        })

                }else {
                    // const lastID = this.gallery[this.gallery.length-1].id ;
                    // const lastID = this.lastGalleryOnlineStatus?this.lastIDGalleryOnline:this.lastIDGalleryOffline ;
                    const lastID = this.gallery[this.gallery.length-1];
                    console.log('getMoreGallery',lastID);
                    const ids = []
                    this.gallery.forEach(item =>{
                        ids.push(item.id)
                    })

                    // axios.get(`/get-more-gallery-for-${this.currentUser.role}/${Number(this.lastGalleryOnlineStatus)}/${lastID}`)
                    axios.post(`/get-more-gallery-for-${this.currentUser.role}-v2`,{
                        memberID: this.chatOwner.id,
                        ids: ids,
                    })
                        .then(response => {
                            console.log(response.data)
                            this.moreGalleryHandel(response.data.gallery,response.data.views) ;
                        })
                        .catch(e => {
                            this.isGetMoreGallery = false;
                            console.log(e);
                            this.errorHandler(e);
                        })
                }

            }

        },
        moreGalleryHandel(moreGallery,views){
            this.isGetMoreGallery = false;
            this.isAllGallery = moreGallery.length < this.galleryCropLimit;
            if(moreGallery.length){
                // this.lastGalleryOnlineStatus = data[data.length-1].online;
                // if (this.lastGalleryOnlineStatus){
                //     this.lastIDGalleryOnline = data[data.length-1].id;
                // }else {
                //     this.lastIDGalleryOffline = data[data.length-1].id;
                // }
                // for (let i=0 ; i < moreGallery.length ; i++){
                //     const findMember = this.gallery.find(item =>{
                //         return Number(item.id) === Number(moreGallery[i].id)
                //     });
                //     if (!findMember){
                //         this.gallery.push(moreGallery[i]);
                //     }
                // }
                if(views && views.length){
                    moreGallery.forEach((item)=>{
                        this.$set(item,'isViewOpponent',Boolean(views.find(view =>(+item.id===+view[`${this.roleOpponent}_id`]))))
                    })
                }
                // this.setViewsToGallery(moreGallery);
                moreGallery.forEach(member => {
                    const findMember = this.gallery.find(item =>{
                        return Number(item.id) === Number(member.id)
                    });
                    if (!findMember){
                        this.gallery.push(member);
                    }
                })

            }
            this.$bus.$emit('more_gallery_completed');
            this.$nextTick(()=>{
                sleepScroll = false
            }, 300)
            // setTimeout(()=>{
            //     this.$bus.$emit('more_gallery_completed')
            // },0);
        },
        // selectContact(contact){
        //     this.selectedContact = contact;
        // },
        initContactPromo(){
            // let contactPromo = {
            //     count_man:1,
            //     count_woman:1,
            //     created_at:new Date().toUTCString(),
            //     favorite_man:false,
            //     favorite_woman:false,
            //     hidden_man:false,
            //     hidden_woman:false,
            //     id:0,
            //     lastMessage:null,
            //     last_message_id:0,
            //     last_time:new Date().toUTCString(),
            //     man_id:0,
            //     unanswered:0,
            //     unread_man:0,
            //     unread_woman:0,
            //     updated_at:new Date().toUTCString(),
            //     woman: {
            //         admin:0,
            //         avatar:"images/woman/1000000618/avatar/6HZrkw0RkNJUDiQiVHLmP4qX6b8V8VwT8MCDR3Tj.jpg",
            //         avatar_webp:"images/woman/1000000618/avatar/NrP1CxpCgrooAZi9Qp703kYzyQVZh1wlwQnupE93.webp",
            //         birthday:null,
            //         color:"#df3535",
            //         country:null,
            //         country_id:null,
            //         created_at:"2023-09-05T17:08:12.000000Z",
            //         credits:0,
            //         desktop_gallery:"images/woman/1000000618/gallery/2inz4agfl0xggvDaY3igDoibu75spYgBLZrUuz8J.jpg",
            //         desktop_gallery_webp:"images/woman/1000000618/gallery/bUS9FHNfkEvM9uGD5GI8uvhOEK44frC3Ka2d20K2.webp",
            //         id:1000000618,
            //         img_gallery:"images/woman/1000000618/gallery/ASuWrNGTRvnNM9I9UKYW3ae3jWqhhlaACZqvfYAe.jpg",
            //         img_gallery_webp:"images/woman/1000000618/gallery/Zk5AhF7fNSlm6F067eiplOgT6w4U5qjfjTjsfZTP.webp",
            //         is_robot:true,
            //         last_time:"2024-04-08 17:39:46",
            //         last_visit:"2024-04-08T15:00:05.000000Z",
            //         lead:null,
            //         lock:false,
            //         name:"Vici_2*",
            //
            //     },
            //     woman_id:1000000618
            //
            // }
            // this.selectedContact = contactPromo
            // this.contacts = []
            // this.contacts.push(contactPromo)
        },
        initPromoProfile(){
            setTimeout(()=>{
                this.$emit('hideloader')
                this.isInitData = true ;
                this.currentUser = {
                    id: 0,
                    role: 'man'
                }
                this.chatOwner = {
                    id: 0,
                    name: 'promo'
                }
                this.tariffs = {
                    id: 0,
                    man_id: 0,
                    tariff_chat: 80,
                    tariff_clip: 2500,
                    tariff_letter: 800,
                    tariff_pack: 5000,
                    tariff_picture: 1000,
                    tariff_voice: 2500
                };
                this.mySettings = {
                    chat_background: 'bg-default',
                    color_theme: 'color-theme-3',
                    id: 0,
                    is_notifications: false,
                    is_sound: true,
                    man_id: 0,
                    message_color:'message_color1',
                    message_size: 16,
                    mode: 'mode-light',
                    push_notify: false,
                    subscribe_blog: false,
                };

                this.roleOpponent = 'woman'
                this.gifts = [
                    {
                        created_at:"2023-10-16T13:49:48.000000Z",
                        credits:1000,
                        description:null,
                        id:7,
                        show:1,
                        title:"Unicorn",
                        updated_at:"2023-10-16T13:49:48.000000Z",
                        url:"gifts/qP3VDa6X4sTN1mRUpMTE4ohmpn7tqLimdFvhgdFW.gif"
                    },{
                        created_at:"2023-08-29T15:07:52.000000Z",
                        credits:2000,
                        description:null,
                        id:5,
                        show:1,
                        title:"Jewel",
                        updated_at:"2023-08-29T15:07:52.000000Z",
                        url:"gifts/WDynnCliOcoH632FTch0QwiJzS7scjSXvsSnjlxE.gif"
                    },{
                        created_at:"2023-08-29T15:06:29.000000Z",
                        credits:5000,
                        description:null,
                        id:3,
                        show:1,
                        title:"Brilliant",
                        updated_at:"2023-08-29T15:06:29.000000Z",
                        url:"gifts/Sk8YZ5xt7XQsjNCaqNPaQWA1hIlVgvGMvknv92dx.gif"
                    }]
            },1000)
        },
        // initData(type='mounted'){
        //     if(this.isPromoPage(false)){
        //         this.initPromoProfile()
        //         return
        //     }
        //     const start = Date.now();
        //     axios.get(`/init-home/v2?type=${type}`)
        //         .then(response => {
        //             console.log(`/init-home/v2?type=${type}`)
        //             console.log(response.data) ;
        //             const speed = Date.now() - start;
        //             this.$emit('hideloader')
        //             this.isInitData = true ;
        //             this.initHandel(response.data,type) ;
        //             if(response.data.currentUser && speed > 500){
        //                 this.sendResponseSpeed(speed);
        //             }
        //         })
        //         .catch(err => {
        //             console.error(err);
        //             if (err.response) {
        //                 // client received an error response (5xx, 4xx)
        //                 console.log('err.response');
        //                 console.log(err.response);
        //                 if (err.response.status === 419 || err.response.status === 401){
        //                     console.log('response status: '+ err.response.status)
        //                     this.$bus.$emit('show_login_form')
        //                 }else {
        //                     this.errorHandler(err)
        //                 }
        //
        //             } else if (err.request) {
        //                 console.log('err.request!!!');
        //                 console.log(err.request);
        //                 this.$emit('no_internet');
        //                 // this.errorStatus = false ;
        //                 // alert('not inet');
        //                 // client never received a response, or request never left
        //             } else {
        //                 console.log('anything else ');
        //                 // anything else
        //                 this.errorHandler(err);
        //             }
        //
        //         })
        // },
        initData(type='mounted'){
            const start = Date.now();
            axios.get(`/init-home/v2?type=${type}`)
                .then(response => {
                    console.log(`/init-home?type=${type}`)
                    console.log(response.data) ;
                    const speed = Date.now() - start;
                    this.isInitData = true ;
                    this.initHandel(response.data,type) ;
                    if(response.data.currentUser && speed > 500){
                        setTimeout(()=>{
                            this.sendResponseSpeed(speed);
                        }, 1000)

                    }
                })
                .catch(err => {
                    console.error(err);
                    if (err.response) {
                        // client received an error response (5xx, 4xx)
                        console.log('err.response');
                        console.log(err.response);
                        if (err.response.status === 419 || err.response.status === 401){
                            console.log('response status: '+ err.response.status)
                            this.$bus.$emit('show_login_form')
                        }else {
                            this.errorHandler(err)
                        }

                    } else if (err.request) {
                        console.log('err.request!!!');
                        console.log(err.request);
                        this.$emit('no_internet');
                        // this.errorStatus = false ;
                        // alert('not inet');
                        // client never received a response, or request never left
                    } else {
                        console.log('anything else ');
                        // anything else
                        this.errorHandler(err);
                    }

                })
                .finally(()=>{
                    this.$emit('hideloader')
                })
        },
        sendResponseSpeed(speed){
            axios.post('/send-response-speed',{
                user_id:this.currentUser.id,
                speed:speed
            })
                .then(response => {
                    console.log('/send-response-speed')
                    console.log(response.data) ;
                })
                .catch(err => {
                    console.log(err);
                    this.errorHandler(err);
                })
        },
        setViewsToGallery(views){
            // console.log({views});
            if(!views || !views.length) return
            console.log('setViewsToGallery length:' + views.length);
            this.gallery.forEach((item)=>{
                this.$set(item,'isViewOpponent',Boolean(views.find(view =>(+item.id===+view[`${this.roleOpponent}_id`]))))
            })
        },
        // initHandel(data,type) {
        //     this.currentUser = data.currentUser;
        //     if (!this.currentUser){
        //         window.location.href = this.myUrl + '/';
        //     }
        //     this.$bus.$emit('set_is_login',true);
        //     this.holidayPromotion = data.holidayPromotion ;
        //     this.cdnLetterImages = data.cdnLetterImages;
        //     this.countries = data.countries;
        //     this.supportContact = data.supportContact;
        //     this.emojis = data.emojis;
        //     this.moods = data.moods;
        //     this.contacts = data.contacts ;
        //     this.chatOwner = data.chatOwner ;
        //     this.gallery = data.gallery;
        //     this.setViewsToGallery(data.views);
        //     this.bonusesCredits = data.bonusesCredits;
        //     // this.countUnread = data.countUnread;
        //     this.setRemainingUnread(data.countUnread);
        //     this.csrfHandel(data.token);
        //     if(this.holidayPromotion){
        //         this.timeNow = Date.now();
        //         this.startCheckHolidayPromotion();
        //         this.checkHolidayPromotion();
        //     }
        //
        //     console.log('gallery length - ',this.gallery.length);
        //     // this.lastGalleryOnlineStatus = false;
        //     // if (this.gallery.length > 0){
        //     //     this.lastGalleryOnlineStatus = this.gallery[this.gallery.length-1].online;
        //     //     if (this.lastGalleryOnlineStatus){
        //     //         this.lastIDGalleryOnline = this.gallery[this.gallery.length-1].id;
        //     //         console.log('lastIDGalleryOnline -',this.gallery[this.gallery.length-1].id);
        //     //     }else {
        //     //         this.lastIDGalleryOffline = this.gallery[this.gallery.length-1].id;
        //     //         console.log('lastIDGalleryOffline -',this.gallery[this.gallery.length-1].id);
        //     //     }
        //     // }
        //     this.banList = data.bansOpponent ;
        //     this.favorites = data.favorites ;
        //     this.myBanned = data.myBanned ;
        //     this.tariffs = data.myTariffs;
        //     this.mySettings = data.settings;
        //     this.gifts = data.gifts.reverse();
        //     this.changeMode(this.mySettings.mode);
        //     this.usedVideoCodecs = data.usedVideoCodecs.map(item => {
        //         return item.codec_name.toLowerCase()
        //     })
        //
        //     this.indicatorNewOpponent = Number(data.indicatorNewOpponent)*24*60*60;
        //     this.$nextTick(()=>{
        //         this.$bus.$emit('init_user_setting');
        //     });
        //     if (this.currentUser) {
        //         this.lastMessage = data.lastMessage;
        //         if (this.contacts.length < 50 ){
        //             this.isAllHistory = true ;
        //             this.isAllFavorite = true ;
        //             this.isAllChatRequest = true;
        //         }
        //         this.checkUser(this.currentUser);
        //         if(type==='mounted'){
        //             console.log('connect to channels');
        //             this.connectToOnChannels() ;
        //         }
        //         this.contacts.forEach(contact=>{
        //             contact.lastMessage = data.messages.find(message=>{
        //                 return +message.contact_id === +contact.id
        //             });
        //         })
        //         if (data.searchData && data.membersSearch.length){
        //             this.searchComplete(data.membersSearch, data.searchMemberIDs, data.searchData);
        //         }
        //         if (this.searchCountry){
        //             this.getSearchData();
        //         }
        //         this.beamsPushNotification();
        //         // this.setUserForSentry(this.currentUser.id,this.currentUser.name);
        //         this.scrollGalleryPosition = 0;
        //         this.galleryIndexFrom = 0;
        //         //test value (was 60)
        //         this.galleryIndexTo = 20;
        //         this.galleryOld = this.gallery;
        //         this.resetSearch();
        //         this.resetPromoMessages()
        //     }
        //
        // },
        initHandel(data,type) {
            console.log('initHandel');
            this.currentUser = data.currentUser;
            if (!this.currentUser){
                window.location.href = this.myUrl + '/';
            }
            this.$bus.$emit('set_is_login',true);
            this.holidayPromotion = data.holidayPromotion;
            this.cdnLetterImages = data.cdnLetterImages;
            this.countries = data.countries;
            this.supportContact = data.supportContact;
            this.emojis = data.emojis;
            this.moods = data.moods;
            this.chatOwner = data.chatOwner;
            this.gallery = data.gallery;
            this.setViewsToGallery(data.views);
            this.bonusesCredits = data.bonusesCredits;

            // this.setRemainingUnread(data.countUnread);
            this.csrfHandel(data.token, data.sessionLifetime);
            this.tariffs = data.myTariffs;
            if(this.holidayPromotion){
                this.timeNow = Date.now();
                this.startCheckHolidayPromotion();
                this.checkHolidayPromotion();
            }else {
                this.showModalGrammar = this.chatOwner.modal_grammarly;
            }
            console.log('gallery length - ',this.gallery.length);
            this.banList = data.bansOpponent ;
            this.favorites = data.favorites ;
            this.myBanned = data.myBanned ;
            this.mySettings = data.settings;
            this.gifts = data.gifts.reverse();
            this.changeMode(this.mySettings.mode);
            this.usedVideoCodecs = data.usedVideoCodecs.map(item => {
                return item.codec_name.toLowerCase()
            })

            this.indicatorNewOpponent = Number(data.indicatorNewOpponent)*24*60*60;
            this.$nextTick(()=>{
                this.$bus.$emit('init_user_setting');
            });

            // if (this.contacts.length < 50 ){
            //     this.isAllHistory = true ;
            //     this.isAllFavorite = true ;
            //     this.isAllChatRequest = true;
            // }
            this.checkUser(this.currentUser);
            if(type==='mounted'){
                console.log('connect to channels');
                this.connectToOnChannels() ;
                axios.defaults.headers.common['My-Seance-Id'] = data.seanceId;
                if(this.chatOwner.lead) axios.defaults.headers.common['My-Lead'] = btoa(this.chatOwner.lead);
            }
            // this.contacts.forEach(contact => {
            //     contact.lastMessage = data.messages.find(message => (+message.contact_id === +contact.id));
            // })
            if (data.searchData && data.membersSearch.length){
                this.searchComplete(data.membersSearch, data.searchMemberIDs, data.searchData);
            }
            if (this.searchCountry && type==='mounted'){
                this.getSearchData();
            }
            this.beamsPushNotification();
            // this.setUserForSentry(this.currentUser.id,this.currentUser.name);
            this.scrollGalleryPosition = 0;
            this.galleryIndexFrom = 0;
            this.galleryIndexTo = 60;
            this.galleryOld = this.gallery;
            this.resetSearch();
            this.getMyContacts()
        },
        getMyContacts(){
            if(!this.currentUser || !this.chatOwner) return;
            axios.get(`/get-my-contacts/${this.currentUser.id}/${this.chatOwner.id}`)
                .then(response => {
                    console.log('get-my-contacts')
                    const data = response.data;
                    console.log(data)
                    if (data.status === 'ok'){
                        if(data.contacts && data.contacts.length) this.contacts = data.contacts;
                        if (data.contacts && data.contacts.length < 50 ){
                            this.isAllHistory = true ;
                            this.isAllFavorite = true ;
                            this.isAllChatRequest = true;
                        }
                        this.setRemainingUnread(data.countUnread);
                        if(data.messages && data.messages.length){
                            this.contacts.forEach(contact => {
                                contact.lastMessage = data.messages.find(message => (+message.contact_id === +contact.id));
                            })
                        }
                        this.showSideBar = true;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        changeMode(mode){
            document.body.classList.forEach(item=>{
                if(item.startsWith('mode')) {
                    document.body.classList.remove(item) ;
                }
            })
            document.body.classList.add(mode)
        },
        // setUserForSentry(id,name){
        //     if (window.isSentryInit){
        //         Sentry.setUser({
        //             id: String(id),
        //             username: name,
        //         })
        //     }
        // },
        getSearchData(){
            const findCountry = this.countries.find(country=>{
                return country.countryCode === this.searchCountry
            });
            if(findCountry){
                let searchData = {
                    currentUserRole: this.currentUser.role,
                    searchParams: {},
                    searchRanges: {},
                    searchMembers: {
                        country_id:findCountry.id
                    }
                }
                console.log(searchData);
                axios.post('/search',searchData)
                    .then(response => {
                        console.log('success /search');
                        console.log(response.data);
                        if (!response.data.members.length){
                            alert('No results were found for your search')
                        } else {
                            this.searchComplete(response.data.members,response.data.searchMemberIDs,searchData,response.data.views);
                        }
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }

        },
        csrfHandel(token, minutes=10080){
            const metaElem = document.getElementById('csrf_token')
            metaElem.setAttribute('content',token);
            axios.defaults.headers.common['X-CSRF-TOKEN'] = token;
            // let expires = "";
            // if (minutes) {
            //     const date = new Date();
            //     date.setTime(date.getTime() + (minutes * 60 * 1000));
            //     expires = date.toUTCString();
            // }
            // document.cookie = `CSRF-TOKEN=${token}; expires=${expires}; path=/; SameSite=Lax`;
        },
        checkUser(currentUser){
            if (currentUser.role === 'woman' && this.chatOwner && !this.chatOwner.valid)  {
                this.$router.push({name: 'SuccessRegistration'});
            } else if (currentUser.role === 'operator' || currentUser.role === 'admin_operator') {
                window.location.href = this.myUrl + '/operator';
                console.error('operator')
            }else if (currentUser.role === 'man' || currentUser.role === 'woman') {
                if (this.$router.currentRoute.name === 'auth'){
                    this.$router.push({name: 'gallery'});
                }
            }
        },
        // loginUser(user){
        //     console.log('loginUser methods')
        //     $("#loginModal ").modal("hide");
        //
        //     this.currentUser = user ;
        //     if (this.currentUser) {
        //         this.checkUser(this.currentUser);
        //         this.initData('mounted');
        //     }
        //
        //     // this.$router.push({name: 'gallery'});
        //     // this.routeHandler(user);
        // },
        // registerUser(currentUser){
        //     $("#registerModal ").modal("hide");
        //     this.$emit('register',currentUser);
        //     this.initData('mounted');
        // },
        logoutUser(){
            // Echo.leave('online');
            // Echo.leave(`${this.currentUser.role}.${this.chatOwner.id}`);
            // this.onChannelOnline = false;
            axios.post('/logout')
                .then(response => {
                    console.log('/logout')
                    this.currentUser = null ;
                    window.location.href = this.myUrl + '/';
                    // this.$router.push({name: 'auth'});
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        // getOpponentData(){
        //     console.log('получить данные об оппоненте с хоста')
        // },

        errorHandler(err){
            console.log('errorHandler')
            if (err.response) {
                // client received an error response (5xx, 4xx)
                console.log('err response');
                console.log(err.response);
                if (err.response.status === 419 || err.response.status === 401){
                    console.log('err.response status 419');
                    console.log('route.name',this.$route.name);
                    if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat' || this.$route.name === 'PaymentPage') this.$bus.$emit('open_register')
                    else this.showModalSessionEnded = true;
                }else if (err.response.status >= 500){
                    console.log('err.response status >= 500');
                    // this.showModalSomethingWrong = true;
                    this.showModalMaintenance = true;
                }
            } else if (err.request) {
                console.log('err.request!!!');
                console.log(err.request);
                // this.showSnotify('no_connect','no_internet','no_internet');
                this.showModalNoConnection = true
                setTimeout(()=>{
                    this.showModalNoConnection = false
                },10000);
            } else {
                console.log('anything else ');
                // anything else
                this.showModalSomethingWrong = true;
                // this.$emit('errorhandler',err);
            }
        },
        sendStatusNotification(status){
            axios.get(`/change-push-notify-user/${status}/${this.currentUser.role}/${this.chatOwner.id}/${this.currentUser.id}`)
                .then(response => {
                    console.log('/change-push-notify/',status);
                    this.mySettings.push_notify = (status === '1');
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        stopBeams(){
            this.disabledNotification = true;
            this.modalNotify = false;
            BeamsClient.stop()
                .then(() => {
                    console.log("stop Beams.");
                    this.onSubscribeNotification = false;
                    this.disabledNotification = false;
                    this.sendStatusNotification('0')
                })
                .catch(e => {
                    console.error('Error BeamsClient.start',e);
                    this.showNotificationButton = false;
                });
        },
        startBeams(){
            this.disabledNotification = true;
            console.log('start beams');
            const  tokenProvider = new PusherPushNotifications.TokenProvider({
                url: `${this.myUrl}/pusher/beams-auth`,
                queryParams: { userId: this.currentUser.id },
                credentials: 'same-origin',
            })
            console.log(tokenProvider);
            if ('BeamsClient' in window){
                BeamsClient.start()
                    .then(() => {
                        BeamsClient.setUserId(String(this.currentUser.id), tokenProvider)
                            .then(() => {
                                console.log('User ID has been set BeamsClient')
                                this.onSubscribeNotification = true;
                                this.disabledNotification = false;
                                this.sendStatusNotification('1');
                            })
                    })

                    .catch(e => {
                        console.error('Could not authenticate with Beams:', e);
                        this.showNotificationButton = false;
                    })
            }

        },
        checkBeamsUserId(){
            console.log('checkBeamsUserId');
            this.disabledNotification = true;
            BeamsClient.getUserId()
                .then(userId => {
                    console.log('BeamsClient.getUserId',userId) // Will log the current user id
                    this.showNotificationButton = true;
                    if(+this.currentUser.id !== +userId){
                        BeamsClient.stop()
                            .then(() => {
                                console.log("stop Beams.");
                                this.onSubscribeNotification = false;
                                this.disabledNotification = true;
                                this.startBeams();
                            })
                            .catch(e => {
                                console.error('Error BeamsClient.start',e);
                                this.showNotificationButton = false;
                            });

                        console.log('currentUser.id !== +userId')

                    }else {
                        this.onSubscribeNotification = true;
                        this.disabledNotification = false;
                        console.log('currentUser.id === +userId')
                        // BeamsClient.start()
                        //     .then((beamsClient) => beamsClient.getDeviceId())
                        //     .then((deviceId) => {
                        //         console.log("Successfully registered with Beams. Device ID:", deviceId)
                        //         this.onSubscribeNotification = true;
                        //         this.disabledNotification = false;
                        //     })
                        //     .catch(e => {
                        //         console.error('Error BeamsClient.start',e);
                        //         this.showNotificationButton = false;
                        //     });
                    }
                })
                .catch(e => {
                    console.error('Could not get user id', e);
                    this.showNotificationButton = false;
                });


        },
        beamsPushNotification(){
            if('BeamsClient' in window){
                this.showNotificationButton = true;
                if( this.mySettings.push_notify && Notification.permission !== 'denied'){
                    console.log('beamsPushNotification');
                    try {
                        BeamsClient
                            .getRegistrationState()
                            .then((state) => {
                                const states = PusherPushNotifications.RegistrationState;
                                switch (state) {
                                    case states.PERMISSION_DENIED: {
                                        // Notifications are blocked
                                        // Show message saying user should unblock notifications in their browser
                                        console.log('Renew subscription');
                                        this.onSubscribeNotification = false;
                                        this.disabledNotification = false;
                                        break;
                                    }
                                    case states.PERMISSION_GRANTED_REGISTERED_WITH_BEAMS: {
                                        console.log('Ready to receive notifications');
                                        this.checkBeamsUserId();
                                        break;
                                    }
                                    case states.PERMISSION_GRANTED_NOT_REGISTERED_WITH_BEAMS:
                                    case states.PERMISSION_PROMPT_REQUIRED: {
                                        console.log('Need to call start before we are ready to receive notifications');
                                        this.startBeams();
                                        break;
                                    }
                                }
                            })
                            .catch((e) => {
                                console.error("Could not get registration state", e);
                                this.showNotificationButton = false;
                            });

                    }catch (e) {
                        console.error('Could not get user id');
                        console.error(e);
                    }


                }else {
                    console.log('Baned on publications');
                    this.disabledNotification = false;
                }
            }

        },
        // method do not used
        // beamsPushNotification(){
        //     console.log('/pusher/beams-auth');
        //
        //     const  tokenProvider = new PusherPushNotifications.TokenProvider({
        //         url: `${this.myUrl}/pusher/beams-auth`,
        //         queryParams: { userId: this.currentUser.id },
        //         // headers: { 'X-XSRF-TOKEN': document.cookie.XSRF-TOKEN },
        //         credentials: 'same-origin',
        //     })
        //     console.log('tokenProvider');
        //     // axios.get('/pusher/beams-auth')
        //     //     .then(res => {
        //     //         console.log(res.data);
        //     //         //res.data.tokenProvider
        //     //         BeamsClient.start()
        //     //             .then(() => BeamsClient.setUserId(String(this.currentUser.id), tokenProvider))
        //     //             .then(() => console.log('User ID has been set'))
        //     //             .catch(e => console.error('Could not authenticate with Beams:', e))
        //     //     })
        //     //     .catch(e => {
        //     //         console.log(e);
        //     //         this.errorHandler(e);
        //     //     })
        //     BeamsClient.start()
        //         .then(() => BeamsClient.addDeviceInterest('hello'))
        //         .then(() => console.log('Successfully registered and subscribed!'))
        //         .catch(console.error);
        // }

        sendPresent(url, present, opponentID){
            console.log(present)

            axios.post(url, present )
                .then(response => {
                    console.log(response.data);
                    if (this.opponent.id === opponentID) {
                        if(this.$route.name === 'profile'){
                            if(this.$mq !== 'desktop'){
                                this.$router.push({path:`/mobile-chat/${this.opponent.id}`})
                            }else{
                                this.showChat = true
                            }
                            this.newMessageHandler(response.data)
                        }
                        if(this.$route.name === 'chat' ){
                            this.newMessageHandler(response.data)
                        }
                    } else {
                        this.chatOwner.credits = response.data.credits
                    }
                    //this.$snotify.success('The gift has been delivered', 'Delivery service');
                })
                .catch(e => {
                    this.errorHandler(e)
                })
                .finally(() => {
                    this.giftProcessing = false;
                })
        },
        sendGift(opponentID, gift) {
            //Need update after include this type in tariffs table
            //TODO
          if (this.checkProbability(gift.credits)) {
                this.giftProcessing = true;
                let present = {
                    opponentID  : opponentID,
                    chatOwnerID : this.chatOwner.id,
                    role        : this.currentUser.role,
                    contact_id  : this.selectedContact ? this.selectedContact.id : null,
                    cost        : gift.credits,
                    type        : "gift",
                    gift_id     : gift.id
                }
                this.sendPresent('/send-present', present, opponentID )
            }
        },
        sendDonate(amount, opponentID){
            console.log('sendDonate')
            if (this.checkProbability(amount)) {
                this.giftProcessing = true;
                let present = {
                    opponentID   : opponentID,
                    chatOwnerID  : this.chatOwner.id,
                    role         : this.currentUser.role,
                    contact_id   : this.selectedContact ? this.selectedContact.id : null,
                    cost         : amount,
                    type         : "donate"
                }
                this.sendPresent('/send-present', present, opponentID)
            }
        },
        buyLink(opponentID, cost){
            if (this.checkProbability(cost)) {
                this.giftProcessing = true;
                let present = {
                    opponentID  : opponentID,
                    chatOwnerID : this.chatOwner.id,
                    role        : this.currentUser.role,
                    contact_id  : this.selectedContact ? this.selectedContact.id : null,
                    cost        : cost,
                    type        :"link"
                }
                this.sendPresent('/send-present', present, opponentID)
            }
        },
        closeModalGifts(){
            console.log('closeModalGifts')
            this.showModalGifts = false;
        },
        openModalGifts(){
            console.log('openModalGifts')
            this.showModalGifts = true;
        },
        checkProbability(cost){
            if(this.chatOwner.credits >= cost){
                return true
            }else {
              this.$router.push({name:'PaymentPage'})
              return false;
            }
        },
        openModalComplain() {
            this.modalComplain = true
            this.$bus.$emit('open_modal');
        },
        closeModalComplain(){
            this.modalComplain = false
            this.$bus.$emit('close_modal');
        },
        openModalBlocked() {
            this.modalBlocked = true;
            this.$bus.$emit('open_modal');
        },
        closeModalBlocked(){
            this.modalBlocked = false;
            this.$bus.$emit('close_modal');
        },
        complainOpponent(data){
            if(this.isPromoPage(false)){
               this.closeModalComplain()
                this.$bus.$emit('open_register')
                return
            }
            console.log('complainOpponent')
            console.log(data)
            axios.post(`/complaint-opponent`,{
                author : data.author,
                complain_message : data.complain_message,
                man_id : data.man_id,
                woman_id : data.woman_id
            })
                .then(response => { console.log(response.data); })
                .catch(e => { alert('Something went wrong...')})
                .finally(()=>{this.$bus.$emit('close_modal_complain')})
        },
        blockOpponentProfile(status){
            if(this.isPromoPage(false)){
                this.closeModalBlocked();
                this.$bus.$emit('open_register')
                return
            }
            axios.post(`/block-opponent`,{
                block:status,
                manID:this.currentUser.role === 'man'?this.chatOwner.id:this.opponent.id,
                womanID:this.currentUser.role === 'woman'?this.chatOwner.id:this.opponent.id,
                operatorID:this.opponent.operator_id?this.opponent.operator_id:null,
                initiator:this.currentUser.role
            })
                .then(response => {
                    console.log(response.data);
                    this.blockOpponent(this.opponent.id,status?'block':'unblock')
                    this.$bus.$emit('close_modal_blocked');
                })
                .catch(e => {
                    console.log(e);
                    this.errorHandler(e);
                })
        },
        updateIndexGallerySlide(index){
            this.indexSlide = index
        },
        updateLimitGallerySlide(value){
            this.limitSlide = value
        },
        updateOffsetGallerySlide(value){
            this.offsetSlide = value
        },
        changeStateGallerySlider(state){
            this.updatingSlide = state
        },
        savePrevioseStateSlider(){
            this.beforeSearchingIndexSlide = this.indexSlide
            this.beforeSearchingOffsetSlide = this.offsetSlide
            this.beforeSearchingLimitSlide = this.limitSlide
        },
        restoreStateSlider(){
            this.indexSlide = this.beforeSearchingIndexSlide
            this.offsetSlide = this.beforeSearchingOffsetSlide
            this.limitSlide = this.beforeSearchingLimitSlide
        },
        isPromoPage(openRegister = true){
            if(this.$route.name === 'promoProfile' || this.$route.name === 'promoChat'){
                if(openRegister)this.$bus.$emit('open_register')
                return true
            }else {return false}
        },
        clearUnreadPromo(){
            this.unreadPromoMessage = 0
        },
        updatePromoMessageInSession(promoMessages){
            axios.post(`/promo-messages-to-session`, {promoMessages:JSON.stringify(promoMessages)})
                .then(response => {console.log(response)})
                .catch(e=>console.error(e))
        },
        resetPromoMessages(){
            if(this.supportsLocalStorage){
                localStorage.removeItem('promoMessages');
            }
        }
    }
}
</script>
<style>
.accordion-enter-active,
.accordion-leave-active {
    will-change: height, opacity;
    transition: height 0.3s ease, opacity 0.3s ease;
    overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
    height: 0 !important;
    opacity: 0;
}

@media (max-width: 529px) {
    .cool-lightbox .cool-lightbox__iframe video {
    height: auto !important;
}}
@media (min-width: 530px){
    .cool-lightbox .cool-lightbox__iframe video {
        width: auto !important;
    }
}

</style>

