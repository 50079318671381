<template>
    <portal selector="#portal-target">
        <div class="modal modal_sm modal_block-user is-active"  tabindex="-1" role="dialog">
            <div class="modal__bg" @click.prevent="closeModal"></div>
            <div class="modal__container">
                <span class="modal__close"  role="button" aria-label="Close" @click.prevent="closeModal">
                    <i class="icon icon_close"></i>
                </span>
                <div class="modal__header">
                    <h2 class="modal__title">Attention!</h2>
                </div>
                <div class="modal__body">
                    <p>File conversion failed.</p>
                    <p>Try again or choose a different file.</p>
                </div>
                <div class="modal__footer">
                    <button class="button button_md button_primary" @click.prevent="closeModal">
                        <span>OK!</span>
                    </button>
                </div>
            </div>
        </div>
    </portal>

</template>

<script>
export default {
    name: "ModalErrorConversation",
    mounted() {
        this.$bus.$emit('open_modal')
    },
    beforeDestroy() {
        this.$bus.$emit('close_modal')
    },
    methods:{
        closeModal(){
            this.$emit('close_modal')
        }
    }
}
</script>

<style scoped>

</style>
