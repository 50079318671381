import {debounce} from "../helpers";

export default {
    inserted: (el, binding, time) => {
        let f = function (evt) {
            if (binding.value(evt, el)) {
                window.removeEventListener('scroll', f)
            }
        }
        window.addEventListener('scroll', debounce(f, 250))
    }
};
