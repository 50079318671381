<template>
    <portal selector="#portal-target">
        <div class="modal modal_notify modal_lost_connect is-active"  tabindex="-1" role="dialog">
            <div class="modal__container">
                <div class="modal__body">
                    <span class="modal__message">
                        <span class="is-desktop">Attention there is </span> no connection...
                    </span>
                    <button class="button button_collapse" @click.prevent="reload">
                        <svg class="button__icon" xmlns="http://www.w3.org/2000/svg" width="32" height="24" fill="none">
                            <path fill="currentColor" d="M4.3661 11.6475H0l5.81695 5.8169 5.81695-5.8169H7.2678c0-4.8136 3.9186-8.73225 8.7322-8.73225 1.4644 0 2.861.36611 4.0678 1.01695l2.1288-2.12881C20.4068.664407 18.278 0 16 0 9.57288.01356 4.3661 5.22034 4.3661 11.6475Zm20.3661 0c0 4.8135-3.9186 8.7322-8.7322 8.7322-1.4644 0-2.861-.3661-4.0678-1.017l-2.12881 2.1288C11.5932 22.6305 13.7085 23.2949 16 23.2949c6.4271 0 11.6339-5.2068 11.6339-11.6339H32l-5.8169-5.81693-5.817 5.80343h4.3661Z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
export default {
    name: "ModalLostConnection",
    methods:{
        reload(){
            window.location.reload()
        }
    }
}
</script>
